import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

import * as toast from 'components/Shared/toast';
import ProposedActionForm from './ProposedActionForm';
import { AppealioPopupWithFooter } from 'components/common/popup';

import { handleError } from 'helpers/errorHandler';

import { createProposedAction } from 'API/SopAPI';

const CreateProposedActionPopup = (props) => {
  const { onClosePressed, fetchProposedAction } = props;

  const handleFormSubmit = async ({ description }) => {
    const payload = {
      description,
    };
    try {
      await createProposedAction(payload);
      onClosePressed();
      toast.success({
        title: 'Success',
        message: 'Proposed action created successfully',
      });
      onClosePressed();
      fetchProposedAction();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <AppealioPopupWithFooter
      className="appealio-popup--v3 proposed-action-popup"
      onClosePressed={onClosePressed}
      title="Add Proposed Action"
      isFooterOutside={false}
      datacy="add-user-AppealioPopupWithFooter"
    >
      <ProposedActionForm handleFormSubmit={handleFormSubmit} />
    </AppealioPopupWithFooter>
  );
};

CreateProposedActionPopup.propTypes = {
  onClosePressed: PropTypes.func,
};

export default CreateProposedActionPopup;
