import PropTypes from 'prop-types';
import React, { Component } from 'react';

import LabelValue from '../../Shared/LabelValue';
import { moneyFormatter } from '../../../helpers/money';

const COLUMN_LABELS = {
  BILLED_AMOUNT: 'Billed Amount:',
  AMOUNT_ALLOWED: 'Amount Allowed:',
  DEDUCTIBLE: 'Deductible:',
  CO_INSURANCE: 'Coinsurance:',
  PAYMENT_AMOUNT: 'Payment Amount:',
};
export default class TotalAmounts extends Component {
  static propTypes = {
    billedAmount: PropTypes.number,
    amountAllowed: PropTypes.number,
    deductible: PropTypes.number,
    coInsurance: PropTypes.number,
    paymentAmount: PropTypes.number,
    totalDeniedAmount: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    billedAmount: 0,
    amountAllowed: 0,
    deductible: 0,
    coInsurance: 0,
    paymentAmount: 0,
    totalDeniedAmount: 0,
    labels: [
      COLUMN_LABELS.BILLED_AMOUNT,
      COLUMN_LABELS.AMOUNT_ALLOWED,
      COLUMN_LABELS.DEDUCTIBLE,
      COLUMN_LABELS.CO_INSURANCE,
      COLUMN_LABELS.PAYMENT_AMOUNT,
    ],
  };

  render() {
    return this.renderColumns();
  }

  renderColumns() {
    const {
      billedAmount,
      amountAllowed,
      deductible,
      coInsurance,
      paymentAmount,
    } = this.props;
    const labels = this.props.labels;

    return (
      <tr
        className="total-amounts cpt__table-row totals-wrapper"
        datacy="TotalAmounts"
      >
        <td colSpan={4} className="section-header cpt__table-column">
          Totals
        </td>

        {this.renderColumn(labels[0], billedAmount)}
        {this.renderColumn(labels[1], amountAllowed)}
        {this.renderColumn(labels[2], deductible, 2)}

        {this.renderColumn(labels[3], coInsurance)}
        {this.renderColumn(labels[4], paymentAmount)}
      </tr>
    );
  }

  renderColumn(label, value, colSpan = null) {
    if (this.props.isImportedAppeal) {
      const COLUMNS_TO_EXCLUDE = [COLUMN_LABELS.PAYMENT_AMOUNT];

      if (COLUMNS_TO_EXCLUDE.includes(label)) {
        return;
      }
    }
    return (
      <td colSpan={colSpan} className="cpt__table-column">
        <LabelValue label={label} value={moneyFormatter().format(value)} />
      </td>
    );
  }
}
