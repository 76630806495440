import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { useState, useEffect } from 'react';

import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';
import DocumentAnimation from 'components/common/animation/document.json';

const ProgressBar = (props) => {
  const {
    className,
    title,
    isDocumentLoaded,
    onProgressComplete,
    showProgressBar,
    timeOutDuration = 3000,
    successfulMessage = 'Appealio has successfully created your package.',
  } = props;
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (!isDocumentLoaded) {
      setTimeout(
        () =>
          setPercentage((prev) => {
            if (prev < 90) prev += 1;
            return prev;
          }),
        50
      );
    } else {
      setPercentage(100);
      setTimeout(() => {
        onProgressComplete();
      }, timeOutDuration);
    }
  }, [percentage, isDocumentLoaded, onProgressComplete, timeOutDuration]);
  return (
    <ScreenDimmerComponent
      className={classnames('progress-bar-screen-dimmer', {
        'progress-bar-screen-dimmer--show': showProgressBar,
      })}
    >
      <div
        className={classnames('progressbar-popup', {
          [className]: className,
        })}
      >
        <div className="progressbar-popup__content">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: DocumentAnimation,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={250}
            width={250}
          />
          <div className="position-relative">
            <div className="loading-bar-container" datacy="loading-bar-div">
              <div className="progress">
                <div
                  className="progress-value"
                  style={{
                    width: `${percentage}%`,
                  }}
                >
                  <div className="progress-percentage">{percentage}%</div>
                </div>
              </div>
            </div>
          </div>
          {title && (
            <h4 className="progressbar-title mt-40 text-center">
              {percentage === 100 ? successfulMessage : title}
            </h4>
          )}
        </div>
      </div>
    </ScreenDimmerComponent>
  );
};

ProgressBar.propTypes = {
  title: PropTypes.string,
};

export default ProgressBar;
