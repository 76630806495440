import { omit } from 'lodash';

import {
  handleErrors,
  createRequest,
  handleJSONParse,
  createGetRequest,
  BASE_API_URL,
} from './Config';

import * as endpoints from '../constants/endpoints';
import { camelizeKeys, snakeCaseKeys } from '../helpers/object';

export const fetchProvidersFromRegistry = async (npi) => {
  const request = createGetRequest(
    '/provider-search',
    {
      npi,
    },
    BASE_API_URL,
    true
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => res.data.map(camelizeKeys));
};

export const signupInfo = async (signupInfoData) => {
  const url = endpoints.CLIENT_SIGNUP;

  const request = createRequest(url, signupInfoData, {
    baseUrl: BASE_API_URL,
    isBearer: true,
  });

  return await fetch(request).then(handleErrors);
};

export const signupDetail = async (signupDetailData) => {
  const url = endpoints.CLIENT_SIGNUP;

  const mappedData = {
    practices: signupDetailData.practices.map((practice) => {
      const practiceUuid = practice.uuid;
      return {
        ...snakeCaseKeys(omit(practice, ['address1', 'address2'])),
        address1: practice.address1,
        address2: practice.address2,
        clinics: signupDetailData.clinics
          .filter((clinic) => clinic.practiceUuid === practiceUuid)
          .map((clinic) => ({
            ...snakeCaseKeys(omit(clinic, ['address1', 'address2'])),
            address1: clinic.address1,
            address2: clinic.address2,
          })),
        billing_providers: signupDetailData.billingProviders
          .filter(
            (billingProvider) => billingProvider.practiceUuid === practiceUuid
          )
          .map(snakeCaseKeys),
        rendering_providers: signupDetailData.renderingProviders
          .filter(
            (billingProvider) => billingProvider.practiceUuid === practiceUuid
          )
          .map(snakeCaseKeys),
      };
    }),
    payer_ids: signupDetailData.payerIds,
    template_ids: signupDetailData.templateIds,
  };

  const request = createRequest(url, mappedData, {
    baseUrl: BASE_API_URL,
    isBearer: true,
    method: 'PUT',
  });

  return await fetch(request).then(handleErrors);
};
