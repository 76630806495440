import * as endpoints from 'constants/endpoints';
import { camelizeKeys } from 'helpers/object';

import {
  handleErrors,
  handleJSONParse,
  createGetRequest,
  createRequest,
  BASE_API_URL,
} from './Config';

/**
 * Calls the API to fetch the list of clients.
 * @returns {Promise}
 */
export const fetchClients = () => {
  const url = endpoints.SWITCH_CLIENT;
  const request = createGetRequest(url, {}, BASE_API_URL);

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Calls the API to fetch the list of clients.
 * @param {Number} clientId
 * @returns {Promise}
 */
export const changeClient = (clientId) => {
  const url = endpoints.SWITCH_CLIENT;
  const request = createRequest(url, {
    client: clientId,
  });

  return fetch(request).then(handleErrors);
};
