import { isEmpty } from 'lodash';
import { BiCheck } from 'react-icons/bi';
import React, { useRef, useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import Input from 'components/common/input';
import Select from 'components/common/select/Select';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import SelectFileToUploadButton from 'components/CreateAppeal/DocumentsUpload/SelectFileToUploadButton';

import { CONTENT_TYPE } from '../AppealLetter';

import AppealsAPI from 'API/AppealsAPI';
import { handleError } from 'helpers/errorHandler';
import { validateFileSize } from 'helpers/validators';

import {
  FILE_SIZE_EXCEEDS,
  INVALID_TEMPLATE_TYPE,
  UPLOAD_PAYER_FORM,
  UPLOAD_LETTER_TEMPLATE,
} from 'components/Shared/Errors/errorMessages';

const UPLOAD_DOCUMENT_TYPE = {
  LETTER: 'Letter',
  FORM: 'Form',
};

const useTemplateDropdownOptions = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [dropdownOptions, setDropdownOptions] = useState({
    payers: [],
  });

  useEffect(() => {
    setLoading(true);

    const fetch = async () => {
      try {
        const payerResponse = await AppealsAPI.getPayersOptions();

        const payers = payerResponse.map(({ id: value, name: label }) => ({
          value,
          label,
        }));

        setDropdownOptions({
          payers,
        });
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    };

    fetch();
  }, []);

  return { loading, error, dropdownOptions };
};

const AddTemplateForm = (props) => {
  const {
    handleSubmit,
    submitting,
    error,
    onSuccess,
    onClose,
    isPopupForm = false,
    contentType,
    selectedPayerId,
  } = props;

  const { loading: isDropdownOptionsLoading, dropdownOptions } =
    useTemplateDropdownOptions();
  const [file, setFile] = useState();
  const inputFileRef = useRef(null);
  const [fileError, setFileError] = useState(null);
  const [isTemplateAdding, setIsTemplateAdding] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const templateType =
    contentType === CONTENT_TYPE.LETTER
      ? UPLOAD_DOCUMENT_TYPE.LETTER
      : UPLOAD_DOCUMENT_TYPE.FORM;

  const onSubmit = async (values) => {
    if (!file) {
      setFileError(
        templateType === UPLOAD_DOCUMENT_TYPE.LETTER
          ? UPLOAD_LETTER_TEMPLATE
          : UPLOAD_PAYER_FORM
      );
      return;
    }

    const { payers = [], templateName } = values.toJS();

    let linkPath;

    try {
      setIsFileUploading(true);
      linkPath = await AppealsAPI.uploadRequestedTemplateToS3(file);
      setIsFileUploading(false);
    } catch (error) {
      return handleError(error);
    }

    const payerIds = payers.map((payer) => payer.value);
    const requestTemplatePayload = {
      link_path: linkPath,
      template_name: templateName,
      payer_ids: payerIds,
      template_type: templateType,
    };

    try {
      setIsTemplateAdding(true);
      await AppealsAPI.requestAppealTemplate(requestTemplatePayload);
      onClose();
      setIsTemplateAdding(false);
      onSuccess(templateName);
    } catch (error) {
      setIsTemplateAdding(false);
      return handleError(error);
    }
  };

  const onBtnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    inputFileRef.current.click();
  };

  const handleFileChange = (event) => {
    const allowedFileExtensions = ['pdf', 'doc', 'docx'];
    const allowedFileTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const file = event.target.files[0];
    const { name, type, size } = file;
    const fileExtension = name.split('.').pop();

    if (!validateFileSize(size)) {
      setFileError(FILE_SIZE_EXCEEDS);
      return;
    }

    if (
      allowedFileExtensions.includes(fileExtension) &&
      allowedFileTypes.includes(type)
    ) {
      setFile(file);
      setFileError(null);
    } else {
      setFileError(INVALID_TEMPLATE_TYPE);
    }
  };

  const isTemplateForm = templateType === 'Form';
  const templateNameLabel = isTemplateForm
    ? 'Payer Form Name *'
    : 'Letter Template Name *';
  const uploadBtnTitle = isTemplateForm
    ? 'Upload Payer Form'
    : 'Upload Letter Template';
  const selectPayersLabel = isTemplateForm
    ? 'Select Payer(s) for this Payer Form'
    : 'Select Payer(s) for this Letter Template';
  const isSubmitBtnDisabled = submitting || fileError || error;
  const payers = dropdownOptions.payers.filter(
    (payer) => payer.value === selectedPayerId
  );
  if (!isEmpty(payers)) {
    props.change('payers', payers);
  }

  return (
    <form className="template-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="row  mb-24">
        <div className="col-md-12">
          <Field
            component={Input}
            name="templateName"
            label={templateNameLabel}
            datacy="template-name-Field"
          />
        </div>
      </div>

      <div className="row mb-24">
        <div className="col-md-12">
          <div className="d-flex align-items-center template-file-upload-wrapper">
            <div className="template-file-upload__file-name">
              {file && !isFileUploading ? (
                file.name.includes('upload') ? (
                  <BiCheck color="green" size="32" />
                ) : (
                  !fileError && file.name
                )
              ) : (
                <LoadingIndicator showing={isFileUploading} />
              )}
            </div>
            <div>
              <label className="file-upload">
                <input
                  className="file-upload__input"
                  type="file"
                  ref={inputFileRef}
                  onChange={handleFileChange}
                  accept="application/msword,
                  application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx,application/pdf"
                  datacy="file-upload-input"
                />
                <SelectFileToUploadButton
                  onClick={onBtnClick}
                  btnTitle={uploadBtnTitle}
                  type="button"
                  datacy="select-file-SelectFileToUploadButton"
                />
              </label>
            </div>
          </div>
          {(error || fileError) && (
            <span className="ap-input-error fw-normal mt-8">
              {fileError || error}
            </span>
          )}
        </div>
      </div>

      <div className="row no-gutter mb-24">
        <div className="col-md-12">
          <Field
            isLoading={isDropdownOptionsLoading}
            component={Select}
            options={dropdownOptions.payers}
            name="payers"
            label={selectPayersLabel}
            placeholder="All Payers"
            reactSelectProps={{
              isMulti: true,
            }}
            isDisabled={!isEmpty(payers)}
            datacy="payers-Field"
          />
        </div>
      </div>
      {isPopupForm ? (
        <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
          <LoadingIndicator showing={isTemplateAdding} />
          <button
            disabled={isSubmitBtnDisabled}
            type="submit"
            className="ap-button ap-button--primary-dark ap-button--primary-md"
            datacy="popup-submit-button"
          >
            Add
          </button>
        </div>
      ) : (
        <div className="row no-gutter template-file-upload__submit-button-wrapper">
          <div className="col-6 appeal-letter__request-wrapper">
            <LoadingIndicator showing={isTemplateAdding} />
            <button
              disabled={submitting}
              type="submit"
              className="ap-button ap-button--primary width-130 justify-content-center"
              datacy="submit-button"
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </form>
  );
};

AddTemplateForm.propTypes = {};

const validate = (values, props) => {
  const errors = {};
  const templateError =
    props.contentType === CONTENT_TYPE.LETTER
      ? 'Letter Template Name is required.'
      : 'Payer Form Name is required.';

  if (!values.get('templateName')) {
    errors.templateName = templateError;
  }

  return errors;
};

export default reduxForm({
  validate,
  form: 'add-template-form',
})(AddTemplateForm);
