import React from 'react';
import classnames from 'classnames';
import ReactAutocomplete from 'react-autocomplete';

import './style.css';

const ItemRenderer = (item, isHighlighted) => (
  <div
    key={item}
    className={classnames('autocomplete-option', {
      'autocomplete-option-highlighted': isHighlighted,
    })}
  >
    {item}
  </div>
);

const Autocomplete = (props) => {
  const {
    name,
    label,
    value,
    onChange,
    onSelect,
    placeholder = '',
    input = {},
    meta: { touched, error = '' } = {},
    options = [],
    renderItem = ItemRenderer,
    getItemValue = (item) => item,
    onPaste = (e) => {},
  } = props;

  return (
    <div
      className="autocomplete-input-container"
      datacy="autocomplete-input-div"
    >
      {label && (
        <label className="ap-input-label" htmlFor={name}>
          {label}
        </label>
      )}
      <ReactAutocomplete
        getItemValue={getItemValue}
        items={options}
        renderItem={renderItem}
        renderInput={(props) => <input {...props} placeholder={placeholder} />}
        value={value}
        {...input}
        onChange={onChange}
        onSelect={onSelect}
        inputProps={{
          onPaste,
        }}
        menuStyle={{
          display: options.length ? 'block' : 'none',
          borderRadius: '3px',
          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
          background: 'rgba(255, 255, 255, 0.9)',
          padding: '2px 0',
          fontSize: '90%',
          position: 'fixed',
          overflow: 'auto',
          maxHeight: '50%',
          zIndex: '1000',
        }}
      />
      {touched && error && <span className="ap-input-error">{error}</span>}
    </div>
  );
};

export default Autocomplete;
