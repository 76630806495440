import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UhcClaimStatusCheck from './UhcClaimStatusCheck';
import { closeUhcClaimStatusWidget } from 'redux/actions/uhcClaimStatusWidgetActions';

const UhcClaimStatusCheckWidget = (props) => {
  const { data, isOpen } = props;
  if (!isOpen) return null;

  return (
    <UhcClaimStatusCheck
      onCloseClick={props.actions.closeUhcClaimStatusWidget}
      claimControlNumber={data.claimControlNumber}
      claimNumber={data.claimNumber}
      billingProviderTaxId={data.billingProviderTaxId}
      ediPayerId={data.ediPayerId}
      billingProviderName={data.billingProviderName}
      renderingProviderName={data.renderingProviderName}
    />
  );
};

UhcClaimStatusCheckWidget.propTypes = {};

const mapStateToProps = (state) => {
  const { isOpen, data } = state.get('uhcClaimStatusStoreWidgetStore').toJS();
  return {
    isOpen,
    data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        closeUhcClaimStatusWidget,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UhcClaimStatusCheckWidget);
