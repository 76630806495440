import { useEffect, useRef } from 'react';

/**
 * Custom hook to perform polling with a given callback function at specified intervals.
 *
 * @param {Function} callback - The callback function to be executed at each interval.
 * @param {number} [interval=60000] - The interval in milliseconds between each callback execution. Defaults to 60000ms (1 minute).
 */
export const usePolling = (callback, interval = 60000) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (interval !== null) {
      const id = setInterval(tick, interval);
      return () => clearInterval(id);
    }
  }, [interval]);
};
