import { get, isEqual } from 'lodash';
import ReactTooltip from 'react-tooltip';
import React, { Component } from 'react';
import ChartistGraph from 'react-chartist';
import ChartistFillDonut from 'chartist-plugin-fill-donut';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';

import DashboardAPI from '../../../../API/DashboardAPI';
import { handleError } from '../../../../helpers/errorHandler';

import questionIconImg from '../../../../img/question.svg';
import LoadingBlockHelper from '../../../Shared/LoadingBlockHelper';

export default class AppealDeadlineMet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        deadlineMetCount: 0,
        deadlineUnmetCount: 0,
        outstandingCount: 0,
        totalDeadlineCount: 0,
      },
      isFetching: false,
    };
  }

  componentDidMount = () => {
    this.fetchAndSetData();
  };

  componentDidUpdate = (prevProps) => {
    if (!isEqual(prevProps.filters, this.props.filters)) {
      this.fetchAndSetData();
    }
  };

  setViewWidgetBy = (setViewWidgetBy) => this.setState({ setViewWidgetBy });

  fetchAndSetData = async () => {
    const { filters } = this.props;

    const filtersForFetch = {
      payer_id: get(filters, 'payer.value'),
      provider_id: get(filters, 'renderingProvider.value'),
      practice_identifier: get(filters, 'practice.value'),
      agent_id: get(filters, 'agent.value'),
      from_date: filters?.dateRange?.fromDate,
      to_date: filters?.dateRange?.toDate,
      team_id: get(filters, 'team.value'),
    };

    try {
      this.setState({ isFetching: true });

      const [data] = await DashboardAPI.fetchDeadlinesMetVsAllDeadlines(
        filtersForFetch
      );

      this.setState({ data, isFetching: false });
    } catch (error) {
      this.setState({ isFetching: false });
      handleError(error);
    }
  };

  renderContent() {
    const {
      deadlineMetCount,
      outstandingCount,
      deadlineUnmetCount,
      totalDeadlineCount,
    } = this.state.data;

    if (this.props.isFetchingFilterOptions || this.state.isFetching) {
      return (
        <LoadingBlockHelper
          className="agent-deadline-met__loader"
          isLoading={true}
        />
      );
    }

    return (
      <React.Fragment>
        <div className="agent-deadline-met__graph ct-chart">
          <ChartistGraph
            data={{
              series: [
                {
                  meta: 'Deadline Met',
                  value: deadlineMetCount,
                  className: 'appeal-deadline-met-donut--met',
                },
                {
                  meta: 'Deadline Unmet Count',
                  value: deadlineUnmetCount,
                  className: 'appeal-deadline-met-donut--unmet',
                },
                {
                  meta: 'Outstanding Count',
                  value: outstandingCount,
                  className: 'appeal-deadline-met-donut--outstanding',
                },
              ],
            }}
            options={{
              donut: true,
              donutWidth: 20,
              startAngle: 0,
              showLabel: false,
              width: 152,
              height: 152,
              plugins: [
                ChartistTooltip({
                  appendToBody: true,
                }),
                ChartistFillDonut({
                  items: [
                    {
                      content: `<div class="appeal-deadline-met-donut__middle-content">
                        <span>${totalDeadlineCount}</span>
                      </div>`,
                    },
                  ],
                }),
              ],
            }}
            type="Pie"
          />
        </div>

        <div className="agent-deadline-met__stats">
          <div className="d-flex align-items-center mb-16">
            <span className="mr-4 appeal-deadline-indicator appeal-deadline-indicator--met"></span>
            <span className="agent-deadline-met__stats-title">
              {deadlineMetCount} Met
            </span>
          </div>

          <div className="d-flex align-items-center mb-16">
            <span className="mr-4 appeal-deadline-indicator appeal-deadline-indicator--unmet"></span>
            <span className="agent-deadline-met__stats-title">
              {deadlineUnmetCount} Unmet
            </span>
          </div>

          <div className="d-flex align-items-center mb-16">
            <span className="mr-4 appeal-deadline-indicator appeal-deadline-indicator--outstanding"></span>
            <span className="agent-deadline-met__stats-title">
              {outstandingCount} Outstanding
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="ap-dashboard-widget__container">
        <div className="ap-dashboard-widget__title d-flex justify-content--space-between">
          <span>Appeal Deadlines</span>
          <img
            data-tip="View the number of met and unmet deadlines"
            alt="question icon"
            src={questionIconImg}
          ></img>
          <ReactTooltip effect="solid" place="top" multiline={true} />
        </div>
        <div className="agent-deadline-met__container">
          {this.renderContent()}
        </div>
      </div>
    );
  }
}
