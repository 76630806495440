import { useEffect, useState, useCallback } from 'react';

import { fetchPortalInfo } from 'API/PortalAPI';
import { serializePortalInfo } from 'API/Mappers';

/**
 * Fetches payer portal information for a given appeal ID and appeal data.
 * If the API request fails or returns no data, it will fall back to generating
 * portal info from the appeal data.
 *
 * @param {number} appealId - The ID of the appeal to fetch portal info for.
 * @param {object} appealData - The appeal data to use if the API request fails.
 *
 * @returns {object} An object containing the loading state, error (if any),
 *                  and the fetched portal info.
 */
export const useFetchPortalInfo = (appealId, appealData) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [portalInfo, setPortalInfo] = useState([]);

  const fetch = useCallback(async () => {
    setLoading(true);
    setPortalInfo([]);
    try {
      const { data } = await fetchPortalInfo(appealId);
      const portalInfo = [...serializePortalInfo(appealData), ...data];
      setPortalInfo(portalInfo);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [appealId, appealData]);

  useEffect(() => {
    fetch();
  }, [fetch, appealId]);

  return { loading, error, fetch, portalInfo };
};
