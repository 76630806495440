import { get } from 'lodash';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { getUserInfo } from 'redux/reducers/loginStore';
import {
  Field,
  reduxForm,
  change,
  SubmissionError,
  formValueSelector,
} from 'redux-form/immutable';

import Input from 'components/common/input';
import Select from 'components/common/select/Select';
import PracticeSelect from './PracticeSelect/PracticeSelect';

import { states } from 'components/CreateAppeal/state-mock-data';

import { USER_ROLE_OPTIONS } from 'constants/options';
import { USER_ROLES_VALUE } from 'constants/appConstants';

import { MASK_PHONE } from 'helpers/masks';
import { handleError } from 'helpers/errorHandler';
import {
  validateEmail,
  validatePhoneNumber,
  fieldCharacterValidation,
  validateAlphaNumericString,
} from 'helpers/validators';
import {
  DROPDOWN_OPTIONS_STATE_KEYS,
  getDropdownOptions,
} from 'redux/reducers/dropdownOptionsStore';

import { extractPracticeAndPracticeGroupValues } from './PracticeSelect/utils';
import { fetchPracticeGroupOptions } from 'redux/actions/dropdownOptionsActions';

let AddUserForm = (props) => {
  const {
    handleSubmit,
    onSubmit,
    initialValues,
    invalid,
    submitting,
    practices,
    practiceGroups,
  } = props;

  const stateOptions = states.map((state) => ({
    label: state.value,
    value: state.key,
  }));

  const validate = (values) => {
    const errors = {
      firstName: 'User first name is required',
      lastName: 'User last name is required',
      emailAddress: 'Email address is required',
      username: 'Username is required',
      role: 'Please select a role',
    };

    if (
      !values.get('firstName') &&
      !values.get('lastName') &&
      !values.get('emailAddress') &&
      !values.get('username') &&
      !values.get('role')
    ) {
      throw new SubmissionError(errors);
    }

    if (values.get('firstName')) {
      delete errors.firstName;
      const firstNameValidation = fieldCharacterValidation(
        'User First Name',
        values.get('firstName'),
        150 // 150 max len from AbstractUser on backend
      );
      if (firstNameValidation) {
        errors.firstName = firstNameValidation;
      }

      if (!validateAlphaNumericString(values.get('firstName'))) {
        errors.firstName =
          'First Name must only contain alphanumeric characters';
      }
    }

    if (values.get('lastName')) {
      delete errors.lastName;
      const lastNameValidation = fieldCharacterValidation(
        'User Last Name',
        values.get('lastName'),
        150 // 150 max len from AbstractUser on backend
      );
      if (lastNameValidation) {
        errors.lastName = lastNameValidation;
      }

      if (!validateAlphaNumericString(values.get('lastName'))) {
        errors.lastName = 'Last Name must only contain alphanumeric characters';
      }
    }

    if (values.get('emailAddress')) {
      delete errors.emailAddress;
      const emailValidation = validateEmail(values.get('emailAddress'));
      if (emailValidation) {
        errors.emailAddress = emailValidation;
      }
    }

    if (values.get('username')) {
      delete errors.username;
      const usernameValidation = fieldCharacterValidation(
        'User Last Name',
        values.get('username')
      );
      if (usernameValidation) {
        errors.name = usernameValidation;
      }
    }

    if (values.get('phone')) {
      const phoneValidation = validatePhoneNumber(values.get('phone'));
      if (phoneValidation) {
        errors.phone = phoneValidation;
      }
    }

    if (values.get('fax')) {
      const faxValidation = validatePhoneNumber(values.get('fax'));
      if (faxValidation) {
        errors.fax = faxValidation;
      }
    }

    if (values.get('role')) {
      delete errors.role;
    }

    if (Object.keys(errors).length) {
      throw new SubmissionError(errors);
    }
  };

  const handleFormSubmit = async (formValues) => {
    const values = formValues.toJS();
    let formData = {};

    validate(formValues);

    const { practiceGroupValues, practiceValues } =
      extractPracticeAndPracticeGroupValues(values?.practices || []);

    formData = {
      ...values,
      allowedItems: {
        practice: practiceValues,
        pgroup: practiceGroupValues,
      },
      state: values.state ? values.state.value : null,
      role: values.role.value,
    };

    try {
      await onSubmit(formData);
    } catch (error) {
      if (error && error.name === 'SubmissionError') {
        throw error;
      }
      handleError(error);
    }
  };

  const availableUserPracticeGroups = [
    ...practiceGroups.data.filter((x) => x.value && x.label !== 'Ungrouped'),
  ];

  const availableUserPractices = [...practices.data.filter((x) => x.value)];

  useEffect(() => {
    props.actions.fetchPracticeGroupOptions();
    if (initialValues) {
      const values = initialValues.toJS();
      const selectedState = stateOptions.find(
        (option) => option.value === values.state
      );

      props.initialize({
        ...values,
        state: selectedState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnRoleChange = (role) => {
    if (
      [USER_ROLES_VALUE.MANAGER, USER_ROLES_VALUE.SUPERVISOR].includes(
        role.value
      )
    ) {
      props.change('practices', []);
    }
    props.change('role', role);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} datacy="add-user-form">
      <div className="form-step__wrapper">
        <h5 className="form-step__title">Enter New User Info</h5>
        <div className="form-step__content">
          <div className="row mb-12">
            <div className="col-md-6">
              <Field
                component={Input}
                name="firstName"
                label="First Name *"
                placeholder="John"
                required
                datacy="user-first-name-Field"
              />
            </div>
            <div className="col-md-6">
              <Field
                component={Input}
                name="lastName"
                label="Last Name *"
                placeholder="Doe"
                datacy="user-last-name-Field"
              />
            </div>
          </div>
          <div className="row mb-12">
            <div className="col-md-6">
              <Field
                component={Input}
                name="emailAddress"
                label="Email Address *"
                placeholder="john.doe@company.com"
                required
                datacy="user-email-address-Field"
              />
            </div>
            <div className="col-md-6">
              <Field
                component={Input}
                name="username"
                label="Username *"
                placeholder="johndoe"
                required
                datacy="user-username-Field"
              />
            </div>
          </div>
          <div className="row mb-12">
            <div className="col-md-6">
              <Field
                component={Select}
                options={USER_ROLE_OPTIONS}
                name="role"
                defaultValue={null}
                label={'Role *'}
                placeholder="Role"
                datacy="role-field"
                onChange={handleOnRoleChange}
              />
            </div>
            <div className="col-md-6">
              <label className="ap-select-label">Practice</label>
              <Field
                component={PracticeSelect}
                practiceGroupOptions={availableUserPracticeGroups}
                practiceOptions={availableUserPractices}
                name="practices"
              />
            </div>
          </div>
          <div className="row mb-12">
            <div className="col-md-6">
              <Field
                component={Input}
                name="phone"
                label="Phone Number"
                maskedInput={true}
                mask={MASK_PHONE}
                placeholder="(DDD) DDD-DDDD"
                datacy="user-phone-Field"
              />
            </div>
            <div className="col-md-6">
              <Field
                component={Input}
                name="fax"
                label="Fax Number"
                maskedInput={true}
                mask={MASK_PHONE}
                placeholder="(DDD) DDD-DDDD"
                datacy="user-fax-Field"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
        <button
          type="submit"
          disabled={invalid || submitting}
          className="ap-button ap-button--primary-dark ap-button--primary-md"
          datacy="add-user-form-submit-button"
        >
          Add
        </button>
      </div>
    </form>
  );
};

AddUserForm.propTypes = {};

AddUserForm = reduxForm({
  form: 'addUserForm',
})(AddUserForm);

const mapStateToProps = (state) => {
  const userInfo = getUserInfo(state);
  const userPractices = get(userInfo, 'relatedPractices', []);
  const selector = formValueSelector('addUserForm');
  const selectedPractices = selector(state, 'practices') || [];
  const currentRole = selector(state, 'role') || [];

  return {
    selectedPractices,
    userPractices,
    currentRole,
    userInfo,
    practiceGroups: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.PRACTICE_GROUP_OPTIONS
    ),
    practices: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PRACTICE_OPTIONS
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      { change, fetchPracticeGroupOptions },
      dispatch
    ),
  };
};

AddUserForm = connect(mapStateToProps, mapDispatchToProps)(AddUserForm);

export default AddUserForm;
