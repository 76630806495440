import { Map } from 'immutable';
import * as types from '../actions/actionTypes';

const initialState = Map({});

export function forgotPasswordStore(state = initialState, action = {}) {
  switch (action.type) {
    case `${types.FORGOT_PASSWORD_SEND_EMAIL}_FULFILLED`:
      return state.set('emailSentTo', action.payload.email);
    default:
      return state;
  }
}
