import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { BiDownload } from 'react-icons/bi';
import { isInclusivelyAfterDay } from 'react-dates';

import * as toast from '../../Shared/toast';
import Button, { BUTTON_TYPE } from './Button';
import { DatePicker } from 'components/common/datepicker';
import AppealioPopup from 'components/Shared/AppealioPopup';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';

import { exportAllClaims } from 'API/DenialsAPI';

import { EXPORT_CSV_MESSAGE, EXPORT_OPTION } from 'constants/appConstants';
import { DATE_FORMAT_YYYY_MM_DD } from 'helpers/dateFormats';

import calendar from 'img/calendar-icon.svg';

const DEFAULT_FILTERS = {
  startDate: moment().subtract(90, 'days').format(DATE_FORMAT_YYYY_MM_DD),
  endDate: moment().format(DATE_FORMAT_YYYY_MM_DD),
  agents: {
    assignedToMe: true,
    unassigned: true,
    assignedToOthers: true,
  },
};

const DownloadClaimsCsv = ({
  tableName = '',
  onClick,
  isLoading = false,
  userInfo,
}) => {
  const handleFilters = (filters) => {
    const agentFilters = [];
    if (filters.agents.assignedToMe) {
      agentFilters.push(userInfo.username);
    }
    if (filters.agents.unassigned) {
      agentFilters.push('n/a');
    }

    if (filters.agents.assignedToOthers) {
      agentFilters.push('others');
    }
    return {
      ...filters,
      agents: agentFilters,
    };
  };
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    EXPORT_OPTION.CURRENT_TAB
  );
  const [filters, setFilters] = useState(handleFilters(DEFAULT_FILTERS));
  const [error, setError] = useState();
  const [isExportingCSV, setIsExportingCSV] = useState(false);

  const onExportAllClaims = async () => {
    setIsExportingCSV(true);
    const res = await exportAllClaims(filters);
    setIsExportingCSV(false);
    toast.success({
      title: 'Success',
      message: EXPORT_CSV_MESSAGE,
    });
    return res;
  };

  const handleFilterChange = ({ value }, name) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  return (
    <div>
      <div className="download-csv-wrapper">
        <Button
          title="Export Claims"
          type={BUTTON_TYPE.TERTIARY}
          iconClassName="mr-4"
          onClick={() => {
            setIsPopupOpen(true);
            setFilters(handleFilters(DEFAULT_FILTERS));
          }}
          datacy="select-from-all-eras-Button"
        />
      </div>

      {isPopupOpen && (
        <AppealioPopup
          title={
            selectedOption === EXPORT_OPTION.CURRENT_TAB
              ? 'Export CSV'
              : 'Export Claims'
          }
          className="appeal-template-popup import-rule-popup download-csv--popup"
          onClosePressed={() => {
            setIsPopupOpen(false);
          }}
        >
          <div>
            <div>
              <h4>Select Option</h4>
            </div>
            <div className="row mb-20 mt-20">
              <div className="col-md-12">
                <div>
                  <input
                    className="mr-4 mt-0"
                    type="radio"
                    id="current-table"
                    name="row-selection"
                    checked={selectedOption === EXPORT_OPTION.CURRENT_TAB}
                    onChange={() =>
                      setSelectedOption(EXPORT_OPTION.CURRENT_TAB)
                    }
                  />
                  <label
                    className="radio-btn-label mt-4"
                    htmlFor="current-table"
                  >
                    {tableName}
                  </label>
                </div>
                <div>
                  <input
                    className="mr-4 mt-12"
                    type="radio"
                    id="all-claims"
                    name="row-selection"
                    checked={selectedOption === EXPORT_OPTION.ALL_CLAIMS}
                    onChange={() => setSelectedOption(EXPORT_OPTION.ALL_CLAIMS)}
                  />
                  <label className="radio-btn-label mt-4" htmlFor="all-claims">
                    All Claims
                  </label>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center mb-24">
              {selectedOption === EXPORT_OPTION.ALL_CLAIMS && (
                <div>
                  <div className="dropdown-container">
                    <div className="dropdown__label">Select EOB Date Range</div>
                  </div>
                  <div className="row mb-12">
                    <div className="col-md-6">
                      <DatePicker
                        displayFormat="LL"
                        customInputIcon={
                          <img
                            className="form-icon"
                            src={calendar}
                            alt="Calendar"
                          />
                        }
                        isOutsideRange={(d) =>
                          isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                          d.isAfter(moment(filters.endDate), 'day')
                        }
                        date={
                          filters.startDate
                            ? moment(filters.startDate)
                            : moment()
                        }
                        placeholder="Start Date"
                        verticalSpacing={10}
                        onDateChange={(date) => {
                          if (!date) {
                            setError({
                              ...error,
                              startDate: null,
                            });
                          }
                          date = moment(date);
                          if (!date.isValid()) {
                            handleFilterChange({ value: null }, 'startDate');
                            return;
                          }
                          setError({
                            ...error,
                            startDate: null,
                          });
                          handleFilterChange(
                            { value: date.format(DATE_FORMAT_YYYY_MM_DD) },
                            'startDate'
                          );
                        }}
                        numberOfMonths={1}
                        orientation="horizontal"
                        block={true}
                      />
                      {error && error.startDate && (
                        <p className="error mt-8">{error.startDate}</p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <DatePicker
                        displayFormat="LL"
                        customInputIcon={
                          <img
                            className="form-icon"
                            src={calendar}
                            alt="Calendar"
                          />
                        }
                        isOutsideRange={(d) =>
                          isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                          d.isBefore(moment(filters.startDate), 'day')
                        }
                        date={
                          filters.endDate ? moment(filters.endDate) : moment()
                        }
                        placeholder="End Date"
                        verticalSpacing={10}
                        onDateChange={(date) => {
                          if (!date) {
                            setError({
                              ...error,
                              endDate: null,
                            });
                          }
                          date = moment(date);
                          if (!date.isValid()) {
                            handleFilterChange({ value: null }, 'endDate');
                            return;
                          }
                          setError({
                            ...error,
                            endDate: null,
                          });
                          handleFilterChange(
                            { value: date.format(DATE_FORMAT_YYYY_MM_DD) },
                            'endDate'
                          );
                        }}
                        numberOfMonths={1}
                        orientation="horizontal"
                        block={true}
                      />
                      {error && error.endDate && (
                        <p className="error mt-8">{error.endDate}</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex justify-content-flex-end">
              <button
                data-tip="Download CSV"
                data-place="bottom"
                onClick={
                  selectedOption === EXPORT_OPTION.CURRENT_TAB
                    ? onClick
                    : onExportAllClaims
                }
                className="ap-button ap-button--primary d-block justify-content-center width-150 height-46"
                disabled={isLoading || isExportingCSV}
              >
                {isLoading || isExportingCSV ? (
                  <LoadingBlockHelper isLoading={true} />
                ) : (
                  <div className="d-flex justify-content-center">
                    <BiDownload size={18} className="mr-8" />
                    Start Export
                  </div>
                )}
              </button>
            </div>
          </div>
        </AppealioPopup>
      )}
    </div>
  );
};

DownloadClaimsCsv.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default DownloadClaimsCsv;
