import { fetchSop } from 'API/ImportRules';
import {
  FETCH_SOP_FAILURE,
  FETCH_SOP_REQUEST,
  FETCH_SOP_SUCCESS,
} from './actionTypes';

export const fetchSopStart = (sopId) => ({
  type: FETCH_SOP_REQUEST,
  payload: { sopId },
});

export const fetchSopSuccess = (sopId, sopData) => ({
  type: FETCH_SOP_SUCCESS,
  payload: { sopId, sopData },
});

export const fetchSopFailure = (sopId, error) => ({
  type: FETCH_SOP_FAILURE,
  payload: { sopId, error },
});

export const fetchSopInfo =
  (sopId, bypassCache = false) =>
  async (dispatch, getState) => {
    const state = getState();
    const sopData = state.getIn(['sopStore', 'sopData', sopId]);

    if (!bypassCache && sopData) {
      return;
    }

    dispatch(fetchSopStart(sopId));

    try {
      const data = await fetchSop(sopId);
      dispatch(fetchSopSuccess(sopId, data));
    } catch (error) {
      dispatch(fetchSopFailure(sopId, error.message));
    }
  };
