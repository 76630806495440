import React from 'react';

import './style.css';

import ActionBar from '../RulesOverview/ActionBar';
import ActionBarLegend from '../RulesOverview/ActionBar/ActionBarLegend';

import assignedActions from '../../../../img/arrow-right-plus.svg';

import { formatNumber } from 'helpers/number';

const ActionOverView = ({ data, filters }) => {
  return (
    <div className="full-width">
      <div className="system-overview__widget system_overview__action-assigned-widget width-full">
        <div className="mr-8">
          <div className="d-flex ">
            <img src={assignedActions} className="mr-4" />
            <span className="system-overview__widget-count">
              {formatNumber(data?.totalAssignedActions)}
            </span>
          </div>
          <div className="mt-2">Actions Assigned</div>
        </div>
        <div className="system-overview-actions-completed">
          <div className="d-flex align-item-center">
            <span className="system-overview-actions-completed__label mr-4">
              {formatNumber(data?.totalActionsCompleted)}
            </span>
            <img
              src={assignedActions}
              className="system-overview-actions-completed__icon mr-4"
            />
            <span className="system-overview-actions-completed__label">
              Actions Completed
            </span>
          </div>
          <ActionBar
            type="small"
            value={data?.actions || []}
            searchData={filters?.search || []}
          />
        </div>
      </div>
      <ActionBarLegend className="mt-4" value={data?.actions || []} />
    </div>
  );
};

export default ActionOverView;
