import { useState, useEffect, useCallback } from 'react';

import { fetchDetailsForSop, fetchSopsForRule } from 'API/SopAPI';
import { extractInitialFormValuesForSop } from './utils';

/**
 * Fetches the details of SOP (Standard Operating Procedures) associated with a given SOP ID.
 *
 * @param {string} id - The ID of the SOP for which to fetch the details.
 * @returns {Object} - An object containing the loading state, error (if any), details,
 */
export const useFetchDetailsForSop = (id, dropdownOptions = {}) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [sopDetails, setSopDetails] = useState({});
  const [initialFormValues, setInitialFormValues] = useState({});

  const fetch = useCallback(async () => {
    setLoading(true);
    if (!id) {
      setInitialFormValues({});
      setSopDetails({});
      setLoading(false);
      return;
    }

    try {
      const { data } = await fetchDetailsForSop(id);
      setSopDetails(data);
      setInitialFormValues(
        extractInitialFormValuesForSop(data, dropdownOptions)
      );
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!id) {
      setLoading(false);
      return;
    }
    fetch();
  }, [id, fetch]);

  return {
    loading,
    error,
    sopDetails,
    fetch,
    setSopDetails,
    initialFormValues,
  };
};

/**
 * Fetches a list of Standard Operating Procedures (SOPs) associated with a given SOP ID.
 *
 * @param {string} id - The ID of the SOP for which to fetch the list.
 * @returns {Object} - An object containing the loading state, error (if any), the list of SOPs,
 * a function to fetch the SOP list, and a function to update the SOP list.
 */
export const useFetchSopList = (id) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [sopList, setSopList] = useState([]);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await fetchSopsForRule(id);
      setSopList(data?.sops);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!id) {
      setLoading(false);
      return;
    }
    fetch();
  }, [id, fetch]);

  return { loading, error, sopList, fetch, setSopList };
};
