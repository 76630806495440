import classnames from 'classnames';
import { BiX } from 'react-icons/bi';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { BsPencil } from 'react-icons/bs';
import { pickBy, identity } from 'lodash';
import ReactDOMServer from 'react-dom/server';

import emptyIcon from 'img/empty.svg';

import { camelizeKeys } from 'helpers/object';

import * as toast from 'components/Shared/toast';
import Pagination from 'components/common/pagination';
import SearchInput from 'components/common/input/SearchInput';
import { AppealioPopupWithFooter } from 'components/common/popup';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import ActionButton from 'components/common/actionButton/ActionButton';

import * as AccountsAPI from 'API/AccountSettingsAPI';

import { handleError } from 'helpers/errorHandler';

import { SETTINGS_PAGE_LIMIT, CONTACT_TYPE } from 'constants/appConstants';
import ChangeContactStatusPopup from './ChangeContactStatusPopup';

import { useFetchContacts } from './hook';
import AddPayerContactForm from '../../Organization/Payer/AddPayerContactForm';

const CONTACT_TYPE_LABEL = {
  [CONTACT_TYPE.RETURN_ADDRESS]: 'Return Contact',
  [CONTACT_TYPE.DESTINATION_ADDRESS]: 'Destination Contact',
};

const DataTipContent = (contact) => {
  const mappedContact = {
    Address1: contact.address1,
    Address2: contact.address2,
    City: contact.city,
    State: contact.state,
    ZIP: contact.zipcode,
  };

  const contactInfo = pickBy(mappedContact, identity);

  return (
    <div>
      {Object.entries(contactInfo).map(([key, value], idx) => (
        <div key={idx}>
          <strong>{key}</strong>: {value}
        </div>
      ))}
    </div>
  );
};

const Contacts = (props) => {
  const { type } = props;
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [addContactFormState, setAddContactFormState] = useState({
    isOpen: false,
    initialValues: {},
  });

  const [changeContactStatusPopup, setChangeContactStatusPopup] = useState({
    isOpen: false,
    contact: null,
    toDeactivate: true,
  });

  const {
    loading,
    contacts,
    totalContacts,
    fetch: fetchContacts,
  } = useFetchContacts(page, searchString, type);

  const contactLabel = CONTACT_TYPE_LABEL[type];
  const onPageChange = (page) => setPage(page);
  const pageCount = Math.ceil(totalContacts / SETTINGS_PAGE_LIMIT);

  const handleSearch = (e) => {
    setSearchString(e.target.value);
    setPage(1);
  };

  const clearSearch = () => setSearchString('');

  /**
   * Opens the Payer Contact form.
   * @param {Object} initialValues
   */
  const openContactForm = (initialValues = {}) => {
    setAddContactFormState({
      isOpen: true,
      initialValues,
    });
  };

  /**
   * Closes the Payer Contact form.
   */
  const closeContactForm = () => {
    setAddContactFormState({
      isOpen: false,
      initialValues: {},
    });
  };

  /**
   * Handles the Payer Contact form submit.
   * @param {Object} data
   */
  const handleContactFormSubmit = async (data) => {
    try {
      const contactIdToEdit = addContactFormState?.initialValues?.id;
      const isEdit = Boolean(contactIdToEdit);

      const contactData = {
        name: data?.name,
        address1: data?.address_1 || '',
        address2: data?.address_2 || '',
        state: data?.state || '',
        zipcode: data?.zipcode || '',
        city: data?.city || '',
        fax: data?.fax || '',
        email: data?.email || '',
        type,
      };

      if (isEdit) {
        await AccountsAPI.updateContact({
          ...contactData,
          id: contactIdToEdit,
        });
      } else {
        await AccountsAPI.createContact(contactData);
      }

      toast.success({
        title: 'Success',
        message: isEdit
          ? `${contactLabel} successfully updated`
          : `${contactLabel} successfully added`,
      });
      closeContactForm();
      fetchContacts();
    } catch (error) {
      handleError(error);
    }
  };

  /**
   * reset the payer contact for delete.
   */
  const resetChangeContactStatusPopup = () => {
    setChangeContactStatusPopup({
      isOpen: false,
      contact: null,
      toDeactivate: false,
    });
  };

  return (
    <div className="settings-content-wrapper" datacy="rendering-provider">
      <div className="d-flex row align-items-center justify-content--space-between settings-content__top-action">
        <div className="col-lg-3">
          <SearchInput
            onSearch={handleSearch}
            placeholder={`Search for ${contactLabel}`}
            onReset={clearSearch}
          />
        </div>
        <button
          className="ap-button ap-button--secondary ml-auto settings-add-button"
          datacy="settings-add-button"
          onClick={() => openContactForm()}
        >
          <span className="mr-4">+</span> Add {contactLabel}
        </button>
        <ReactTooltip effect="solid" multiline={true} />
      </div>
      <div className="row mt-12">
        <div className="col-lg-12">
          {loading ? (
            <LoadingBlockHelper
              className="dashboard-bottom-loader"
              isLoading={loading}
            />
          ) : contacts.length > 0 ? (
            <React.Fragment>
              <div className="row mb-16 create-acount-list-item-header">
                <div className="col-lg-3">Name</div>
                <div className="col-lg-3">Address (Mailing)</div>
                <div className="col-lg-2">Fax Number</div>
                <div className="col-lg-2">Email</div>
                <div className="col-lg-2"></div>
              </div>
              {contacts.map((contact, idx) => {
                const { address1, city, state, zipcode } = contact;
                const submissionAddress =
                  [address1, city, state, zipcode]
                    .filter(Boolean)
                    .join(', ')
                    .replace(/(^|\s)--(\s|$)/g, '$1--$2') || '--';
                const addressProps = {
                  ...((contact.address1 || contact.address2) && {
                    'data-tip': ReactDOMServer.renderToStaticMarkup(
                      DataTipContent(camelizeKeys(contact))
                    ),
                  }),
                };
                return (
                  <div
                    className={classnames(
                      'create-account-list-item row mb-16',
                      {
                        'create-account-list-item--inactive': !contact.isActive,
                      }
                    )}
                    key={idx}
                  >
                    <div className="col-lg-3">
                      <span data-tip={contact.name}>
                        {contact.name || '--'}
                      </span>
                    </div>
                    <div className="col-lg-3">
                      <span {...addressProps} data-html={true}>
                        {submissionAddress}
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <span data-tip={contact.fax}>{contact.fax || '--'}</span>
                    </div>
                    <div className="col-lg-2">
                      <span data-tip={contact.email}>
                        {contact.email || '--'}
                      </span>
                    </div>
                    <div className="col-lg-2 d-flex justify-content-flex-end">
                      {contact.isActive ? (
                        <div className="d-flex">
                          <ActionButton
                            Icon={BsPencil}
                            className="mr-16 ml-auto"
                            dataTip="Edit"
                            onClick={(e) => {
                              openContactForm(contact);
                            }}
                            iconClassName="appeal__action--appeal"
                            datacy="payer-edit-ActionButton"
                          />
                          <ActionButton
                            Icon={BiX}
                            className="mr-16 ap-button--action-delete"
                            dataTip="Deactivate"
                            onClick={(e) => {
                              setChangeContactStatusPopup({
                                isOpen: true,
                                contact,
                                toDeactivate: true,
                              });
                            }}
                            iconClassName="appeal__action--appeal"
                          />
                        </div>
                      ) : (
                        <div>
                          <button
                            onClick={(e) => {
                              setChangeContactStatusPopup({
                                isOpen: true,
                                contact,
                                toDeactivate: false,
                              });
                            }}
                            className="ap-button ap-button--secondary ml-auto settings-reactivate-button"
                          >
                            Reactivate
                          </button>
                        </div>
                      )}
                    </div>
                    <ReactTooltip effect="solid" place="top" multiline={true} />
                  </div>
                );
              })}
            </React.Fragment>
          ) : (
            <div className="no-data-container">
              <img src={emptyIcon} alt="No Data Found" />
              <h4>No Data Found</h4>
            </div>
          )}

          {!loading && totalContacts && pageCount > 1 ? (
            <div className="d-flex justify-content-center">
              <Pagination
                pageCount={pageCount}
                activePage={page}
                onPageChange={onPageChange}
                datacy="rendering-provider-Pagination"
              />
            </div>
          ) : null}
        </div>
      </div>
      {addContactFormState.isOpen && (
        <AppealioPopupWithFooter
          onClosePressed={closeContactForm}
          title={`Add ${contactLabel}`}
          isFooterOutside={false}
        >
          <AddPayerContactForm
            onSubmit={handleContactFormSubmit}
            initialValues={addContactFormState.initialValues}
            datacy="add-contact-form"
            label={contactLabel}
          />
        </AppealioPopupWithFooter>
      )}

      {/* Delete payer contact */}
      {changeContactStatusPopup.isOpen && (
        <ChangeContactStatusPopup
          onClosePressed={resetChangeContactStatusPopup}
          contact={changeContactStatusPopup.contact}
          title={`Are you sure you want to ${
            changeContactStatusPopup.toDeactivate ? 'deactivate' : 'activate'
          } this ${contactLabel}?`}
          onSubmitSuccess={() => {
            resetChangeContactStatusPopup();
            fetchContacts();
          }}
          toDeactivate={changeContactStatusPopup.toDeactivate}
          label={contactLabel}
        />
      )}
    </div>
  );
};

export default Contacts;
