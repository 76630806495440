import React from 'react';
import PropTypes from 'prop-types';

import AppealsAPI from 'API/AppealsAPI';

import { handleError } from 'helpers/errorHandler';

const LetterFormOption = (props) => {
  const {
    item,
    setPreviewLetterForm,
    onClickPreview,
    previewLabel = 'Preview',
  } = props;

  const handlePreviewClick = async (e) => {
    e.stopPropagation();
    if (onClickPreview) {
      onClickPreview(item);
      return;
    }

    if (item.type === 'AppealDocument') {
      setPreviewLetterForm({
        isOpen: true,
        fileUrl: item.attributes.link,
        item,
      });
      return;
    }
    try {
      const fileUrl = await AppealsAPI.getAppealLetterPdfPreviewById(item?.id);
      setPreviewLetterForm({
        isOpen: true,
        fileUrl,
        item,
      });
    } catch (error) {
      setPreviewLetterForm({
        isOpen: false,
        fileUrl: null,
        item,
      });
      handleError(error);
    }
  };

  return (
    <div className="duplicate-claim-popup__letter-pdf-form-option">
      <span>{item.label}</span>
      <span
        className="duplicate-claim-popup__letter-pdf-form-option--link"
        onClick={handlePreviewClick}
      >
        {previewLabel}
      </span>
    </div>
  );
};

LetterFormOption.propTypes = {
  item: PropTypes.object.isRequired,
};

export default LetterFormOption;
