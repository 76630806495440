import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AppealDetails from './AppealDetails';
import RightSlideOut from '../../Shared/RightSlideOut';

export default class SlidingAppealDetails extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onDimRegionPressed: PropTypes.func,
    appealID: PropTypes.string,
  };

  render() {
    return (
      <RightSlideOut
        isOpen={this.props.isOpen}
        onDimRegionPressed={this.props.onDimRegionPressed}
      >
        <AppealDetails appealId={this.props.appealID} defaultActiveTabKey="0" />
      </RightSlideOut>
    );
  }
}
