import _ from 'lodash';

import { sumMoney } from '../../../helpers/money';
import { MASK_DATE } from '../../../helpers/masks';

export const format = (value) => {
  if (!value) {
    return value;
  }

  let formattedCurrency = String(value);
  if (!_.startsWith(value, '$')) {
    formattedCurrency = '$' + value;
  }
  return formattedCurrency;
};

export const normalizeMoney = (value) => {
  if (!value) {
    return value;
  }

  // removes leading zeros
  const normalizedValue = value.replace('$', '').replace(/^(?!0$)0+/, '');

  return normalizedValue;
};

const moneyField = (field) => {
  return {
    ...field,
    format,
    normalize: normalizeMoney,
    isMoneyType: true,
  };
};

export const CPTFields = {
  columns: [
    {
      label: 'Serv Date:',
      placeholder: 'Optional',
      type: 'text',
      name: 'serv_date',
      className: 'cpt__table-column__serv-date',
      component: {
        name: 'maskedInput',
        parameters: {
          mask: MASK_DATE,
          type: 'string',
          size: '10',
        },
      },
    },
    {
      label: 'Procedure:',
      placeholder: 'Optional',
      name: 'cpt_code',
      className: 'cpt__table-column__procedure',
      maxLength: '6',
    },
    {
      label: 'Modifier:',
      placeholder: 'Optional',
      name: 'cpt_modifiers_code',
      maxLength: '10',
      isMultiValue: true,
    },
    {
      label: 'Quantity:',
      placeholder: 'Optional',
      type: 'number',
      name: 'quantity',
      isNumberType: true,
      max: '32767',
    },
    moneyField({
      label: 'Billed Amount:',
      placeholder: 'Optional',
      type: 'number',
      name: 'billed_amount',
    }),
    moneyField({
      label: 'Allowed Amount:',
      placeholder: 'Optional',
      type: 'number',
      name: 'amount_allowed',
    }),
    moneyField({
      label: 'Deductible:',
      placeholder: 'Optional',
      type: 'number',
      name: 'deductible',
    }),
    {
      label: 'Adjustment Code:',
      placeholder: 'Optional',
      type: 'text',
      name: 'adj_codes',
      isMultiValue: true,
      maxLength: '100',
    },
    moneyField({
      label: 'Coinsurance:',
      placeholder: 'Optional',
      type: 'number',
      name: 'co_insurance',
    }),
    moneyField({
      label: 'Payment Amount:',
      placeholder: 'Optional',
      type: 'number',
      name: 'payment_amount',
    }),
    {
      label: '',
      name: 'is_selected',
      type: 'checkbox',
      className: 'cpt__table-column__select',
      component: {
        name: 'flagCheck',
        parameters: {
          dataTip:
            'Mark claim line as “high priority”, if any. This will not affect your appeal in anyway, and is used for analytics purposes.',
        },
      },
    },
  ],

  allowsRemoval: true,
};

const procedureColumns = CPTFields.columns;
// all money-related columns except billed amount
const moneyColumns = procedureColumns
  .filter((e) => !!e.isMoneyType && e.name !== 'billed_amount')
  .map((e) => e.name);
const defaultValues = new Array(moneyColumns.length).fill(null);
export const defaultMoneyInitialValues = _.zipObject(
  moneyColumns,
  defaultValues
);

export const CPTFieldsDisabled = {
  columns: CPTFields.columns.map((e) => {
    return { ...e, disabled: true };
  }),
  allowsRemoval: false,
};

// serv_date, cpt_code, quantity, billed_amount
const indiciesToDisableInPartiallyDisabled = [0, 1, 2, 3];
export const CPTFieldsPartiallyDisabled = {
  columns: CPTFields.columns.map((e, index) => {
    return { ...e, disabled: index in indiciesToDisableInPartiallyDisabled };
  }),
  allowsRemoval: false,
};

export const readAmountsFromCPTForm = (cpts) => {
  const billedAmount = parseFloat(sumMoney(cpts, 'billed_amount'));
  const amountAllowed = parseFloat(sumMoney(cpts, 'amount_allowed'));
  const deductible = parseFloat(sumMoney(cpts, 'deductible'));
  const coInsurance = parseFloat(sumMoney(cpts, 'co_insurance'));
  const paymentAmount = parseFloat(sumMoney(cpts, 'payment_amount'));

  const amountAllowedDelta = parseFloat(sumMoney(cpts, 'amount_allowed_delta'));
  const deductibleDelta = parseFloat(sumMoney(cpts, 'deductible_delta'));
  const coInsuranceDelta = parseFloat(sumMoney(cpts, 'co_insurance_delta'));
  const paymentAmountDelta = parseFloat(sumMoney(cpts, 'payment_amount_delta'));

  const totalDeniedAmount = parseFloat(billedAmount - amountAllowed);

  const sums = {
    billedAmount,
    amountAllowed,
    amountAllowedDelta,
    deductible,
    deductibleDelta,
    coInsurance,
    coInsuranceDelta,
    paymentAmount,
    paymentAmountDelta,
    totalDeniedAmount,
  };

  return sums;
};

export const readDeniedAmountFromCPTForm = (cpts) => {
  const billedAmount = sumMoney(cpts, 'billed_amount');
  const amountAllowed = sumMoney(cpts, 'amount_allowed');

  return billedAmount - amountAllowed;
};
