import * as toast from '../components/Shared/toast';

const GENERIC_ERROR = 'Oops! Something went wrong';

const getErrorMessage = async (error) => {
  const response = error.response && (await error.response.json());

  if (
    response &&
    Array.isArray(response) &&
    response.every((err) => typeof err === 'string')
  ) {
    return response;
  }

  const errorMessage = (response && response.message) || error.message;

  return errorMessage;
};

/**
 * Generic error handler to handle error.
 *
 * @param {object} error
 * @param {{title, message}} options
 */
export const handleError = async (error, options = {}) => {
  const message = (await getErrorMessage(error)) || GENERIC_ERROR;

  toast.error({
    title: options?.title || 'Error',
    message: options?.message || message,
  });
};
