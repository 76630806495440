import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';

import * as toast from 'components/Shared/toast';
import Input from 'components/common/input';
import Button from 'components/common/button';
import Select from 'components/common/select/Select';
import './style.css';

import { CLAIM_STATUS_CHECK_CRON_OPTIONS } from 'constants/options';

const MAXIMUM_ATTEMPTS = {
  MIN: 1,
  MAX: 20,
};

const ScheduleForm = (props) => {
  const { setIsScheduleFormOpen, handleSubmit, onSubmit } = props;

  const handleFormSubmit = (formValues) => {
    const values = formValues.toJS();
    if (values.noOfRetries < MAXIMUM_ATTEMPTS.MIN) {
      toast.error({
        title: 'Error',
        message: `Number of retries can not be less than ${MAXIMUM_ATTEMPTS.MIN}`,
      });
      return;
    }

    if (values.noOfRetries === '') {
      toast.error({
        title: 'Error',
        message: 'Retries times can not be empty.',
      });
      return;
    }

    if (values.noOfRetries > MAXIMUM_ATTEMPTS.MAX) {
      toast.error({
        title: 'Error',
        message: `Retries times can not be more than ${MAXIMUM_ATTEMPTS.MAX}`,
      });
      return;
    }

    onSubmit(formValues);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="claim-status-tracker__schedule-form">
        <div className="d-flex align-items-center">
          <div className="claim-status-tracker__schedule-days">
            <Field
              component={Select}
              options={CLAIM_STATUS_CHECK_CRON_OPTIONS}
              name="schedule"
              label={'Repeat this Status Check'}
              placeholder=""
              reactSelectProps={{
                menuPlacement: 'auto',
              }}
              datacy="schedule-days"
            />
          </div>
          <div className="claim-status-tracker__schedule-times">
            <Field
              component={Input}
              name="noOfRetries"
              label="time(s)"
              className="custom-input"
              type="number"
              min={MAXIMUM_ATTEMPTS.MIN}
              max={MAXIMUM_ATTEMPTS.MAX}
            />
          </div>
        </div>
        <div className="claim-status-tracker__schedule-action">
          <Button type="primary-dark">Schedule</Button>
          <Button
            type="light"
            className="cancel-btn"
            onClick={() => setIsScheduleFormOpen(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

ScheduleForm.propTypes = {
  setIsScheduleFormOpen: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default reduxForm({
  form: 'add-claim-to-tracker-form',
  enableReinitialize: true,
  initialValues: {
    schedule: CLAIM_STATUS_CHECK_CRON_OPTIONS[0],
    noOfRetries: MAXIMUM_ATTEMPTS.MIN,
  },
})(ScheduleForm);
