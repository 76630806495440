import PropTypes from 'prop-types';
import { omit, isEqual } from 'lodash';
import React, { useState } from 'react';

import Button from 'components/common/button';
import Dropdown from 'components/Shared/Dropdown';
import { BUTTON_TYPE } from 'components/common/button/Button';
import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';

import closeIcon from 'img/close-modal.svg';
import settingsIcon from 'img/settings-black.svg';

import {
  SOURCE_OPTIONS,
  PM_NOTE_STATUS_OPTIONS,
  ACTION_LOG_TAB_VALUES,
} from 'constants/appConstants';

import { FILTERS_LABEL, DEFAULT_FILTERS } from './ActionLogs';

import { FILTER_KEYS } from './hooks';
import { REMINDER_STATUS_OPTIONS } from 'constants/options';

const ActionLogPopupFilter = (props) => {
  const {
    dropDownFilters,
    setShowFilters,
    filters,
    onFilterChange,
    filterOptions,
    logType,
    isAdminOrStaffUser,
  } = props;
  const [actionLogFilters, setActionLogFilters] = useState(filters);

  const keysToOmit = ['appliedRule', 'removalReason', 'search'];

  const isFilterEmpty = Object.values(omit(actionLogFilters, keysToOmit)).every(
    (x) => x === null || x === '' || x === undefined
  );

  const filtersWithoutOmittedKeys = (filters) => omit(filters, keysToOmit);
  const actionFiltersWithoutOmittedKeys = filtersWithoutOmittedKeys(filters);
  const actionLogPopupFiltersWithoutOmittedKeys =
    filtersWithoutOmittedKeys(actionLogFilters);
  const isApplyFilterDisabled = isEqual(
    Object.values(actionLogPopupFiltersWithoutOmittedKeys),
    Object.values(actionFiltersWithoutOmittedKeys)
  );

  const handleConfirmFilters = () => {
    onFilterChange({ ...actionLogFilters });
    setShowFilters(false);
  };

  const getSelectedValue = (options, value) => {
    return options.find((option) => option.value === value);
  };

  const popupTitle =
    logType.completed === ACTION_LOG_TAB_VALUES.PENDING
      ? 'More Pending Actions Filters'
      : 'More  Completed Actions Filters';

  const dropdownFilterOptions =
    logType.completed === ACTION_LOG_TAB_VALUES.PENDING
      ? dropDownFilters
      : [
          ...dropDownFilters.filter((option) => option !== 'agents'),
          'actionCompletedBy',
        ];

  return (
    <ScreenDimmerComponent>
      <div className="appealio-popup appealio-popup-filter">
        <div className="appealio-popup-filter__header">
          <div className="filter__header-title">
            <img src={settingsIcon} alt={'More filters'} />
            <h6>{popupTitle} </h6>
          </div>
          <div className="appealio-popup-filter__clear-wrapper">
            <div className="filter__header--reset-option">
              {!isFilterEmpty && (
                <p
                  className="denial-table__filter-clear text-decoration-underline"
                  onClick={() => {
                    onFilterChange({
                      ...DEFAULT_FILTERS,
                      appliedRule: filters.appliedRule,
                      search: filters.search,
                      removalReason: filters.removalReason,
                    });
                    setShowFilters(false);
                  }}
                >
                  Reset All
                </p>
              )}
            </div>
            <img
              alt="Close"
              onClick={() => setShowFilters(false)}
              className="close-icon"
              src={closeIcon}
            />
          </div>
        </div>
        <div className="row mb-12 appealio-popup-filter__options">
          {dropdownFilterOptions.map((filter, idx) => {
            const options = filterOptions[filter]?.data || [];
            const isFetching = filterOptions[filter]?.isFetching;
            if (filter === 'practices' && options.length < 3) return '';
            return (
              <div className="col-md-6 mb-12" key={idx}>
                <Dropdown
                  options={options}
                  onChange={(selectedOption) => {
                    setActionLogFilters({
                      ...actionLogFilters,
                      [filter]: selectedOption.value,
                    });
                  }}
                  label={FILTERS_LABEL[filter]}
                  key={idx}
                  placeholder="All"
                  isLoading={isFetching}
                  value={options.find(
                    (option) => option.value === actionLogFilters[filter]
                  )}
                  datacy="filters-Dropdown"
                />
              </div>
            );
          })}
          {isAdminOrStaffUser && (
            <div className="col-md-6 mb-12">
              <Dropdown
                options={SOURCE_OPTIONS}
                placeholder="All"
                label="Appeal/Record Created"
                onChange={(selectedOption) => {
                  setActionLogFilters({
                    ...actionLogFilters,
                    source: selectedOption.value,
                  });
                }}
                value={getSelectedValue(
                  SOURCE_OPTIONS,
                  actionLogFilters.source
                )}
              />
            </div>
          )}
          {logType.completed === ACTION_LOG_TAB_VALUES.COMPLETED && (
            <>
              <div className="col-md-6 mb-12">
                <Dropdown
                  options={PM_NOTE_STATUS_OPTIONS}
                  placeholder="All"
                  label="PM Note Status"
                  onChange={(selectedOption) => {
                    setActionLogFilters({
                      ...actionLogFilters,
                      [FILTER_KEYS.pmNotesCopied]: selectedOption.value,
                    });
                  }}
                  value={getSelectedValue(
                    PM_NOTE_STATUS_OPTIONS,
                    actionLogFilters[FILTER_KEYS.pmNotesCopied]
                  )}
                />
              </div>
              <div className="col-md-6 mb-12">
                <Dropdown
                  options={REMINDER_STATUS_OPTIONS}
                  placeholder="All"
                  label="Reminder(s)"
                  onChange={(selectedOption) => {
                    setActionLogFilters({
                      ...actionLogFilters,
                      reminderStatus: selectedOption.value,
                    });
                  }}
                  value={getSelectedValue(
                    REMINDER_STATUS_OPTIONS,
                    actionLogFilters?.reminderStatus
                  )}
                />
              </div>
            </>
          )}
        </div>

        <div className="appealio-popup-filter-buttons">
          <Button
            title="Apply"
            type={BUTTON_TYPE.SECONDARY}
            className="ap-button  ap-button--block"
            onClick={handleConfirmFilters}
            datacy="dataCy-confirm-filters"
            disabled={isApplyFilterDisabled}
          />
        </div>
      </div>
    </ScreenDimmerComponent>
  );
};

ActionLogPopupFilter.propTypes = {
  dropDownFilters: PropTypes.array,
  setShowFilters: PropTypes.func,
  filters: PropTypes.object,
  onFilterChange: PropTypes.func,
  filterOptions: PropTypes.object,
};

export default ActionLogPopupFilter;
