import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.css';

const CardContainer = (props) => {
  const { children, className } = props;

  return (
    <div
      className={classnames('card-container', className)}
      datacy="card-container"
    >
      {children}
    </div>
  );
};

CardContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

export default CardContainer;
