import PropTypes from 'prop-types';
import { isEmpty, values } from 'lodash';
import React, { useEffect, useState, forwardRef } from 'react';

import Widget from '../Widget';
import DonutChart from './DonutChart';
import ReasonList from './ReasonList';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import EmptyWidgetPlaceholder from 'components/ApDashboard/Manager/EmptyWidgetPlaceholder';

import { useFetchDenialsAndUnderpayments } from './hooks';

const DenialsAndUnderpayments = forwardRef((props, ref) => {
  const [viewChartBy, setViewChartBy] = useState(null);
  const { filters, isFetchingData, setIsFetchingData } = props;
  const { error, loading, denialsAndUnderpayments } =
    useFetchDenialsAndUnderpayments(filters, viewChartBy);

  useEffect(() => {
    setIsFetchingData({
      ...isFetchingData,
      isFetchingDenialsAndUnderPayments: loading,
    });
    // eslint-disable-next-line
  }, [loading]);

  const resetButton = (
    <button ref={ref} onClick={() => setViewChartBy(null)} className="d-none">
      reset
    </button>
  );

  if (error) {
    return (
      <Widget
        widgetTitle={{
          title: 'Denial Amount by Category and Reason',
          titleClassName: 'v3__widget-title--lg',
        }}
        info="Denial Amount by Category and Reason"
      >
        <EmptyWidgetPlaceholder title="Opps! Something went wrong!" />
      </Widget>
    );
  }

  const isDenialsAndUnderpaymentsDataEmpty =
    isEmpty(denialsAndUnderpayments.codes) &&
    values(denialsAndUnderpayments.groups).every(isEmpty);
  if (isDenialsAndUnderpaymentsDataEmpty && !loading) {
    return (
      <Widget
        widgetTitle={{
          title: 'Denial Amount by Category and Reason',
          titleClassName: 'v3__widget-title--lg',
        }}
        info="Denial Amount by Category and Reason"
      >
        <EmptyWidgetPlaceholder title="No Data Available " />
      </Widget>
    );
  }

  return (
    <Widget
      widgetTitle={{
        title: 'Denial Amount by Category and Reason',
        titleClassName: 'v3__widget-title--lg',
      }}
    >
      {loading ? (
        <div className="row equal v3__widget-min-height">
          <LoadingIndicator showing={true} />
        </div>
      ) : (
        <div className="row equal">
          <div className="col-lg-8 ap-donut-chart-container">
            <DonutChart
              groups={denialsAndUnderpayments && denialsAndUnderpayments.groups}
              viewChartBy={viewChartBy}
              setViewChartBy={setViewChartBy}
            />
          </div>
          <div className="col-lg-4 ap-reason-list-container">
            <ReasonList
              codes={denialsAndUnderpayments && denialsAndUnderpayments.codes}
            />
          </div>
          {resetButton}
        </div>
      )}
    </Widget>
  );
});

DenialsAndUnderpayments.propTypes = {
  filters: PropTypes.object.isRequired,
};

export default DenialsAndUnderpayments;
