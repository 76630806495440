/**
 * Title Case converts an entire string into title case.
 *
 * @param {str} {String} A string that contains what you would like converted into title case.
 *
 * return string converted into title case.
 *
 * How to use > toTitleCase([your string here])
 */
export const toTitleCase = (str) =>
  str.replace(
    /\w\S*/g,
    (txt) => `${txt.charAt(0).toUpperCase()}${txt.substr(1).toLowerCase()}`
  );
