import React, { useMemo } from 'react';
import classNames from 'classnames';
import { AiOutlineFolder } from 'react-icons/ai';

import UsersList from './UsersList';
import { AppealioPopupWithFooter } from 'components/common/popup';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';

import { useFetchTeam } from './hooks';

const UsersListPopup = ({
  teamId,
  usersOptions,
  onClosePressed,
  teamName,
  isAdminUser,
  onAddRemoveUserClick,
}) => {
  const { team, loading } = useFetchTeam(teamId);

  const teamUsers = team?.users || [];
  const teamUsernames = teamUsers.map((user) => user.username);

  const teamMembersList = usersOptions?.data.filter(({ value }) =>
    teamUsernames.includes(value)
  );

  const hasMembersInTeam = teamMembersList.length > 0;

  const Title = useMemo(
    () => (
      <div>
        Add/Remove Users from <span className="fw-bold">{teamName}</span>
      </div>
    ),
    [teamName]
  );

  return (
    <AppealioPopupWithFooter
      className={classNames(
        'appealio-popup--v3 appealio-popup--v3--overflow-hidden',
        {
          'empty-teams-popup': !hasMembersInTeam,
        }
      )}
      onClosePressed={onClosePressed}
      title={
        <div className="d-flex align-items-center">
          <AiOutlineFolder size="22" className="mr-4" />
          {Title}
        </div>
      }
      isFooterOutside={false}
    >
      {loading ? (
        <LoadingBlockHelper className="mt-8" isLoading />
      ) : (
        <>
          <div className="d-flex align-items-center justify-content--space-between">
            {!hasMembersInTeam && (
              <span>No Users are yet assigned to this Team.</span>
            )}
            {isAdminUser && (
              <button
                onClick={() => onAddRemoveUserClick(team)}
                className="ap-button ap-button--secondary ml-auto settings-add-button"
              >
                Add/Remove User(s)
              </button>
            )}
          </div>
          {hasMembersInTeam && <UsersList users={teamMembersList} />}
        </>
      )}
    </AppealioPopupWithFooter>
  );
};

export default UsersListPopup;
