import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ChartistGraph from 'react-chartist';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';

import { formatToMoney } from 'helpers/money';

import { TIME_PERIOD_OPTIONS } from 'constants/options';

const options = {
  axisX: {
    offset: 60,
    position: 'end',
    labelOffset: {
      x: 0,
      y: 22,
    },
    showLabel: true,
    showGrid: false,
    labelInterpolationFnc: (value, index) => value,
    scaleMinSpace: 30,
    onlyInteger: false,
  },
  axisY: {
    offset: 40,
    position: 'start',
    showLabel: true,
    showGrid: true,
    labelInterpolationFnc: function (value, index) {
      return value === 50 ? `${value}%` : '';
    },
    scaleMinSpace: 30,
    onlyInteger: false,
  },
  width: '100%',
  height: '400px',
  high: 100,
  low: 0,
  referenceValue: null,
  seriesBarDistance: 0,
  stackBars: true,

  horizontalBars: false,
  reverseData: false,
  showGridBackground: true,
  classNames: {
    chart: 'ct-chart-bar',
    horizontalBars: 'ct-horizontal-bars',
    label: 'ct-label',
    labelGroup: 'ct-labels__custom ct-labels',
    series: 'ct-series',
    bar: 'ct-bar',
    grid: 'ct-grid',
    gridGroup: 'ct-grids',
    gridBackground: 'ct-grid-background',
    vertical: 'ct-vertical',
    horizontal: 'ct-horizontal',
    start: 'ct-start',
    end: 'ct-end',
  },
  plugins: [
    ChartistTooltip({
      tooltipFnc: (meta, value) =>
        `${value}% (${formatToMoney(Math.round(meta))})`,
    }),
  ],
};

const type = 'Bar';

const StackGraph = (props) => {
  const { claimsPerformance, total, interval, claimsCountInfo } = props;
  const [data, setData] = useState({});
  useEffect(() => {
    const prepareLabels = (claimsPerformance) => {
      const labels = [
        `All \n ( ${
          !isEmpty(claimsCountInfo)
            ? claimsCountInfo?.count.toLocaleString()
            : 'N/A'
        } )`,
      ];
      claimsPerformance.forEach((claim) => {
        const countMap = !isEmpty(claimsCountInfo)
          ? claimsCountInfo.breakdown.find(({ label }) => label === claim.label)
          : null;
        labels.push(
          `${claim.label} \n ( ${
            countMap ? countMap.count.toLocaleString() : 'N/A'
          } )`
        );
      });
      return labels;
    };
    const prepareSeries = (claimsPerformance, total, interval) => {
      const allowedAmountSeries = [
        {
          value: total.unclassifiedAdjustmentPercentage,
          meta: total.totalAllowedAmount,
        },
        ...claimsPerformance.map(({ total }) => ({
          value: total.unclassifiedAdjustmentPercentage.toFixed(2),
          meta: total.totalAllowedAmount,
        })),
      ];
      const deniedAndUnderpaymentSeries = [
        {
          value: total.denialsAndUnderpaymentsPercentage,
          meta: total.totalDauAdj,
        },
        ...claimsPerformance.map(({ total }) => ({
          value: total.denialsAndUnderpaymentsPercentage.toFixed(2),
          meta: total.totalDauAdj,
        })),
      ];
      const routineAdjustmentsSeries = [
        {
          value: total.routineAdjustmentPercentage,
          meta: total.totalRoutineAdj,
        },
        ...claimsPerformance.map(({ total }) => ({
          value: total.routineAdjustmentPercentage.toFixed(2),
          meta: total.totalRoutineAdj,
        })),
      ];

      return [
        {
          value: allowedAmountSeries,
          className: classnames('ap-ct-series ap-ct-series__green', {
            'ap-ct-series--lg': TIME_PERIOD_OPTIONS[1].value === interval,
          }),
        },
        {
          value: deniedAndUnderpaymentSeries,
          className: classnames('ap-ct-series ap-ct-series__red', {
            'ap-ct-series--lg': TIME_PERIOD_OPTIONS[1].value === interval,
          }),
        },
        {
          value: routineAdjustmentsSeries,
          className: classnames('ap-ct-series ap-ct-series__grey', {
            'ap-ct-series--lg': TIME_PERIOD_OPTIONS[1].value === interval,
          }),
        },
      ];
    };
    setData({
      labels: prepareLabels(claimsPerformance),
      series: prepareSeries(claimsPerformance, total, interval),
    });
    // eslint-disable-next-line
  }, [claimsCountInfo, claimsPerformance]);

  function handleDrawEvent(e) {
    if (e.type === 'bar') {
      const { index, series } = e;
      const value = series.value[index];

      if (value?.meta) {
        e.element._node.setAttribute('ct:meta', value.meta);
      }
    }
  }

  return (
    <div className="ap-stack-chart">
      <div className="ap-stack-chart__label">
        <p>Time Period</p>
        <p>Claim Count</p>
      </div>
      <ChartistGraph
        data={data}
        options={options}
        type={type}
        listener={{
          draw: handleDrawEvent,
        }}
      />
    </div>
  );
};

StackGraph.propTypes = {
  claimsPerformance: PropTypes.array.isRequired,
  total: PropTypes.object.isRequired,
  interval: PropTypes.string,
};

export default StackGraph;
