import { useEffect, useState } from 'react';

import {
  fetchAvailityClaimStatusPayerConfig,
  fetchAvailityClaimStatusSearchPrefillData,
} from 'API/PayerAPI';

export const useFetchClaimStatusInitialData = (
  ediPayerId,
  claimControlNumber,
  appealId
) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [availityEdiPayerId, setAvailityEdiPayerId] = useState(null);
  const [prefillData, setPrefillData] = useState({});
  const [payerInputFields, setPayerInputFields] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        const [payerConfigResponse, _prefillData] = await Promise.all([
          fetchAvailityClaimStatusPayerConfig(ediPayerId),
          fetchAvailityClaimStatusSearchPrefillData({
            appeal_id: appealId,
            claim_control_number: claimControlNumber,
          }),
        ]);
        setPayerInputFields(payerConfigResponse.fields);
        setAvailityEdiPayerId(payerConfigResponse.availityEdiPayerId);
        setPrefillData({
          ..._prefillData,
        });
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
  }, [ediPayerId, claimControlNumber, appealId]);

  return {
    isLoading,
    error,
    payerInputFields,
    prefillData,
    availityEdiPayerId,
  };
};
