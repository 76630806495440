import { debounce } from 'lodash';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { BsPencil } from 'react-icons/bs';
import { bindActionCreators } from 'redux';
import ReactDOMServer from 'react-dom/server';
import { MdInfoOutline } from 'react-icons/md';
import React, { useState, useCallback } from 'react';

import emptyIcon from 'img/empty.svg';

import AddPayer from './AddPayer';
import Pagination from 'components/common/pagination';
import AppealDeadlineInput from './AppealDeadlineInput';
import SearchInput from 'components/common/input/SearchInput';
import TextHighlighter from 'components/common/textHighlighter';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import ActionButton from 'components/common/actionButton/ActionButton';

import { SETTINGS_PAGE_LIMIT } from 'constants/appConstants';
import { SEARCH_QUERY_CHARACTERS_OFFSET } from '../constants';

import { useFetchPayers, useFetchPayerIdentifiers } from '../hook';

import { isClientAdmin, isSuperUserAndStaffUser } from 'Auth/AuthUtils';
import { getUserInfo } from 'redux/reducers/loginStore';

import { fetchDenialsPayerOptions } from 'redux/actions/dropdownOptionsActions';

export const DEADLINE_LIMIT = {
  MAX: 365,
  MIN: 1,
};

const DataTipContent = (content) => {
  if (!Array.isArray(content) || content.length === 0) {
    return '';
  }

  return (
    <div>
      <ul style={{ paddingLeft: 0, listStyle: 'none' }}>
        {content.map((alias, idx) => (
          <li key={idx}>{alias}</li>
        ))}
      </ul>
    </div>
  );
};

const renderArray = (array) => {
  if (
    !Array.isArray(array) ||
    array.length === 0 ||
    (array.length === 1 && array?.[0] === '')
  ) {
    return '--';
  }
  return array.join(', ');
};

const Payer = (props) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [payerToUpdate, setPayerToUpdate] = useState(null);
  const [isPayerPopupOpen, setIsPayerPopupOpen] = useState(false);
  const { loading, payers, totalPayers, fetch } = useFetchPayers(page, search);

  const refetchPayers = () => {
    fetch();
    props.actions.fetchDenialsPayerOptions(true);
  };

  const {
    payerIdentifiersOptions,
    loading: isPayerIdentifiersLoading,
    addNewPayerIdentifierOption,
  } = useFetchPayerIdentifiers();

  const addPayerIdentifierOption = (option) => {
    addNewPayerIdentifierOption(option);
  };

  const onClosePressed = () => {
    setIsPayerPopupOpen(false);
  };

  const onPageChange = (page) => setPage(page);
  const pageCount = Math.ceil(totalPayers / SETTINGS_PAGE_LIMIT);
  const handleSearch = (event) => {
    setPage(1);
    setSearch(event.target?.value);
  };

  const clearSearch = () => setSearch('');

  const onSearch = useCallback((args) => debounce(handleSearch, 800)(args), []);

  return (
    <div className="settings-content-wrapper">
      <p className="setting-content__description"></p>
      <div className="d-flex align-items-center justify-content--space-between settings-content__top-action">
        <SearchInput
          placeholder="Search by Payer"
          onSearch={onSearch}
          onReset={clearSearch}
          datacy="search-for-payer-SearchInput"
        />
        {isClientAdmin(props.userInfo) && (
          <button
            onClick={() => {
              setPayerToUpdate(null);
              setIsPayerPopupOpen(true);
            }}
            className="ap-button ap-button--secondary ml-auto settings-add-button"
            datacy="settings-add-button"
          >
            <span className="mr-4">+</span> Add Payer
          </button>
        )}
      </div>

      <div className="row mt-12 ap-card-list-wraper">
        <div className="col-lg-12">
          {loading && loading ? (
            <LoadingBlockHelper
              className="dashboard-bottom-loader"
              isLoading={loading}
              datacy="payer-LoadingBlockHelper"
            />
          ) : payers.length > 0 ? (
            <React.Fragment>
              <div className="row mb-16 create-acount-list-item-header">
                <div className="col-lg-2">Payer Name</div>
                <div className="col-lg-2  d-flex align-items-center">
                  Appeal Deadline (Level 1)
                  <MdInfoOutline
                    size="18"
                    className="ml-4"
                    data-for="tooltip-status-info"
                    data-tip={
                      'Click on Appeal Deadline to update the Level 1 Appeal Deadline. This ranges from 1-365 days. '
                    }
                  />
                </div>
                <div className="col-lg-5">Payer Alias(es)</div>
                <div className="col-lg-2">EDI Payer IDs</div>
                <div className="col-lg-1">&nbsp;</div>
                <ReactTooltip
                  effect="solid"
                  place="top"
                  multiline={true}
                  id="tooltip-status-info"
                  className="info-header__tooltip"
                />
              </div>
              {payers.map((payer, idx) => {
                const alias = renderArray(payer.alias);
                return (
                  <div className="create-account-list-item row mb-16" key={idx}>
                    <div className="col-lg-2">
                      <span data-tip={payer.name}>
                        {payer.name &&
                        search?.length >= SEARCH_QUERY_CHARACTERS_OFFSET ? (
                          <TextHighlighter text={payer.name} query={search} />
                        ) : (
                          <span>{payer.name}</span> || '--'
                        )}
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <AppealDeadlineInput
                        idx={idx}
                        payer={payer}
                        fetchPayers={fetch}
                        disabled={!isClientAdmin(props.userInfo)}
                      />
                    </div>
                    <div className="col-lg-5">
                      <span
                        data-tip={ReactDOMServer.renderToStaticMarkup(
                          DataTipContent(payer.alias)
                        )}
                        data-html={true}
                      >
                        {alias.substring(0, 100)}
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <span
                        data-tip={ReactDOMServer.renderToStaticMarkup(
                          DataTipContent(payer.identifiers)
                        )}
                        data-html={true}
                      >
                        {renderArray(payer.identifiers)}
                      </span>
                    </div>
                    {isClientAdmin(props.userInfo) && (
                      <div className="col-lg-1 d-flex justify-content-flex-end">
                        <ActionButton
                          Icon={BsPencil}
                          className="mr-16 ml-auto"
                          dataTip="Edit"
                          onClick={(e) => {
                            setPayerToUpdate(payer);
                            setIsPayerPopupOpen(true);
                          }}
                          iconClassName="appeal__action--appeal"
                          datacy="payer-edit-ActionButton"
                        />
                      </div>
                    )}
                    <ReactTooltip effect="solid" multiline={true} />
                  </div>
                );
              })}
            </React.Fragment>
          ) : (
            <div className="no-data-container">
              <img src={emptyIcon} alt="No Data Found" />
              <h4>No Data Found</h4>
            </div>
          )}
        </div>
      </div>
      {isPayerPopupOpen && (
        <AddPayer
          onClosePressed={onClosePressed}
          payerToUpdate={payerToUpdate}
          refetchPayers={refetchPayers}
          datacy="payer-AddPayer"
          payerIdentifiersOptions={payerIdentifiersOptions}
          isPayerIdentifiersLoading={isPayerIdentifiersLoading}
          isSuperUser={isSuperUserAndStaffUser(props.userInfo)}
          addPayerIdentifierOption={addPayerIdentifierOption}
        />
      )}

      {!loading && totalPayers && pageCount > 1 ? (
        <div className="d-flex justify-content-center">
          <Pagination
            pageCount={pageCount}
            activePage={page}
            onPageChange={onPageChange}
            datacy="payer-Pagination"
          />
        </div>
      ) : null}
    </div>
  );
};

Payer.propTypes = {};

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);

  return {
    userInfo,
  };
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchDenialsPayerOptions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payer);
