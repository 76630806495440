import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';

import PatientInfoForm from './PatientInfoForm';
import { getUserInfo } from '../../../redux/reducers/loginStore';
import LoadingBlockHelper from '../../Shared/LoadingBlockHelper';
import { getAppealById } from '../../../redux/actions/createAppealActions';
import {
  getCurrentAppealID,
  getCurrentAppealPreFillData,
} from '../../../redux/reducers/createAppealStore';
import {
  isAppealReadyToUse,
  getAppealFromState,
  getPatientInfoInitialValuesFromAppealState,
  getPatientInfoPreFillInitialValues,
} from '../../../redux/reducers/AppealStore';

class PatientInfoContainer extends Component {
  componentDidMount() {
    const currentAppealID = this.props.currentAppealID;
    // if it's null it means we just started creating appeal
    // in other case we have to prefill form
    if (currentAppealID) {
      this.props.actions.getAppealById(currentAppealID);
    }
  }

  render() {
    return (
      <div>
        {this.renderForm()}
        {this.renderSpinner()}
      </div>
    );
  }

  renderForm() {
    if (this.props.isLoading === false) {
      return <PatientInfoForm initialValues={this.props.initialValues} />;
    }
  }

  renderSpinner() {
    if (this.props.isLoading === true) {
      return (
        <div className="row appeal-box__row-no-padding">
          <LoadingBlockHelper isLoading={true} />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  const currentAppealID = getCurrentAppealID(state);
  const userInfo = getUserInfo(state);
  const userPractices = _.get(userInfo, 'relatedPractices', []);

  if (!currentAppealID) {
    const preFillData = getCurrentAppealPreFillData(state);
    return {
      currentAppealID,
      isLoading: false,
      initialValues: {
        ...getPatientInfoPreFillInitialValues(preFillData, userPractices),
        agent: userInfo.id,
      },
    };
  }

  const isAppealReady = isAppealReadyToUse(state, currentAppealID);

  if (!isAppealReady) {
    return {
      currentAppealID,
      isLoading: true,
    };
  } else {
    // we came back to patient info from back button
    const appealState = getAppealFromState(state, currentAppealID);
    const initialValues =
      getPatientInfoInitialValuesFromAppealState(appealState);
    if (!_.has(initialValues, 'agent') || _.isEmpty(initialValues.agent)) {
      initialValues.agent = userInfo.id;
    }
    return {
      currentAppealID,
      isLoading: false,
      appeal: appealState.appeal,
      initialValues,
    };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getAppealById }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientInfoContainer);
