import { Map } from 'immutable';

import { CLOSE_RPA_WIDGET, OPEN_RPA_WIDGET } from 'redux/actions/actionTypes';

const initialState = Map({
  isWidgetOpen: false,
  widgetType: null,
  widgetConfig: null,
});

export const rpaWidgetStore = (state = initialState, action = {}) => {
  switch (action.type) {
    case OPEN_RPA_WIDGET:
      return state
        .set('isWidgetOpen', true)
        .set('widgetType', action.payload.widgetType)
        .set('widgetConfig', action.payload.widgetConfig);
    case CLOSE_RPA_WIDGET:
      return state
        .set('isWidgetOpen', false)
        .set('widgetType', null)
        .set('widgetConfig', null);
    default:
      return state;
  }
};
