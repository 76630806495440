import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import React, { useState, useRef } from 'react';

import Button from 'components/common/button';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import SelectFileToUploadButton from 'components/CreateAppeal/DocumentsUpload/SelectFileToUploadButton';

import fileIcon from 'img/file.svg';
import alertIcon from 'img/alert.svg';
import xIcon from 'img/close-copy.svg';
import placeholderImg from 'img/document-drop.png';

import './style.css';

import { validateManualClaimCSV } from 'API/ManualClaimAPI';

const FileUpload = ({ onSuccess, onError }) => {
  const dropzoneRef = useRef();

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onDrop = (acceptedFiles) => {
    const csvFile = acceptedFiles.find(
      (file) => file.type === 'text/csv' || file.name.endsWith('.csv')
    );
    if (!csvFile) {
      setErrorMessage('Only CSV file is allowed.');
      return;
    }
    setFile(csvFile);
    setErrorMessage('');
  };

  const onFileCancelClicked = (e) => {
    e.stopPropagation();
    setFile(null);
  };

  const handleFileUpload = async () => {
    if (!file) {
      setErrorMessage('No file selected for upload.');
      return;
    }

    setLoading(true);
    try {
      const data = await validateManualClaimCSV(file);
      setLoading(false);
      onSuccess(data);
    } catch (error) {
      const errResponse = await error?.response?.json();
      setErrorMessage(
        errResponse?.error || 'Failed to upload file. Please try again.'
      );
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && (
        <div className="file-upload-box">
          <LoadingBlockHelper
            useProgressBar
            isLoading={loading}
            loadingMessage="Checking CSV Format"
          />
        </div>
      )}

      {!loading && errorMessage && (
        <React.Fragment>
          <div className="file-upload-box">
            <div className="d-flex flex-direction--column align-items-center">
              <img src={alertIcon} alt="Alert Icon" />
              <div className="file-upload-error-msg-container">
                {!['More than 500 rows in CSV', 'Invalid CSV'].includes(
                  errorMessage
                ) && <div>Incorrect File Format:</div>}
                <div>{errorMessage}</div>
              </div>
              <div>Please check your file and reupload to Appealio.</div>
            </div>
          </div>
          <div className="d-flex justify-content-flex-end">
            <Button
              title="Back to Upload"
              className="ap-button--secondary justify-content-center"
              onClick={() => setErrorMessage('')}
            />
          </div>
        </React.Fragment>
      )}

      {!loading && !errorMessage && (
        <div>
          <p className="file-upload-info-text">
            Acceptable File Formats: CSV only
            <br />
            Total rows in CSV cannot exceed 500 rows.
          </p>
          <Dropzone
            ref={dropzoneRef}
            onDrop={onDrop}
            multiple={false}
            accept="text/csv"
            disableClick={true}
            style={{ border: 'none', padding: 0 }}
          >
            <div
              className={classNames('file-upload-box', {
                'file-upload-box--has-file': file,
              })}
            >
              {file ? (
                <div className="row">
                  <div className="file-upload-file-icon">
                    <img alt="file icon" src={fileIcon} />
                  </div>
                  <div className="file_item-content">
                    <span className="file__item-name">{file.name}</span>
                    <span className="file__item-cancel">
                      <img
                        alt="Cancel Button Icon"
                        onClick={onFileCancelClicked}
                        src={xIcon}
                      />
                    </span>
                    <div className="mt-20 mb-20">
                      <progress
                        className="file__item-progress"
                        value="100"
                        max="100"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <img
                    alt="Empty Placeholder"
                    src={placeholderImg}
                    width="150"
                  />
                  <div>
                    <span className="section-header">Drag & Drop</span>
                    <br />
                    <span className="file-upload-description">
                      Drag your file here or
                    </span>
                    <SelectFileToUploadButton
                      onClick={() => dropzoneRef.current.open()}
                      btnTitle="Select CSV File to Upload"
                    />
                  </div>
                </div>
              )}
            </div>
          </Dropzone>
          <div className="d-flex justify-content-flex-end">
            <Button
              title="Continue"
              className="ap-button--secondary justify-content-center"
              disabled={!file || loading}
              onClick={handleFileUpload}
            />
          </div>
        </div>
      )}
    </div>
  );
};

FileUpload.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default FileUpload;
