import React from 'react';

const CrossWalkInfo = ({ data }) => {
  const formattedData = data.reduce((acc, cur) => {
    const keys = Object.keys(cur);
    const crossWalkData = keys.filter((key) => key.endsWith('Desc'));
    const formattedValues = crossWalkData.map((descriptionKey) => {
      const codeKey = descriptionKey.replace('Desc', '');
      const code = cur[codeKey];
      const description = cur[descriptionKey];

      return {
        code,
        description,
      };
    });

    return [...acc, ...formattedValues];
  }, []);

  return (
    <div className="cross-walk-info mb-20">
      {formattedData.map((item, index) => (
        <div key={index}>
          <p className="mb-4">
            <span className="text-bold">{item.code}</span>
            <span key={index}> : {item.description}</span>
          </p>
        </div>
      ))}
    </div>
  );
};

export default CrossWalkInfo;
