import { Serializer as JSONAPISerializer } from 'jsonapi-serializer';

export const DocumentSerializer = new JSONAPISerializer('AppealDocument', {
  attributes: [
    'linkPath',
    'docType',
    'preview',
    'submission_id',
    'rank',
    'documentCategory',
  ],
  pluralizeType: false,
  keyForAttribute: 'underscore_case',
});
