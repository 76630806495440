import {
  ENTER_KEY,
  UP_ARROW_KEY,
  DOWN_ARROW_KEY,
  FIRST_ALPHA_KEY,
  LAST_ALPHA_KEY,
  FIRST_NUMERIC_KEY,
  LAST_NUMERIC_KEY,
} from '../../helpers/keyCodes';

export const dropdownKeyDown = (
  e,
  dropdownOptions,
  selectedIndex,
  onDropdownFilterChange,
  resetDropdown,
  setSearchString,
  searchString
) => {
  // Autocomplete Functionality
  if (setSearchString) {
    const keyIsAlphabetical =
      e.keyCode >= FIRST_ALPHA_KEY && e.keyCode <= LAST_ALPHA_KEY;
    const keyIsNumerical =
      e.keyCode >= FIRST_NUMERIC_KEY && e.keyCode <= LAST_NUMERIC_KEY;
    if (keyIsAlphabetical || keyIsNumerical) {
      const charPressed = String.fromCharCode(e.keyCode);
      let newSearchString = searchString + charPressed;
      if (keyIsAlphabetical) {
        newSearchString = newSearchString.toLowerCase();
        newSearchString =
          newSearchString.charAt(0).toUpperCase() + newSearchString.slice(1);
      }
      setSearchString(newSearchString);
    }
  }

  // Key UP/DOWN Functionality
  let newIndex = selectedIndex;
  const recognizedKeyPress =
    e.keyCode === UP_ARROW_KEY ||
    e.keyCode === DOWN_ARROW_KEY ||
    e.keyCode === ENTER_KEY;
  if (recognizedKeyPress) {
    e.preventDefault();
    if (e.keyCode === UP_ARROW_KEY) {
      if (newIndex > 0) {
        newIndex--;
      } else {
        newIndex = 0;
      }
    } else if (e.keyCode === DOWN_ARROW_KEY) {
      if (newIndex < dropdownOptions.length - 1) {
        newIndex++;
      }
    } else if (e.keyCode === ENTER_KEY) {
      resetDropdown();
    }
    onDropdownFilterChange(newIndex);
  }
};
