import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AppealHistoryCard from './AppealHistoryCard';
import { connect } from 'react-redux';
import { getAppealFromState } from '../../../../redux/reducers/AppealStore';
import LoadingBlockHelper from '../../../Shared/LoadingBlockHelper';

const FINAL_STATUS = ['Paid', 'Partially Paid', 'Denied', 'Closed'];

class AppealHistory extends Component {
  static propTypes = {
    appealData: PropTypes.object,
  };

  renderAppealHistory = () => {
    const history = this.props.appeal.historyAppeals;
    if (!history || history.length <= 0) {
      return;
    }

    const rows = history.map((appeal, index) => {
      return (
        <AppealHistoryCard
          key={index + 1}
          appealData={appeal}
          finalEOB={false}
        />
      );
    });
    const sectionTitle = (
      <div className="appeal-history__title appeal-history__title--past">
        Past Appeals
      </div>
    );

    return [sectionTitle].concat(rows);
  };

  render() {
    // const hasHistory = this.props.appeal.historyAppeals && this.props.appeal.historyAppeals.length > 0;
    const currentTitle = FINAL_STATUS.includes(this.props.appeal.status)
      ? 'Final EOB'
      : 'Current Appeal';
    return (
      <div className="appeal-history">
        <LoadingBlockHelper isLoading={this.props.isLoading}>
          <div className="appeal-history__title appeal-history__title--current">
            {currentTitle}
          </div>
          <AppealHistoryCard
            key={0}
            appealData={this.props.appeal}
            finalEOB={true}
          />
          {this.renderAppealHistory()}
        </LoadingBlockHelper>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const appealDetailsWrapper = getAppealFromState(state, ownProps.appealID);
  if (
    !appealDetailsWrapper ||
    appealDetailsWrapper.isLoading ||
    appealDetailsWrapper.isRejected
  ) {
    return {
      isLoading: true,
    };
  } else {
    return {
      appeal: appealDetailsWrapper.appeal,
      isLoading: false,
    };
  }
}

export default connect(mapStateToProps, null)(AppealHistory);
