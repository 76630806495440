import { get } from 'lodash';
import ReactDOM from 'react-dom';
import React, { useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ChartistGraph from 'react-chartist';
import ChartistFillDonut from 'chartist-plugin-fill-donut';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';

import { formatToMoney } from 'helpers/money';

export const DENIALS_UNDERPAYMENTS_TOTALS_BY_CATEGORY = {
  title: 'Denial Amount Totals by Category',
  documentationIssue: 'Claim Documentation Issue',
  dataIssue: 'Claim Data Issue',
  serviceNotCovered: 'Service Not Covered',
  serviceNotPayable: 'Service Not Separately Payable',
  otherDenialsUnderpayments: 'Other Denial Amount',
};

export const DENIALS_UNDERPAYMENTS_TOTALS_BY_CATEGORY_COLOR = {
  documentationIssue: 'purple',
  dataIssue: 'pink',
  serviceNotCovered: 'blue',
  serviceNotPayable: 'yellow',
  otherDenialsUnderpayments: 'brown',
};

const type = 'Pie';

const DonutChart = (props) => {
  let donutChartRef = useRef();

  const { groups, viewChartBy, setViewChartBy } = props;
  const { totalAmount } = groups;
  const data = {
    series: Object.keys(DENIALS_UNDERPAYMENTS_TOTALS_BY_CATEGORY_COLOR).map(
      (category) => {
        return {
          meta: `${DENIALS_UNDERPAYMENTS_TOTALS_BY_CATEGORY[category]}`,
          value: groups[category] || 0,
          className: classnames({
            [`ap-donut-series--${DENIALS_UNDERPAYMENTS_TOTALS_BY_CATEGORY_COLOR[category]}`]: true,
            'ap-donut-series--fade': viewChartBy && viewChartBy !== category,
          }),
        };
      }
    ),
  };

  const defaultTitleInsideDonutChart = viewChartBy
    ? `Total ${DENIALS_UNDERPAYMENTS_TOTALS_BY_CATEGORY[viewChartBy]} <br> `
    : 'Total Denial Amount <br>';
  const defaultTotalValueInsideDonutChart = viewChartBy
    ? groups[viewChartBy]
    : totalAmount;

  const options = {
    donut: true,
    donutWidth: 24,
    startAngle: 25,
    showLabel: false,
    width: 316,
    height: 316,
    labelInterpolationFnc: function (value) {
      return value;
    },
    plugins: [
      ChartistTooltip({
        appendToBody: true,
        currency: '-', // added to use currencyFormatCallback option
        currencyFormatCallback: (value) => {
          return `(${formatToMoney(value)})`;
        },
      }),
      ChartistFillDonut({
        items: [
          {
            content: `<div class="ap-donut-chart__middle-content">
          ${defaultTitleInsideDonutChart}
          <span class="ap-donut-chart__middle-content-value">(${formatToMoney(
            defaultTotalValueInsideDonutChart
          )})</span>
          </div>`,
            position: 'center',
          },
        ],
      }),
    ],
  };

  const onDonutChartClick = (e) => {
    if (!get(e, 'target.attributes.getNamedItem')) return;
    const ctMeta = e.target.attributes.getNamedItem('ct:meta');
    const metaValue = ctMeta && ctMeta.nodeValue ? ctMeta.nodeValue : '';

    if (
      !Object.values(DENIALS_UNDERPAYMENTS_TOTALS_BY_CATEGORY).includes(
        metaValue
      ) ||
      metaValue === DENIALS_UNDERPAYMENTS_TOTALS_BY_CATEGORY[viewChartBy]
    ) {
      return setViewChartBy(null);
    }

    setViewChartBy(
      Object.keys(DENIALS_UNDERPAYMENTS_TOTALS_BY_CATEGORY).find(
        (item) => DENIALS_UNDERPAYMENTS_TOTALS_BY_CATEGORY[item] === metaValue
      )
    );
  };

  const onDonutChartRef = (ref) => {
    donutChartRef = ref;
    if (!donutChartRef) return;
    // eslint-disable-next-line react/no-find-dom-node
    const donutChartDOMNode = ReactDOM.findDOMNode(donutChartRef);
    donutChartDOMNode.addEventListener('click', onDonutChartClick);
  };

  return (
    <div className="ap-donut-chart">
      <ChartistGraph
        ref={onDonutChartRef}
        data={data}
        options={options}
        type={type}
      />
      <div className="ap-donut-chart__details">
        <p className="ap-donut-chart__details-title">
          {DENIALS_UNDERPAYMENTS_TOTALS_BY_CATEGORY.title}
        </p>
        <ul className="ap-donut-chart__legend-menu">
          {Object.keys(DENIALS_UNDERPAYMENTS_TOTALS_BY_CATEGORY)
            .filter((item) =>
              viewChartBy ? item === viewChartBy : item !== 'title'
            )
            .map((item, idx) => {
              return groups[item] ? (
                <li
                  className={`ap-donut-chart__lagend-menu__item ap-donut-chart__lagend-menu__item--${DENIALS_UNDERPAYMENTS_TOTALS_BY_CATEGORY_COLOR[item]}`}
                  key={idx}
                >
                  {DENIALS_UNDERPAYMENTS_TOTALS_BY_CATEGORY[item]} (
                  {formatToMoney(groups[item])})
                </li>
              ) : (
                ''
              );
            })}
        </ul>
      </div>
    </div>
  );
};

DonutChart.propTypes = {
  groups: PropTypes.object.isRequired,
};

export default DonutChart;
