import {
  OPEN_BATCH_UPLOAD_WIDGET,
  CLOSE_BATCH_UPLOAD_WIDGET,
  MINIMIZE_BATCH_UPLOAD_WIDGET,
  MAXIMIZE_BATCH_UPLOAD_WIDGET,
} from 'redux/actions/actionTypes';

export function openBatchUploadWidget({ appealIdToDuplicate = null }) {
  return {
    type: OPEN_BATCH_UPLOAD_WIDGET,
    payload: {
      appealIdToDuplicate,
    },
  };
}

export function minimizeBatchUploadWidget() {
  return {
    type: MINIMIZE_BATCH_UPLOAD_WIDGET,
  };
}

export function closeBatchUploadWidget() {
  return {
    type: CLOSE_BATCH_UPLOAD_WIDGET,
  };
}

export function maximizeBatchUploadWidget() {
  return {
    type: MAXIMIZE_BATCH_UPLOAD_WIDGET,
  };
}
