import moment from 'moment';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { DATE_FORMATS, VALIDATION_DATE_FORMAT } from './dateFormats';
import {
  DATE_STRING_ERROR,
  INVALID_DATE_FORMAT,
  SERVICE_AFTER_DENIAL,
  INVALID_AMOUNT_DECIMAL_VALUE,
  INVALID_AMOUNT_INTEGER_VALUE,
  FIELD_INCORRECT_AMOUNT_FORMAT,
  INVALID_DATE,
  FIELD_REQUIRED_STRING,
  FIELD_INCORRECT_ID_FORMAT,
  PAYER_CLAIM_ID_FIELD_INCORRECT_FORMAT,
} from '../components/Shared/Errors/errorMessages';

import { FILE_SIZE_IN_MB, FILE_SIZE_LIMIT_10_MB } from 'constants/appConstants';
import {
  INDIVIDUAL_PROVIDER_TAX_ID_REGEX,
  ORGANIZATION_PROVIDER_TAX_ID_REGEX,
  ZIP_CODE_REGEX,
  NPI_CODE_REGEX,
} from 'constants/regex';

const phoneUtil = PhoneNumberUtil.getInstance();

export function validateWithValidators(value, validators) {
  let error = null;
  validators.every((validator) => {
    error = validator(value);
    return error == null;
  });

  return error;
}

/**
 * Extracts length of integer and decimal digits in a number.
 *
 * @param {Number|String} number
 * @returns {Array} [lengthOfIntegerDigits, lengthOfDecimalDigits]
 *
 * @example
 * const number = 12.345
 * console.log(extractIntegerAndDecimalLength(number)); // [2, 3]
 */
function extractIntegerAndDecimalLength(number) {
  if (isNaN(number)) {
    return [0, 0];
  }

  const [integerValue, decimalValue] = number.toString().split('.');

  const integerDigitLength = Math.abs(integerValue) ? integerValue.length : 0;
  const decimalDigitLength = decimalValue ? decimalValue.length : 0;

  return [integerDigitLength, decimalDigitLength];
}

export function validateAmountFormat(amount) {
  const formattedAmount = amount.replace('$', '').replace(/,/g, '');
  const parsedNumber = Number(formattedAmount);

  if (Number.isNaN(parsedNumber)) {
    return FIELD_INCORRECT_AMOUNT_FORMAT;
  } else {
    return null;
  }
}

/**
 * Sanity check for amount values.
 *
 * @param {String} amount
 * @returns {String}
 */
export function validateAmount(amount) {
  const MAX_INTEGER_LENGTH = 8;
  const MAX_DECIMAL_LENGTH = 2;

  const formattedAmount = amount.replace('$', '').replace(/,/g, '');

  const [integerDigitLength, decimalDigitLength] =
    extractIntegerAndDecimalLength(formattedAmount);

  if (integerDigitLength > MAX_INTEGER_LENGTH) {
    return INVALID_AMOUNT_INTEGER_VALUE;
  } else if (decimalDigitLength > MAX_DECIMAL_LENGTH) {
    return INVALID_AMOUNT_DECIMAL_VALUE;
  } else {
    return null;
  }
}

export function validateNotNegativeAmount(amount) {
  const wrongFormatError = validateAmountFormat(amount);
  if (wrongFormatError) {
    return wrongFormatError;
  }
  const formattedAmount = amount.replace('$', '').replace(/,/g, '');
  const amountAsNumber = parseFloat(formattedAmount);

  if (amountAsNumber < 0) {
    return 'Negative amount';
  } else {
    return null;
  }
}

export function validateEmail(email) {
  if (!email) {
    return FIELD_REQUIRED_STRING;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return 'Invalid email address';
  } else {
    return null;
  }
}

export function validateID(ID) {
  if (!ID) {
    return null;
  } else if (!/^[A-Za-z0-9]+$/i.test(ID)) {
    return FIELD_INCORRECT_ID_FORMAT;
  } else {
    return null;
  }
}

export function validatePayerClaimId(
  ID,
  errorMessage = PAYER_CLAIM_ID_FIELD_INCORRECT_FORMAT
) {
  if (!/^[A-Za-z0-9-]+$/i.test(ID)) {
    return errorMessage;
  } else {
    return null;
  }
}

/**
 * Validates a number based on a based on the type.
 *
 * @param {string} number - The number to be validated
 * @param {string} type - The type of number (e.g., phone, fax)
 * @return {string|null} A message indicating invalid format or null if valid
 */
export function validatePhoneFaxNumber(number, type) {
  try {
    /* eslint-disable no-useless-escape */
    if (!number.match(/^\([0-9]{3}\)\s*[0-9]{3}\-?\s?[0-9]{4}$/)) {
      return `Invalid ${type} number format. Number format must be (DDD) DDD-DDDD`;
    }
    if (phoneUtil.isValidNumber(phoneUtil.parse(number, 'US')) !== true) {
      return `Invalid ${type} number`;
    }
  } catch (e) {
    return `Invalid ${type} format`;
  }

  return null;
}

/**
 * Validates a Phone number.
 *
 * @param {type} PhoneNumber - the fax number to validate
 * @return {}
 */
export function validatePhoneNumber(phoneNumber) {
  return validatePhoneFaxNumber(phoneNumber, 'phone');
}

/**
 * Validates a Fax number.
 *
 * @param {type} faxNumber - the fax number to validate
 * @return {}
 */
export function validateFaxNumber(fax) {
  return validatePhoneFaxNumber(fax, 'Fax');
}

export function validateExists(value) {
  if (!value) {
    return FIELD_REQUIRED_STRING;
  } else {
    return null;
  }
}

export function validateURL(url) {
  return !isValidHttpUrl(url) ? 'Invalid URL' : null;
}

export function isValidHttpUrl(str) {
  try {
    const url = new URL(str);

    return ['http:', 'https:'].includes(url.protocol);
  } catch (err) {
    return false;
  }
}

export function validateStringLength(value, length) {
  if (typeof value !== 'string') return 'Value should be string';

  if (value.length !== length)
    return `String should be ${length} characters long`;

  return null;
}

export function validateStringDigitsAndLength(value, length) {
  if (typeof value !== 'string') return 'Value should be string';

  if (!value.match(/^[a-zA-Z0-9]+$/))
    return 'String should contain only characters or numbers';

  if (value.length !== length)
    return `String should be ${length} characters long`;

  return null;
}

export function validateNotEmptyString(value) {
  if (value === '' || value === null || value === undefined)
    return 'Value cannot be empty';

  return null;
}

export function validatePassword(password) {
  return validateExists(password);
}

export function validateUsername(username) {
  return validateExists(username);
}

export function validateZipCode(zipCode) {
  if (!zipCode) {
    return FIELD_REQUIRED_STRING;
  } else if (!/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/.test(zipCode)) {
    return 'Invalid ZIP code';
  } else {
    return null;
  }
}

export function validateDateString(dateString, shouldBeInThePast) {
  // first check if is in valid format
  if (
    !/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(dateString)
  ) {
    return INVALID_DATE;
  } else if (!moment(dateString, VALIDATION_DATE_FORMAT, true).isValid()) {
    return INVALID_DATE_FORMAT;
  } else if (shouldBeInThePast) {
    const now = moment();
    const testedDate = moment(dateString, DATE_FORMATS);
    const isInThePast = now.diff(testedDate) > 0;
    return isInThePast ? null : DATE_STRING_ERROR;
  }
}

export function validateDateIsBeforeDate(date1, date2) {
  const momentDate1 = moment(date1, DATE_FORMATS);
  const momentDate2 = moment(date2, DATE_FORMATS);

  if (!momentDate1.isValid() || !momentDate2.isValid()) {
    return;
  }

  const difference = momentDate2.diff(momentDate1);
  if (difference < 0) {
    return SERVICE_AFTER_DENIAL;
  }
}

export function dateIsInThePast(dateString) {
  const now = moment();
  const testedDate = moment(dateString, DATE_FORMATS);
  const isInThePast = now.diff(testedDate) > 0;
  return isInThePast;
}

export function dateIsInFuture(dateString) {
  return !dateIsInThePast(dateString);
}

/**
 * Returns max valid EOB date.
 * @info A valid EOB date can be 10 days ahead of the current date.
 * @returns {Moment}
 */
export function getMaxValidEOBDate() {
  return moment().add(10, 'd');
}

/**
 * Checks if the eob date is valid.
 * @param {string} dateString
 * @returns {boolean}
 */
export function isValidEOBDate(dateString) {
  const maxValidDate = getMaxValidEOBDate();

  return maxValidDate.diff(moment(dateString, DATE_FORMATS)) > 0;
}

export function validateProviderTaxId(taxId, isProviderOrganization = true) {
  const regex = isProviderOrganization
    ? ORGANIZATION_PROVIDER_TAX_ID_REGEX
    : INDIVIDUAL_PROVIDER_TAX_ID_REGEX;
  const errorMessage = isProviderOrganization
    ? 'Tax id should be in following sample format xx-xxxxxxx'
    : 'Tax id should be in following sample 9xx-xx-xxxx';

  if (!regex.test(taxId)) {
    return errorMessage;
  }

  return null;
}

export function validateFileSize(size) {
  const fileSizeInMB = size / FILE_SIZE_IN_MB;

  return fileSizeInMB < FILE_SIZE_LIMIT_10_MB;
}

// Zip code must be either 5 digits XXXXX or 9 Digits XXXXX-XXXX
export function zipCodeValidation(zipCode) {
  const regex = ZIP_CODE_REGEX;
  if (!zipCode) {
    return FIELD_REQUIRED_STRING;
  } else if (!regex.test(zipCode)) {
    return 'Zip code must be either 5 digits XXXXX or 9 Digits XXXXX-XXXX';
  } else {
    return null;
  }
}

export function validateNPI(npi) {
  const regex = NPI_CODE_REGEX;
  if (!npi) {
    return 'NPI is required';
  } else if (!regex.test(npi)) {
    return 'NPI must be 10 digits';
  } else {
    return null;
  }
}

export function fieldCharacterValidation(
  label = 'This field',
  value,
  maxLength = 40,
  isRequired = true
) {
  if (!value && isRequired) {
    return `${FIELD_REQUIRED_STRING}.`;
  } else if (value && value.length > maxLength) {
    return `${label} should be less than ${maxLength} characters.`;
  } else {
    return null;
  }
}

export function validatePasswordForUser(password, confirmPassword) {
  if (password !== confirmPassword) {
    return 'Passwords do not match';
  }

  return null;
}

/**
 * Check if the total documents page number is more than 120 pages
 * @param {object}
 * @returns {boolean}
 */
export function pageValidationForDocuments(pageValidationValues) {
  const errors = {};
  errors.displayErrors = [];
  const {
    isStandAlone,
    isDoubleSide,
    totalDocumentsPageNumbers,
    submissionType,
  } = pageValidationValues;

  if (submissionType === 'MAIL') {
    if (!isStandAlone && !isDoubleSide && totalDocumentsPageNumbers > 120) {
      errors.displayErrors.push({
        message:
          'The submission package cannot be more than 120 pages. If possible, please reduce the page size of this submission or choose Fax in the dropdown.',
      });
    }

    if (isStandAlone && isDoubleSide && totalDocumentsPageNumbers > 120) {
      errors.displayErrors.push({
        message:
          'The submission package cannot be more than 120 pages. If possible, please reduce the page size of this submission or choose Fax in the dropdown.',
      });
    }
    if (isStandAlone && !isDoubleSide && totalDocumentsPageNumbers > 60) {
      errors.displayErrors.push({
        message:
          'The submission package cannot be more than 60 pages. If possible, please reduce the page size of this submission or choose Fax in the dropdown.',
      });
    }
  }

  if (submissionType === 'FAX' && totalDocumentsPageNumbers > 195) {
    errors.displayErrors.push({
      message:
        'Your package seems to have more than 195 pages. We recommend you reduce the page size as faxes with more than 195 pages usually errors out.',
    });
  }

  return errors;
}

export function validateAlphaNumericString(string) {
  const alphanumeric = /^[a-zA-Z0-9_ -]+$/; // regular expression to match alphanumeric characters
  return alphanumeric.test(string);
}
