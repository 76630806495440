import {
  createRequest,
  handleErrors,
  handleJSONParse,
  createGetRequest,
  INTEGRATION_API_BASE_URL,
} from './Config';

import {
  SOP,
  SOP_LIST,
  DELETE_SOP_STEP,
  PROPOSED_ACTIONS,
} from 'constants/endpoints';

import { interpolate } from 'helpers/string';
import { camelizeKeys, snakeCaseKeys } from 'helpers/object';

/**
 * Fetches the details for a specific SOP (Standard Operating Procedure) by its ID.
 *
 * @param {string} id - The ID of the SOP to fetch details for.
 * @param {Object} [params={}] - Optional query parameters to include in the request.
 * @returns {Promise<Object>} - A Promise that resolves to the fetched SOP details.
 */
export const fetchDetailsForSop = (id, params = {}) => {
  const url = `${SOP}${id}`;
  const request = createGetRequest(url, params, INTEGRATION_API_BASE_URL, true);
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Creates a new SOP (Standard Operating Procedure) for a given rule.
 *
 * @param {Object} data - The data required to create the SOP.
 * @returns {Promise<Object>} - A Promise that resolves to the created SOP.
 */
export const createSopForRule = (data) => {
  const request = createRequest(SOP, snakeCaseKeys(data), {
    baseUrl: INTEGRATION_API_BASE_URL,
    isBearer: true,
  });
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const updateSopForRule = (id, data) => {
  const url = `${SOP}${id}/`;
  const request = createRequest(url, snakeCaseKeys(data), {
    baseUrl: INTEGRATION_API_BASE_URL,
    isBearer: true,
    method: 'PUT',
  });

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Deletes a SOP (Standard Operating Procedure) by the given ID.
 *
 * @param {string} id - The ID of the SOP to delete.
 * @returns {Promise<Response>} - A Promise that resolves to the fetch response.
 */
export const deleteSop = (id) => {
  const url = `${SOP}${id}`;

  const request = createRequest(
    url,
    {},
    {
      baseUrl: INTEGRATION_API_BASE_URL,
      method: 'DELETE',
      isBearer: true,
    }
  );

  return fetch(request).then(handleErrors);
};

/**
 * Deletes a step from a SOP (Standard Operating Procedure).
 *
 * @param {string} stepId - The ID of the step to delete.
 * @returns {Promise<void>} - A Promise that resolves when the step has been deleted.
 */
export const deleteSopStep = (stepId) => {
  const url = `${DELETE_SOP_STEP}${stepId}`;

  const request = createRequest(
    url,
    {},
    {
      baseUrl: INTEGRATION_API_BASE_URL,
      method: 'DELETE',
      isBearer: true,
    }
  );

  return fetch(request).then(handleErrors);
};

/**
 * Fetches a list of SOPs (Standard Operating Procedures) for a given rule ID.
 *
 * @param {string} id - The ID of the rule to fetch SOPs for.
 * @param {object} [params={}] - Optional parameters to pass to the API request.
 * @returns {Promise<Response>} - A Promise that resolves to the fetch response containing the list of SOPs.
 */
export const fetchSopsForRule = (id, params = {}) => {
  const url = interpolate(SOP_LIST, {
    id,
  });
  const request = createGetRequest(url, params, INTEGRATION_API_BASE_URL, true);
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Fetches a list of proposed actions from the API.
 *
 * @param {object} [params={}] - Optional parameters to pass to the API request.
 * @return {Promise<Object>} - A Promise that resolves to an object containing
 *   an array of proposed actions and a counts object.
 */
export const fetchProposedActions = (params = {}) => {
  const request = createGetRequest(
    PROPOSED_ACTIONS,
    {
      ...params,
    },
    INTEGRATION_API_BASE_URL
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Creates a new proposed action.
 *
 * @param {object} data - The data for the proposed action.
 * @return {Promise<object>} A Promise that resolves to the created proposed action.
 */
export const createProposedAction = (data) => {
  const request = createRequest(PROPOSED_ACTIONS, snakeCaseKeys(data), {
    baseUrl: INTEGRATION_API_BASE_URL,
    isBearer: true,
  });

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};
