import _ from 'lodash';
import classnames from 'classnames';
import { BiX } from 'react-icons/bi';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { BsPencil } from 'react-icons/bs';
import React, { useState, useEffect, useMemo } from 'react';

import emptyIcon from 'img/empty.svg';

import * as toast from 'components/Shared/toast';
import Pagination from 'components/common/pagination';
import TextHighlighter from 'components/common/textHighlighter';
import { AppealioPopupWithFooter } from 'components/common/popup';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import ActionButton from 'components/common/actionButton/ActionButton';
import SearchWithOption from 'components/common/search/SearchWithOption/SearchWithOption';
import AddBillingProviderForm from 'components/CreateAccount/CreateAppealioAccountDetails/AddBillingProviderContainer/AddBillingProviderForm';
import ChangeBillingProviderStatusPopup from './ChangeBillingProviderStatusPopup/ChangeBillingProviderStatusPopup';

import {
  useFetchBillingProviders,
  useFilterPractices,
  useFetchBillingProviderCount,
} from '../hook';

import { handleError } from 'helpers/errorHandler';
import { attachCountsToArrayOfObjects } from '../utils';
import { SEARCH_QUERY_CHARACTERS_OFFSET } from '../constants';

import * as AccountsAPI from 'API/AccountSettingsAPI';

import { getUserInfo } from 'redux/reducers/loginStore';
import {
  SETTINGS_PAGE_LIMIT,
  RENDERING_OR_BILLING_TYPE,
} from 'constants/appConstants';
import { isClientAdmin } from 'Auth/AuthUtils';

const PRACTICE_OPTION = {
  label: 'Practice',
  value: 'PRACTICE',
  isDefault: true,
};

const BILLING_PROVIDER_OPTION = {
  label: 'Billing Provider',
  value: 'BILLING_PROVIDER',
};

const SEARCH_TYPES = [PRACTICE_OPTION, BILLING_PROVIDER_OPTION];

const BillingProvider = (props) => {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState({
    query: '',
    type: PRACTICE_OPTION.value,
  });
  const [selectedPractice, setSelectedPractice] = useState();
  const [isBillingProviderPopupOpen, setIsBillingProviderPopupOpen] =
    useState(false);
  const [billingProviderToUpdate, setBillingProviderToUpdate] = useState(null);
  const [
    changeBillingProviderStatusPopup,
    setChangeBillingProviderStatusPopup,
  ] = useState({
    isOpen: false,
    billingProvider: null,
    toDeactivate: true,
  });

  const isPracticeSearch = searchQuery.type === PRACTICE_OPTION.value;
  const practiceSearchQuery =
    searchQuery.type === PRACTICE_OPTION.value ? searchQuery.query : '';
  const billingProviderSearchQuery =
    searchQuery.type === BILLING_PROVIDER_OPTION.value ? searchQuery.query : '';

  const { practices } = useFilterPractices(
    props.userPractices,
    practiceSearchQuery
  );

  const [listedPractices, setListedPractices] = useState(practices);
  const { loading, billingProviders, totalBillingProviders, fetch } =
    useFetchBillingProviders(
      page,
      selectedPractice ? selectedPractice.id : null,
      billingProviderSearchQuery
    );

  const resetChangeBillingProviderStatusPopup = () => {
    setChangeBillingProviderStatusPopup({
      isOpen: false,
      billingProvider: null,
      toDeactivate: true,
    });
  };

  const handlePracticeClick = (practice) => setSelectedPractice(practice);

  const billingProviderToRender =
    selectedPractice &&
    billingProviders.filter(({ practice }) => practice === selectedPractice.id);

  const handleSubmit = async (values) => {
    const practiceId = selectedPractice.id;

    const formData = {
      ...values,
      practice: practiceId,
      rendering_or_billing: RENDERING_OR_BILLING_TYPE.BILLING,
    };

    try {
      const requestPayload = !billingProviderToUpdate
        ? formData
        : { ...billingProviderToUpdate, ...formData };
      if (!billingProviderToUpdate) {
        await AccountsAPI.createBillingProvider(requestPayload);
      } else {
        await AccountsAPI.updateBillingProvider(requestPayload);
      }
      toast.success({
        title: 'Success',
        message: !billingProviderToUpdate
          ? 'Billing Provider added successfully.'
          : 'Billing Provider updated successfully',
      });
      setIsBillingProviderPopupOpen(false);
    } catch (error) {
      handleError(error);
    }

    fetch();
  };

  const handleSearch = ({ query, type }) => {
    setSearchQuery({
      query,
      type,
    });
  };

  const { billingProviderCount } = useFetchBillingProviderCount(
    billingProviderSearchQuery
  );

  const filteredPractices = useMemo(() => {
    if (isPracticeSearch) {
      if (!searchQuery.query) {
        if (!selectedPractice?.id) {
          setSelectedPractice(practices[0]);
        }
        return practices;
      }

      const search = searchQuery.query.toUpperCase();
      return practices.filter((payer) =>
        payer.name.toUpperCase().includes(search)
      );
    }

    if (
      billingProviderCount?.length > 0 &&
      searchQuery.query.length >= SEARCH_QUERY_CHARACTERS_OFFSET
    ) {
      const practiceWithProviderCount = attachCountsToArrayOfObjects(
        practices,
        billingProviderCount,
        'practiceId',
        'providerCount'
      ).filter((provider) => provider.providerCount);

      const selectedHasContacts = practiceWithProviderCount.some(
        (payer) => payer.id === selectedPractice?.id
      );

      if (
        (billingProviders.length < 1 &&
          practiceWithProviderCount.length > 0 &&
          !selectedHasContacts) ||
        practiceWithProviderCount.length === 1
      ) {
        setSelectedPractice(practiceWithProviderCount[0]);
      }

      return practiceWithProviderCount;
    }

    return practices;
  }, [
    practices,
    isPracticeSearch,
    billingProviders.length,
    selectedPractice?.id,
    billingProviderCount,
    searchQuery,
  ]);

  useEffect(() => {
    setListedPractices(filteredPractices);
  }, [filteredPractices, searchQuery]);

  const onPageChange = (page) => setPage(page);
  const pageCount = Math.ceil(totalBillingProviders / SETTINGS_PAGE_LIMIT);

  const searchBoxPlaceholder = isPracticeSearch
    ? 'Search by Practice'
    : 'Search by Billing Provider';

  return (
    <div className="settings-content-wrapper">
      <p className="setting-content__description">
        All Billing Providers are organized by their respective Practice. To add
        a Practice, please go to the
        <span className="fw-bold"> Practices </span> tab.
      </p>
      <div
        className={classnames(
          'd-flex row align-items-center settings-content__top-action',
          {
            'justify-content--space-between': isClientAdmin(props.userInfo),
          }
        )}
      >
        <div className="col-lg-6">
          <SearchWithOption
            onSearch={handleSearch}
            options={SEARCH_TYPES}
            placeholder={searchBoxPlaceholder}
            datacy="billing-provider-SearchInput"
            handleReset={() => setSelectedPractice(practices[0])}
          />
        </div>
        {isClientAdmin(props.userInfo) && (
          <button
            onClick={() => {
              setBillingProviderToUpdate(null);
              setIsBillingProviderPopupOpen(true);
            }}
            className="ap-button ap-button--secondary ml-auto settings-add-button"
          >
            <span className="mr-4">+</span> Add Billing Provider
          </button>
        )}
      </div>
      <div className="row mt-12">
        <div className="col-lg-3 border-right-grey settings-content__left-sidebar">
          {listedPractices?.length > 0 ? (
            listedPractices.map((practice, idx) => (
              <div
                key={idx}
                onClick={() => handlePracticeClick(practice)}
                className={classnames('create-account-list-item mb-8', {
                  'create-account-list-item--selected':
                    selectedPractice && selectedPractice.id === practice.id,
                  'd-flex justify-content--space-between align-items-center':
                    billingProviderCount?.length > 0,
                })}
              >
                <span> {practice.name}</span>
                {practice?.providerCount && (
                  <span>({practice.providerCount})</span>
                )}
              </div>
            ))
          ) : (
            <p className="fw-bold"> No Practice found </p>
          )}
        </div>
        <div className="col-lg-9">
          {loading && loading ? (
            <LoadingBlockHelper
              className="dashboard-bottom-loader"
              isLoading={loading}
              datacy="billing-provider-LoadingBlockHelper"
            />
          ) : selectedPractice && billingProviderToRender.length > 0 ? (
            <React.Fragment>
              <div className="row mb-16 create-acount-list-item-header">
                <div className="col-lg-3">Name</div>
                <div className="col-lg-3">Type</div>
                <div className="col-lg-2">NPI</div>
                <div className="col-lg-2">Tax ID</div>
                <div className="col-lg-2">&nbsp;</div>
              </div>
              {billingProviderToRender.map((billingProvider, idx) => (
                <div
                  className={classnames('create-account-list-item row mb-16', {
                    'create-account-list-item--inactive':
                      !billingProvider.isActive,
                  })}
                  key={idx}
                >
                  <div className="col-lg-3">
                    <span
                      data-tip={
                        billingProvider.organizationName ||
                        `${billingProvider.firstName} ${billingProvider.lastName}`
                      }
                    >
                      {searchQuery.query?.length >=
                      SEARCH_QUERY_CHARACTERS_OFFSET ? (
                        <TextHighlighter
                          text={
                            billingProvider.organizationName ||
                            `${billingProvider.firstName} ${billingProvider.lastName}`
                          }
                          query={searchQuery.query}
                        />
                      ) : (
                        billingProvider.organizationName ||
                        `${billingProvider.firstName} ${billingProvider.lastName}`
                      )}
                    </span>
                  </div>
                  <div className="col-lg-3">
                    <span
                      data-tip={
                        billingProvider.organizationName
                          ? 'Organization'
                          : 'Individual'
                      }
                    >
                      {billingProvider.organizationName
                        ? 'Organization'
                        : 'Individual'}
                    </span>
                  </div>
                  <div className="col-lg-2">
                    <span data-tip={billingProvider.npi}>
                      {billingProvider.npi || '--'}
                    </span>
                  </div>
                  <div className="col-lg-2">
                    <span data-tip={billingProvider.taxId}>
                      {billingProvider.taxId || '--'}
                    </span>
                  </div>
                  {isClientAdmin(props.userInfo) && (
                    <div className="col-lg-2 d-flex justify-content-flex-end pr-0">
                      {billingProvider.isActive ? (
                        <div className="d-flex">
                          <ActionButton
                            Icon={BsPencil}
                            className="mr-16 ml-auto"
                            dataTip="Edit"
                            onClick={(e) => {
                              setBillingProviderToUpdate(billingProvider);
                              setIsBillingProviderPopupOpen(true);
                            }}
                            iconClassName="appeal__action--appeal"
                            datacy="billing-provider-edit-ActionButton"
                          />
                          <ActionButton
                            Icon={BiX}
                            className="mr-16 ap-button--action-delete"
                            dataTip="Deactivate"
                            onClick={(e) => {
                              setChangeBillingProviderStatusPopup({
                                isOpen: true,
                                billingProvider,
                                toDeactivate: true,
                              });
                            }}
                            iconClassName="appeal__action--appeal"
                            datacy="billing-provider-deactivate-ActionButton"
                          />
                        </div>
                      ) : (
                        <div>
                          <button
                            onClick={(e) => {
                              setChangeBillingProviderStatusPopup({
                                isOpen: true,
                                billingProvider,
                                toDeactivate: false,
                              });
                            }}
                            className="ap-button ap-button--secondary ml-auto settings-reactivate-button"
                            datacy="billing-provider-deactivate-button"
                          >
                            Reactivate
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  <ReactTooltip effect="solid" place="top" multiline={true} />
                </div>
              ))}
            </React.Fragment>
          ) : (
            <div className="no-data-container">
              <img src={emptyIcon} alt="No Data Found" />
              <h4>No Data Found</h4>
            </div>
          )}

          {isBillingProviderPopupOpen && (
            <AppealioPopupWithFooter
              className="appealio-popup--v3"
              onClosePressed={() => setIsBillingProviderPopupOpen(false)}
              title={`${
                billingProviderToUpdate ? 'Edit' : 'Add'
              } Billing Provider`}
              isFooterOutside={false}
              datacy="billing-provider-AppealioPopupWithFooter"
            >
              <AddBillingProviderForm
                onSubmit={handleSubmit}
                practice={selectedPractice}
                initialValues={billingProviderToUpdate}
                isPopupForm={true}
                datacy="billing-provider-AddBillingProviderForm"
              />
            </AppealioPopupWithFooter>
          )}
          {changeBillingProviderStatusPopup.isOpen && (
            <ChangeBillingProviderStatusPopup
              onClosePressed={resetChangeBillingProviderStatusPopup}
              billingProvider={changeBillingProviderStatusPopup.billingProvider}
              title={`Are you sure want to ${
                changeBillingProviderStatusPopup.toDeactivate
                  ? 'deactivate'
                  : 'reactivate'
              } this billing provider?`}
              practiceName={selectedPractice.name}
              onSubmitSuccess={() => {
                resetChangeBillingProviderStatusPopup();
                fetch();
              }}
              toDeactivate={changeBillingProviderStatusPopup.toDeactivate}
              datacy="billing-provider-ChangeBillingProviderStatusPopup"
            />
          )}
          {!loading && totalBillingProviders && pageCount > 1 ? (
            <div className="d-flex justify-content-center">
              <Pagination
                pageCount={pageCount}
                activePage={page}
                onPageChange={onPageChange}
                datacy="billing-provider-Pagination"
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);
  const userPractices = _.get(userInfo, 'relatedPractices', []);

  return {
    userInfo,
    userPractices,
  };
}

export default connect(mapStateToProps, {})(BillingProvider);
