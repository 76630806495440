import React from 'react';
import range from 'lodash/range';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Steps extends React.Component {
  static props = {
    stepTitles: PropTypes.array.isRequired,
    activeStep: PropTypes.number,
  };

  static defaultProps = {
    stepTitles: [''],
    activeStep: 0,
  };

  getSteps() {
    const listItemWidth =
      (1 / parseInt(this.props.stepTitles.length, 10)) * 100 + '%';
    const calculatedWidthStyle = { width: listItemWidth };
    const circleStyle = { margin: 'auto' };
    const divStyle = { textAlign: 'center' };

    return this.props.stepTitles.map((item, index) => {
      const stepGroupClass = classNames(
        'steps-status-bar__list-item',
        { 'steps-status-bar__step--active': index === this.props.activeStep },
        { 'steps-status-bar__step--passed': index < this.props.activeStep }
      );

      const circleShapeClass = classNames(
        'steps-status-bar__circle-shape',
        {
          'steps-status-bar__circle-shape--active':
            index === this.props.activeStep,
        },
        {
          'steps-status-bar__circle-shape--passed':
            index < this.props.activeStep,
        }
      );

      const stepTitleClass = classNames('steps-status-bar__step-title', {
        'steps-status-bar__step-title--step-active':
          index === this.props.activeStep,
      });

      return (
        <li
          className={stepGroupClass}
          style={calculatedWidthStyle}
          key={index}
          datacy={`step-${index + 1}`}
        >
          <div className={circleShapeClass} style={circleStyle}></div>
          <div className={stepTitleClass} style={divStyle}>
            {item}
          </div>
        </li>
      );
    });
  }

  getLines() {
    const segWidth =
      (1 / parseInt(this.props.stepTitles.length - 1, 10)) * 100 + '%';
    const segStyle = { width: segWidth };
    return range(this.props.activeStep).map((item, index) => {
      return (
        <div
          className="steps-status-bar__line-seg-background-line"
          style={segStyle}
          key={index}
        ></div>
      );
    });
  }

  render() {
    const steps = this.getSteps();
    const lineSegs = this.getLines();
    const lineWidth =
      (1 - 1 / parseInt(this.props.stepTitles.length, 10)) * 100 + '%';
    const calculatedLineWidth = { width: lineWidth };
    return (
      <div className="steps-status-bar">
        <ul className="steps-status-bar__list">{steps}</ul>
        <div
          className="steps-status-bar__background-line"
          style={calculatedLineWidth}
        >
          {lineSegs}
        </div>
      </div>
    );
  }
}
