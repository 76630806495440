import { Map } from 'immutable';

import {
  OPEN_BATCH_UPLOAD_WIDGET,
  CLOSE_BATCH_UPLOAD_WIDGET,
  MINIMIZE_BATCH_UPLOAD_WIDGET,
  MAXIMIZE_BATCH_UPLOAD_WIDGET,
} from 'redux/actions/actionTypes';

const initialState = Map({
  isOpen: false,
  isMinimized: false,
  appealIdToDuplicate: null,
});

export const batchUploadWidgetStore = (state = initialState, action = {}) => {
  switch (action.type) {
    case OPEN_BATCH_UPLOAD_WIDGET:
      return state
        .set('isOpen', true)
        .set('isMinimized', false)
        .set('appealIdToDuplicate', action.payload.appealIdToDuplicate);
    case MINIMIZE_BATCH_UPLOAD_WIDGET:
      return state.set('isMinimized', true).set('appealIdToDuplicate', null);
    case CLOSE_BATCH_UPLOAD_WIDGET:
      return state
        .set('isOpen', false)
        .set('isMinimized', false)
        .set('appealIdToDuplicate', null);
    case MAXIMIZE_BATCH_UPLOAD_WIDGET:
      return state.set('isMinimized', false);

    default:
      return state;
  }
};

export const getBatchUploadWidgetStore = (state) =>
  state.get('batchUploadWidgetStore');
export const getIsOpen = (state) =>
  getBatchUploadWidgetStore(state).get('isOpen');
export const getIsMinimized = (state) =>
  getBatchUploadWidgetStore(state).get('isMinimized');
export const getAppealIdToDuplicate = (state) =>
  getBatchUploadWidgetStore(state).get('appealIdToDuplicate');
