import CreateAppealAPI from 'API/CreateAppealAPI';
import { useEffect, useState } from 'react';

/**
 * Use fetch associated practices.
 */
export const useFetchAssociatedProviders = (practiceId) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    renderingProviders: [],
    billingProviders: [],
    clinics: [],
  });

  useEffect(() => {
    setLoading(true);

    const fetch = async () => {
      try {
        const { billingProviders, renderingProviders, clinics } =
          await CreateAppealAPI.getProviderAndClinic({
            practice_id: practiceId,
          });

        setData({
          billingProviders,
          clinics,
          renderingProviders,
        });
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    };

    fetch();
  }, [practiceId]);

  return { loading, error, data };
};
