import React, { useState } from 'react';

import './style.css';

import * as toast from 'components/Shared/toast';
import { AppealioPopupWithFooter } from 'components/common/popup';

import { handleError } from 'helpers/errorHandler';
import * as AccountsAPI from 'API/AccountSettingsAPI';

const ChangeBillingProviderStatusPopup = (props) => {
  const {
    title,
    onClosePressed,
    billingProvider,
    practiceName,
    toDeactivate,
    onSubmitSuccess,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmitClick = async () => {
    try {
      setIsSubmitting(true);
      await AccountsAPI.updateBillingProvider({
        ...billingProvider,
        is_active: !toDeactivate,
      });
      setIsSubmitting(false);
      const toastMessage = toDeactivate
        ? "Billing Provider's status updated successfully"
        : "Billing Provider's status change requested to the CX team successfully";
      toast.success({
        title: '',
        message: toastMessage,
      });
      onSubmitSuccess();
    } catch (error) {
      setIsSubmitting(false);
      handleError(error);
    }
  };

  return (
    <AppealioPopupWithFooter
      className="appealio-popup--v3"
      title={title}
      onClosePressed={onClosePressed}
      isFooterOutside={false}
      datacy="change-billing-provider-status-popup-AppealioPopupWithFooter"
    >
      <div>
        <div className="mb-16">
          <div className="fs-14 fw-bold">Name</div>
          <div>
            {billingProvider.organizationName ||
              `${billingProvider.firstName} ${billingProvider.lastName}`}
          </div>
        </div>
        <div className="mb-16">
          <div className="fs-14 fw-bold">Tax ID</div>
          <div>{billingProvider.taxId}</div>
        </div>
        <div className="mb-30">
          <div className="fs-14 fw-bold">Practice</div>
          <div>{practiceName}</div>
        </div>
      </div>
      <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
        <div className="d-flex justify-content-flex-end">
          <button
            disabled={isSubmitting}
            className="ml-0 mr-16 ap-button ap-button--primary-red ap-button--primary-md"
            onClick={onClosePressed}
          >
            Cancel
          </button>
          <button
            disabled={isSubmitting}
            className="ml-0 ap-button ap-button--primary-dark ap-button--primary-md"
            onClick={onSubmitClick}
          >
            {toDeactivate ? 'Deactivate' : 'Reactivate'}
          </button>
        </div>
      </div>
    </AppealioPopupWithFooter>
  );
};

ChangeBillingProviderStatusPopup.propTypes = {};

export default ChangeBillingProviderStatusPopup;
