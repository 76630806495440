import { pickBy, identity } from 'lodash';
import * as endpoints from '../constants/endpoints';
import { camelizeKeys, snakeCaseKeys } from '../helpers/object';

import {
  handleErrors,
  handleJSONParse,
  createRequest,
  BASE_API_URL,
  INTEGRATION_API_BASE_URL,
  createFormDataRequest,
} from './Config';

/**
 * Adds a manual claim.
 *
 * @param {Object} data - The claim data to add.
 * @returns {Promise} A promise that resolves with the added claim.
 */
export const addManualClaim = (data) => {
  const url = `${endpoints.CREATE_MANUAL_CLAIM}`;
  const request = createRequest(url, snakeCaseKeys(pickBy(data, identity)), {
    baseUrl: INTEGRATION_API_BASE_URL,
    method: 'POST',
  });

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Updates an existing manual claim by ID.
 *
 * @param {Object} data - The updated claim data.
 * @param {string} id - The ID of the claim to update.
 * @returns {Promise} A promise that resolves with the updated claim.
 */
export const updateManualClaim = (data, id) => {
  const url = `${endpoints.CREATE_MANUAL_CLAIM}${id}`;
  const request = createRequest(url, snakeCaseKeys(pickBy(data, identity)), {
    baseUrl: INTEGRATION_API_BASE_URL,
    method: 'PATCH',
  });

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Appeal Manual claim.
 *
 * @param {Object} requestBody
 * @returns {Promise}
 */
export const appealManualClaim = async (requestBody = {}) => {
  const request = createRequest(
    endpoints.APPEAL_MANUAL_CLAIM,
    snakeCaseKeys(requestBody),
    {
      baseUrl: BASE_API_URL,
      method: 'POST',
    }
  );

  return fetch(request).then(handleErrors).then(handleJSONParse);
};

/**
 * Sends a request to validate the manual claim CSV data.
 *
 * @param {File} file - The CSV file to validate.
 * @returns {Promise}
 */
export const validateManualClaimCSV = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const request = createFormDataRequest(
    endpoints.VALIDATE_MANUAL_CLAIM_CSV,
    formData,
    'POST',
    INTEGRATION_API_BASE_URL
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Uploads manual claim data.
 *
 * @param {Object} data
 * @returns {Promise}
 */
export const bulkUploadManualClaim = (data) => {
  const request = createRequest(endpoints.UPLOAD_MANUAL_CLAIM_CSV, data, {
    baseUrl: INTEGRATION_API_BASE_URL,
    method: 'POST',
  });

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};
