import classnames from 'classnames';
import { BiX } from 'react-icons/bi';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { pickBy, identity } from 'lodash';
import { BsPencil } from 'react-icons/bs';
import ReactDOMServer from 'react-dom/server';
import React, { useState, useEffect, useMemo } from 'react';

import emptyIcon from 'img/empty.svg';

import { camelizeKeys } from 'helpers/object';
import { attachCountsToArrayOfObjects } from '../utils';
import { SEARCH_QUERY_CHARACTERS_OFFSET } from '../constants';

import * as toast from 'components/Shared/toast';
import Pagination from 'components/common/pagination';
import DvVerifiedIcon from 'components/common/dvVerified';
import AddPayerContactForm from '../Payer/AddPayerContactForm';
import TextHighlighter from 'components/common/textHighlighter';
import { AppealioPopupWithFooter } from 'components/common/popup';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import ActionButton from 'components/common/actionButton/ActionButton';
import SearchWithOption from 'components/common/search/SearchWithOption/SearchWithOption';

import * as AccountsAPI from 'API/AccountSettingsAPI';

import {
  useFetchPayersList,
  useFetchPayerContacts,
  useFetchPayerContactCounts,
} from '../hook';

import { handleError } from 'helpers/errorHandler';

import { SETTINGS_PAGE_LIMIT } from 'constants/appConstants';
import ChangePayerContactStatusPopup from './changePayerContactStatusPopup';
import { getUserInfo } from 'redux/reducers/loginStore';
import { isAuthorizedForEmailSubmission, isClientAdmin } from 'Auth/AuthUtils';

const PAYER_OPTION = {
  label: 'Payer',
  value: 'PAYER',
  isDefault: true,
};

const PAYER_CONTACT_OPTION = {
  label: 'Payer Contact',
  value: 'PAYER_CONTACT',
};

const SEARCH_TYPES = [PAYER_OPTION, PAYER_CONTACT_OPTION];

const DataTipContent = (payerContact) => {
  const mappedPayerContact = {
    Address1: payerContact.address1,
    Address2: payerContact.address2,
    City: payerContact.city,
    State: payerContact.state,
    ZIP: payerContact.zipcode,
  };

  const payerContactInfo = pickBy(mappedPayerContact, identity);

  return (
    <div>
      {Object.entries(payerContactInfo).map(([key, value], idx) => (
        <div key={idx}>
          <strong>{key}</strong>: {value}
        </div>
      ))}
    </div>
  );
};

const PayerContacts = (props) => {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState({
    query: '',
    type: PAYER_OPTION.value,
  });

  const isPayerSearch = searchQuery.type === PAYER_OPTION.value;
  const payerContactSearchQuery =
    searchQuery.type === PAYER_CONTACT_OPTION.value ? searchQuery.query : '';

  const { loading, payers } = useFetchPayersList();
  const [selectedPayer, setSelectedPayer] = useState();
  const [listedPayers, setListedPayers] = useState(payers);
  const [addPayerContactFormState, setAddPayerContactFormState] = useState({
    isOpen: false,
    payerId: null,
    payerName: null,
    initialValues: {},
  });

  const [changePayerContactStatusPopup, setChangePayerContactStatusPopup] =
    useState({
      isOpen: false,
      payerContact: null,
      toDeactivate: true,
    });

  const selectedPayerId = selectedPayer ? selectedPayer.id : null;
  const {
    loading: loadingPayerContacts,
    payerContacts,
    totalContacts,
    fetch: fetchPayerContacts,
  } = useFetchPayerContacts(page, selectedPayerId, payerContactSearchQuery);

  const emailEnabledForUser = isAuthorizedForEmailSubmission(props.userInfo);

  const { payerContactCounts } = useFetchPayerContactCounts(
    payerContactSearchQuery
  );

  const filteredPayers = useMemo(() => {
    if (isPayerSearch) {
      if (!searchQuery.query) {
        if (!selectedPayerId) {
          setSelectedPayer(payers[0]);
        }
        return payers;
      }

      const search = searchQuery.query.toUpperCase();
      return payers.filter((payer) =>
        payer.name.toUpperCase().includes(search)
      );
    }

    if (
      payerContactCounts?.length > 0 &&
      searchQuery.query.length >= SEARCH_QUERY_CHARACTERS_OFFSET
    ) {
      const payersWithCounts = attachCountsToArrayOfObjects(
        payers,
        payerContactCounts,
        'payerId',
        'payerContactCount'
      ).filter((payer) => payer.payerContactCount);

      const selectedHasContacts = payersWithCounts.some(
        (payer) => payer.id === selectedPayerId
      );

      if (
        (payerContacts.length < 1 &&
          payersWithCounts.length > 0 &&
          !selectedHasContacts) ||
        payersWithCounts.length === 1
      ) {
        setSelectedPayer(payersWithCounts[0]);
      }

      return payersWithCounts;
    }

    return payers;
  }, [
    isPayerSearch,
    payerContactCounts,
    payerContacts,
    payers,
    selectedPayerId,
    searchQuery,
  ]);

  useEffect(() => {
    setListedPayers(filteredPayers);
  }, [filteredPayers, searchQuery]);

  const onPageChange = (page) => setPage(page);
  const pageCount = Math.ceil(totalContacts / SETTINGS_PAGE_LIMIT);

  const handlePayerClick = (payer) => {
    setPage(1);
    setSelectedPayer(payer);
  };

  const handleSearch = ({ query, type }) => {
    setSearchQuery({
      query,
      type,
    });
  };

  /**
   * Opens the Payer Contact form.
   * @param {Object} initialValues
   */
  const openPayerContactForm = (initialValues = {}) => {
    setAddPayerContactFormState({
      isOpen: true,
      payerId: selectedPayerId,
      payerName: selectedPayer.name,
      initialValues,
    });
  };

  /**
   * Closes the Payer Contact form.
   */
  const closePayerContactForm = () => {
    setAddPayerContactFormState({
      isOpen: false,
      payerId: null,
      payerName: null,
      initialValues: {},
    });
  };

  /**
   * Handles the Payer Contact form submit.
   * @param {Object} data
   */
  const handlePayerContactFormSubmit = async (data) => {
    try {
      const payerContactIdToEdit = addPayerContactFormState?.initialValues?.id;
      const isEdit = Boolean(payerContactIdToEdit);
      const payerContactData = {
        ...data,
        payerId: addPayerContactFormState.payerId,
      };

      if (isEdit) {
        await AccountsAPI.updatePayerContact({
          ...payerContactData,
          id: payerContactIdToEdit,
        });
      } else {
        await AccountsAPI.createPayerContact(payerContactData);
      }

      toast.success({
        title: 'Success',
        message: isEdit ? 'Payer Contact updated' : 'Payer Contact created',
      });
      closePayerContactForm();
      fetchPayerContacts();
    } catch (error) {
      handleError(error);
    }
  };

  /**
   * reset the payer contact for delete.
   */
  const resetChangePayerContactStatusPopup = () => {
    setChangePayerContactStatusPopup({
      isOpen: false,
      payerContact: null,
      toDeactivate: false,
    });
  };

  const isEdit = Boolean(addPayerContactFormState?.initialValues?.id);
  const title = `${isEdit ? 'Edit' : 'Add'} Payer Contact for ${
    addPayerContactFormState.payerName
  }`;

  const searchBoxPlaceholder = isPayerSearch
    ? 'Search by Payer'
    : 'Search by Payer Contact';

  return (
    <div className="settings-content-wrapper" datacy="rendering-provider">
      <p className="setting-content__description">
        All Payer Contact(s) are organized by their respective Payer. To add a
        Payer, please go to the
        <span className="fw-bold"> Payers </span> tab.
      </p>
      <div
        className={classnames(
          'd-flex row align-items-center settings-content__top-action',
          {
            'justify-content--space-between': isClientAdmin(props.userInfo),
          }
        )}
      >
        <div className="col-lg-6">
          <SearchWithOption
            onSearch={handleSearch}
            options={SEARCH_TYPES}
            placeholder={searchBoxPlaceholder}
            handleReset={() => setSelectedPayer(payers[0])}
          />
        </div>
        {isClientAdmin(props.userInfo) && (
          <button
            className="ap-button ap-button--secondary ml-auto settings-add-button"
            datacy="settings-add-button"
            onClick={() => openPayerContactForm()}
          >
            <span className="mr-4">+</span> Add Payer Contact
          </button>
        )}
        <ReactTooltip effect="solid" multiline={true} />
      </div>
      <div className="row mt-12">
        <div className="col-lg-3 border-right-grey settings-content__left-sidebar">
          {listedPayers?.length > 0 ? (
            listedPayers.map((payer, idx) => (
              <div
                key={idx}
                onClick={() => handlePayerClick(payer)}
                className={classnames('create-account-list-item mb-8', {
                  'create-account-list-item--selected':
                    selectedPayer && selectedPayer.id === payer.id,
                  'd-flex justify-content--space-between align-items-center':
                    payerContactCounts?.length > 0,
                })}
              >
                <span>{payer.name}</span>
                {payer?.payerContactCount && (
                  <span>({payer.payerContactCount})</span>
                )}
              </div>
            ))
          ) : loading && loading ? (
            <LoadingBlockHelper
              className="dashboard-bottom-loader"
              isLoading={loading}
            />
          ) : (
            <p className="fw-bold"> No Payer found </p>
          )}
        </div>
        <div className="col-lg-9">
          {loadingPayerContacts && loadingPayerContacts ? (
            <LoadingBlockHelper
              className="dashboard-bottom-loader"
              isLoading={loadingPayerContacts}
            />
          ) : payerContacts.length > 0 ? (
            <React.Fragment>
              <div className="row mb-16 create-acount-list-item-header">
                <div className="col-lg-2">Name</div>
                <div className="col-lg-2">Mailing Address</div>
                <div className="col-lg-2 pl-24">Payer Portal</div>
                <div className="col-lg-2">Fax Number</div>
                <div className="col-lg-2">{emailEnabledForUser && 'Email'}</div>
                <div className="col-lg-2"></div>
              </div>
              {payerContacts.map((payerContact, idx) => {
                const { address1, city, state, zipcode } = payerContact;
                const submissionAddress =
                  [address1, city, state, zipcode]
                    .filter(Boolean)
                    .join(', ')
                    .replace(/(^|\s)--(\s|$)/g, '$1--$2') || '--';
                const addressProps = {
                  ...((payerContact.address1 || payerContact.address2) && {
                    'data-tip': ReactDOMServer.renderToStaticMarkup(
                      DataTipContent(camelizeKeys(payerContact))
                    ),
                  }),
                };
                const payerPortalUrl = payerContact.payerPortalUrl;
                return (
                  <div
                    className={classnames(
                      'create-account-list-item row mb-16',
                      {
                        'create-account-list-item--inactive':
                          !payerContact.isActive,
                      }
                    )}
                    key={idx}
                  >
                    <div className="col-lg-2">
                      <span data-tip={payerContact.name}>
                        {payerContact.name &&
                        searchQuery.query?.length >=
                          SEARCH_QUERY_CHARACTERS_OFFSET ? (
                          <TextHighlighter
                            text={payerContact.name}
                            query={searchQuery.query}
                          />
                        ) : (
                          <span>{payerContact.name || '--'}</span>
                        )}
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <div className="d-flex align-items-center">
                        {payerContact.dvVerifiedMail ? (
                          <DvVerifiedIcon className="mr-8" />
                        ) : null}
                        <span {...addressProps} data-html={true}>
                          {submissionAddress}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="d-flex align-items-center pl-12">
                        {payerPortalUrl ? (
                          <a
                            href={payerPortalUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            title={payerPortalUrl}
                          >
                            Portal Link
                          </a>
                        ) : (
                          '--'
                        )}
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="d-flex align-items-center">
                        {payerContact.dvVerifiedFax ? (
                          <DvVerifiedIcon className="mr-8" />
                        ) : null}
                        <span data-tip={payerContact.fax}>
                          {payerContact.fax || '--'}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      {emailEnabledForUser && (
                        <div className="d-flex align-items-center">
                          <span data-tip={payerContact.email}>
                            {payerContact.email || '--'}
                          </span>
                        </div>
                      )}
                    </div>
                    {isClientAdmin(props.userInfo) && (
                      <div className="col-lg-2 d-flex justify-content-flex-end">
                        {payerContact.isActive ? (
                          <div className="d-flex">
                            <ActionButton
                              Icon={BsPencil}
                              className="mr-16 ml-auto"
                              dataTip="Edit"
                              onClick={(e) => {
                                openPayerContactForm(payerContact);
                              }}
                              iconClassName="appeal__action--appeal"
                              datacy="payer-edit-ActionButton"
                            />
                            <ActionButton
                              Icon={BiX}
                              className="mr-16 ap-button--action-delete"
                              dataTip="Deactivate"
                              onClick={(e) => {
                                setChangePayerContactStatusPopup({
                                  isOpen: true,
                                  payerContact,
                                  toDeactivate: true,
                                });
                              }}
                              iconClassName="appeal__action--appeal"
                            />
                          </div>
                        ) : (
                          <div>
                            <button
                              onClick={(e) => {
                                setChangePayerContactStatusPopup({
                                  isOpen: true,
                                  payerContact,
                                  toDeactivate: false,
                                });
                              }}
                              className="ap-button ap-button--secondary ml-auto settings-reactivate-button"
                            >
                              Reactivate
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                    <ReactTooltip effect="solid" place="top" multiline={true} />
                  </div>
                );
              })}
            </React.Fragment>
          ) : (
            <div className="no-data-container">
              <img src={emptyIcon} alt="No Data Found" />
              <h4>No Data Found</h4>
            </div>
          )}

          {!loading && totalContacts && pageCount > 1 ? (
            <div className="d-flex justify-content-center">
              <Pagination
                pageCount={pageCount}
                activePage={page}
                onPageChange={onPageChange}
                datacy="rendering-provider-Pagination"
              />
            </div>
          ) : null}
        </div>
      </div>
      {addPayerContactFormState.isOpen && (
        <AppealioPopupWithFooter
          onClosePressed={closePayerContactForm}
          title={title}
          className="add-payer-contact-popup"
          isFooterOutside={false}
        >
          <AddPayerContactForm
            onSubmit={handlePayerContactFormSubmit}
            initialValues={addPayerContactFormState.initialValues}
            datacy="add-payer-contact-form"
            payerId={selectedPayerId}
          />
        </AppealioPopupWithFooter>
      )}

      {/* Delete payer contact */}
      {changePayerContactStatusPopup.isOpen && (
        <ChangePayerContactStatusPopup
          onClosePressed={resetChangePayerContactStatusPopup}
          payerContact={changePayerContactStatusPopup.payerContact}
          title={`Are you sure you want to ${
            changePayerContactStatusPopup.toDeactivate
              ? 'deactivate'
              : 'activate'
          } this Payer Contact?`}
          onSubmitSuccess={() => {
            resetChangePayerContactStatusPopup();
            fetchPayerContacts();
          }}
          toDeactivate={changePayerContactStatusPopup.toDeactivate}
          payerId={selectedPayerId}
        />
      )}
    </div>
  );
};
function mapStateToProps(state) {
  const userInfo = getUserInfo(state);

  return {
    userInfo,
  };
}

export default connect(mapStateToProps, {})(PayerContacts);
