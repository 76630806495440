import React from 'react';
import classnames from 'classnames';
import { times } from 'lodash';
import PropTypes from 'prop-types';

import './style.css';

const StepIndicator = ({ isActive }) => {
  return (
    <div
      className={classnames('step-indicator', {
        'step-indicator--active': isActive,
      })}
    ></div>
  );
};

const Stepper = ({ totalSteps = 5, step }) => {
  return (
    <div className="stepper-wrapper">
      {times(totalSteps, (i) => (
        <StepIndicator
          key={i}
          isActive={i === step - 1}
          datacy={`step-indicator-${i}`}
        />
      ))}
    </div>
  );
};

Stepper.propTypes = {
  totalSteps: PropTypes.number,
  step: PropTypes.number,
};

export default Stepper;
