import React from 'react';
import { Field } from 'redux-form/immutable';

import Input from 'components/common/input';
import * as toast from 'components/Shared/toast';
import EmailFileDropzone from './EmailFileDropzone/EmailFileDropzone';
import { renderReduxTextarea } from 'components/CreateAppeal/reduxTextarea';
import CustomCreatableSelect from 'components/common/select/CreatableSelect';

import { validateEmail } from 'helpers/validators';

export const renderCCEmails = ({ input, label, placeholder, className }) => {
  const handleChange = (selectedOptions) => {
    input.onChange(selectedOptions.map((option) => option.value));
  };

  let emailOptions = [];
  const value = input.value?.toJS?.() || input.value;
  if (Array.isArray(value)) {
    emailOptions = value.map((email) => ({
      value: email,
      label: email,
    }));
  }

  return (
    <div className={className}>
      <label className="fw-normal">{label}</label>
      <CustomCreatableSelect
        placeholder={placeholder}
        options={emailOptions}
        values={emailOptions}
        handleChange={handleChange}
        onBlur={() => input.onBlur(input.value)}
        noOptionsMessage={() => 'Type email and press Enter'}
        formatCreateLabel={(inputValue) => `Add email: ${inputValue}`}
        onCreateOption={(inputValue) => {
          const errorMessage = validateEmail(inputValue);
          if (errorMessage) {
            return toast.error({
              title: '',
              message: errorMessage,
            });
          }
          input.onChange([...(input.value || []), inputValue]);
        }}
        meta={{ error: input.error, touched: input.touched }}
      />
    </div>
  );
};

const EmailFormFields = (props) => (
  <div>
    <Field
      className="mb-8"
      name="recipientEmail"
      component={Input}
      label="Recipient Email: *"
    />
    <Field
      className="mb-8"
      name="emailSubject"
      component={Input}
      label="Subject Line: *"
    />
    <Field
      name="ccEmails"
      component={renderCCEmails}
      label="Cc:"
      placeholder=""
      className="w-100 mb-8"
    />
    <Field
      name="emailMessage"
      component={renderReduxTextarea}
      label="Message: *"
      className="email-message-container"
      maxLength={100000}
      required={true}
    />
    <EmailFileDropzone
      files={props.emailFiles}
      onFilesChange={props.onFilesChange}
      onFileDelete={props.onFileDelete}
    />
  </div>
);

export default EmailFormFields;
