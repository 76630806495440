import _ from 'lodash';

export const TAG_CLASS_NAME = 'template_tag';

export function createRegexpForTagMatching(tags) {
  const tagNames = tags.map((tag) => `\\b${tag.tag}\\b`);
  const re = new RegExp(tagNames.join('|'), 'g');
  return re;
}
/*
  converts content (e.g. Hello PATIENT_FIRST_NAME hi hi)
  into 'Hello <input>PATIENT_FIRST_NAME</input> hi hi'
  where tags are {
    tag: PATIENT_FIRST_NAME, value: ...
  }
*/
export function createTagNode(tagValue) {
  return `<input type="button" class="${TAG_CLASS_NAME}" value="${tagValue}" disabled="disabled" />`;
}

export function formatTemplate(content, tags) {
  if (_.isEmpty(content) || _.isEmpty(tags)) {
    return content;
  }

  const re = createRegexpForTagMatching(tags);
  const formattedTags = content.replace(re, function (matched) {
    return createTagNode(matched);
  });

  return formattedTags.replace(/\r?\n/g, '<br />');
}

const SIGNATURE_TAGS = [
  'AGENTSIGNATURE',
  'PROVIDERSIGNATURE',
  'BILLINGPROVIDERSIGNATURE',
];

const NON_EMPTY_TAGS = [
  'PAYERCITY',
  'PAYERNAME',
  'PAYERZIP',
  'PAYERSTATE',
  'PAYERADDRESS1',
  'PAYERADDRESS2',
  'CPTCODE1',
  'CPTCODE2',
  'CPTCODE3',
  'CPTCODE4',
  'CPTCODE5',
  'CPTCODE6',
  'CPTCODE7',
  'CPTCODE8',
  'CPTCODE9',
  'CPTCODE10',
  'CPTCODE11',
  'CPTCODE12',
];

const getTagValue = ({ tag, value }) => {
  if (value) {
    if (SIGNATURE_TAGS.includes(tag)) {
      return `<span style="font-size: 22px; font-family: 'Dancing Script', cursive; font-style: italic;">${value}</span>`;
    }

    return value;
  }

  if (NON_EMPTY_TAGS.includes(tag)) {
    return `<strong>[INSERT ${tag}]</strong>`;
  }

  return '';
};

export function fillTemplate(content, tags) {
  if (_.isEmpty(content) || _.isEmpty(tags)) {
    return content;
  }

  // eslint-disable-next-line no-useless-escape
  const re = new RegExp(
    `<input type="button" class="${TAG_CLASS_NAME}" value=\"([^"]*)\" disabled="disabled" \/>`, // eslint-disable-line
    'g'
  );
  const tagValuesMap = _.keyBy(tags, (t) => t.tag);

  const contentWithoutTagNodes = content.replace(re, '$1');

  const tagRe = createRegexpForTagMatching(tags);

  return contentWithoutTagNodes.replace(tagRe, (tag) =>
    getTagValue(tagValuesMap[tag])
  );
}
