import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

import dvVerifiedIcon from 'img/dv-verified.svg';

const DvVerifiedIcon = (props) => {
  const {
    className,
    style = {},
    title = `Indicates a commonly used contact across the DocVocate client base that is successfully transmitted at least 80% of the time.`,
  } = props;
  // generate random identifier
  const id = Math.floor(Math.random() * 10);
  return (
    <>
      <img
        data-tip={title}
        data-for={`dv-verified-icon-${id}`}
        src={dvVerifiedIcon}
        style={style}
        className={`${className ? `${className}` : ''}`}
        alt="dv-verified-icon"
      />
      <ReactTooltip
        id={`dv-verified-icon-${id}`}
        effect="solid"
        place="top"
        multiline={true}
      />
    </>
  );
};

DvVerifiedIcon.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
};

export default DvVerifiedIcon;
