import {
  FIELD_REQUIRED_STRING,
  REQUIRED_FIELD_ERROR,
} from '../Shared/Errors/errorMessages';

export const addedRequiredFieldError = (
  displayErrors,
  errorString,
  fieldName = null
) => {
  let fieldNameLabel = '';
  if (typeof fieldName === 'string') {
    fieldNameLabel = `${fieldName}: `;
  }
  if (errorString === FIELD_REQUIRED_STRING) {
    displayErrors.push({
      title: REQUIRED_FIELD_ERROR.title,
      message: `${fieldNameLabel}${REQUIRED_FIELD_ERROR.message}`,
    });
    return true;
  }
  return false;
};
