import React, { useState } from 'react';

import * as toast from 'components/Shared/toast';
import { AppealioPopupWithFooter } from 'components/common/popup';

import { handleError } from 'helpers/errorHandler';
import * as AccountsAPI from 'API/AccountSettingsAPI';

const ChangeClinicStatusPopup = (props) => {
  const { title, onClosePressed, clinic, toDeactivate, onSubmitSuccess } =
    props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmitClick = async () => {
    try {
      setIsSubmitting(true);
      if (toDeactivate) {
        await AccountsAPI.deactivateClinic(clinic.id);
      } else {
        await AccountsAPI.requestReactivate(clinic.id, 'clinic');
      }
      setIsSubmitting(false);
      const toastMessage = toDeactivate
        ? "Clinic's status updated successfully"
        : "Clinic's status change requested to the CX team successfully";
      toast.success({
        title: '',
        message: toastMessage,
      });
      onSubmitSuccess();
    } catch (error) {
      setIsSubmitting(false);
      handleError(error);
    }
  };

  return (
    <AppealioPopupWithFooter
      className="appealio-popup--v3"
      title={title}
      onClosePressed={onClosePressed}
      isFooterOutside={false}
    >
      <div>
        <div className="mb-16">
          <div className="fs-14 fw-bold">Clinic Name</div>
          <div>{clinic.name}</div>
        </div>
        <div className="mb-16">
          <div className="fs-14 fw-bold">Address</div>
          <div>
            <div>{clinic.address1}</div>
            {clinic.address2 && <div>{clinic.address2}</div>}
            <div>
              {clinic.city && `${clinic.city}, `}
              {clinic.state} {clinic.zipcode}
            </div>
            <div>US</div>
          </div>
        </div>
      </div>
      <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
        <div className="d-flex justify-content-flex-end">
          <button
            disabled={isSubmitting}
            className="ml-0 mr-16 ap-button ap-button--primary-red ap-button--primary-md"
            onClick={onClosePressed}
          >
            Cancel
          </button>
          <button
            disabled={isSubmitting}
            className="ml-0 ap-button ap-button--primary-dark ap-button--primary-md"
            onClick={onSubmitClick}
          >
            {toDeactivate ? 'Deactivate' : 'Reactivate'}
          </button>
        </div>
      </div>
    </AppealioPopupWithFooter>
  );
};

ChangeClinicStatusPopup.propTypes = {};

export default ChangeClinicStatusPopup;
