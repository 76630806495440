import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select, { components } from 'react-select';

import './style.css';
import { RULE_STATUSES } from 'constants/appConstants';

export const SingleValue = ({ children, ...props }) => {
  const { data } = props;

  return (
    <components.SingleValue {...props}>
      <div className="d-flex align-item-center">
        <span
          className={classnames('rule-status-select-dot mr-2', {
            'rule-status-select-dot--active':
              data.value === RULE_STATUSES.ACTIVE,
            'rule-status-select-dot--paused':
              data.value === RULE_STATUSES.PAUSED,
          })}
        ></span>
        {children}
      </div>
    </components.SingleValue>
  );
};

const RuleStatusSelect = (props) => {
  const {
    className,
    onChange,
    status,
    isDisabled = false,
    isLoading = false,
  } = props;
  const options = [
    {
      value: RULE_STATUSES.ACTIVE,
      label: 'Active',
    },
    {
      value: RULE_STATUSES.PAUSED,
      label: 'Paused',
    },
    // {
    //   value: RULE_STATUSES.ARCHIVED,
    //   label: "Archived",
    // },
  ];

  const value = options.find(({ value }) => value === status) || options[0];

  return (
    <React.Fragment>
      <Select
        className={classnames('rule-status-select', className)}
        options={options}
        onChange={onChange}
        isDisabled={isDisabled}
        isSearchable={false}
        isLoading={isLoading}
        classNamePrefix={'rule-select'}
        components={{
          SingleValue,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        value={value}
        datacy="rule-status-Select"
      />
    </React.Fragment>
  );
};

RuleStatusSelect.propTypes = {
  className: PropTypes.string,
};

export default RuleStatusSelect;
