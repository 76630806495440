import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextArea from 'react-textarea-autosize';

import AppealNoteItemList from './AppealNoteItemList';
import SubmitButton from '../../../Shared/SubmitButton';
import LoadingBlockHelper from '../../../Shared/LoadingBlockHelper';

import AppealNotesAPI from '../../../../API/AppealNotesAPI';

export default class AppealNotes extends Component {
  static propTypes = {
    appealId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      noteInputValue: '',
      notes: [],
      notesAreLoading: false,
    };
  }

  componentDidMount() {
    this.getNotes();
  }

  getNotes = () => {
    this.setState({ notesAreLoading: true }, () => {
      AppealNotesAPI.getNotesForAppealId(this.props.appealId).then((notes) => {
        this.setState({ notes, notesAreLoading: false });
      });
    });
  };

  onKeyDown = (e) => {
    const key = e.which || e.keyCode;
    const enterKeyCode = 13;
    if (key === enterKeyCode) {
      e.preventDefault();
      e.stopPropagation();
      this.onSaveNote();
    }
  };

  onChange = (e) => {
    this.setState({ noteInputValue: e.currentTarget.value });
  };

  onSaveNote = () => {
    if (this.state.noteInputValue !== '') {
      AppealNotesAPI.postNote({
        id: this.props.appealId,
        text: this.state.noteInputValue,
      }).then(this.refreshNotes);
    }
  };

  refreshNotes = () => {
    this.setState({ noteInputValue: '' });
    this.getNotes();
  };

  renderSaveNoteButton = () => {
    if (this.state.noteInputValue !== '') {
      return (
        <SubmitButton
          onClick={this.onSaveNote}
          className="submit__button appeal-notes__submitbtn"
          title="Save Note"
        />
      );
    }
  };

  render() {
    return (
      <div className="appeal-notes">
        <div className="appeal-notes__title">Notes</div>
        <TextArea
          value={this.state.noteInputValue}
          className="appeal-notes__input"
          placeholder="Type your note here..."
          onKeyDown={this.onKeyDown}
          onChange={this.onChange}
        ></TextArea>
        <div className="appeal-notes__list">
          <LoadingBlockHelper isLoading={this.state.notesAreLoading}>
            <AppealNoteItemList
              notes={this.state.notes}
              hasTopBorder={true}
              charMax={200}
            />
          </LoadingBlockHelper>
        </div>
        {this.renderSaveNoteButton()}
      </div>
    );
  }
}
