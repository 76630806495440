import { useCallback, useEffect, useState } from 'react';

import { snakeCaseKeys } from 'helpers/object';
import * as AnalyticsDashboardAPI from 'API/AnalyticsDashboardAPI';

export const useFetchClaimsPerformance = (filters, interval) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [claimsPerformance, setClaimsPerformance] = useState({});
  const fetch = useCallback(async () => {
    setLoading(true);
    setClaimsPerformance({});
    setError(null);
    try {
      const queryParam = {
        ...snakeCaseKeys({ ...filters, interval }),
      };

      const data = await AnalyticsDashboardAPI.fetchClaimsPerformanceOverview(
        queryParam
      );
      setClaimsPerformance(data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [filters, interval]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [filters, interval]);

  return { loading, error, claimsPerformance, fetch };
};
