import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { fetchPatients } from '../../../../API/CostEstimatorAPI';
import { handleError } from '../../../../helpers/errorHandler';
import LoadingIndicator from '../../../Shared/LoadingIndicator';

/**
 * Use patients hook.
 * @param {Object} filters
 * @returns {Promise}
 */
const useFetchPatients = (filters) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    setLoading(true);

    const fetch = async () => {
      setPatients([]);
      try {
        const data = await fetchPatients(filters);
        setError(null);
        setPatients(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response.status !== 404) {
          handleError(error);
        }
      }
    };

    fetch();
  }, [filters]);

  return { loading, error, patients };
};

const PatientList = (props) => {
  const { searchFilter, onSelectPatient } = props;

  const { loading, patients } = useFetchPatients(searchFilter);

  const handlePatientSelect = (patient) => onSelectPatient(patient);

  const extractPatientAddress = (patient) => {
    const address1 = patient.address1 ? `${patient.address1}, ` : '';
    const address2 = [
      patient.address2,
      patient.city,
      patient.state,
      patient.zip,
    ]
      .filter((v) => v)
      .join(' ');

    const patientAddress = `${address1}${address2}`;

    if (!patientAddress) {
      return 'N/A';
    }

    return patientAddress;
  };

  return (
    <div
      className="patient-cost-estimator-content no-print"
      datacy="patient-list"
    >
      <hr className="separator-line" />
      <div className="cost-estimator-create-estimates-table">
        <h4 className="patient-cost-estimator__title">
          Patient Search Results
        </h4>
        <table>
          <thead>
            <tr>
              <th>
                <span className="ml-20">Patient ID (Athena)</span>
              </th>
              <th>Name</th>
              <th>Date of Birth</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            {loading || patients.length === 0 ? (
              <tr>
                <td colSpan="4">
                  {loading ? (
                    <div className="position-relative">
                      <LoadingIndicator showing={true} />
                    </div>
                  ) : (
                    <span className="d-flex justify-content-center">
                      No records found
                    </span>
                  )}
                </td>
              </tr>
            ) : (
              patients.map((patient, idx) => (
                <tr key={idx}>
                  <td>
                    <label>
                      <input
                        name="patientId"
                        type="radio"
                        onChange={() => handlePatientSelect(patient)}
                        datacy={`patient-list-id-radio-${idx}`}
                      />
                      <span className="ml-8">{patient.id}</span>
                    </label>
                  </td>
                  <td>{`${patient.firstName} ${patient.lastName}`}</td>
                  <td>{patient.dob}</td>
                  <td>{extractPatientAddress(patient)}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

PatientList.propTypes = {
  searchFilter: PropTypes.object.isRequired,
  onSelectPatient: PropTypes.func.isRequired,
};

export default PatientList;
