import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import React, { useCallback } from 'react';
import ReactDOMServer from 'react-dom/server';

import notification from 'img/notification.svg';
import notificationBlue from 'img/notification--blue.svg';
import notificationBlack from 'img/notification--black.svg';
import disabledNotification from 'img/notification--disabled.svg';

import './style.css';

import { getFormattedDate } from 'helpers/dateFormats';

const ReminderIcon = ({
  remindersData,
  id,
  handleClick,
  userInfo,
  isClientAdmin,
}) => {
  const reminderData = remindersData?.[id];

  const getIcon = useCallback(() => {
    if (
      !reminderData ||
      (!reminderData.reminderId && !reminderData.isReminderToday)
    ) {
      return notification;
    }
    if (reminderData.isReminderToday) {
      return notificationBlue;
    }
    return notificationBlack;
  }, [reminderData]);

  if (!reminderData) {
    return null;
  }

  const dataTipId = `reminder-data-${id}`;
  const dataTipData =
    reminderData?.reminderId && reminderData?.reminderNote
      ? ReactDOMServer.renderToStaticMarkup(
          <table className="reminder-tooltip-table">
            <tr>
              <th>Note:</th>
              <td>{reminderData?.reminderNote}</td>
            </tr>
            <tr>
              <th>Reminder Date:</th>
              <td>{getFormattedDate(reminderData.reminderDate, 'll')}</td>
            </tr>
            <tr>
              <th>Assigned To:</th>
              <td>{reminderData.actionCompletedBy.fullName}</td>
            </tr>
          </table>
        )
      : 'Add Reminder';

  const disableReminderIcon =
    !isClientAdmin &&
    userInfo.username !== reminderData?.actionCompletedBy?.username;

  return (
    <>
      <div
        className={classnames('reminder-icon-wrapper cursor-pointer', {
          'disabled-reminder-icon': disableReminderIcon,
        })}
        onClick={handleClick}
        data-for={dataTipId}
        data-html="true"
        data-tip={dataTipData}
      >
        <img
          src={disableReminderIcon ? disabledNotification : getIcon()}
          alt="Reminder"
          className="reminder-icon"
        />
      </div>
      <ReactTooltip
        effect="solid"
        place="top"
        id={dataTipId}
        className="reminder-icon-tooltip"
      />
    </>
  );
};

ReminderIcon.propTypes = {
  remindersData: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  claimId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ReminderIcon;
