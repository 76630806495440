import React, { useState } from 'react';

import FileUpload from './FileUpload';
import ReviewAndUpload from './ReviewAndUpload/ReviewAndUpload';
import ConfirmExitReviewAndUpload from './ReviewAndUpload/Popups/ConfirmTabChangePopup';

const Step = {
  UPLOAD: 'UPLOAD',
  REVIEW: 'REVIEW',
};

const BatchUpload = ({
  dropdownOptions,
  setIsBatchFileUploaded,
  closeAddManualClaimPopup,
}) => {
  const [step, setStep] = useState(Step.UPLOAD);
  const [uploadedData, setUploadedData] = useState([]);
  const [showConfirmStepChangePopup, setShowConfirmStepChangePopup] =
    useState(false);

  const handleSuccess = (data) => {
    setIsBatchFileUploaded(data.length > 0);
    setUploadedData(data);
    setStep(Step.REVIEW);
  };

  const handleBackToUploadClick = () => {
    if (uploadedData.length > 0) {
      setShowConfirmStepChangePopup(true);
    } else {
      setStep(Step.UPLOAD);
    }
  };

  return (
    <div>
      {step === Step.UPLOAD && <FileUpload onSuccess={handleSuccess} />}
      {step === Step.REVIEW && (
        <ReviewAndUpload
          data={uploadedData}
          setData={setUploadedData}
          dropdownOptions={dropdownOptions}
          onBackToUploadClick={handleBackToUploadClick}
          onCloseClick={closeAddManualClaimPopup}
        />
      )}
      {showConfirmStepChangePopup && (
        <ConfirmExitReviewAndUpload
          onCancelClick={() => setShowConfirmStepChangePopup(false)}
          onConfirmClick={() => {
            setShowConfirmStepChangePopup(false);
            setIsBatchFileUploaded(false);
            setStep(Step.UPLOAD);
          }}
        />
      )}
    </div>
  );
};

export default BatchUpload;
