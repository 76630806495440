import { camelCase } from 'lodash';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { TableVirtuoso } from 'react-virtuoso';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import './style.css';

import Tabs from 'components/common/tabs';
import Tab from 'components/common/tabs/Tab';
import Button from 'components/common/button';
import BulkFindAndReplace from './BulkFindAndReplace';
import ConfirmUploadPopup from './Popups/ConfirmUploadPopup';
import ImportSuccessfulPopup from './Popups/ImportSuccessfulPopup';
import AppealioDialog from 'components/common/popup/AppealioDialog';

import { bulkUploadManualClaim } from 'API/ManualClaimAPI';

import {
  columns,
  MAIN_TABS,
  CLEAN_TABS,
  ERROR_SUB_TABS,
  ERROR_TYPE_MAPPING,
} from './constant';

import {
  snakeCaseKeys,
  sortArrayOfObjectsByPropertyOrder,
} from 'helpers/object';
import { handleError } from 'helpers/errorHandler';
import IndividualRowEditor from './IndividualRowEditor';

const DEFAULT_BULK_FIND_AND_REPLACE_POPUP = {
  show: false,
  errorTypeToFix: null,
  rowKey: null,
};
const DEFAULT_INDIVIDUAL_ROW_EDITOR_POPUP = {
  show: false,
  errorTypeToFix: null,
  rowKey: null,
};
const DEFAULT_CELL_ERROR_POPUP = {
  show: false,
  message: 'this is test message',
};

const ReviewAndUpload = ({
  data,
  setData,
  onCloseClick,
  dropdownOptions,
  onBackToUploadClick,
}) => {
  const mainTabsContainerRef = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeMainTab, setActiveMainTab] = useState(MAIN_TABS.CLEAN_ROWS);
  const [showConfirmUploadPopup, setShowConfirmUploadPopup] = useState(false);
  const [showImportSuccessfulPopup, setImportSuccessfulPopup] = useState(false);
  const [activeCleanSubTab, setActiveCleanSubTab] = useState(
    CLEAN_TABS.NEW_ROWS
  );
  const [activeErrorSubTab, setActiveErrorSubTab] = useState(
    ERROR_SUB_TABS.ALL_ERRORS
  );
  const [subTabLeftMargin, setSubTabLeftMargin] = useState(0);
  const [bulkFindAndReplacePopup, setBulkFindAndReplacePopup] = useState(
    DEFAULT_BULK_FIND_AND_REPLACE_POPUP
  );
  const [cellErrorPopup, setCellErrorPopup] = useState(
    DEFAULT_CELL_ERROR_POPUP
  );
  const [individualRowEditorPopup, setIndividualRowEditorPopup] = useState(
    DEFAULT_INDIVIDUAL_ROW_EDITOR_POPUP
  );

  const {
    allRowsCount,
    errorRowsCount,
    cleanRowsCount,
    cleanNewRowsCount,
    cleanDuplicateRowsCount,
  } = useMemo(() => {
    return data.reduce(
      (counts, row) => {
        counts.allRowsCount++;
        if (row?.errors?.length > 0) {
          counts.errorRowsCount++;
        } else {
          counts.cleanRowsCount++;
          if (row?.duplicate) {
            counts.cleanDuplicateRowsCount++;
          } else {
            counts.cleanNewRowsCount++;
          }
        }
        return counts;
      },
      {
        allRowsCount: 0,
        errorRowsCount: 0,
        cleanRowsCount: 0,
        cleanNewRowsCount: 0,
        cleanDuplicateRowsCount: 0,
      }
    );
  }, [data]);

  useEffect(() => {
    if (cleanRowsCount === 0 && errorRowsCount > 0) {
      setActiveMainTab(MAIN_TABS.ERRORS);
    }
    if (cleanRowsCount > 0 && cleanNewRowsCount === 0) {
      setActiveCleanSubTab(CLEAN_TABS.DUPLICATE_ROWS);
    }
  }, [cleanNewRowsCount, cleanRowsCount, errorRowsCount, activeMainTab]);

  useEffect(() => {
    const activeTab = document.querySelector(
      '.tab-item.claims-csv-review-tab.active'
    );
    const tabsContainer = mainTabsContainerRef.current;

    if (activeTab && tabsContainer) {
      const activeTabOffsetLeft = activeTab.offsetLeft;
      const containerOffsetLeft = tabsContainer.offsetLeft;
      setSubTabLeftMargin(activeTabOffsetLeft - containerOffsetLeft);
    }
  }, [activeMainTab]);

  const onMainTabChange = (tab) => {
    setActiveMainTab(tab);
    setActiveCleanSubTab(CLEAN_TABS.NEW_ROWS);
    setActiveErrorSubTab(ERROR_SUB_TABS.ALL_ERRORS);
  };

  const getFilteredData = () => {
    if (activeMainTab === MAIN_TABS.CLEAN_ROWS) {
      switch (activeCleanSubTab) {
        case CLEAN_TABS.NEW_ROWS:
          return data.filter(
            (row) => !row.duplicate && row.errors.length === 0
          );
        case CLEAN_TABS.DUPLICATE_ROWS:
          return data.filter((row) => row.duplicate && row.errors.length === 0);
        default:
          return data.filter((row) => row.errors.length === 0);
      }
    } else if (activeMainTab === MAIN_TABS.ERRORS) {
      if (activeErrorSubTab !== ERROR_SUB_TABS.ALL_ERRORS) {
        return data.filter((row) =>
          row.errors.some((error) => error.error === activeErrorSubTab)
        );
      }
      return data.filter((row) => row.errors.length > 0);
    }
    return data;
  };

  const mainTabs = [
    {
      title: `Clean Rows (${cleanRowsCount})`,
      onClick: () => onMainTabChange(MAIN_TABS.CLEAN_ROWS),
      isActive: activeMainTab === MAIN_TABS.CLEAN_ROWS,
      disabled: cleanRowsCount === 0,
      className: 'ml-10',
    },
    {
      title: `Errors (${errorRowsCount})`,
      onClick: () => onMainTabChange(MAIN_TABS.ERRORS),
      isActive: activeMainTab === MAIN_TABS.ERRORS,
      className: 'claims-csv-review-tab--error',
      disabled: errorRowsCount === 0,
    },
  ];

  const cleanSubTabs = [
    {
      title: `New Rows (${cleanNewRowsCount})`,
      onClick: () => setActiveCleanSubTab(CLEAN_TABS.NEW_ROWS),
      isActive: activeCleanSubTab === CLEAN_TABS.NEW_ROWS,
      className: 'ml-10',
      disabled: cleanNewRowsCount === 0,
    },
    {
      title: `Duplicate Rows (${cleanDuplicateRowsCount})`,
      onClick: () => setActiveCleanSubTab(CLEAN_TABS.DUPLICATE_ROWS),
      isActive: activeCleanSubTab === CLEAN_TABS.DUPLICATE_ROWS,
      disabled: cleanDuplicateRowsCount === 0,
    },
  ];

  const errorTypes = useMemo(() => {
    return [
      ...new Set(data.flatMap((row) => row.errors.map((err) => err.error))),
    ];
  }, [data]);

  const errorTypeCountMap = useMemo(() => {
    return errorTypes.reduce((acc, type) => {
      acc[type] = data.filter((row) =>
        row.errors.some((error) => error.error === type)
      ).length;
      return acc;
    }, {});
  }, [data, errorTypes]);

  const filteredData = getFilteredData();

  const errorSubTabs = useMemo(() => {
    return [
      {
        title: `All Errors (${errorRowsCount})`,
        onClick: () => setActiveErrorSubTab(ERROR_SUB_TABS.ALL_ERRORS),
        isActive: activeErrorSubTab === ERROR_SUB_TABS.ALL_ERRORS,
        className: classNames({
          'claims-csv-review-tab--error':
            activeErrorSubTab === ERROR_SUB_TABS.ALL_ERRORS,
        }),
      },
    ].concat(
      sortArrayOfObjectsByPropertyOrder(
        errorTypes.map((type) => {
          const title = `${ERROR_TYPE_MAPPING[type] || type} (${
            errorTypeCountMap[type]
          })`;
          return {
            title,
            type,
            onClick: () => setActiveErrorSubTab(type),
            isActive: activeErrorSubTab === type,
            className: classNames({
              'claims-csv-review-tab--error': activeErrorSubTab === type,
            }),
          };
        }),
        Object.keys(ERROR_TYPE_MAPPING),
        'type'
      )
    );
  }, [activeErrorSubTab, errorRowsCount, errorTypeCountMap, errorTypes]);

  const handleUpload = async () => {
    try {
      setIsSubmitting(true);
      const cleanRows = snakeCaseKeys(
        data.filter((row) => row.errors.length === 0)
      );
      await bulkUploadManualClaim(cleanRows);
      setImportSuccessfulPopup(true);
    } catch (error) {
      handleError(error);
    } finally {
      setShowConfirmUploadPopup(false);
      setIsSubmitting(false);
    }
  };

  const handleCellClick = (key, row) => {
    if (row.errors.some((e) => camelCase(e.rowKey) === camelCase(key))) {
      if (key === 'renderingProvider') {
        if (row.errors.some((e) => e.rowKey === 'practice')) {
          setCellErrorPopup({
            show: true,
            message:
              'Please correct the Practice error before editing the Rendering Provider.',
          });
          return;
        }
      } else if (key === 'billingProvider') {
        if (
          row.errors.some((e) => e.rowKey === 'practice') ||
          row.errors.some((e) => camelCase(e.rowKey) === 'renderingProvider')
        ) {
          setCellErrorPopup({
            show: true,
            message:
              'Please correct the Practice error and Rendering Provider error before editing the Billing Provider.',
          });
          return;
        }
      }

      const errorTypeToFix = row.errors.find(
        (e) => camelCase(e.rowKey) === camelCase(key)
      ).error;

      if (
        errorTypeToFix === 'MISSING_DATA' ||
        errorTypeToFix === 'INCORRECT_FORMATTING' ||
        errorTypeToFix === 'DATA_TOO_LONG' ||
        errorTypeToFix === 'INVALID_DATA'
      ) {
        setIndividualRowEditorPopup({
          show: true,
          errorTypeToFix,
          rowKey: key,
        });
      } else {
        setBulkFindAndReplacePopup({
          show: true,
          errorTypeToFix,
          rowKey: key,
        });
      }
    }
  };
  const onBulkFindAndReplace = (newData) => {
    setData(newData);
    setBulkFindAndReplacePopup(DEFAULT_BULK_FIND_AND_REPLACE_POPUP);
    setActiveErrorSubTab(ERROR_SUB_TABS.ALL_ERRORS);
  };
  const onIndividualRowUpdate = (newData) => {
    setData(newData);
    setIndividualRowEditorPopup(DEFAULT_INDIVIDUAL_ROW_EDITOR_POPUP);
    setActiveErrorSubTab(ERROR_SUB_TABS.ALL_ERRORS);
  };

  const hasModifierCodeData = useMemo(
    () => data.some((row) => row.modifierCode),
    [data]
  );

  const columnsToRender = useMemo(() => {
    if (hasModifierCodeData) {
      return columns.concat({ header: 'Modifier Code', key: 'modifierCode' });
    }
    return columns;
  }, [hasModifierCodeData]);

  const renderTableHeader = () => {
    return (
      <tr>
        {columnsToRender.map(({ header }) => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    );
  };

  const renderTableRow = (index, row) => {
    return (
      <React.Fragment>
        {columnsToRender.map(({ key }) => {
          const errorHasMissingData = row.errors.some(
            (e) => e.rowKey === key && e.error === 'MISSING_DATA'
          );

          const rowHasError = row.errors.some(
            (e) => camelCase(e.rowKey) === camelCase(key)
          );

          return (
            <td
              key={`${index}-${key}`}
              data-tip={row[key] || ''}
              data-delay-show="350"
              className={rowHasError ? 'error-cell' : ''}
              onClick={() => handleCellClick(key, row)}
            >
              {row[key] || (errorHasMissingData ? 'Missing Data' : '-')}
            </td>
          );
        })}
        <ReactTooltip effect="solid" place="top" />
      </React.Fragment>
    );
  };

  return (
    <div className="claims-csv-review">
      <div>
        <div className="d-flex" ref={mainTabsContainerRef}>
          <Tab
            title={`All Rows (${allRowsCount})`}
            onClick={() => {}}
            className="claims-csv-review-tab claims-csv-review-tab--all"
          />
          <Tabs
            tabs={mainTabs}
            className="claims-csv-review-tabs flex-grow-1"
            customTabClassName="claims-csv-review-tab"
          />
          {activeMainTab === MAIN_TABS.ERRORS && (
            <span className="claims-csv-review-tab--error-message">
              <span className="fw-bold">Note:</span> To resolve any errors,
              please click on the corresponding cell.
            </span>
          )}
        </div>

        <div
          style={{
            marginLeft: subTabLeftMargin,
          }}
        >
          {activeMainTab === MAIN_TABS.CLEAN_ROWS && (
            <div className="d-flex">
              <Tab
                title={`All Rows (${cleanRowsCount})`}
                className="claims-csv-review-tab claims-csv-review-tab--sub-tab claims-csv-review-tab--all claims-csv-review-sub-tab--all"
              />
              <Tabs
                tabs={cleanSubTabs}
                className="claims-csv-review-tabs claims-csv-review-sub-tabs flex-grow-1"
                customTabClassName="claims-csv-review-tab claims-csv-review-tab--sub-tab"
              />
            </div>
          )}
          {activeMainTab === MAIN_TABS.ERRORS && (
            <Tabs
              tabs={errorSubTabs}
              className="claims-csv-review-tabs claims-csv-review-sub-tabs"
              customTabClassName="claims-csv-review-tab claims-csv-review-tab--sub-tab"
            />
          )}
        </div>

        <div className="claims-csv-review-table-container mt-10">
          <TableVirtuoso
            data={filteredData}
            itemContent={renderTableRow}
            className="claims-csv-review-table"
            style={{ height: '440px', width: '100%' }}
            fixedHeaderContent={renderTableHeader}
          />
        </div>
      </div>

      <div className="d-flex justify-content--space-between mt-16">
        <Button
          className="ap-button--secondary justify-content-center mb-0"
          title="<- Back to Upload"
          onClick={onBackToUploadClick}
        />
        <Button
          className="ap-button--secondary justify-content-center mb-0"
          title={`Upload ${cleanRowsCount} Clean Rows`}
          disabled={cleanRowsCount === 0}
          onClick={() => setShowConfirmUploadPopup(true)}
        />
      </div>
      {showImportSuccessfulPopup && (
        <ImportSuccessfulPopup
          onCloseClick={() => onCloseClick(true)}
          cleanRowsCount={cleanRowsCount}
        />
      )}
      {showConfirmUploadPopup && (
        <ConfirmUploadPopup
          cleanRowsCount={cleanRowsCount}
          cleanDuplicateRowsCount={cleanDuplicateRowsCount}
          isSubmitting={isSubmitting}
          onConfirmClick={handleUpload}
          onCancelClick={() => {
            setShowConfirmUploadPopup(false);
          }}
        />
      )}

      {bulkFindAndReplacePopup.show && (
        <BulkFindAndReplace
          data={data}
          errorTypeToFix={bulkFindAndReplacePopup.errorTypeToFix}
          rowKey={bulkFindAndReplacePopup.rowKey}
          onFindAndReplace={onBulkFindAndReplace}
          dropdownOptions={dropdownOptions}
          onCloseClick={() =>
            setBulkFindAndReplacePopup(DEFAULT_BULK_FIND_AND_REPLACE_POPUP)
          }
        />
      )}
      {individualRowEditorPopup.show && (
        <IndividualRowEditor
          data={data}
          errorTypeToFix={individualRowEditorPopup.errorTypeToFix}
          rowKey={individualRowEditorPopup.rowKey}
          onIndividualRowUpdate={onIndividualRowUpdate}
          onCloseClick={() =>
            setIndividualRowEditorPopup(DEFAULT_INDIVIDUAL_ROW_EDITOR_POPUP)
          }
          dropdownOptions={dropdownOptions}
        />
      )}
      {cellErrorPopup.show && (
        <AppealioDialog
          onClosePressed={() => setCellErrorPopup(DEFAULT_CELL_ERROR_POPUP)}
        >
          <div className="text-center">{cellErrorPopup.message}</div>
        </AppealioDialog>
      )}
    </div>
  );
};

export default ReviewAndUpload;
