import moment from 'moment';
import {
  isUserAuthorizedForAnalytics,
  isUserAuthorizedForSubmission,
  isAuthorizedForDeliveryTracker,
  isUserAuthorizedForDenialsPage,
  isUserAuthorizedForDashboard,
} from 'Auth/AuthUtils';

import {
  REMINDER_STATUS,
  UHC_CLAIMS_STATUS,
  USER_ROLES_VALUE,
} from 'constants/appConstants';

import * as routes from 'constants/routes';
import { DATE_FORMAT_YYYY_MM_DD } from 'helpers/dateFormats';

export const SEARCH_BY_PATIENT_DROPDOWN_OPTIONS = [
  {
    value: 'patient',
    label: 'Patient',
  },
  {
    value: 'patient_id',
    label: 'Patient Id',
  },
  {
    value: 'claim_control_number',
    label: 'Payer Claim ID',
  },
  {
    value: 'claim_number',
    label: 'Claim ID',
  },
  {
    value: 'voucher_id',
    label: 'Voucher Number',
  },
];

export const SEARCH_BY_DATE_DROPDOWN_OPTIONS = [
  {
    value: 'edi_payment_date',
    label: 'EOB Date',
  },
  {
    value: 'service_date',
    label: 'Service Date',
  },
];

export const SEARCH_DATE_RANGE_OPTIONS = {
  fromDate: moment().subtract(1, 'year').format(DATE_FORMAT_YYYY_MM_DD),
  toDate: moment().format(DATE_FORMAT_YYYY_MM_DD),
};

export const SEARCH_GENERIC_KEY = 'search';

export const TIME_PERIOD_OPTIONS = [
  {
    value: 'month',
    label: 'By Month',
  },
  {
    value: 'quarter',
    label: 'By Quarter',
  },
];

export const ZERO_PAY_DENIALS_OPTIONS = {
  option1: 'All',
  option2: 'Zero Pay',
};

export const CLAIM_STATUS_CHECK_CRON_OPTIONS = [
  {
    value: 'EVERYDAY',
    label: 'Every Day',
  },
  {
    value: 'EVERY_ALTERNATIVE_DAY',
    label: 'Every Two Days',
  },
  {
    value: 'EVERY_THIRD_DAY',
    label: 'Every Three Days',
  },
];

export const CLAIM_STATUS_CHECK_FILTER_OPTIONS = [
  {
    value: UHC_CLAIMS_STATUS.FINALIZED,
    label: 'Finalized',
  },
  {
    value: UHC_CLAIMS_STATUS.PENDING,
    label: 'Pending',
  },
  {
    value: UHC_CLAIMS_STATUS.DENIED,
    label: 'Denied',
  },
  {
    value: UHC_CLAIMS_STATUS.IN_PROCESS,
    label: 'In Process',
  },
  {
    value: UHC_CLAIMS_STATUS.MULTIPLE,
    label: 'Multiple Statuses',
  },
];

export const USER_ROLE_OPTIONS = [
  { label: USER_ROLES_VALUE.AGENT, value: USER_ROLES_VALUE.AGENT },
  { label: USER_ROLES_VALUE.MANAGER, value: USER_ROLES_VALUE.MANAGER },
  {
    label: USER_ROLES_VALUE.SUPERVISOR,
    value: USER_ROLES_VALUE.SUPERVISOR,
  },
];

export const HOME_PAGE_OPTIONS = [
  {
    value: 'DELIVERY_TRACKER',
    label: 'Delivery Tracker',
    checkPermission: (userInfo) => isAuthorizedForDeliveryTracker(userInfo),
    route: routes.SUBMISSIONS,
  },
  {
    value: 'DENIALS_QUEUE',
    label: 'Denials Queue',
    checkPermission: (userInfo) => isUserAuthorizedForDenialsPage(userInfo),
    route: routes.DENIED_QUEUE,
  },
  {
    value: 'PENDING_ACTIONS',
    label: 'Pending Actions',
    checkPermission: (userInfo) => isUserAuthorizedForDenialsPage(userInfo),
    route: routes.DENIED_QUEUE_LOGS,
  },
  {
    value: 'PENDING_ACTIONS_COMPLETED',
    label: 'Completed Actions',
    checkPermission: (userInfo) => isUserAuthorizedForDenialsPage(userInfo),
    route: routes.DENIED_QUEUE_COMPLETED_LOGS,
  },
  {
    value: 'IN_PROGRESS_SUBMISSIONS',
    label: 'In Progress Submissions',
    checkPermission: (userInfo) => isUserAuthorizedForSubmission(userInfo),
    route: routes.SUBMISSIONS_IN_PROGRESS,
  },
  {
    value: 'TEAM_PERFORMANCE_DASHBOARD',
    label: 'Team Performance',
    checkPermission: (userInfo) =>
      isUserAuthorizedForDashboard(userInfo) &&
      isUserAuthorizedForSubmission(userInfo),
    route: routes.MANAGER_DASHBOARD_TEAM,
  },
  {
    value: 'CLAIM_ANALYTICS_DASHBOARD',
    label: 'Claims Analytics',
    checkPermission: (userInfo) =>
      isUserAuthorizedForDashboard(userInfo) &&
      isUserAuthorizedForAnalytics(userInfo),
    route: routes.ANALYTICS,
  },
];

export const REMINDER_STATUS_OPTIONS = [
  { value: null, label: 'All' },
  { value: REMINDER_STATUS.PAST_DUE, label: 'Past Due' },
  { value: REMINDER_STATUS.TODAY, label: 'Today' },
  {
    value: REMINDER_STATUS.UPCOMING,
    label: 'Upcoming (Within 3 Business Days)',
  },
];
