import React from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import Input from 'components/common/input';
import { Field } from 'redux-form/immutable';
import { MdInfoOutline } from 'react-icons/md';

import { states } from 'components/CreateAppeal/state-mock-data';

import { renderReduxDropdownV2 } from 'components/CreateAppeal/reduxReactSelect';

const stateOptions = states.map((state) => ({
  ...state,
  label: state.value,
}));

const renderRadioField = ({ input, label, className, radioButtonValue }) => {
  return (
    <label className={classnames('fw-normal', className)}>
      <input
        type="radio"
        {...input}
        className="mr-4"
        onChange={(e) => {
          input.onChange(radioButtonValue);
        }}
        checked={input.value === radioButtonValue}
      />

      {label}
    </label>
  );
};

export const renderCheckbox = ({ input, label, className }) => {
  return (
    <label className={classnames('fw-normal', className)}>
      <input
        {...input}
        type="checkbox"
        className="mr-4"
        checked={input.value}
      />
      {label}
    </label>
  );
};

const MailFormFields = () => (
  <div>
    <div className="mb-10">
      <label className="fw-bold">Print/Mail Option</label>
      <div className="mb-8">
        <Field
          name="color"
          component={renderRadioField}
          value="BLACK_AND_WHITE"
          radioButtonValue="BLACK_AND_WHITE"
          label="B&W"
          className="mr-8"
        />
        <Field
          name="color"
          component={renderRadioField}
          value="COLOR"
          radioButtonValue="COLOR"
          label="Color"
        />
      </div>
      <div className="d-flex align-items-center">
        <Field
          name="certified"
          component={renderCheckbox}
          label="Send as Certified Mail"
        />
        <MdInfoOutline
          size="16"
          className="ml-8"
          data-tip={
            '<span>USPS tracking URL available within Delivery Tracker</span><br/><span>Additional fees applied</span>'
          }
          data-html={true}
        />
        <ReactTooltip effect="solid" place="top" />
      </div>
    </div>
    <div className="mb-10">
      <label>Sides Option</label>
      <div>
        <Field
          name="side"
          component={renderRadioField}
          value="SINGLE"
          radioButtonValue="SINGLE"
          label="Single-Sided"
          className="mr-8"
        />
        <Field
          name="side"
          component={renderRadioField}
          value="DOUBLE"
          radioButtonValue="DOUBLE"
          label="Double-Sided"
        />
      </div>
    </div>
    <div className="mb-10">
      <label>Return Address</label>
      <div className="row mb-8">
        <div className="col-lg-6">
          <Field name="return_address_name" component={Input} label="Name: *" />
        </div>
      </div>
      <div className="row mb-8">
        <div className="col-lg-6">
          <Field
            name="return_address_line1"
            component={Input}
            label="Address Line 1: *"
          />
        </div>
        <div className="col-lg-6">
          <Field
            name="return_address_line2"
            component={Input}
            label="Address Line 2:"
          />
        </div>
      </div>
      <div className="row mb-8">
        <div className="col-lg-4">
          <Field name="return_city" component={Input} label="City: *" />
        </div>
        <div className="col-lg-4">
          <label className="ap-input-label">State: *</label>

          <Field
            name="return_state"
            label="State: *"
            dropdownOptions={stateOptions}
            component={renderReduxDropdownV2}
          />
        </div>
        <div className="col-lg-4">
          <Field name="return_zipcode" component={Input} label="Zip: *" />
        </div>
      </div>
    </div>
    <div className="mb-10">
      <label>Shipping Address</label>
      <div className="row mb-8">
        <div className="col-lg-6">
          <Field name="name" component={Input} label="Name: *" />
        </div>
      </div>
      <div className="row mb-8">
        <div className="col-lg-6">
          <Field name="attention_line" component={Input} label="Attn:" />
        </div>
      </div>
      <div className="row mb-8">
        <div className="col-lg-6">
          <Field
            name="address_line1"
            component={Input}
            label="Address Line 1: *"
          />
        </div>
        <div className="col-lg-6">
          <Field
            name="address_line2"
            component={Input}
            label="Address Line 2:"
          />
        </div>
      </div>
      <div className="row mb-8">
        <div className="col-lg-4">
          <Field name="city" component={Input} label="City: *" />
        </div>
        <div className="col-lg-4">
          <label className="ap-input-label">State: *</label>
          <Field
            name="state"
            label="State"
            dropdownOptions={stateOptions}
            component={renderReduxDropdownV2}
          />
        </div>
        <div className="col-lg-4">
          <Field name="zipcode" component={Input} label="Zip: *" />
        </div>
      </div>
    </div>
  </div>
);

export default MailFormFields;
