import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import './style.css';

import { downloadFile } from 'API/Config';
import * as routes from 'constants/routes';
import { DOCUMENT_CATEGORY, SUBMISSION_TYPE } from 'constants/appConstants';

import { handleError } from 'helpers/errorHandler';

import LetterDropdown from './LetterDropdown';
import * as toast from 'components/Shared/toast';
import LetterFormOption from './LetterFormOption';
import CheckboxOption from 'components/common/checkbox';
import { AppealioPopupWithFooter } from 'components/common/popup';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import IdenticalAppealConfirmationPopup from './IdenticalAppealConfirmationPopup';
import CustomDropdown from 'components/common/dropdown/ButtonDropdown/CustomDropdown';

import { useFetchLetterFormsAndDocuments } from './hook';

import { transformLinkPathToBasename } from 'API/Serializers/Transforms';
import { createSameClaimDifferentAttachment } from 'API/DuplicateClaimAPI';

let SameClaimEditAttachmentsPopup = (props) => {
  const {
    onClosePressed,
    patientName = 'N/A',
    claimId = 'N/A',
    appealId,
    setPreviewLetterForm,
    submissionId,
    handleSubmit,
    change,
    submissionType,
    hasFormLettersOrDocuments,
  } = props;
  const [isCreatingAppeal, setIsCreatingAppeal] = useState(false);
  const [isAttachmentOptionSelected, setIsAttachmentOptionSelected] =
    useState(true);
  const [isEmailAttachmentOptionSelected, setIsEmailAttachmentOptionSelected] =
    useState(true);
  const [isEobDocumentAttached, setIsEobDocumentAttached] = useState(false);

  const {
    letterFormsAndDocuments,
    isEobDocumentAttached: hasEobDocumentAttached,
    loading: isLoadingLetterFormsAndDocuments,
  } = useFetchLetterFormsAndDocuments(appealId);
  const [closeInProgressDuplicateClaim, setCloseInProgressDuplicateClaim] =
    useState(false);

  const appealLetterForms = useMemo(
    () =>
      letterFormsAndDocuments.filter((item) => item.type === 'AppealLetter'),
    [letterFormsAndDocuments]
  );

  const appealDocuments = useMemo(
    () =>
      letterFormsAndDocuments.filter(
        (item) =>
          item.type === 'AppealDocument' &&
          item.attributes?.documentCategory ===
            DOCUMENT_CATEGORY.APPEAL_ATTACHMENT
      ),
    [letterFormsAndDocuments]
  );

  const emailAttachments = useMemo(
    () =>
      letterFormsAndDocuments.filter(
        (item) =>
          item.type === 'AppealDocument' &&
          item.attributes?.documentCategory ===
            DOCUMENT_CATEGORY.EMAIL_ATTACHMENT
      ),
    [letterFormsAndDocuments]
  );

  const appealLetterFormsOptions = useMemo(
    () =>
      appealLetterForms.map((item) => ({
        ...item,
        value: item.id,
        label: item.attributes.name,
      })),
    [appealLetterForms]
  );

  const appealDocumentsOptions = useMemo(
    () =>
      appealDocuments.map((item) => ({
        ...item,
        value: item.id,
        label: transformLinkPathToBasename(item?.attributes?.linkPath),
      })),
    [appealDocuments]
  );
  const emailAttachmentsOptions = useMemo(
    () =>
      emailAttachments.map((item) => ({
        ...item,
        value: item.id,
        label: transformLinkPathToBasename(item?.attributes?.linkPath),
      })),
    [emailAttachments]
  );

  useEffect(() => {
    if (appealLetterFormsOptions.length > 0) {
      change('letterIds', appealLetterFormsOptions);
    }
    // eslint-disable-next-line
  }, [appealLetterFormsOptions]);

  useEffect(() => {
    if (isAttachmentOptionSelected && appealDocumentsOptions.length > 0) {
      change('documentIds', appealDocumentsOptions);
    }
    // eslint-disable-next-line
  }, [isAttachmentOptionSelected, appealDocumentsOptions]);

  useEffect(() => {
    if (isEmailAttachmentOptionSelected && emailAttachments.length > 0) {
      change('emailDocumentIds', emailAttachmentsOptions);
    }
    // eslint-disable-next-line
  }, [
    isEmailAttachmentOptionSelected,
    emailAttachments,
    emailAttachmentsOptions,
  ]);

  useEffect(() => {
    if (hasEobDocumentAttached) {
      setIsEobDocumentAttached(true);
    }
  }, [hasEobDocumentAttached]);

  const onSubmit = async (formValues) => {
    const values = formValues.toJS();
    const { documentIds = [], letterIds, emailDocumentIds = [] } = values;
    const formData = {
      documentIds:
        [...documentIds, ...emailDocumentIds]?.map(({ value }) => value) || [],
      letterIds: letterIds?.map(({ value }) => value) || [],
      submissionId,
      includeEobDocument: isEobDocumentAttached,
    };

    try {
      setIsCreatingAppeal(true);
      const { appealId } = await createSameClaimDifferentAttachment(formData);
      setIsCreatingAppeal(false);
      toast.success({
        title: 'Success',
        message: `Successfully created duplicate ${
          submissionType === SUBMISSION_TYPE.RECORD ? 'record' : 'appeal'
        }.`,
      });
      onClosePressed();

      props.actions.push(`${routes.CREATE_APPEAL_REVIEW}?appealId=${appealId}`);
    } catch (error) {
      setIsCreatingAppeal(false);
      handleError(error);
    }
  };

  const createBtnTitle =
    submissionType === SUBMISSION_TYPE.RECORD
      ? 'Create Record'
      : 'Create Appeal';

  const onConfirmationPopup = useCallback(() => {
    setCloseInProgressDuplicateClaim(false);
  }, []);

  const disableCreateButton =
    !isEobDocumentAttached && !hasFormLettersOrDocuments;

  return (
    <AppealioPopupWithFooter
      className="appealio-popup--v3 appealio-duplicate-claim-popup"
      onClosePressed={() => setCloseInProgressDuplicateClaim(true)}
      title="Duplicate Submission for Same Claim, Edit Attachments"
      isFooterOutside={false}
      datacy="add-user-AppealioPopupWithFooter"
    >
      <div className="claim-info">
        <table className="ap-info-table">
          <tbody>
            <tr>
              <td>
                {submissionType === SUBMISSION_TYPE.RECORD
                  ? 'Claim ID'
                  : 'Payer Claim ID'}
              </td>
              <td>{claimId}</td>
            </tr>
            <tr>
              <td>Patient Name</td>
              <td>{patientName}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="ml-4 mt-16 mb-16">
        Select the attachments you'd like to include below:
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="claim-options__wrapper">
          <div className="claim-options row no-gutter">
            <div className="col-md-4">
              <CheckboxOption
                label="Letter/PDF Form templates"
                withoutBackground={true}
                checked={true}
                disabled={true}
              />
            </div>
            <div className="col-md-8">
              <Field
                name="letterIds"
                component={LetterDropdown}
                className="full-width"
                options={appealLetterFormsOptions}
                isMulti
                isDisabled={
                  isLoadingLetterFormsAndDocuments ||
                  !appealLetterFormsOptions.length
                }
                components={{
                  Option: (item) => (
                    <LetterFormOption
                      setPreviewLetterForm={setPreviewLetterForm}
                      {...item}
                    />
                  ),
                }}
                hideClearAllButton
                labelForAllSelectedOption="All Letters/Forms"
                placeholder="Select Letters/Forms"
              />
            </div>
          </div>
          <div className="claim-options row no-gutter">
            <div className="col-md-4">
              <CheckboxOption
                label="Attachments"
                withoutBackground={true}
                checked={isAttachmentOptionSelected}
                onChange={() => {
                  setIsAttachmentOptionSelected((prevState) => {
                    const newState = !prevState;
                    if (!newState) {
                      change('documentIds', []);
                    }
                    return newState;
                  });
                }}
                disabled={
                  isLoadingLetterFormsAndDocuments ||
                  !appealDocumentsOptions.length
                }
              />
            </div>
            <div className="col-md-8">
              <Field
                name="documentIds"
                component={CustomDropdown}
                className="full-width"
                options={appealDocumentsOptions}
                isMulti
                isDisabled={
                  !isAttachmentOptionSelected ||
                  isLoadingLetterFormsAndDocuments ||
                  !appealDocumentsOptions.length
                }
                components={{
                  Option: (item) => (
                    <LetterFormOption
                      setPreviewLetterForm={setPreviewLetterForm}
                      {...item}
                    />
                  ),
                }}
                placeholder="Select Attachments"
                labelForAllSelectedOption="All Attachments"
              />
            </div>
          </div>
          {emailAttachmentsOptions.length > 0 && (
            <div className="claim-options row no-gutter">
              <div className="col-md-4">
                <CheckboxOption
                  label="Excel Attachments"
                  withoutBackground={true}
                  checked={isEmailAttachmentOptionSelected}
                  onChange={() => {
                    setIsEmailAttachmentOptionSelected((prevState) => {
                      const newState = !prevState;
                      if (!newState) {
                        change('emailDocumentIds', []);
                      }
                      return newState;
                    });
                  }}
                  disabled={
                    isLoadingLetterFormsAndDocuments ||
                    !emailAttachmentsOptions.length
                  }
                />
              </div>
              <div className="col-md-8">
                <Field
                  name="emailDocumentIds"
                  component={CustomDropdown}
                  className="full-width"
                  options={emailAttachmentsOptions}
                  isMulti
                  isDisabled={
                    !isEmailAttachmentOptionSelected ||
                    isLoadingLetterFormsAndDocuments ||
                    !emailAttachmentsOptions.length
                  }
                  components={{
                    Option: (item) => (
                      <LetterFormOption
                        onClickPreview={(item) => {
                          downloadFile(
                            item.attributes.link,
                            item.attributes.name
                          );
                        }}
                        previewLabel="Download"
                        {...item}
                      />
                    ),
                  }}
                  placeholder="Select"
                  labelForAllSelectedOption="All"
                />
              </div>
            </div>
          )}

          {hasEobDocumentAttached && (
            <div className="claim-options row no-gutter">
              <div className="col-md-4">
                <CheckboxOption
                  label="Attach EOB"
                  className="mt-10"
                  withoutBackground={true}
                  checked={isEobDocumentAttached}
                  onChange={() => {
                    setIsEobDocumentAttached((prevState) => !prevState);
                  }}
                />
              </div>
              <div className="col-md-8" />
            </div>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-flex-end mt-20">
          {isCreatingAppeal && (
            <LoadingBlockHelper className="mr-44" isLoading={true} />
          )}
          <button
            type="submit"
            className="ap-button ap-button--secondary"
            disabled={disableCreateButton || isCreatingAppeal}
          >
            {createBtnTitle}
          </button>
        </div>
      </form>

      {closeInProgressDuplicateClaim && (
        <IdenticalAppealConfirmationPopup
          onClosePressed={onClosePressed}
          onConfirmationPopup={onConfirmationPopup}
        />
      )}
    </AppealioPopupWithFooter>
  );
};

SameClaimEditAttachmentsPopup.propTypes = {
  onClosePressed: PropTypes.func.isRequired,
  patientName: PropTypes.string,
  claimId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  appealId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setPreviewLetterForm: PropTypes.func.isRequired,
  submissionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func,
};

const FORM_NAME = 'duplicate-claim-edit-attachments-popup';
SameClaimEditAttachmentsPopup = reduxForm({
  enableReinitialize: true,
  form: FORM_NAME,
})(SameClaimEditAttachmentsPopup);

function mapStateToProps(state) {
  const selector = formValueSelector(FORM_NAME);
  const formLetters = selector(state, 'letterIds') || [];
  const formDocuments = selector(state, 'documentIds') || [];

  return {
    hasFormLettersOrDocuments:
      formLetters.length > 0 || formDocuments.length > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        push,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SameClaimEditAttachmentsPopup);
