export const KEY_AUTH_TOKEN = 'key_auth_token';
export const SIGNUP_FORM_DATA_KEY = 'SIGNUP_FORM_DATA_KEY';
export const USER_NOTIFICATION_DATA_KEY = 'USER_NOTIFICATION_DATA_:userId';
export const USER_DENIALS_QUEUE_FILTER_SHOULD_LOCK_FILTERS =
  'USER_DENIALS_QUEUE_FILTER_SHOULD_LOCK_FILTERS_:userId';
export const USER_DENIALS_QUEUE_FILTER_VALUE =
  'USER_DENIALS_QUEUE_FILTER_VALUE_:userId';
export const ACTION_LOG_LOCK_FILTER_KEY_PENDING =
  'ACTION_LOGS_FILTER_LOCK_PENDING_:userId';
export const ACTION_LOG_FILTER_VALUE_KEY_PENDING =
  'ACTION_LOGS_FILTER_VALUE_PENDING_:userId';
export const ACTION_LOG_LOCK_FILTER_KEY_COMPLETED =
  'ACTION_LOGS_FILTER_LOCK_COMPLETED_:userId';
export const ACTION_LOG_FILTER_VALUE_KEY_COMPLETED =
  'ACTION_LOGS_FILTER_VALUE_COMPLETED_:userId';
