import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import domtoimage from 'dom-to-image';
import React, { useState, useRef } from 'react';

import Filter from './Filters/Filter';
import * as toast from 'components/Shared/toast';
import DownloadDashboard from './components/DownloadDashboard';
import ClaimsPerformance from './ClaimsPerformance/ClaimsPerformance';
import DenialsAndUnderpayments from './DenialsAndUnderpayments/DenialsAndUnderpayments';
import TopPayersAndProcedureCode from './TopPayersAndProcedureCodePerformance/TopPayersAndProcedureCode';

import { getUserInfo } from 'redux/reducers/loginStore';

import {
  useFetchPayersDropdown,
  useFetchClaimsInformation,
  useFetchPracticeDropdownOptions,
} from './Filters/hooks';

import { getFormattedDate, VALIDATION_DATE_FORMAT } from 'helpers/dateFormats';

export const DEFAULT_FILTER = {
  start: null,
  end: null,
  payer: null,
  practice: null,
};

const Analytics = (props) => {
  const claimsPerformanceWidgetResetRef = useRef(null);
  const denialsAndUnderpaymentsWidgetResetRef = useRef(null);
  const topPayersAndProcedureCodeWidgetResetRef = useRef(null);
  const [filters, setFilters] = useState(DEFAULT_FILTER);
  const [isFetchingData, setIsFetchingData] = useState({
    isFetchingClaimsPerformanceData: false,
    isFetchingDenialsAndUnderPayments: false,
    isFetchingTopPayersData: false,
    isFetchingTopProcedureCodesData: false,
  });
  const dashboardViewRef = useRef(null);
  const { loading: isDataCountsLoading, claims: claimsCount } =
    useFetchClaimsInformation(filters.start, filters.end);

  const resetWidgetFilters = () => {
    if (
      claimsPerformanceWidgetResetRef &&
      claimsPerformanceWidgetResetRef.current
    ) {
      claimsPerformanceWidgetResetRef.current.click();
    }
    if (
      denialsAndUnderpaymentsWidgetResetRef &&
      denialsAndUnderpaymentsWidgetResetRef.current
    ) {
      denialsAndUnderpaymentsWidgetResetRef.current.click();
    }
    if (
      topPayersAndProcedureCodeWidgetResetRef &&
      topPayersAndProcedureCodeWidgetResetRef.current
    ) {
      topPayersAndProcedureCodeWidgetResetRef.current.click();
    }
  };

  const handleFilterChange = (filters) => {
    setFilters(filters);
    resetWidgetFilters();
  };
  const resetFilters = () => {
    handleFilterChange(DEFAULT_FILTER);
    resetWidgetFilters();
  };
  const { payersDropdownOptions } = useFetchPayersDropdown();
  const { practiceDropdownOptions } = useFetchPracticeDropdownOptions();

  const isSomeWidgetsLoading = Object.values(isFetchingData).some((val) => val);

  const onDownloadClick = () => {
    const xmlns = document.querySelectorAll('[xmlns]');
    xmlns.forEach((element) => {
      element.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');
    });
    domtoimage
      .toJpeg(dashboardViewRef.current)
      .then(function (dataUrl) {
        const link = document.createElement('a');
        const fileName = `appealio-claims-analytics-${getFormattedDate(
          new Date(),
          VALIDATION_DATE_FORMAT
        )}.jpeg`;
        link.download = fileName;
        link.href = dataUrl;
        link.click();
      })
      .catch(function (error) {
        return toast.error({
          message: `Oops, something went wrong! :${error}`,
          title: 'error',
        });
      });
  };

  return (
    <div className="claim__analytics-container">
      {!isSomeWidgetsLoading && <DownloadDashboard onClick={onDownloadClick} />}
      <div className="v3__analytics-container">
        <div className="v3__analytics">
          <div ref={dashboardViewRef}>
            <Filter
              filters={filters}
              handleFilterChange={handleFilterChange}
              payersDropdownOptions={payersDropdownOptions}
              practiceDropdownOptions={practiceDropdownOptions}
              disableFilter={isSomeWidgetsLoading}
              resetFilters={resetFilters}
              isDataCountsLoading={isDataCountsLoading}
              claimsCount={isEmpty(claimsCount) ? null : claimsCount}
            />
            <ClaimsPerformance
              claimsCount={claimsCount.claims}
              ref={claimsPerformanceWidgetResetRef}
              filters={filters}
              isFetchingData={isFetchingData}
              setIsFetchingData={setIsFetchingData}
            />
            <DenialsAndUnderpayments
              filters={filters}
              isFetchingData={isFetchingData}
              setIsFetchingData={setIsFetchingData}
              ref={denialsAndUnderpaymentsWidgetResetRef}
            />
            <TopPayersAndProcedureCode
              filters={filters}
              isFetchingData={isFetchingData}
              setIsFetchingData={setIsFetchingData}
              payersDropdownOptions={payersDropdownOptions}
              topPayersAndProcedureCodesRef={
                topPayersAndProcedureCodeWidgetResetRef
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const userInfo = getUserInfo(state);
  const userPractices = get(userInfo, 'relatedPractices', []);

  return {
    userInfo,
    userPractices,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
