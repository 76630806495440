import React from 'react';

import SearchBox from 'components/common/search';
import Dropdown from 'components/Shared/Dropdown';
import CustomDropdown from 'components/common/dropdown/ButtonDropdown/CustomDropdown';

const FilterSection = ({
  filters,
  searchTerm,
  onFilterChange,
  onSearchChange,
  dropdownOptions,
}) => {
  const handleSelectedPayerChange = (value) => onFilterChange('payer', value);
  const handleSelectedPracticeChange = (value) =>
    onFilterChange('practice', value);
  const handleSelectedProviderChange = (value) =>
    onFilterChange('providers', value);
  const handleSelectedCptCodeChange = (value) =>
    onFilterChange('procedures', value);
  const handleSelectedProcedureModifierChange = (value) =>
    onFilterChange('modifiers', value);
  const handleSelectedReasonCodeChange = (value) =>
    onFilterChange('reasons', value);
  const handleSelectedRemarkCodeChange = (value) =>
    onFilterChange('remarks', value);

  const debounceOnGeneralSearchFilterChange = (value) => {
    onSearchChange(value);
  };

  const filterOutAllOptions = (options) =>
    options.filter((item) => item.value !== '');

  return (
    <div className="denied-claims-filter__row">
      <div>
        <div className="">&nbsp;</div>
        <div className="batch-submission-filter__search_string_filter">
          <SearchBox
            placeholder="Search by Name & IDs"
            className="mt-4 mr-8"
            onSearch={debounceOnGeneralSearchFilterChange}
          />
        </div>
      </div>
      <div className="mr-8 width-150">
        <Dropdown
          label="Payer"
          name="payer"
          placeholder="All"
          onChange={handleSelectedPayerChange}
          value={filters.payer}
          isClearable={false}
          options={dropdownOptions?.payers?.data}
          isLoading={false}
          isDisabled
        />
      </div>
      <div className="mr-8 width-150">
        <Dropdown
          label="Practice"
          name="practice"
          placeholder="All"
          isClearable={false}
          onChange={handleSelectedPracticeChange}
          value={filters.practice}
          options={dropdownOptions?.practices?.data}
          isLoading={false}
        />
      </div>
      <div className="mr-8 width-150">
        <Dropdown
          label="Provider"
          name="Provider"
          placeholder="All"
          onChange={handleSelectedProviderChange}
          value={filters.providers}
          isClearable={false}
          options={dropdownOptions?.providers?.data}
          isLoading={false}
        />
      </div>
      <div className="mr-8 width-150">
        <CustomDropdown
          label="Procedure"
          options={filterOutAllOptions(dropdownOptions?.cptCodes?.data)}
          isMulti
          onChange={handleSelectedCptCodeChange}
          className="batch-submission-form-dropdown"
          input={{
            value: filters.procedures,
          }}
        />
      </div>
      <div className="mr-8 width-150">
        <CustomDropdown
          label="Modifier"
          options={filterOutAllOptions(
            dropdownOptions?.procedureModifiers?.data
          )}
          isMulti
          onChange={handleSelectedProcedureModifierChange}
          className="batch-submission-form-dropdown"
          input={{
            value: filters.modifiers,
          }}
        />
      </div>
      <div className="mr-8 width-150">
        <CustomDropdown
          label="Reason"
          options={filterOutAllOptions(dropdownOptions?.reasonCodes?.data)}
          isMulti
          onChange={handleSelectedReasonCodeChange}
          className="batch-submission-form-dropdown"
          input={{
            value: filters.reasons,
          }}
        />
      </div>
      <div className="mr-8 width-150">
        <CustomDropdown
          label="Remark"
          options={filterOutAllOptions(dropdownOptions?.remarkCodes?.data)}
          isMulti
          onChange={handleSelectedRemarkCodeChange}
          className="batch-submission-form-dropdown"
          input={{
            value: filters.remarks,
          }}
        />
      </div>
    </div>
  );
};

export default FilterSection;
