import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import closeIcon from 'img/close-modal.svg';
import warningIcon from 'img/incomplete.svg';

import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';

const AppealioDialog = ({ className, children, onClosePressed }) => {
  return (
    <ScreenDimmerComponent>
      <div
        className={classnames('appealio-dialog-popup', {
          [className]: className,
        })}
      >
        <img
          alt="Close"
          onClick={() => onClosePressed()}
          className="appealio-dialog-popup__close-btn"
          src={closeIcon}
        />
        <div className="appealio-dialog-popup__content">
          <img
            alt="Warning"
            className="appealio-dialog-popup__notification-icon"
            src={warningIcon}
          />
          {children}
        </div>
      </div>
    </ScreenDimmerComponent>
  );
};

AppealioDialog.propTypes = {
  className: PropTypes.string,
  onClosePressed: PropTypes.func.isRequired,
};

export default AppealioDialog;
