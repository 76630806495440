import _ from 'lodash';
import React from 'react';
import { Map } from 'immutable';
import classNames from 'classnames';
import { Field } from 'redux-form/immutable';

import FlagCheck from 'components/common/flagCheck';
import { renderField } from '../../Shared/textfield';
import SubmitButton from '../../Shared/SubmitButton';
import MultiValueInput from '../../Shared/MultiValueInput';
import {
  defaultMoneyInitialValues,
  CPTFields,
  CPTFieldsDisabled,
  CPTFieldsPartiallyDisabled,
} from './CPTRowConfig';

import { renderReduxDatepicker } from '../reduxDatepicker';
import { renderReduxMaskedInput } from '../reduxMaskedInput';
import { renderNumberField } from '../../Shared/numberField';

import xIcon from '../../../img/close-copy.svg';

const components = {
  datePicker: renderReduxDatepicker,
  maskedInput: renderReduxMaskedInput,
  flagCheck: FlagCheck,
};

const renderAddCPTButton = (cpts) => {
  return (
    <tr>
      <td colSpan="3">
        <SubmitButton
          type="button"
          wrapInDiv={false}
          title="+ Add another CPT"
          className={classNames(
            'submit__button',
            'submit__button--transparent',
            'cpt__table-add-button',
            {
              'submit__button--error': cpts.meta.error && cpts.meta.invalid,
            }
          )}
          onClick={() => {
            const procedures = _.defaults({}, defaultMoneyInitialValues);
            cpts.fields.push(Map(procedures));
          }}
          datacy="add-cpt-SubmitButton"
        />
      </td>
    </tr>
  );
};

export const renderRemovalIconIfConfigAllows = (cpts, index, config) => {
  const handleRemove = (index) => {
    // select the first claim line while removing the claim lines from the list
    if (index === 1) {
      const values = cpts.fields.get(0).toJS();
      cpts.fields.insert(
        0,
        Map({
          ...values,
          is_selected: true,
        })
      );
      cpts.fields.remove(index); // Remove original element
    }
    cpts.fields.remove(index); // Remove for other indexes
  };

  if (config.allowsRemoval && index > 0) {
    // user shouldn't be able to remove first element
    return (
      <td className="cpt__table-column cpt__table-column--remove">
        <span
          onClick={() => handleRemove(index)}
          datacy={`remove-row-${index}`}
        >
          <img alt="Remove CPT" src={xIcon} />
        </span>
      </td>
    );
  }
};

const extractRowConfig = (config, extraParams) => {
  const { integrationType, isImportedAppeal, cpt } = extraParams;

  if (!integrationType && !isImportedAppeal) {
    return config;
  }

  let columns = [...config.columns];

  if (isImportedAppeal) {
    columns = columns.map((column) => {
      /**
       * Info: Handle logic for imported appeal:
       * 1. Enable service date if null.
       */
      const isEmptyServiceDate =
        column.name === 'serv_date' && cpt.serv_date === null;

      if (isEmptyServiceDate) {
        return {
          ...column,
          disabled: false,
        };
      }

      return column;
    });

    return {
      ...config,
      columns,
    };
  }

  return {
    ...config,
    columns,
  };
};

export const renderRow = (cpts, cpt, index, config, disabledIndexes = []) => {
  const isSingleClaimLine = cpts?.fields?.length === 1;
  return (
    <tr key={index} className="cpt__table-row" datacy={`row-index-${index}`}>
      {config.columns.map((field, index) => {
        let component;
        let componentParameters = {};
        if (field.name === 'is_selected') {
          field.component.parameters.disabled = isSingleClaimLine; // disable the flag if there exists only single claim line
        }
        if (field.component && components[field.component.name]) {
          component = components[field.component.name];
          componentParameters = field.component.parameters || {};
        } else if (
          !!field.isMultiValue &&
          !_.includes(disabledIndexes, index)
        ) {
          component = MultiValueInput;
        } else if (field.isDateType) {
          component = renderReduxDatepicker;
        } else if (field.isNumberType) {
          component = renderNumberField;
        } else {
          component = renderField;
        }
        return (
          <td
            key={`${field.name}`}
            className={classNames('cpt__table-column', field.className)}
            datacy={field.label}
          >
            <Field
              label={field.label}
              disabled={field.disabled || _.includes(disabledIndexes, index)}
              parse={field.parse}
              format={field.format}
              normalize={field.normalize}
              name={`${cpt}.${field.name}`}
              placeholder={field.placeholder}
              maxLength={field.maxLength}
              type={field.type}
              max={field.max}
              component={component}
              {...componentParameters}
            />
          </td>
        );
      })}
      {renderRemovalIconIfConfigAllows(cpts, index, config)}
    </tr>
  );
};

export const renderCPTS = (cpts) => {
  return (
    <tbody>
      {cpts.fields.map((cpt, index) =>
        renderRow(
          cpts,
          cpt,
          index,
          extractRowConfig(CPTFields, {
            integrationTYpe: cpts.integrationType,
            isImportedAppeal: cpts.isImportedAppeal,
            cpt: cpts.fields.get(index).toJS(),
          })
        )
      )}
      {renderAddCPTButton(cpts)}
    </tbody>
  );
};

export const renderCPTSDisabled = (cpts) => {
  return (
    <tbody>
      {cpts.fields.map((cpt, index) =>
        renderRow(
          cpts,
          cpt,
          index,
          extractRowConfig(CPTFieldsDisabled, {
            integrationTYpe: cpts.integrationType,
            isImportedAppeal: cpts.isImportedAppeal,
            cpt: cpts.fields.get(index).toJS(),
          })
        )
      )}
      <tr className="divider" />
    </tbody>
  );
};

export const renderCPTSPartiallyDisabled = (cpts) => {
  return (
    <tbody>
      {cpts.fields.map((cpt, index) =>
        renderRow(
          cpts,
          cpt,
          index,
          extractRowConfig(CPTFieldsPartiallyDisabled, {
            integrationTYpe: cpts.integrationType,
            isImportedAppeal: cpts.isImportedAppeal,
            cpt: cpts.fields.get(index).toJS(),
          })
        )
      )}
      <tr className="divider" />
    </tbody>
  );
};

export const renderCPTSWorkers = (cpts) => {
  const workerCompensationAffectedFields = [5, 6, 7];

  return (
    <tbody>
      {cpts.fields.map((cpt, index) =>
        renderRow(
          cpts,
          cpt,
          index,
          extractRowConfig(CPTFields, {
            integrationTYpe: cpts.integrationType,
            isImportedAppeal: cpts.isImportedAppeal,
            cpt: cpts.fields.get(index).toJS(),
          }),
          workerCompensationAffectedFields
        )
      )}
      {renderAddCPTButton(cpts)}
    </tbody>
  );
};

export const renderCPTSWorkersPartiallyDisabled = (cpts) => {
  const workerCompensationAffectedFields = [5, 6, 7];

  return (
    <tbody>
      {cpts.fields.map((cpt, index) =>
        renderRow(
          cpts,
          cpt,
          index,
          extractRowConfig(CPTFieldsPartiallyDisabled, {
            integrationTYpe: cpts.integrationType,
            isImportedAppeal: cpts.isImportedAppeal,
            cpt: cpts.fields.get(index).toJS(),
          }),
          workerCompensationAffectedFields
        )
      )}
      <tr className="divider" />
    </tbody>
  );
};
