import { ALL_VALUE } from 'constants/appConstants';
import { OPTION_TYPES } from './constant';
import { isEmpty } from 'lodash';

export const addTypeToOptions = (options, type) => {
  return options.map((option) => ({ ...option, type }));
};

/**
 * Extracts practice and practice group values from the provided options.
 *
 * @param {Array} options - The options from which values will be extracted.
 * @returns {Object} An object containing arrays of practiceGroupValues and practiceValues.
 *
 * @example
 * const options = [
 *   { value: 'group1', type: 'PRACTICE_GROUP', label: 'Group 1' },
 *   { value: 'practice1', type: 'PRACTICE', label: 'Practice 1' },
 *   ...
 * ];
 *
 * const { practiceGroupValues, practiceValues } = extractPracticeAndPracticeGroupValues(options);
 */
export const extractPracticeAndPracticeGroupValues = (options) => {
  // Check for the edge case where we immediately know the returned values
  if (
    options.length === 0 ||
    (options.length === 1 && options[0].value === ALL_VALUE)
  ) {
    return { practiceGroupValues: [ALL_VALUE], practiceValues: [ALL_VALUE] };
  }

  const { practiceGroupValues, practiceValues } = options.reduce(
    (acc, option) => {
      if (option.type === OPTION_TYPES.PRACTICE_GROUP) {
        acc.practiceGroupValues.push(option.value);
      } else if (option.type === OPTION_TYPES.PRACTICE) {
        acc.practiceValues.push(option.value);
      }
      return acc;
    },
    {
      practiceGroupValues: [],
      practiceValues: [],
    }
  );

  return {
    practiceGroupValues: isEmpty(practiceGroupValues)
      ? [ALL_VALUE]
      : practiceGroupValues,
    practiceValues: isEmpty(practiceValues) ? [ALL_VALUE] : practiceValues,
  };
};
