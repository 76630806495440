/**
 * Created by alex on 11/11/16.
 */

import { Serializer as JSONAPISerializer } from 'jsonapi-serializer';

export const AppealNotesSerializer = new JSONAPISerializer('AppealNote', {
  attributes: ['text', 'Appeal'],
  Appeal: {
    ref: 'id',
    included: false,
  },
  pluralizeType: false,
  keyForAttribute: 'underscore_case',
});
