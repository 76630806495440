import { connect } from 'react-redux';
import React, { useMemo, useState } from 'react';

import './style.css';

import RulesOverview from './RulesOverview';
import { getUserInfo } from 'redux/reducers/loginStore';
import SystemOverView from './SystemOverView/SystemOverView';
import Filter, { DEFAULT_FILTERS } from './Filters/Filter';

import { useFetchSystemOverview } from './SystemOverView/hooks';

import { processValues } from './RulesOverview/ActionBar/utils';
import { isAuthorizedForTeams } from 'Auth/AuthUtils';

const extractFiltersForApi = (filters) => {
  return {
    search: Object.entries(filters).reduce((apiFilters, [key, value]) => {
      if (!value) return apiFilters;

      return [
        ...apiFilters,
        {
          key,
          value,
        },
      ];
    }, []),
  };
};

const DenialsOverView = (props) => {
  const { userInfo } = props;
  const isAuthorizedForTeamsOptions = isAuthorizedForTeams(userInfo);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const filtersForApi = useMemo(() => extractFiltersForApi(filters), [filters]);
  const { loading, data } = useFetchSystemOverview(
    filtersForApi,
    userInfo?.practice,
    userInfo?.secretKey
  );
  const systemOverviewActionsData = processValues(data?.actions || []);
  return (
    <div className="background-color-grey-1">
      <div className="dashboard__header d-flex align-items-center">
        <div className="v3__widget-title denials-overview__heading pl-24">
          Denial Analytics
        </div>
      </div>
      <div className="system-info">
        <div className="row mb-16 no-gutter">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="system-info-container">
              <Filter
                isAuthorizedForTeamsOptions={isAuthorizedForTeamsOptions}
                filters={filters}
                setFilters={setFilters}
              />
            </div>
            <div className="system-info-container">
              <SystemOverView
                filters={filtersForApi}
                data={data}
                loading={loading}
              />
              <RulesOverview
                filters={filtersForApi}
                userInfo={userInfo}
                systemOverviewActionsData={systemOverviewActionsData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: getUserInfo(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DenialsOverView);
