/**
 * Created by alex on 11/11/16.
 */

import { Serializer as JSONAPISerializer } from 'jsonapi-serializer';
import { transformDateString } from './Transforms';

export const PatientInfoSerializerTransform = (object) => {
  object.dob = transformDateString(object.dob);
  object.address1 = object.address;
  object.zipcode = object.zipCode;
  return object;
};

export const PatientInfoSerializer = new JSONAPISerializer('Patient', {
  attributes: [
    'firstName',
    'lastName',
    'dob',
    'address1',
    'city',
    'state',
    'zipcode',
    'customPatientId',
    'Appeals',
    'Provider',
  ],
  Appeals: {
    ref: 'id',
    included: false,
  },
  Provider: {
    ref: 'id',
    included: false,
  },
  pluralizeType: false,
  keyForAttribute: 'underscore_case',
  typeForAttribute: (arg) => {
    if (arg === 'Appeals') return 'Appeal';
  },
});
