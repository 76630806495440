import classnames from 'classnames';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';

import notificationIcon from 'img/notification.svg';

import {
  getNotifications,
  isNotificationEmpty,
  getUnreadNotificationCount,
  getAllNotificationsViewedStatus,
} from 'redux/reducers/notificationStore';
import {
  setNotifications,
  clickNotification,
  viewAllNotifications,
  fetchNotificationsInfo,
  resetLatestNotifications,
} from 'redux/actions/notificationActions';
import { getUserInfo } from 'redux/reducers/loginStore';

import { usePolling } from 'hooks/usePolling';

import { interpolate } from 'helpers/string';
import { createURLWithParams } from 'API/Config';

import { REMINDER_STATUS } from 'constants/appConstants';
import { USER_NOTIFICATION_DATA_KEY } from 'constants/localStorage';
import {
  DENIED_QUEUE_COMPLETED_LOGS,
  SUBMISSIONS,
  SUBMISSIONS_IN_PROGRESS,
} from 'constants/routes';

import OutsideClickWrapper from 'components/common/outsideClickWrapper/OutsideClickWrapper';

import './style.css';

const AppNotification = ({
  userId,
  userName,
  unreadCount,
  notifications,
  isNotificationEmpty,
  allNotificationsViewed,
  latestNotificationTimestamp,
  push,
  hideAccountMenu,
  setNotifications,
  clickNotification,
  viewAllNotifications,
  fetchNotificationsInfo,
  resetLatestNotifications,
}) => {
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);

  usePolling(fetchNotificationsInfo, 60000); // Polling every 1 minute

  const notificationLocalstorageKey = interpolate(USER_NOTIFICATION_DATA_KEY, {
    userId,
  });

  const notificationText = {
    pastDueReminders: (count) => (
      <span>
        You have{' '}
        <span className="link-url text-decoration-underline">
          {count} past due reminder(s)
        </span>
        .
      </span>
    ),
    reminderForToday: (count, onClick) => (
      <span>
        You have{' '}
        <span className="link-url text-decoration-underline">
          {count} reminder(s) for today
        </span>
        .
      </span>
    ),
    upcomingReminders: (count, onClick) => (
      <span>
        You have{' '}
        <span className="link-url text-decoration-underline">
          {count} upcoming reminder(s)
        </span>{' '}
        (Within 3 Business Days).
      </span>
    ),
    inProgress: (count) => (
      <span>
        You have{' '}
        <span className="link-url text-decoration-underline">
          {count} In Progress Submission(s)
        </span>{' '}
        for more than 3 days.
      </span>
    ),
    underReview: (count) => (
      <span>
        You have{' '}
        <span className="link-url text-decoration-underline">
          {count} Under Review Submission(s)
        </span>{' '}
        for more than 3 days.
      </span>
    ),
  };

  const notificationOnClick = (category) => {
    let url;
    let queryParam = {};

    switch (category) {
      case 'pastDueReminders':
        url = DENIED_QUEUE_COMPLETED_LOGS;
        queryParam = {
          actionCompletedBy: userName,
          reminderStatus: REMINDER_STATUS.PAST_DUE,
        };
        break;
      case 'reminderForToday':
        url = DENIED_QUEUE_COMPLETED_LOGS;
        queryParam = {
          actionCompletedBy: userName,
          reminderStatus: REMINDER_STATUS.TODAY,
        };
        break;
      case 'upcomingReminders':
        url = DENIED_QUEUE_COMPLETED_LOGS;
        queryParam = {
          actionCompletedBy: userName,
          reminderStatus: REMINDER_STATUS.UPCOMING,
        };
        break;
      case 'inProgress':
        url = SUBMISSIONS_IN_PROGRESS;
        queryParam = {
          notificationRedirect: 'true',
        };
        break;
      case 'underReview':
        url = SUBMISSIONS;
        queryParam = {
          notificationRedirect: 'true',
        };
        break;
      default:
        return;
    }

    push(createURLWithParams(url, queryParam, true, '', true));
  };

  useEffect(() => {
    let storedNotificationData = null;

    try {
      const encodedData = localStorage.getItem(notificationLocalstorageKey);
      if (encodedData) {
        // Decode from base64 and parse the JSON data
        storedNotificationData = JSON.parse(atob(encodedData));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        'Error while fetching notification data from local storage',
        error
      );
    }
    if (storedNotificationData) {
      setNotifications({
        notifications: storedNotificationData.notifications,
        allNotificationsViewed: storedNotificationData.allNotificationsViewed,
        latestNotificationTimestamp:
          storedNotificationData.latestNotificationTimestamp,
      });
    }
    fetchNotificationsInfo();
  }, [
    fetchNotificationsInfo,
    notificationLocalstorageKey,
    setNotifications,
    userId,
  ]);

  useEffect(() => {
    if (notifications) {
      const notificationData = JSON.stringify({
        notifications,
        allNotificationsViewed,
        latestNotificationTimestamp,
      });

      const encodedData = btoa(notificationData); // Base64 encode the string

      localStorage.setItem(notificationLocalstorageKey, encodedData);
    }
  }, [
    notifications,
    allNotificationsViewed,
    latestNotificationTimestamp,
    userId,
    notificationLocalstorageKey,
  ]);

  const onNotificationsClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (showNotificationPopup) {
      hideNotificationMenu();
    } else {
      setShowNotificationPopup(true);
    }
    hideAccountMenu();
    if (!showNotificationPopup) {
      viewAllNotifications();
    }
  };

  const hideNotificationMenu = () => {
    setShowNotificationPopup(false);
    resetLatestNotifications();
  };

  const handleNotificationClick = (category) => {
    clickNotification(category);
    notificationOnClick(category);
  };

  return (
    <>
      <div
        onClick={onNotificationsClicked}
        className="topbar__dropmenu--notification"
      >
        <img
          alt="Dropdown"
          className="topbar__notification-ic"
          src={notificationIcon}
        />
        {!allNotificationsViewed && unreadCount > 0 && (
          <span className="topbar__notification--count">
            <span>{unreadCount}</span>
          </span>
        )}
      </div>

      {showNotificationPopup && (
        <OutsideClickWrapper handleOutsideClick={hideNotificationMenu}>
          <div className="notification-container">
            <div className="notification-header">
              <span className="notification-count">Notifications</span>
            </div>

            <div className="notification-content">
              {Object.entries(notifications).map(
                ([category, data]) =>
                  data.count > 0 && (
                    <div
                      key={category}
                      className={classnames('notification-item', {
                        'notification-item-unread': !data.viewed,
                      })}
                      onClick={() => handleNotificationClick(category)}
                    >
                      <div className="d-flex align-items-center notification-item-text">
                        <span
                          className={classnames('notification-dot', {
                            'visibility-hidden': data.viewed || !data?.isLatest,
                          })}
                        ></span>
                        <span className="notification-text">
                          {notificationText[category](data.count)}
                        </span>
                      </div>
                    </div>
                  )
              )}
              {isNotificationEmpty && (
                <div className="notification-item">
                  <span className="notification-text align-items-center">
                    You have no new notification(s).
                  </span>
                </div>
              )}
            </div>
          </div>
        </OutsideClickWrapper>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userId: getUserInfo(state).id,
  userName: getUserInfo(state).username,
  notifications: getNotifications(state)?.toJS(),
  unreadCount: getUnreadNotificationCount(state),
  isNotificationEmpty: isNotificationEmpty(state),
  allNotificationsViewed: getAllNotificationsViewedStatus(state),
  latestNotificationTimestamp: state.getIn([
    'notificationStore',
    'latestNotificationTimestamp',
  ]),
});

const mapDispatchToProps = {
  push,
  setNotifications,
  clickNotification,
  viewAllNotifications,
  fetchNotificationsInfo,
  resetLatestNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppNotification);
