import { connect } from 'react-redux';
import { BiCheck } from 'react-icons/bi';
import React, { useState, useRef } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';

import './style.css';

import { handleError } from 'helpers/errorHandler';
import { validateFileSize } from 'helpers/validators';
import {
  FILE_SIZE_EXCEEDS,
  INVALID_PM_NOTE_TEMPLATE_TYPE,
  UPLOAD_PM_NOTE_TEMPLATE,
} from 'components/Shared/Errors/errorMessages';

import Select from 'components/common/select/Select';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import { AppealioPopupWithFooter } from 'components/common/popup';
import SelectFileToUploadButton from 'components/CreateAppeal/DocumentsUpload/SelectFileToUploadButton';

import { getUserInfo } from 'redux/reducers/loginStore';

import { useFetchActionList } from 'components/DenialQueue/hooks/useFetchActionList';

import { RequestPmTemplate } from 'API/AccountSettingsAPI';

const RequestTemplate = (props) => {
  const {
    onClosePressed,
    onSuccess,
    handleSubmit,
    error,
    submitting,
    pristine,
  } = props;
  const { actionList, isLoading: isActionLoading } = useFetchActionList(false);
  const popupTitle = 'Request PM Note Template';

  const inputFileRef = useRef(null);
  const [file, setFile] = useState();
  const [fileError, setFileError] = useState(null);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const handleFileChange = (event) => {
    const allowedFileExtensions = ['txt', 'doc', 'docx'];
    const allowedFileTypes = [
      'text/plain',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const file = event.target.files[0];
    if (!file) {
      return setFileError(UPLOAD_PM_NOTE_TEMPLATE);
    }
    const { name, type, size } = file;
    const fileExtension = name.split('.').pop();

    if (!validateFileSize(size)) {
      setFileError(FILE_SIZE_EXCEEDS);
      return;
    }

    if (
      allowedFileExtensions.includes(fileExtension) &&
      allowedFileTypes.includes(type)
    ) {
      setFile(file);
      setFileError(null);
    } else {
      setFileError(INVALID_PM_NOTE_TEMPLATE_TYPE);
    }
  };

  const onUploadBtnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    inputFileRef.current.click();
  };

  const isSubmitBtnDisabled = submitting || fileError || error || pristine;

  const handleFormSubmit = async (values) => {
    if (!file) {
      setFileError(UPLOAD_PM_NOTE_TEMPLATE);
      return;
    }
    const { action } = values.toJS();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('action_type', action.value);
    try {
      setIsFileUploading(true);
      await RequestPmTemplate(formData);
      setIsFileUploading(false);
      onSuccess(true);
    } catch (error) {
      return handleError(error);
    }
  };

  return (
    <React.Fragment>
      <AppealioPopupWithFooter
        className="appealio-popup--v3 request-pm-note-template-popup"
        onClosePressed={onClosePressed}
        title={popupTitle}
        isFooterOutside={false}
        datacy="add-template-AppealioPopupWithFooter"
      >
        <div className="form-step__content mb-24">
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="row  mb-24">
              <div className="col-md-12">
                <Field
                  component={Select}
                  options={actionList}
                  name="action"
                  label="Select Action *"
                  placeholder="Action Name"
                  isLoading={isActionLoading}
                />
              </div>
            </div>
            <div className="row mb-24">
              <div className="col-md-12">
                <div className="d-flex align-items-center template-file-upload-wrapper">
                  <div className="template-file-upload__file-name">
                    {file && !isFileUploading ? (
                      file.name.includes('upload') ? (
                        <BiCheck color="green" size="32" />
                      ) : (
                        !fileError && file.name
                      )
                    ) : (
                      <LoadingIndicator showing={isFileUploading} />
                    )}
                  </div>
                  <div>
                    <label className="file-upload">
                      <input
                        className="file-upload__input"
                        type="file"
                        ref={inputFileRef}
                        onChange={handleFileChange}
                        accept="application/msword,
                  application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx,.txt"
                        datacy="file-upload-input"
                      />
                      <SelectFileToUploadButton
                        onClick={onUploadBtnClick}
                        btnTitle="Upload PM Note Template"
                        type="button"
                        datacy="select-file-SelectFileToUploadButton"
                      />
                    </label>
                  </div>
                </div>
                {(error || fileError) && (
                  <span className="ap-input-error fw-normal mt-8">
                    {fileError || error}
                  </span>
                )}
              </div>
            </div>
            <div className="row no-gutter template-file-upload__submit-button-wrapper">
              <div className="col-6 appeal-letter__request-wrapper">
                <LoadingIndicator showing={submitting} />
                <button
                  disabled={isSubmitBtnDisabled}
                  type="submit"
                  className="ap-button ap-button--primary width-130 justify-content-center"
                  datacy="submit-button"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </AppealioPopupWithFooter>
    </React.Fragment>
  );
};

export default connect((state) => ({
  userInfo: getUserInfo(state),
}))(
  reduxForm({
    form: 'add-pm-template-form',
  })(RequestTemplate)
);
