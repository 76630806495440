import React from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

import './style.css';

import { ReactComponent as CheckIcon } from 'img/check-sm.svg';

const StatusTrackerCheckBox = (props) => {
  const {
    title,
    onCheck,
    isChecked,
    className,
    hasDefaultBorder = false,
    tooltip,
  } = props;

  return (
    <div
      className={classnames('status-tracker-checkbox', className, {
        'status-tracker-checkbox--checked': isChecked,
        'status-tracker-checkbox--default-border': hasDefaultBorder,
      })}
      onClick={onCheck}
      data-tip={tooltip}
    >
      {isChecked ? (
        <CheckIcon className="status-tracker-checkbox__icon" />
      ) : (
        <div className="status-tracker-checkbox__icon">&nbsp;</div>
      )}
      <span className="status-tracker-checkbox__title">{title}</span>
      <ReactTooltip effect="solid" />
    </div>
  );
};

export default StatusTrackerCheckBox;
