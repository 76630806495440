import _ from 'lodash';

import { addedDateFieldError } from '../../CreateAppeal/addDateError';
import { validateCPTs } from '../../CreateAppeal/DenialInformation/validate';
import { addedRequiredFieldError } from '../../CreateAppeal/addRequiredError';
import {
  getMaxValidEOBDate,
  isValidEOBDate,
  validateDateString,
  validateExists,
} from '../../../helpers/validators';
import { interpolate } from '../../../helpers/string';
import { transformDateString } from '../../../API/Serializers/Transforms';
import { CPT_ERROR_VALID_DENIAL_DATE } from '../../Shared/Errors/errorMessages';

const validate = (values) => {
  const errors = {
    displayErrors: [],
  };

  const nonEmptyFields = [
    {
      field: 'denialDate',
      label: 'EOB Date',
    },
  ];

  nonEmptyFields.forEach(({ field, label }) => {
    const fieldValidation = validateExists(values.get(field));
    errors[field] = fieldValidation;

    addedRequiredFieldError(errors.displayErrors, errors[field], label);
  });

  const hasSelectedDenialDate = values.has('denialDate');
  if (hasSelectedDenialDate && !errors.denialDate) {
    const dateValidationError = validateDateString(values.get('denialDate'));
    const isValidDate = !dateValidationError;
    if (!isValidDate) {
      errors.denialDate = dateValidationError;
      addedDateFieldError(
        errors.displayErrors,
        dateValidationError,
        'EOB Date'
      );
    }

    const denialDateString = transformDateString(values.get('denialDate'));
    const isInvalidEOBDate = !isValidEOBDate(denialDateString);

    if (isValidDate && isInvalidEOBDate) {
      const validEobDate = getMaxValidEOBDate().format('MMM D YYYY');

      errors.displayErrors.push({
        title: CPT_ERROR_VALID_DENIAL_DATE.title,
        message: interpolate(CPT_ERROR_VALID_DENIAL_DATE.message, {
          validEobDate,
        }),
      });
      errors.denialDate = CPT_ERROR_VALID_DENIAL_DATE;
    }
  }

  const cptArrayErrors = validateCPTs(values, errors.displayErrors, {
    validateBilledAmountGtAllowedAmount: false,
  });

  const nonEmptyCPTErrors = cptArrayErrors.filter(
    (error) => !_.values(error).every(_.isEmpty)
  );

  if (!_.isEmpty(nonEmptyCPTErrors)) {
    errors.cpts = nonEmptyCPTErrors;
  }

  return errors;
};

export default validate;
