import { useHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';
import React, { useCallback, useEffect, useState } from 'react';

import ActionPopup, { TYPE_ACCEPT } from '../../Shared/ActionPopup';
import StandAlonePromptPopup from 'components/common/popup/StandAlonePromptPopup';

import appealPaidIcon from '../../../img/appeal-paid.svg';
import checkIcon from '../../../img/appeal_check.svg';
import closeIcon from '../../../img/closeappeal.svg';
import calendarIcon from '../../../img/calendar.svg';
import incompleteIcon from '../../../img/incomplete.svg';

import {
  CREATE_APPEAL_DOCUMENTS,
  CREATE_APPEAL_SUBMIT_CONFIRM,
  CREATE_APPEAL_REVIEW,
} from 'constants/routes';

export function AppealWasPaidPopup(props) {
  return (
    <ActionPopup
      title="Looks like the appeal was paid in full"
      subtitle="Would you like to mark this appeal as being paid and close it out?"
      actionButtons={[{ title: 'Mark Appeal as Paid', type: TYPE_ACCEPT }]}
      icon={appealPaidIcon}
      {...props}
    />
  );
}

export function AllowedIncreasedPopup(props) {
  return (
    <ActionPopup
      title="Looks like the allowed amount has increased"
      subtitle={`Thank you for entering ${props.payerName} response. Here are your options: (1) Continue Editing (Click X), (2) Re-Appeal (Continue Appeal Button), or (3) Mark as as Paid`}
      actionButtons={[
        {
          title: 'Continue Appeal',
        },
        {
          title: 'Mark as Paid',
          type: TYPE_ACCEPT,
        },
      ]}
      icon={appealPaidIcon}
      {...props}
    />
  );
}

export function DeniedAppealPopup(props) {
  return (
    <ActionPopup
      title="Looks like there are no changes in the appeal"
      subtitle="Would you like to mark this appeal as denied?"
      actionButtons={[
        {
          title: 'Continue Appeal',
        },
        {
          title: 'Mark as Denied',
          type: TYPE_ACCEPT,
        },
      ]}
      icon={appealPaidIcon}
      {...props}
    />
  );
}

export function EOBInformationAddedPopup(props) {
  return (
    <ActionPopup
      title="EOB Information Added"
      subtitle={`Thank you for entering ${props.payerName} response. Here are your options: (1) Continue Editing (Click X), (2) Re-Appeal (Continue Appeal Button), or (3) Permanently Close Claim (Close Appeal Button).`}
      actionButtons={[
        {
          title: 'Close Appeal',
        },
        {
          title: 'Continue Appeal',
          type: TYPE_ACCEPT,
        },
      ]}
      icon={checkIcon}
      {...props}
    />
  );
}

export function ConfirmClosingAppealPopup(props) {
  return (
    <ActionPopup
      title="Are you sure you want to mark the appeal as closed?"
      actionButtons={[
        {
          title: 'No',
        },
        {
          title: 'Yes',
          type: TYPE_ACCEPT,
        },
      ]}
      icon={incompleteIcon}
      {...props}
    />
  );
}

export function ConfirmMarkingAsPaidPopup(props) {
  return (
    <ActionPopup
      title="Are you sure you want to mark the appeal as paid?"
      actionButtons={[
        {
          title: 'No, Go Back',
        },
        {
          title: 'Yes, Mark as Paid',
          type: TYPE_ACCEPT,
        },
      ]}
      icon={closeIcon}
      {...props}
    />
  );
}

export function ConfirmMarkingAsDeniedPopup(props) {
  return (
    <ActionPopup
      title="Are you sure you want to mark the appeal as denied?"
      actionButtons={[
        {
          title: 'No, Go Back',
        },
        {
          title: 'Yes, Mark as Denied',
          type: TYPE_ACCEPT,
        },
      ]}
      icon={closeIcon}
      {...props}
    />
  );
}
export function AppealDeadlineHasPastPopup(props) {
  return (
    <ActionPopup
      title="Appeal Deadline has past!"
      subtitle="If date entered is incorrect, update and resubmit. If the date is correct you must mark the appeal as closed."
      actionButtons={[
        {
          title: 'Mark Appeal as Closed',
          type: TYPE_ACCEPT,
        },
      ]}
      icon={calendarIcon}
      {...props}
    />
  );
}

export function LetterIncompletePopup(props) {
  return (
    <ActionPopup
      title="Letter Incomplete"
      subtitle="Are you sure you want to continue without this letter? "
      actionButtons={[
        {
          title: 'No',
        },
        {
          title: 'Yes',
          type: TYPE_ACCEPT,
        },
      ]}
      icon={incompleteIcon}
      {...props}
    />
  );
}

export function FormIncompletePopup(props) {
  return (
    <ActionPopup
      title="This form may have incomplete data. Are you sure you want to Save & Continue?"
      actionButtons={[
        {
          title: 'No',
        },
        {
          title: 'Yes',
          type: TYPE_ACCEPT,
        },
      ]}
      icon={incompleteIcon}
      {...props}
    />
  );
}

export function PastDeadlinePopUp(props) {
  return (
    <ActionPopup
      title="Notice: the appeal deadline date has passed, do you still want to continue?"
      subtitle="Are you sure you still want to proceed?"
      actionButtons={[
        {
          title: 'Cancel',
        },
        {
          title: 'Proceed Anyway',
          type: TYPE_ACCEPT,
        },
      ]}
      icon={incompleteIcon}
      {...props}
    />
  );
}

export function EmptyPayerContactPopUp(props) {
  return (
    <ActionPopup
      title="Reminder: Since you have not selected a Payer Appeal Contact,
      you will need to enter contact information in your Appeal Letter and again
      to submit your Appeal.  Are you sure you want to proceed?"
      subtitle=""
      actionButtons={[
        {
          title: 'Cancel',
        },
        {
          title: 'Proceed Anyway',
          type: TYPE_ACCEPT,
        },
      ]}
      icon={incompleteIcon}
      {...props}
    />
  );
}

export function DeleteFilePopup(props) {
  return (
    <ActionPopup
      title="Are you sure you want delete this file?"
      actionButtons={[
        {
          title: 'Cancel',
        },
        {
          title: 'Yes',
          type: TYPE_ACCEPT,
        },
      ]}
      icon={incompleteIcon}
      {...props}
    />
  );
}

export function PayerPortalSubmissionConfirmationPopUp(props) {
  const title = `Are you sure you want to submit this ${
    props?.isStandAlone ? 'package' : 'appeal'
  }?`;
  return (
    <ActionPopup
      title={title}
      actionButtons={[
        {
          title: 'No',
        },
        {
          title: 'Yes',
          type: TYPE_ACCEPT,
        },
      ]}
      icon={incompleteIcon}
      {...props}
    />
  );
}

export function EmptyEOBProceduresWarningPopup(props) {
  const { warnings = [] } = props;

  return (
    <ActionPopup
      title="This appeal contains empty EOB value(s), do you still want to continue?"
      actionButtons={[
        {
          title: 'Cancel',
        },
        {
          title: 'Proceed Anyway',
          type: TYPE_ACCEPT,
        },
      ]}
      icon={incompleteIcon}
      {...props}
    >
      <div>
        <div className="eob-warning-wrapper">
          {warnings.map((warning, index) => {
            const { row, emptyFields } = warning;

            if (emptyFields.length > 3) {
              const dataToRender = emptyFields.slice(0, 3).join(', ');
              const dataTip = emptyFields.join(', ');
              const remainingCount = emptyFields.length - 3;

              return (
                <div data-tip={dataTip} className="eob-warning" key={index}>
                  ROW# {row}: {dataToRender} + {remainingCount}
                </div>
              );
            }
            return (
              <div className="eob-warning" key={index}>
                ROW# {row}: {emptyFields.join(', ')}
              </div>
            );
          })}
        </div>
      </div>
      <ReactTooltip effect="solid" place="top" />
    </ActionPopup>
  );
}

/**
 * Display warning message popup
 * @param {object}
 */
export function StandaloneRouterPrompt(props) {
  const { when } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        if (
          prompt.pathname === CREATE_APPEAL_DOCUMENTS ||
          prompt.pathname === CREATE_APPEAL_SUBMIT_CONFIRM ||
          prompt.pathname === CREATE_APPEAL_REVIEW
        ) {
          setShowPrompt(false);
          history.block(() => {});
        } else {
          setCurrentPath(prompt.pathname);
          setShowPrompt(true);
          return false;
        }
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleCancelOrOk = useCallback(
    async (value) => {
      if (value === 'cancel') {
        setShowPrompt(false);
      } else {
        history.block(() => {});
        history.push(currentPath);
      }
    },
    [currentPath, history]
  );

  return showPrompt ? (
    <StandAlonePromptPopup
      handleOk={() => handleCancelOrOk('ok')}
      handleCancel={() => handleCancelOrOk('cancel')}
    />
  ) : null;
}
