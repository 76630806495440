import React from 'react';
import PropTypes from 'prop-types';

const Introduction = ({ title, description = '', info = '', datacy }) => {
  return (
    <div className="section-intro" datacy="introduction">
      <div className="d-flex align-items-center">
        {/* <AiOutlineQuestionCircle
            size="18"
            color="#5E6871"
          />
         <p className="section-intro__description">{description}</p> */}
      </div>
    </div>
  );
};

Introduction.propTypes = {
  title: PropTypes.string.isRequired,
  details: PropTypes.string,
  info: PropTypes.string,
};

export default Introduction;
