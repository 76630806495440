import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import React, { useState, useEffect } from 'react';

import { MASK_DATE } from '../../../../helpers/masks';

import SubmitButton from '../../../Shared/SubmitButton';
import Input from '../../../Shared/InputTextField';

const SearchPatient = (props) => {
  const { className, initialValue, onSearch } = props;

  const [formValue, setFormValue] = useState({
    firstName: '',
    lastName: '',
    dob: '',
  });

  useEffect(() => {
    setFormValue({ ...initialValue });
  }, [initialValue]);

  const handleFormValueChange = (event) => {
    const { name, value } = event.target;

    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  const handleSearchButtonClick = () => {
    onSearch(formValue);
  };

  return (
    <div className={className} datacy="search-patient">
      <h4 className="patient-cost-estimator__title">Patient Search</h4>
      <div className="row">
        <div className="col-md-3">
          <Input
            name="firstName"
            label="First Name *"
            placeholder="John"
            onChange={handleFormValueChange}
            value={formValue.firstName}
            datacy="first-name-Input"
          />
        </div>
        <div className="col-md-3 col-md-offset-1">
          <Input
            name="lastName"
            label="Last Name *"
            placeholder="Doe"
            value={formValue.lastName}
            onChange={handleFormValueChange}
            datacy="last-name-Input"
          />
        </div>
        <div className="col-md-3 col-md-offset-1">
          <label htmlFor="dob" className="textfield__label">
            Date of Birth *
          </label>
          <MaskedInput
            name="dob"
            label="Enter Date of Birth *"
            className="textfield__input"
            mask={MASK_DATE}
            type="string"
            placeholder="MM/DD/YYYY"
            value={formValue.dob}
            onChange={handleFormValueChange}
            render={(ref, props) => <input ref={ref} {...props} />}
            datacy="dob-MaskedInput"
          />
        </div>
      </div>
      <SubmitButton
        title="Search"
        type="button"
        wrapInDiv
        onClick={handleSearchButtonClick}
        datacy="search-SubmitButton"
      />
    </div>
  );
};

SearchPatient.propTypes = {
  className: PropTypes.string,
  initialValue: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default SearchPatient;
