import React from 'react';
import classNames from 'classnames';

export const renderInput = ({
  disabled,
  key,
  input,
  placeholder,
  autoFocus = false,
  inputRef = null,
  required = false,
  className = '',
  meta: { touched, error },
}) => {
  return (
    <React.Fragment>
      <input
        key={key}
        className={classNames('cpt-input', {
          'cpt-input--has-error': error,
        })}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
        ref={inputRef}
        {...input}
      />
    </React.Fragment>
  );
};
