import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const InputTextField = (props) => {
  const {
    name,
    required,
    label,
    placeholder,
    disabled,
    autoFocus,
    hasError,
    onChange,
    value,
  } = props;

  return (
    <div datacy="input-text-field">
      <label
        htmlFor={name}
        className={classNames('textfield__label', {
          'textfield__label--required': required,
        })}
      >
        {label} {required ? '*' : ''}
      </label>
      <div>
        <span>
          <input
            tabIndex={0}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            autoFocus={autoFocus}
            onChange={onChange}
            value={value}
            className={classNames(
              'textfield__input',
              {
                'textfield__input--error': hasError,
              },
              {
                'textfield__input--disabled': disabled,
              }
            )}
          />
        </span>
      </div>
    </div>
  );
};

InputTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  hasError: PropTypes.bool,
  value: PropTypes.string,
};

InputTextField.defaultProps = {
  required: false,
  placeholder: '',
  hasError: false,
  autoFocus: false,
  disabled: false,
  value: '',
};

export default InputTextField;
