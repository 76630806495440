import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';

import './style.css';

import * as toast from 'components/Shared/toast';
import AddOrUpdateReminder from './AddOrUpdateReminder';
import { AppealioPopupWithFooter } from 'components/common/popup';

import {
  deleteReminderForDenial,
  createReminderForDenial,
  updateReminderForDenial,
  resolveReminderForDenial,
} from 'API/ReminderAPI';
import { fetchNotificationsInfo } from 'redux/actions/notificationActions';

import { handleError } from 'helpers/errorHandler';

import { useFetchReminderData } from './hooks';

const ReminderPopup = (props) => {
  const {
    reminderId,
    denialsQueueId,
    claimId,
    onClosePressed,
    fetchNotificationsInfo,
  } = props;
  const [initialFormValues, setInitialFormValues] = useState({
    claimId,
    reminderId,
    denialsQueueId,
  });
  const popupTitle = !reminderId
    ? 'Create Follow-up Reminder'
    : `Reminder Details for ${claimId}`;

  const { reminderData, loading: isReminderDataLoading } =
    useFetchReminderData(denialsQueueId);

  useEffect(() => {
    if (reminderData.length > 0) {
      const data = reminderData.find(
        (data) => data.id === reminderId && !data.isResolved
      );
      setInitialFormValues((prevValues) => ({
        ...prevValues,
        reminderId: data?.id,
        note: data?.note,
        reminderDate: data?.reminderDate ? moment(data?.reminderDate) : null,
      }));
    }
  }, [reminderData, reminderId]);

  const onSubmit = async (formData) => {
    const mapReminderDate = formData?.reminderDate.format('YYYY-MM-DD');
    const mappedReminderData = {
      reminderDate: mapReminderDate,
      note: formData.note,
    };

    try {
      if (reminderId) {
        const reminder = reminderData.find(
          (reminder) => reminder.id === reminderId
        );
        await updateReminderForDenial(denialsQueueId, reminderId, {
          ...reminder,
          reminderDate: mapReminderDate,
        });
        toast.success({
          title: 'Success',
          message: 'Reminder has been successfully updated.',
        });
      } else {
        await createReminderForDenial(denialsQueueId, mappedReminderData);
        toast.success({
          title: 'Success',
          message: 'Reminder has been successfully created.',
        });
      }
      onClosePressed();
      fetchNotificationsInfo();
    } catch (error) {
      handleError(error);
    }
  };

  const onResolveReminderClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await resolveReminderForDenial(denialsQueueId, reminderId);
      toast.success({
        title: 'Success',
        message: 'Reminder has been resolved successfully.',
      });
      onClosePressed();
      fetchNotificationsInfo();
    } catch (error) {
      handleError(error);
    }
  };

  const onDeleteReminderClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await deleteReminderForDenial(denialsQueueId, reminderId);
      toast.success({
        title: 'Success',
        message: 'Reminder has been deleted successfully.',
      });
      onClosePressed();
      fetchNotificationsInfo();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <AppealioPopupWithFooter
      className="appealio-popup--v3 reminder-popup"
      onClosePressed={onClosePressed}
      title={popupTitle}
      isFooterOutside={false}
      datacy="add-user-AppealioPopupWithFooter"
    >
      <AddOrUpdateReminder
        reminderId={reminderId}
        isReminderDataLoading={isReminderDataLoading}
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        onDeleteReminderClick={onDeleteReminderClick}
        onResolveReminderClick={onResolveReminderClick}
      />
    </AppealioPopupWithFooter>
  );
};

ReminderPopup.propTypes = {
  onClosePressed: PropTypes.func.isRequired,
  reminderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  denialId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  claimId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapDispatchToProps = {
  fetchNotificationsInfo,
};

export default connect(null, mapDispatchToProps)(ReminderPopup);
