import {
  createRequest,
  handleErrors,
  handleJSONParse,
  createGetRequest,
  INTEGRATION_API_BASE_URL,
} from './Config';

import { interpolate } from 'helpers/string';
import { camelizeKeys, snakeCaseKeys } from 'helpers/object';

import {
  CREATE_OR_FETCH_REMINDER,
  UPDATE_OR_DELETE_REMINDER,
  RESOLVE_REMINDER,
} from 'constants/endpoints';

/**
 * Fetches the reminder for a given denial.
 *
 * @param {string} did - The ID of the denial to fetch the reminder for.
 * @returns {Promise<Object>} - A promise that resolves to the fetched reminder object.
 */
export const fetchReminderForDenial = (dId) => {
  const url = interpolate(CREATE_OR_FETCH_REMINDER, {
    dId,
  });
  const request = createGetRequest(url, {}, INTEGRATION_API_BASE_URL, true);
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Creates a new reminder for a given denial.
 *
 * @param {string} dId - The ID of the denial to create a reminder for.
 * @param {Object} payload - The payload containing the details of the reminder to create.
 * @returns {Promise<Object>} - A promise that resolves to the created reminder object.
 */
export const createReminderForDenial = (dId, payload) => {
  const url = interpolate(CREATE_OR_FETCH_REMINDER, {
    dId,
  });
  const request = createRequest(url, snakeCaseKeys(payload), {
    baseUrl: INTEGRATION_API_BASE_URL,
    isBearer: true,
  });
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Updates an existing reminder for a given denial.
 *
 * @param {string} dId - The ID of the denial to update the reminder for.
 *  * @param {string} rId - The ID of the reminder to update the reminder for.
 * @param {Object} payload - The payload containing the updated details of the reminder.
 * @returns {Promise<Object>} - A promise that resolves to the updated reminder object.
 */
export const updateReminderForDenial = (dId, rId, payload) => {
  const url = interpolate(UPDATE_OR_DELETE_REMINDER, {
    dId,
    rId,
  });
  const request = createRequest(url, snakeCaseKeys(payload), {
    baseUrl: INTEGRATION_API_BASE_URL,
    isBearer: true,
    method: 'PUT',
  });

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Deletes a reminder for a given denial.
 *
 * @param {string} dId - The ID of the denial for which the reminder needs to be deleted.
 * @param {string} rId - The ID of the reminder for which the reminder needs to be deleted.
 * @returns {Promise} - A promise that resolves with the server response or rejects with an error.
 */
export const deleteReminderForDenial = (dId, rId) => {
  const url = interpolate(UPDATE_OR_DELETE_REMINDER, {
    dId,
    rId,
  });

  const request = createRequest(
    url,
    {},
    {
      baseUrl: INTEGRATION_API_BASE_URL,
      method: 'DELETE',
      isBearer: true,
    }
  );

  return fetch(request).then(handleErrors);
};

/**
 * Resolves a reminder for a given denial.
 *
 * @param {string} dId - The ID of the denial for which the reminder needs to be resolved.
 * @param {string} rId - The ID of the reminder that needs to be resolved.
 * @returns {Promise<Object>} - A promise that resolves to the server response or rejects with an error.
 */
export const resolveReminderForDenial = (dId, rId) => {
  const url = interpolate(RESOLVE_REMINDER, {
    dId,
    rId,
  });
  const request = createRequest(
    url,
    {},
    {
      baseUrl: INTEGRATION_API_BASE_URL,
      isBearer: true,
    }
  );
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};
