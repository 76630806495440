/**
 * Created by alex on 10/10/16.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from '../Shared/Footer';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { reset } from 'redux-form';
import helpIcon from '../../img/clipboard.svg';
import { Field, reduxForm, SubmissionError } from 'redux-form/immutable';
import { renderField } from '../Shared/textfield';
import { renderArea } from '../Shared/textarea';
import SubmitButton from '../Shared/SubmitButton';
import { sendHelpInfo } from '../../redux/actions/helpActions';
import { validateExists, validateEmail } from '../../helpers/validators';
import checkIcon from '../../img/appeal_check.svg';
import ActionPopup, { TYPE_ACCEPT } from '../Shared/ActionPopup';

class HelpComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPopup: false,
    };
  }

  render() {
    const { handleSubmit, invalid } = this.props;
    return (
      <div>
        <div className="help" datacy="help-component">
          <div className="row help__row-no-padding help__header">
            <div className="help__content col-xs-offset-3">
              <img alt="Help" className="help__icon col-xs-1" src={helpIcon} />
              <span className="col-xs-8 help__top-text-header">
                What can we help you with?
              </span>
              <span className="col-xs-5 help__top-text">
                If you're stuck or just need some additional support solving
                your issue, we're here to help.
              </span>
            </div>
          </div>
          <div className="row help__row-no-padding">
            <div className="col-xs-offset-4 col-xs-4 help__form">
              <div className="row">
                <div className="col-xs-offset-1 col-xs-10">
                  <form onSubmit={handleSubmit(this.submit)}>
                    <Field
                      label="Your email"
                      name="email"
                      component={renderField}
                      datacy="email-Field"
                    />
                    <Field
                      label="Messages"
                      name="text"
                      placeholder="Be sure to include all the important details about your question or problem"
                      component={renderArea}
                      datacy="messages-Field"
                    />
                    <div className="help__button">
                      <SubmitButton
                        className="submit__button submit__button--fullwidth"
                        title={'Send Message'}
                        disabled={invalid}
                        datacy="send-message-SubmitButton"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer className="help__footer-color" />
        {this.renderPopup()}
      </div>
    );
  }

  submit = (values) => {
    return this.props.actions
      .sendHelpInfo(values)
      .then((res) => {
        this.props.actions.reset('help_form');
        this.setState({
          showPopup: true,
        });
      })
      .catch((e) => {
        throw new SubmissionError();
      });
  };

  renderPopup = () => {
    if (this.state.showPopup === false) return;

    return (
      <ActionPopup
        title="Your message has been sent."
        actionButtons={[
          {
            title: 'Close',
            type: TYPE_ACCEPT,
          },
        ]}
        icon={checkIcon}
        onClosePressed={this.onClosePopupPressed}
        onActionButtonPressed={this.onClosePopupPressed}
        datacy="help-component-message-sent-ActionPopup"
      />
    );
  };

  onClosePopupPressed = () => {
    this.setState({
      showPopup: false,
    });
  };
}

function validate(values) {
  const errors = {};
  errors.email = validateEmail(values.get('email'));
  errors.text = validateExists(values.get('text'));

  return errors;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ sendHelpInfo, reset }, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(
  withRouter(
    reduxForm({
      form: 'help_form',
      validate,
    })(HelpComponent)
  )
);
