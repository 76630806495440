import { Map } from 'immutable';
import {
  FETCH_SOP_REQUEST,
  FETCH_SOP_SUCCESS,
  FETCH_SOP_FAILURE,
} from 'redux/actions/actionTypes';

const initialState = Map({
  sopData: Map({}),
  loadingStates: Map({}),
  errors: Map({}),
});

export const sopStore = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SOP_REQUEST:
      return state.setIn(['loadingStates', action.payload.sopId], true);
    case FETCH_SOP_SUCCESS:
      return state
        .setIn(['sopData', action.payload.sopId], action.payload.sopData)
        .setIn(['loadingStates', action.payload.sopId], false);
    case FETCH_SOP_FAILURE:
      return state
        .setIn(['errors', action.payload.sopId], action.payload.error)
        .setIn(['loadingStates', action.payload.sopId], false);
    default:
      return state;
  }
};

export const getSopData = (state, sopId) =>
  state.getIn(['sopStore', 'sopData', sopId]);

export const getSopLoadingState = (state, sopId) =>
  state.getIn(['sopStore', 'loadingStates', sopId], false);

export const getSopError = (state, sopId) =>
  state.getIn(['sopStore', 'errors', sopId]);
