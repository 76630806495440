import {
  OPEN_UHC_CLAIM_STATUS_WIDGET,
  CLOSE_UHC_CLAIM_STATUS_WIDGET,
} from './actionTypes';

export function openUhcClaimStatusWidget(payload) {
  return {
    type: OPEN_UHC_CLAIM_STATUS_WIDGET,
    payload,
  };
}

export function closeUhcClaimStatusWidget() {
  return {
    type: CLOSE_UHC_CLAIM_STATUS_WIDGET,
  };
}
