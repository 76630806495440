import { Map } from 'immutable';
import * as types from '../actions/actionTypes';

import { saveAuthToken, deleteAuthToken } from '../../helpers/tokenStorage';

const initialState = Map({
  authState: Map({
    isPending: false,
    isRejected: false,
    isSuccess: false,
  }),
});

export function loginStore(state = initialState, action = {}) {
  switch (action.type) {
    case `${types.LOGIN_AUTH}_PENDING`:
      return state;
    case `${types.LOGIN_AUTH}_FULFILLED`: {
      const { authToken, userInfo } = action.payload;
      saveAuthToken(authToken);
      const newState = Map({
        isPending: false,
        isSuccess: true,
        authToken,
        userInfo,
      });
      return state.set('authState', newState);
    }
    case `${types.LOGIN_SET_AUTOLOGIN_INFO}`:
      return state.set(
        'authState',
        Map({
          isPending: false,
          isSuccess: true,
          authToken: action.authToken,
          userInfo: action.userInfo,
        })
      );
    case `${types.CLEAR_AUTH_TOKEN}`:
      deleteAuthToken();
      return initialState;
    case types.SET_RELATED_PRACTICES: {
      const authState = state.get('authState').toJS();
      const newAuthState = {
        ...authState,
        userInfo: {
          ...authState.userInfo,
          relatedPractices: action.payload,
        },
      };
      return state.set(
        'authState',
        Map({
          ...newAuthState,
        })
      );
    }

    default:
      return state;
  }
}

function getAuthState(state) {
  const loginStore = state.has('loginStore') ? state.get('loginStore') : state;
  const authState = loginStore.has('authState')
    ? loginStore.get('authState')
    : state;
  return authState;
}

export function isAuthenticated(state) {
  return getAuthState(state).has('authToken');
}

export function isAuthenticating(state) {
  return getAuthState(state).get('isPending');
}

export function getUserInfo(state) {
  return getAuthState(state).get('userInfo');
}

export function getAuthToken(state) {
  return getAuthState(state).get('authToken');
}
