import { useCallback, useEffect, useState } from 'react';
import * as AccountsAPI from 'API/AccountSettingsAPI';

export const useFetchContacts = (page, search, type) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [totalContacts, setTotalContacts] = useState(0);
  const [contacts, setContacts] = useState([]);

  const fetch = useCallback(async () => {
    setLoading(true);
    setContacts([]);
    setTotalContacts(0);

    try {
      const queryParam = {
        page,
        ...(search && { search }),
        type,
      };
      const {
        data,
        meta: { total },
      } = await AccountsAPI.fetchContacts(queryParam);

      setContacts(data);
      setTotalContacts(total);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [page, search, type]);

  useEffect(() => {
    fetch();
  }, [page, search, type, fetch]);

  return { loading, error, contacts, totalContacts, fetch };
};
