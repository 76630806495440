import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import './style.css';

import { getUserInfo } from 'redux/reducers/loginStore';

import { OTHER_HEX, PRE_DEFINED_BACKGROUND_COLORS } from './constant';

import { generateRandomHex, processValues } from './utils';

const ActionBarLegend = ({ value = [], actionsData, className }) => {
  const processedActions = processValues(value);

  if (actionsData?.isFetching) return null;

  return (
    <div className="action-bar-legends-wrapper">
      {processedActions.map((item, idx) => {
        const isOther = item.name === 'Other';
        const backgroundColor = isOther
          ? OTHER_HEX
          : PRE_DEFINED_BACKGROUND_COLORS[idx] || generateRandomHex();

        return (
          <div className={classNames('action-bar-legend', className)} key={idx}>
            <span
              className="action-bar-legend-indicator"
              style={{ backgroundColor }}
            ></span>
            <span>{item.name}</span>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: getUserInfo(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ActionBarLegend);
