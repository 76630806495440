import React from 'react';
import appealCheck from 'img/appeal_check.svg';
import PropTypes from 'prop-types';

import { AlertDialog } from 'components/common/popup';

const SuccessPopup = (props) => {
  const { onClosePressed } = props;
  return (
    <AlertDialog
      onClosePressed={onClosePressed}
      className=""
      statusIcon={appealCheck}
      datacy="template-AlertDialog"
    >
      <p>
        Your PM Note Template request was successfully submitted.
        <br />
        Our CX team will reach out to you via email within 1 business day.
      </p>
    </AlertDialog>
  );
};

SuccessPopup.propTypes = {
  onClosePressed: PropTypes.func.isRequired,
};

export default SuccessPopup;
