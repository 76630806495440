/**
 * Created by goer on 11/22/16.
 */

import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { IoMdDownload } from 'react-icons/io';

import LoadingIndicator from '../../Shared/LoadingIndicator';
import ScreenDimmerComponent from '../../Shared/ScreenDimmerComponent';

import {
  SUPPORTED_FILE_TYPES,
  isImage,
  isPdf,
  mimeTypes,
} from '../../../helpers/mimeTypes';

import closeImg from '../../../img/close-modal-white.svg';
import previousImg from '../../../img/previous.svg';
import nextImg from '../../../img/next.svg';

import { downloadFile } from 'API/Config';
import { generateUUID } from 'helpers/utils';

const PdfViewer = lazy(() => import('components/common/pdfViewer/PdfViewer'));

export default class DocumentViewer extends React.Component {
  static propTypes = {
    documents: PropTypes.array.isRequired,
    defaultIndex: PropTypes.number.isRequired,
    onClosePress: PropTypes.func,
    previewUrl: PropTypes.string,
    previewTitle: PropTypes.string,
    isLoadingDocuments: PropTypes.bool,
  };

  static defaultProps = {
    documents: [],
    defaultIndex: 0,
    isLoadingDocuments: false,
  };

  constructor(props) {
    super(props);

    this.fileRenderer = {};
    SUPPORTED_FILE_TYPES.forEach((mimeType) => {
      if (isImage(mimeType) === true) {
        this.fileRenderer[mimeType] = this.renderImageBody;
      } else if (isPdf(mimeType)) {
        this.fileRenderer[mimeType] = this.renderPdfFile;
      }
    });

    this.state = {
      activeIndex: props.defaultIndex,
      displayDocuments: true,
    };
  }

  setDisplayDocuments = (displayDocuments, cb) =>
    this.setState({ displayDocuments }, cb);

  render() {
    return (
      <ScreenDimmerComponent>
        <div className="document-viewer" datacy="document-viewer">
          <div className="document-viewer__row">
            <div
              className="document-viewer__previous"
              onClick={this.onPreviousClicked}
            >
              <img alt="Previous Document" src={previousImg} />
            </div>

            <div className="document-viewer__content">
              {this.renderHeader()}
              {this.renderBody()}
            </div>

            <div className="document-viewer__next" onClick={this.onNextClicked}>
              <img alt="Next Document" src={nextImg} />
            </div>
          </div>
        </div>
      </ScreenDimmerComponent>
    );
  }

  renderPreviewToggleButton() {
    if (!this.props.previewUrl) return;

    return (
      <div className="d-flex align-items-center">
        <button
          className={classnames('document-viewer__preview-btn mr-8', {
            'document-viewer__preview-btn--dark': this.state.displayDocuments,
          })}
          onClick={() => this.setDisplayDocuments(true)}
        >
          Documents
        </button>
        <button
          className={classnames('document-viewer__preview-btn mr-8', {
            'document-viewer__preview-btn--dark': !this.state.displayDocuments,
          })}
          onClick={() => this.setDisplayDocuments(false)}
        >
          {this.props.previewTitle}
        </button>
      </div>
    );
  }

  downloadFile = () => {
    const document = this.props.documents[this.state.activeIndex];
    const link = document.link;
    const defaultFileName = generateUUID();
    const fileName =
      document.fileNameForSave || document.name || defaultFileName;
    downloadFile(link, fileName);
  };

  shouldRenderDownloadFileButton = () => {
    return (
      !this.props.isLoadingDocuments &&
      this.props.documents[this.state.activeIndex]?.docType ===
        mimeTypes.APPLICATION_PDF
    );
  };

  renderHeader() {
    return (
      <div className="document-viewer__header">
        <div className="d-flex full-width justify-content--space-between">
          <div className="document-viewer__title width-full">
            <div className="d-flex justify-content--space-between">
              {this.props.documents[this.state.activeIndex].name}
              {this.shouldRenderDownloadFileButton() && (
                <button
                  className="document-viewer-file-download-btn"
                  onClick={this.downloadFile}
                  title="Download File"
                >
                  <IoMdDownload className="document-viewer-file-download-btn-icon" />
                </button>
              )}
            </div>
          </div>
          {this.renderPreviewToggleButton()}
        </div>
        <img
          alt="Close"
          onClick={this.props.onClosePress}
          className="document-viewer__closeimg"
          src={closeImg}
        />
      </div>
    );
  }

  renderBody() {
    if (this.props.previewUrl && !this.state.displayDocuments) {
      return <div>{this.renderPdfFile(this.props.previewUrl)}</div>;
    }

    if (this.props.isLoadingDocuments === true) {
      return (
        <div className="position-relative document-viewer__body-iframe">
          <LoadingIndicator showing={true} />
        </div>
      );
    }

    const document = this.props.documents[this.state.activeIndex];
    return <div>{this.fileRenderer[document.docType](document.link)}</div>;
  }

  renderImageBody = (link) => {
    return (
      <img
        alt="Img Document"
        className="document-viewer__body-image"
        src={link}
      />
    );
  };

  renderPdfFile = (link) => {
    return (
      <div className="document-viewer__body-iframe">
        <Suspense fallback={<div>Loading Viewer...</div>}>
          <PdfViewer initialDoc={link} />
        </Suspense>
      </div>
    );
  };

  onPreviousClicked = () => {
    if (this.state.activeIndex > 0) {
      this.setState({ activeIndex: this.state.activeIndex - 1 });
    }
  };

  onNextClicked = () => {
    if (this.state.activeIndex < this.props.documents.length - 1) {
      this.setState({ activeIndex: this.state.activeIndex + 1 });
    }
  };
}
