import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const OverviewBox = (props) => {
  const { color, title, value } = props;
  return (
    <div className="overviewbox">
      <span
        className={classnames('overviewbox__line', {
          [`overviewbox__line--${color}`]: color,
        })}
      ></span>
      <p
        className={classnames('overviewbox__title', {
          [`overviewbox__title--${color}`]: color,
        })}
      >
        {title}
      </p>
      <p className="overviewbox__value">{value}%</p>
    </div>
  );
};

OverviewBox.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default OverviewBox;
