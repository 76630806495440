import { connect } from 'react-redux';
import React, { useState } from 'react';

import './style.css';

import SearchBox from 'components/common/search/SearchBox';
import SearchResultContainer from './SearchResultContainer/SearchResultContainer';

import { useFetchSearchResults } from './hook';

import { getUserInfo } from 'redux/reducers/loginStore';
import { extractUrlAndQueryParam } from './util';

const GlobalSearch = (props) => {
  const [searchTerm, setSearchTerm] = useState('');

  const { data, loading } = useFetchSearchResults(
    searchTerm,
    props.userInfo,
    false
  );

  const handleOnSearch = (search) => {
    setSearchTerm(search);
  };

  const handleRedirect = (type, searchValue) => {
    const { url, queryParam } = extractUrlAndQueryParam(type, searchValue);

    props.handleRedirect(url, queryParam);
  };

  const handleTitleClick = (type) => {
    handleRedirect(type, searchTerm);
  };

  const handleRowClick = (type, data) => {
    const searchQuery =
      data.claimNumber === '--'
        ? data.patientName
        : data.claimNumber || data.patientName;
    handleRedirect(type, searchQuery);
  };

  const checkIsSearchDisabled = (search) => {
    // Disable search if search term is less than 3 characters
    if (search.length < 3) return true;
    // Disable search if search term is same as previous search term
    if (search === searchTerm) return true;
    return false;
  };

  return (
    <div className="global-search-container">
      <div>
        <SearchBox
          value={searchTerm}
          onSearch={handleOnSearch}
          hideIcon
          checkIsSearchDisabled={checkIsSearchDisabled}
          className="global-search-box"
        />
      </div>

      {searchTerm && (
        <SearchResultContainer
          data={data}
          loading={loading}
          searchTerm={searchTerm}
          handleTitleClick={handleTitleClick}
          handleRowClick={handleRowClick}
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);
  if (!userInfo) {
    return {};
  }

  return {
    userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch);
