import { isEmpty } from 'lodash';

import {
  dateIsInFuture,
  isValidEOBDate,
  getMaxValidEOBDate,
  validateAmount,
  validateAmountFormat,
  validateDateString,
  validateExists,
  validatePayerClaimId,
  // validateNotNegativeAmount,
  validateWithValidators,
} from '../../../helpers/validators';
import { CPTFields } from './CPTRowConfig';

import { parseMoneyInput } from '../../../helpers/money';

import { transformDateString } from '../../../API/Serializers/Transforms';
import {
  CPT_CODE_ERROR,
  // CPT_ERROR_AMOUNT_ALLOWED_SUM,
  CPT_ERROR_BILLED_AMOUNT,
  CPT_ERROR_TITLE,
  DENIAL_DATE_IS_REQUIRED,
  CPT_ERROR_VALID_DENIAL_DATE,
  FIELD_INCORRECT_AMOUNT_FORMAT,
  INVALID_AMOUNT_INTEGER_VALUE,
  INVALID_AMOUNT_DECIMAL_VALUE,
  FIELD_NEGATIVE_VALUE_STRING,
  FIELD_REQUIRED_STRING,
  INJURY_DATE_IN_FUTURE,
  NEGATIVE_VALUE_NOT_ALLOWED,
  REQUIRED_FIELD_ERROR,
  PAYER_CLAIM_ID_FORMAT_ERROR,
  NEGATIVE_VALUE_FIELD_ERROR_MESSAGE,
  DECIMAL_VALUE_FIELD_ERROR_MESSAGE,
} from '../../Shared/Errors/errorMessages';
import { addedRequiredFieldError } from '../addRequiredError';
import { addedDateFieldError } from '../addDateError';
import { interpolate } from '../../../helpers/string';

const appendRowNumberToMessage = (message, index) =>
  `${message} [Row #${index + 1}].`;

/**
 * @param {Object} cpts
 * @returns {Array} [{row: 1, emptyFields: ['CPT Code', 'Quantity']}]
 */
export const extractEmptyEobProceduresWarnings = (cpts) => {
  const { columns: nonEmptyFields } = CPTFields;

  return cpts.reduce((acc, cur, index) => {
    const emptyFields = nonEmptyFields.reduce((acc, { name, label }) => {
      if (!cur.get(name)) {
        const error = label.replace(':', '').replace('*', '');
        return [...acc, error];
      }

      return acc;
    }, []);

    if (isEmpty(emptyFields)) {
      return acc;
    }

    return [
      ...acc,
      {
        row: index + 1,
        emptyFields,
      },
    ];
  }, []);
};

export const validateCPTs = (
  values,
  displayErrors,
  config = {
    validateBilledAmountGtAllowedAmount: true,
    isImportedAppeal: false,
  }
) => {
  const cptArrayErrors = [];

  let denialDate = null;

  if (
    values.get('denialDate') &&
    !validateDateString(values.get('denialDate'))
  ) {
    denialDate = transformDateString(values.get('denialDate'));
  }

  const hasSelectedDenialDate = !!denialDate;

  values.get('cpts').forEach((cpt, index) => {
    const cptErrors = {};
    CPTFields.columns.forEach((field) => {
      const fieldValue = cpt.get(field.name);
      // eslint-disable-next-line no-useless-escape
      const fieldLabel = field.label.replace(/\:/g, '').trim();

      /**
       * Allow empty values for CPTs for manual appeal.
       */
      if (!config.isImportedAppeal && !fieldValue) return;

      if (field.isMoneyType === true) {
        cptErrors[field.name] = validateWithValidators(fieldValue, [
          validateExists,
          // validateNotNegativeAmount,
          validateAmountFormat,
          validateAmount,
        ]);
        if (cptErrors[field.name] === FIELD_NEGATIVE_VALUE_STRING) {
          displayErrors.push({
            title: CPT_ERROR_TITLE,
            message: appendRowNumberToMessage(
              `${fieldLabel}: ${NEGATIVE_VALUE_NOT_ALLOWED}`,
              index
            ),
          });
        } else if (cptErrors[field.name] === FIELD_INCORRECT_AMOUNT_FORMAT) {
          displayErrors.push({
            title: CPT_ERROR_TITLE,
            message: appendRowNumberToMessage(
              `${fieldLabel}: ${FIELD_INCORRECT_AMOUNT_FORMAT}`,
              index
            ),
          });
        } else if (cptErrors[field.name] === INVALID_AMOUNT_INTEGER_VALUE) {
          displayErrors.push({
            title: CPT_ERROR_TITLE,
            message: appendRowNumberToMessage(
              `${fieldLabel}: ${INVALID_AMOUNT_INTEGER_VALUE}`,
              index
            ),
          });
        } else if (cptErrors[field.name] === INVALID_AMOUNT_DECIMAL_VALUE) {
          displayErrors.push({
            title: CPT_ERROR_TITLE,
            message: appendRowNumberToMessage(
              `${fieldLabel}: ${INVALID_AMOUNT_DECIMAL_VALUE}`,
              index
            ),
          });
        } else if (cptErrors[field.name] === FIELD_REQUIRED_STRING) {
          displayErrors.push({
            title: REQUIRED_FIELD_ERROR.title,
            message: appendRowNumberToMessage(
              `${fieldLabel}: ${REQUIRED_FIELD_ERROR.message}`,
              index
            ),
          });
        } else if (typeof cptErrors[field.name] === 'string') {
          displayErrors.push({
            title: CPT_ERROR_TITLE,
            message: appendRowNumberToMessage(
              `${fieldLabel}: ${cptErrors[field.name]}`,
              index
            ),
          });
        }
      }

      if (field.name === 'quantity' && !cptErrors.quantity) {
        // check if the provided quantity is negative
        const isNegativeValue = fieldValue < 0;
        if (isNegativeValue) {
          cptErrors[field.name] = NEGATIVE_VALUE_FIELD_ERROR_MESSAGE;
          displayErrors.push({
            title: NEGATIVE_VALUE_FIELD_ERROR_MESSAGE,
            message: appendRowNumberToMessage(
              `${fieldLabel}: ${NEGATIVE_VALUE_FIELD_ERROR_MESSAGE}`,
              index
            ),
          });
        }

        // check if the provided quantity is decimal field
        const isIntegerValue = Number.isInteger(Number(fieldValue));
        if (!isIntegerValue) {
          cptErrors[field.name] = DECIMAL_VALUE_FIELD_ERROR_MESSAGE;
          displayErrors.push({
            title: DECIMAL_VALUE_FIELD_ERROR_MESSAGE,
            message: appendRowNumberToMessage(
              `${fieldLabel}: ${DECIMAL_VALUE_FIELD_ERROR_MESSAGE}`,
              index
            ),
          });
        }
      }

      if (
        !['adj_codes', 'quantity', 'cpt_modifiers_code'].includes(field.name) &&
        !field.isMoneyType
      ) {
        cptErrors[field.name] = validateExists(fieldValue);

        if (cptErrors[field.name] === FIELD_REQUIRED_STRING) {
          displayErrors.push({
            title: REQUIRED_FIELD_ERROR.title,
            message: appendRowNumberToMessage(
              `${fieldLabel}: ${REQUIRED_FIELD_ERROR.message}`,
              index
            ),
          });
        }
      }

      const isValidCPT = (value) => {
        const isInvalidCPT =
          value.length < 3 ||
          value.length > 6 ||
          !value.match(/^[a-zA-Z0-9]+$/);
        return !isInvalidCPT;
      };

      if (field.name === 'cpt_code' && !cptErrors[field.name]) {
        cptErrors[field.name] = isValidCPT(fieldValue);

        if (cptErrors[field.name] !== true) {
          displayErrors.push({
            title: CPT_ERROR_TITLE,
            message: appendRowNumberToMessage(
              `${fieldLabel}: ${CPT_CODE_ERROR}`,
              index
            ),
          });
        }
      }
    });

    // rule 2 & 3
    if (cpt.get('serv_date') && !cptErrors.serv_date) {
      cptErrors.serv_date = validateDateString(cpt.get('serv_date'), true);
      if (cptErrors.serv_date) {
        addedDateFieldError(displayErrors, cptErrors.serv_date, 'Serv. Date');
      } else {
        if (cptErrors.serv_date === null) {
          cptErrors.serv_date = hasSelectedDenialDate
            ? null
            : DENIAL_DATE_IS_REQUIRED;
        }
        // Disable date validation for service date
        // if (cptErrors['serv_date'] === null) {
        //   cptErrors['serv_date'] = validateDateIsBeforeDate(cpt.get('serv_date'), denialDate);
        // }
        if (
          cptErrors.serv_date &&
          typeof (
            cptErrors.serv_date === 'string' &&
            cptErrors.serv_date !== FIELD_REQUIRED_STRING
          )
        ) {
          displayErrors.push({
            title: CPT_ERROR_TITLE,
            message: appendRowNumberToMessage(
              `Serv. Date: ${cptErrors.serv_date}`,
              index
            ),
          });
        }
      }
    }
    const billed = parseMoneyInput(cpt.get('billed_amount'));
    const allowed = parseMoneyInput(cpt.get('amount_allowed'));

    // rule 4
    if (cpt.get('serv_date') && !cptErrors.billed_amount) {
      const billedAmountHigherOrEqualThanAllowed = billed >= allowed;
      if (
        config.validateBilledAmountGtAllowedAmount &&
        !billedAmountHigherOrEqualThanAllowed
      ) {
        cptErrors.billed_amount = CPT_ERROR_BILLED_AMOUNT;
        displayErrors.push({
          title: CPT_ERROR_TITLE,
          message: appendRowNumberToMessage(
            `Billed Amount: ${CPT_ERROR_BILLED_AMOUNT}`,
            index
          ),
        });
      }
    }

    // rule 5 (Disabled temporarily by Andy)
    // if (!cptErrors['amount_allowed']) {
    //   const sum = sumMoneyInputs(cpt, 'co_insurance', 'deductible', 'payment_amount')
    //
    //   const allowedHigher = allowed >= sum;
    //   if (!allowedHigher) {
    //     cptErrors['amount_allowed'] = CPT_ERROR_AMOUNT_ALLOWED;
    //     displayErrors.push({
    //       title: CPT_ERROR_TITLE,
    //       message: `Amount Allowed: ${CPT_ERROR_AMOUNT_ALLOWED}`
    //     })
    //   }
    // }

    cptArrayErrors[index] = cptErrors;
  });

  return cptArrayErrors;
};

export const validate = (values, config = { isImportedAppeal: false }) => {
  const errors = {};
  errors.displayErrors = [];

  const nonEmptyFields = [
    {
      field: 'claimNumber',
      label: 'Payer Claim Id',
    },
    {
      field: 'denialDate',
      label: 'EOB Date',
    },
  ];

  if (values.has('employer')) {
    nonEmptyFields.push({
      field: 'employer',
      label: 'Employer name',
    });
  }

  if (values.has('injuryDate')) {
    nonEmptyFields.push({
      field: 'injuryDate',
      label: 'Date of Injury',
    });
  }

  nonEmptyFields.forEach((f) => {
    const field = f.field;
    const label = f.label;
    const fieldValidation = validateExists(values.get(field));
    errors[field] = fieldValidation;
    addedRequiredFieldError(errors.displayErrors, errors[field], label);
  });

  if (
    !values.has('cpts') ||
    values.get('cpts') == null ||
    !values.get('cpts').size
  ) {
    errors.cpts = [];
  } else {
    const cptArrayErrors = validateCPTs(values, errors.displayErrors, {
      isImportedAppeal: config.isImportedAppeal,
    });

    if (cptArrayErrors.length) {
      errors.cpts = cptArrayErrors;
    }
  }

  if (values.has('claimNumber') || !values.get('claimNumber') == null) {
    const payerClaimIdError = validatePayerClaimId(values.get('claimNumber'));

    if (payerClaimIdError != null) {
      errors.displayErrors.push({
        title: PAYER_CLAIM_ID_FORMAT_ERROR.title,
        message: `Payer Claim ID: ${PAYER_CLAIM_ID_FORMAT_ERROR.message}`,
      });
      errors.claimNumber = payerClaimIdError;
    }
  }

  const hasSelectedDenialDate = values.has('denialDate');
  if (hasSelectedDenialDate && !errors.denialDate) {
    const dateValidationError = validateDateString(values.get('denialDate'));
    const isValidDate = !dateValidationError;
    if (!isValidDate) {
      errors.denialDate = dateValidationError;
      addedDateFieldError(
        errors.displayErrors,
        dateValidationError,
        'EOB Date'
      );
    }

    const denialDateString = transformDateString(values.get('denialDate'));
    const isInvalidEOBDate = !isValidEOBDate(denialDateString);

    if (isValidDate && isInvalidEOBDate) {
      const validEobDate = getMaxValidEOBDate().format('MMM D YYYY');

      errors.displayErrors.push({
        title: CPT_ERROR_VALID_DENIAL_DATE.title,
        message: interpolate(CPT_ERROR_VALID_DENIAL_DATE.message, {
          validEobDate,
        }),
      });
      errors.denialDate = CPT_ERROR_VALID_DENIAL_DATE;
    }
  }

  if (values.has('injuryDate') && !errors.injuryDate) {
    const injuryDateString = transformDateString(values.get('injuryDate'));

    const injuryDateValidationError = validateDateString(
      values.get('injuryDate')
    );
    const isValidInjuryDate = !injuryDateValidationError;
    if (!isValidInjuryDate) {
      errors.injuryDate = injuryDateValidationError;
      addedDateFieldError(
        errors.displayErrors,
        injuryDateValidationError,
        'Date of Injury'
      );
    }
    const injuryDateIsInTheFuture = dateIsInFuture(injuryDateString);
    if (isValidInjuryDate && injuryDateIsInTheFuture) {
      errors.displayErrors.push({
        title: INJURY_DATE_IN_FUTURE.title,
        message: `Injury Date: ${INJURY_DATE_IN_FUTURE.message}`,
      });
      errors.injuryDate = INJURY_DATE_IN_FUTURE;
    }
  }

  return errors;
};

/*
Rules from Andy:
1. Claim Number and Payer should be unique_together [x]
2. Denial Date cannot be in the future and cannot in the past more than the appeal deadline days number [v]
3. All Service Dates should be before denied date [v]
4. Billed Amount should always be >= Allowed Amount [v]
5. Allowed Amount should always be >= Deductible + Coinsurance + Payment Amount [v]
6. If Billed Amount > Allowed Amount then Adjustment Code is required [v]
*/
