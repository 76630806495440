import React from 'react';

export const renderArea = ({ input, label, placeholder }) => (
  <div>
    <label className="textarea__label" htmlFor={input.name}>
      {label}
    </label>
    <div>
      <span>
        <textarea
          rows="6"
          {...input}
          placeholder={placeholder}
          className="textarea__input"
        />
      </span>
    </div>
  </div>
);
