/**
 * Created by piotr on 09/01/17.
 */

import {
  KEY_AUTH_TOKEN,
  USER_NOTIFICATION_DATA_KEY,
  USER_DENIALS_QUEUE_FILTER_SHOULD_LOCK_FILTERS,
  USER_DENIALS_QUEUE_FILTER_VALUE,
  ACTION_LOG_LOCK_FILTER_KEY_PENDING,
  ACTION_LOG_FILTER_VALUE_KEY_PENDING,
  ACTION_LOG_LOCK_FILTER_KEY_COMPLETED,
  ACTION_LOG_FILTER_VALUE_KEY_COMPLETED,
} from 'constants/localStorage';

export function saveAuthToken(authToken) {
  localStorage.setItem(KEY_AUTH_TOKEN, authToken);
}

export function getAuthToken() {
  return localStorage.getItem(KEY_AUTH_TOKEN);
}

export function deleteAuthToken() {
  localStorage.removeItem(KEY_AUTH_TOKEN);

  const keysToKeep = [
    USER_NOTIFICATION_DATA_KEY,
    USER_DENIALS_QUEUE_FILTER_SHOULD_LOCK_FILTERS,
    USER_DENIALS_QUEUE_FILTER_VALUE,
    ACTION_LOG_LOCK_FILTER_KEY_PENDING,
    ACTION_LOG_FILTER_VALUE_KEY_PENDING,
    ACTION_LOG_LOCK_FILTER_KEY_COMPLETED,
    ACTION_LOG_FILTER_VALUE_KEY_COMPLETED,
  ].map((key) => key.split(':userId')[0]);

  Object.keys(localStorage).forEach((key) => {
    if (!keysToKeep.some((keepKey) => key.startsWith(keepKey))) {
      localStorage.removeItem(key);
    }
  });
}
