/* eslint-disable guard-for-in */
/**
 * Created by alex on 10/5/16.
 */

import _ from 'lodash';
import Immutable from 'immutable';
import classnames from 'classnames';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import {
  Field,
  change,
  reduxForm,
  SubmissionError,
  getFormSyncErrors,
  formValueSelector,
} from 'redux-form/immutable';

import * as toast from '../../Shared/toast';
import SectionHeader from '../SectionHeader';
import { MASK_DATE } from '../../../helpers/masks';
import { renderField } from '../../Shared/textfield';
import { renderReduxDropdown } from '../reduxDropdown';
import { addedDateFieldError } from '../addDateError';
import { renderReduxMaskedInput } from '../reduxMaskedInput';
import { addedRequiredFieldError } from '../addRequiredError';
import { ErrorHandling } from '../../Shared/Errors/ErrorHandling';
import ErrorBannerSection from '../../Shared/Errors/ErrorBannerSection';
import {
  getCurrentAppealData,
  isCurrentAppealMedicalRecord,
} from '../../../redux/reducers/createAppealStore';
import SaveContinueFooterComponent from '../../Shared/SaveContinueFooterComponent';
import {
  validateExists,
  validateDateString,
} from '../../../helpers/validators';
import {
  setCurrentStep,
  createAppealAndSendPatientInfo,
} from '../../../redux/actions/createAppealActions';

import { SUBMISSION_FAILED_ERROR } from '../../Shared/Errors/errorMessages';
import { renderReduxDropdownV2 } from '../reduxReactSelect';
import { handleError } from '../../../helpers/errorHandler';
import { getUserInfo } from '../../../redux/reducers/loginStore';

import MemberIdInput from './MemberIdInput';
import PayerContactDropdown from './PayerContactDropdown';
import BillingProviderDropdown from './BillingProviderDropdown';
import {
  APPEAL_STEPS,
  INTEGRATION_TYPE,
  UHC_DIRECT_TO_PAYER,
} from '../../../constants/appConstants';
import {
  CREATE_APPEAL_DOCUMENTS,
  CREATE_APPEAL_APPEAL_LETTER,
  CREATE_APPEAL_DENIAL_INFO,
} from 'constants/routes';
import { camelizeKeys } from '../../../helpers/object';

import CreateAppealAPI from 'API/CreateAppealAPI';
import * as AccountsAPI from 'API/AccountSettingsAPI';
import { AppealioPopupWithFooter } from 'components/common/popup';
import AddPayerContactForm from 'components/ApDashboard/Manager/Settings/Organization/Payer/AddPayerContactForm';

class PatientInfoFrom extends Component {
  constructor(props) {
    super(props);

    this.isEditingAppeal = false;
  }

  state = {
    clinicsDropdownOptions: [],
    providers: [],
    providersDropdownOptions: [],
    payersDropdownOptions: [],
    practiceDropdownOptions: [],
    filteredProvidersDropdownOptions: [],
    payers: [],
    payerContacts: [],
    isLoading: false,
    enableByAppeal: false,
    providerClinicsDropdownOptions: [],
    isFetchingRenderingProviders: false,
    isFetchingClinicsAndBillingProviders: false,
    isAddPayerContactPopupOpen: false,
    isPracticesDropdownHidden: false,
    isPayerDisableForUHC: false,
    isDirectUHCApiAvailable: false,
    isDirectToPayerAvailable: {
      shouldPayersFetch: false,
      isPayerContactFetched: false,
    },
  };

  UNSAFE_componentWillMount() {
    this.props.actions.setCurrentStep(0);
  }

  async componentDidMount() {
    this.getFormDropdownOptions();
    if (this.props.isImportedAppeal && !this.props.isMedicalRecordsSubmission) {
      await this.fetchAndSetDeliveryFormats();
    }
    if (
      this.props.initialValues.get('appealID') ||
      this.props.initialValues.get('provider') ||
      this.props.initialValues.get('practiceId')
    ) {
      const providerId = this.props.initialValues.get('provider');
      if (providerId) {
        this.fetchAndSetProviderOptions(providerId);
      }

      const practiceId = this.props.initialValues.get('practiceId');
      if (practiceId) {
        this.fetchAndSetRenderingProvidersOptions(practiceId);
      }
    }

    if (!this.props.practiceId && this.props.userPractices.length === 1) {
      const practiceId = this.props.userPractices[0].id;
      this.props.change('practiceId', practiceId);
      this.fetchAndSetRenderingProvidersOptions(practiceId);

      this.setState({
        isPracticesDropdownHidden: true,
      });
    }

    if (this.props.initialValues.get('payer')) {
      this.fetchAndSetPayerContacts(this.props.initialValues.get('payer'));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isDirectToPayerAvailable.shouldPayersFetch !==
      this.state.isDirectToPayerAvailable.shouldPayersFetch
    ) {
      this.fetchAndUpdatePayers();
    }

    if (
      prevState.isDirectUHCApiAvailable !== this.state.isDirectUHCApiAvailable
    ) {
      this.setAppealContactAddress();
    }
  }

  setProviders = (providers) => {
    this.setState({
      filteredProvidersDropdownOptions: providers,
    });
  };

  filterProviders = (e) => {
    let clinicId = '';

    for (const idx in e) {
      if (!isNaN(idx)) {
        clinicId += e[idx];
      }
    }

    const { providersDropdownOptions } = this.state;
    if (clinicId) {
      const providers = providersDropdownOptions.filter(
        (provider) => +provider.clinicId === +clinicId
      );
      this.setProviders(providers, clinicId);
    } else {
      this.setProviders(providersDropdownOptions);
    }
  };

  extractPayerContactDropdownOptions = () => {
    const { payer: selectedPayerId } = this.props;

    if (!selectedPayerId) {
      return [];
    }

    if (this.state.isFetchingPayerContacts) {
      return [];
    }

    const payerContacts = this.state.payerContacts;
    // Display "Direct To Payer" only if it is import or moved from denials
    let payerContactsFiltered;
    if (!this.props.isImportedAppeal || !this.state.isDirectUHCApiAvailable) {
      // eslint-disable-next-line no-sequences
      payerContactsFiltered = payerContacts.reduce((p, c) => {
        if (
          c.name !== UHC_DIRECT_TO_PAYER.LABEL &&
          c.abbr !== UHC_DIRECT_TO_PAYER.ABBR
        ) {
          return [...p, c];
        }
        return p;
      }, []);
    } else {
      payerContactsFiltered = payerContacts;
    }
    // check if "direct to payer" exist
    const payerWithDirectToPayerExist = payerContactsFiltered.find(
      (payerContact) =>
        payerContact.name === UHC_DIRECT_TO_PAYER.LABEL &&
        payerContact.abbr === UHC_DIRECT_TO_PAYER.ABBR
    );
    if (
      Boolean(payerWithDirectToPayerExist) &&
      this.state.isDirectUHCApiAvailable &&
      !this.state.isDirectToPayerAvailable.isPayerContactFetched
    ) {
      const defaultPayerContactId = _.get(
        payerWithDirectToPayerExist,
        ['id'],
        null
      );

      this.props.change('payerContact', defaultPayerContactId);
      this.setState({
        isDirectToPayerAvailable: {
          ...this.state.isDirectToPayerAvailable,
          shouldPayersFetch: false,
          isPayerContactFetched: true,
        },
      });
    }

    const formattedPayerContacts = payerContactsFiltered.map(
      (payerContact) => ({
        key: payerContact.id,
        value: payerContact.name,
        data: payerContact,
      })
    );

    return camelizeKeys(formattedPayerContacts);
  };

  extractBillingProviderDropdownOptions = () => {
    const { providers } = this.state;
    const { provider: selectedProviderId } = this.props;

    if (!selectedProviderId) {
      return [];
    }

    const selectedProvider = providers.find(
      (provider) =>
        Number(provider.rendering_provider.provider_id) ===
        Number(selectedProviderId)
    );

    const billingProviders = _.get(selectedProvider, 'billing_provider', []);

    if (billingProviders.length === 1) {
      this.props.change('billingProvider', billingProviders[0].provider_id);
    }

    const options = billingProviders
      .map((provider) => CreateAppealAPI.extractProviderOption(provider, true))
      .map((provider) => {
        return {
          ...provider,
          value: provider.isActive
            ? provider.value
            : `${provider.value} (Inactive)`,
        };
      });

    return [
      ...options.filter((option) => option.isActive),
      ...options.filter((option) => !option.isActive),
    ];
  };

  fetchAndSetPayerContacts = async (selectedPayerId) => {
    this.setState({
      isFetchingPayerContacts: true,
    });

    try {
      const data = await AccountsAPI.fetchPayerContacts({
        payer: selectedPayerId,
        all: 1,
        include_direct_to_payer: 1,
      });

      this.setState({
        payerContacts: data,
        isFetchingPayerContacts: false,
      });
      return data;
    } catch (error) {
      this.setState({
        isFetchingPayerContacts: false,
      });
    }
  };

  onPayerChange = async (selectedPayerId = null) => {
    const { payers } = this.state;
    const selectedPayer = payers.find(
      (payer) => +payer.id === +selectedPayerId
    );
    // fetch payer contacts
    const payerPayerContacts = await this.fetchAndSetPayerContacts(
      selectedPayer.id
    );

    this.props.change('payer', selectedPayerId);
    this.props.change(
      'payerContact',
      payerPayerContacts.length === 1 ? payerPayerContacts[0].id : null
    );
    this.props.change('ediPayerId', '');
  };

  onPracticeChange = (practiceId = null) => {
    this.fetchAndSetRenderingProvidersOptions(practiceId);

    this.props.change('practiceId', practiceId);
    this.props.change('provider', null);
    this.clearProviderAssociatedInputs();
  };

  onProviderChange = (providerId = null) => {
    this.fetchAndSetProviderOptions(providerId);

    this.props.change('provider', providerId);
    this.clearProviderAssociatedInputs();
  };

  fetchAndSetProviderOptions = async (providerId = null) => {
    this.setState({
      isFetchingClinicsAndBillingProviders: true,
    });

    try {
      const providerClinicAccessResponse =
        await CreateAppealAPI.getProviderClinicAccessByProviderId(providerId);

      const { data } = providerClinicAccessResponse;

      const providerClinicsDropdownOptions = [...data.clinic_list].map(
        ({ clinic: clinicId, clinic__name: clinicName }) => ({
          key: clinicId,
          value: clinicName,
        })
      );

      if (providerClinicsDropdownOptions.length === 1) {
        this.props.change('clinic', providerClinicsDropdownOptions[0].key);
      }

      this.setState({
        isFetchingClinicsAndBillingProviders: false,
        providerClinicsDropdownOptions,
      });
    } catch (error) {
      this.setState({
        isFetchingClinicsAndBillingProviders: false,
      });

      handleError(error);
    }
  };

  fetchAndUpdatePayers = async () => {
    if (this.state.isDirectToPayerAvailable.shouldPayersFetch) {
      CreateAppealAPI.getDropdownOptions('payers').then((payers) => {
        this.setState({
          payersDropdownOptions: CreateAppealAPI.parsePayers(payers),
          payers: CreateAppealAPI.parsePayerData(payers),
          isPayerDisableForUHC: this.props.isImportedAppeal,
        });
      });
    }
  };

  /**
   * Check if UHC API is available
   */
  fetchAndSetDeliveryFormats = async () => {
    try {
      const deliveryFormats = await CreateAppealAPI.getDeliveryFormats(
        this.props.appealId
      );
      const includesDirectAppeal = deliveryFormats.includes('direct_appeal');
      const includesDirectRecon = deliveryFormats.includes('direct_recon');

      const isUHCAvailable = includesDirectAppeal || includesDirectRecon;

      return this.setState({
        isDirectUHCApiAvailable: isUHCAvailable,
      });
    } catch (error) {
      handleError(error);
    }
  };

  /**
   * Set payer contact address , Set "Direct To Payer" for payer contact
   * @param {Array} payers
   * @param {Number} selectedPayerId
   */
  setPayerContactAddress = async (payers, selectedPayerId) => {
    const payerPayerContacts = this.state.payerContacts;
    const payerPayerContactsData = payerPayerContacts.find(
      (payerContact) =>
        payerContact.name === UHC_DIRECT_TO_PAYER.LABEL &&
        payerContact.abbr === UHC_DIRECT_TO_PAYER.ABBR
    );
    const isDefaultPayerContactExistForDirectPayer = !!payerPayerContactsData;
    if (isDefaultPayerContactExistForDirectPayer) {
      this.setState({
        isDirectToPayerAvailable: {
          ...this.state.isDirectToPayerAvailable,
          shouldPayersFetch: false,
          isPayerContactFetched: true,
        },
      });
    }

    // case when "Direct To Pay" does not exist for payer contact
    if (
      !isDefaultPayerContactExistForDirectPayer &&
      !this.state.isDirectToPayerAvailable.shouldPayersFetch
    ) {
      await CreateAppealAPI.createPayerDirectToContacts(selectedPayerId);
      this.setState({
        isDirectToPayerAvailable: {
          ...this.state.isDirectToPayerAvailable,
          shouldPayersFetch: true,
          isPayerContactFetched: false,
        },
      });
    }
    return payerPayerContactsData;
  };

  /**
   * Set default payer contact address
   */
  setAppealContactAddress = async () => {
    if (this.state.isDirectUHCApiAvailable && this.state.payers.length > 0) {
      const { payers } = this.state;
      const { payer: selectedPayerId } = this.props;
      const payerContactData = await this.setPayerContactAddress(
        payers,
        selectedPayerId
      );
      const defaultPayerContactId = _.get(payerContactData, ['id'], null);
      this.props.change('payer', selectedPayerId);
      this.props.change('payerContact', defaultPayerContactId);
    }
  };

  clearProviderAssociatedInputs = () => {
    this.props.change('clinic', null);
    this.props.change('billingProvider', null);
  };

  openAddPayerContactPopup = () => {
    const selectedPayer = this.props.payer;

    if (!selectedPayer) {
      return toast.info({
        title: '',
        message: 'Please choose Payer or wait for Payer to load.',
      });
    }

    this.setState({ isAddPayerContactPopupOpen: true });
  };

  closeAddPayerContactPopup = () =>
    this.setState({
      isAddPayerContactPopupOpen: false,
    });

  onCreateNewPayerSuccess = (newPayerContact) => {
    this.setState({
      payerContacts: [...this.state.payerContacts, newPayerContact],
    });
    this.props.change('payerContact', newPayerContact.id);
    this.closeAddPayerContactPopup();
  };

  handleOnSubmit = async (values) => {
    const payerId = this.props.payer;
    try {
      const res = await AccountsAPI.createPayerContact({
        ...values,
        payerId,
      });
      toast.success({
        title: 'Success',
        message: 'Payer Contact added successfully.',
      });
      this.closeAddPayerContactPopup();
      this.onCreateNewPayerSuccess(res);
    } catch (error) {
      handleError(error);
    }
  };

  renderAddPayerContactPopup = () => {
    if (this.state.isAddPayerContactPopupOpen === true) {
      const { payers } = this.state;
      const selectedPayerId = this.props.payer;
      const selectedPayer = payers.find(
        (payer) => +payer.id === +selectedPayerId
      );
      if (!selectedPayer) {
        return toast.info({
          title: '',
          message: 'Please choose Payer or wait for Payer to load.',
        });
      }
      return (
        <AppealioPopupWithFooter
          onClosePressed={this.closeAddPayerContactPopup}
          title={`Add Payer Contact for ${selectedPayer.name}`}
          isFooterOutside={false}
          className="add-payer-contact-popup"
        >
          <AddPayerContactForm
            initialValues={{}}
            onSubmit={this.handleOnSubmit}
            payerId={selectedPayer?.id}
          />
        </AppealioPopupWithFooter>
      );
    }
  };

  handleMemberIdChange = (memberId, data = null) => {
    this.props.change('memberId', memberId);
    if (!data) {
      return;
    }

    this.props.change('firstName', data.firstName);
    this.props.change('lastName', data.lastName);
    this.props.change('dob', data.dob);
  };

  render() {
    const {
      handleSubmit,
      isImportedAppeal,
      integrationType,
      isMedicalRecordsSubmission,
    } = this.props;
    const payerContacts = this.extractPayerContactDropdownOptions();

    const activePayerContact = payerContacts.filter(
      (payerContact) => payerContact.data.isActive
    );
    const inactivePayerContact = payerContacts
      .filter((payerContact) => !payerContact.data.isActive)
      .map((option) => ({
        ...option,
        value: `${option.value} (Inactive)`,
        isActive: false,
      }));
    const payerContactDropdownOptions = [
      ...activePayerContact,
      ...inactivePayerContact,
    ];

    const billingProviderDropdownOptions =
      this.extractBillingProviderDropdownOptions();

    const isSingleBillingProvider = billingProviderDropdownOptions.length === 1;
    const isSingleClinicOption =
      this.state.providerClinicsDropdownOptions.length === 1;
    const isSingleRenderingProvider =
      this.state.filteredProvidersDropdownOptions.length === 1;
    return (
      <React.Fragment>
        <div className="row appeal-box__row-no-padding">
          <ErrorBannerSection
            className="col-xs-offset-2 col-xs-8"
            errors={this.props.displayErrors}
          />
          <div className="col-xs-12">
            <div className="patient-information-form appeal-box">
              <form onSubmit={handleSubmit(this.submit)}>
                <div className="row">
                  <div className="col-xs-12 patient-info-wrapper">
                    <div className="row">
                      <div className="">
                        <SectionHeader
                          headerTitle={'Patient'}
                          className="appeal-box__section-header"
                          isWithLine={false}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12">
                        <div className="row">
                          <div className=" col-xs-12">
                            <div className="row">
                              <div className="col-xs-6">
                                <div className="col-xs-11">
                                  <div className="create-appeal__dropdown">
                                    <label className="create-appeal__label textfield__label--required">
                                      Member Id: *
                                    </label>
                                    <Field
                                      label="Member Id:"
                                      name="memberId"
                                      placeholder="Member Id"
                                      autoFocus={true}
                                      required={true}
                                      maxLength="50"
                                      dataCy="input-member-id"
                                      component={MemberIdInput}
                                      handleChange={(value, data) =>
                                        this.handleMemberIdChange(value, data)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className=" col-xs-12">
                            <div className="row">
                              <div className="col-xs-4">
                                <div className="col-xs-11">
                                  <Field
                                    label="First Name:"
                                    name="firstName"
                                    autoFocus={true}
                                    required={true}
                                    maxLength="50"
                                    component={renderField}
                                  />
                                </div>
                              </div>
                              <div className="col-xs-4">
                                <div className="col-xs-11">
                                  <Field
                                    label="Last Name:"
                                    name="lastName"
                                    required={true}
                                    maxLength="50"
                                    component={renderField}
                                  />
                                </div>
                              </div>
                              <div className="col-xs-4">
                                <div className="col-xs-11">
                                  <Field
                                    label="Date of Birth:"
                                    name="dob"
                                    size="4"
                                    type="string"
                                    required={true}
                                    mask={MASK_DATE}
                                    placeholder="MM/DD/YYYY"
                                    component={renderReduxMaskedInput}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12 patient-info-wrapper">
                    <div className="row">
                      <div className="">
                        <SectionHeader
                          headerTitle={
                            isMedicalRecordsSubmission
                              ? 'Medical Record Destination'
                              : 'Appeal Destination'
                          }
                          className="appeal-box__section-header"
                          isWithLine={false}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12">
                        <div className="row">
                          <div className=" col-xs-12">
                            <div className="row">
                              <div className="col-xs-6">
                                <div className="col-xs-11">
                                  <div className="create-appeal__dropdown">
                                    <label className="create-appeal__label textfield__label--required">
                                      Payer: *
                                    </label>
                                    <Field
                                      name="payer"
                                      placeholder="Select Payer"
                                      dropdownOptions={
                                        this.state.payersDropdownOptions
                                      }
                                      component={renderReduxDropdownV2}
                                      onChange={this.onPayerChange}
                                      datacy="payer-Field"
                                      isDisabled={
                                        this.state.isPayerDisableForUHC
                                      }
                                    />
                                    <Field
                                      name="ediPayerId"
                                      component="input"
                                      hidden={true}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-xs-6">
                                <div className="create-appeal__dropdown">
                                  <label className="create-appeal__label textfield__label--required">
                                    {isMedicalRecordsSubmission
                                      ? 'Payer Contact'
                                      : 'Payer Appeal Contact'}
                                    : *
                                  </label>
                                  <Field
                                    name="payerContact"
                                    placeholder={
                                      isMedicalRecordsSubmission
                                        ? 'Select Payer Contact'
                                        : 'Select Payer Appeal Contact'
                                    }
                                    dropdownOptions={
                                      payerContactDropdownOptions
                                    }
                                    component={PayerContactDropdown}
                                    selectedPayerContact={payerContactDropdownOptions.find(
                                      (payerContact) =>
                                        payerContact.key ===
                                        Number(this.props.payerContact)
                                    )}
                                    isDisabled={!this.props.payer}
                                  />

                                  <label
                                    className={classnames(
                                      'create-appeal__label add-new-payer-contact-label',
                                      {
                                        'opacity-50': !this.props.payer,
                                      }
                                    )}
                                  >
                                    If Payer{' '}
                                    {isMedicalRecordsSubmission ? '' : 'Appeal'}{' '}
                                    Contact not found{' '}
                                    <span
                                      className="add-new-payer-contact-action"
                                      onClick={this.openAddPayerContactPopup}
                                    >
                                      click here to Add New
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12">
                    <div className="row">
                      <div>
                        <SectionHeader
                          headerTitle={'Insurance & Billing'}
                          className="appeal-box__section-header"
                          isWithLine={false}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12">
                        <div className="row">
                          <div className="col-xs-12">
                            {this.state.enableAppealByFacility && (
                              <div className="create-appeal__dropdown">
                                <label className="create-appeal__label textfield__label--required">
                                  Facility: *
                                </label>
                                <Field
                                  name="clinic"
                                  placeholder="Select Facility"
                                  dropdownOptions={
                                    this.state.clinicsDropdownOptions
                                  }
                                  maxStringLength={40}
                                  shouldTrimOptionLength={true}
                                  required={true}
                                  component={renderReduxDropdown}
                                  onChange={this.filterProviders}
                                />
                              </div>
                            )}
                            {!isImportedAppeal &&
                              integrationType === INTEGRATION_TYPE.APM && (
                                <div>
                                  <Field
                                    label="Voucher ID:"
                                    name="pmSystemAccountId"
                                    required={true}
                                    maxLength="50"
                                    component={renderField}
                                  />
                                </div>
                              )}
                            {!this.state.isPracticesDropdownHidden && (
                              <div className="create-appeal__dropdown">
                                <label className="create-appeal__label textfield__label--required">
                                  Practice: *
                                </label>
                                <Field
                                  name="practiceId"
                                  placeholder="Select Practice"
                                  dropdownOptions={
                                    this.state.practiceDropdownOptions
                                  }
                                  required={true}
                                  onChange={this.onPracticeChange}
                                  component={renderReduxDropdownV2}
                                />
                              </div>
                            )}
                            <div className="create-appeal__dropdown">
                              <label className="create-appeal__label textfield__label--required">
                                Rendering Provider: *
                              </label>
                              <Field
                                name="provider"
                                placeholder="Select Rendering Provider"
                                dropdownOptions={
                                  this.state.filteredProvidersDropdownOptions
                                }
                                required={true}
                                component={renderReduxDropdownV2}
                                onChange={this.onProviderChange}
                                isDisabled={
                                  !this.props.practiceId ||
                                  isSingleRenderingProvider
                                }
                              />
                            </div>
                            <div className="create-appeal__dropdown">
                              <label className="create-appeal__label textfield__label--required">
                                Clinic: *
                              </label>
                              <Field
                                name="clinic"
                                placeholder="Select Clinic"
                                dropdownOptions={
                                  this.state.providerClinicsDropdownOptions
                                }
                                maxStringLength={40}
                                shouldTrimOptionLength={true}
                                required={true}
                                component={renderReduxDropdownV2}
                                isLoading={
                                  this.state
                                    .isFetchingClinicsAndBillingProviders
                                }
                                isDisabled={
                                  !this.props.provider || isSingleClinicOption
                                }
                              />
                            </div>
                            <div className="create-appeal__dropdown">
                              <label className="create-appeal__label textfield__label--required">
                                Billing Provider: *
                              </label>
                              <Field
                                name="billingProvider"
                                placeholder="Select Billing Provider"
                                dropdownOptions={billingProviderDropdownOptions}
                                component={BillingProviderDropdown}
                                selectedProvider={billingProviderDropdownOptions.find(
                                  (provider) =>
                                    provider.key === this.props.billingProvider
                                )}
                                isDisabled={
                                  !this.props.provider ||
                                  isSingleBillingProvider
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="row appeal-box__divider" />
                </div>
                <div className="patient-info-form__save-btn">
                  <SaveContinueFooterComponent
                    isLoading={this.state.isLoading}
                    shouldShowDiscardAndSkipButton={isImportedAppeal}
                    discardAndSkipBtnTitle="Skip to Attach Documents"
                    onDiscardAndSkipPressed={() =>
                      handleSubmit((values) => this.submit(values, true))()
                    }
                    shouldShowCancelButton={!isImportedAppeal}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        {this.renderAddPayerContactPopup()}
      </React.Fragment>
    );
  }

  /**
   * Extracts appeal with updated step.
   *
   * @param {Immutable.Map} values
   * @return {Immutable.Map}
   */
  updateAppealStep = (values, skipStep) => {
    const { isImportedAppeal, currentAppealStep } = this.props;
    const shouldUpdateStep =
      isImportedAppeal && Number(currentAppealStep) === 0;

    if (!shouldUpdateStep && skipStep !== true) {
      return values;
    }

    const updatedValues = {
      ...values.toJS(),
      step:
        isImportedAppeal && skipStep === true
          ? APPEAL_STEPS.DOCUMENTS
          : APPEAL_STEPS.DENIAL_INFO,
    };

    return Immutable.fromJS(updatedValues);
  };

  navigateToNextStep = (appealId, isMedicalRecord, skipStep) => {
    const route =
      this.props.isImportedAppeal && skipStep === true
        ? CREATE_APPEAL_DOCUMENTS
        : isMedicalRecord
        ? CREATE_APPEAL_APPEAL_LETTER
        : CREATE_APPEAL_DENIAL_INFO;

    this.props.actions.push(`${route}?appealId=${appealId}`);
  };

  submit = (values, skipStep = false) => {
    this.setState({ isLoading: true });
    return new Promise((resolve, reject) => {
      let errors = {};
      if (!this.state.enableAppealByFacility) {
        errors = this.props.validateForm(values, (values) =>
          validate(
            values,
            this.props.integrationType,
            this.props.isImportedAppeal
          )
        );
      } else {
        errors = this.props.validateForm(values, (values) =>
          validateWithFacility(
            values,
            this.props.integrationType,
            this.props.isImportedAppeal
          )
        );
      }

      if (errors.displayErrors.length > 0) {
        this.setState({ isLoading: false });
        window.scrollTo(0, 0);
        throw new SubmissionError(errors);
      } else {
        const extractedAppealValues = this.updateAppealStep(values, skipStep);
        this.props.actions
          .createAppealAndSendPatientInfo(extractedAppealValues)
          .then((res) => {
            const claimNumber = values.get('claimNumber');
            const appealId = _.get(res, 'value.appeal.id');
            const isMedicalRecord =
              _.get(res, 'value.appeal.isMedicalRecordsSubmission', false) ===
              true;
            if (
              !this.props.isImportedAppeal &&
              !isMedicalRecord &&
              claimNumber &&
              this.props.clientPartitionId
            ) {
              CreateAppealAPI.syncClaimStatus(
                this.props.clientPartitionId,
                claimNumber,
                1
              ).catch(handleError);
            }

            this.navigateToNextStep(appealId, isMedicalRecord, skipStep);
            resolve();
          })
          .catch((e) => {
            this.setState({ isLoading: false });
            this.props.updateErrors([SUBMISSION_FAILED_ERROR]);
            throw new SubmissionError();
          });
      }
    });
  };

  fetchAndSetRenderingProvidersOptions = async (practiceId) => {
    this.setState({
      isFetchingRenderingProviders: true,
    });

    try {
      const providers = await CreateAppealAPI.getRenderingBillingProviders({
        practice_id: practiceId,
      });

      const filteredProvidersDropdownOptions = providers.map((provider) =>
        CreateAppealAPI.extractProviderOption(provider.rendering_provider)
      );

      // set the default option for single option rendering provider
      const isSinglefilteredProvidersDropdownOptions =
        filteredProvidersDropdownOptions.length === 1;
      if (isSinglefilteredProvidersDropdownOptions) {
        this.onProviderChange(filteredProvidersDropdownOptions[0].key);
      }
      const activeProviderDropdownOptions =
        filteredProvidersDropdownOptions.filter(({ isActive }) => isActive);
      const inActiveProviderDropdownOptions = filteredProvidersDropdownOptions
        .filter(({ isActive }) => !isActive)
        .map((option) => ({
          ...option,
          value: `${option.value} (Inactive)`,
          isActive: false,
        }));
      this.setState({
        providers,
        filteredProvidersDropdownOptions: [
          ...activeProviderDropdownOptions,
          ...inActiveProviderDropdownOptions,
        ],
        isFetchingRenderingProviders: false,
      });
    } catch (error) {
      this.setState({
        isFetchingRenderingProviders: false,
      });
      handleError(error);
    }
  };

  getFormDropdownOptions = () => {
    CreateAppealAPI.getDropdownOptions('clinics').then((clinics) => {
      this.setState({
        clinicsDropdownOptions: CreateAppealAPI.parseClinics(clinics),
      });
    });

    CreateAppealAPI.getDropdownOptions('payers').then((payers) => {
      this.setState({
        payersDropdownOptions: CreateAppealAPI.parsePayers(payers),
        payers: CreateAppealAPI.parsePayerData(payers),
        isPayerDisableForUHC:
          CreateAppealAPI.parsePayerData(payers).length > 0 &&
          this.props.isImportedAppeal,
      });
    });

    CreateAppealAPI.getClientInformation().then((res) => {
      const client = res.data[0];
      const enableAppealByFacility =
        client.attributes.enable_appeal_by_facility;

      this.setState({
        enableAppealByFacility,
      });
    });

    const userPractices = this.props.userPractices;
    const practiceDropdownOptions =
      CreateAppealAPI.parsePracticeData(userPractices);
    const activePracticeDropdownOptions = practiceDropdownOptions.filter(
      (practice) => practice.isActive
    );
    const inActivePracticeDropdownOptions = practiceDropdownOptions.filter(
      (practice) => !practice.isActive
    );
    this.setState({
      practiceDropdownOptions: [
        ...activePracticeDropdownOptions,
        ...inActivePracticeDropdownOptions.map((option) => ({
          ...option,
          value: `${option.value} (Inactive)`,
        })),
      ],
    });
  };
}

function getNonEmptyFields(integrationType, isImportedAppeal) {
  const nonEmptyFields = [
    {
      field: 'firstName',
      label: 'First Name',
    },
    {
      field: 'lastName',
      label: 'Last Name',
    },
    {
      field: 'provider',
      label: 'Provider',
    },
    {
      field: 'billingProvider',
      label: 'Billing Provider',
    },
    {
      field: 'payer',
      label: 'Payer',
    },
    {
      field: 'clinic',
      label: 'Clinic',
    },
    {
      field: 'memberId',
      label: 'Member ID',
    },
    {
      field: 'dob',
      label: 'Date of Birth',
    },
    {
      field: 'practiceId',
      label: 'Practice',
    },
    {
      field: 'payerContact',
      label: 'Payer Appeal Contact',
    },
  ];

  if (!isImportedAppeal && integrationType === INTEGRATION_TYPE.APM) {
    return [
      ...nonEmptyFields,
      {
        field: 'pmSystemAccountId',
        label: 'Voucher ID',
      },
    ];
  }

  return nonEmptyFields;
}

function validateWithFacility(values, integrationType, isImportedAppeal) {
  const errors = {};
  errors.displayErrors = [];

  const notEmptyFields = getNonEmptyFields(integrationType, isImportedAppeal);
  notEmptyFields.push({
    field: 'clinic',
    label: 'Facility',
  });

  const dateFields = [
    {
      field: 'dob',
      label: 'Date of Birth',
    },
  ];

  // Validate empty fields
  // eslint-disable-next-line guard-for-in
  for (const f in notEmptyFields) {
    const fieldName = notEmptyFields[f].field;
    const label = notEmptyFields[f].label;

    errors[fieldName] = validateExists(values.get(fieldName));
    addedRequiredFieldError(errors.displayErrors, errors[fieldName], label);
  }

  // Validate date fields
  // eslint-disable-next-line guard-for-in
  for (const f in dateFields) {
    const fieldName = dateFields[f].field;
    const label = dateFields[f].label;

    if (!errors[fieldName]) {
      errors[fieldName] = validateDateString(values.get(fieldName), true);
      addedDateFieldError(errors.displayErrors, errors[fieldName], label);
    }
  }

  return errors;
}

function validate(values, integrationType, isImportedAppeal) {
  const errors = {};
  errors.displayErrors = [];
  const notEmptyFields = getNonEmptyFields(integrationType, isImportedAppeal);
  const dateFields = [
    {
      field: 'dob',
      label: 'Date of Birth',
    },
  ];

  // Validate empty fields
  for (const f in notEmptyFields) {
    const fieldName = notEmptyFields[f].field;
    const label = notEmptyFields[f].label;

    errors[fieldName] = validateExists(values.get(fieldName));
    addedRequiredFieldError(errors.displayErrors, errors[fieldName], label);
  }

  // Validate date fields
  for (const f in dateFields) {
    const fieldName = dateFields[f].field;
    const label = dateFields[f].label;

    if (!errors[fieldName]) {
      errors[fieldName] = validateDateString(values.get(fieldName), true);
      addedDateFieldError(errors.displayErrors, errors[fieldName], label);
    }
  }

  return errors;
}

const selector = formValueSelector('patientInfo');

function mapStateToProps(state, ownProps) {
  const userInfo = getUserInfo(state);
  const integrationType = userInfo && userInfo.integrationType;
  const isMedicalRecordsSubmission =
    _.get(ownProps, 'initialValues.isMedicalRecordsSubmission', false) ||
    isCurrentAppealMedicalRecord(state);
  const errors = getFormSyncErrors('patientInfo')(state.toJS());
  const payerContact = selector(state, 'payerContact');
  const payer = selector(state, 'payer');
  const provider = selector(state, 'provider');
  const clinic = selector(state, 'clinic');
  const memberId = selector(state, 'memberId');
  const billingProvider = selector(state, 'billingProvider');
  const pmSystemAccountId = selector(state, 'pmSystemAccountId');
  const practiceId = selector(state, 'practiceId');
  const selectedAppeal = getCurrentAppealData(state);
  const isImportedAppeal = selectedAppeal
    ? selectedAppeal.get('imported')
    : false;
  const currentAppealStep = selectedAppeal ? selectedAppeal.get('step') : 0;
  const userPractices = _.get(userInfo, 'relatedPractices', []);
  const clientPartitionId = _.get(userInfo, 'practice', '');
  const appealId = selectedAppeal ? selectedAppeal.get('id') : null;
  return {
    appealId,
    formErrors: errors,
    payerContact,
    provider,
    payer,
    clinic,
    memberId,
    billingProvider,
    isImportedAppeal,
    currentAppealStep,
    pmSystemAccountId,
    integrationType,
    userPractices,
    practiceId,
    clientPartitionId,
    isMedicalRecordsSubmission,
    userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { change, push, setCurrentStep, createAppealAndSendPatientInfo },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ErrorHandling(
    reduxForm({
      form: 'patientInfo',
      enableReinitialize: true,
    })(PatientInfoFrom)
  )
);
