import React, { useState } from 'react';
import { BsPencil } from 'react-icons/bs';
import { IoMdCloseCircleOutline } from 'react-icons/io';

import { generateUUID } from 'helpers/utils';

import Card from 'components/common/card';
import * as toast from 'components/Shared/toast';
import AddPracticeForm from './AddPracticeForm/AddPracticeForm';
import { APPEALIO_SUPPORT_EMAIL } from 'constants/appConstants';
import ActionPopup, { TYPE_ACCEPT } from 'components/Shared/ActionPopup';

import infoIcon from 'img/info.svg';
import warningIcon from 'img/incomplete.svg';

const AddPracticeContainer = (props) => {
  const { practices, updateFormData, onPracticeDelete } = props;

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [practiceToUpdate, setPracticeToUpdate] = useState(null);
  const [deletePracticeModal, setDeletePracticeModal] = useState({
    isOpen: false,
    practice: null,
  });
  const resetDeletePracticeModal = () =>
    setDeletePracticeModal({
      isOpen: false,
      practice: null,
    });
  const openDeletePracticeModal = (practice) =>
    setDeletePracticeModal({
      isOpen: true,
      practice,
    });
  const resetFormVisibility = () => {
    setIsFormVisible(false);
    setPracticeToUpdate(null);
  };

  const handleSubmit = (values) => {
    if (!practiceToUpdate) {
      resetFormVisibility();

      return updateFormData([
        ...practices,
        { uuid: generateUUID(), ...values },
      ]);
    }

    const updatedPractices = practices.map((practice) =>
      practice.uuid === practiceToUpdate.uuid
        ? { uuid: practiceToUpdate.uuid, ...values }
        : practice
    );

    resetFormVisibility();
    updateFormData(updatedPractices);
  };

  const handleDelete = (practiceToDelete) => {
    const newPractices = practices.filter(
      (practice) => practice.uuid !== practiceToDelete.uuid
    );

    onPracticeDelete(practiceToDelete.uuid);
    updateFormData(newPractices);
  };

  const renderDeleteConfirmation = () => {
    if (!deletePracticeModal.isOpen) return;
    const handleClosePressed = () => resetDeletePracticeModal(null);
    const handleActionButtonPress = (index) => {
      if (index !== 1) {
        return handleClosePressed();
      }
      handleDelete(deletePracticeModal.practice);
      handleClosePressed();
    };

    return (
      <ActionPopup
        title={'Delete Practice?'}
        subtitle="Deleting practice will remove all clinics, billing providers, and rendering providers associated with the practice."
        actionButtons={[
          {
            title: 'No',
          },
          {
            title: 'Yes',
            type: TYPE_ACCEPT,
          },
        ]}
        icon={warningIcon}
        onClosePressed={handleClosePressed}
        onActionButtonPressed={handleActionButtonPress}
      />
    );
  };
  return (
    <div className="card-wrapper">
      <Card className="min-height-40 mb-58">
        <div className="row form-info-row">
          <div className="col-md-9">
            <div className="form-section-title">Add all practice(s)</div>
            <div className="form-section-description">
              <div>
                Please add your practice(s). If you have any questions, please
                feel free to contact us at {APPEALIO_SUPPORT_EMAIL}
              </div>
              <div className="mt-2">
                <span>
                  <img src={infoIcon} alt="info" className="mr-4" />A practice
                  is one or more Providers organized in a legal entity for the
                  purpose of delivering healthcare.
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-offset-1 col-md-2">
            {practices.length > 0 && (
              <button
                onClick={() => {
                  if (practices.length === 10) {
                    return toast.warning({
                      title: '',
                      message: `You can add up to 10 practices from this onboarding form.
                      You can add/update practices from the app after your successful onboarding.`,
                    });
                  }
                  setPracticeToUpdate(null);
                  setIsFormVisible(true);
                }}
                className="ap-button ap-button--secondary add-btn--main"
              >
                <span className="mr-4">+</span> Add Practice
              </button>
            )}
          </div>
        </div>

        <div className="mt-8">
          {practices.length > 0 && (
            <div className="row mb-16 create-acount-list-item-header">
              <div className="col-lg-2">Practice Name</div>
              <div className="col-lg-2">Address 1</div>
              <div className="col-lg-2">Address 2</div>
              <div className="col-lg-2">City</div>
              <div className="col-lg-1">State</div>
              <div className="col-lg-1">Zip</div>
              <div className="col-lg-2">&nbsp;</div>
            </div>
          )}
          {practices.map((practice, idx) => {
            return (
              <div key={idx} className="create-account-list-item row mb-16">
                <div className="col-lg-2">{practice.name}</div>
                <div className="col-lg-2">
                  <span data-tip={practice.address1}>{practice.address1}</span>
                </div>
                <div className="col-lg-2">
                  <span data-tip={practice.address2}>{practice.address2}</span>
                </div>
                <div className="col-lg-2">
                  <span data-tip={practice.city}>{practice.city}</span>
                </div>
                <div className="col-lg-1">
                  <span data-tip={practice.state}>{practice.state}</span>
                </div>
                <div className="col-lg-1">
                  <span data-tip={practice.zipcode}>{practice.zipcode}</span>
                </div>
                <div className="col-lg-2">
                  <BsPencil
                    size={16}
                    title="Edit"
                    className="mr-16"
                    onClick={(e) => {
                      setPracticeToUpdate(practice);
                      setIsFormVisible(true);
                    }}
                  />
                  <IoMdCloseCircleOutline
                    title="Delete"
                    size={16}
                    onClick={() => openDeletePracticeModal(practice)}
                  />
                </div>
              </div>
            );
          })}
          {!isFormVisible && practices.length < 1 && (
            <button
              onClick={() => {
                setPracticeToUpdate(null);
                setIsFormVisible(true);
              }}
              className="add-item-btn"
            >
              <span className="mr-4">+</span> Add Your First Practice
            </button>
          )}

          {isFormVisible && (
            <AddPracticeForm
              onSubmit={handleSubmit}
              initialValues={practiceToUpdate}
              onCancel={resetFormVisibility}
            />
          )}
        </div>
      </Card>
      {renderDeleteConfirmation()}
    </div>
  );
};

export default AddPracticeContainer;
