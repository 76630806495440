import { SET_SHIPPING_ADDRESS, RESET_SHIPPING_ADDRESS } from './actionTypes';

export function setShippingAddress(data) {
  return {
    type: SET_SHIPPING_ADDRESS,
    payload: {
      shippingAddress: data,
    },
  };
}

export function fetchShippingAddress(state) {
  return state.getIn(['appealFormPrefillStore', 'submissionInfo']);
}

export function clearCurrentShippingAddress() {
  return {
    type: RESET_SHIPPING_ADDRESS,
  };
}
