import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { BsPencil, BsPencilFill } from 'react-icons/bs';

import Button from 'components/common/button';
import * as toast from 'components/Shared/toast';
import { BUTTON_TYPE } from 'components/common/button/Button';

import './style.css';

const SopAccordion = (props) => {
  const {
    title,
    headerDetails,
    content,
    onFormSubmit,
    disableSubmitButton = false,
    hideToggleButton = false,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    if (disableSubmitButton) {
      toast.error({
        title: 'Error',
        message:
          'Some step(s) in your SOP are incomplete. Please complete all required step(s).',
      });
      return;
    }
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={classnames('sop-accordion', {
        'sop-accordion--open': isOpen,
      })}
    >
      <div className="sop-accordion__header">
        <div className="sop-accordion__header-info">
          <div className="sop-accordion__header-info-text">{title}</div>
          {!isOpen && (
            <div className="sop-accordion__header-info-details">
              {headerDetails}
            </div>
          )}
        </div>
        {hideToggleButton && (
          <div
            className="mr-24 cursor-pointer"
            onClick={toggleAccordion}
            data-for="edit-sop-parameter"
            data-tip="Click to edit"
          >
            {isOpen ? <BsPencilFill /> : <BsPencil />}
            <ReactTooltip place="left" id="edit-sop-parameter" />
          </div>
        )}
      </div>
      {isOpen && (
        <div className="sop-accordion__content">
          {disableSubmitButton && (
            <div className="fs-12 mb-12 error">
              Some step(s) in the SOP are incomplete. Please complete all
              required step(s) before saving.
            </div>
          )}
          <div>{content}</div>
          <div className="d-flex justify-content-flex-end mt-40 mb-40">
            <Button
              title="Cancel"
              type={BUTTON_TYPE.LIGHT}
              className="width-120 justify-content-center fw-normal mr-8"
              onClick={toggleAccordion}
              buttonType="reset"
            />
            <Button
              type="button"
              className="width-120 ap-button ap-button--secondary justify-content-center fw-normal height-40"
              onClick={() => onFormSubmit()}
              disabled={disableSubmitButton}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

SopAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  headerDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onFormSubmit: PropTypes.func,
  disableSubmitButton: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default SopAccordion;
