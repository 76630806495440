import * as Sentry from '@sentry/browser';

/**
 * Starts a new Sentry transaction with the specified name and operation.
 * Optionally adds user info to the transaction's data.
 *
 * @param {string} transactionName - The name of the transaction.
 * @param {{ username?: string, clientName?: string }} [userInfo] - User information to add to the transaction's data.
 * @param {string} [operation='performance'] - The operation type of the transaction (e.g. performance, event, etc.).
 * @returns {Sentry.Transaction} The new Sentry transaction.
 */
export function startSentryTransaction(
  transactionName,
  userInfo,
  operation = 'performance'
) {
  const transaction = Sentry.startTransaction({
    name: transactionName,
    op: operation,
  });

  const username = userInfo?.username;
  const client = userInfo?.clientName;

  transaction?.setData('username', username);
  transaction?.setData('client', client);

  return transaction;
}
