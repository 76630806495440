import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { replace } from 'connected-react-router';

import '../stylesheets/styles.css';

import * as routes from 'constants/routes';

import TopBar from './Shared/TopBar';
import AuthAPI from '../API/AuthAPI';
import LoadingIndicator from './Shared/LoadingIndicator';
import BatchSubmissionWidget from './BatchSubmissionWidget';
import AvailityClaimStatusCheckWidget from './AvailityClaimStatusCheck/AvailityClaimStatusCheckWidget';

import { setAutoLoginInfo, signOut } from '../redux/actions/loginActions';
import { clearCurrentAppealData } from '../redux/actions/createAppealActions';
import { getUserInfo, isAuthenticated } from '../redux/reducers/loginStore';

import { getAuthToken, deleteAuthToken } from '../helpers/tokenStorage';
import { ONBOARDING } from 'constants/appConstants';

import UhcClaimStatusCheckWidget from './UhcClaimStatusCheck/UhcClaimStatusCheckWidget';
import {
  isAvailityClaimStatusCheckEnabledForUser,
  isUHCClaimStatusCheckEnabledForUser,
} from 'Auth/FeatureFlags';

class App extends Component {
  static defaultProps = {
    isLoggedIn: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isCheckingToken: true,
    };
  }

  componentDidMount() {
    const savedAuthToken = getAuthToken();
    if (!savedAuthToken) {
      this.setState({
        isCheckingToken: false,
      });
    } else {
      AuthAPI.getUserInfo(savedAuthToken, false)
        .then((userInfo) => {
          this.props.actions.setAutoLoginInfo(savedAuthToken, userInfo);
          this.setState({
            isCheckingToken: false,
          });

          if (
            userInfo.clientStatus === ONBOARDING ||
            userInfo.status === ONBOARDING
          ) {
            return this.props.actions.replace(
              routes.CREATE_APPEALIO_ACCOUNT_DETAIL
            );
          }
        })
        .catch((e) => {
          deleteAuthToken();
          this.setState({
            isCheckingToken: false,
          });
        });
    }
  }

  render() {
    return (
      <div className="App" datacy="App">
        <TopBar
          isLoggedIn={this.props.isLoggedIn}
          isAdmin={true}
          datacy="app-TopBar"
        />
        {this.renderContent()}
        {this.renderLoadingIndiciator()}
        {this.props.isLoggedIn &&
          isUHCClaimStatusCheckEnabledForUser(this.props.userInfo) && (
            <React.Fragment>
              <UhcClaimStatusCheckWidget />
            </React.Fragment>
          )}
        {this.props.isLoggedIn &&
          isAvailityClaimStatusCheckEnabledForUser(this.props.userInfo) && (
            <React.Fragment>
              <AvailityClaimStatusCheckWidget />
            </React.Fragment>
          )}
        {this.props.isLoggedIn && <BatchSubmissionWidget />}
      </div>
    );
  }

  renderContent() {
    if (!this.state.isCheckingToken) {
      return this.props.children;
    }
  }

  renderLoadingIndiciator() {
    if (this.state.isCheckingToken) {
      return <LoadingIndicator showing={true} />;
    }
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: isAuthenticated(state),
    userInfo: getUserInfo(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { setAutoLoginInfo, signOut, replace, clearCurrentAppealData },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
