import { orderBy } from 'lodash';
import {
  LOGIN_AUTH,
  FORGOT_PASSWORD_SEND_EMAIL,
  RESET_PASSWORD,
  CLEAR_AUTH_TOKEN,
  LOGIN_SET_AUTOLOGIN_INFO,
  SET_RELATED_PRACTICES,
  LOGOUT,
} from './actionTypes';
import AuthAPI from '../../API/AuthAPI';

export function sendAuthentication(username, password) {
  return {
    type: LOGIN_AUTH,
    payload: AuthAPI.authenticate(username, password),
  };
}

export function sendForgotPasswordEmail(email, shouldCreatePassword = false) {
  return {
    type: FORGOT_PASSWORD_SEND_EMAIL,
    payload: AuthAPI.sendForgotPasswordEmail(email, shouldCreatePassword),
  };
}

export function resetPassword(uid, token, newPassword) {
  return {
    type: RESET_PASSWORD,
    payload: AuthAPI.resetPassword(uid, token, newPassword),
  };
}

export function clearAuthToken() {
  return {
    type: CLEAR_AUTH_TOKEN,
  };
}

export function setAutoLoginInfo(token, userInfo) {
  return {
    type: LOGIN_SET_AUTOLOGIN_INFO,
    authToken: token,
    userInfo: {
      ...userInfo,
      relatedPractices: orderBy(userInfo.relatedPractices, ['name'], ['asc']),
    },
  };
}

export function signOut(token) {
  return {
    type: LOGOUT,
    payload: AuthAPI.signOut(token),
  };
}

export function setUserPractices(practices) {
  return {
    type: SET_RELATED_PRACTICES,
    payload: practices,
  };
}
