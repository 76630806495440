import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/common/button';
import AppealioPopup from 'components/Shared/AppealioPopup';

import checkIcon from 'img/check-sharp.svg';

import './style.css';

const ImportSuccessfulPopup = (props) => {
  const { onCloseClick, cleanRowsCount } = props;
  return (
    <AppealioPopup
      className="appealio-popup appealio-action-popup"
      titleClassName="appealio-action-popup__header csv-claims-import-confirm-popup__title"
      title="Import Successful!"
    >
      <div className="d-flex align-items-center justify-content-center">
        <img
          className="csv-claims-import-successful-popup__icon"
          src={checkIcon}
          alt="check"
        />
      </div>
      <div className="d-flex align-items-center justify-content-center">
        {cleanRowsCount} row(s) imported
      </div>

      <Button
        className="mt-60 width-full ap-button--secondary csv-claims-import-confirm-popup-close-cancel-btn"
        onClick={onCloseClick}
      >
        Close
      </Button>
    </AppealioPopup>
  );
};

ImportSuccessfulPopup.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
};

export default ImportSuccessfulPopup;
