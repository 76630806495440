import React from 'react';
import PropTypes from 'prop-types';

import closeIcon from 'img/close-modal.svg';

import LoadingIndicator from 'components/Shared/LoadingIndicator';
import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';

import { useFetchTransactionSummary } from './hooks';

const TransactionSummaryPopup = (props) => {
  const { setShowTransactionPopup } = props;

  const { loading, transactionSummary } = useFetchTransactionSummary({
    isStandAloneUser: true,
  });

  return (
    <ScreenDimmerComponent>
      <div
        className={
          'ap-popup transaction-summary-popup ap-popup--overflow--visible ap-popup--p-0'
        }
      >
        <div className="ap-popup__header ap-popup__header--with-border">
          <div className="ap-popup__title">Transaction Summary</div>
          {loading ? (
            <LoadingIndicator showing={true} />
          ) : (
            <button className="ap-button appealio-express__balance appealio-express__balance--status">
              {' '}
              Balance:{' '}
              <span className="link-text ml-4">
                ${transactionSummary.currentBalance || '0.00'}
              </span>
            </button>
          )}
          <div
            className="ap-popup__close"
            onClick={() => {
              setShowTransactionPopup(false);
            }}
          >
            <img alt="Close" className="ap-popup__close-icon" src={closeIcon} />
          </div>
        </div>
        <div className="ap-popup__content">
          {loading ? (
            <LoadingIndicator showing={true} />
          ) : (
            <React.Fragment>
              <div className="appealio-express__stat-wrapper">
                <div className="data-widget-card">
                  <h5 className="data-widget-card__title">Total Submissions</h5>
                  <p className="data-widget-card__value">
                    {transactionSummary.transactionDetails?.totalSubmissions ||
                      '0'}
                  </p>
                </div>
                <div className="data-widget-card">
                  <h5 className="data-widget-card__title">
                    Total Charged Amount
                  </h5>
                  <p className="data-widget-card__value">
                    $
                    {transactionSummary.transactionDetails
                      ?.totalChargedAmount || '0.00'}
                  </p>
                </div>
                <div className="data-widget-card">
                  <h5 className="data-widget-card__title">
                    Total Submissions via Fax
                  </h5>
                  <p className="data-widget-card__value">
                    {transactionSummary.transactionDetails
                      ?.numberOfFaxSubmissions || '0'}
                  </p>
                </div>
                <div className="data-widget-card">
                  <h5 className="data-widget-card__title">
                    Total Submissions Via Mail
                  </h5>
                  <p className="data-widget-card__value">
                    {transactionSummary.transactionDetails
                      ?.numberOfMailSubmissions || '0'}
                  </p>
                </div>
                <div className="data-widget-card">
                  <h5 className="data-widget-card__title">
                    Total Submissions Via Certified Mail
                  </h5>
                  <p className="data-widget-card__value">
                    {transactionSummary.transactionDetails
                      ?.numberOfCertifiedMailSubmissions || '0'}
                  </p>
                </div>
              </div>
              <div className="appealio-express__transaction-history">
                <h5 className="ap-popup__title">Transaction History</h5>

                <table>
                  <thead>
                    <tr>
                      <th>Transaction Date</th>
                      <th>Description</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionSummary.paymentLogs &&
                    transactionSummary.paymentLogs.length > 0 ? (
                      transactionSummary.paymentLogs.map((log, idx) => (
                        <tr key={idx}>
                          <td>{log.createdAt}</td>
                          <td>{log.description}</td>
                          <td>
                            $
                            {log.chargedAmount
                              ? `${log.chargedAmount}`
                              : '0.00'}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3}>
                          <p className="text-center text-bold">
                            No Transaction History
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </ScreenDimmerComponent>
  );
};

TransactionSummaryPopup.propTypes = {
  setShowTransactionPopup: PropTypes.func.isRequired,
};

export default TransactionSummaryPopup;
