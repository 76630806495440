import React from 'react';

import '../style.css';

const PortalSubmissionDetails = ({ data: payerPortalUrl }) => {
  return (
    <div>
      <div className="submission-details-title">Payer Portal Submission</div>
      <p className="mt-12">
        <label>URL</label> :{' '}
        {payerPortalUrl ? (
          <a target="_blank" rel="noreferrer" href={payerPortalUrl}>
            {payerPortalUrl}
          </a>
        ) : (
          'N/A'
        )}{' '}
      </p>
    </div>
  );
};

export default PortalSubmissionDetails;
