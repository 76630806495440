import { get as _get } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, SubmissionError } from 'redux-form/immutable';

import { validate, renderField } from './inputs';
import SubmitButton from '../Shared/SubmitButton';
import LoadingBlockHelper from '../Shared/LoadingBlockHelper';

import { sendAuthentication } from '../../redux/actions/loginActions';

import '../Signup/style.css';

class LoginForm extends Component {
  render() {
    const { error, handleSubmit, pristine, submitting, invalid } = this.props;

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <div>
          <Field
            label="Username"
            showCheckmarkIfOk={false}
            type="text"
            name="username"
            datacy="username-Field"
            component={renderField}
          />
        </div>

        <div>
          <Field
            label="Password"
            showCheckmarkIfOk={false}
            type="password"
            name="password"
            datacy="password-Field"
            component={renderField}
          />
        </div>

        {error && (
          <div className="login__error-wrapper">
            {' '}
            <strong className="login__error">{error}</strong>{' '}
          </div>
        )}

        <div className="login__footer">
          <div className="login__footer-right">
            <Link
              className="login__forgot-password-link"
              to={'/forgotpassword'}
              datacy="forgot-password-Link"
            >
              Forgot Password?
            </Link>
          </div>
        </div>

        <LoadingBlockHelper
          className="login__loadingblockhelper"
          isLoading={submitting}
        >
          <SubmitButton
            className="submit__button submit__button--fullwidth"
            disabled={submitting || pristine || invalid}
            datacy="submit-Button"
          />
        </LoadingBlockHelper>
      </form>
    );
  }

  submit = (values) => {
    return this.props.actions
      .sendAuthentication(values.get('username'), values.get('password'))
      .then((res) => {
        const userInfo = _get(res, 'value.userInfo', null);
        this.props.onSuccessfulLogin(userInfo);
      })
      .catch((e) => {
        if ([419, 429].includes(e.response.status)) {
          throw new SubmissionError({ _error: e.message });
        }

        throw new SubmissionError({
          _error: 'Incorrect username or password. Please try again.',
        });
      });
  };
}

function mapStateToProps(state) {
  const loginStore = state.get('loginStore');
  return {
    loginStore,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ sendAuthentication }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'login',
    validate,
  })(LoginForm)
);
