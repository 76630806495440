import React from 'react';
import { AiOutlineFolder } from 'react-icons/ai';
import { Field, SubmissionError, reduxForm } from 'redux-form/immutable';

import Input from 'components/common/input';
import { AppealioPopupWithFooter } from 'components/common/popup';

import { createTeam } from 'API/AccountSettingsAPI';

import { handleError } from 'helpers/errorHandler';

const AddTeam = ({
  onClosePressed,
  invalid,
  submitting,
  handleSubmit,
  onAddTeamsSuccess,
}) => {
  /**
   * Handles the form submission.
   *
   * @param {Object} values - Form values.
   */
  const handleFormSubmit = async (values) => {
    const data = {
      name: values.toJS().name,
      users_ids: [],
    };
    try {
      const { data: newTeam } = await createTeam(data);
      onAddTeamsSuccess(newTeam);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const err = await error.response.json();
        throw new SubmissionError(err);
      }
      handleError(error);
    }
  };

  return (
    <AppealioPopupWithFooter
      className="appealio-popup--v3 appealio-popup--v3--overflow-hidden"
      onClosePressed={onClosePressed}
      title={
        <div className="d-flex align-items-center">
          <AiOutlineFolder size="22" className="mr-4" /> Create Team
        </div>
      }
      isFooterOutside={false}
      datacy="add-practice-AppealioPopupWithFooter"
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="row mb-12">
          <div className="col-lg-12">
            <Field
              component={Input}
              name="name"
              label="Enter Team Name *"
              placeholder="Team Name"
            />
          </div>
        </div>
        <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
          <button
            type="submit"
            disabled={invalid || submitting}
            className="ap-button ap-button--primary-dark ap-button--primary-md"
          >
            Create
          </button>
        </div>
      </form>
    </AppealioPopupWithFooter>
  );
};

export default reduxForm({
  form: 'add-team-form',
})(AddTeam);
