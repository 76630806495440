import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { areUrlsSame } from 'helpers/utils';
import { useLocation } from 'react-router-dom';
import { replace } from 'connected-react-router';

const SubmissionsTab = (props) => {
  const { label, route, activeRoutes } = props;
  const location = useLocation();
  const isActive = activeRoutes.some((route) =>
    areUrlsSame(route, location.pathname)
  );

  return (
    <div
      className={classnames('appealio-tab-filter__item', {
        'appealio-tab-filter__item--active': isActive,
      })}
      onClick={() => {
        props.actions.replace(route.default);
      }}
    >
      {label}
    </div>
  );
};

SubmissionsTab.propTypes = {
  label: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
  activeRoutes: PropTypes.array,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ replace }, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(SubmissionsTab);
