/**
 * Created by alex on 11/11/16.
 */

import { Serializer as JSONAPISerializer } from 'jsonapi-serializer';

export const AppealInfoSerializer = new JSONAPISerializer('Appeal', {
  attributes: [
    'clinic',
    'memberId',
    'customPatientId',
    'pmSystemAccountId',
    'provider',
    'step',
    'billingProvider',
    'PayerContact',
    'Agent',
    'Payer',
    'practiceId',
    'claimNumber',
    'submitterClaimId',
    'isMedicalRecordsSubmission',
    'ediPayerId',
  ],
  PayerContact: {
    ref: 'id',
    included: false,
  },
  Agent: {
    ref: 'id',
    included: false,
  },
  pluralizeType: false,
  keyForAttribute: 'underscore_case',
  typeForAttribute: (arg) => {
    if (arg === 'Agent') return 'User';
  },
});
