import moment from 'moment';
import { DATE_FORMATS } from '../../helpers/dateFormats';
import _ from 'lodash';
import pad from 'lodash/padStart';

export const transformAmountString = (amountString) => {
  return amountString.replace('$', '').replace(/,/g, '');
};

export const transformDateString = (dateString) => {
  return moment(dateString, DATE_FORMATS).format('YYYY-MM-DD');
};

export const transformToDateInput = (dateString) => {
  return moment(dateString, DATE_FORMATS).format('MM/DD/YYYY');
};

export const transformNumberToString = (number) => {
  return number.toString();
};

export const transformDayMonthYearDateObjectToString = (dateObject) => {
  return dateObject.year + '-' + dateObject.month + '-' + dateObject.day;
};

export const getTodayDayMonthYearObject = () => {
  const today = moment();

  return {
    year: pad(today.year(), 2, '0'),
    month: pad(today.month() + 1, 2, '0'),
    day: pad(today.date(), 2, '0'),
  };
};

export const getTodayString = () => {
  return transformToDateInput(moment());
};

export const transformStringToYearMonthDayObject = (string) => {
  if (!string) return null;

  const components = string.split('-');
  return {
    year: components[0],
    month: components[1],
    day: components[2],
  };
};

export const transformStringWithCommasToArray = (string) => {
  if (!string) {
    return [];
  }
  const array = string.split(',') || [];
  const trimmedArray = array.map((e) => _.trim(e));
  return _.compact(trimmedArray);
};

export const transformArrayToStringWithCommas = (array) => {
  return _.join(array, ',');
};

export const transformLinkPathToBasename = (filePath) => {
  return filePath.substring(filePath.indexOf('/') + 1);
};

export const isGreaterThanToday = (dateString) => {
  return moment(dateString).diff(moment(), 'days') > 0;
};
