import React from 'react';

import ToggleSwitch from 'components/common/toggle';

import './style.css';

const AutoReminderToggle = (props) => {
  const options = props?.showLabels
    ? { option1: 'Off', option2: 'On' }
    : { option1: '', option2: '' };

  return (
    <ToggleSwitch
      options={options}
      checked={props.checked}
      handleToggle={props.handleToggle}
      className="auto-reminder-toggle"
    />
  );
};

export default AutoReminderToggle;
