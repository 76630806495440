import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { Component } from 'react';

import dropdownIcon from '../../img/triangle.svg';

import { dropdownKeyDown } from './dropdownKeyDown';

const MAX_SIZE_WITHOUT_SEARCH = 10;

export default class DropdownListFilter extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    onDropdownFilterChange: PropTypes.func.isRequired,
    doesDisplayKey: PropTypes.bool,
    selectedKey: PropTypes.string,
    dropdownOptions: PropTypes.array.isRequired,
    shouldTrimOptionLength: PropTypes.bool.isRequired,
    maxStringLength: PropTypes.number,
    allowNoneItem: PropTypes.bool,
  };

  static defaultProps = {
    shouldTrimOptionLength: false,
    selectedKey: '',
    doesDisplayKey: false,
    allowNoneItem: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      dropdownIsDisplayed: false,
      selectedIndex: this.getIndexFromKey(props.selectedKey),
      showSearchOptions: false,
      searchString: '',
    };

    this.searchStringTimeoutDelay = 400;
    this.searchStringTimeout = () => {};

    this.maxStringLength =
      typeof this.props.maxStringLength === 'undefined'
        ? -1
        : this.props.maxStringLength;
    this.onPageClickFunction = () => this.onPageClick();
  }

  componentDidMount() {
    document.addEventListener('click', this.onPageClickFunction, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onPageClickFunction, true);
  }

  onPageClick = () => {
    if (this.state.dropdownIsDisplayed) {
      this.resetDropdown();
    }
  };

  getIndexFromKey = (
    selectedKey,
    dropdownOptions = this.props.dropdownOptions
  ) => {
    return _.findIndex(dropdownOptions, (o) => {
      return o.key.toString() === selectedKey.toString();
    });
  };

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      selectedIndex: this.getIndexFromKey(
        props.selectedKey,
        props.dropdownOptions
      ),
    });
  }

  showDropdown = () => {
    const dropdownIsDisplayed = true;
    this.setState({ dropdownIsDisplayed });

    if (this.props.dropdownOptions.length > MAX_SIZE_WITHOUT_SEARCH) {
      this.setState({ showSearchOptions: true });
    }
  };

  resetDropdown = () => {
    this.setState({
      dropdownIsDisplayed: false,
      showSearchOptions: false,
      searchString: '',
    });
  };

  renderMainDisplayItem = (selectedIndex) => {
    if (this.state.showSearchOptions) return;

    let displayText =
      selectedIndex >= 0
        ? this.getCorrectDisplayValue(selectedIndex)
        : this.props.placeholder;
    const displayTextClass = classNames(
      'dropdownfilter__display-selected-text',
      {
        'dropdownfilter__display-selected-text--bold':
          this.props.dropdownIndexSelected >= 0,
      }
    );

    if (this.state.searchString !== '') {
      displayText = this.state.searchString;
    } else if (
      this.props.shouldTrimOptionLength === true &&
      displayText.length > this.maxStringLength
    ) {
      displayText = displayText.substring(0, this.maxStringLength - 1);
      displayText += '...';
    }

    return (
      <div className="dropdownfilter__display-selected">
        <div className={displayTextClass}>{displayText}</div>
        <img
          alt="Dropdown Filter"
          className="dropdownfilter__display-selected-img"
          src={dropdownIcon}
        />
      </div>
    );
  };

  renderSearchInput = () => {
    if (!this.state.showSearchOptions) return;

    return (
      <div>
        <div className="dropdownfilter__input-layout">
          <input
            className="dropdownfilter__input"
            placeholder={this.props.placeholder}
            ref={(input) => input && input.focus()}
            onChange={this.onInputChange}
          />
        </div>
        <img
          alt="Search"
          className="dropdownfilter__input-img"
          src={dropdownIcon}
        />
      </div>
    );
  };

  onInputChange = (e) => {
    this.setSearchString(e.target.value);
  };

  setSearchString = (string) => {
    const newIndex = _.findIndex(this.props.dropdownOptions, (d) => {
      return d.value.toString().includes(string);
    });
    if (newIndex >= 0) {
      this.setState({ searchString: string });
      clearTimeout(this.searchStringTimeout);
      this.searchStringTimeout = setTimeout(() => {
        this.setState({ searchString: '' });
        this.onDropdownFilterChange(+newIndex);
      }, this.searchStringTimeoutDelay);
    } else {
      clearTimeout(this.searchStringTimeout);
      this.setState({ searchString: '' });
    }
  };

  renderListItems = () => {
    const dropdownOptions = this.props.dropdownOptions;
    const searchString = this.state.searchString;
    // eslint-disable-next-line array-callback-return
    const listItems = dropdownOptions.map((value, index) => {
      const dropdownOption = this.getCorrectDisplayValue(index);
      if (searchString !== '') {
        if (
          _.startsWith(dropdownOption.toLowerCase(), searchString.toLowerCase())
        ) {
          return this.createListItem(index, dropdownOption);
        }
      } else {
        return this.createListItem(index, dropdownOption);
      }
    });
    if (this.props.allowNoneItem === true) {
      listItems.splice(0, 0, this.createListItem(-1, 'None'));
    }
    return listItems;
  };

  createListItem(index, value) {
    const itemClassName = classNames('dropdownfilter__list-item');
    return (
      <div
        key={+index}
        onClick={(e) => this.itemClick(e, +index)}
        className={itemClassName}
      >
        {value}
      </div>
    );
  }

  getCorrectDisplayValue = (index) => {
    let dropdownOption = '';
    if (this.props.doesDisplayKey) {
      dropdownOption = this.props.dropdownOptions[index].key.toString();
    } else {
      dropdownOption = this.props.dropdownOptions[index].value.toString();
    }
    return dropdownOption;
  };

  itemClick = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    this.onDropdownFilterChange(+index);
    this.resetDropdown();
  };

  onDropdownFilterChange = (selectedIndex) => {
    if (selectedIndex === -1) {
      this.props.onDropdownFilterChange('');
    } else {
      this.props.onDropdownFilterChange(
        this.props.dropdownOptions[selectedIndex].key.toString()
      );
    }
    this.resetDropdown();
  };

  getListClass = () => {
    return classNames('dropdownfilter__list', {
      hidden: !this.state.dropdownIsDisplayed,
    });
  };

  getDropdownClass = () => {
    return classNames('dropdownfilter', {
      'dropdownfilter--error': this.props.error && this.props.touched,
    });
  };

  onClick = () => {
    this.showDropdown();
  };

  render() {
    return (
      <div
        onKeyDown={(e) =>
          dropdownKeyDown(
            e,
            this.props.dropdownOptions,
            this.state.selectedIndex,
            this.onDropdownFilterChange,
            this.resetDropdown,
            this.setSearchString,
            this.state.searchString
          )
        }
        tabIndex={0}
        name={this.props.input.name}
        className={this.getDropdownClass()}
        datacy="dropdown-list-filter-for-key-value"
      >
        <div className="dropdownfilter__display" onClick={this.onClick}>
          {this.renderMainDisplayItem(this.state.selectedIndex)}
        </div>
        <div className={this.getListClass()}>
          {this.renderSearchInput()}
          {this.renderMainDisplayItem(this.state.selectedIndex)}
          {this.renderListItems()}
        </div>
      </div>
    );
  }
}
