import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './style.css';

const CheckboxOption = ({
  label,
  disabled = false,
  checked = true,
  onChange,
  withoutBackground = false,
  className,
  input = {},
}) => {
  return (
    <div
      className={classnames('checkbox-option-container', className, {
        [`checkbox-option-container--disabled`]: false,
        [`checkbox-option-container--without-background`]: withoutBackground,
      })}
    >
      <label
        htmlFor={`${label}`}
        className={classnames('checkbox-option__label', {
          'checkbox-option__label--disabled': disabled,
          'checkbox-option__label--active': checked,
          'checkbox-option__label--without-background': withoutBackground,
        })}
      >
        <input
          className="checkbox-option__input"
          id={`${label}`}
          type="checkbox"
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          {...input}
        />

        {label}
      </label>
    </div>
  );
};

CheckboxOption.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default CheckboxOption;
