import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { RiPrinterFill } from 'react-icons/ri';

import './style.css';

const FloatingPrintButton = (props) => {
  const {
    title = 'Print Screen',
    onClick = null,
    ButtonIcon = RiPrinterFill,
  } = props;

  const handleButtonClick = (e) => {
    e.stopPropagation();

    if (onClick) {
      return onClick();
    }

    window.print();
  };

  return (
    <div className="floating-print-btn-wrapper no-print">
      <button
        onClick={handleButtonClick}
        data-tip={title}
        className="floating-print-btn"
        datacy="floating-print-button"
      >
        <ButtonIcon className="print-icon" />
      </button>
      <ReactTooltip effect="solid" place="top" multiline={true} />
    </div>
  );
};

FloatingPrintButton.propTypes = {
  title: PropTypes.string,
};

export default FloatingPrintButton;
