import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoadingIndicator from '../Shared/LoadingIndicator';
import classNames from 'classnames';
import LoadingBar from 'components/common/loadingBar/LoadingBar';

// NOTE:
// Wrap a html block with this class if it relies on a
// loading data and you want to show a spinner while it loads

export default class LoadingBlockHelper extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    className: PropTypes.string,
    loadingMessage: PropTypes.node,
    useProgressBar: PropTypes.bool,
  };

  render() {
    const hideUntilLoadClass = classNames({
      invisible: this.props.isLoading === true,
    });
    const loadingBlockHelperWrapperClasses = classNames(
      'loadingblockhelper',
      this.props.className
    );

    return (
      <div
        className={loadingBlockHelperWrapperClasses}
        datacy="loading-block-helper"
      >
        <div className={hideUntilLoadClass}>{this.props.children}</div>
        {!this.props.useProgressBar ? (
          <LoadingIndicator showing={this.props.isLoading}>
            <div className="d-flex justify-content-center mt-8">
              {this.props.loadingMessage}
            </div>
          </LoadingIndicator>
        ) : (
          <LoadingBar showing={this.props.isLoading}>
            <div className="d-flex justify-content-center mt-8">
              {this.props.loadingMessage}
            </div>
          </LoadingBar>
        )}
      </div>
    );
  }
}
