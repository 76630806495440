import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import './style.css';

import Bar from './Bar';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';

import { getUserInfo } from 'redux/reducers/loginStore';

import { OTHER_HEX, PRE_DEFINED_BACKGROUND_COLORS } from './constant';

import { generateRandomHex, processValues } from './utils';

const ActionBar = ({
  value = [],
  actionsData,
  userInfo,
  searchData = [],
  type = 'normal',
  actionBarKey = 'actionBarKey',
  barClassName = '',
  actionColorMap = {},
}) => {
  const total = value.reduce((acc, item) => acc + item.count, 0);
  const minWidth = 5;
  const processedActions = processValues(value);

  if (actionsData?.isFetching) return <LoadingBlockHelper isLoading />;

  return (
    <div className="full-width">
      <div
        className={classnames(`action-bar-wrapper`, {
          'action-bar-wrapper--small': type === 'small',
          'action-bar-wrapper--lg': type === 'large',
        })}
      >
        {processedActions.map((item, idx) => {
          let backgroundColor;
          const isOther = item.name === 'Other';

          if (get(actionColorMap, item.name)) {
            backgroundColor = actionColorMap[item.name];
          } else {
            backgroundColor = isOther
              ? OTHER_HEX
              : PRE_DEFINED_BACKGROUND_COLORS[idx] || generateRandomHex();
          }

          const key = `${actionBarKey}-${item.name}-${idx}-${type}`;
          const percentage = (item.count / total) * 100;

          return (
            <Bar
              key={key}
              barKey={key}
              type={type}
              actionName={item.name}
              actionNames={isOther ? [item.name, ...item?.actionNames] : null}
              backgroundColor={backgroundColor}
              percentage={percentage}
              width={`${percentage < minWidth ? minWidth : percentage}%`}
              minWidth={`${minWidth}%`}
              userInfo={userInfo}
              searchData={searchData}
              className={barClassName}
            />
          );
        })}
      </div>
    </div>
  );
};

ActionBar.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  actionsData: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object),
  }),
  fetchActions: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userInfo: getUserInfo(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ActionBar);
