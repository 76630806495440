import React from 'react';
import ReactTooltip from 'react-tooltip';
import { pickBy, identity } from 'lodash';
import ReactDOMServer from 'react-dom/server';

import { camelizeKeys } from '../../../helpers/object';
import { renderReduxDropdownV2 as Dropdown } from '../reduxReactSelect';

const DataTipContent = (billingProvider) => {
  const tin = Array.isArray(billingProvider.taxId)
    ? billingProvider.taxId.join(' ,')
    : billingProvider.taxId;
  const npi = Array.isArray(billingProvider.npi)
    ? billingProvider.npi.join(' ,')
    : billingProvider.npi;
  const mappedBillingProvider = {
    TIN: tin,
    NPI: npi,
  };

  const billingProviderInfo = pickBy(mappedBillingProvider, identity);

  return (
    <div>
      {Object.entries(billingProviderInfo).map(([key, value], idx) => (
        <div key={idx}>
          <strong>{key}</strong>: {value}
        </div>
      ))}
    </div>
  );
};

const SelectOption = ({ label, data }) => {
  const dataTipContent = ReactDOMServer.renderToStaticMarkup(
    DataTipContent(camelizeKeys(data))
  );

  return (
    <div>
      <span data-tip={dataTipContent} data-html={true}>
        {label}
      </span>

      <ReactTooltip effect="solid" place="top" />
    </div>
  );
};

const BillingProviderDropdown = (props) => {
  return (
    <React.Fragment>
      <Dropdown
        reactSelectProps={{
          classNamePrefix: 'custom-tooltip__dropdown',
        }}
        {...props}
        formatOptionLabel={SelectOption}
        {...(props.selectedProvider && !props.isDisabled
          ? {
              dataTip: ReactDOMServer.renderToStaticMarkup(
                DataTipContent(camelizeKeys(props.selectedProvider.data))
              ),
            }
          : {})}
      />
    </React.Fragment>
  );
};

export default BillingProviderDropdown;
