import React from 'react';
import { connect } from 'react-redux';

import DashboardNavBar from './DashboardNavbar/DashboardNavbar';

import * as routes from '../../constants/routes';
import { getUserInfo } from '../../redux/reducers/loginStore';
import { INTEGRATION_TYPE } from '../../constants/appConstants';

import teamImg from '../../img/team.svg';

const withDashboardNavbar = (Component) => {
  class WithNav extends React.Component {
    render() {
      const defaultNavItems = [
        {
          to: routes.MANAGER_DASHBOARD_TEAM,
          title: 'Team',
          icon: teamImg,
        },
      ];

      /*
       * Remove overview dashboard until new analytics dashboard is ready.
       */
      /*
        const integrationApiAuthorizedNavItems = [
          {
            to: routes.MANAGER_DASHBOARD_OVERVIEW,
            title: "Overview",
            icon: gaugeImg,
          },
        ]

        const navItems = !this.props.isDenialsQueueEnabled
          ? defaultNavItems
          : [...integrationApiAuthorizedNavItems, ...defaultNavItems];
      */
      return (
        <React.Fragment>
          <DashboardNavBar navItems={defaultNavItems} />
          <Component {...this.props} />
        </React.Fragment>
      );
    }
  }

  return connect((state) => {
    const userInfo = getUserInfo(state);
    const integrationType = userInfo && userInfo.integrationType;

    return {
      isDenialsQueueEnabled:
        Object.values(INTEGRATION_TYPE).includes(integrationType),
    };
  }, {})(WithNav);
};

export default withDashboardNavbar;
