import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.css';

export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  PRIMARY_DARK: 'primary-dark',
  SECONDARY: 'secondary',
  SECONDARY_OUTLINE: 'secondary-outline',
  LIGHT: 'light',
  ACTION: 'action',
  TERTIARY: 'tertiary',
};

const Button = (props) => {
  const {
    title,
    onClick,
    icon,
    className,
    iconClassName,
    disabled = false,
    children,
    dataTip,
    type = BUTTON_TYPE.PRIMARY,
    datacy = 'button',
    buttonType,
  } = props;

  return (
    <button
      className={classnames(
        'ap-button',
        `ap-button--${type}`,
        { 'ap-button--disabled': disabled },
        className
      )}
      type={buttonType}
      onClick={onClick}
      disabled={disabled}
      data-tip={dataTip}
      datacy={datacy}
    >
      <div className="ap-button__title">
        {icon && (
          <img src={icon} className={classnames(iconClassName)} alt={title} />
        )}
        {title}
      </div>
      {children}
    </button>
  );
};

Button.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.any,
};

export default Button;
