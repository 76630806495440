import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.css';

import Stepper from 'components/common/stepper';

const Card = (props) => {
  const {
    title,
    className,
    description,
    children,
    stepper,
    step,
    datacy = 'card',
  } = props;
  return (
    <div className={classnames('appealio-card-v2', className)} datacy={datacy}>
      <div className="row d-flex align-items-center">
        <div className="col-md-6">
          {description && (
            <div className="appealio-card-v2__description">{description}</div>
          )}
          {title && <div className="appealio-card-v2__title">{title}</div>}
        </div>
        {stepper && (
          <div className="col-md-6">
            <Stepper step={step} />
          </div>
        )}
      </div>

      {children}
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  stepper: PropTypes.bool,
  step: PropTypes.number,
  className: PropTypes.string,
  description: PropTypes.string,
};

export default Card;
