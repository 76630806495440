import React, { useState } from 'react';

import './style.css';

const ProvidersList = (props) => {
  const { providers } = props;

  const [showMore, setShowMore] = useState(false);

  const DEFAULT_SHOW_COUNT = 10;
  const providersToRender = showMore
    ? providers
    : providers.slice(0, DEFAULT_SHOW_COUNT);

  return (
    <div className="provider-list" datacy="providers-list">
      {providersToRender.map((provider, idx) => (
        <div className="provider-list-item" key={idx}>
          {provider}
        </div>
      ))}

      {providers.length > DEFAULT_SHOW_COUNT && (
        <div
          className="provider-list-item provider-list-item--show-all cursor-pointer"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'Show less' : 'Show all'}
        </div>
      )}
    </div>
  );
};

ProvidersList.propTypes = {};

export default ProvidersList;
