import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form/immutable';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { validate, renderField } from './inputs';
import SubmitButton from '../Shared/SubmitButton';
import { sendForgotPasswordEmail } from '../../redux/actions/loginActions';
import LoadingBlockHelper from '../Shared/LoadingBlockHelper';
import Recaptcha from 'react-recaptcha';

class ForgotPasswordEnterEmailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVerified: false,
    };
  }

  static propTypes = {
    onEmailSent: PropTypes.func.isRequired,
  };

  reCaptchaLoaded() {
    //
  }

  verifyCallback = (res) => {
    if (res) {
      this.setState({
        isVerified: true,
      });
    }
  };

  handleCaptchaExpired = () => {
    this.setState({
      isVerified: false,
    });
  };

  render() {
    const siteKey = process.env.REACT_APP_RECAPTCHA_KEY;
    const { error, handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div>
        <div>
          <Field
            label="Email"
            showCheckmarkIfOk={false}
            type="email"
            name="email"
            datacy="forgot-password-email-Field"
            component={renderField}
          />
        </div>

        {error && (
          <div className="login__error-wrapper">
            {' '}
            <strong className="login__error">{error}</strong>{' '}
          </div>
        )}

        <div className="submit__button-wrapper">
          <LoadingBlockHelper isLoading={submitting}>
            {this.state.isVerified && (
              <SubmitButton
                onClick={handleSubmit(this.submit)}
                className="mb-16 submit__button submit__button--fullwidth"
                title="Send Instructions"
                disabled={submitting || pristine || invalid}
              />
            )}

            <Recaptcha
              sitekey={siteKey}
              render="explicit"
              verifyCallback={this.verifyCallback}
              onloadCallback={this.reCaptchaLoaded}
              expiredCallback={this.handleCaptchaExpired}
              expiredCallbackName="handleCaptchaExpired"
            />
          </LoadingBlockHelper>
        </div>
      </div>
    );
  }

  submit = (values) => {
    return this.props.actions
      .sendForgotPasswordEmail(
        values.get('email'),
        this.props.isRequestCreatePasswordPath || false
      )
      .then((res) => {
        this.props.onEmailSent();
      })
      .catch((e) => {
        throw new SubmissionError({ _error: 'Email not sent.' });
      });
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ sendForgotPasswordEmail }, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: 'forgot_password_enter_email',
    validate,
  })(ForgotPasswordEnterEmailForm)
);
