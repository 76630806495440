import { ALL_VALUE } from 'constants/appConstants';

/**
 * Extracts and combines the practice identifiers associated with a user, including those from practice groups.
 * It manages scenarios where all practices or all practice groups are selected.
 *
 * @param {Object} user - The user whose practice identifiers are to be extracted.
 * @param {Array} selectedPracticeGroupsData - List of practice group data.
 * @param {Array} selectedPracticeIdentifiers - Specific practice identifiers to consider.
 * @returns {Array} - Combined list of practice identifiers associated with the user, excluding special ALL_VALUE.
 */
export const getUserPracticeIdentifiers = (
  user,
  selectedPracticeGroupsData,
  selectedPracticeIdentifiers
) => {
  const { practice: usersPracticeIdentifiers, pgroup: usersPracticeGroupIds } =
    user.allowedItems;
  const isAllPracticeSelected = usersPracticeIdentifiers === ALL_VALUE;
  const isAllPracticeGroupSelected =
    usersPracticeGroupIds === ALL_VALUE || !('pgroup' in user.allowedItems);

  const practicesFromGroups = selectedPracticeGroupsData.flatMap((pg) =>
    usersPracticeGroupIds.includes(pg.id) ? pg.practices : []
  );
  const practicesMatchingUser = [
    ...selectedPracticeGroupsData.flatMap((pg) => pg.practices),
    ...selectedPracticeIdentifiers,
  ].filter((id) => usersPracticeIdentifiers.includes(id));

  if (isAllPracticeGroupSelected && isAllPracticeSelected) {
    return getUniquePractices(
      selectedPracticeGroupsData,
      selectedPracticeIdentifiers
    );
  }

  const userPracticeToFilter = [
    ...(isAllPracticeSelected ? [] : practicesMatchingUser),
    ...(isAllPracticeGroupSelected ? [] : practicesFromGroups),
  ];

  return getUniquePractices(
    selectedPracticeGroupsData,
    selectedPracticeIdentifiers
  ).filter((p) => userPracticeToFilter.includes(p));
};

const getUniquePractices = (
  selectedPracticeGroupsData,
  selectedPracticeIdentifiers
) => {
  return Array.from(
    new Set([
      ...selectedPracticeGroupsData.flatMap((pg) => pg.practices),
      ...selectedPracticeIdentifiers,
    ])
  ).filter((p) => p !== ALL_VALUE);
};
