import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { replace } from 'connected-react-router';

import ActionPopup, { TYPE_ACCEPT } from 'components/Shared/ActionPopup';

import { createDeleteRequest, handleErrors } from 'API/Config';
import {
  downloadPreviewPackage,
  createSubmittedPackageLink,
} from 'API/DeliveryLogsAPI';

import {
  setStandaloneSubmissionId,
  setSubmitConfirmPrefillData,
} from 'redux/actions/createSubmitPackageActions';

import * as endpoints from 'constants/endpoints';
import { SUBMISSION_MENU_OPTION } from './constants';

import checkIcon from 'img/appeal_check.svg';

import * as routes from 'constants/routes';
import * as toast from 'components/Shared/toast';

const CancelPopup = (props) => {
  const { cancelPopup, setCancelPopup, fetchDeliveryLogs } = props;
  const [isDownloading, setIsDownloading] = useState(false);

  const handlePackageDownload = async (fileUrl) => {
    setIsDownloading(true);
    const url = createSubmittedPackageLink(fileUrl.replace('/api/', ''));
    if (url) {
      try {
        const packageFile = await downloadPreviewPackage(url);
        saveAs(packageFile, cancelPopup.submissionId);
        setIsDownloading(false);
      } catch (error) {
        toast.error({
          title: '',
          message: 'Package is not available at the moment',
        });
        setIsDownloading(false);
      }
    }
  };

  const popupTitle = `Are you sure you want to ${cancelPopup.type} your submission ?`;
  const popupSubtitle =
    cancelPopup.type === SUBMISSION_MENU_OPTION.CANCEL &&
    cancelPopup.submissionType === 'Standalone' ? (
      <div>
        {' '}
        Your package will be deleted. You can download your full package from{' '}
        <span
          onClick={() => handlePackageDownload(cancelPopup.fileUrl)}
          className="link-url "
        >
          here.
        </span>
      </div>
    ) : (
      ''
    );

  const handleClosePopup = () =>
    setCancelPopup({
      isOpen: false,
      type: null,
      submissionId: null,
    });

  const handelActionButtonClick = async (index) => {
    if (index === 1) {
      try {
        props.actions.setSubmitConfirmPrefillData(
          cancelPopup?.submissionDetails || {}
        );
        const request = createDeleteRequest(
          `${endpoints.DELIVERY_TRACKING}${cancelPopup.submissionId}`,
          {
            reset: cancelPopup.type === SUBMISSION_MENU_OPTION.CANCEL ? 0 : 1,
          }
        );
        await fetch(request).then(handleErrors);
        handleClosePopup();
        toast.success({
          title: 'Success',
          message: 'Your submission has been successfully canceled.',
        });
        if (cancelPopup.type === SUBMISSION_MENU_OPTION.CANCEL) {
          fetchDeliveryLogs();
        }
        if (cancelPopup.type === SUBMISSION_MENU_OPTION.EDIT) {
          if (cancelPopup.submissionType !== 'Standalone') {
            props.actions.replace(
              `${routes.CREATE_APPEAL_SUBMIT_CONFIRM}?appealId=${cancelPopup.appealId}`
            );
          } else {
            props.actions.setStandaloneSubmissionId(cancelPopup.submissionId);
            props.actions.replace(
              `${routes.CREATE_APPEAL_SUBMIT_CONFIRM}?submissionId=${cancelPopup.submissionId}`
            );
          }
        }
      } catch (error) {
        toast.error({
          title: '',
          message: 'Oops! Something went wrong. Please try again later.',
        });
        props.actions.setSubmitConfirmPrefillData({});
        handleClosePopup();
      }
    } else {
      handleClosePopup();
    }
  };

  return (
    <ActionPopup
      title={popupTitle}
      subtitle={popupSubtitle}
      actionButtons={[
        {
          title: 'No',
        },
        {
          title: 'Yes',
          type: TYPE_ACCEPT,
        },
      ]}
      isLoading={isDownloading}
      icon={checkIcon}
      onClosePressed={handleClosePopup}
      onActionButtonPressed={handelActionButtonClick}
    />
  );
};

CancelPopup.propTypes = {
  submissionId: PropTypes.number,
  cancelPopup: PropTypes.object,
  setCancelPopup: PropTypes.func,
  fetchDeliveryLogs: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { replace, setStandaloneSubmissionId, setSubmitConfirmPrefillData },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelPopup);
