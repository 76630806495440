import { pickBy, identity } from 'lodash';
import * as endpoints from '../constants/endpoints';
import { camelizeKeys, snakeCaseKeys } from '../helpers/object';

import {
  handleErrors,
  handleJSONParse,
  createGetRequest,
  ANALYTICS_API_BASE_URL,
} from './Config';

export const fetchAnalyticsPayers = async (queryParams) => {
  const nonEmptyParams = pickBy(queryParams, identity);
  const request = createGetRequest(
    endpoints.ANALYTICS_PAYER,
    {
      ...snakeCaseKeys(nonEmptyParams),
    },
    ANALYTICS_API_BASE_URL,
    true
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => camelizeKeys(res.data));
};

export const fetchAnalyticsPractices = async (queryParams) => {
  const nonEmptyParams = pickBy(queryParams, identity);
  const request = createGetRequest(
    endpoints.ANALYTICS_PRACTICE,
    {
      ...snakeCaseKeys(nonEmptyParams),
    },
    ANALYTICS_API_BASE_URL,
    true
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => camelizeKeys(res.data));
};

export const fetchClaimsPerformanceOverview = async (filters) => {
  const nonEmptyFilters = pickBy(filters, identity);
  const url = endpoints.CLAIM_PERFORMANCE;
  const request = createGetRequest(
    url,
    {
      ...nonEmptyFilters,
    },
    ANALYTICS_API_BASE_URL,
    true
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => camelizeKeys(res.data));
};

export const fetchTopPayers = async (filters) => {
  const nonEmptyFilters = pickBy(filters, identity);
  const url = endpoints.TOP_PAYERS;
  const request = createGetRequest(
    url,
    {
      ...nonEmptyFilters,
    },
    ANALYTICS_API_BASE_URL,
    true
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => camelizeKeys(res.data));
};

export const fetchAverageAllowedAmountsByProcedureCodes = async (filters) => {
  const nonEmptyFilters = pickBy(filters, identity);
  const url = endpoints.AVERAGE_AMOUNTS_PROCEDURE_CODES;
  const request = createGetRequest(
    url,
    {
      ...snakeCaseKeys(nonEmptyFilters),
    },
    ANALYTICS_API_BASE_URL,
    true
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => camelizeKeys(res.data));
};

export const fetchDenialsReasonsByCategory = async (filters) => {
  const nonEmptyFilters = pickBy(filters, identity);
  const url = endpoints.DENAILS_REASONS_BY_CATEGORY;
  const request = createGetRequest(
    url,
    {
      ...snakeCaseKeys(nonEmptyFilters),
    },
    ANALYTICS_API_BASE_URL,
    true
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => camelizeKeys(res.data));
};

export const fetchAnalyticsClaimsInformation = async (queryParams) => {
  const nonEmptyParams = pickBy(queryParams, identity);
  const request = createGetRequest(
    endpoints.ANALYTICS_DATA_COUNTS,
    {
      ...snakeCaseKeys(nonEmptyParams),
    },
    ANALYTICS_API_BASE_URL,
    true
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => camelizeKeys(res.data));
};
