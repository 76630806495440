import React from 'react';
import PropTypes from 'prop-types';

import calendar from 'img/calendar-icon.svg';

import { DatePicker } from 'components/common/datepicker';

const DateRange = (props) => {
  const {
    title,
    dateDisplayFormat = 'MMM D, YYYY',
    startDate,
    startDatePlaceholder = 'From',
    startDateRangeValidation,
    startDateOnChange,
    endDateRangeValidation,
    endDate,
    endDatePlaceholder = 'To',
    endDateOnChange,
  } = props;
  return (
    <div className="dropdown-container">
      <div className="dropdown__label">{title}</div>
      <div className="row mb-12">
        <div className="col-md-6">
          <DatePicker
            displayFormat={dateDisplayFormat}
            customInputIcon={
              <img className="form-icon" src={calendar} alt="Calendar" />
            }
            placeholder={startDatePlaceholder}
            verticalSpacing={10}
            date={startDate}
            isOutsideRange={startDateRangeValidation}
            onDateChange={startDateOnChange}
            numberOfMonths={1}
            orientation="horizontal"
            block={true}
          />
        </div>
        <div className="col-md-6">
          <DatePicker
            displayFormat={dateDisplayFormat}
            customInputIcon={
              <img className="form-icon" src={calendar} alt="Calendar" />
            }
            isOutsideRange={endDateRangeValidation}
            date={endDate}
            placeholder={endDatePlaceholder}
            verticalSpacing={10}
            onDateChange={endDateOnChange}
            numberOfMonths={1}
            orientation="horizontal"
            block={true}
          />
        </div>
      </div>
    </div>
  );
};

DateRange.propTypes = {
  title: PropTypes.string,
  dateDisplayFormat: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  startDatePlaceholder: PropTypes.string,
  startDateRangeValidation: PropTypes.func,
  startDateOnChange: PropTypes.func.isRequired,
  endDateRangeValidation: PropTypes.func,
  endDate: PropTypes.instanceOf(Date),
  endDateOnChange: PropTypes.func.isRequired,
  endDatePlaceholder: PropTypes.string,
};

export default DateRange;
