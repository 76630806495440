import { useState, useEffect, useCallback } from 'react';

import { ALLOW_RULE_PAGINATION } from 'constants/appConstants';

import DenialsAPI from 'API/DenialsAPI';
import { fetchProposedActions } from 'API/SopAPI';
import { fetchImportRules } from 'API/ImportRules';

import { handleError } from 'helpers/errorHandler';

/**
 * Use import rules hook.
 * @param {Object} filters
 * @returns {Promise}
 */
export const useImportRules = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [importRules, setImportRules] = useState([]);

  useEffect(() => {
    setLoading(true);

    const fetch = async () => {
      const queryParams = {
        all: ALLOW_RULE_PAGINATION,
      };

      try {
        const data = await fetchImportRules(queryParams);
        setImportRules(data);
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    };

    fetch();
  }, []);

  return { loading, error, importRules, setImportRules };
};

export const useFetchCodeOptionsCount = () => {
  const [data, setData] = useState({
    reasonCodeCounts: [],
    remarkCodeCounts: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        setLoading(true);
        const response = await DenialsAPI.fetchDenialsCounts({
          only_codes_count: 1,
        });
        setData({
          reasonCodeCounts: response.reasonCodeCounts,
          remarkCodeCounts: response.remarkCodeCounts,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleError(error);
        setError(error);
      }
    };

    fetchCounts();
  }, []);

  return {
    data: {
      reasonCodeCounts: data.reasonCodeCounts,
      remarkCodeCounts: data.remarkCodeCounts,
    },
    error,
    loading,
  };
};

/**
 * Custom hook to fetch proposed actions
 *
 * @return {Object} An object containing the following properties:
 *   - error: The error object if an error occurred during the fetch.
 *   - isLoading: A boolean indicating whether the fetch is in progress.
 *   - proposedActions: An array of proposed actions fetched from the server.
 */
export const useFetchProposedActions = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [proposedActions, setProposedActions] = useState([]);

  const extractOptions = ({ id, description }) => ({
    value: id,
    label: description,
  });

  const fetch = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchProposedActions();

      setProposedActions(data.map(extractOptions));
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);
  return {
    error,
    isLoading,
    proposedActions,
    fetch,
  };
};
