import moment from 'moment';
import PropTypes from 'prop-types';
import { omit, isEqual } from 'lodash';
import React, { useState } from 'react';
import { isInclusivelyAfterDay } from 'react-dates';

import Button from 'components/common/button';
import Dropdown from 'components/Shared/Dropdown';
import ApDateRange from 'components/common/dateRange';

import ToggleSwitch from 'components/common/toggle';
import { BUTTON_TYPE } from 'components/common/button/Button';
import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';

import closeIcon from 'img/close-modal.svg';
import settingsIcon from 'img/settings-black.svg';

import { SUCCESSFUL_DENIED_OPTIONS } from 'constants/appConstants';

const DeliveryTrackerPopupFilter = (props) => {
  const {
    setShowPopupFilter,
    filters,
    onFilterChange,
    options,
    defaultFilters,
    getSelectedValue,
    userPractices,
    loading,
    isAppealioExpressUser,
  } = props;
  const [deliveryTrackerFilters, setDeliveryTrackerFilters] = useState(filters);

  const keysToOmit = ['search', 'status', 'method', 'types'];
  const isFilterEmpty = Object.keys(
    omit(deliveryTrackerFilters, keysToOmit)
  ).every((filterKey) => filters[filterKey] === defaultFilters[filterKey]);

  const getFiltersWithoutOmittedKeys = (filters) => omit(filters, keysToOmit);
  const dtFiltersWithoutOmittedKeys = getFiltersWithoutOmittedKeys(filters);
  const dtPopupFiltersWithoutOmittedKeys = getFiltersWithoutOmittedKeys(
    deliveryTrackerFilters
  );
  const isApplyFilterDisabled = isEqual(
    Object.values(dtFiltersWithoutOmittedKeys),
    Object.values(dtPopupFiltersWithoutOmittedKeys)
  );

  const resetFilters = () => onFilterChange(omit(defaultFilters, keysToOmit));

  const handleConfirmFilters = () => {
    onFilterChange(deliveryTrackerFilters);
    setShowPopupFilter(false);
  };

  const handleFilterChange = (key, value) =>
    setDeliveryTrackerFilters({ ...deliveryTrackerFilters, [key]: value });

  return (
    <ScreenDimmerComponent>
      <div className="appealio-popup appealio-popup-filter">
        <div className="appealio-popup-filter__header">
          <div className="filter__header-title">
            <img src={settingsIcon} alt="More filters" />
            <h6>More Filters </h6>
          </div>
          <div className="appealio-popup-filter__clear-wrapper">
            <div className="filter__header--reset-option">
              {!isFilterEmpty && (
                <p
                  className="denial-table__filter-clear text-decoration-underline"
                  onClick={() => {
                    setDeliveryTrackerFilters(defaultFilters);
                    resetFilters();
                    setShowPopupFilter(false);
                  }}
                >
                  Reset All
                </p>
              )}
            </div>
            <img
              alt="Close"
              onClick={() => setShowPopupFilter(false)}
              className="close-icon"
              src={closeIcon}
            />
          </div>
        </div>
        <div className="appealio-popup-filter__options">
          <ApDateRange
            title="Submitted On"
            startDate={
              deliveryTrackerFilters.from_date
                ? moment(deliveryTrackerFilters.from_date)
                : null
            }
            startDateRangeValidation={(d) =>
              isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
              d.isAfter(moment(deliveryTrackerFilters.to_date), 'day')
            }
            startDateOnChange={(date) => {
              date = moment(date);
              if (!date.isValid()) {
                handleFilterChange('from_date', null);
                return;
              }
              handleFilterChange('from_date', date.format('YYYY-MM-DD'));
            }}
            endDate={
              deliveryTrackerFilters.to_date
                ? moment(deliveryTrackerFilters.to_date)
                : null
            }
            endDateRangeValidation={(d) =>
              isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
              d.isBefore(moment(deliveryTrackerFilters.from_date), 'day')
            }
            endDateOnChange={(date) => {
              date = moment(date);
              if (!date.isValid()) {
                handleFilterChange('to_date', null);
                return;
              }
              handleFilterChange('to_date', date.format('YYYY-MM-DD'));
            }}
          />
        </div>
        {!isAppealioExpressUser && (
          <>
            <div className="row mb-12">
              <div className="col-md-6">
                <Dropdown
                  options={options.payers}
                  placeholder="All"
                  onChange={(option) =>
                    handleFilterChange('payer_id', option.value)
                  }
                  isLoading={loading}
                  value={getSelectedValue(
                    options.payers,
                    Number(deliveryTrackerFilters.payer_id)
                  )}
                  label="Payer"
                />
              </div>
              <div className="col-md-6">
                <Dropdown
                  options={options.providers}
                  placeholder="All"
                  onChange={(option) =>
                    handleFilterChange('provider_id', option.value)
                  }
                  isLoading={loading}
                  label="Provider"
                  value={getSelectedValue(
                    options.providers,
                    deliveryTrackerFilters.provider_id
                  )}
                />
              </div>
            </div>
            <div className="row mb-12">
              <div className="col-md-6">
                <Dropdown
                  options={options.agents}
                  placeholder="All"
                  onChange={(option) =>
                    handleFilterChange('agent_id', option.value)
                  }
                  isLoading={loading}
                  label="Agent"
                  value={getSelectedValue(
                    options.agents,
                    deliveryTrackerFilters.agent_id
                  )}
                />
              </div>
              <div className="col-md-6 mb-12">
                <Dropdown
                  options={SUCCESSFUL_DENIED_OPTIONS}
                  placeholder="All"
                  onChange={(option) =>
                    handleFilterChange('record_response_status', option.value)
                  }
                  isLoading={loading}
                  label="Successful/Denied"
                  value={getSelectedValue(
                    SUCCESSFUL_DENIED_OPTIONS,
                    deliveryTrackerFilters.record_response_status
                  )}
                />
              </div>
              <div className="col-md-6">
                {userPractices && userPractices.length > 1 && (
                  <Dropdown
                    options={options.practices}
                    placeholder="All"
                    onChange={(option) =>
                      handleFilterChange('practice_id', option.value)
                    }
                    isLoading={loading}
                    label="Practice"
                    value={getSelectedValue(
                      options.practices,
                      Number(deliveryTrackerFilters.practice_id)
                    )}
                  />
                )}
              </div>
              <div className="col-md-6">
                <ToggleSwitch
                  className="mt-28"
                  optionClassName="fs-12"
                  options={{
                    option1: 'All',
                    option2: 'Under Review (More than 3 days)',
                  }}
                  checked={
                    deliveryTrackerFilters.notification_redirect || false
                  }
                  handleToggle={(e) =>
                    handleFilterChange(
                      'notification_redirect',
                      e.target.checked
                    )
                  }
                />
              </div>
            </div>
          </>
        )}
        <div className="appealio-popup-filter-buttons mt-24">
          <Button
            title="Apply"
            type={BUTTON_TYPE.SECONDARY}
            className="ap-button ap-button--block"
            onClick={handleConfirmFilters}
            datacy="dataCy-confirm-filters"
            disabled={isApplyFilterDisabled}
          />
        </div>
      </div>
    </ScreenDimmerComponent>
  );
};

DeliveryTrackerPopupFilter.propTypes = {
  setShowPopupFilter: PropTypes.func.isRequired,
  filters: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
  options: PropTypes.object,
  getSelectedValue: PropTypes.func,
};

export default DeliveryTrackerPopupFilter;
