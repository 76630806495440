import moment from 'moment';
import { omit, isEqual } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isInclusivelyAfterDay } from 'react-dates';

import Button from 'components/common/button';
import Dropdown from 'components/Shared/Dropdown';
import ToggleSwitch from 'components/common/toggle';
import { DatePicker } from 'components/common/datepicker';
import { BUTTON_TYPE } from 'components/common/button/Button';
import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';

import closeIcon from 'img/close-modal.svg';
import calendar from 'img/calendar-icon.svg';
import settingsIcon from 'img/settings-black.svg';

import { ZERO_PAY_DENIALS_OPTIONS } from 'constants/options';
import {
  UNASSIGNED_OPTION,
  ALL_AGENTS_OPTION_VALUE,
} from 'constants/appConstants';

const DenialPopupFilter = (props) => {
  const {
    setShowDenialFilters,
    filters,
    onFilterChange,
    filterOptions,
    defaultFilters,
    isArchivedPage,
  } = props;
  const [error, setError] = useState(null);
  const [denialFilters, setDenialFilters] = useState(filters);

  const keysToOmit = ['applied_rule', 'search'];
  const isFilterEmpty = Object.keys(omit(denialFilters, keysToOmit)).every(
    (filterKey) => filters[filterKey] === defaultFilters[filterKey]
  );

  const filtersWithoutOmittedKeys = (filters) => omit(filters, keysToOmit);
  const dqFiltersWithoutOmittedKeys = filtersWithoutOmittedKeys(filters);
  const dqPopupFiltersWithoutOmittedKeys =
    filtersWithoutOmittedKeys(denialFilters);
  const isApplyFilterDisabled = isEqual(
    Object.values(dqFiltersWithoutOmittedKeys),
    Object.values(dqPopupFiltersWithoutOmittedKeys)
  );

  const agentSelectOptions = filterOptions?.agents?.data;
  const payerSelectOptions = filterOptions?.payers?.data;
  const reasonCodeSelectOptions = filterOptions?.reasonCodes?.data;
  const cptCodeSelectOptions = filterOptions?.cptCodes?.data;
  const procedureModifiersSelectOptions =
    filterOptions?.procedureModifiers?.data;
  const remarkCodeOptions = filterOptions?.remarkCodes?.data;
  const practicesOptions = filterOptions?.practices?.data;
  const providerOptions = filterOptions?.providers?.data;

  const handleFilterChange = ({ value }, name) => {
    setDenialFilters({
      ...denialFilters,
      [name]: value,
    });
  };

  const handleToggleZeroPay = (e) => {
    setDenialFilters({ ...denialFilters, zero_pay: e.target.checked });
  };

  const handleAgentChange = ({ value }) => {
    let agentValue;

    if (!value || value === ALL_AGENTS_OPTION_VALUE) {
      agentValue = [ALL_AGENTS_OPTION_VALUE];
    } else {
      agentValue = [value];
    }

    return handleFilterChange({ value: agentValue }, 'agents');
  };

  const selectedPayerOption =
    payerSelectOptions.find((option) => denialFilters.payer === option.value) ||
    false;

  const selectedPracticeId =
    practicesOptions.find(
      ({ value }) => value === denialFilters.practice_identifier
    ) || false;

  const selectedReasonCodeOption =
    reasonCodeSelectOptions.find(
      (option) => denialFilters.reason_code === option.value
    ) || false;

  const selectedRemarkCodeOption =
    remarkCodeOptions.find(
      (option) => denialFilters.remark_codes === option.value
    ) || false;

  const selectedCptCodeOption =
    cptCodeSelectOptions.find(
      (option) => denialFilters.cpt_code === option.value
    ) || false;

  const selectedCptModifierCodeOption =
    procedureModifiersSelectOptions.find(
      (option) => denialFilters.cpt_modifiers_code === option.value
    ) || false;

  const [allOption, ...options] = providerOptions;
  const providerOptionsWithUnassigned = providerOptions.length
    ? [allOption, UNASSIGNED_OPTION, ...options]
    : providerOptions;
  const selectedProviderOption =
    providerOptionsWithUnassigned.find(
      (option) => denialFilters.provider === option.value
    ) || false;
  /**
   * Info: Hide practice filter if there is only one practice as option.
   * filterOptions.practices = [{label: 'Any'}, ...practice];
   */
  const isPracticeFilterVisible =
    filterOptions.practices && filterOptions.practices?.data?.length > 2;

  const [allOptions, ...agentOptions] = agentSelectOptions;
  const agentOptionsWithUnassignedOption = [
    allOptions,
    UNASSIGNED_OPTION,
    ...agentOptions,
  ];
  const selectedAgentOption =
    agentOptionsWithUnassignedOption.find(
      (option) =>
        denialFilters.agents && denialFilters.agents[0] === option.value
    ) || false;

  const validateDateFields = (
    fromDate,
    toDate,
    fromDateErrorKey,
    toDateErrorKey
  ) => {
    if (!fromDate && !toDate) {
      return true;
    }
    if (!fromDate && toDate) {
      setError({
        [fromDateErrorKey]: 'Please choose the start date.',
      });
      return false;
    } else if (fromDate && !toDate) {
      setError({
        [toDateErrorKey]: 'Please choose the end date.',
      });
      return false;
    }
    return true;
  };

  const handleConfirmFilters = () => {
    const isValidServiceDateFields = validateDateFields(
      denialFilters.service_date_from,
      denialFilters.service_date_to,
      'fromDate',
      'toDate'
    );

    const isValidAddedDateFields = !isArchivedPage
      ? validateDateFields(
          denialFilters.date_added_from,
          denialFilters.date_added_to,
          'dateAddedFromError',
          'dateAddedToError'
        )
      : validateDateFields(
          denialFilters.date_archived_from,
          denialFilters.date_archived_to,
          'dateArchivedFromError',
          'dateArchivedToError'
        );

    if (isValidServiceDateFields && isValidAddedDateFields) {
      onFilterChange(denialFilters);
      setShowDenialFilters(false);
    }
  };

  const resetFilters = () =>
    onFilterChange(omit(defaultFilters, ['applied_rule', 'search']));

  return (
    <ScreenDimmerComponent>
      <div className="appealio-popup appealio-popup-filter">
        <div className="appealio-popup-filter__header">
          <div className="filter__header-title">
            <img src={settingsIcon} alt={'More filters'} />
            <h6>More Denial Queue Filters </h6>
          </div>
          <div className="appealio-popup-filter__clear-wrapper">
            <div className="filter__header--reset-option">
              {!isFilterEmpty && (
                <p
                  className="denial-table__filter-clear text-decoration-underline"
                  onClick={() => {
                    setDenialFilters(defaultFilters);
                    resetFilters();
                    setShowDenialFilters(false);
                  }}
                >
                  Reset All
                </p>
              )}
            </div>
            <img
              alt="Close"
              onClick={() => setShowDenialFilters(false)}
              className="close-icon"
              src={closeIcon}
            />
          </div>
        </div>
        <div className="appealio-popup-filter__options">
          <div className="row mb-12">
            <div className="col-md-12">
              <div className="dropdown-container">
                <div className="dropdown__label">
                  {!isArchivedPage ? 'Date Added' : 'Date Archived'}
                </div>
                {!isArchivedPage ? (
                  <div className="row">
                    <div className="col-md-6">
                      <DatePicker
                        displayFormat="LL"
                        customInputIcon={
                          <img
                            className="form-icon"
                            src={calendar}
                            alt="Calendar"
                          />
                        }
                        placeholder="From"
                        verticalSpacing={10}
                        date={
                          denialFilters.date_added_from
                            ? moment(denialFilters.date_added_from)
                            : null
                        }
                        isOutsideRange={(d) =>
                          isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                          d.isAfter(moment(denialFilters.date_added_to), 'day')
                        }
                        onDateChange={(date) => {
                          if (!date) {
                            setError({
                              ...error,
                              dateAddedToError: null,
                            });
                          }
                          date = moment(date);
                          if (!date.isValid()) {
                            handleFilterChange(
                              { value: null },
                              'date_added_from'
                            );
                            return;
                          }
                          setError({
                            ...error,
                            dateAddedFromError: null,
                          });
                          handleFilterChange(
                            { value: date.format('YYYY-MM-DD') },
                            'date_added_from'
                          );
                        }}
                        numberOfMonths={1}
                        orientation="horizontal"
                        block={true}
                      />
                      {error && error.dateAddedFromError && (
                        <p className="error mt-8">{error.dateAddedFromError}</p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <DatePicker
                        displayFormat="LL"
                        customInputIcon={
                          <img
                            className="form-icon"
                            src={calendar}
                            alt="Calendar"
                          />
                        }
                        isOutsideRange={(d) =>
                          isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                          d.isBefore(
                            moment(denialFilters.date_added_from),
                            'day'
                          )
                        }
                        date={
                          denialFilters.date_added_to
                            ? moment(denialFilters.date_added_to)
                            : null
                        }
                        placeholder="To"
                        verticalSpacing={10}
                        onDateChange={(date) => {
                          if (!date) {
                            setError({
                              ...error,
                              dateAddedFromError: null,
                            });
                          }
                          date = moment(date);
                          if (!date.isValid()) {
                            handleFilterChange(
                              { value: null },
                              'date_added_to'
                            );
                            return;
                          }
                          setError({
                            ...error,
                            dateAddedToError: null,
                          });
                          handleFilterChange(
                            { value: date.format('YYYY-MM-DD') },
                            'date_added_to'
                          );
                        }}
                        numberOfMonths={1}
                        orientation="horizontal"
                        block={true}
                      />
                      {error && error.dateAddedToError && (
                        <p className="error mt-8">{error.dateAddedToError}</p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-6">
                      <DatePicker
                        displayFormat="LL"
                        customInputIcon={
                          <img
                            className="form-icon"
                            src={calendar}
                            alt="Calendar"
                          />
                        }
                        placeholder="From"
                        verticalSpacing={10}
                        date={
                          denialFilters.date_archived_from
                            ? moment(denialFilters.date_archived_from)
                            : null
                        }
                        isOutsideRange={(d) =>
                          isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                          d.isAfter(
                            moment(denialFilters.date_archived_to),
                            'day'
                          )
                        }
                        onDateChange={(date) => {
                          if (!date) {
                            setError({
                              ...error,
                              dateArchivedToError: null,
                            });
                          }
                          date = moment(date);
                          if (!date.isValid()) {
                            handleFilterChange(
                              { value: null },
                              'date_archived_from'
                            );
                            return;
                          }
                          setError({
                            ...error,
                            dateArchivedFromError: null,
                          });
                          handleFilterChange(
                            { value: date.format('YYYY-MM-DD') },
                            'date_archived_from'
                          );
                        }}
                        numberOfMonths={1}
                        orientation="horizontal"
                        block={true}
                      />
                      {error && error.dateArchivedFromError && (
                        <p className="error mt-8">
                          {error.dateArchivedFromError}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <DatePicker
                        displayFormat="LL"
                        customInputIcon={
                          <img
                            className="form-icon"
                            src={calendar}
                            alt="Calendar"
                          />
                        }
                        isOutsideRange={(d) =>
                          isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                          d.isBefore(
                            moment(denialFilters.date_archived_from),
                            'day'
                          )
                        }
                        date={
                          denialFilters.date_archived_to
                            ? moment(denialFilters.date_archived_to)
                            : null
                        }
                        placeholder="To"
                        verticalSpacing={10}
                        onDateChange={(date) => {
                          if (!date) {
                            setError({
                              ...error,
                              dateArchivedFromError: null,
                            });
                          }
                          date = moment(date);
                          if (!date.isValid()) {
                            handleFilterChange(
                              { value: null },
                              'date_archived_to'
                            );
                            return;
                          }
                          setError({
                            ...error,
                            dateArchivedToError: null,
                          });
                          handleFilterChange(
                            { value: date.format('YYYY-MM-DD') },
                            'date_archived_to'
                          );
                        }}
                        numberOfMonths={1}
                        orientation="horizontal"
                        block={true}
                      />
                      {error && error.dateArchivedToError && (
                        <p className="error mt-8">
                          {error.dateArchivedToError}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="dropdown-container">
                <div className="dropdown__label">Service Date</div>
              </div>
            </div>
          </div>
          <div className="row mb-12">
            <div className="col-md-6">
              <DatePicker
                displayFormat="LL"
                customInputIcon={
                  <img className="form-icon" src={calendar} alt="Calendar" />
                }
                placeholder="From"
                verticalSpacing={10}
                date={
                  denialFilters.service_date_from
                    ? moment(denialFilters.service_date_from)
                    : null
                }
                isOutsideRange={(d) =>
                  isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                  d.isAfter(moment(denialFilters.service_date_to), 'day')
                }
                onDateChange={(date) => {
                  if (!date) {
                    setError({
                      ...error,
                      toDate: null,
                    });
                  }
                  date = moment(date);
                  if (!date.isValid()) {
                    handleFilterChange({ value: null }, 'service_date_from');
                    return;
                  }
                  setError({
                    ...error,
                    fromDate: null,
                  });
                  handleFilterChange(
                    { value: date.format('YYYY-MM-DD') },
                    'service_date_from'
                  );
                }}
                numberOfMonths={1}
                orientation="horizontal"
                block={true}
              />
              {error && error.fromDate && (
                <p className="error mt-8">{error.fromDate}</p>
              )}
            </div>
            <div className="col-md-6">
              <DatePicker
                displayFormat="LL"
                customInputIcon={
                  <img className="form-icon" src={calendar} alt="Calendar" />
                }
                isOutsideRange={(d) =>
                  isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                  d.isBefore(moment(denialFilters.service_date_from), 'day')
                }
                date={
                  denialFilters.service_date_to
                    ? moment(denialFilters.service_date_to)
                    : null
                }
                placeholder="To"
                verticalSpacing={10}
                onDateChange={(date) => {
                  if (!date) {
                    setError({
                      ...error,
                      fromDate: null,
                    });
                  }
                  date = moment(date);
                  if (!date.isValid()) {
                    handleFilterChange({ value: null }, 'service_date_to');
                    return;
                  }
                  setError({
                    ...error,
                    toDate: null,
                  });
                  handleFilterChange(
                    { value: date.format('YYYY-MM-DD') },
                    'service_date_to'
                  );
                }}
                numberOfMonths={1}
                orientation="horizontal"
                block={true}
              />
              {error && error.toDate && (
                <p className="error mt-8">{error.toDate}</p>
              )}
            </div>
          </div>
          <div className="row mb-12">
            <div className="col-md-6">
              <Dropdown
                label="Payer"
                name="payer"
                placeholder="All"
                onChange={(option) => handleFilterChange(option, 'payer')}
                value={selectedPayerOption}
                isClearable={false}
                options={payerSelectOptions}
                isLoading={filterOptions?.payers?.isFetching}
                datacy="payer-Dropdown"
              />
            </div>
            <div className="col-md-6">
              <Dropdown
                label="Provider"
                name="provider"
                placeholder="All"
                onChange={(option) => handleFilterChange(option, 'provider')}
                value={selectedProviderOption}
                isClearable={false}
                options={providerOptionsWithUnassigned}
                isLoading={filterOptions?.providers?.isFetching}
                datacy="providers-Dropdown"
              />
            </div>
          </div>
          <div className="row mb-12">
            <div className="col-md-6">
              <Dropdown
                label="Procedure"
                name="cptCode"
                placeholder="All"
                onChange={(option) => handleFilterChange(option, 'cpt_code')}
                value={selectedCptCodeOption}
                isClearable={false}
                options={cptCodeSelectOptions}
                isLoading={filterOptions?.cptCodes?.isFetching}
                datacy="procedure-Dropdown"
              />
            </div>
            <div className="col-md-6">
              <Dropdown
                label="Reason"
                name="reasonCode"
                placeholder="All"
                onChange={(option) => handleFilterChange(option, 'reason_code')}
                value={selectedReasonCodeOption}
                isClearable={false}
                options={reasonCodeSelectOptions}
                isLoading={filterOptions?.reasonCodes?.isFetching}
                datacy="reason-Dropdown"
              />
            </div>
          </div>
          <div className="row mb-12 mb-16">
            <div className="col-md-6">
              <Dropdown
                label="Modifier"
                name="procedureModifier"
                placeholder="All"
                onChange={(option) =>
                  handleFilterChange(option, 'cpt_modifiers_code')
                }
                value={selectedCptModifierCodeOption}
                isClearable={false}
                options={procedureModifiersSelectOptions}
                isLoading={filterOptions?.procedureModifiers?.isFetching}
                datacy="modifier-Dropdown"
              />
            </div>
            <div className="col-md-6">
              <Dropdown
                label="Remark"
                name="remarkCode"
                placeholder="All"
                onChange={(option) =>
                  handleFilterChange(option, 'remark_codes')
                }
                value={selectedRemarkCodeOption}
                isClearable={false}
                options={remarkCodeOptions}
                isLoading={filterOptions?.remarkCodes?.isFetching}
                datacy="remark-Dropdown"
              />
            </div>
          </div>
          <div className="row mb-12 mb-16 d-flex flex-wrap--wrap">
            <div className="col-md-6">
              <Dropdown
                label="Assigned To"
                name="agent"
                placeholder="All"
                onChange={handleAgentChange}
                value={selectedAgentOption}
                isClearable={false}
                options={agentOptionsWithUnassignedOption}
                isLoading={filterOptions?.agents?.isFetching}
                datacy="agents-Dropdown"
              />
            </div>
            {isPracticeFilterVisible && (
              <div className="col-md-6">
                <Dropdown
                  label="Practice"
                  name="practice"
                  placeholder="All"
                  options={practicesOptions}
                  onChange={(option) =>
                    handleFilterChange(option, 'practice_identifier')
                  }
                  value={selectedPracticeId}
                  isClearable={false}
                  isLoading={filterOptions?.practices?.isFetching}
                  datacy="practice-Dropdown"
                />
              </div>
            )}

            <div className="col-md-6">
              <ToggleSwitch
                className="mt-28"
                options={ZERO_PAY_DENIALS_OPTIONS}
                checked={denialFilters.zero_pay || false}
                handleToggle={handleToggleZeroPay}
              />
            </div>
          </div>
        </div>
        <div className="appealio-popup-filter-buttons">
          <Button
            title="Apply"
            type={BUTTON_TYPE.SECONDARY}
            className="ap-button ap-button--block"
            onClick={handleConfirmFilters}
            datacy="dataCy-confirm-filters"
            disabled={isApplyFilterDisabled}
          />
        </div>
      </div>
    </ScreenDimmerComponent>
  );
};

DenialPopupFilter.propTypes = {
  userRole: PropTypes.number,
  setShowDenialFilters: PropTypes.func.isRequired,
  filters: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
  filterOptions: PropTypes.object,
};

export default DenialPopupFilter;
