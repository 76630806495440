import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import LoginForm from './LoginForm';
import CardView from '../Shared/CardView';

import * as routes from '../../constants/routes';

import { isUserOnboarding } from 'Auth/AuthUtils';
import { getHomePageRouteForUser } from 'helpers/route';
import { clearAuthToken } from '../../redux/actions/loginActions';

class LoginComponent extends Component {
  componentDidMount() {
    if (this.props.tokenHasExpired) {
      this.props.actions.clearAuthToken();
    }
  }

  render() {
    return (
      <div className="login">
        <CardView>
          <h2 className="login__header" datacy="login-header">
            Log In
          </h2>
          <LoginForm onSuccessfulLogin={this.onSuccessfulLogin} />
        </CardView>
      </div>
    );
  }

  onSuccessfulLogin = (userInfo) => {
    if (isUserOnboarding(userInfo)) {
      return this.props.actions.push(routes.CREATE_APPEALIO_ACCOUNT_DETAIL);
    }
    const defaultRoute = getHomePageRouteForUser(userInfo);
    const nextScreenPath =
      this.props.redirectTo || defaultRoute || routes.DENIED_QUEUE;

    this.props.actions.push(nextScreenPath);
  };
}

function mapStateToProps(state, ownProps) {
  const query = queryString.parse(ownProps.location.search);

  return {
    search: ownProps.location.search,
    tokenHasExpired: query.expired === 'true',
    redirectTo: query.redirect,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ push, clearAuthToken }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
