import { useEffect, useState } from 'react';

import DenialsAPI from 'API/DenialsAPI';

export const useFetchActionList = (includeOtherOption = true) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [actionList, setActionList] = useState([]);

  const extractOptions = ({ id, description }) => ({
    value: id,
    label: description,
  });

  const appendOtherOption = (options) => [
    ...options,
    { value: 0, label: 'Other' },
  ];

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        const { actionList } = await DenialsAPI.fetchActionLogs();
        setActionList(
          includeOtherOption
            ? appendOtherOption(actionList.map(extractOptions))
            : actionList.map(extractOptions)
        );
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };
    fetch();
  }, [includeOtherOption]);
  return {
    error,
    isLoading,
    actionList,
  };
};
