import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import React, { useState } from 'react';
import {
  getSopData,
  getSopError,
  getSopLoadingState,
} from 'redux/reducers/sopStore';

import sopIcon from 'img/sop-icon.svg';

import { fetchSopInfo } from 'redux/actions/sopActions';

import './style.css';
import SopPopup from './SopPopup/SopPopup';

const SopInfoIcon = ({
  appliedRule,
  sopIds,
  sopData,
  loading,
  error,
  fetchSopInfo,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderTooltipContent = () => (
    <div className="sop-info-tooltip">
      <div className="sop-info-tooltip-title">
        Click to view Standard Operating Procedures
      </div>
      <div className="sop-info-tooltip-subtitle">
        Applied Rule Name: {appliedRule}
      </div>
    </div>
  );

  const dataTipContent = ReactDOMServer.renderToStaticMarkup(
    renderTooltipContent()
  );

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsModalOpen(true);
    sopIds.forEach((sopId) => {
      if (!sopData[sopId]) {
        fetchSopInfo(sopId);
      }
    });
  };

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="table-rule-icon">
        <div
          data-tip={dataTipContent}
          data-for="sop-info-tooltip"
          data-html={true}
          className="sop-info-icon"
          onClick={handleClick}
        >
          <img src={sopIcon} className="sop-icon" alt="applied rule" />
        </div>

        <ReactTooltip
          effect="solid"
          place="right"
          className="sop-info-tooltip-background"
          id="sop-info-tooltip"
        />
      </div>

      {isModalOpen && (
        <SopPopup
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          appliedRule={appliedRule}
          sopIds={sopIds}
          sopData={sopData}
          loading={loading}
          error={error}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  sopData: ownProps.sopIds.reduce((acc, sopId) => {
    acc[sopId] = getSopData(state, sopId);
    return acc;
  }, {}),
  loading: ownProps.sopIds.some((sopId) => getSopLoadingState(state, sopId)),
  error: ownProps.sopIds
    .map((sopId) => getSopError(state, sopId))
    .find((error) => error),
});

const mapDispatchToProps = {
  fetchSopInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(SopInfoIcon);
