import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tab from './Tab';

import './style.css';

const Tabs = ({ tabs, className, customTabClassName }) => {
  return (
    <div className={classNames('tabs', className)}>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          title={tab.title}
          onClick={tab.onClick}
          disabled={tab.disabled}
          isActive={tab?.isActive}
          dataTip={tab?.dataTip}
          className={classNames(customTabClassName, tab?.className, 'mr-14')}
        />
      ))}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      isActive: PropTypes.bool,
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  className: PropTypes.string,
  customTabClassName: PropTypes.string,
};

export default Tabs;
