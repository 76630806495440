import _ from 'lodash';
import Events from 'events';
import S3Upload from 'react-s3-uploader/s3upload.js';

const EventEmitter = Events.EventEmitter;
/*
  Converted code from here: https://github.com/odysseyscience/react-s3-uploader/issues/43
  to ES6 class declaration - Piotr
*/
export default class S3FileUploader {
  constructor(file, options) {
    this.file = file;
    this.preview = file.preview || URL.createObjectURL(file);
    this.emitter = new EventEmitter();
    this.opts = _.defaults(options || {}, {
      signingUrl: '/s3/signUpload',
    });
  }

  getFile() {
    return this.file;
  }

  getFileType() {
    return this.getFile().type;
  }

  getFilename() {
    return this.getFile().name;
  }

  getResultS3Filename() {
    return this.result && this.result.signedUrl;
  }

  getPublicUrl() {
    return this.result && this.result.publicUrl;
  }

  on(event, callback) {
    this.emitter.addListener(event, callback);
  }

  off(event, callback) {
    this.emitter.removeListener(event, callback);
  }

  cancel() {
    if (this.upload) {
      this.upload.abortUpload();
      this.emitter.emit('abort');
      this.upload = null;
    }
  }

  markFailed() {
    this.failed = true;
  }

  isFailed() {
    return !!this.failed;
  }

  getContentDisposition() {
    const isPdf = this.getFileType() === 'application/pdf';
    return isPdf ? 'attachment' : 'auto';
  }

  start() {
    return new Promise((resolve, reject) => {
      this.upload = new S3Upload({
        files: [this.file],
        server: this.opts.server,
        signingUrl: this.opts.signingUrl,
        signingUrlQueryParams: this.opts.signingUrlQueryParams,
        signingUrlHeaders: this.opts.signingUrlHeaders,
        uploadRequestHeaders: { 'x-amz-acl': 'private' },
        contentDisposition: this.getContentDisposition(),
        onProgress: (percent, status) => {
          this.emitter.emit('progress', percent, status);
        },
        onFinishS3Put: (result) => {
          this.result = result;
          this.emitter.emit('complete', result);
          resolve(result);
        },
        onError: (status) => {
          this.emitter.emit('error', status);
          this.markFailed();
          reject(status);
        },
      });
      this.emitter.once('abort', resolve);
    });
  }
}
