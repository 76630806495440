import React from 'react';

import ReactDatePicker from '../Shared/ReactDatePicker';

export const renderReduxDatepicker = ({
  input,
  placeholder,
  label,
  disabled,
  meta: { touched, error },
}) => (
  <ReactDatePicker
    placeholder={placeholder}
    label={label}
    disabled={disabled}
    dateFormat={'MM/DD/YYYY'}
    onDateChange={input.onChange}
    selectedDate={input.value}
    error={error}
    touched={touched}
    input={input}
  />
);
