import React from 'react';
import Lottie from 'react-lottie';

import Button from 'components/common/button';
import AppealioPopup from 'components/Shared/AppealioPopup';

import './style.css';

import claimFetchingAnimation from '../UhcClaimStatusCheck/claimFetchingAnimation.json';

const ErrorPopup = ({
  message = null,
  animationData = claimFetchingAnimation,
  hideCloseButton = false,
  onCloseClick,
}) => {
  return (
    <AppealioPopup
      title="Claim Status Check"
      onClosePressed={onCloseClick}
      className="uhc-claim-status-check claim-status-detail-popup"
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={250}
        width={250}
      />
      {message && (
        <div className="uhc-claim-status-check__error">{message}</div>
      )}
      {!hideCloseButton && (
        <div className="d-flex justify-content-flex-end">
          <Button
            type="light"
            onClick={() => {
              onCloseClick();
            }}
          >
            Close
          </Button>
        </div>
      )}
    </AppealioPopup>
  );
};
export default ErrorPopup;
