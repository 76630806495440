import React from 'react';
import classNames from 'classnames';
import { AiOutlineFolder } from 'react-icons/ai';

import AssignUsers from './AssignUsers';
import { AppealioPopupWithFooter } from 'components/common/popup';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';

import { useFetchTeam } from './hooks';

const AddOrRemoveUsersPopup = ({
  teamId,
  usersOptions,
  onClosePressed,
  teamName,
  onAssignUsersComplete,
}) => {
  const { team, loading } = useFetchTeam(teamId);

  const teamUsers = team?.users || [];
  const teamUsernames = teamUsers.map((user) => user.username);

  const selectedUsersList = usersOptions?.data
    .filter(({ value }) => teamUsernames.includes(value))
    .map((x) => ({ ...x, selected: true }));

  const unSelectedUsersList = usersOptions?.data
    .filter(
      (agent) => agent.label !== 'All' && !teamUsernames.includes(agent.value)
    )
    .map((x) => ({ ...x, selected: false }));

  const usersList = [...selectedUsersList, ...unSelectedUsersList].sort(
    (a, b) => b.selected - a.selected
  );

  if (loading) {
    return (
      <AppealioPopupWithFooter
        className={classNames(
          'appealio-popup--v3 appealio-popup--v3--overflow-hidden'
        )}
        onClosePressed={onClosePressed}
        title={
          <div className="d-flex align-items-center">
            <AiOutlineFolder size="22" className="mr-4" />
            Add/Remove User(s) from{' '}
            <span className="ml-4 fw-bold"> {teamName}</span>
          </div>
        }
        isFooterOutside={false}
      >
        <LoadingBlockHelper className="mt-8" isLoading />
      </AppealioPopupWithFooter>
    );
  }

  return (
    <AssignUsers
      users={usersList}
      teamName={teamName}
      teamId={teamId}
      onClosePressed={onClosePressed}
      onAssignUsersComplete={onAssignUsersComplete}
    />
  );
};

export default AddOrRemoveUsersPopup;
