import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import React, { useCallback, useState } from 'react';

import './style.css';

const Pagination = (props) => {
  const { pageCount, activePage, onPageChange } = props;
  const [activePageValue, setActivePageValue] = useState(activePage);

  const debouncedOnPageChange = useCallback(
    (args) => {
      debounce(onPageChange, 1200)(args);
    },
    [onPageChange]
  );

  const handleInputPageChange = (e) => {
    const { value } = e.target;
    const formattedValue = value > pageCount ? pageCount : value;
    setActivePageValue(formattedValue);
    if (formattedValue) {
      debouncedOnPageChange(formattedValue);
    }
  };

  return (
    <div className="pagination" datacy="pagination">
      <ReactPaginate
        pageCount={pageCount}
        breakLabel="..."
        pageRangeDisplayed={4}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        forcePage={activePage - 1}
        onPageChange={({ selected: selectedIndex }) =>
          onPageChange(selectedIndex + 1)
        }
      />

      <div className="pagination-input">
        <label className="pagination-input__label" htmlFor="pageNumber">
          Go to page
        </label>
        <input
          value={activePageValue}
          id="pageNumber"
          className="pagination-input__number-input"
          type="number"
          min={1}
          max={pageCount}
          onChange={handleInputPageChange}
        />
      </div>
    </div>
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
