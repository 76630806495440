import React, { useState } from 'react';

import './style.css';

import Input from 'components/common/input';

const UsersList = ({ users }) => {
  const [searchValue, setSearchValue] = useState('');

  /**
   * Handles the search change.
   * @param {Object} e
   */
  const handleSearchChange = (e) => setSearchValue(e.target.value);

  const filteredList = users.filter((user) =>
    user.label.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <>
      <div className="ap-popup-list__sticky-search-bar row pb-0">
        <div className="col-lg-12">
          <Input
            label="Search for User"
            placeholder="User Name"
            onChange={handleSearchChange}
            value={searchValue}
          />
        </div>
      </div>
      <div className="col-lg-12  mt-2  mb-12">
        <div className="d-flex justify-content-flex-end ">
          <span className="fs-12">Total: {filteredList.length}</span>
        </div>
      </div>
      <div className="row mb-12">
        <div className="col-lg-12">
          <ul className="ap-popup-list__list">
            {filteredList.map((user, idx) => (
              <li className="ap-popup-list__list--item" key={idx}>
                <span>{user.label}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default UsersList;
