import './style.css';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import PlusImg from '../../img/plus.svg';
import SubmitButton from '../Shared/SubmitButton';
import * as routes from '../../constants/routes';

import { fetchEstimates } from '../../API/CostEstimatorAPI';

import LoadingBlockHelper from '../../components/Shared/LoadingBlockHelper';

/**
 * Use fetch estimates hook.
 * @param {Object} filters
 * @returns {Promise}
 */
const useFetchEstimates = (filters) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [estimates, setEstimates] = useState([]);

  useEffect(() => {
    setLoading(true);

    const fetch = async () => {
      const data = await fetchEstimates();

      setError(null);
      setEstimates(data);
      setLoading(false);
    };

    fetch();
  }, [filters]);

  return { loading, error, estimates };
};

const PatientCostEstimator = (props) => {
  const { loading, estimates } = useFetchEstimates();

  if (loading) {
    return (
      <LoadingBlockHelper
        className="dashboard-bottom-loader"
        isLoading={loading}
      />
    );
  }

  return (
    <div className="custom-container">
      <div className="max-width-1310 mr-auto ml-auto">
        <div className="patient-cost-estimator">
          <div className="container">
            <label className="patient-cost-estimator__title">
              Create A New Estimate
            </label>
            <SubmitButton
              title="Create Estimate"
              wrapInDiv
              className="create-estimate-nav-button"
              type="button"
              imgSrc={PlusImg}
              onClick={() =>
                props.actions.push(
                  routes.PATIENT_COST_ESTIMATOR_CREATE_ESTIMATE
                )
              }
            />
          </div>
          <div className="cost-esitmator-filter">
            <label>{estimates.length} Estimates</label>
          </div>
          <div>
            <table className="cost-estimator-estimates-table">
              <thead>
                <tr>
                  <th>Patient (Subscriber Name)</th>
                  <th>Provider</th>
                  <th>Payer</th>
                  <th>Procedures</th>
                  <th>Estimate</th>
                  <th>Date Estimate</th>
                </tr>
              </thead>
              <tbody>
                {estimates.map((estimate, key) => {
                  return (
                    <tr key={`cost-estimator-main-display-table-${key}`}>
                      <td>{estimate.patientName}</td>
                      <td>{estimate.provider}</td>
                      <td>{estimate.payer}</td>
                      <td>{estimate.procedures}</td>
                      <td>{estimate.estimate}</td>
                      <td>{estimate.estimateDate}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ push }, dispatch),
});

export default connect(() => ({}), mapDispatchToProps)(PatientCostEstimator);
