/**
 * Created by goer on 11/9/16.
 */
import { Serializer as JSONAPISerializer } from 'jsonapi-serializer';

export const AppealLetterSerializer = new JSONAPISerializer('AppealLetter', {
  attributes: ['html', 'name', 'order', 'Appeal', 'Template'],
  Appeal: {
    ref: 'id',
    included: false,
  },
  Template: {
    ref: 'id',
    included: false,
  },
  pluralizeType: false,
  keyForAttribute: 'underscore_case',
  typeForAttribute: (arg) => {
    if (arg === 'Template') return 'AppealTemplate';
  },
});
