import { useState, useCallback, useEffect } from 'react';

import CreateAppealAPI from 'API/CreateAppealAPI';
import * as AccountsAPI from 'API/AccountSettingsAPI';
import { getSubmissionTypeContacts } from 'components/CreateAppeal/Submit/utils';
import { isEmpty } from 'lodash';

export const useFetchLetterFormsAndDocuments = (appealId) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [letterFormsAndDocuments, setLetterFormsAndDocuments] = useState([]);
  const [isEobDocumentAttached, setIsEobDocumentAttached] = useState(false);

  const fetch = useCallback(async () => {
    setLoading(true);
    setLetterFormsAndDocuments([]);
    setIsEobDocumentAttached(false);

    try {
      const queryParam = {
        include: 'letters,documents',
      };
      const { included, data } = await CreateAppealAPI.getAppealDetails(
        appealId,
        queryParam
      );

      setLetterFormsAndDocuments(included);
      setIsEobDocumentAttached(data?.attributes?.includeEobDocument);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [appealId]);

  useEffect(() => {
    fetch();
  }, [appealId, fetch]);

  return {
    loading,
    error,
    letterFormsAndDocuments,
    isEobDocumentAttached,
    fetch,
  };
};

export const useFetchAndSetPayerContactOptions = (payerId) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [payerContactOptions, setPayerContactOptions] = useState({
    payerContactFaxOptions: [],
    payerContactMailOptions: [],
    payerContactEmailOptions: [],
    payerPortalOptions: [],
    allPayerContactOptions: [],
  });

  const fetch = useCallback(async () => {
    if (!isEmpty(payerContactOptions?.allPayerContactOptions)) {
      return;
    }
    try {
      setLoading(true);
      const payerContacts = await AccountsAPI.fetchPayerContacts({
        payer: payerId,
        all: 1,
      });
      const { mailContacts, faxContacts, payerPortalContacts, emailContacts } =
        getSubmissionTypeContacts(payerContacts);
      setPayerContactOptions({
        ...payerContactOptions,
        payerContactMailOptions: mailContacts,
        payerContactEmailOptions: emailContacts,
        payerContactFaxOptions: faxContacts,
        payerPortalOptions: payerPortalContacts,
        isFetchingPayerContactOptions: false,
        allPayerContactOptions: payerContacts,
      });
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [payerId, payerContactOptions]);

  useEffect(() => {
    fetch();
  }, [payerId, fetch]);
  return {
    loading,
    error,
    payerContactOptions,
  };
};

const defaultSubmissionOptions = [
  { value: 'mail', label: 'MAIL' },
  { value: 'fax', label: 'FAX' },
  { value: 'portal', label: 'Payer Portal' },
];

export const useFetchAndSetDeliveryFormats = (
  appealId,
  canUserSubmitViaEmail
) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [submissionOptions, setSubmissionOptions] = useState(
    defaultSubmissionOptions
  );
  const [reconReasons, setReconReasons] = useState([]);
  const [isFetchingDeliveryDetails, setIsFetchingDeliveryDetails] =
    useState(true);
  const fetch = useCallback(async () => {
    setLoading(true);
    const updatedSubmissionOptions = [...defaultSubmissionOptions];
    try {
      const deliveryFormats = await CreateAppealAPI.getDeliveryFormats(
        appealId
      );
      const includesDirectAppeal = deliveryFormats.includes('direct_appeal');
      const includesDirectRecon = deliveryFormats.includes('direct_recon');

      if (includesDirectRecon) {
        const reconReasons = await CreateAppealAPI.getReconsiderationReasons(
          appealId
        );
        setReconReasons(reconReasons);
      }

      if (includesDirectAppeal) {
        updatedSubmissionOptions.push({
          value: 'directAppeal',
          label: 'DIRECT APPEAL',
        });
      }
      if (includesDirectRecon) {
        updatedSubmissionOptions.push({
          value: 'directRecon',
          label: 'DIRECT RECONSIDERATION',
        });
      }
    } catch (error) {
      setError(error);
    }
    if (canUserSubmitViaEmail) {
      updatedSubmissionOptions.push({ value: 'email', label: 'EMAIL' });
    }
    setSubmissionOptions(updatedSubmissionOptions);
    setLoading(false);
    setIsFetchingDeliveryDetails(false);
  }, [appealId, canUserSubmitViaEmail]);

  useEffect(() => {
    fetch();
  }, [canUserSubmitViaEmail, fetch]);

  return {
    loading,
    error,
    submissionOptions,
    reconReasons,
    isFetchingDeliveryDetails,
  };
};
