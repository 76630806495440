import React from 'react';

const CommonSystemOverView = (props) => {
  const { systemNumber, systemImage, systemName } = props;
  return (
    <div className="system-overview__widget">
      <div className="d-flex">
        <span className="system-overview__widget-count">{systemNumber}</span>
        <div className="system-overview__widget-icon">
          <img src={systemImage} className="ml-16" />
          <span>{systemName}</span>
        </div>
      </div>
    </div>
  );
};

export default CommonSystemOverView;
