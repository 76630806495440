import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import * as routes from 'constants/routes';

import InProgressSubmissions from './InProgressSubmissions';
import DeliverTracking from 'components/Submissions/DeliveryTracking/DeliverTracking';

const SubmissionRouter = (props) => {
  return (
    <Switch>
      <Route
        exact
        path={routes.SUBMISSIONS}
        render={() => <DeliverTracking />}
      />
      <Route
        exact
        path={routes.SUBMISSIONS_IN_PROGRESS}
        render={() => <InProgressSubmissions />}
      />
      <Redirect to={routes.SUBMISSIONS} />
    </Switch>
  );
};

export default SubmissionRouter;
