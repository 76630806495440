import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';

export default class ReactDatePicker extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    onDateChange: PropTypes.func.isRequired,
    selectedDate: PropTypes.string,
    dateFormat: PropTypes.string,
    disabled: PropTypes.bool,
  };

  handleChange = (date) => {
    this.props.onDateChange(date.format(this.props.dateFormat));
  };

  renderDatePicker = () => {
    if (this.props.selectedDate === '') {
      return (
        <DatePicker
          className="datepicker__input"
          placeholderText={this.props.placeholder}
          onChange={this.handleChange}
          disabled={this.props.disabled}
          datacy="empty-DatePicker"
        />
      );
    } else {
      return (
        <DatePicker
          selected={moment(this.props.selectedDate, this.props.dateFormat)}
          className="datepicker__input"
          placeholderText={this.props.placeholder}
          onChange={this.handleChange}
          disabled={this.props.disabled}
          datacy="selected-DatePicker"
        />
      );
    }
  };

  render() {
    const datepickerClass = classNames(
      'react-datepicker',
      { 'datepicker__input--disabled': this.props.disabled },
      { 'datepicker--error': this.props.error && this.props.touched }
    );
    return (
      <div name={this.props.input.name} datacy="react-date-picker">
        <div className="datepicker__label">{this.props.label}</div>
        <div className={datepickerClass}>{this.renderDatePicker()}</div>
      </div>
    );
  }
}
