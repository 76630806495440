import { useCallback, useEffect, useState } from 'react';

import {
  fetchAnalyticsPayers,
  fetchAnalyticsPractices,
  fetchAnalyticsClaimsInformation,
} from 'API/AnalyticsDashboardAPI';

export const useFetchPayersDropdown = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [payers, setPayers] = useState([]);
  const [payersDropdownOptions, setPayersDropdownOptions] = useState([]);
  const fetch = useCallback(async () => {
    setLoading(true);
    setPayers([]);
    setError(null);
    try {
      const payers = await fetchAnalyticsPayers();
      const payersDropdownOptions = payers.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setPayers(payers);
      setPayersDropdownOptions(payersDropdownOptions);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);
  return { loading, error, payers, payersDropdownOptions };
};

export const useFetchPracticeDropdownOptions = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [practiceDropdownOptions, setPracticeDropdownOptions] = useState([]);
  const fetch = useCallback(async () => {
    setLoading(true);
    setPracticeDropdownOptions([]);
    setError(null);
    try {
      const practices = await fetchAnalyticsPractices();
      const practiceDropdownOptions = practices.map(
        ({ id, name, practiceIdentifier }) => ({
          value: id,
          label: name,
          key: practiceIdentifier,
        })
      );
      setPracticeDropdownOptions(practiceDropdownOptions);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  return { loading, error, practiceDropdownOptions };
};

export const useFetchClaimsInformation = (startDate, endDate) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [claims, setClaims] = useState({});
  const fetch = useCallback(async () => {
    setLoading(true);
    setClaims({});
    setError(null);
    try {
      const claims = await fetchAnalyticsClaimsInformation({
        start: startDate,
        end: endDate,
      });
      setClaims(claims);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [startDate, endDate]);

  return { loading, error, claims };
};
