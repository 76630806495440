import { get } from 'lodash';
import classnames from 'classnames';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import './style.css';

import Pill from 'components/common/pill';
import * as toast from 'components/Shared/toast';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import SuccessModalUHC from 'components/UhcClaimStatusCheck/SuccessModal';
import SuccessModalAvaility from 'components/AvailityClaimStatusCheck/SuccessModal/SuccessModal';

import ScheduleInformation from 'components/UhcClaimStatusCheck/ScheduleInformation';

import { useFetchClaimStatusHistoryById } from './hooks';

import {
  addClaimToStatusTracker,
  addClaimToStatusTrackerAviality,
} from 'API/PayerAPI';

import { PAYER_API_TYPE, UHC_CLAIMS_STATUS } from 'constants/appConstants';
import { CLAIM_STATUS_CHECK_CRON_OPTIONS } from 'constants/options';
import { getFormattedDate, DATE_WITH_TIME_FORMAT } from 'helpers/dateFormats';
import { extractStatus } from '../utils';

const ClaimStatusCheckHistory = (props) => {
  const { id } = props;
  const [claimInfoModal, setClaimInfoModal] = useState({
    isOpen: false,
    claimInfo: null,
    api: null, // UHC or AVAILITY
  });
  const openClaimInfoModal = (claimInfo, api) =>
    setClaimInfoModal({
      isOpen: true,
      claimInfo,
      api,
    });

  const closeClaimInfoModal = () =>
    setClaimInfoModal({
      isOpen: false,
      claimInfo: null,
      api: null,
    });

  const { data, loading, setData } = useFetchClaimStatusHistoryById(id);

  if (loading || !data)
    return (
      <div className="claim-hisstory-flyover">
        <LoadingIndicator showing />
      </div>
    );

  const appealioClaimSchedule = {
    noOfRetries: data.noOfRetries,
    schedule: data.schedule,
  };

  const handleClaimInfoModalSubmit = async (formValues) => {
    const { schedule, noOfRetries } = formValues.toJS();

    try {
      if (data.api === PAYER_API_TYPE.AVAILITY) {
        addClaimToStatusTrackerAviality({
          claim_control_number: data.requestJson.claimControlNumber,
          tax_id: data.requestJson.taxId,
          edi_payer_id: data.requestJson.ediPayerId,
          schedule: schedule.value,
          no_of_retries: noOfRetries,
        });
      } else {
        await addClaimToStatusTracker(
          data.requestJson.claimControlNumber,
          data.requestJson.taxId,
          data.requestJson.ediPayerId,
          schedule.value,
          noOfRetries
        );
      }
      toast.success({
        title: '',
        message: 'Scheduled check information has been updated successfully.',
      });

      setData({
        ...data,
        noOfRetries,
        schedule: schedule.value,
      });
    } catch (error) {
      toast.error({
        title: '',
        message:
          'Sorry, we could not update the Scheduled check information. Please try again later.',
      });
    }
  };

  return (
    <div className="claim-hisstory-flyover">
      <div className="claim-hisstory-flyover__title">Claim Status History</div>
      <div className="claim-history-flyover__info">
        <div className="row">
          <table>
            <thead>
              <tr>
                <th>Claim Number</th>
                <th>TIN</th>
                <th>Payer</th>
                <th>Patient</th>
                <th>Provider</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {data.requestJson.claimControlNumber ||
                    data.requestJson.claimNumber}
                </td>
                <td>{data.requestJson.taxId}</td>
                <td>{get(data, 'appealioPayer') || 'N/A'} </td>
                <td>{data.patientName}</td>
                <td>{get(data, 'appealioProvider') || 'N/A'} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="claim-history-flyover__schedule-info">
        <ScheduleInformation
          appealioClaimSchedule={appealioClaimSchedule}
          hideHistoryView
          onSubmit={handleClaimInfoModalSubmit}
          initialValues={{
            noOfRetries: appealioClaimSchedule.noOfRetries,
            schedule:
              CLAIM_STATUS_CHECK_CRON_OPTIONS.find(
                (option) => option.value === appealioClaimSchedule.schedule
              ) || CLAIM_STATUS_CHECK_CRON_OPTIONS[0],
          }}
        />
      </div>

      <div className="claim-history-flyover__hisory-table">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Claim Status</th>
              <th>Status Effective Date</th>
              <th>Receive Date</th>
              <th>Total Paid Amount</th>
              <th>Checked At</th>
              <th>Checked By</th>
            </tr>
          </thead>
          <tbody>
            {data.claimStatusHistory.map((item, index) => {
              const status = extractStatus({
                ...item,
                claimControlNumber: data.requestJson.claimControlNumber,
              });
              return (
                <tr key={index}>
                  <td>
                    <Pill
                      dataTip="Click to view claim status check details"
                      status={status}
                      className={classnames({
                        xl: item.status === UHC_CLAIMS_STATUS.MULTIPLE,
                        success: item.status === UHC_CLAIMS_STATUS.FINALIZED,
                        outline: item.status === UHC_CLAIMS_STATUS.PENDING,
                        danger: item.status === UHC_CLAIMS_STATUS.DENIED,
                      })}
                      onClick={(e) => {
                        e.stopPropagation();
                        openClaimInfoModal(
                          {
                            ...item.responseJson,
                            claimNumber: data.requestJson.claimControlNumber,
                          },
                          data.api
                        );
                      }}
                    />
                    <ReactTooltip effect="solid" />
                  </td>
                  <td>{status}</td>
                  <td>
                    {item.responseJson?.claimSummary?.statusEfctDt || 'N/A'}
                  </td>
                  <td>
                    {item.responseJson?.claimSummary?.receivedDt || 'N/A'}
                  </td>
                  <td>
                    {item.responseJson?.claimSummary?.totalPaidAmt || 'N/A'}
                  </td>
                  <td>
                    {getFormattedDate(item.checkedAt, DATE_WITH_TIME_FORMAT)}
                  </td>
                  <td>{item.checkedByName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {claimInfoModal.isOpen &&
        (claimInfoModal.api === PAYER_API_TYPE.AVAILITY ? (
          <SuccessModalAvaility
            claimInfo={claimInfoModal.claimInfo}
            onCloseClick={closeClaimInfoModal}
            showQuickActions={false}
          />
        ) : (
          <SuccessModalUHC
            claimInfo={claimInfoModal.claimInfo}
            onCloseClick={closeClaimInfoModal}
            showQuickActions={false}
          />
        ))}
    </div>
  );
};

ClaimStatusCheckHistory.propTypes = {};

export default ClaimStatusCheckHistory;
