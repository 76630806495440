import React from 'react';
import classNames from 'classnames';

import { validateUsername, validatePassword } from '../../helpers/validators';

export const validate = (values) => {
  const errors = {};
  errors.username = validateUsername(values.get('username'));
  errors.password = validatePassword(values.get('password'));
  return errors;
};

export const renderField = ({
  input,
  label,
  showCheckmarkIfOk,
  type,
  meta: { touched, error, active },
  datacy,
}) => (
  <div>
    <label className="login__label" htmlFor={input.name}>
      {label}
    </label>
    <div>
      <span>
        <input
          {...input}
          placeholder={label}
          type={type}
          datacy={datacy}
          className={classNames('login__input', {
            'login__input--checkmark': showCheckmarkIfOk && !error,
          })}
        />
      </span>
    </div>
  </div>
);
