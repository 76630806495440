/**
 * Created by alex on 10/27/16.
 */
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component } from 'react';

export default class ScreenDimmerComponent extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    darkBackground: PropTypes.bool,
    className: PropTypes.string,
  };

  render() {
    return (
      <div
        className={classnames(
          'dimmer',
          { 'dimmer--dark': this.props.darkBackground },
          this.props.className
        )}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </div>
    );
  }
}
