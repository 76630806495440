import classNames from 'classnames';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import { get as _get, identity, isEmpty, pickBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmissionError, reduxForm, initialize } from 'redux-form/immutable';

import FaxFormFields from './FaxFormFields';
import Button from 'components/common/button';
import MailFormFields from './MailFormFields';
import EmailFormFields from './EmailFormFields';
import * as toast from 'components/Shared/toast';
import Select from 'components/common/select/Select';
import DirectSubmissionFields from './DirectSubmissionFields';
import { AppealioPopupWithFooter } from 'components/common/popup';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import PayerPortalFormFields from './PayerPortalFormFields/PayerPortalFields';
import IdenticalAppealConfirmationPopup from '../IdenticalAppealConfirmationPopup';

import { formatFaxFormValues, validateFaxForm } from './FaxFormFields/utils';
import { formatMailFormValues, validateMailForm } from './MailFormFields/utils';
import {
  formatEmailFormValues,
  validateEmailForm,
} from './EmailFormFields/utils';
import {
  formatPayerPortalFormValues,
  validatePayerPortalForm,
} from './PayerPortalFormFields/utils';
import {
  formatDirectSubmissionValues,
  validateDirectSubmissionForm,
} from './DirectSubmissionFields/utils';

import { SUBMISSION_TYPE } from 'constants/appConstants';

import { getUserInfo } from 'redux/reducers/loginStore';
import CreateAppealAPI, { defaultIncludeOptions } from 'API/CreateAppealAPI';
import { createIdenticalAppealsWithExistingInfo } from 'API/DeliveryLogsAPI';

import { handleError } from 'helpers/errorHandler';

import './style.css';

import DvVerifiedIcon from 'components/common/dvVerified';

import {
  useFetchAndSetDeliveryFormats,
  useFetchAndSetPayerContactOptions,
} from '../hook';

const ResendIdenticalAppeal = (props) => {
  const {
    submissionId,
    appealId,
    handleOnSubmitResendIdenticalAppeal,
    patientName,
    claimNumber,
    userInfo,
    submissionType,
    onClickPreviewPackage,
    logId,
    onClosePressed,
    change,
    payerId,
    submissionMethod,
  } = props;
  const [emailFiles, setEmailFiles] = useState([]);
  const [isFetchingAppealDetails, setIsFetchingAppealDetails] = useState(true);
  const [selectedOption, setSelectedOption] = useState('fax');
  const [isDownloadingPDFPackage, setIsDownloadingPDFPackage] = useState(false);
  const [associatePayerContactDetails, setAssociatePayerContactDetails] =
    useState({});
  const [closeInProgressDuplicateClaim, setCloseInProgressDuplicateClaim] =
    useState(false);

  const [isCreatingAppeal, setIsCreatingAppeal] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState({
    associatePayerContactId: null,
    selectedPayerContact: null,
  });

  const downloadFileName = `${patientName}-${claimNumber}.pdf`;

  const { loading: isLoadingPayerContact, payerContactOptions } =
    useFetchAndSetPayerContactOptions(payerId);
  const { submissionOptions, reconReasons, isFetchingDeliveryDetails } =
    useFetchAndSetDeliveryFormats(appealId, userInfo?.canUserSubmitViaEmail);
  const isDirectAppealAvailable = submissionOptions.some(
    (item) => item.value === 'directAppeal'
  );

  useEffect(() => {
    const fetchAndSetEmailFiles = async () => {
      try {
        const emailFiles = await CreateAppealAPI.fetchEmailDocuments(
          submissionId
        );
        const files = await Promise.all(
          emailFiles.map(async (file) => {
            const blob = await CreateAppealAPI.fetchAppealDocument(file.id);

            return new File([blob], file.name, { type: document.docType });
          })
        );
        setEmailFiles(files);
      } catch (error) {
        handleError(error);
      }
    };

    const fetch = async () => {
      try {
        const queryParams = { include: defaultIncludeOptions };
        const { included } = await CreateAppealAPI.getAppealDetails(
          appealId,
          queryParams
        );
        if (included) {
          const payerContactDataList = included.find(
            (item) => item.type === 'PayerContact'
          );
          setAssociatePayerContactDetails(payerContactDataList?.attributes);

          const payerContact =
            payerContactOptions?.allPayerContactOptions?.find(
              (item) => item.id === Number(payerContactDataList?.id)
            );

          if (isDirectAppealAvailable) {
            setSelectedContacts({
              selectedPayerContact: {
                label: '',
                value: payerContactDataList.id,
              },
              associatePayerContactId: payerContactDataList.id,
            });
          } else {
            const payerContactName = payerContact?.name || '';
            const payerContactId = payerContact?.id;
            setSelectedContacts({
              selectedPayerContact: {
                label:
                  submissionMethod !== 'Direct'
                    ? `${payerContactName} (${payerContact?.payersDetails?.[0]?.name})`
                    : '',
                value: payerContactId,
              },
              associatePayerContactId: payerContactId,
            });
          }
        }
      } catch (error) {
        handleError(error);
      } finally {
        setIsFetchingAppealDetails(false);
      }
    };

    fetch();
    fetchAndSetEmailFiles();
  }, [
    submissionId,
    appealId,
    payerContactOptions?.allPayerContactOptions,
    isDirectAppealAvailable,
    submissionMethod,
  ]);

  const onDownloadPDFPackage = async (submissionId, fileName) => {
    try {
      setIsDownloadingPDFPackage(true);

      await CreateAppealAPI.downloadPreviewFile(submissionId, fileName);
      setIsDownloadingPDFPackage(false);
    } catch (error) {
      toast.error({
        title: 'Error',
        message:
          'Failed to download package. Please contact at cx@docvocate.com for further details.',
      });
      setIsDownloadingPDFPackage(false);
    }
  };

  const prefillMailForm = (deliveryDetail) => {
    const initialValues = {
      address_line1: _get(deliveryDetail, 'addressLine1', ''),
      address_line2: _get(deliveryDetail, 'addressLine2', ''),
      city: _get(deliveryDetail, 'city', ''),
      state: _get(deliveryDetail, 'state', ''),
      zipcode: _get(deliveryDetail, 'zipcode', ''),
      certified: _get(deliveryDetail, 'certified', ''),
      name: _get(deliveryDetail, 'deliveryOptions.shipping', ''),
      return_address_name: _get(
        deliveryDetail,
        'deliveryOptions.return.name',
        ''
      ),
      return_address_line1: _get(
        deliveryDetail,
        'deliveryOptions.return.addressLine1',
        ''
      ),
      return_address_line2: _get(
        deliveryDetail,
        'deliveryOptions.return.addressLine2',
        ''
      ),
      return_city: _get(deliveryDetail, 'deliveryOptions.return.city', ''),
      return_state: _get(deliveryDetail, 'deliveryOptions.return.state', ''),
      return_zipcode: _get(
        deliveryDetail,
        'deliveryOptions.return.zipcode',
        ''
      ),
    };
    props.initialize(initialValues);
  };

  const prefillFaxForm = (deliveryDetail) => {
    const initialValues = {
      faxNumber: _get(deliveryDetail, 'fax', ''),
      from: _get(deliveryDetail, 'deliveryOptions.faxFrom', ''),
      to: _get(deliveryDetail, 'deliveryOptions.faxTo', ''),
      phone: _get(deliveryDetail, 'coverLetterPhone', ''),
      re: _get(deliveryDetail, 'coverLetterRe', ''),
      cc: _get(deliveryDetail, 'coverLetterCc', ''),
      coverLetterText: _get(deliveryDetail, 'coverLetterText', ''),
    };
    props.initialize(initialValues);
  };

  const prefillEmailForm = (deliveryDetail) => {
    const initialValues = {
      emailSubject: _get(deliveryDetail, 'subject', ''),
      emailMessage: _get(deliveryDetail, 'message', ''),
      recipientEmail: _get(deliveryDetail, 'recipientEmail', ''),
      ccEmails: _get(deliveryDetail, 'ccEmails', []),
    };
    props.initialize(initialValues);
  };

  const setPrintAndSideOption = (deliveryDetail) => {
    props.change(
      'color',
      _get(deliveryDetail, 'deliveryOptions.color') === '1'
        ? 'COLOR'
        : 'BLACK_AND_WHITE'
    );
    props.change(
      'side',
      _get(deliveryDetail, 'deliveryOptions.doubleSided') === false
        ? 'SINGLE'
        : 'DOUBLE'
    );
  };

  useEffect(() => {
    const deliveryDetail = props.deliveryDetail;
    if (deliveryDetail) {
      if (props.deliveryDetail.addressLine1) {
        setSelectedOption('mail');
        prefillMailForm(deliveryDetail);
      } else if (props.deliveryDetail.fax) {
        setSelectedOption('fax');
        prefillFaxForm(deliveryDetail);
      } else if (
        props.deliveryDetail.recipientEmail &&
        userInfo?.canUserSubmitViaEmail
      ) {
        setSelectedOption('email');
        prefillEmailForm(deliveryDetail);
      } else if (props.deliveryDetail?.payerPortalUrl) {
        setSelectedOption('portal');
      } else if (isDirectAppealAvailable) {
        setSelectedOption('directAppeal');
      }
      setPrintAndSideOption(deliveryDetail); // Set Print and Side options either from prior submissions or default options.
    }
    // eslint-disable-next-line
  }, [props.deliveryDetail, isDirectAppealAvailable]);
  const selectedSubmissionOption = submissionOptions.find(
    (option) => option.value === selectedOption
  );

  const submitBtnTitle =
    selectedSubmissionOption?.value === 'portal'
      ? 'Confirm Submission'
      : submissionType === SUBMISSION_TYPE.RECORD
      ? 'Submit Record'
      : 'Submit Appeal';

  const payerContactOptionsForSubmissions = useMemo(() => {
    const payerContactOptionMapping = {
      mail: payerContactOptions?.payerContactMailOptions,
      fax: payerContactOptions?.payerContactFaxOptions,
      email: payerContactOptions?.payerContactEmailOptions,
      portal: payerContactOptions?.payerPortalOptions,
    };

    return payerContactOptionMapping[selectedSubmissionOption?.value];
  }, [selectedSubmissionOption, payerContactOptions]);

  const handleSubmissionTypeChange = (selectedOption) => {
    setSelectedOption(selectedOption.value);
    setPayerContactAddress(selectedOption?.value);
  };

  const createIdenticalAppeals = async (
    values,
    submissionId,
    selectedOption,
    selectedPayerContact
  ) => {
    let submissionType = selectedOption;
    if (['directAppeal', 'directRecon'].includes(submissionType)) {
      submissionType = 'direct';
    }
    const data = {
      submission_id: submissionId,
      submit_type_info: {
        submission_type: submissionType,
        ...values,
      },
      payer_contact_id: selectedPayerContact?.value,
    };

    const shouldUseFormData =
      selectedOption === 'email' && emailFiles.length > 0;
    const formData = new FormData();
    if (shouldUseFormData) {
      formData.append('submission_id', data.submission_id);
      formData.append(
        'submit_type_info',
        JSON.stringify(data.submit_type_info)
      );
      formData.append('payer_contact_id', data.payer_contact_id);
      if (selectedOption === 'email') {
        emailFiles.forEach((file) => {
          formData.append('email_documents', file);
        });
      }
    }

    try {
      setIsCreatingAppeal(true);
      await createIdenticalAppealsWithExistingInfo(
        shouldUseFormData ? formData : data
      );
      setIsCreatingAppeal(false);
      handleOnSubmitResendIdenticalAppeal();
    } catch (error) {
      setIsCreatingAppeal(false);
      handleError(error);
    }
  };

  const handleSubmit = (formData) => {
    if (!selectedContacts?.selectedPayerContact) {
      return toast.warning({
        title: '',
        message: 'Payer Contact is required',
      });
    }
    let errors;
    let values;
    switch (selectedOption) {
      case 'mail':
        errors = validateMailForm(formData);
        if (errors?.displayErrors?.length > 0) {
          scrollToTop();
          throw new SubmissionError({ ...errors });
        }
        values = formatMailFormValues(formData);
        break;
      case 'fax':
        errors = validateFaxForm(formData);

        if (errors?.displayErrors?.length > 0) {
          scrollToTop();
          throw new SubmissionError({ ...errors });
        }
        values = formatFaxFormValues(formData);
        break;
      case 'email':
        errors = validateEmailForm(formData);
        if (errors?.displayErrors?.length > 0) {
          scrollToTop();
          throw new SubmissionError({ ...errors });
        }
        values = formatEmailFormValues(formData);
        break;
      case 'directAppeal':
        errors = validateDirectSubmissionForm(formData, false);
        if (errors?.displayErrors?.length > 0) {
          scrollToTop();
          throw new SubmissionError({ ...errors });
        }
        values = formatDirectSubmissionValues(formData, false);
        break;
      case 'directRecon':
        errors = validateDirectSubmissionForm(formData, true);
        if (errors?.displayErrors?.length > 0) {
          scrollToTop();
          throw new SubmissionError({ ...errors });
        }
        values = formatDirectSubmissionValues(formData, true);
        break;
      case 'portal':
        formData = {
          payerPortalUrl: associatePayerContactDetails?.payerPortalUrl,
        };
        errors = validatePayerPortalForm(formData);
        if (errors?.displayErrors?.length > 0) {
          scrollToTop();
          throw new SubmissionError({ ...errors });
        }
        values = formatPayerPortalFormValues(formData);
        break;
      default:
        break;
    }
    createIdenticalAppeals(
      values,
      submissionId,
      selectedOption,
      selectedContacts?.selectedPayerContact
    );
    return null;
  };

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };

  const renderLoader = () => {
    return (
      <div className="resend-identical-loader">
        <LoadingIndicator showing />
      </div>
    );
  };

  const renderForm = () => {
    switch (selectedOption) {
      case 'mail':
        return <MailFormFields />;
      case 'fax':
        return <FaxFormFields />;
      case 'email':
        return (
          <EmailFormFields
            emailFiles={emailFiles}
            onFilesChange={setEmailFiles}
          />
        );
      case 'directAppeal':
        return (
          <DirectSubmissionFields
            change={change}
            isDirectRecon={false}
            reconReasons={reconReasons}
          />
        );
      case 'directRecon':
        return (
          <DirectSubmissionFields
            change={change}
            isDirectRecon={true}
            reconReasons={reconReasons}
          />
        );
      case 'portal':
        return (
          <PayerPortalFormFields
            submissionId={submissionId}
            appealId={appealId}
            payerPortalUrl={associatePayerContactDetails?.payerPortalUrl}
            downloadFileName={downloadFileName}
            onDownloadPDFPackage={onDownloadPDFPackage}
            isDownloadingPDFPackage={isDownloadingPDFPackage}
          />
        );

      default:
        return null;
    }
  };

  const handlePreviewClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClickPreviewPackage(`final-package/${logId}`);
  };

  const filteredSubmissionOptions =
    !associatePayerContactDetails?.payerPortalUrl
      ? submissionOptions.filter((option) => option.value !== 'portal')
      : submissionOptions;

  const previewButtonTitle = 'Preview Full Package';

  const onConfirmationPopup = useCallback(() => {
    setCloseInProgressDuplicateClaim(false);
  }, []);

  const formatMailingAddress = (address, dvVerifiedMail = false) => {
    const mappedAddressInfo = {
      Address: address.address1,
      'Address 2': address.address2,
      City: address.city,
      State: address.state,
      ZIP: address.zipcode,
    };
    const contactInfo = pickBy(mappedAddressInfo, identity);
    return (
      <div>
        {dvVerifiedMail ? (
          <p className="dv-verified-legend">
            DV
            <span className="ml-2">Verified</span>
          </p>
        ) : null}
        {Object.entries(contactInfo).map(([key, value], idx) => (
          <div key={idx}>
            <strong>{key}</strong>: {value || 'N/A'}
          </div>
        ))}
      </div>
    );
  };

  const formatOptionLabelForSubmission = (option) => {
    const isOptionVerified = option?.dvVerifiedFax || option?.dvVerifiedMail;
    const dataTipContent = ReactDOMServer.renderToStaticMarkup(
      option?.address ? (
        formatMailingAddress(option.address, option?.dvVerifiedMail)
      ) : (
        <div>
          {isOptionVerified ? (
            <p className="dv-verified-legend">
              DV
              <span className="ml-2">Verified</span>
            </p>
          ) : null}

          {option?.fax && <div>Fax: {option.fax}</div>}
          {option?.email && <div>Email: {option.email}</div>}
          {option?.payerPortalUrl && (
            <div>Payer Portal Url: {option.payerPortalUrl}</div>
          )}
        </div>
      )
    );
    return (
      <div
        className={classNames({
          'position-relative d-flex align-items-center justify-content--space-between':
            isOptionVerified,
        })}
      >
        <span data-tip={dataTipContent} data-html={true}>
          {option.label}
        </span>
        {isOptionVerified ? (
          <DvVerifiedIcon className="dv-verified-field dv-verified-field--select z-index-9" />
        ) : null}
        <ReactTooltip effect="solid" place="top" />
      </div>
    );
  };

  const shouldPayerContactSelectedWithChangeInSubmissionType = (
    selectedPayerContact,
    submissionOption
  ) => {
    const contactFields = {
      fax: 'fax',
      mail: 'address1',
      email: 'email',
      portal: 'payerPortalUrl',
    };

    return !(
      submissionOption in contactFields &&
      selectedPayerContact?.[contactFields[submissionOption]] === ''
    );
  };

  const setPayerContactAddress = (submissionOption) => {
    const selectedPayerContactInfo =
      payerContactOptions.allPayerContactOptions?.find(
        (item) => item.id === Number(selectedContacts.associatePayerContactId)
      );
    const shouldPayerContactSelected =
      shouldPayerContactSelectedWithChangeInSubmissionType(
        selectedPayerContactInfo,
        submissionOption
      );
    if (!isEmpty(selectedPayerContactInfo) && shouldPayerContactSelected) {
      setSelectedContacts((prevContacts) => ({
        ...prevContacts,
        selectedPayerContact: {
          label: `${selectedPayerContactInfo.name} (${selectedPayerContactInfo?.payersDetails?.[0]?.name})`,
          value: selectedPayerContactInfo.id,
        },
      }));

      preFillAddress(submissionOption, selectedPayerContactInfo);
    } else {
      setSelectedContacts((prevContacts) => ({
        ...prevContacts,
        selectedPayerContact: null,
      }));
    }
  };

  const preFillAddress = (submissionOption, value) => {
    let prefillValues = {};
    if (submissionOption === 'mail') {
      prefillValues = {
        address_line1:
          _get(value, 'address.address1', '') || _get(value, 'address1', ''),
        address_line2:
          _get(value, 'address.address2', '') || _get(value, 'address2', ''),
        city: _get(value, 'address.city', '') || _get(value, 'city', ''),
        state: _get(value, 'address.state', '') || _get(value, 'state', ''),
        zipcode:
          _get(value, 'address.zipcode', '') || _get(value, 'zipcode', ''),
        name: _get(value, 'address.name', '') || _get(value, 'name', ''),
      };
    } else if (submissionOption === 'fax') {
      prefillValues = {
        faxNumber: _get(value, 'fax', ''),
        phone: props.userInfo?.phone,
      };
    } else if (submissionOption === 'email') {
      const email = _get(value, 'email', '');
      if (email) {
        prefillValues = {
          recipientEmail: email,
        };
      }
    }

    Object.keys(prefillValues).forEach((key) => {
      props.change(key, prefillValues[key]);
    });
  };

  const handlePayerContactSelect = (value) => {
    const submissionOption = selectedSubmissionOption?.value;
    preFillAddress(submissionOption, value);

    setSelectedContacts((prevContacts) => ({
      ...prevContacts,
      selectedPayerContact: {
        label: value?.label,
        value: value?.value,
      },
    }));
    if (submissionOption === 'portal') {
      setAssociatePayerContactDetails((prevContacts) => ({
        ...prevContacts,
        payerPortalUrl: _get(value, 'payerPortalUrl', ''),
      }));
    }
  };

  return (
    <>
      <AppealioPopupWithFooter
        className="appealio-popup--v3 appealio-resend-identical-claim-popup"
        title="Customize Duplicate Appeal"
        isFooterOutside={false}
        onClosePressed={() => setCloseInProgressDuplicateClaim(true)}
      >
        {isFetchingAppealDetails || isFetchingDeliveryDetails ? (
          renderLoader()
        ) : (
          <form onSubmit={props.handleSubmit(handleSubmit)}>
            <div className="mb-10">
              <label>Submission Type:</label>
              <Select
                value={selectedSubmissionOption}
                onChange={handleSubmissionTypeChange}
                options={filteredSubmissionOptions}
              />
            </div>
            {payerContactOptionsForSubmissions &&
              payerContactOptionsForSubmissions.length > 0 && (
                <div className="mb-10">
                  <label>Payer Contact: * </label>
                  <Select
                    name="payerContactOptions"
                    options={payerContactOptionsForSubmissions}
                    isLoading={isLoadingPayerContact}
                    placeholder="Select Payer Contact"
                    onChange={handlePayerContactSelect}
                    value={selectedContacts.selectedPayerContact}
                    reactSelectProps={{
                      formatOptionLabel: formatOptionLabelForSubmission,
                    }}
                  />
                </div>
              )}
            {renderForm()}
            <div className="d-flex justify-content-flex-end align-items-center mt-22">
              {selectedSubmissionOption?.value !== 'portal' && (
                <button
                  className="preview_button--identical-appeal"
                  onClick={(e) => handlePreviewClick(e)}
                >
                  {previewButtonTitle}
                </button>
              )}
              <Button
                type="submit"
                disabled={isCreatingAppeal}
                className="ap-button ap-button--secondary width-160 justify-content-center"
              >
                {submitBtnTitle}
              </Button>
            </div>
          </form>
        )}
      </AppealioPopupWithFooter>

      {closeInProgressDuplicateClaim && (
        <IdenticalAppealConfirmationPopup
          onClosePressed={onClosePressed}
          onConfirmationPopup={onConfirmationPopup}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  initialize,
};

function mapStateToProps(state) {
  return {
    userInfo: getUserInfo(state),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'resendIdenticalAppeal',
    enableReinitialize: false,
  })(ResendIdenticalAppeal)
);
