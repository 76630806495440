import classnames from 'classnames';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { get, identity, isEmpty, pickBy, has, debounce } from 'lodash';

import {
  PAYER_CLAIM_ID_FIELD_INCORRECT_FORMAT,
  SUBMITTER_CLAIM_ID_FIELD_INCORRECT_FORMAT,
} from 'components/Shared/Errors/errorMessages';

import * as toast from 'components/Shared/toast';
import AppealioPopup from 'components/Shared/AppealioPopup';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import MissingPayerPopup from 'components/DenialQueue/MissingPayerPopup';
import MissingRenderingProviderPopup from 'components/DenialQueue/MissingRenderingProviderPopup';
import SearchResultContainer from 'components/GlobalSearch/SearchResultContainer/SearchResultContainer';

import * as routes from 'constants/routes';

import DenialsAPI from 'API/DenialsAPI';
import AppealsAPI from 'API/AppealsAPI';
import CreateAppealAPI from 'API/CreateAppealAPI';

import {
  setAppealData,
  setAppealPreFillData,
  clearCurrentAppealData,
} from 'redux/actions/createAppealActions';
import { getUserInfo } from 'redux/reducers/loginStore';
import { useFetchSearchResults } from 'components/GlobalSearch/hook';

import { handleError } from 'helpers/errorHandler';
import { getFormattedDate } from 'helpers/dateFormats';
import { validatePayerClaimId } from 'helpers/validators';
import { SUBMISSION_STATUS } from 'constants/appConstants';

import crossIcon from 'img/cross-icon.svg';
import arrowBackIcon from 'img/keyboard-arrow-return.svg';

import { createURLWithParams } from 'API/Config';
import useDebouncedValue from 'hooks/useDebouncedValue';
import { extractUrlAndQueryParam } from 'components/GlobalSearch/util';

const Input = (props) => {
  const {
    name,
    value,
    onChange,
    className,
    handleSearchSubmit,
    placeholder = '',
  } = props;

  return (
    <form
      className="search-box__form"
      datacy="search-form"
      onSubmit={handleSearchSubmit}
    >
      <div className="ap-input-container form-field">
        <input
          value={value}
          className={className}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          autoComplete="off"
          autoFocus={true}
        />
      </div>
      <button
        className="btn ap-button--secondary search-box__submit-btn"
        type="submit"
        datacy="search-button"
        disabled={!value}
        onClick={handleSearchSubmit}
      >
        <img src={arrowBackIcon} alt="enter" />
        <span className="ml-8">Enter</span>
      </button>
    </form>
  );
};

const CreateAppealModal = (props) => {
  const { isMedicalRecord } = props;
  const popupTitle = !isMedicalRecord
    ? 'Create Appeal'
    : 'Create Medical Record';

  const [searchString, setSearchString] = useState('');
  const [isCreatingAppeal, setIsCreatingAppeal] = useState(false);
  const [searchClaimId, setSearchClaimId] = useState('');

  const [selectedClaimToImport, setSelectedClaimToImport] = useState(null);
  const [manualPayerId, setManualPayerId] = useState('');
  const [error, setError] = useState(null);

  const [missingImportClaimInfo, setMissingImportClaimInfo] = useState({
    isMissingPayer: false,
    isMissingProvider: false,
    assignedRenderingProviderId: null,
    requestedRenderingProviderInfo: {},
    assignedPayerId: null,
    requestedPayerInfo: {},
  });
  const [isMissingPayerPopupOpen, setIsMissingPayerPopupOpen] = useState(false);
  const [isMissingProviderPopupOpen, setIsMissingProviderPopupOpen] =
    useState(false);

  const { debouncedValue: debouncedSearchClaimId, setDebouncedValue } =
    useDebouncedValue(searchClaimId, 600);
  const { data, loading } = useFetchSearchResults(
    searchClaimId,
    props.userInfo,
    true,
    3
  );

  const handleRowClick = (_, data) => {
    setSelectedClaimToImport({
      ...data,
    });
  };

  const resetSelectedValues = () => {
    setDebouncedValue(null);
    setSearchClaimId('');
    setSearchString('');
    setSelectedClaimToImport(null);
    setManualPayerId('');
    setError(null);
  };

  const checkIfAppealExists = async (payerClaimId) => {
    const { data: existingAppeals } = await AppealsAPI.getAppealsOnPage(1, [
      {
        name: 'payer_claim_id',
        query: payerClaimId,
        useAsURLParam: true,
      },
    ]);

    return {
      isExisting: existingAppeals.length > 0,
      status: existingAppeals?.[0]?.appeals?.[0]?.status,
    };
  };

  const setAppealDataAndRedirect = async (appealId) => {
    const appeal = await CreateAppealAPI.getAppealById(appealId);

    props.actions.setAppealData({
      appeals: [
        {
          ...appeal,
          step: 0,
        },
      ],
      name: appeal.patientName,
    });

    props.actions.push('/createappeal/patientinfo');
  };

  const createImportedAppeal = async (extraParams) => {
    const { claimNumber, submitterClaimId } = selectedClaimToImport;
    const requestBody = {
      ...pickBy(extraParams, identity),
      claimNumber: submitterClaimId,
      claimControlNumber: claimNumber,
      is_medical_record: isMedicalRecord,
    };

    let appealExists;
    let appealStatus;
    try {
      const { isExisting, status } = isMedicalRecord
        ? { isExisting: false, status: null }
        : await checkIfAppealExists(claimNumber);
      appealExists = isExisting;
      appealStatus = status;
    } catch (error) {
      return handleError(error);
    }

    if (appealExists) {
      return setError({
        message: `*We found an existing ${
          isMedicalRecord ? 'record' : 'appeal'
        } with this ${isMedicalRecord ? 'Claim ID' : 'Payer Claim ID'}.`,
        payerClaimId: claimNumber,
        status: appealStatus,
      });
    }

    try {
      setIsCreatingAppeal(true);
      const { appeal_id: appealId } = await DenialsAPI.appealDeniedClaim(
        requestBody
      );
      setAppealDataAndRedirect(appealId);
      setIsCreatingAppeal(false);
    } catch (error) {
      setIsCreatingAppeal(false);
      if (error.response.status === 404) {
        const errorBody = await error.response.json();
        const missingEntities = get(errorBody, 'missing_entity', []);

        if (isEmpty(missingEntities)) {
          return handleError(error);
        }

        const isMissingPayer = missingEntities.includes('PAYER');
        const isMissingProvider = missingEntities.includes('PROVIDER');
        setMissingImportClaimInfo({
          ...missingImportClaimInfo,
          isMissingPayer,
          isMissingProvider,
        });

        if (isMissingProvider) {
          return setIsMissingProviderPopupOpen(true);
        }
        if (isMissingPayer) {
          return setIsMissingPayerPopupOpen(true);
        }
      }

      return handleError(error);
    }

    toast.success({
      title: 'Success',
      message: `${
        isMedicalRecord ? 'Record' : 'Appeal'
      } of claim id ${submitterClaimId} is now in progress.`,
    });
    props.closeCreateAppealDialog();
  };

  const createManualAppeal = async () => {
    if (!manualPayerId) {
      const errorMessage = isMedicalRecord
        ? 'Claim ID is required'
        : 'Payer  Claim ID is required';

      return toast.error({
        title: '',
        message: errorMessage,
        extraParams: {
          position: 'center',
        },
      });
    }

    const invalidPayerClaimIdMessage = validatePayerClaimId(
      manualPayerId,
      isMedicalRecord
        ? SUBMITTER_CLAIM_ID_FIELD_INCORRECT_FORMAT
        : PAYER_CLAIM_ID_FIELD_INCORRECT_FORMAT
    );
    if (manualPayerId && invalidPayerClaimIdMessage) {
      return toast.error({
        title: '',
        message: invalidPayerClaimIdMessage,
        extraParams: {
          position: 'center',
        },
      });
    }

    props.actions.clearCurrentAppealData();

    let appealExists = false;
    let appealStatus;
    try {
      const { isExisting, status } = isMedicalRecord
        ? { isExisting: false, status: null }
        : await checkIfAppealExists(manualPayerId);
      appealExists = isExisting;
      appealStatus = status;
    } catch (error) {
      return handleError(error);
    }

    if (appealExists) {
      return setError({
        message: `*We found an existing ${
          isMedicalRecord ? 'record' : 'appeal'
        } with this ${isMedicalRecord ? 'Claim ID' : 'Payer Claim ID'}.`,
        payerClaimId: manualPayerId,
        status: appealStatus,
      });
    }

    try {
      // TODO: Use API to get prefill data with submitter claim id once API supports it
      let apiData = null;
      if (!isMedicalRecord) {
        apiData = await CreateAppealAPI.getPrefillAppealData(manualPayerId);
        if (apiData) {
          toast.success({
            message:
              'Data found in claim pool, we have filled in the forms. Please verify.',
            title: '',
            extraParams: {
              extraParams: {
                position: 'center',
              },
              timeout: 6000,
              class: 'create-appeal-toast',
            },
          });
        }
      }
      const claimNumber = manualPayerId;
      const submitterClaimId = isMedicalRecord ? manualPayerId : '';
      props.actions.setAppealPreFillData({
        ...(apiData || {}),
        claimNumber,
        submitterClaimId,
        isMedicalRecordsSubmission: isMedicalRecord,
      });
      props.actions.push('/createappeal/patientinfo');
    } catch (error) {
      handleError(error);
    }
    props.closeCreateAppealDialog();
  };
  const isCreateBtnDisabled = !selectedClaimToImport && !manualPayerId;

  const onSubmit = async () => {
    if (isCreateBtnDisabled) {
      return toast.error({
        message: 'Please select a claim to import',
        title: '',
        extraParams: {
          position: 'center',
        },
      });
    }

    if (selectedClaimToImport) {
      createImportedAppeal();
    } else {
      createManualAppeal();
    }
  };

  const debouncedOnSubmit = debounce(onSubmit, 600);

  const setSubmissionFilterAndRedirect = () => {
    const { payerClaimId, status } = error;
    const route =
      status === SUBMISSION_STATUS.IN_PROGRESS
        ? routes.SUBMISSIONS_IN_PROGRESS
        : routes.SUBMISSIONS;
    props.closeCreateAppealDialog();
    props.actions.push(`${route}?set_filters=true&search=${payerClaimId}`);
  };

  const handleMissingImportClaimInfo = async (missingImportClaimInfo) => {
    setMissingImportClaimInfo(missingImportClaimInfo);

    const isProviderDataAvailable =
      !missingImportClaimInfo.isMissingProvider ||
      missingImportClaimInfo.assignedRenderingProviderId ||
      !isEmpty(missingImportClaimInfo.requestedRenderingProviderInfo);

    const isPayerDataAvailable =
      !missingImportClaimInfo.isMissingPayer ||
      missingImportClaimInfo.assignedPayerId ||
      !isEmpty(missingImportClaimInfo.requestedPayerInfo);

    if (!isPayerDataAvailable) {
      setIsMissingProviderPopupOpen(false);
      return this.openMissingPayerPopup(missingImportClaimInfo.claim);
    }

    if (!isProviderDataAvailable) {
      setIsMissingPayerPopupOpen(false);
      return this.openRenderingProviderPopup(missingImportClaimInfo.claim);
    }

    const hasAssignedPayerAndProvider =
      (!missingImportClaimInfo.isMissingPayer ||
        missingImportClaimInfo.assignedPayerId) &&
      (!missingImportClaimInfo.isMissingProvider ||
        missingImportClaimInfo.assignedRenderingProviderId);

    if (hasAssignedPayerAndProvider) {
      await createImportedAppeal({
        providerId: +missingImportClaimInfo.assignedRenderingProviderId,
        payerId: +missingImportClaimInfo.assignedPayerId,
      });
    }
  };

  const handleAssignMissingRenderingProvider = (
    assignedRenderingProviderId
  ) => {
    handleMissingImportClaimInfo({
      ...missingImportClaimInfo,
      assignedRenderingProviderId,
    });
  };
  const handleAssignMissingPayer = (assignedPayerId) => {
    handleMissingImportClaimInfo({
      ...missingImportClaimInfo,
      assignedPayerId,
    });
  };
  const resetPopupsAndMissingImportClaimInfo = () => {
    setMissingImportClaimInfo(null);
    setIsMissingProviderPopupOpen(false);
    setIsMissingPayerPopupOpen(false);
  };

  if (isMissingProviderPopupOpen) {
    const appealioPractice =
      selectedClaimToImport &&
      props.userInfo.relatedPractices.find(
        (practice) =>
          practice.practiceIdentifier ===
          selectedClaimToImport?.practiceIdentifier
      );
    return (
      <MissingRenderingProviderPopup
        className="create-appeal-missing-rendering-provider-popup"
        onAssignRenderingProvider={handleAssignMissingRenderingProvider}
        onClosePressed={resetPopupsAndMissingImportClaimInfo}
        isSubmittingData={isCreatingAppeal}
        submitting={isCreatingAppeal}
        practiceId={appealioPractice?.id}
      />
    );
  }

  if (isMissingPayerPopupOpen) {
    return (
      <MissingPayerPopup
        onAssignPayer={handleAssignMissingPayer}
        onClosePressed={resetPopupsAndMissingImportClaimInfo}
        isSubmittingData={isCreatingAppeal}
      />
    );
  }

  const onCreateManualAppeal = (e) => {
    setManualPayerId(searchClaimId || null);

    const invalidPayerClaimIdMessage = validatePayerClaimId(
      e.target.value,
      isMedicalRecord
        ? SUBMITTER_CLAIM_ID_FIELD_INCORRECT_FORMAT
        : PAYER_CLAIM_ID_FIELD_INCORRECT_FORMAT
    );
    if (invalidPayerClaimIdMessage) {
      return toast.error({
        title: '',
        message: invalidPayerClaimIdMessage,
        extraParams: {
          position: 'center',
        },
      });
    }
  };

  const SearchListFooterComponent = ({ className }) => {
    return (
      <div
        className={classnames(
          'text-right search-list-item search-list-footer',
          {
            [className]: className,
          }
        )}
        onClick={onCreateManualAppeal}
      >
        Or, create
        {isMedicalRecord ? ' a Record ' : ' an Appeal '}
        <span className="cursor-pointer">without imported data</span>
        <span className="pl-8 text-bold">{'->'}</span>
      </div>
    );
  };

  const NoResultComponent = () => {
    const invalidPayerClaimIdMessage = validatePayerClaimId(searchClaimId);

    if (invalidPayerClaimIdMessage) {
      return (
        <div className="no-result-container">
          <span>
            No matches found for {searchClaimId}. Please enter a valid{' '}
            {isMedicalRecord ? 'Claim ID' : 'Payer Claim ID'}.
          </span>
        </div>
      );
    }

    return (
      <div className="no-result-container">
        <div className="text-center">
          No matches found for {searchClaimId}. Please try again.
        </div>
        <SearchListFooterComponent className="search-list-footer--hover" />
      </div>
    );
  };

  const SelectedValue = () => {
    if (isCreateBtnDisabled) {
      return null;
    }

    if (selectedClaimToImport) {
      return (
        <div className="selected-claim-container">
          <div className="selected-claim row no-gutter equal">
            <div className="col-lg-4">
              <div>
                <div className="selected-claim-label">Patient Name</div>
              </div>
              <div className="selected-claim-value">
                {selectedClaimToImport.patientName}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="selected-claim-label">
                {isMedicalRecord ? 'Claim ID' : 'Payer Claim ID'}
              </div>
              <div className="selected-claim-value text-overflow-ellipsis">
                {isMedicalRecord
                  ? selectedClaimToImport?.submitterClaimId || 'N/A'
                  : selectedClaimToImport?.claimNumber || 'N/A'}
              </div>
            </div>
            <div className="col-lg-3">
              <div className="selected-claim-label">EOB Date</div>
              <div className="selected-claim-value">
                {getFormattedDate(selectedClaimToImport.eobDate)}
              </div>
            </div>
            <div className="col-lg-1">
              <div className="remove-icon-container">
                <img
                  className="remove-icon"
                  alt="remove icon"
                  src={crossIcon}
                  onClick={resetSelectedValues}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="selected-claim-container">
        <div className="selected-claim manual-payer-id">
          <div>
            <div className="selected-claim-label">
              {isMedicalRecord ? 'Claim ID' : 'Payer Claim ID'}
            </div>
            <div className="selected-claim-value">{manualPayerId}</div>
          </div>
          <div className="remove-icon-container ml-24">
            <img
              className="remove-icon"
              alt="remove icon"
              src={crossIcon}
              onClick={resetSelectedValues}
            />
          </div>
        </div>
      </div>
    );
  };

  const handleSearchResultTitleClick = (type, claim) => {
    const { url, queryParam } = extractUrlAndQueryParam(type, searchClaimId);
    if (!url && !queryParam) return;
    props.actions.push(createURLWithParams(url, queryParam, true, '', true));
    props.closeCreateAppealDialog();
  };

  const handleInputChange = (e) => {
    if (!e.target.value) {
      setSearchClaimId('');
    }
    setSearchString(e.target.value);
    if (error) {
      setError(null);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchClaimId(searchString);
  };

  const extractCreateButtonTitle = () => {
    if (isMedicalRecord) {
      if (manualPayerId) {
        return `Create Record for Claim ID: ${manualPayerId}`;
      }

      return 'Create Record';
    }

    if (manualPayerId) {
      return `Create Appeal for Payer Claim ID: ${manualPayerId}`;
    }

    return 'Create Appeal';
  };

  return (
    <AppealioPopup
      title={popupTitle}
      className="create-appeal-popup"
      onClosePressed={props.closeCreateAppealDialog}
    >
      <div className="create-appeal-popup__form">
        <div className="row">
          <div className="col-lg-12">
            {selectedClaimToImport || manualPayerId ? (
              <React.Fragment>
                <SelectedValue />
              </React.Fragment>
            ) : (
              <Input
                type="text"
                placeholder="Search by Payer Claim ID, Claim ID, or Patient Name"
                className="custom-input"
                onChange={handleInputChange}
                value={searchString}
                handleSearchSubmit={handleSearchSubmit}
                disableEnterPress={loading || !searchString}
              />
            )}
          </div>
        </div>
        {searchString && debouncedSearchClaimId && isCreateBtnDisabled && (
          <SearchResultContainer
            data={data}
            loading={loading}
            searchTerm={debouncedSearchClaimId}
            className="search-result-container"
            handleTitleClick={handleSearchResultTitleClick}
            handleRowClick={handleRowClick}
            NoResultComponent={NoResultComponent}
            SearchListFooterComponent={SearchListFooterComponent}
            showSubmitterClaimId={isMedicalRecord}
          />
        )}
        {error && (
          <div className="row">
            <div className="col-lg-12 fs-14 font-style-italic color-red mt-12">
              <span>{error.message}</span>
              {has(error, 'payerClaimId') && (
                <span>
                  {' '}
                  Please go to{' '}
                  <span
                    className="link-color--blue cursor-pointer text-decoration-underline"
                    onClick={setSubmissionFilterAndRedirect}
                  >
                    {error.payerClaimId}
                  </span>
                </span>
              )}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-lg-12">
            <button
              className={classnames(
                'aplo-button create-appeal-btn aplo-button--block mt-24 mb-24',
                {
                  'aplo-button--disabled': isCreateBtnDisabled || error,
                }
              )}
              disabled={isCreateBtnDisabled || isCreatingAppeal || error}
              onClick={debouncedOnSubmit}
            >
              {!isCreatingAppeal ? (
                extractCreateButtonTitle()
              ) : (
                <div className="pt-10 pb-10">
                  <LoadingIndicator showing isLightIndicator />
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    </AppealioPopup>
  );
};

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);
  if (!userInfo) {
    return {};
  }

  return {
    userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { push, setAppealPreFillData, clearCurrentAppealData, setAppealData },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAppealModal);
