import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AppealNoteItem from './AppealNoteItem';

export default class AppealNoteItemList extends Component {
  static propTypes = {
    notes: PropTypes.array,
    charMax: PropTypes.number,
    hasTopBorder: PropTypes.bool,
  };

  render() {
    return (
      <div>
        {this.props.notes.map((note, index) => {
          return (
            <AppealNoteItem
              key={index}
              note={note}
              hasTopBorder={this.props.hasTopBorder}
              charMax={this.props.charMax}
            />
          );
        })}
      </div>
    );
  }
}
