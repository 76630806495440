import React from 'react';
import ReactTooltip from 'react-tooltip';
import { MdInfoOutline } from 'react-icons/md';

const Label = (props) => {
  const { label, name, dataTip } = props;
  return (
    <label className="ap-input-label" htmlFor={name}>
      {label}
      {dataTip && (
        <>
          <MdInfoOutline
            size="18"
            className="ml-4 ap-input-container__tooltip-icon"
            data-tip={dataTip}
            data-for={label}
            color="#5E6871"
          />
          <ReactTooltip
            effect="solid"
            place="top"
            id={label}
            multiline={true}
          />
        </>
      )}
    </label>
  );
};

export default Label;
