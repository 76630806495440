import { get } from 'lodash';

import {
  USER_ROLES,
  ONBOARDING,
  CLIENT_SERVICES,
  INTEGRATION_TYPE,
  CLIENT_CONFIG_KEYS,
} from '../constants/appConstants';

import { IS_DASHBOARD_ENABLED } from '../API/Config';

/**
 * Checks if user has provided client service.
 * @param {Object} user
 * @param {String} clientService
 * @returns {Boolean}
 */
const userHasClientService = (user, clientService) => {
  if (!user) return false;

  const clientServices = get(user, 'clientServices', []);

  return clientServices.includes(clientService);
};

/**
 * Checks if user has only one client service.
 * @param {Object} user
 * @returns {Boolean}
 */
export const userHasOnlyOneClientService = (user) => {
  if (!user) return false;

  const clientServices = get(user, 'clientServices', []);

  return clientServices.length === 1;
};

/**
 * Checks if user is authorized for denials page.
 * @param {Object} user
 * @returns {Boolean}
 */
export const isUserAuthorizedForDenialsPage = (user) => {
  if (!user) return false;

  const integrationType = user && user.integrationType;

  const isIntegrationTypeConfigured =
    Object.values(INTEGRATION_TYPE).includes(integrationType);

  const isClientAuthorized = userHasClientService(
    user,
    CLIENT_SERVICES.DENIAL_MANAGEMENT
  );

  return isIntegrationTypeConfigured && isClientAuthorized;
};

/**
 * Checks if user is authorized for teams/old dashboard.
 * @param {Object} user
 * @returns {Boolean}
 */
export const isUserAuthorizedForDashboard = (user) => {
  if (!user) return false;
  const isSuperUserAndStaffUser = user.isSuperuser && user.isStaff;

  return (
    IS_DASHBOARD_ENABLED &&
    (isSuperUserAndStaffUser || isClientAdminOrSupervisor(user.role))
  );
};

/**
 * Checks if user is authorized for analytics dashboard.
 * @param {Object} user
 * @returns {Boolean}
 */
export const isUserAuthorizedForAnalyticsDashboard = (user) =>
  userHasClientService(user, CLIENT_SERVICES.ANALYTICS);

/**
 * Checks if user is authorized for PCE.
 * @param {Object} user
 * @returns {Boolean}
 */
export const isUserAuthorizedForPCE = (user) =>
  userHasClientService(user, CLIENT_SERVICES.PCE);

export const isUserAuthorizedForRpaWidget = (user) =>
  userHasClientService(user, CLIENT_SERVICES.RPA);

/**
 * Checks if user is authorized for standalone submission.
 * @param {Object} user
 * @returns {Boolean}
 */
export const isUserAuthorizedForStandaloneSubmission = (user) =>
  userHasClientService(user, CLIENT_SERVICES.STANDALONE_SUBMISSION);

/**
 * Check if user is authorized for submission page.
 * @param {Object} user
 * @returns {Boolean}
 */
export const isUserAuthorizedForSubmission = (user) =>
  userHasClientService(user, CLIENT_SERVICES.APPEALS_RECORDS_SUBMISSIONS);

export const isUserAuthorizedForAnalytics = (user) => {
  if (!user) return false;

  const hasAnalyticsClientService = userHasClientService(
    user,
    CLIENT_SERVICES.ANALYTICS
  );
  if (!hasAnalyticsClientService) return false;

  // If user has only one client service, then he is authorized for analytics.
  if (userHasOnlyOneClientService(user)) return true;

  return isClientAdminOrSupervisor(user.role) && hasAnalyticsClientService;
};

export const isUserOnboarding = (userInfo) =>
  userInfo &&
  (userInfo.status === ONBOARDING || userInfo.clientStatus === ONBOARDING);

export const isUserAuthorizedCms1500Merge = (user) => {
  if (!user) return false;

  return userHasClientService(user, CLIENT_SERVICES.CMS_1500_MERGE);
};

export const isUserAuthorizedUB04Merge = (user) => {
  if (!user) return false;

  return userHasClientService(user, CLIENT_SERVICES.UB_04_MERGE);
};

/**
 * Check if user is standalone only user.
 * @param {Object} user
 * @returns {Boolean}
 */
export const isAppealioExpressUser = (user) => {
  const clientServices = get(user, 'clientServices', []);

  return (
    clientServices.length === 1 &&
    userHasClientService(user, CLIENT_SERVICES.STANDALONE_SUBMISSION)
  );
};

/**
 * Check if user is analytics only user.
 * @param {Object} user
 */
export const isAnalyticsOnlyUser = (user) => {
  const clientServices = get(user, 'clientServices', []);
  return (
    clientServices.length === 1 &&
    userHasClientService(user, CLIENT_SERVICES.ANALYTICS)
  );
};

/**
 * Check if user is authorized for client admin or supervisor.
 * @param {Number} userRole
 * @returns {Boolean}
 */
export const isClientAdminOrSupervisor = (userRole) =>
  [USER_ROLES.CLIENT_ADMIN, USER_ROLES.CLIENT_SUPERVISOR].includes(userRole);

/**
 * Check if user is client admin.
 * @param {Object} userRole
 * @returns {Boolean}
 */
export const isClientAdmin = (userInfo) =>
  userInfo?.role === USER_ROLES.CLIENT_ADMIN;

/**
 * Check if user is client agent.
 * @param {Object} userRole
 * @returns {Boolean}
 */
export const isClientAgent = (userInfo) =>
  userInfo?.role === USER_ROLES.CLIENT_USER;

/**
 * Check if user can assign agent.
 * @param {Object} userInfo
 * @returns {Boolean}
 */
export const canAssignAgent = (userInfo) => {
  if (!userInfo) return false;

  return (
    isClientAdminOrSupervisor(userInfo.role) ||
    userInfo?.clientConfig?.[
      CLIENT_CONFIG_KEYS.DD_AGENT_ASSIGNMENT_BY_AGENT
    ] === true
  );
};

/**
 * Check if user is authorized for denial analytics.
 * @param {object} userInfo
 * @returns {Boolean}
 */
export const isAuthorizedForDenialAnalytics = (userInfo) =>
  userInfo?.clientConfig?.[CLIENT_CONFIG_KEYS.DQ_ANALYTICS] === true;

export const isAuthorizedForPracticeGroup = (userInfo) =>
  userInfo?.clientConfig?.[CLIENT_CONFIG_KEYS.PRACTICE_GROUP] === true;

export const isAuthorizedForEmailSubmission = (userInfo) =>
  userInfo?.clientConfig?.[CLIENT_CONFIG_KEYS.SUBMIT_PACKAGE_VIA_EMAIL] ===
  true;

export const isAuthorizedForTeams = (userInfo) =>
  userInfo?.clientConfig?.[CLIENT_CONFIG_KEYS.TEAMS] === true &&
  isClientAdminOrSupervisor(userInfo.role);

/**
 * Check if user is authorized for merge proof of submission.
 * @param {Object} userInfo
 * @returns {Boolean}
 */
export const isAuthorizedForMergeProofOfSubmission = (userInfo) =>
  userInfo?.clientConfig?.[CLIENT_CONFIG_KEYS.MERGE_PROOF_OF_SUBMISSION] ===
  true;

/**
 * Check if user is staff and superuser
 * @param {object} userInfo
 * @returns {Boolean}
 */
export const isSuperUserOrStaffUser = (userInfo) => {
  if (!userInfo) return false;

  return userInfo.isSuperuser || userInfo.isStaff;
};

/**
 * Check if user is authorized for delivery tracker.
 * @param {object} userInfo
 * @returns {Boolean}
 */
export const isAuthorizedForDeliveryTracker = (userInfo) =>
  isUserAuthorizedForSubmission(userInfo) || isAppealioExpressUser(userInfo);

/**
 * Check if user is staff and superuser
 * @param {object} userInfo
 * @returns {Boolean}
 */
export const isSuperUserAndStaffUser = (userInfo) => {
  if (!userInfo) return false;

  return userInfo.isSuperuser && userInfo.isStaff;
};
