import React from 'react';
import classnames from 'classnames';

import './style.css';

const DotAnimation = (props) => {
  return (
    <div className={classnames('animation-container', props?.className)}>
      <div className="dot-container">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
};

export default DotAnimation;
