import { useEffect, useState } from 'react';

import CreateAppealAPI from 'API/CreateAppealAPI';

/**
 * Use fetch payers hook.
 * @returns {Promise}
 */
export const useFetchPayers = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [payers, setPayers] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetch = async () => {
      try {
        const data = await CreateAppealAPI.getDropdownOptions('payers');

        setPayers(CreateAppealAPI.parsePayerData(data));
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    };

    fetch();
  }, []);

  return { loading, error, payers };
};

/**
 * Use fetch appeal templates hook.
 * @returns {Promise}
 */
export const useFetchAppealTemplates = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [appealTemplates, setAppealTemplates] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetch = async () => {
      try {
        const { data } = await CreateAppealAPI.getDropdownOptions(
          'appeal_templates'
        );

        setAppealTemplates(
          data.map(({ id, attributes }) => ({ id, ...attributes }))
        );
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    };

    fetch();
  }, []);

  return { loading, error, appealTemplates };
};
