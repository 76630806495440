import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class OneLineInfo extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className="row tabbar__row-no-padding tabbar__line">
        <div className="col-xs-2 tabbar__row-title">{this.props.title}:</div>
        <div className="col-xs-offset-1 col-xs-8 tabbar__row-text">
          {this.props.value}
        </div>
      </div>
    );
  }
}
