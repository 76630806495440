import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MdAutorenew } from 'react-icons/md';

import './style.css';

const AutoFillButton = (props) => {
  return (
    <span
      className={classnames('auto-fill-btn', props.className)}
      onClick={props.onClick}
      datacy={props.datacy}
    >
      <MdAutorenew className="mr-4" /> {props.title}
    </span>
  );
};

AutoFillButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default AutoFillButton;
