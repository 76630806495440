import React, { useState } from 'react';

import './style.css';

import errorAnimation from '../UhcClaimStatusCheck/errorAnimation.json';

import { useFetchClaimStatusInitialData } from './hook';

import ErrorPopup from './ErrorPopup';
import AvailityForm from './AvailityForm';
import SuccessModal from './SuccessModal/SuccessModal';

const AvailityClaimStatusCheck = (props) => {
  const { onCloseClick } = props;
  const {
    isLoading,
    error,
    payerInputFields,
    prefillData,
    availityEdiPayerId,
  } = useFetchClaimStatusInitialData(
    props?.data?.ediPayerId,
    props?.data?.claimControlNumber,
    props?.data?.appealId
  );
  const [claimStatusCheckResult, setClaimStatusCheckResult] = useState(null);
  const [requestData, setRequestData] = useState(null);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorPopupMessage, setErrorPopupMessage] = useState(null);

  const onClaimStatusCheckSuccess = (result, requestData) => {
    setClaimStatusCheckResult(result);
    setRequestData(requestData);
  };

  if (showErrorPopup) {
    return (
      <ErrorPopup
        message={
          errorPopupMessage ||
          `Availity is unable to find a claim associated with Claim ID: ${props?.data?.claimControlNumber}.`
        }
        animationData={errorAnimation}
        onCloseClick={() =>
          setShowErrorPopup(false) && setErrorPopupMessage(null)
        }
      />
    );
  }

  if (claimStatusCheckResult) {
    if (String(claimStatusCheckResult?.statusCode) === '0') {
      return (
        <ErrorPopup
          message="Availity is communicating with the health plan. Check back in a few seconds."
          animationData={errorAnimation}
          onCloseClick={props.onCloseClick}
        />
      );
    }

    return (
      <SuccessModal
        claimInfo={claimStatusCheckResult}
        onCloseClick={onCloseClick}
        requestData={requestData}
        setClaimInfo={setClaimStatusCheckResult}
      />
    );
  }

  if (!isLoading && !error) {
    return (
      <AvailityForm
        onCloseClick={onCloseClick}
        ediPayerId={availityEdiPayerId}
        prefillData={prefillData}
        payerInputFields={payerInputFields}
        claimControlNumber={props?.data?.claimControlNumber}
        onClaimStatusCheckSuccess={onClaimStatusCheckSuccess}
        onClaimStatusCheckError={(message = null) => {
          setErrorPopupMessage(message);
          setShowErrorPopup(true);
        }}
      />
    );
  }

  return (
    <ErrorPopup
      message={isLoading ? 'Preparing Claim Status Check form' : null}
      onCloseClick={props.onCloseClick}
    />
  );
};

AvailityClaimStatusCheck.propTypes = {};

export default AvailityClaimStatusCheck;
