import {
  handleErrors,
  handleJSONParse,
  createRequest,
  createGetRequest,
  BASE_API_URL,
} from './Config';

import { camelizeKeys, snakeCaseKeys } from '../helpers/object';

import * as endpoints from '../constants/endpoints';

export const createSubmitPackageAPI = async (data) => {
  const url = `${endpoints.SUBMIT_PACKAGE}/`;
  const request = createRequest(url, data, {
    method: 'POST',
  });

  const res = await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
  let type = '';
  if (res.data.appeal !== null) {
    type = 'appeal';
  } else {
    type = 'submissionPackage';
  }
  const result = {
    id: res.data.id,
    type,
  };
  return result;
};

/**
 * @param {object} requestBody
 * @param {Number} submissionId
 * @returns promise
 */

export const createTagsWithPageCount = (requestBody, submissionId) => {
  const url = `${endpoints.SUBMIT_PACKAGE}/${submissionId}`;
  const request = createRequest(url, snakeCaseKeys(requestBody), {
    method: 'PUT',
  });
  return fetch(request).then(handleErrors);
};

/**
 * fetch the submission reasons
 *
 * @returns promise
 */
export const fetchSubmissionReasons = () => {
  const request = createGetRequest(endpoints.SUBMISSION_REASONS, BASE_API_URL);

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};
