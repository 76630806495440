import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LinkList from './LinkList';

import downloadIcon from '../../img/download.svg';
import { APIConfig, secureDownload } from '../../API/Config';

export default class LettersAndDocumentList extends Component {
  static propTypes = {
    formattedDocuments: PropTypes.array,
    formattedLetters: PropTypes.array,
    appealId: PropTypes.string,
  };

  static defaultProps = {
    formattedLetters: [],
    formattedDocuments: [],
  };

  render() {
    return (
      <div
        className="appeal-history-card__filesection"
        datacy="letters-and-document-list"
      >
        <div className="appeal-history-card__files">
          <div className="appeal-history-card__files-docs">
            <div className="appeal-history-card__sectiontitle">Documents</div>
            <LinkList
              listClassName="appeal-history-card__files-list"
              elementClassName="appeal-history-card__files-list-item"
              links={this.props.formattedDocuments}
              datacy="documents-LinkList"
            />
          </div>
          <div className="appeal-history-card__files-letters">
            <div className="appeal-history-card__sectiontitle">Letters</div>
            <LinkList
              listClassName="appeal-history-card__files-list"
              elementClassName="appeal-history-card__files-list-item"
              links={this.props.formattedLetters}
              datacy="letters-LinkList"
            />
          </div>
          <div className="appeal-history-card__downloadbtn">
            <img
              alt="Download Icon"
              className="appeal-history-card__downloadbtn-img"
              src={downloadIcon}
            />
            <span
              className="appeal-history-card__downloadbtn-text"
              onClick={() =>
                secureDownload(
                  `appeals/${this.props.appealId}/attachments.zip/`,
                  APIConfig.processParams(),
                  'attachments.zip'
                )
              }
              datacy="letters-and-document-list-download-link"
            >
              Download all documents
            </span>
          </div>
        </div>
      </div>
    );
  }
}
