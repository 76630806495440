import React from 'react';

import './style.css';

const LoadingBar = (props) => {
  if (!props.showing) {
    return null;
  }
  return (
    <div className="loading-bar-container" datacy="loading-bar-div">
      <div className="progress">
        <div className="progress-value"></div>
      </div>
      {props.children}
    </div>
  );
};

export default LoadingBar;
