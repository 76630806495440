import React, { Component } from 'react';
import CardView from '../Shared/CardView';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingBlockHelper from '../Shared/LoadingBlockHelper';
import SubmitButton from '../Shared/SubmitButton';
import { sendForgotPasswordEmail } from '../../redux/actions/loginActions';
import emailSentIcon from '../../img/verify.svg';

export class ForgotPasswordEmailSent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.emailSentTo == null) {
      this.props.router.replace('/login');
    }
  }

  render() {
    return (
      <div className="login">
        <CardView>
          <div className="login__header">
            <img
              alt="Request Processed"
              className="login__header-logo"
              src={emailSentIcon}
            />
            <h2 datacy="forgot-password-processed-header">
              Request Processed.
            </h2>
          </div>

          <div className="login__subheader">
            <strong datacy="forgot-password-processed-description">
              An email with a password recovery link has been sent to{' '}
              {this.props.emailSentTo}
            </strong>
          </div>

          <div className="submit__button-wrapper">
            <LoadingBlockHelper isLoading={this.state.isLoading}>
              <SubmitButton
                className="submit__button submit__button--fullwidth"
                title="Resend Link"
                onClick={this.onResend}
                datacy="resend-link-SubmitButton"
              />
            </LoadingBlockHelper>
          </div>
        </CardView>
      </div>
    );
  }

  onResend = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.props.actions
          .sendForgotPasswordEmail(this.props.emailSentTo)
          .then((res) => {
            this.setState({
              isLoading: false,
            });
          })
          .catch((e) => {
            this.setState({
              isLoading: false,
            });
          });
      }
    );
  };
}

function mapStateToProps(state) {
  const forgotPasswordStore = state.get('forgotPasswordStore');
  return {
    emailSentTo: forgotPasswordStore.get('emailSentTo'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ sendForgotPasswordEmail }, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordEmailSent)
);
