import React from 'react';
import { Field } from 'redux-form/immutable';

import { MASK_PHONE } from 'helpers/masks';
import Input from 'components/common/input';
import { renderReduxTextarea } from 'components/CreateAppeal/reduxTextarea';

import './style.css';

const FaxFormFields = () => (
  <div>
    <div className="mb-10">
      <Field
        name="faxNumber"
        component={Input}
        label="Fax Number: *"
        placeholder="(DDD) DDD-DDDD"
        mask={MASK_PHONE}
        maskedInput
      />
    </div>
    <div className="create-appeal__separator"></div>
    <br />
    <h5>Cover Page details</h5>
    <br />
    <div className="mb-10">
      <Field name="from" component={Input} label="From: *" />
    </div>
    <div className="mb-10">
      <Field name="to" component={Input} label="To: *" />
    </div>
    <div className="mb-10">
      <Field
        name="phone"
        component={Input}
        label="Phone: *"
        placeholder="(DDD) DDD-DDDD"
        mask={MASK_PHONE}
        maskedInput
      />
    </div>
    <div className="mb-10">
      <Field name="re" component={Input} label="Re:" />
    </div>
    <div className="mb-10">
      <Field name="cc" component={Input} label="CC:" />
    </div>
    <div className="mb-10">
      <Field
        name="coverLetterText"
        component={renderReduxTextarea}
        className="fax-form-field__cover-letter"
        textAreaClassName="resize-vertical"
        label="Additional Cover Page Text:"
      />
    </div>
  </div>
);

export default FaxFormFields;
