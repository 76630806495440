import { withoutAttrs } from 'helpers/object';
import { extractInitialFormValues } from '../ImportRule/utils';

export const extractInitialFormValuesForSop = (data, dropdownOptions) => {
  const sopInitialValues = extractInitialFormValues(data, dropdownOptions);
  const steps = data.steps;

  const serializedSteps = steps.map((step) => ({
    id: step.id,
    action: {
      value: step.action.id,
      label: step.action.description,
    },
    role: {
      label: step.role.value,
      value: step.role.value,
    },
    instruction: step.instruction,
  }));

  const serializedData = {
    ...data,
    id: data.id,
    ruleId: data.ruleId,
    steps: serializedSteps,
    ...withoutAttrs(sopInitialValues, ['effectiveDate', 'status', 'agents']),
  };

  return serializedData;
};
