import React from 'react';

import Contacts from './Contacts';
import { CONTACT_TYPE } from 'constants/appConstants';

const DestinationContact = (props) => {
  return <Contacts type={CONTACT_TYPE.DESTINATION_ADDRESS} />;
};

export default DestinationContact;
