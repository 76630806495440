import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { GrCircleInformation } from 'react-icons/gr';

import PracticeListPopup from '../../PracticeGroup/PracticeListPopup';

import { OPTION_TYPES } from './constant';

import { addTypeToOptions } from './utils';
import CustomDropdown from 'components/common/dropdown/ButtonDropdown/CustomDropdown';

const PracticeSelectWithCustomDropdown = ({
  input = {},
  practiceGroupOptions,
  practiceOptions,
  isDisabled = false,
  isExclusionDropdown = false,
  placeholder = 'All practices (by default)',
  ...rest
}) => {
  const [showPracticeList, setShowPracticeList] = useState({
    isOpen: false,
    practiceGroupId: null,
    practiceName: null,
  });

  const groupedOptions = [
    {
      label: 'Practice Groups',
      options: addTypeToOptions(
        practiceGroupOptions,
        OPTION_TYPES.PRACTICE_GROUP
      ),
    },
    {
      label: 'Practices',
      options: addTypeToOptions(practiceOptions, OPTION_TYPES.PRACTICE),
    },
  ].filter(
    (group) =>
      !(
        (group.label === 'Practice Groups' && group.options.length < 1) ||
        (group.label === 'Practices' && group.options.length < 1)
      )
  );

  const handleOpenPracticeGroupPopup = (practiceName, practiceGroupId) => {
    setShowPracticeList({
      isOpen: true,
      practiceGroupId,
      practiceName,
    });
  };

  const PracticeGroupContent = ({ label, value }) => {
    const handleOpenPracticeGroup = (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleOpenPracticeGroupPopup(label, value);
    };
    return (
      <div className="d-flex align-items-center justify-content-between">
        <span>{label}</span>
        <div
          className="d-flex align-items-center px-2 py-2 cursor-pointer"
          onClick={handleOpenPracticeGroup}
          data-tip="Click here to view Practice(s)"
          data-for="practice-group-content-tooltip"
        >
          <GrCircleInformation />
        </div>
        {!isDisabled && (
          <ReactTooltip
            id="practice-group-content-tooltip"
            effect="solid"
            place="top"
          />
        )}
      </div>
    );
  };

  const Option = ({ item }) => {
    return (
      <div>
        {item?.type === OPTION_TYPES.PRACTICE_GROUP ? (
          <PracticeGroupContent label={item.label} value={item.value} />
        ) : (
          item.label
        )}
      </div>
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      <CustomDropdown
        options={groupedOptions}
        {...rest}
        isMulti
        isDisabled={isDisabled}
        components={{ Option }}
        placeholder={placeholder}
        input={input}
      />

      {showPracticeList.isOpen && (
        <PracticeListPopup
          practiceGroupId={showPracticeList.practiceGroupId}
          title={showPracticeList.practiceName}
          onClosePressed={() => {
            setShowPracticeList({
              isOpen: false,
              practiceGroupId: null,
              practiceName: null,
            });
          }}
        />
      )}
    </div>
  );
};

export default PracticeSelectWithCustomDropdown;
