import React from 'react';
import { Field } from 'redux-form/immutable';

import { renderField } from '../../Shared/textfield';
import { MASK_DATE } from '../../../helpers/masks';
import { renderReduxMaskedInput } from '../reduxMaskedInput';

const PatientStandaloneForm = () => {
  return (
    <div>
      <div className="row mt-20 mb-12">
        <h5 className="mb-12">Patient Information</h5>
        <div className="col-xs-4">
          <div className="col-xs-11">
            <Field
              label="First Name:"
              name="firstName"
              maxLength="50"
              component={renderField}
            />
          </div>
        </div>
        <div className="col-xs-4">
          <div className="col-xs-11">
            <Field
              label="Last Name:"
              name="lastName"
              maxLength="50"
              component={renderField}
            />
          </div>
        </div>
        <div className="col-xs-4">
          <Field
            label="Date of Birth:"
            name="dob"
            size="4"
            type="string"
            mask={MASK_DATE}
            placeholder="MM/DD/YYYY"
            component={renderReduxMaskedInput}
          />
        </div>
      </div>

      <div className="row mt-20 mb-12">
        <h5 className="">Service Date</h5>
        <div className="col-xs-6">
          <div className="col-xs-11">
            <Field
              label="Start Date:"
              name="serviceStartDate"
              type="string"
              mask={MASK_DATE}
              placeholder="MM/DD/YYYY"
              component={renderReduxMaskedInput}
            />
          </div>
        </div>
        <div className="col-xs-6">
          <Field
            label="End Date:"
            name="serviceEndDate"
            type="string"
            mask={MASK_DATE}
            placeholder="MM/DD/YYYY"
            component={renderReduxMaskedInput}
          />
        </div>
      </div>
    </div>
  );
};

export default PatientStandaloneForm;
