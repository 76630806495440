import React from 'react';
import ReactTooltip from 'react-tooltip';
import { BsPencil } from 'react-icons/bs';

import TextHighlighter from 'components/common/textHighlighter';
import ActionButton from 'components/common/actionButton/ActionButton';
import AutoReminderToggle from './AutoReminderToggle/AutoReminderToggle';

import { SEARCH_QUERY_CHARACTERS_OFFSET } from '../constants';

const DenialActionItem = ({
  action,
  search,
  isAdmin,
  onEdit,
  onAutoReminderToggle,
  handlePreview,
}) => (
  <div className="create-account-list-item row mb-16">
    <div className="col-lg-3">
      <span data-tip={action.description}>
        {action.description &&
        search?.length >= SEARCH_QUERY_CHARACTERS_OFFSET ? (
          <TextHighlighter text={action.description} query={search} />
        ) : (
          <span>{action.description || '--'}</span>
        )}
      </span>
    </div>
    <div className="col-lg-3">
      <div className="d-flex">
        <AutoReminderToggle
          checked={action?.autoReminderConfig?.isEnabled === true}
          handleToggle={() => {
            onAutoReminderToggle(action);
          }}
        />
        <span className="ml-40">
          {action?.autoReminderConfig?.daysUntilReminder
            ? `${action?.autoReminderConfig?.daysUntilReminder} day(s)`
            : '-'}
        </span>
      </div>
    </div>
    <div className="col-lg-3">
      <span
        data-tip="test"
        {...(!action?.autoReminderConfig?.reminderNote
          ? {}
          : { 'data-tip': action?.autoReminderConfig?.reminderNote })}
      >
        <span> {action?.autoReminderConfig?.reminderNote || '-'}</span>
      </span>
    </div>
    <div className="col-lg-2">
      {action?.pmNoteTemplateConfigData?.template ? (
        <button
          className="ap-button text-right ap-button--link btn--link"
          onClick={() => {
            const templatePreviewContent = {
              name: action.description,
              template: action?.pmNoteTemplateConfigData?.template,
            };
            handlePreview(templatePreviewContent);
          }}
        >
          Preview
        </button>
      ) : (
        '-'
      )}
    </div>
    {isAdmin && (
      <div className="col-lg-1 d-flex justify-content-flex-end">
        <ActionButton
          Icon={BsPencil}
          className="mr-16 ml-auto"
          dataTip="Edit"
          onClick={onEdit}
          iconClassName="appeal__action--appeal"
        />
      </div>
    )}
    <ReactTooltip effect="solid" place="top" multiline={true} />
  </div>
);

export default DenialActionItem;
