import PropTypes from 'prop-types';
import React, { useState } from 'react';

import './style.css';

import Select from 'components/common/select/Select';
import SearchInput from 'components/common/input/SearchInput';

const SearchWithOption = (props) => {
  const { options, onSearch, placeholder = '', handleReset } = props;
  const defaultOption =
    options.find((option) => option.isDefault) || options[0];
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const [isOptionChanged, setIsOptionChanged] = useState(false);

  const handleSearch = (e) => {
    const searchString = e.target.value.trim();
    onSearch({
      query: searchString,
      type: selectedOption.value,
    });
  };

  const clearSearch = () => {
    onSearch({
      query: '',
      type: selectedOption.value,
    });
    setIsOptionChanged(false);
    if (handleReset) {
      handleReset();
    }
  };

  const handleOnChange = (selectedOption) => {
    setSelectedOption(
      options.find((option) => option.value === selectedOption.value)
    );
    setIsOptionChanged(true);
  };

  return (
    <div className="search-with-option">
      <Select
        options={options}
        value={selectedOption}
        className="search-with-option__dropdown"
        onChange={handleOnChange}
      />
      <SearchInput
        onSearch={handleSearch}
        placeholder={placeholder}
        onReset={clearSearch}
        isOptionChanged={isOptionChanged}
      />
    </div>
  );
};

SearchWithOption.propTypes = {
  options: PropTypes.array.isRequired,
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default SearchWithOption;
