import {
  handleErrors,
  handleJSONParse,
  createRequest as createPostRequest,
  INTEGRATION_API_BASE_URL,
} from './Config';

import { camelizeKeys, snakeCaseKeys } from 'helpers/object';

import * as endpoints from 'constants/endpoints';

/**
 * Fetch DenialsReminders counts
 * @param {Object} data
 * @returns {Promise}
 */
export const fetchDenialsReminders = (payload) => {
  const request = createPostRequest(
    endpoints.DENIALS_REMINDERS,
    snakeCaseKeys(payload),
    {
      baseUrl: INTEGRATION_API_BASE_URL,
      method: 'POST',
    }
  );
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};
