import React from 'react';

import './style.css';

const Counter = ({ value = 0, className = null }) => {
  return (
    <div className={`counter ${className}`} datacy="counter-div">
      {value}
    </div>
  );
};

export default Counter;
