import { snakeCase } from 'lodash';
import { useEffect, useState } from 'react';

import { snakeCaseKeys } from 'helpers/object';

import { searchCodes } from 'API/CodesAPI';
import * as AnalyticsDashboardAPI from 'API/AnalyticsDashboardAPI';

export const useFetchDenialsAndUnderpayments = (filters, viewChartBy) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [denialsAndUnderpayments, setDenialsAndUnderpayments] = useState({});

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      setDenialsAndUnderpayments({});
      setError(null);
      try {
        const queryParam = {
          ...snakeCaseKeys({ ...filters }),
          ...(viewChartBy && { dau: snakeCase(viewChartBy) }),
        };

        const data = await AnalyticsDashboardAPI.fetchDenialsReasonsByCategory(
          queryParam
        );
        setDenialsAndUnderpayments(data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };
    fetch();
    // eslint-disable-next-line
  }, [filters, viewChartBy]);

  return { loading, error, denialsAndUnderpayments, fetch };
};

/**
 * Use fetch codes descriptions hook for reason codes.
 */
export const useFetchCodesDescriptions = (reasonCodes) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    carcs: [],
  });

  useEffect(() => {
    setLoading(true);

    const fetch = async () => {
      try {
        const { data } = await searchCodes({
          carcs: reasonCodes || [],
        });

        const carcs = data.carcs;

        setData({
          carcs,
        });
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    };

    fetch();
    // eslint-disable-next-line
  }, []);

  return { loading, error, data };
};
