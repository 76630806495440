import { useCallback, useEffect, useState } from 'react';
import { isEmpty, identity, pickBy, snakeCase } from 'lodash';

import AppealsAPI from 'API/AppealsAPI';
import { handleError } from 'helpers/errorHandler';

import { IN_PROGRESS_STATUS } from './constant';

export const useFetchInProgressSubmissions = (sortBy, filters, page) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [totalSubmissions, setTotalSubmissions] = useState(0);
  const [inProgressSubmissions, setInProgressSubmissions] = useState([]);

  const fetch = useCallback(async () => {
    setLoading(true);
    setInProgressSubmissions([]);
    setTotalSubmissions(0);

    try {
      const queryParam = {
        ...filters,
        status: IN_PROGRESS_STATUS,
      };

      if (!isEmpty(sortBy)) {
        queryParam.ordering = sortBy.desc
          ? `-${snakeCase(sortBy.id)}`
          : snakeCase(sortBy.id);
      }

      const { data, totalRows } = await AppealsAPI.getInProgressSubmissions(
        page,
        pickBy(queryParam, identity)
      );

      const tableData = data.map((data) => {
        const [appeal] = data.appeals;

        return {
          ...appeal,
        };
      });

      setInProgressSubmissions(tableData);
      setTotalSubmissions(totalRows);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [sortBy, filters, page]);

  useEffect(() => {
    fetch();
  }, [sortBy, filters, page, fetch]);

  return { loading, error, inProgressSubmissions, totalSubmissions, fetch };
};

/**
 * Fetches and returns medical records dropdown options counts based on the given filters.
 *
 * @param {object} filters - The filters to apply when fetching the options counts
 * @return {object}
 */
export const useFetchInProgressOptionsCounts = (filters) => {
  const [inProgressDropdownWithCounts, setInProgressDropdownWithCounts] =
    useState([]);

  const fetch = useCallback(async () => {
    try {
      const {
        payerCounts,
        providerCounts,
        agentCounts,
        practiceCounts,
        appealsCount,
        recordsCount,
      } = await AppealsAPI.getInProgressFilterOptionsCount(
        pickBy(
          {
            ...filters,
            status: IN_PROGRESS_STATUS,
          },
          identity
        )
      );
      setInProgressDropdownWithCounts({
        payerCounts,
        providerCounts,
        agentCounts,
        practiceCounts,
        appealsCount,
        recordsCount,
      });
    } catch (error) {
      handleError(error);
    }
  }, [filters]);

  useEffect(() => {
    fetch();
  }, [fetch, filters]);

  return { inProgressDropdownWithCounts };
};
