import React from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { pickBy, identity } from 'lodash';
import ReactDOMServer from 'react-dom/server';

import { camelizeKeys } from 'helpers/object';

import DvVerifiedIcon from 'components/common/dvVerified';
import { renderReduxDropdownV2 as Dropdown } from '../reduxReactSelect';
import { UHC_DIRECT_TO_PAYER } from 'constants/appConstants';

export const DataTipContent = (payerContact) => {
  const mappedPayerContact = {
    FAX: payerContact?.dvVerifiedFax ? (
      <div className="ml-2">
        <span className="text-color--green">{payerContact.fax}</span>{' '}
        <DvVerifiedIcon className="dv-verified--tooltip" />
      </div>
    ) : (
      payerContact.fax
    ),
    Address: payerContact?.dvVerifiedMail ? (
      <div className="ml-2">
        <span className="text-color--green">{payerContact.address1}</span>
        <DvVerifiedIcon className="dv-verified--tooltip" />
      </div>
    ) : (
      payerContact.address1
    ),
    City: payerContact.city,
    State: payerContact.state,
    ZIP: payerContact.zipcode,
    'Payer Portal URL': payerContact.payerPortalUrl,
    Email: payerContact?.email,
  };

  const payerContactInfo = pickBy(mappedPayerContact, identity);
  if (!Object.keys(payerContactInfo).length) return null;

  return (
    <div>
      {Object.entries(payerContactInfo).map(([key, value], idx) => (
        <div key={idx} className="d-flex align-items-center p-3">
          <strong>{key}</strong>: {value}
        </div>
      ))}
    </div>
  );
};

const SelectOption = ({ label, data }) => {
  const dataTipContent =
    data?.abbr === UHC_DIRECT_TO_PAYER.ABBR
      ? null
      : ReactDOMServer.renderToStaticMarkup(DataTipContent(camelizeKeys(data)));

  const isOptionVerified = data?.dvVerifiedMail || data?.dvVerifiedFax;
  const dataTipId = `selection-option-${data.id}`;

  return (
    <div
      className={classnames({
        'position-relative d-flex align-items-center justify-content--space-between':
          isOptionVerified,
      })}
    >
      <span data-tip={dataTipContent} data-html={true} data-for={dataTipId}>
        {label}
      </span>
      {isOptionVerified ? (
        <DvVerifiedIcon className="dv-verified-field dv-verified-field--select z-index-1" />
      ) : null}
      <ReactTooltip id={dataTipId} effect="solid" place="top" />
    </div>
  );
};

const PayerContactDropdown = (props) => {
  const payerContactInfo = camelizeKeys(props?.selectedPayerContact?.data);
  return (
    <React.Fragment>
      <Dropdown
        {...props}
        formatOptionLabel={SelectOption}
        reactSelectProps={{
          classNamePrefix: 'custom-tooltip__dropdown',
        }}
        {...(props.selectedPayerContact &&
        !props.isDisabled &&
        !(payerContactInfo?.dvVerifiedFax || payerContactInfo?.dvVerifiedMail)
          ? {
              dataTip: ReactDOMServer.renderToStaticMarkup(
                DataTipContent(payerContactInfo)
              ),
            }
          : {})}
      />
    </React.Fragment>
  );
};

export default PayerContactDropdown;
