import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

import openLockIcon from 'img/open-lock-icon-1.svg';
import closeLockIcon from 'img/close-lock-icon.svg';

import './style.css';

const LockFilterButton = (props) => {
  const { isLocked, onClick, className } = props;
  const dataTip = isLocked
    ? 'Unlock Filters'
    : 'Lock Filters: Keep your filters from resetting when you return to this page.';

  return (
    <>
      <button
        onClick={onClick}
        data-for="lock-filter-button"
        data-tip={dataTip}
        className={classnames('lock-filter-button', className)}
      >
        {isLocked ? <img src={closeLockIcon} /> : <img src={openLockIcon} />}
      </button>
      <ReactTooltip id="lock-filter-button" effect="solid" />
    </>
  );
};

LockFilterButton.propTypes = {
  isLocked: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default LockFilterButton;
