import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm, SubmissionError } from 'redux-form/immutable';

import Input from 'components/common/input';
import AppealioPopup from '../Shared/AppealioPopup';
import LoadingIndicator from 'components/Shared/LoadingIndicator';

import { handleError } from 'helpers/errorHandler';
import { validatePayerClaimId } from 'helpers/validators';

import { updateManualClaim } from 'API/ManualClaimAPI';

let MissingManualClaimInfo = (props) => {
  const {
    claimInfo,
    onClosePressed,
    handleSubmit,
    pristine,
    submitting,
    applyManualClaim,
  } = props;
  const {
    manualClaimId,
    isMissingClaimNumber,
    isMissingClaimControlNumber,
    isMedicalRecord,
  } = claimInfo;

  const popupTitle = isMissingClaimNumber
    ? 'Missing Claim ID'
    : ' Missing Payer Claim ID';
  const label = isMissingClaimNumber ? 'Claim ID' : 'Payer Claim ID';
  const btnLabel = isMissingClaimNumber ? 'Add Claim ID' : 'Add Payer Claim ID';

  const validate = (values) => {
    const errors = {};
    const errorMessage = {
      claimNumber: 'Claim ID is required',
      claimControlNumber: 'Payer Claim ID is required',
    };

    const requiredFields = ['claimId'];

    requiredFields.forEach((field) => {
      if (!values.get(field)) {
        const message = isMissingClaimNumber
          ? errorMessage.claimNumber
          : errorMessage.claimControlNumber;
        errors[field] = message;
      }
    });

    const claimId = values.get('claimId');
    const invalidClaimIdMessage = isMissingClaimNumber
      ? 'Claim ID should only contain "-" , letters or numbers.'
      : 'Payer Claim ID should only contain "-" , letters or numbers.';

    const invalidValidClaimId = validatePayerClaimId(
      claimId,
      invalidClaimIdMessage
    );

    if (invalidValidClaimId) {
      errors.claimId = invalidValidClaimId;
    }

    if (Object.keys(errors).length) {
      throw new SubmissionError(errors);
    }
  };

  const handleAddClaimError = async (error) => {
    const errorResponse = error.response;
    if (errorResponse.status === 400) {
      const response = await error.response.json();
      if (response.claim_number || response.claim_control_number) {
        return Promise.reject(
          new SubmissionError({
            ...(response.claim_number && {
              claimId: 'Claim ID already exists in Appealio.',
            }),
            ...(response.claim_control_number && {
              claimId: 'Payer Claim ID already exists in Appealio.',
            }),
          })
        );
      }
    }
    handleError(error);
  };

  const handleFormSubmit = async (formValues) => {
    const values = formValues.toJS();
    validate(formValues);
    let formData = {};

    formData = {
      ...(isMissingClaimNumber && {
        claimNumber: values.claimId,
      }),
      ...(isMissingClaimControlNumber && {
        claimControlNumber: values.claimId,
      }),
    };

    try {
      await updateManualClaim(formData, manualClaimId);
      await applyManualClaim(manualClaimId, values.claimId, {
        isMedicalRecord,
      });
      onClosePressed();
    } catch (error) {
      return handleAddClaimError(error);
    }
  };

  return (
    <AppealioPopup
      title={popupTitle}
      className="missing-payer-popup"
      onClosePressed={onClosePressed}
      datacy="missing-claim-info"
    >
      <div className="assign-manual-claim-info">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="row no-gutter">
            <div className="col-xs-12">
              <Field
                label={label}
                name="claimId"
                required={true}
                component={Input}
                datacy="claimId-field"
              />
            </div>
          </div>

          <div className="row no-gutter assign-manual-claim-info__button-wrapper">
            <div className="col-xs-12">
              <button
                type="submit"
                className={classnames('ap-button ap-button--secondary', {
                  'add-payer-submit-button--disabled': pristine,
                })}
                disabled={pristine}
                datacy="missing-payer-popup-submit-button"
              >
                {submitting ? (
                  <div>
                    <LoadingIndicator showing isLightIndicator />
                  </div>
                ) : (
                  btnLabel
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </AppealioPopup>
  );
};

MissingManualClaimInfo.propTypes = {
  claimInfo: PropTypes.object,
  onClosePressed: PropTypes.func,
  handleSubmit: PropTypes.func,
};

MissingManualClaimInfo = reduxForm({
  form: 'missingClaimNumberOrClaimControlNumberForm',
})(MissingManualClaimInfo);

MissingManualClaimInfo = connect(null, null)(MissingManualClaimInfo);

export default MissingManualClaimInfo;
