import React from 'react';

import DropdownListFilter from '../Shared/DropdownListFilterForKeyValue';

export const renderReduxDropdown = ({
  input,
  dropdownOptions,
  maxStringLength,
  shouldTrimOptionLength,
  placeholder,
  defaultValue,
  meta: { touched, error },
  allowNoneItem,
}) => (
  <DropdownListFilter
    placeholder={placeholder}
    dropdownOptions={dropdownOptions}
    maxStringLength={maxStringLength}
    shouldTrimOptionLength={shouldTrimOptionLength}
    onDropdownFilterChange={input.onChange}
    selectedKey={input.value || defaultValue}
    input={input}
    error={error}
    touched={touched}
    allowNoneItem={allowNoneItem}
  />
);
