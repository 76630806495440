import React from 'react';
import AppealioPopup from 'components/Shared/AppealioPopup';
import { Field, reduxForm } from 'redux-form/immutable';

import './style.css';

import Input from 'components/common/input';
import Button from 'components/common/button';
import * as toast from 'components/Shared/toast';
import { BUTTON_TYPE } from 'components/common/button/Button';

const UpdateBenefitPopup = (props) => {
  const {
    handleSubmit,
    submitting,
    onClose,
    onSubmit,
    isProcedureCodesSelected,
  } = props;

  const onFormSubmit = (formValues) => {
    const values = formValues.toJS();
    onSubmit({
      ...values,
    });
    toast.success({
      title: '',
      message: 'Benefit details updated successfully.',
    });
  };

  return (
    <AppealioPopup
      title="Update Benefit Details"
      className="update-benefit-popup"
      datacy="update-benefit-details-AppealioPopup"
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="row mb-12">
          <div className="row mb-12">
            <div className="col-lg-12">
              <Field
                component={Input}
                name="deductible"
                label="Remaining Deductible"
                placeholder="Deductible"
                min={0}
                step={0.01}
                type="number"
                datacy="deductible-Field"
              />
            </div>
          </div>

          <div className="row mb-12">
            <div className="col-lg-12">
              <Field
                component={Input}
                name="coinsurance"
                label="Patient Coinsurance %"
                placeholder="Patient Coinsurance %"
                min={0}
                max={100}
                step={0.01}
                type="number"
                datacy="coinsurance-Field"
              />
            </div>
          </div>

          <div className="row mb-12">
            <div className="col-lg-12">
              <Field
                component={Input}
                name="copayment"
                label="Copayment"
                placeholder="Copayment"
                step={0.01}
                min={0}
                type="number"
                datacy="copayment-Field"
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-flex-end">
          <Button
            title="Cancel"
            type={BUTTON_TYPE.LIGHT}
            className="width-80 justify-content-center fw-normal mr-8"
            disabled={submitting}
            onClick={onClose}
            datacy="cancel-Button"
          />
          <Button
            title={isProcedureCodesSelected ? 'Save & Recalculate' : 'Save'}
            className="justify-content-center"
            disabled={submitting}
            datacy="save-recalculate-Button"
          />
        </div>
      </form>
    </AppealioPopup>
  );
};

export default reduxForm({
  form: 'update-benefit-form',
})(UpdateBenefitPopup);
