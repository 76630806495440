import { debounce } from 'lodash';
import classnames from 'classnames';
import React, { useCallback, useState, useEffect } from 'react';

import { TIME_INTERVAL } from './ClaimsPerformance';

const Filter = (props) => {
  const [interval, setInterval] = useState(
    props.interval || TIME_INTERVAL.month
  );
  const debouncedHandleFilterChange = useCallback(
    (args) => debounce(props.setInterval, 800)(args),
    [props.setInterval]
  );
  const handleFilterChange = (interval) => {
    setInterval(interval);
    debouncedHandleFilterChange(interval);
  };

  useEffect(() => {
    setInterval(props.interval || TIME_INTERVAL.month);
  }, [props.interval]);

  return (
    <div className="d-flex align-items-center ap-dashboard-widget__status-filter-button-wrapper">
      <button
        onClick={() => handleFilterChange(TIME_INTERVAL.month)}
        className={classnames('ap-button--filter mr-12', {
          'ap-button--filter-active': interval === TIME_INTERVAL.month,
        })}
        disabled={props.isFetchingData}
      >
        By Month
      </button>
      <button
        onClick={() => handleFilterChange(TIME_INTERVAL.quarter)}
        className={classnames('ap-button--filter', {
          'ap-button--filter-active': interval === TIME_INTERVAL.quarter,
        })}
        disabled={props.isFetchingData}
      >
        By Quarter
      </button>
    </div>
  );
};

Filter.propTypes = {};

export default Filter;
