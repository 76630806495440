import React, { useState } from 'react';

import './style.css';

import * as toast from 'components/Shared/toast';
import { AppealioPopupWithFooter } from 'components/common/popup';

import { handleError } from 'helpers/errorHandler';
import * as AccountsAPI from 'API/AccountSettingsAPI';

const ChangeRenderingProviderStatusPopup = (props) => {
  const {
    title,
    onClosePressed,
    renderingProvider,
    practiceName,
    toDeactivate,
    onSubmitSuccess,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmitClick = async () => {
    try {
      setIsSubmitting(true);

      if (toDeactivate) {
        await AccountsAPI.updateRenderingProvider({
          ...renderingProvider,
          billingProviders: renderingProvider.billingProviders.map(
            ({ id }) => id
          ),
          clinics: renderingProvider.clinics.map(({ id }) => id),
          practices: renderingProvider.practices.map(({ id }) => id),
          is_active: !toDeactivate,
        });
      } else {
        await AccountsAPI.requestReactivate(
          renderingProvider.id,
          'rendering_provider'
        );
      }
      setIsSubmitting(false);
      const toastMessage = toDeactivate
        ? "Rendering Provider's status updated successfully"
        : "Rendering Provider's status change requested to the CX team successfully";
      toast.success({
        title: '',
        message: toastMessage,
      });
      onSubmitSuccess();
    } catch (error) {
      setIsSubmitting(false);
      handleError(error);
    }
  };

  return (
    <AppealioPopupWithFooter
      className="appealio-popup--v3"
      title={title}
      onClosePressed={onClosePressed}
      isFooterOutside={false}
      datacy="change-rendering-provider-status-AppealioPopupWithFooter"
    >
      <div>
        <div className="mb-16">
          <div className="fs-14 fw-bold">Name</div>
          <div>
            {renderingProvider.organizationName ||
              `${renderingProvider.firstName} ${renderingProvider.lastName}`}
          </div>
        </div>
        <div className="mb-16">
          <div className="fs-14 fw-bold">NPI</div>
          <div>{renderingProvider.npi}</div>
        </div>
        <div className="mb-30">
          <div className="fs-14 fw-bold">Practice(s)</div>
          <div>{practiceName}</div>
        </div>
      </div>
      <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
        <div className="d-flex justify-content-flex-end">
          <button
            disabled={isSubmitting}
            className="ml-0 mr-16 ap-button ap-button--primary-red ap-button--primary-md"
            onClick={onClosePressed}
          >
            Cancel
          </button>
          <button
            disabled={isSubmitting}
            className="ml-0 ap-button ap-button--primary-dark ap-button--primary-md"
            onClick={onSubmitClick}
          >
            {toDeactivate ? 'Deactivate' : 'Reactivate'}
          </button>
        </div>
      </div>
    </AppealioPopupWithFooter>
  );
};

ChangeRenderingProviderStatusPopup.propTypes = {};

export default ChangeRenderingProviderStatusPopup;
