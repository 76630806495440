import classnames from 'classnames';
import React, { useState, useEffect } from 'react';

import searchIcon from 'img/search.svg';
import arrowBackIcon from 'img/keyboard-arrow-return.svg';

import './style.css';

const SearchBox = ({
  onSearch,
  value = '',
  placeholder = 'Search by Name & IDs',
  datacy,
  checkIsSearchDisabled = (search) => false, // validate function to check if search button should be disabled
  hideIcon = false,
  className = '',
}) => {
  const [searchString, setSearchString] = useState(value);

  useEffect(() => {
    setSearchString(value);
  }, [value]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    onSearch(searchString);
  };

  const isSearchButtonDisabled = checkIsSearchDisabled(searchString);

  return (
    <div className={classnames('search-box', className)} datacy={datacy}>
      {!hideIcon && (
        <div className="search-box__icon">
          <img src={searchIcon} alt="Search" />
        </div>
      )}
      <form
        className="search-box__form"
        onSubmit={handleSearchSubmit}
        datacy="search-form"
      >
        <input
          className="search-box__input"
          type="text"
          placeholder={placeholder}
          value={searchString}
          autoFocus
          onChange={(e) => setSearchString(e.target.value)}
        />
        <button
          className="btn ap-button--secondary search-box__submit-btn"
          type="submit"
          datacy="search-button"
          disabled={isSearchButtonDisabled}
        >
          <img src={arrowBackIcon} alt="enter" />
          <span className="ml-8">Enter</span>
        </button>
      </form>
    </div>
  );
};

export default SearchBox;
