import moment from 'moment';

import {
  handleErrors,
  createVndApiRequest,
  handleJSONParse,
  createGetRequest,
  createJSONFetch,
} from './Config';

import { serializeAppealNotes } from './Mappers';

export default class AppealNotesAPI {
  static getNotesForAppealId = (id) => {
    const endpoint = `appeals/${id}`;
    const request = createGetRequest(endpoint, { include: 'notes' });

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then(AppealNotesAPI.parseNotesData);
  };

  static parseNotesData = (res) => {
    const notesRes = res.included;
    if (typeof notesRes === 'undefined') {
      return [];
    } else {
      const notes = notesRes.map((note, i) => {
        return AppealNotesAPI.parseNote(note);
      });

      const sortedNotes = notes.sort((x, y) => {
        return moment(y.rawtimestamp) - moment(x.rawtimestamp);
      });

      return sortedNotes;
    }
  };

  static parseNote = (note) => {
    const time = note.attributes.created_at;
    return {
      author: note.attributes.agent_name,
      rawtimestamp: time,
      time: moment(time).format('h:mm a'),
      date: moment(time).format('L'),
      text: note.attributes.text,
    };
  };

  static postNote = async (appealNote) => {
    const serializedNote = serializeAppealNotes(appealNote);
    const notesRequest = createVndApiRequest('appeal_notes', serializedNote);

    const response = await createJSONFetch(notesRequest);

    return {
      note: response.data.attributes.text,
      createdAt: response.data.attributes.created_at,
      by: response.data.attributes.agent_name,
    };
  };
}
