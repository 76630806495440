import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.css';

const Indicator = ({
  totalCount = 5,
  activeCount = 0,
  containerClassName = '',
  className = '',
}) => {
  const getIndicatorClass = (index) =>
    classnames({
      'indicator-dot--visited': index < activeCount,
      'indicator-dot--active': index === activeCount,
    });

  const dots = Array.from({ length: totalCount }, (_, index) => (
    <div
      key={index}
      className={classnames(
        'indicator-dot',
        getIndicatorClass(index),
        className
      )}
    ></div>
  ));

  return (
    <div className={classnames('indicator-container', containerClassName)}>
      {dots}
    </div>
  );
};

Indicator.propTypes = {
  totalCount: PropTypes.number,
  activeCount: PropTypes.number,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
};

export default Indicator;
