import React from 'react';

import {
  transformToDateInput,
  isGreaterThanToday,
} from 'API/Serializers/Transforms';

import '../style.css';

const extractDeliveryDateLabel = (expectedDeliveryDate) => {
  if (!expectedDeliveryDate || isGreaterThanToday(expectedDeliveryDate))
    return 'Expected Delivery Date:';

  return 'Delivery Date:';
};

const MailSubmissionDetails = ({ data, status, tags }) => {
  const { certified, trackingLink } = data;
  const title = certified ? 'Certified Mail' : 'Mail';
  return (
    <div>
      {data ? (
        <div>
          <div className="submission-details-title">{title}</div>
          {certified && (
            <p className="mt-12">
              <label>Tracking URL</label> :{' '}
              {trackingLink ? (
                <a target="_blank" rel="noreferrer" href={trackingLink}>
                  {trackingLink}
                </a>
              ) : (
                'N/A'
              )}{' '}
            </p>
          )}
          <div className="submission-details-header">
            {data.eta && (
              <p>
                <label>Estimated Submission Time</label>:
                <span>{data.eta.replace("'", '')}(s)</span>
              </p>
            )}
            <p>
              <label>Address:</label>
              {data.addressLine1}
            </p>
            {data.addressLine2 && (
              <p>
                <label>
                  <label>Address2</label>:
                </label>
                {data.addressLine2}
              </p>
            )}

            <div className="submission-first-column">
              <p>
                <label>City:</label>
                {data.city}
              </p>
              <p>
                <label>State:</label>
                {data.state}
              </p>
              <p>
                <label>Zip:</label>
                {data.zipcode}
              </p>
              <p>
                <label className="submission-label">Status Message:</label>
                {data.deliverStatus}
              </p>
            </div>
            <div className="submission-second-column">
              <p>
                <label>
                  {status === 'Under Review'
                    ? 'Expected Delivery Date:'
                    : extractDeliveryDateLabel(data?.expectedDeliveryDate)}
                </label>
                {data?.expectedDeliveryDate
                  ? transformToDateInput(data?.expectedDeliveryDate)
                  : 'N/A'}
              </p>

              <p>
                <label>Carrier:</label>
                {data.carrier || 'N/A'}
              </p>
              <p>
                <label className="submission-label">Delivery Status:</label>
                <span>{status}</span>
              </p>
              {tags.length > 0 && (
                <p>
                  <label>Tags:</label>
                  {tags.join(', ')}
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <p className="error-message">
          Mail does not have any associated data yet.
        </p>
      )}
    </div>
  );
};

export default MailSubmissionDetails;
