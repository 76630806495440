import React, { useState, useEffect } from 'react';
import { BiSearch } from 'react-icons/bi';

const Search = ({
  onSearch,
  value = '',
  placeholder = 'Search by Name & IDs',
  className = '',
  datacy,
  dataTip = '',
}) => {
  const [searchString, setSearchString] = useState(value);

  useEffect(() => {
    setSearchString(value);
  }, [value]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const searchValue = searchString.trim();
    onSearch(searchValue);
  };

  return (
    <form
      className={`filter-wrapper__search-box ${className}`}
      onSubmit={handleSearchSubmit}
      datacy="search-form"
    >
      <input
        type="text"
        placeholder={placeholder}
        value={searchString}
        title={dataTip}
        onChange={(e) => setSearchString(e.target.value)}
      />
      <button
        className="btn ap-button--secondary"
        type="submit"
        datacy="search-button"
      >
        <BiSearch />
        <span className="ml-8">Search</span>
      </button>
    </form>
  );
};

export default Search;
