import { useEffect, useState } from 'react';

import { fetchSubmissionReasons } from 'API/SubmitPackageAPI';

export const useFetchSubmissionReasonList = (includeOtherOption = true) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [submissionsReasonList, setSubmissionsReasonList] = useState([]);

  const extractOptions = ({ id, name }) => ({
    key: id,
    value: name,
  });

  const appendOtherOption = (options) => [
    ...options,
    { value: 'Other', key: 0 },
  ];

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        const { data } = await fetchSubmissionReasons();
        setSubmissionsReasonList(
          includeOtherOption
            ? appendOtherOption(data.map(extractOptions))
            : data.map(extractOptions)
        );
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };
    fetch();
  }, [includeOtherOption]);

  return {
    error,
    isLoading,
    submissionsReasonList,
  };
};
