import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import searchIcon from '../../../../img/search.svg';

export default class SearchStringFilter extends Component {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    lastSearchStringSubmitted: PropTypes.string.isRequired,
    onSearchStringFilterChange: PropTypes.func.isRequired,
    searchStringFilterTypeOptions: PropTypes.array.isRequired,
    searchStringFilterTypeIndexSelected: PropTypes.number.isRequired,
    hideSearchBtn: PropTypes.bool,
    searchButtonText: PropTypes.string,
    onSearchTextChange: PropTypes.func,
    allowEmptyString: PropTypes.bool,
    buttonType: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.searchStringFilterTypes = this.props.searchStringFilterTypeOptions;

    this.state = {
      searchButtonEnabled: false,
      dropdownIsDisplayed: false,
      searchStringFilterType:
        this.searchStringFilterTypes[
          this.props.searchStringFilterTypeIndexSelected
        ],
    };
    this.onPageClickFunction = () => this.onPageClick();
  }

  componentDidMount() {
    document.addEventListener('click', this.onPageClickFunction, true);

    this.computeAndInitializeState(this.props);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onPageClickFunction, true);
  }

  onPageClick = () => {
    if (this.state.dropdownIsDisplayed) {
      this.setState({ dropdownIsDisplayed: false });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.computeAndInitializeState(nextProps);
  }

  computeAndInitializeState(props) {
    // eslint-disable-next-line react/no-string-refs
    if (typeof this.refs.searchStringFilter !== 'undefined') {
      // eslint-disable-next-line react/no-string-refs
      this.refs.searchStringFilter.value = props.lastSearchStringSubmitted;
      if (props.lastSearchStringSubmitted === '' && !props.allowEmptyString) {
        this.setState({ searchButtonEnabled: false });
      } else {
        this.setState({ searchButtonEnabled: true });
      }
    }
    this.setState({
      searchStringFilterType:
        this.searchStringFilterTypes[props.searchStringFilterTypeIndexSelected],
    });
  }

  showDropdown = () => {
    if (!this.props.disabled) {
      this.setState({ dropdownIsDisplayed: true });
    }
  };

  hideDropdown = () => {
    const dropdownIsDisplayed = false;
    this.setState({ dropdownIsDisplayed });
  };

  searchStringFilterDidChange = (e) => {
    const searchString = e.target.value;
    let searchButtonEnabled = false;
    if (searchString !== '' || this.props.allowEmptyString) {
      searchButtonEnabled = true;
    }
    this.setState({ searchButtonEnabled });
  };

  getButtonClass = () => {
    return classNames(
      'searchbar__btn',
      { 'searchbar__btn--disabled': !this.state.searchButtonEnabled },
      { 'searchbar__btn--enabled': this.state.searchButtonEnabled }
    );
  };

  getStringTypeListClass = () => {
    return classNames('searchbar__dropdown-list', {
      hidden: !this.state.dropdownIsDisplayed,
    });
  };

  getInputClass = () => {
    return classNames(
      'searchbar__input',
      { 'searchbar__input--disabled': this.props.disabled },
      { 'searchbar__input--disabled-search-btn': this.props.hideSearchBtn }
    );
  };

  onSearchStringFilterChange = () => {
    if (this.state.searchButtonEnabled || this.props.allowEmptyString) {
      // eslint-disable-next-line react/no-string-refs
      const searchStringFilterInput = this.refs.searchStringFilter;
      const searchString = searchStringFilterInput.value;
      this.props.onSearchStringFilterChange(
        searchString,
        this.state.searchStringFilterType.filterId
      );
    }
  };

  onSearchStringFilterTypeChange = (searchStringFilterType) => {
    this.setState({ searchStringFilterType }, () => {
      if (this.props.hideSearchBtn) {
        this.onSearchStringFilterChange();
      }
    });
  };

  renderStringFilterTypeList = () => {
    const list = [];
    // eslint-disable-next-line guard-for-in
    for (const i in this.searchStringFilterTypes) {
      list.push(
        <div
          key={i}
          onClick={() =>
            this.onSearchStringFilterTypeChange(this.searchStringFilterTypes[i])
          }
          className="searchbar__dropdown-list-item"
        >
          {this.searchStringFilterTypes[i].display}
        </div>
      );
    }
    return list;
  };

  onSearchStringChange = () => {
    if (this.props.hideSearchBtn) {
      this.onSearchStringFilterChange();
    }
  };

  renderSearchButton = () => {
    if (this.props.hideSearchBtn === true) {
      return '';
    }

    return (
      <div
        className={classNames(
          this.getButtonClass(),
          this.props.buttonType && `ap-button--${this.props.buttonType}`
        )}
        onClick={this.onSearchStringFilterChange}
      >
        <img
          alt="search icon"
          className="searchbar__btn-icn"
          src={searchIcon}
        />
        <div className="searchbar__btn-text">Search</div>
      </div>
    );
  };

  onKeyPress = (e) => {
    const key = e.which || e.keyCode;
    const enterKeyCode = 13;
    if (key === enterKeyCode) {
      this.onSearchStringFilterChange();
    }
  };

  getDropdownClass = () => {
    return classNames('searchbar__dropdown', {
      'searchbar__dropdown--disabled': this.props.disabled,
    });
  };

  render() {
    return (
      <div className="searchbar">
        <input
          onKeyUp={this.searchStringFilterDidChange}
          onChange={this.onSearchStringChange}
          onKeyPress={this.onKeyPress}
          placeholder="Search by Name & IDs"
          className={this.getInputClass()}
          // eslint-disable-next-line react/no-string-refs
          ref="searchStringFilter"
          disabled={this.props.disabled}
        />
        {this.renderSearchButton()}
      </div>
    );
  }
}
