import _ from 'lodash';
import moment from 'moment';
import { moneyFormatter } from '../helpers/money.js';

export default class UtilAPI {
  static cloneObject(obj) {
    return _.cloneDeep(obj);
  }

  static formatAmount(amount) {
    return moneyFormatter().format(amount);
  }

  static formatDate(date, status, submittedAt) {
    if (date === null) {
      return null;
    }
    const today = moment().format('YYYY-MM-DD');
    const deadline = moment(date, 'YYYY-MM-DD');
    const submitted = moment(submittedAt, 'YYYY-MM-DD');
    const diffFromToday = moment().diff(deadline, 'days');
    const diffFromSubmitted = moment().diff(submitted, 'days');
    const diffFromDeadline = deadline.diff(today, 'days');
    const formattedDate = moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
    let outputDate;
    if (status === 'Submitted') {
      outputDate = `${formattedDate} (${diffFromSubmitted} days since submission)`;
      outputDate = diffFromSubmitted > 30 ? `${outputDate} ` : outputDate;
    } else {
      if (diffFromDeadline > 0) {
        outputDate = `${formattedDate} (${diffFromDeadline} days left)`;
        outputDate = diffFromDeadline < 7 ? `${outputDate} ` : outputDate;
      } else {
        outputDate = `${formattedDate} (${diffFromToday} days past due) `;
      }
    }
    if (status === 'Paid' || status === 'Closed') {
      outputDate = '--';
    }

    return outputDate;
  }

  static preventNullValue(data, alt) {
    let validData = data;
    if (typeof data === 'undefined' || data == null || data === '') {
      validData = alt;
    }
    return validData;
  }

  static sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
}
