import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const AppealStatusIndicator = (props) => {
  const { inProgress, submitted, successful, denied, className } = props;

  return (
    <span
      className={classnames('appeal-status-indicator', {
        'appeal-status-indicator--in-progress': inProgress,
        'appeal-status-indicator--submitted': submitted,
        'appeal-status-indicator--successful': successful,
        'appeal-status-indicator--denied': denied,
        [className]: className,
      })}
      datacy="appeal-status-indicator"
    ></span>
  );
};

AppealStatusIndicator.propTypes = {
  inProgress: PropTypes.bool,
  submitted: PropTypes.bool,
  successful: PropTypes.bool,
  denied: PropTypes.bool,
  className: PropTypes.string,
};

export default AppealStatusIndicator;
