import PropTypes from 'prop-types';
import classnames from 'classnames';
import { AiOutlineRight } from 'react-icons/ai';
import React, { useEffect, useState } from 'react';

import StandAloneNav from 'components/StandAloneNav/StandAloneNav';
import StandAlonePromptPopup from 'components/common/popup/StandAlonePromptPopup';
import CreateAppealModal from 'components/Dashboard/CreateAppealModal/CreateAppealModal';
import OutsideClickWrapper from 'components/common/outsideClickWrapper/OutsideClickWrapper';

import * as routes from 'constants/routes';
import { APPEAL_STEPS } from 'constants/appConstants';

const CreateSubmission = (props) => {
  const {
    isDisabled,
    currentStep,
    isUserAuthorizedForStandalone,
    isAuthorizedForSubmission,
    isStandAloneSubmission = false,
  } = props;
  const [showCreateMenu, setShowCreateMenu] = useState(false);
  const [createAppealModal, setCreateAppealModal] = useState({
    isMedicalRecord: null,
    isOpen: false,
  });

  const [showStandAlonePrompt, setShowStandAlonePrompt] = useState(false);

  useEffect(() => {
    if (props.showGlobalSearch) {
      setShowCreateMenu(false);
    }
  }, [props.showGlobalSearch]);

  const handleCancelPrompt = () => setShowStandAlonePrompt(false);
  const handleOkPrompt = () => {
    window.location = routes.SUBMISSIONS;
  };

  return (
    <div>
      <OutsideClickWrapper
        handleOutsideClick={() => {
          setShowCreateMenu(false);
        }}
      >
        <div
          className="ap-dropmenu__wrapper d-inline"
          onClick={(e) => {
            e.stopPropagation();
            if (!showCreateMenu & !isStandAloneSubmission) {
              setShowCreateMenu(true);
            }
            props.closeGlobalSearch();
            if (isStandAloneSubmission) {
              currentStep <= APPEAL_STEPS.SUBMIT_CONFIRM
                ? setShowStandAlonePrompt(true)
                : setShowCreateMenu(true);
            }
          }}
        >
          <button
            className="ap-button ap-button--primary-md topbar__dashitem topbar__dashitem--create"
            disabled={isDisabled}
          >
            + Create
          </button>

          <div
            className={classnames('ap-dropmenu', {
              'ap-dropmenu--visible ap-dropmenu--visible-create':
                showCreateMenu,
            })}
          >
            {isUserAuthorizedForStandalone && isAuthorizedForSubmission && (
              <>
                <button
                  className="ap-dropmenu__item"
                  onClick={() => {
                    setShowCreateMenu(false);
                    setCreateAppealModal({
                      isMedicalRecord: false,
                      isOpen: true,
                    });
                  }}
                >
                  <span>Create Appeal</span>
                  <span>
                    {' '}
                    <AiOutlineRight />
                  </span>
                </button>
                <button
                  className="ap-dropmenu__item"
                  onClick={() => {
                    setShowCreateMenu(false);
                    setCreateAppealModal({
                      isMedicalRecord: true,
                      isOpen: true,
                    });
                  }}
                >
                  <span>Create Record</span>
                  <span>
                    {' '}
                    <AiOutlineRight />
                  </span>
                </button>
              </>
            )}
            <StandAloneNav onClickHideMenu={() => setShowCreateMenu(false)} />
          </div>
        </div>
      </OutsideClickWrapper>
      {createAppealModal.isOpen && (
        <CreateAppealModal
          closeCreateAppealDialog={() =>
            setCreateAppealModal({
              isMedicalRecord: null,
              isOpen: false,
            })
          }
          isMedicalRecord={createAppealModal?.isMedicalRecord}
        />
      )}
      {showStandAlonePrompt && (
        <StandAlonePromptPopup
          handleCancel={handleCancelPrompt}
          handleOk={handleOkPrompt}
        />
      )}
    </div>
  );
};

CreateSubmission.propTypes = {
  isDisabled: PropTypes.bool,
  currentStep: PropTypes.number,
  isUserAuthorizedForStandalone: PropTypes.bool,
  isAuthorizedForSubmission: PropTypes.bool,
};

export default CreateSubmission;
