import React from 'react';
import classNames from 'classnames';

export const renderNumberField = ({
  input,
  disabled,
  key,
  label,
  placeholder,
  max,
  autoFocus = false,
  inputRef = null,
  required = false,
  meta: { touched, error },
}) => (
  <div datacy="number-field">
    <label
      htmlFor={input.name}
      className={classNames('number-field__label', {
        'number-field__label--required': required,
      })}
    >
      {label} {required ? '*' : ''}
    </label>
    <div>
      <span>
        <input
          tabIndex={0}
          {...input}
          type="number"
          key={key}
          placeholder={placeholder}
          disabled={disabled}
          max={max}
          autoFocus={autoFocus}
          ref={inputRef}
          className={classNames(
            'number-field__input',
            {
              'number-field__input--error': error && touched,
            },
            {
              'number-field__input--disabled': disabled,
            }
          )}
        />
      </span>
    </div>
  </div>
);
