import { ALL_VALUE, ZERO_PAY_RULE_OPTIONS } from 'constants/appConstants';
import { extractPracticeAndPracticeGroupValues } from 'components/ApDashboard/Manager/Settings/Organization/Users/PracticeSelect/utils';

const updateAllValues = ({ practiceValues, practiceGroupValues }) => {
  if (
    practiceGroupValues.includes(ALL_VALUE) &&
    practiceValues.includes(ALL_VALUE)
  ) {
    // Both contain ALL_VALUE
    practiceGroupValues = [];
    practiceValues = [];
  } else if (
    practiceGroupValues.includes(ALL_VALUE) &&
    practiceValues.length > 0 &&
    !practiceValues.includes(ALL_VALUE)
  ) {
    // Only practiceGroupValues contains ALL_VALUE and practiceValues contains other values
    practiceGroupValues = [];
  } else if (
    practiceValues.includes(ALL_VALUE) &&
    practiceGroupValues.length > 0 &&
    !practiceGroupValues.includes(ALL_VALUE)
  ) {
    // Only practiceValues contains ALL_VALUE and practiceGroupValues contains other values
    practiceValues = [];
  }

  return { practiceValues, practiceGroupValues };
};

export const mapToRequestData = (formData) => {
  const extractValuesFromSelectedItems = (selectedItems) => {
    if (!selectedItems) {
      return [];
    }
    const values = selectedItems.map(({ value }) => value);

    if (values.includes(ALL_VALUE)) {
      return [];
    }

    return values;
  };
  const { practiceGroupValues, practiceValues } = updateAllValues(
    extractPracticeAndPracticeGroupValues(formData.practices)
  );
  const {
    practiceGroupValues: excludedPracticeGroupValues,
    practiceValues: excludedPracticeValues,
  } = updateAllValues(
    extractPracticeAndPracticeGroupValues(formData.excludedPractices)
  );
  const ANY_CLAIM_LINE_RULE = ZERO_PAY_RULE_OPTIONS[1].value;
  const ENTIRE_CLAIM_RULE = ZERO_PAY_RULE_OPTIONS[0].value;
  const paymentAmount =
    formData.onlyZeroPay &&
    formData?.onlyZeroPayRule?.value === ENTIRE_CLAIM_RULE
      ? 0
      : 1;
  const checkServiceLineZeroPay =
    formData.onlyZeroPay &&
    formData?.onlyZeroPayRule?.value === ANY_CLAIM_LINE_RULE;

  return {
    name: formData.name,
    status: formData.status,
    action: formData.action,
    effectiveDate: formData.effectiveDate,
    clientPartitionId: formData.clientPartitionId,
    minimumDeniedAmount: 0,
    practiceIds: practiceValues,
    providerNpis: extractValuesFromSelectedItems(formData.providers),
    assignedAgents: extractValuesFromSelectedItems(formData.agents),
    payerIds: extractValuesFromSelectedItems(formData.payers),
    procedureCodes: extractValuesFromSelectedItems(formData.procedureCodes),
    reasonCodes: extractValuesFromSelectedItems(formData.reasonCodes),
    remarkCodes: extractValuesFromSelectedItems(formData.remarkCodes),
    forGroup: {
      pgroup: practiceGroupValues,
    },
    procedureCodeModifiers: extractValuesFromSelectedItems(
      formData.procedureModifierCodes
    ),
    exclude: {
      payerIds: extractValuesFromSelectedItems(formData?.excludedPayers) || [],
      procedureCodes:
        extractValuesFromSelectedItems(formData?.excludedProcedureCodes) || [],
      reasonCodes:
        extractValuesFromSelectedItems(formData?.excludedReasonCodes) || [],
      remarkCodes:
        extractValuesFromSelectedItems(formData?.excludedRemarkCodes) || [],
      procedureCodeModifiers: extractValuesFromSelectedItems(
        formData?.excludedProcedureModifierCodes || []
      ),
      practiceIds: excludedPracticeValues,
      pgroup: excludedPracticeGroupValues,
    },
    paymentAmount,
    checkServiceLineZeroPay: Boolean(checkServiceLineZeroPay),
    minimumBilledAmountForMatchingClaims: isNaN(
      formData.minimumBilledAmountForMatchingClaims
    )
      ? null
      : formData.minimumBilledAmountForMatchingClaims,
  };
};

export const isDuplicateRuleName = (existingRules = [], ruleName = '') =>
  existingRules
    .map((rule) => rule.name.toLowerCase())
    .includes(ruleName.toLowerCase());
