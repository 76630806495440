import PropTypes from 'prop-types';
import classNames from 'classnames';
import Truncate from 'react-truncate';
import React, { Component } from 'react';

export default class AppealNoteItem extends Component {
  static propTypes = {
    note: PropTypes.object,
    charMax: PropTypes.number,
    hasTopBorder: PropTypes.bool,
  };

  constructor() {
    super();
    this.defaultNumLines = 2;
    this.state = {
      numLines: 2,
    };
  }

  getItemClass = () => {
    return classNames('appeal-notes__list-item', {
      'appeal-notes__list-item--topborder': this.props.hasTopBorder,
    });
  };

  toggleExpand = () => {
    let numLines = this.state.numLines;
    if (numLines > 0) {
      numLines = -1;
    } else {
      numLines = this.defaultNumLines;
    }
    this.setState({ numLines });
  };

  renderNote = () => {
    return (
      <div className="appeal-notes__list-item-subtitle-note">
        <Truncate
          lines={this.state.numLines}
          ellipsis={
            <span>
              ...
              <div
                className="appeal-notes__showallbtn"
                onClick={this.toggleExpand}
              >
                {'View more'}
              </div>
            </span>
          }
        >
          {this.props.note.text}
        </Truncate>
      </div>
    );
  };

  render() {
    return (
      <div className={this.getItemClass()}>
        <div className="appeal-notes__list-item-subtitle">
          <div className="appeal-notes__list-item-subtitle-name">
            {this.props.note.author}
          </div>
          <div className="appeal-notes__list-item-subtitle-time">
            {this.props.note.time}
          </div>
          <div className="appeal-notes__list-item-subtitle-date">
            {this.props.note.date}
          </div>
        </div>
        {this.renderNote()}
      </div>
    );
  }
}
