import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { MdInfoOutline } from 'react-icons/md';

import './style.css';

const ErrorMessage = ({ errorMsg, useErrorSpace = false }) => {
  if (!errorMsg && !useErrorSpace) return null;

  return <span className="ap-input-error">{errorMsg || '\u00A0'}</span>;
};

const TextArea = (props) => {
  const {
    name,
    label = '',
    value,
    onChange,
    rows = 2,
    placeholder = '',
    readOnly = false,
    input = {},
    meta: { touched, error = '' } = {},
    inputRef = null,
    type,
    min,
    max,
    step,
    maxLength,
    customError,
    datacy,
    pattern,
    title,
    useErrorSpace = false,
    showErrorInputField = false,
    className,
    dataTip = '',
    resize = true,
    styles = {},
    showCharactersCounter = false,
  } = props;

  const errorMsg = customError || (touched && error ? error : '');

  return (
    <div className={classnames('ap-input-container', className)}>
      {label && (
        <label className="ap-input-label" htmlFor={name}>
          {label}
          {dataTip && (
            <>
              <MdInfoOutline
                size="18"
                className="ml-4 ap-input-container__tooltip-icon"
                data-tip={dataTip}
                data-for={label}
                color="#5E6871"
              />
              <ReactTooltip
                effect="solid"
                place="top"
                id={label}
                multiline={true}
              />
            </>
          )}
        </label>
      )}

      <textarea
        value={value}
        className={classnames('ap-input', {
          'ap-input--disabled': readOnly,
          'ap-input--error': showErrorInputField && touched && error,
        })}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        ref={inputRef}
        type={type}
        min={min}
        max={max}
        step={step}
        readOnly={readOnly}
        pattern={pattern}
        title={title}
        maxLength={maxLength}
        datacy={datacy}
        rows={rows}
        style={{
          ...input?.style,
          resize: resize ? true : 'none',
          ...styles,
        }}
        {...input}
      />

      <ErrorMessage errorMsg={errorMsg} useErrorSpace={useErrorSpace} />
      {showCharactersCounter && !readOnly && (
        <p className="mt-8 mb-0 mr-4 fw-500 fs-12 text-right">
          {input?.value?.length}/{max}
        </p>
      )}
    </div>
  );
};

TextArea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default TextArea;
