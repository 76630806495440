import { validateExists, fieldCharacterValidation } from 'helpers/validators';

export const validateDirectSubmissionForm = (values, isRecon = false) => {
  const errors = {};
  const displayErrors = [];

  const notEmptyFields = [
    {
      field: 'provComments',
      label: 'Provider Comments',
    },
  ];

  if (isRecon) {
    notEmptyFields.push(
      {
        field: 'amtOwed',
        label: 'Amount Requested',
      },
      {
        field: 'reqReason',
        label: 'Request Reason',
      }
    );
  }

  notEmptyFields.forEach(({ field, label }) => {
    errors[field] = validateExists(values.get(field));
    if (errors[field]) {
      const errorMessage = `${label} is required`;
      errors[field] = errorMessage;
      displayErrors.push({ message: errorMessage });
    }
  });

  errors.provComments = fieldCharacterValidation(
    'Provider Comments',
    values.get('provComments'),
    1000
  );
  if (errors.provComments) {
    displayErrors.push({ message: errors.provComments });
  }

  if (displayErrors.length > 0) {
    errors.displayErrors = displayErrors;
  }

  return errors;
};

export const formatDirectSubmissionValues = (values, isRecon = false) => {
  const formattedValues = {
    prov_comments: values.get('provComments') || '',
  };

  if (isRecon) {
    formattedValues.amt_owed = values.get('amtOwed') || '';
    formattedValues.req_reason = values.get('reqReason') || '';
    formattedValues.dont_know_dollar_amt =
      values.get('dontKnowDollarAmt') || false;
  }

  return formattedValues;
};
