import { validateEmail } from 'helpers/validators';

const validateExists = (value) => (value ? null : 'Required');

export const validateEmailForm = (values) => {
  const errors = {};
  const displayErrors = [];

  const notEmptyFields = [
    { field: 'emailSubject', label: 'Email Subject' },
    { field: 'emailMessage', label: 'Message' },
  ];

  notEmptyFields.forEach(({ field, label }) => {
    errors[field] = validateExists(values.get(field));
    if (errors[field]) {
      const errorMessage = `${label} is required`;
      errors[field] = errorMessage;
      displayErrors.push({ message: errorMessage });
    }
  });

  errors.recipientEmail = validateEmail(values.get('recipientEmail'));
  if (errors.recipientEmail) {
    displayErrors.push({ message: errors.recipientEmail });
  }

  if (displayErrors.length > 0) {
    errors.displayErrors = displayErrors;
  }

  return errors;
};

export const formatEmailFormValues = (values) => {
  const formattedValues = {
    subject: values.get('emailSubject') || '',
    message: values.get('emailMessage') || '',
    recipient_email: values.get('recipientEmail') || '',
    cc_emails: values.get('ccEmails') || [],
  };

  return formattedValues;
};
