/**
 * Attaches counts from a countArray to an array of items based on a specified key.
 *
 * @param {Array<Object>} items - The array of items to which counts will be attached.
 * @param {Array<Object>} countArray - The array containing counts to be attached.
 * @param {string} key - The key used to match items with counts in the countArray.
 * @param {string} countKey - The key to which the count value will be attached in each item.
 * @returns {Array<Object>} - The array of items with attached counts.
 *
 * @example
 * const myArray = [{ id: 1 }, { id: 2 }, { id: 3 }];
 * const countArray = [{ itemId: 1, count: 10 }, { itemId: 2, count: 5 }];
 * const updatedArray = attachCountsToArrayOfObjects(myArray, countArray, 'id', 'count');
 * Result: [{ id: 1, count: 10 }, { id: 2, count: 5 }, { id: 3, count: 0 }]
 */
export const attachCountsToArrayOfObjects = (
  items,
  countArray,
  key,
  countKey
) => {
  return items.map((item) => {
    const matchingCount = countArray.find(
      (countItem) => countItem[key] === item.id
    );
    return {
      ...item,
      [countKey]: matchingCount?.[countKey] || 0,
    };
  });
};
