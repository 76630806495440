import { useEffect, useState, useCallback } from 'react';

import DenialsAPI from 'API/DenialsAPI';
import { handleError } from 'helpers/errorHandler';
import AppealNotesAPI from 'API/AppealNotesAPI';
import * as toast from 'components/Shared/toast';

export const useFetchNotes = (id, isAppeal) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState([]);

  const fetch = useCallback(async () => {
    setLoading(true);
    setNotes([]);
    const queryParamsKey = isAppeal ? 'appeal' : 'dq';
    try {
      const { data } = await DenialsAPI.fetchSubmissionsNotes({
        [queryParamsKey]: id,
      });
      setNotes(data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [id, isAppeal]);

  useEffect(() => {
    fetch();
  }, [fetch, id, isAppeal]);

  return { loading, error, fetch, notes, setNotes };
};

export const useCreateNote = (isAppeal) => {
  const [isCreatingNotes, setIsCreatingNotes] = useState(false);
  const createNote = useCallback(
    async (note, id, queryParams = {}) => {
      if (!note || isCreatingNotes) {
        return;
      }

      setIsCreatingNotes(true);
      const requestData = isAppeal ? { id, text: note } : { note };

      try {
        const newNote = isAppeal
          ? await AppealNotesAPI.postNote(requestData)
          : await DenialsAPI.createNote(requestData, id, queryParams);

        toast.success({
          title: 'Success',
          message: `Note successfully added.`,
        });

        return newNote;
      } catch (error) {
        handleError(error);
      } finally {
        setIsCreatingNotes(false);
      }
    },
    [isAppeal, isCreatingNotes]
  );

  return { createNote, isCreatingNotes };
};
