import {
  BASE_API_URL,
  createVndApiRequest,
  createJSONFetch,
  APIConfig,
} from './Config';

import { serializeDocument } from './Mappers';

export default class DocumentsUploadAPI {
  static createOptions() {
    return {
      server: BASE_API_URL,
      signingUrl: 'sign_s3/',
      signingUrlHeaders: APIConfig.processHeaders({}),
    };
  }

  static uploadDocument(submissionID, file) {
    const serializedDocument = serializeDocument(submissionID, file);
    const request = createVndApiRequest('appeal_documents', serializedDocument);
    return createJSONFetch(request);
  }
}
