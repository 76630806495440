import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import React, { useState } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import MaskedInput from 'react-text-mask';

import { parseMoneyInput } from 'helpers/money';
import createNumberMask from 'helpers/createNumberMask';

const Filter = (props) => {
  const {
    filterByMaxAllowed,
    setFilterByMaxAllowed,
    loading,
    initialMaxValue = null,
  } = props;
  const [maxAllowed, setMaxAllowed] = useState(null);
  const [isResetBtnVisible, setIsResetBtnVisible] = useState(false);

  return (
    <div className="ap-scatter-plot-filter">
      <MdInfoOutline
        size="22"
        className="mr-8 mb-4"
        data-tip={
          'Adjust the grid by entering a new Maximum Average Allowed Amount.  Hit "Enter" and the grid will adjust accordingly.'
        }
      />
      <label htmlFor="filter">Change Y-axis</label>
      <ReactTooltip
        effect="solid"
        place="top"
        multiline={true}
        type="info"
        className="appealio-custom-tooltip--info"
      />
      <MaskedInput
        mask={createNumberMask()}
        type="text"
        value={maxAllowed}
        onChange={({ target: { value } }) => {
          const parseMoney = value ? parseMoneyInput(value) : '';
          if (parseMoney !== initialMaxValue) {
            setIsResetBtnVisible(true);
          } else {
            setIsResetBtnVisible(false);
          }
          setMaxAllowed(parseMoney);
        }}
        onBlur={(e) => {
          if (initialMaxValue === maxAllowed) return;
          setFilterByMaxAllowed({ ...filterByMaxAllowed, maxAllowed });
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            setFilterByMaxAllowed({ ...filterByMaxAllowed, maxAllowed });
          }
        }}
        disabled={loading}
      />
      {isResetBtnVisible && (
        <button
          className="ap-button--link ap-button--link-danger ml-12"
          onClick={() => {
            setMaxAllowed(null);
            setFilterByMaxAllowed(0);
            setIsResetBtnVisible(false);
          }}
          onMouseDown={(e) => e.preventDefault()}
        >
          Reset
        </button>
      )}
    </div>
  );
};

Filter.propTypes = {
  loading: PropTypes.bool,
  filterByAvg: PropTypes.object,
  setFilterByAvg: PropTypes.func,
};

export default Filter;
