import { useState, useEffect, useCallback } from 'react';
import { fetchUsersAccessControl } from 'API/UsersAccessControls';

/**
 * Use fetch user access control .
 *
 * @returns {Promise}
 */
export const useFetchUsersAccessControl = (search, page) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [usersAccessControl, setUsersAccessControl] = useState([]);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const queryParam = {
        ...(search && { search }),
        page,
      };

      const {
        data,
        meta: { total },
      } = await fetchUsersAccessControl(queryParam);
      setError(null);
      setUsersAccessControl(data);
      setTotalUsers(total);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [page, search]);

  useEffect(() => {
    fetch();
  }, [page, search, fetch]);

  return {
    fetch,
    loading,
    error,
    totalUsers,
    usersAccessControl,
    setUsersAccessControl,
  };
};
