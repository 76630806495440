import React from 'react';
import classnames from 'classnames';

import './style.css';

const NavigationToggle = (props) => {
  const { options, activeOption, className, onOptionClick } = props;
  return (
    <div className={classnames('navigation-toggle', className)}>
      {options.map((option, idx) => {
        const { icon: Icon } = option;
        const isActiveItem = activeOption?.value === option.value;
        return (
          <div
            className={classnames('navigation-toggle-item cursor-pointer', {
              'navigation-toggle-item--active': isActiveItem,
            })}
            key={idx}
            onClick={() => onOptionClick(option)}
          >
            <div>
              <Icon />
            </div>
            <div
              className={classnames(' navigation-toggle-item-label', {
                'ml-8 navigation-toggle-item-label--visible': isActiveItem,
              })}
            >
              {option.label}
            </div>{' '}
          </div>
        );
      })}
    </div>
  );
};

NavigationToggle.propTypes = {};

export default NavigationToggle;
