import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class LabelValue extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    labelClassName: PropTypes.string,
    valueClassName: PropTypes.string,
  };

  static defaultProps = {
    label: 'Deadline',
    value: '-',
    labelClassName: 'labelvalue__label',
    valueClassName: 'labelvalue__value',
  };

  render() {
    const { label, value, labelClassName, valueClassName } = this.props;
    return (
      <div>
        <label className={labelClassName}>{label}</label>
        <div>
          <span className={valueClassName} datacy={`Total ${label}`}>
            {value}
          </span>
        </div>
      </div>
    );
  }
}
