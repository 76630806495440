import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useEffect, useState } from 'react';

import PracticePopup from './PracticePopup';
import AddPracticeGroup from './AddPracticeGroup';
import PracticeListPopup from './PracticeListPopup';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import {
  SETTINGS_PAGE_LIMIT,
  UNGROUPED_PRACTICE_NAME,
} from 'constants/appConstants';

import {
  DROPDOWN_OPTIONS_STATE_KEYS,
  getDropdownOptions,
} from 'redux/reducers/dropdownOptionsStore';

import { useFetchPracticeGroups } from './hooks';
import { fetchDenialsPracticeOptions } from 'redux/actions/dropdownOptionsActions';
import Pagination from 'components/common/pagination';
import { isClientAdmin } from 'Auth/AuthUtils';

import { getUserInfo } from 'redux/reducers/loginStore';

const PracticeGroup = ({
  actions: { fetchPracticeOptions },
  userInfo,
  practiceOptions,
  ungroupedPractices,
}) => {
  const [page, setPage] = useState(1);
  const [showAddPracticeGroupPopup, setShowAddPracticeGroupPopup] =
    useState(false);
  const [showPracticeList, setShowPracticeList] = useState({
    isOpen: false,
    practiceGroupId: null,
    practiceName: null,
  });
  const [showPracticePopup, setShowPracticePopup] = useState({
    isOpen: false,
    isAddOrRemove: true,
    practiceGroupId: null,
    practiceGroupName: null,
  });

  useEffect(() => {
    fetchPracticeOptions();
  }, [fetchPracticeOptions]);

  const {
    loading,
    practiceGroups,
    fetch: fetchPracticeGroups,
    totalPracticeGroups,
  } = useFetchPracticeGroups(page);

  /**
   * Handles the page change.
   * @param {Number} page
   */
  const onPageChange = (page) => setPage(page);

  /**
   * Handles the assign practice complete.
   */
  const handleAssignPracticeComplete = () => {
    setShowPracticePopup({
      isOpen: false,
      isAddOrRemove: true,
      practiceGroupId: null,
      practiceGroupName: null,
    });
    fetchPracticeOptions(true);
    fetchPracticeGroups();
  };

  const pageCount = Math.ceil(totalPracticeGroups / SETTINGS_PAGE_LIMIT);

  return (
    <div className="settings-content-wrapper">
      <p className="setting-content__description">
        Organize and Manage your Practices into Groups.
      </p>
      {isClientAdmin(userInfo) && (
        <div
          className={classnames(
            'd-flex row align-items-center settings-content__top-action',
            'justify-content--space-between'
          )}
        >
          <button
            onClick={() => setShowAddPracticeGroupPopup(true)}
            className="ap-button ap-button--secondary ml-auto settings-add-button"
          >
            <span className="mr-4">+</span> Add Practice Group
          </button>
        </div>
      )}
      <div className="row mt-12 ap-card-list-wraper">
        <div className="col-lg-12">
          {practiceOptions?.isFetching || loading ? (
            <LoadingBlockHelper
              className="dashboard-bottom-loader"
              isLoading={loading}
            />
          ) : (
            <>
              <div className="row mb-16 create-acount-list-item-header">
                <div className="col-lg-3">Name</div>
                <div className="col-lg-6">Number of Practice(s)</div>
              </div>
              {practiceGroups.map((practiceGroup, idx) => {
                const isUngrouped =
                  practiceGroup.name === UNGROUPED_PRACTICE_NAME;
                return (
                  <div className="create-account-list-item row mb-16" key={idx}>
                    <div className="col-lg-3">
                      {isUngrouped ? 'Ungrouped' : practiceGroup.name}
                    </div>
                    <div
                      className="col-lg-6 link-url"
                      onClick={() => {
                        if (isUngrouped || !isClientAdmin(userInfo)) {
                          return setShowPracticeList({
                            isOpen: true,
                            practiceGroupId: practiceGroup.id,
                            practiceName: isUngrouped
                              ? 'Ungrouped'
                              : practiceGroup.name,
                          });
                        }
                        setShowPracticePopup({
                          isOpen: true,
                          practiceGroupId: practiceGroup.id,
                          practiceGroupName: practiceGroup.name,
                        });
                      }}
                    >
                      {practiceGroup.count}
                    </div>
                    <div className="col-lg-3 text-right">
                      {!isUngrouped && isClientAdmin(userInfo) && (
                        <span
                          className="link-url font-style-italic text-decoration-underline"
                          onClick={() =>
                            setShowPracticePopup({
                              isOpen: true,
                              practiceGroupId: practiceGroup.id,
                              isAddOrRemove: true,
                              practiceGroupName: practiceGroup.name,
                            })
                          }
                        >
                          Add/Remove Practice(s)
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          )}
          {!practiceOptions?.isFetching &&
          !loading &&
          totalPracticeGroups &&
          pageCount > 1 ? (
            <div className="d-flex justify-content-center">
              <Pagination
                pageCount={pageCount}
                activePage={page}
                onPageChange={onPageChange}
              />
            </div>
          ) : null}
        </div>
      </div>
      {showAddPracticeGroupPopup && (
        <AddPracticeGroup
          onClosePressed={() => setShowAddPracticeGroupPopup(false)}
          onAddPracticeGroupSuccess={async (practiceGroup) => {
            fetchPracticeGroups();
            setShowAddPracticeGroupPopup(false);
            fetchPracticeOptions(true);
            setShowPracticePopup({
              isOpen: true,
              practiceGroupId: practiceGroup.id,
              isAddOrRemove: true,
              practiceGroupName: practiceGroup.name,
            });
          }}
        />
      )}
      {showPracticeList.isOpen && (
        <PracticeListPopup
          practiceGroupId={showPracticeList.practiceGroupId}
          title={showPracticeList.practiceName}
          onClosePressed={() => {
            setShowPracticeList({
              isOpen: false,
              practiceGroupId: null,
              practiceName: null,
            });
          }}
        />
      )}
      {showPracticePopup.isOpen && (
        <PracticePopup
          onClosePressed={() => {
            setShowPracticePopup((prevState) => ({
              ...prevState,
              isOpen: false,
            }));
          }}
          practiceGroupId={showPracticePopup.practiceGroupId}
          isAddOrRemove={showPracticePopup.isAddOrRemove}
          ungroupedPractices={ungroupedPractices}
          practiceGroupName={showPracticePopup.practiceGroupName}
          onAddRemovePracticeClick={() =>
            setShowPracticePopup((prevState) => ({
              ...prevState,
              isAddOrRemove: true,
            }))
          }
          onAssignPracticeComplete={handleAssignPracticeComplete}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const practiceOptions = getDropdownOptions(
    state,
    DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PRACTICE_OPTIONS
  );
  const ungroupedPractices =
    practiceOptions?.data?.filter(
      (practice) => practice?.isUngrouped === true
    ) || [];
  return {
    userInfo: getUserInfo(state),
    practiceOptions,
    ungroupedPractices,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { fetchPracticeOptions: fetchDenialsPracticeOptions },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PracticeGroup);
