import React, { useEffect } from 'react';
import { Field } from 'redux-form/immutable';

import { CREATE_OPTION } from 'constants/endpoints';
import { renderReduxDropdownV2 } from '../reduxReactSelect';
import PatientStandaloneForm from './PatientFormForStandAlone';
import CustomCreatableSelect from 'components/common/select/CreatableSelect';
export const TAGS_CHARACTER_LIMIT = 50;
const MAXIMUM_SUBMISSION_TAGS_ALLOWED = 3;

const TagSubmissionForm = (props) => {
  const {
    handleTagSubmission,
    tagSubmissionData,
    payersDropdownOptions,
    onTagInputChange,
    isStandAlone,
    isAppealioExpressUser,
    change,
    disablePayer,
    submissionTypeIndexSelected,
    enablePayer,
  } = props;

  useEffect(() => {
    return () => {
      change('payers', null); // Info: For Mail and Fax selections, the payer value should not be populated. It should only be auto-selected for the payer portal when the payer contact is selected.
      enablePayer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionTypeIndexSelected]);

  const handleChange = (selectedTags, actionMeta) => {
    if (actionMeta.action === CREATE_OPTION) {
      if (tagSubmissionData.tags.length >= MAXIMUM_SUBMISSION_TAGS_ALLOWED) {
        return;
      }
    }
    const tagsList = selectedTags.map((tag) => ({
      ...tag,
      label: tag.label.toLowerCase(),
      value: tag.value.toLowerCase(),
    }));

    handleTagSubmission(tagsList);
  };

  return (
    <div>
      <div className="row mt-20">
        <h5 className="fw-bold mb-8">Optional Fields</h5>
        {isStandAlone && !isAppealioExpressUser && <PatientStandaloneForm />}

        <h5>Add Tags</h5>
        <Field
          component={CustomCreatableSelect}
          name="tagSubmission"
          onInputChange={onTagInputChange}
          handleChange={handleChange}
          values={tagSubmissionData.tags}
          required={true}
          optionsCharacterLimit={TAGS_CHARACTER_LIMIT}
          shouldDisableWhitespace={true}
        />
      </div>
      <br></br>
      <div className="row">
        {isStandAlone && payersDropdownOptions.length > 0 && (
          <div className="col-xs-6">
            <div className="col-xs-11">
              <label className="textfield__label">Payer:</label>
              <Field
                component={renderReduxDropdownV2}
                dropdownOptions={payersDropdownOptions}
                name="payers"
                placeholder="Select Payer"
                showLabelTooltip
                datacy="payers-Field"
                isClearable
                isDisabled={disablePayer}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TagSubmissionForm;
