import classnames from 'classnames';
import React, { useState } from 'react';
import moment from 'moment';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { MdInfoOutline } from 'react-icons/md';

import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';

import calendar from 'img/calendar-icon.svg';

import Widget from '../Widget';
import Select from 'components/common/select/Select';
import { DatePicker } from 'components/common/datepicker';

import closeIcon from 'img/close-modal.svg';

import { DEFAULT_FILTER } from '../Analytics';

import { ALL_OPTION_LABEL } from 'constants/appConstants';

import { extractObjectMap } from 'helpers/object';

const CLAIMS_THRESHOLD = 500;

const Filter = (props) => {
  const {
    filters,
    handleFilterChange,
    payersDropdownOptions,
    practiceDropdownOptions,
    disableFilter,
    resetFilters,
    isDataCountsLoading,
    claimsCount,
  } = props;

  const [showAnalyticsInformation, setShowAnalyticsInformation] =
    useState(false);

  const today = moment();
  const defaultEndDate = moment(today).endOf('month').subtract(2, 'months');
  const startDate = filters.start
    ? moment(filters.start)
    : moment(today).startOf('month').subtract(13, 'months');
  const endDate = filters.end
    ? moment(filters.end)
    : moment(today).endOf('month').subtract(2, 'months');
  const startDateLimit = moment(today).startOf('month').subtract(14, 'months');

  const extractLabelSuffix = (countValue) => {
    if (!countValue) return '';

    return `(${countValue.toLocaleString()})`;
  };

  const getInitialPayersWithCount = () => {
    if (!claimsCount) return [];

    const payersCountMap = extractObjectMap(claimsCount.payers, 'id', 'count');

    const optionsWithCount = payersDropdownOptions
      .map((option) => {
        return {
          ...option,
          label: `${option.label} ${extractLabelSuffix(
            payersCountMap[option.value]
          )}`,
          count: payersCountMap[option.value] || 0,
          isActive: payersCountMap[option.value] >= CLAIMS_THRESHOLD,
        };
      })
      .sort((a, b) => b.count - a.count);
    return optionsWithCount;
  };

  /**
   * Returns payer options with count.
   * @returns {Array}
   */
  const getPayerOptionsWithCount = () => {
    if (!claimsCount) {
      return [];
    }

    const ALL_OPTION = {
      label: 'All',
      value: null,
    };

    const totalCount = claimsCount.claims.count;

    // If not practice selected return all options with count
    if (!filters.practice) {
      const optionsWithCount = getInitialPayersWithCount();
      return [
        {
          ...ALL_OPTION,
          label: `${ALL_OPTION.label} (${totalCount.toLocaleString()})`,
        },
        ...optionsWithCount,
      ];
    }

    const selectedPracticeOption = practiceDropdownOptions.find(
      (option) => option.value === filters.practice
    );

    const payersCountWithPractice = claimsCount.practices.find(
      ({ identifier }) => {
        return identifier === selectedPracticeOption.key;
      }
    );

    if (!payersCountWithPractice) {
      return [];
    }

    const payersCountMap = extractObjectMap(
      payersCountWithPractice.payers,
      'id',
      'count'
    );

    const filteredPayers = payersDropdownOptions.filter((option) => {
      return payersCountMap[option.value];
    });

    const optionsWithCount = filteredPayers
      .map((option) => {
        return {
          ...option,
          label: `${option.label} ${extractLabelSuffix(
            payersCountMap[option.value]
          )}`,
          count: payersCountMap[option.value],
          isActive: payersCountMap[option.value] >= CLAIMS_THRESHOLD,
        };
      })
      .sort((a, b) => b.count - a.count);

    return [
      {
        ...ALL_OPTION,
        label: `All claims for selected practice (${payersCountWithPractice.count.toLocaleString()})`,
      },
      ...optionsWithCount,
    ];
  };

  const getInitialPracticesWithCount = () => {
    if (!claimsCount) return [];

    const practiceCountMap = extractObjectMap(
      claimsCount.practices,
      'identifier',
      'count'
    );

    const optionsWithCount = practiceDropdownOptions
      .map((option) => {
        return {
          ...option,
          label: `${option.label} ${extractLabelSuffix(
            practiceCountMap[option.key]
          )}`,
          count: practiceCountMap[option.key] || 0,
          isActive: practiceCountMap[option.key] >= CLAIMS_THRESHOLD,
        };
      })
      .sort((a, b) => b.count - a.count);

    return optionsWithCount;
  };

  /**
   * Returns practice options with count.
   * @returns {Array}
   */
  const getPracticeOptionsWithCount = () => {
    if (!claimsCount) {
      return [];
    }

    const ALL_OPTION = {
      label: 'All',
      value: null,
    };

    const totalCount = claimsCount.claims.count;

    if (!filters.payer) {
      const optionsWithCount = getInitialPracticesWithCount();
      return [
        {
          ...ALL_OPTION,
          label: `${ALL_OPTION.label} (${totalCount.toLocaleString()})`,
        },
        ...optionsWithCount,
      ];
    }

    const practiceCounts = claimsCount.practices.reduce((acc, cur) => {
      const payer = cur.payers.find(({ id }) => id === filters.payer);

      if (!payer) {
        return acc;
      }

      return [
        ...acc,
        {
          identifier: cur.identifier,
          count: payer.count,
        },
      ];
    }, []);

    const practiceCountMap = extractObjectMap(
      practiceCounts,
      'identifier',
      'count'
    );

    const filteredPractices = practiceDropdownOptions.filter((option) => {
      return practiceCountMap[option.key];
    });

    const optionsWithCount = filteredPractices
      .map((option) => {
        return {
          ...option,
          label: `${option.label} ${extractLabelSuffix(
            practiceCountMap[option.key]
          )}`,
          count: practiceCountMap[option.key],
          isActive: practiceCountMap[option.key] >= CLAIMS_THRESHOLD,
        };
      })
      .sort((a, b) => b.count - a.count);

    const practiceCountWithPayer = claimsCount.payers.find(({ id }) => {
      return id === filters.payer;
    });

    return [
      {
        ...ALL_OPTION,
        label: `All claims for selected payer (${practiceCountWithPayer.count.toLocaleString()})`,
      },
      ...optionsWithCount,
    ];
  };

  const practiceOptionsWithCount = getPracticeOptionsWithCount();
  const payerOptionsWithCount = getPayerOptionsWithCount();

  const payersTotalClaimCount =
    claimsCount && claimsCount.claims.count.toLocaleString();
  const payerInfo = `Total claim count by all payers (${
    payersTotalClaimCount || 'loading...'
  }). <br/> * Payer(s) with claim count below ${CLAIMS_THRESHOLD} are inactive.`;

  const practicesTotalClaimCount =
    claimsCount && claimsCount.claims.count.toLocaleString();
  const practiceInfo = `Total claim count by all practices (${
    practicesTotalClaimCount || 'loading...'
  }). <br/>  * Practice(s) with claim count below ${CLAIMS_THRESHOLD} are inactive.`;

  return (
    <Widget
      widgetTitle={{
        title: 'Analytics Dashboard',
        titleClassName: 'v3__widget-title--lg mb-32',
      }}
      isClearable={{
        clearTitle: 'Reset',
        onClear: () => {
          resetFilters();
        },
        isVisible: true,
        isActive: !isEqual(DEFAULT_FILTER, filters),
      }}
      className="v3__analytics-widget--no-shadow"
      isAnalyticsInfo={true}
    >
      <div className="title-information">
        <MdInfoOutline
          size="22"
          className="ml-8"
          onClick={() => setShowAnalyticsInformation(true)}
        />
      </div>

      <div className="row equal v3__analytics-filter-container">
        <div className="col-sm-12 col-md-4 col-lg-4">
          <div className="d-flex align-items-center justify-content--space-between">
            <div className="form-group">
              <label className="ap-select-label">Start Date</label>

              <div className="start-date">
                <DatePicker
                  id="startDate"
                  displayFormat="LL"
                  isOutsideRange={(v) =>
                    !(
                      v.isBetween(startDateLimit, endDate) ||
                      v.isSame(startDate) ||
                      v.isSame(endDate)
                    )
                  }
                  disabled={disableFilter}
                  customInputIcon={
                    <object
                      className="filter-date-picker-icon"
                      data={calendar}
                      aria-label="calendar icon for the start date"
                      type="image/svg+xml"
                    ></object>
                  }
                  date={moment(startDate)}
                  onDateChange={(date) => {
                    date = moment(date);
                    if (!date.isValid()) {
                      return;
                    }

                    handleFilterChange({
                      ...filters,
                      start: date.toDate().toLocaleDateString('en-US'),
                      end: endDate.toDate().toLocaleDateString('en-US'),
                    });
                  }}
                  numberOfMonths={1}
                  orientation={'horizontal'}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="ap-select-label">End Date</label>
              <div className="end-date">
                <DatePicker
                  id="endDate"
                  displayFormat="LL"
                  isOutsideRange={(v) =>
                    !(
                      v.isBetween(startDate, defaultEndDate) ||
                      v.isSame(endDate) ||
                      v.isSame(startDate)
                    )
                  }
                  customInputIcon={
                    <object
                      className="filter-date-picker-icon"
                      data={calendar}
                      aria-label="calendar icon for the end date"
                      type="image/svg+xml"
                    ></object>
                  }
                  date={endDate}
                  disabled={disableFilter}
                  onDateChange={(date) => {
                    date = moment(date);
                    if (!date.isValid()) {
                      return;
                    }
                    handleFilterChange({
                      ...filters,
                      start: startDate.toDate().toLocaleDateString('en-US'),
                      end: date.toDate().toLocaleDateString('en-US'),
                    });
                  }}
                  numberOfMonths={1}
                  orientation={'horizontal'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4">
          <Select
            label="Payer"
            placeholder={ALL_OPTION_LABEL}
            classNamePrefix="v3__analytics-dropdown"
            isLoading={isDataCountsLoading}
            options={getPayerOptionsWithCount()}
            onChange={({ value }) => {
              handleFilterChange({
                ...filters,
                payer: value || null,
              });
            }}
            value={
              payerOptionsWithCount.find(
                (option) => option.value === filters.payer
              ) || {
                label: ALL_OPTION_LABEL,
                value: null,
              }
            }
            reactSelectProps={{
              isDisabled: disableFilter,
            }}
            labelHelperToolTip={payerInfo}
          />
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4">
          <Select
            label="Practice"
            placeholder={ALL_OPTION_LABEL}
            classNamePrefix="v3__analytics-dropdown"
            isLoading={isDataCountsLoading}
            options={practiceOptionsWithCount}
            onChange={({ value }) => {
              handleFilterChange({
                ...filters,
                practice: value,
              });
            }}
            value={
              practiceOptionsWithCount.find(
                (option) => option.value === filters.practice
              ) || {
                label: ALL_OPTION_LABEL,
                value: '',
              }
            }
            reactSelectProps={{
              isDisabled: disableFilter,
            }}
            labelHelperToolTip={practiceInfo}
          />
        </div>
      </div>
      {showAnalyticsInformation && (
        <ScreenDimmerComponent>
          <div
            className={classnames(
              'appealio-dialog-popup appealio-popup--info ',
              {}
            )}
          >
            <img
              alt="Close"
              onClick={() => setShowAnalyticsInformation(false)}
              className="appealio-dialog-popup__close-btn"
              src={closeIcon}
            />
            <div className="appealio-dialog-popup__content">
              <h4>Analytics Data Based Upon:</h4>
              <ul className="appealio-information-list">
                <li>
                  <h5 className="appealio-information-list__title">
                    Payers First Response (First ERA) only for each claim
                  </h5>
                </li>
                <li>
                  <h5 className="appealio-information-list__title">
                    Dates Filtered By “Service Date” (not EOB or payment date)
                  </h5>
                </li>
                <li>
                  <h5 className="appealio-information-list__title">
                    Primary Payer data only
                  </h5>
                </li>
              </ul>
            </div>
          </div>
        </ScreenDimmerComponent>
      )}
    </Widget>
  );
};

Filter.propTypes = {
  filters: PropTypes.object.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
};

export default Filter;
