import { useEffect, useState } from 'react';

import { searchCodes } from 'API/CodesAPI';

/**
 * Use fetch codes descriptions hook.
 */
const useFetchCodesDescriptions = (reasonCodes, remarkCodes) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    carcs: [],
    rarcs: [],
  });

  useEffect(() => {
    setLoading(true);

    const fetch = async () => {
      try {
        const { data } = await searchCodes({
          carcs: reasonCodes || [],
          rarcs: remarkCodes || [],
        });

        const carcs = data.carcs.map((carc) => {
          const code = reasonCodes.find((reasonCode) =>
            reasonCode.includes(carc.code)
          );

          return {
            ...carc,
            code,
          };
        });

        setData({
          ...data,
          carcs,
        });
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    };

    fetch();
  }, [reasonCodes, remarkCodes]);

  return { loading, error, data };
};

export default useFetchCodesDescriptions;
