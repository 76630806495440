import { xor } from 'lodash';
import { connect } from 'react-redux';

import React, { useState } from 'react';
import {
  Field,
  formValueSelector,
  initialize,
  reduxForm,
  SubmissionError,
} from 'redux-form/immutable';
import { bindActionCreators } from 'redux';

import Input from 'components/common/input';
import TextArea from 'components/common/textarea';
import CheckBox from 'components/Shared/CheckBox';
import Select from 'components/common/select/Select';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import CustomDropdown from 'components/common/dropdown/ButtonDropdown/CustomDropdown';

import * as AccountsAPI from 'API/AccountSettingsAPI';

import { handleError } from 'helpers/errorHandler';
import {
  fieldCharacterValidation,
  zipCodeValidation,
} from 'helpers/validators';

import { useFetchPublicPayers } from '../hook';
import * as toast from 'components/Shared/toast';
import AddPayerIdentifierForm from './AddPayerIdentifier';
import { AppealioPopupWithFooter } from 'components/common/popup';

import { states } from 'components/CreateAppeal/state-mock-data';

const PAYER_SELECTION_TYPE = {
  GENERIC_LIST: 'GENERIC_LIST',
  CUSTOM_PAYER: 'CUSTOM_PAYER',
};

const AddPayerForm = (props) => {
  const {
    handleSubmit,
    onSubmit,
    onPayerCloneSubmit,
    initialValues,
    invalid,
    submitting,
    isSuperUser,
    payerIdentifiersOptions,
    isPayerIdentifiersLoading,
    addPayerIdentifierOption,
    identifiers,
    change,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [payerSelectionType, setPayerSelectionType] = useState(
    PAYER_SELECTION_TYPE.CUSTOM_PAYER
  );
  const [payerIds, setPayerIds] = useState([]);
  const [isPayerIdentifierPopupOpen, setIsPayerIdentifierPopupOpen] =
    useState(false);

  const { payers = [], loading: isPayersLoading } = useFetchPublicPayers();

  const stateOptions = states.map((state) => ({
    label: state.value,
    value: state.key,
  }));

  const validate = (values) => {
    const errors = {
      name: 'Payer Name is required',
      // payerAddress1: "Payer Address Line 1 is required",
      // city: "Payer City is required",
      // state: "Payer State is required",
      // zipcode: "Payer Zipcode is required",
    };

    // if (
    //   !values.get("name") &&
    //   !values.get("payerAddress1") &&
    //   !values.get("city") &&
    //   !values.get("state") &&
    //   !values.get("zipcode")
    // ) {
    //   throw new SubmissionError(errors);
    // }

    if (values.get('name')) {
      delete errors.name;
    }

    // if (values.get("payerAddress1")) {
    //   delete errors.payerAddress1;
    // }

    // if (values.get("city")) {
    //   delete errors.city;
    // }

    // if (values.get("state")) {
    //   delete errors.state;
    // }

    if (values.get('zipcode')) {
      const zipValidation = zipCodeValidation(values.get('zipcode'));
      if (zipValidation) {
        errors.zipcode = zipValidation;
      }
    }
    if (values.get('name')) {
      const payerNameValidation = fieldCharacterValidation(
        'Payer Name',
        values.get('name')
      );
      if (payerNameValidation) {
        errors.name = payerNameValidation;
      }
    }

    if (Object.keys(errors).length) {
      throw new SubmissionError(errors);
    }
  };

  const handleFormSubmit = async (formValues) => {
    const values = formValues.toJS();
    let formData = {};
    validate(formValues);

    if (payerSelectionType === PAYER_SELECTION_TYPE.GENERIC_LIST) {
      formData = {
        payerIds,
      };
      onPayerCloneSubmit(formData);
    } else {
      formData = {
        ...values,
        state: values.state ? values.state.value : null,
      };

      let data;
      try {
        const res = await AccountsAPI.fetchPayers({
          page: 1,
          name: values.name,
        });
        data = res.data;
      } catch (error) {
        return handleError(error);
      }

      if (data.length > 0) {
        const payerId = initialValues && initialValues.toJS().id;
        const existingPayers = data.filter((payer) => payer.id !== payerId);
        if (existingPayers.length > 0) {
          throw new SubmissionError({
            name: 'Payer name already used',
          });
        }
      }
      await onSubmit(formData);
    }
  };

  const handleEdiPayerIdFormSubmit = async (data) => {
    try {
      const { id, identificationNumber, partnerName } =
        await AccountsAPI.createPayerIdentifier(data);
      toast.success({
        title: 'Success',
        message: 'EDI Payer ID added successfully.',
      });
      const option = {
        id,
        value: id,
        label: `${identificationNumber} (${partnerName || 'N/A'})`,
      };

      setIsPayerIdentifierPopupOpen(false);
      const newPayerIdentifiers = [...identifiers, option];
      change('identifiers', newPayerIdentifiers);
      addPayerIdentifierOption(option);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)} datacy="add-payer-form">
        {/* {!initialValues && (
      <div className="form-step__wrapper">
        <h5 className="form-step__title">
            <span className="form-step__counter">1.</span> Select one
          </h5>

        <p className="form-step__description">
        Either add payer OR select one from the generic list.
        </p>

          <div className="form-step__content">
            <div className="d-flex align-items-center">
            <label className="radio-btn-label mr-12" htmlFor="custom-payer">
                <input
                  className="mr-8"
                  type="radio"
                  id="custom-payer"
                  checked={
                    payerSelectionType === PAYER_SELECTION_TYPE.CUSTOM_PAYER
                  }
                  onChange={onPayerSelectionTypeChange}
                  name={PAYER_SELECTION_TYPE.CUSTOM_PAYER}
                  value={PAYER_SELECTION_TYPE.CUSTOM_PAYER}
                />
                Add Payer
              </label>
              <label className="radio-btn-label mr-12" htmlFor="generic-list">
                <input
                  className="mr-8"
                  type="radio"
                  id="generic-list"
                  checked={
                    payerSelectionType === PAYER_SELECTION_TYPE.GENERIC_LIST
                  }
                  onChange={onPayerSelectionTypeChange}
                  name={PAYER_SELECTION_TYPE.GENERIC_LIST}
                  value={PAYER_SELECTION_TYPE.GENERIC_LIST}
                />
                Generic List
              </label>
            </div>
          </div>

      </div>
       )} */}
        {payerSelectionType === PAYER_SELECTION_TYPE.CUSTOM_PAYER && (
          <div className="form-step__wrapper">
            <h5 className="form-step__title">
              {/* <span className="form-step__counter">
              {!initialValues ? "2. " : null}
            </span> */}
              {!initialValues ? 'Enter new' : 'Edit'} Payer Info
            </h5>
            <div className="form-step__content">
              <div className="row mb-12">
                <div className="col-md-6">
                  <Field
                    component={Input}
                    name="name"
                    label="Payer Name *"
                    placeholder="Payer Name"
                    required
                    datacy="payer-name-Field"
                  />
                </div>
              </div>
              <div className="row mb-12">
                <div className="col-md-6">
                  <Field
                    component={Input}
                    name="payerAddress1"
                    label="Payer Address Line 1"
                    placeholder="Payer Address Line 1"
                    datacy="payer-address-line-1-Field"
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    component={Input}
                    name="payerAddress2"
                    label="Payer Address Line 2"
                    placeholder="Payer Address Line 2"
                    datacy="payer-address-line-2-Field"
                  />
                </div>
              </div>
              <div className="row mb-12">
                <div className="col-md-4">
                  <Field
                    component={Input}
                    name="city"
                    label="Payer City"
                    placeholder="Payer City"
                    datacy="payer-city-Field"
                  />
                </div>
                <div className="col-md-4">
                  <Field
                    component={Select}
                    options={stateOptions}
                    name="state"
                    defaultValue={null}
                    label="Payer State"
                    placeholder="Payer State"
                    reactSelectProps={{
                      isClearable: true,
                    }}
                    datacy="payer-state-Field"
                  />
                </div>
                <div className="col-md-4">
                  <Field
                    component={Input}
                    name="zipcode"
                    label="Payer Zip"
                    placeholder="Payer Zip"
                    datacy="payer-zipcode-Field"
                  />
                </div>
              </div>
              {isSuperUser && (
                <div className="row mb-12">
                  <div className="col-md-6">
                    <Field
                      component={TextArea}
                      name="alias"
                      label="Alias"
                      datacy="payer-alias-Field"
                      resize={false}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      component={CustomDropdown}
                      options={payerIdentifiersOptions}
                      name="identifiers"
                      label="EDI Payer IDs"
                      isDisabled={isPayerIdentifiersLoading}
                      isMulti
                      placeholder={'Select EDI Payer ID'}
                      maxListItems={6}
                    />
                  </div>
                  <span className="add-edi-payerId">
                    If EDI Payer ID not found,
                    <span onClick={() => setIsPayerIdentifierPopupOpen(true)}>
                      {'click here to Add New'}
                    </span>
                  </span>
                </div>
              )}
            </div>
          </div>
        )}

        {payerSelectionType === PAYER_SELECTION_TYPE.GENERIC_LIST && (
          <div className="form-step__wrapper">
            <h5 className="form-step__title">
              <span className="form-step__counter">2.</span>Select Payer(s)
            </h5>
            <div className="create-account-card-content">
              {!isPayersLoading ? (
                payers.map((payer, idx) => {
                  return (
                    <div key={payer?.id} className="template-list mt-12">
                      <CheckBox
                        title={payer.name}
                        checked={payerIds.includes(payer.id)}
                        onChange={() => {
                          const newPayerIds = xor(payerIds, [payer.id]);

                          setPayerIds(newPayerIds);
                        }}
                        className="mb-0"
                        datacy="payer-name-CheckBox"
                      />
                    </div>
                  );
                })
              ) : (
                <div>
                  <LoadingIndicator className="mt-18" showing={true} />
                </div>
              )}
            </div>
          </div>
        )}
        <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
          <button
            type="submit"
            disabled={invalid || submitting || isPayerIdentifierPopupOpen}
            className="ap-button ap-button--primary-dark ap-button--primary-md"
            datacy="add-payer-form-submit-button"
          >
            {props.initialValues
              ? 'Update'
              : payerSelectionType === PAYER_SELECTION_TYPE.CUSTOM_PAYER
              ? 'Next'
              : 'Add'}
          </button>
        </div>
      </form>
      {isPayerIdentifierPopupOpen && isSuperUser && (
        <AppealioPopupWithFooter
          className="payerIdentifier-popup--v3"
          onClosePressed={() => setIsPayerIdentifierPopupOpen(false)}
          title={'Add EDI Payer ID'}
          isFooterOutside={false}
          datacy="add-user-AppealioPopupWithFooter"
        >
          <AddPayerIdentifierForm onSubmit={handleEdiPayerIdFormSubmit} />
        </AppealioPopupWithFooter>
      )}
    </>
  );
};

function mapStateToProps(state) {
  const selector = formValueSelector('addPayerForm');
  const identifiers = selector(state, 'identifiers') || [];
  const newPayerIdentifiers = identifiers?.toJS
    ? identifiers?.toJS()
    : identifiers;

  return {
    identifiers: newPayerIdentifiers || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ initialize }, dispatch),
  };
}

export default reduxForm({
  form: 'addPayerForm',
})(connect(mapStateToProps, mapDispatchToProps)(AddPayerForm));
