import React, {
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';

import Widget from '../Widget';
import ScatterPlot from './ScatterPlot';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import EmptyWidgetPlaceholder from 'components/ApDashboard/Manager/EmptyWidgetPlaceholder';

import { useFetchAverageAllowedAmountByProcedureCodes } from './hooks';
import Filter from './Filter';

export const AVERAGE_AMOUNT = 'Average Amount';

const TOP_PAYERS_CLASSNAME_BY_INDEX = {
  0: 'top-payer-1',
  1: 'top-payer-2',
  2: 'top-payer-3',
};

const AVG_ALLOWED_AMT_GRAPH_ID = 'avg-allowed-amt-scatter-plot';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const AverageAllowedAmount = forwardRef((props, ref) => {
  const {
    selectedThreePayerIds = [],
    payersId = [],
    isFetchingData,
    setIsFetchingData,
    payerNotInTopPayerList,
    isDownloading,
  } = props;
  const scatterPlotRef = useRef();
  const [width, height] = useWindowSize();
  const [filterByMaxAllowed, setFilterByMaxAllowed] = useState({});

  const { error, loading, averageAmounts } =
    useFetchAverageAllowedAmountByProcedureCodes(payersId, props.filters);

  useEffect(() => {
    setIsFetchingData((isFetchingData) => ({
      ...isFetchingData,
      isFetchingTopProcedureCodesData: loading,
    }));
    // eslint-disable-next-line
  }, [loading]);

  const payerNameMapById = React.useMemo(() => {
    return averageAmounts.reduce((acc, cur) => {
      const { payers } = cur;
      payers.forEach((payer) => {
        acc[payer.id] = payer.payer;
      });
      return acc;
    }, {});
  }, [averageAmounts]);

  useEffect(() => {
    setTimeout(() => {
      const element = scatterPlotRef.current;

      if (!element) return;

      const linesElements = element.getElementsByClassName('ct-point');

      for (let i = 0; i < linesElements.length; i++) {
        const line = linesElements[i];
        const ctMeta = line.getAttribute('ct:meta');
        if (ctMeta) {
          if (ctMeta === AVERAGE_AMOUNT) {
            line.classList.add('average-ct-point');
          } else if (!isNaN(ctMeta)) {
            const payerId = Number(ctMeta);
            line.setAttribute('ct:meta', payerNameMapById[payerId]);
            const topPayerClassNameLabel = selectedThreePayerIds.findIndex(
              (meta) => meta === payerId
            );
            if (topPayerClassNameLabel !== -1) {
              line.classList.add(
                TOP_PAYERS_CLASSNAME_BY_INDEX[topPayerClassNameLabel]
              );
            }
          }
        }
      }
    }, 20);
  }, [
    payerNameMapById,
    selectedThreePayerIds,
    width,
    height,
    isFetchingData,
    filterByMaxAllowed,
  ]);

  const maxValue = averageAmounts.reduce((acc, cur) => {
    const values = cur.payers.map((payer) => payer.averageAmount);
    const maxValue = Math.max(...values);
    return maxValue > acc ? maxValue : acc;
  }, 0);

  const labels = [
    null, // don't show label on x = 0
    ...averageAmounts.map(
      (item) =>
        `${item.procedureCode} \n \n (${item.claimCount.toLocaleString()})`
    ),
  ];

  const series = averageAmounts.reduce((acc, cur, idx) => {
    // idx + 1 to account for [x = 0]
    const emptyArray = Array(idx + 1).fill(null);
    const values = cur.payers.map(({ id, averageAmount = 0 }) => {
      return [
        ...emptyArray,
        {
          value: Number(averageAmount.toFixed(2)),
          meta: id,
        },
      ];
    });

    const averageAmountByProcedureCode = [
      ...emptyArray,
      {
        value: Number(cur.averageAmt ? cur.averageAmt.toFixed(2) : 0),
        meta: AVERAGE_AMOUNT,
      },
    ];

    return [...acc, ...values, averageAmountByProcedureCode];
  }, []);
  const data = {
    labels,
    series,
  };

  const resetButton = (
    <button
      ref={ref}
      onClick={() => setFilterByMaxAllowed(0)}
      className="d-none"
    >
      reset
    </button>
  );

  if (payerNotInTopPayerList) {
    return (
      <Widget
        widgetTitle={{
          title: 'Average Allowed Amount by Top 10 Procedure Code',
          titleClassName: 'v3__widget-title--lg',
        }}
        className=" v3__analytics-widget--no-radius-left"
      >
        <EmptyWidgetPlaceholder
          className="mt-40"
          title={'N/A: Selected Payer does not fall within the Top 10 Payers.'}
        />
      </Widget>
    );
  }

  if (error) {
    return (
      <Widget
        widgetTitle={{
          title: 'Average Allowed Amount by Top 10 Procedure Code',
          titleClassName: 'v3__widget-title--lg',
        }}
        className=" v3__analytics-widget--no-radius-left"
      >
        <EmptyWidgetPlaceholder title="Opps! Something went wrong!" />
      </Widget>
    );
  }

  const isPayersAvailable = averageAmounts.filter(
    (amount) => amount.payers.length
  );

  if (!averageAmounts.length && !loading) {
    return (
      <Widget
        widgetTitle={{
          title: 'Average Allowed Amount by Top 10 Procedure Code',
          titleClassName: 'v3__widget-title--lg',
        }}
        className=" v3__analytics-widget--no-radius-left"
      >
        <EmptyWidgetPlaceholder title="No Data Available" />
      </Widget>
    );
  }

  const highPoint = filterByMaxAllowed.maxAllowed
    ? filterByMaxAllowed.maxAllowed
    : maxValue;

  return (
    <Widget
      widgetTitle={{
        title: 'Average Allowed Amount by Top 10 Procedure Code',
        titleClassName: 'v3__widget-title--lg',
      }}
      className="v3__analytics-widget--no-radius-left"
    >
      {resetButton}
      {!maxValue && !filterByMaxAllowed.maxAllowed ? (
        <LoadingIndicator showing={true} />
      ) : (
        <Filter
          loading={loading}
          filterByMaxAllowed={filterByMaxAllowed}
          setFilterByMaxAllowed={setFilterByMaxAllowed}
          initialMaxValue={maxValue}
        />
      )}
      {loading ? (
        <LoadingIndicator showing={true} />
      ) : isPayersAvailable.length ? (
        isDownloading ? (
          <div></div>
        ) : (
          <ScatterPlot
            data={data}
            highPoint={highPoint}
            scatterPlotRef={scatterPlotRef}
            id={AVG_ALLOWED_AMT_GRAPH_ID}
          />
        )
      ) : (
        <EmptyWidgetPlaceholder title="No Data Available" className="mt-40" />
      )}
    </Widget>
  );
});

AverageAllowedAmount.propTypes = {
  filters: PropTypes.object,
};

export default AverageAllowedAmount;
