import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import './style.css';

const Tab = ({
  title,
  onClick,
  isActive,
  className,
  disabled = false,
  dataTip = '',
}) => {
  return (
    <button
      disabled={disabled}
      className={classNames('tab-item', className, { active: isActive })}
      onClick={onClick}
      data-tip={dataTip}
    >
      {title}
      {dataTip && <ReactTooltip effect="solid" place="top" />}
    </button>
  );
};

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dataTip: PropTypes.string,
};

export default Tab;
