import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ErrorBanner from './ErrorBanner';

export default class ErrorBannerSection extends Component {
  static propTypes = {
    errors: PropTypes.array.isRequired,
    className: PropTypes.string.isRequired,
    datacy: PropTypes.string,
  };

  static defaultProps = {
    errors: [],
  };

  renderErrors = () => {
    if (this.props.errors.length > 0) {
      return (
        <ErrorBanner errors={this.props.errors} datacy="error-banner-section" />
      );
    }
  };

  render() {
    return <div className={this.props.className}>{this.renderErrors()}</div>;
  }
}
