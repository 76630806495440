import { degrees, PDFDocument } from 'pdf-lib';

/**
 * Rotates pages of a PDF document based on their orientation.
 *
 * @param {Buffer} pdfBuffer - The buffer of the PDF document to be modified.
 * @param {boolean} [clockwise=false] - Determines the direction of rotation.
 *                                     `true` for clockwise, `false` for counterclockwise.
 * @returns {Promise<Buffer>} - A buffer of the modified PDF document if pages were rotated,
 *                              otherwise the original buffer.
 */
export async function rotatePdfPage(pdfBuffer, clockwise = false) {
  const pdfDoc = await PDFDocument.load(pdfBuffer);
  let pagesModified = false;

  const pages = pdfDoc.getPages();
  for (const page of pages) {
    const { width, height } = page.getSize();
    const currentRotation = page.getRotation()?.angle || 0;

    const isLandscape =
      currentRotation === 0 || currentRotation === 180
        ? width > height
        : height > width;

    if (isLandscape) {
      const rotationIncrement = clockwise ? 90 : -90;
      const newRotation = (currentRotation + rotationIncrement) % 360;
      page.setRotation(degrees(newRotation));
      pagesModified = true;
    }
  }

  return pagesModified ? await pdfDoc.save() : pdfBuffer;
}

/**
 * Match the page number in the PDF file with the corresponding URL.
 * @param {Array} pageNumbers
 * @param {ArrayBuffer} buffer
 * @returns {Promise<Array>}
 */
export const getMatchingPagesWithUrl = async (pageNumbers, buffer) => {
  const pdfLibDocument = await PDFDocument.load(buffer);
  const matchingPages = [];

  for (const pageNumber of pageNumbers) {
    const newPdfDoc = await PDFDocument.create();
    const pageIndex = pageNumber - 1;
    const [copiedPage] = await newPdfDoc.copyPages(pdfLibDocument, [pageIndex]);
    newPdfDoc.addPage(copiedPage);
    const pdfBytes = await newPdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.addEventListener('unload', () => URL.revokeObjectURL(url));

    matchingPages.push({ url, currentPage: pageNumber });
  }

  return matchingPages;
};

export const getPDFFileAsArrayBuffer = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

/**
 * Extracts the number of pages from a PDF file.
 *
 * @param {Uint8Array | ArrayBuffer} file
 * @returns {Promise<number>} Number of pages in the PDF file.
 */
export async function extractFilePageNumbers(file) {
  const pdfDoc = await PDFDocument.load(file);
  return pdfDoc.getPageCount();
}

export async function getPageFromPdfFile(fileUrl, pageNumber) {
  try {
    const arrayBuffer = await fetch(fileUrl).then((res) => res.arrayBuffer());
    const inputPdf = await PDFDocument.load(arrayBuffer);
    const outputPdf = await PDFDocument.create();
    if (pageNumber < 1 || pageNumber > inputPdf.getPageCount()) {
      throw new Error('Page number out of bounds');
    }
    const [copiedPage] = await outputPdf.copyPages(inputPdf, [pageNumber - 1]);
    outputPdf.addPage(copiedPage);

    const pdfBytes = await outputPdf.save();

    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const objectUrl = URL.createObjectURL(blob);
    return objectUrl;
  } catch (error) {
    // eslint-disable-next-line
    console.error('Error processing PDF:', error);
    throw error;
  }
}

/**
 * Extracts the number of pages from a PDF file.
 * @param {ArrayBuffer} arrayBuffer
 * @returns {Promise<number>} Number of pages in the PDF file.
 */
export async function getPDFPageCount(arrayBuffer) {
  const pdfDoc = await PDFDocument.load(arrayBuffer);
  return pdfDoc.getPageCount();
}

/**
 * Merges multiple PDF blobs into a single PDF file.
 *
 * @param {Blob[]} pdfBlobs - An array of PDF blobs to merge.
 * @returns {Promise<Blob>} - A blob of the merged PDF document.
 */
export async function mergePdfBlobs(pdfBlobs) {
  const mergedPdf = await PDFDocument.create();

  for (const blob of pdfBlobs) {
    const pdf = await PDFDocument.load(await blob.arrayBuffer());
    const pages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
    pages.forEach((page) => mergedPdf.addPage(page));
  }

  const mergedPdfBytes = await mergedPdf.save();
  return new Blob([mergedPdfBytes], { type: 'application/pdf' });
}
