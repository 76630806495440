import React from 'react';
import PropTypes from 'prop-types';
import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';

const StandAlonePromptPopup = (props) => {
  const { handleOk, handleCancel } = props;
  return (
    <ScreenDimmerComponent>
      <div className={'appealio-popup appealio-action-popup'}>
        <div className="appealio-action-popup__header"></div>
        <div className="appealio-action-popup__content">
          <p className="appealio-action-prompt_details">
            Are you sure you want to exit this standalone submission? Any files
            that have been uploaded will not be saved.
          </p>
          <button
            className={'ap-button ap-button--secondary ap-button--block'}
            onClick={() => handleOk()}
          >
            OK
          </button>
          <button
            className="ap-button ap-button--tertiary-outline ap-button--block"
            onClick={() => handleCancel()}
          >
            Cancel
          </button>
        </div>
      </div>
    </ScreenDimmerComponent>
  );
};

StandAlonePromptPopup.propTypes = {
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default StandAlonePromptPopup;
