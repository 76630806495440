import React from 'react';
import PropTypes from 'prop-types';
import Introduction from '../Introduction';
import { SETTINGS_NAVIGATION_INTRO } from 'constants/Navigation';

const AccountDetails = (props) => {
  const {
    account: { title, description, info },
  } = SETTINGS_NAVIGATION_INTRO;
  return (
    <div>
      <Introduction
        title={title}
        description={description}
        info={info}
        datacy="account-details-Introduction"
      />
    </div>
  );
};

AccountDetails.propTypes = {
  info: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default AccountDetails;
