import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/common/button';
import AppealioPopup from 'components/Shared/AppealioPopup';

import checkIcon from 'img/check-icon.svg';
import warningIcon from 'img/warning-icon.svg';

import './style.css';

const ConfirmUploadPopup = (props) => {
  const {
    cleanRowsCount,
    duplicateRowsCount,
    isSubmitting,
    onConfirmClick,
    onCancelClick,
  } = props;
  return (
    <AppealioPopup
      className="appealio-popup appealio-action-popup"
      titleClassName="appealio-action-popup__header csv-claims-import-confirm-popup__title"
      title={`You are importing ${cleanRowsCount} clean rows.`}
    >
      <div className="d-flex align-items-center">
        <div>
          <img
            className="csv-claims-import-confirm-popup__icon"
            src={checkIcon}
            alt="check"
          />
        </div>
        <div className="csv-claims-import-confirm-popup__info">
          You are importing {cleanRowsCount} clean rows
        </div>
      </div>

      {duplicateRowsCount > 0 && (
        <div className="d-flex align-items-center mt-20">
          <div>
            <img
              className="csv-claims-import-confirm-popup__icon"
              src={warningIcon}
              alt="warning"
            />
          </div>
          <div>
            <div className="csv-claims-import-confirm-popup__info">
              There are{' '}
              <span className="">{duplicateRowsCount} Duplicate rows.</span>
            </div>
          </div>
        </div>
      )}
      <div className="csv-claims-import-confirm-popup__confirm-text mt-20">
        Do you want to continue?
      </div>
      <div className="mt-20">
        <Button
          className="width-full ap-button--secondary"
          disabled={isSubmitting}
          onClick={onConfirmClick}
        >
          {isSubmitting ? 'Uploading...' : 'Confirm Upload'}
        </Button>
      </div>
      <div className="mt-20">
        <Button
          className="width-full ap-button--secondary csv-claims-import-confirm-popup-close-cancel-btn"
          disabled={isSubmitting}
          onClick={onCancelClick}
        >
          Cancel
        </Button>
      </div>
    </AppealioPopup>
  );
};

ConfirmUploadPopup.propTypes = {
  cleanRowsCount: PropTypes.number.isRequired,
  duplicateRowsCount: PropTypes.number,
  isSubmitting: PropTypes.bool.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};

export default ConfirmUploadPopup;
