import _, { pickBy } from 'lodash';
import { saveAs } from 'file-saver';
import identity from 'lodash/identity';

import { camelizeKeys } from '../helpers/object';
import {
  APPEAL_DOCUMENTS,
  APPEAL_SUBMIT_DIRECT,
  DOWNLOAD_APPEAL_DOCUMENT,
  APPEAL_SUBMIT_PAYER_PORTAL,
  PREVIEW_EOB_DOCUMENT,
  PREVIEW_CMS1500_DOCUMENT,
  EMAIL_SUBMISSION_ALLOWED,
  APPEALS,
} from 'constants/endpoints';

import { interpolate } from 'helpers/string';
import {
  APIConfig,
  handleErrors,
  createRequest as createPostRequest,
  createRequest,
  handleJSONParse,
  createJSONFetch,
  createGetRequest,
  createURLWithParams,
  createDeleteRequest,
  createVndApiRequest,
  createFormDataRequest,
  INTEGRATION_API_BASE_URL,
} from './Config';

import {
  parseAppeal,
  parsePatient,
  serializeAppeal,
  serializeAppealInfo,
  serializeAppealAgent,
  serializePatientInfo,
  serializeAppealLetter,
  serializeEOBProcedure,
  serializeAppealSubmitFax,
  serializeAppealLetterForm,
  serializeAppealSubmitMail,
  parseAppealWithNestedAppeals,
  serializeAppealLetterWithoutContent,
  serializeAppealSubmitDirect,
  serializeAppealSubmitEmail,
} from './Mappers';

import { AppealFaxSubmitSerializer } from './Serializers/AppealSubmissionSerializers';

import { DOCUMENT_CATEGORY } from 'constants/appConstants';

export const defaultIncludeOptions = [
  'agent',
  'author',
  'letters',
  'documents',
  'payerContact',
  'patient',
  'eob',
  'eob.eob_procedures',
  'clinic',
];

export const INDIVIDUAL_TYPE_PROVIDER = 1;
export const ORGANIZATION_TYPE_PROVIDER = 2;

export default class CreateAppealAPI {
  static getDropdownOptions(path, params) {
    const request = createGetRequest(path, params);
    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => {
        return res;
      });
  }

  static getReconsiderationReasons(appealId) {
    const request = createGetRequest(`appeals/${appealId}/recon_reasons/`);
    return fetch(request).then(handleErrors).then(handleJSONParse);
  }

  static getDeliveryFormats(appealId) {
    const request = createGetRequest(
      `appeals/${appealId}/supported_delivery_formats`
    );
    return fetch(request).then(handleErrors).then(handleJSONParse);
  }

  static async getProviderClinicAccessByProviderId(providerId) {
    const request = createGetRequest(`provider-clinic-access/${providerId}`);

    return createJSONFetch(request);
  }

  static async getAppealDetails(appealId, queryParams = {}) {
    const url = interpolate(`${APPEALS}:appealId`, {
      appealId,
    });

    const request = createGetRequest(url, queryParams);
    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then(camelizeKeys);
  }

  /**
   * Returns rendering providers with nested billing providers.
   */
  static async getRenderingBillingProviders(filters) {
    const nonEmptyFilters = pickBy(filters, identity);

    const request = createGetRequest(
      'rendering_billing_provider',
      nonEmptyFilters
    );

    const data = await createJSONFetch(request);

    return _.get(data, 'results', []);
  }

  static async getProviderAndClinic(practiceId) {
    const request = createGetRequest(`practices/${practiceId.practice_id}`);
    const { data } = await createJSONFetch(request);

    return camelizeKeys(data);
  }

  static async fetchPatientSuggestions(query = {}) {
    const request = createGetRequest('suggest_patient', query);
    const response = await fetch(request).then(handleErrors);
    if (response.status === 204) {
      return null;
    }

    return handleJSONParse(response).then((res) => res.data.map(camelizeKeys));
  }

  static async getBillingProvidersByProviderId(providerId) {
    const request = createGetRequest(
      `rendering_billing_provider_by_rendering_id/${providerId}`
    );

    const data = await createJSONFetch(request);

    const billingProviders = _.get(data, 'results[0].billing_provider', []);

    return billingProviders;
  }

  static parseAgents(agents) {
    return agents.data.map((item) => {
      return {
        id: item.id,
        name: `${item.attributes.first_name} ${item.attributes.last_name}`,
        isActive: item.attributes.is_active,
      };
    });
  }

  /**
   * Extracts payer's name.
   *
   * @param {object} provider
   * @param {number} provider.provider_type
   * @param {string} provider.first_name
   * @param {string} provider.last_name
   * @param {string} provider.organization_name
   * @param {boolean} disableLastNameFirstNameFormatting
   * @return {string}
   */
  static extractProviderName = (
    provider,
    disableLastNameFirstNameFormatting = false
  ) => {
    if (disableLastNameFirstNameFormatting) {
      return provider.provider_type === INDIVIDUAL_TYPE_PROVIDER
        ? `${provider.first_name} ${provider.last_name}`
        : provider.organization_name;
    }
    return provider.provider_type === INDIVIDUAL_TYPE_PROVIDER
      ? `${provider.last_name}, ${provider.first_name}`
      : provider.organization_name;
  };

  /**
   * Extracts provider option for dropdown.
   *
   * @param {object} provider
   * @param {number} provider.provider_id
   * @param {number} provider.clinic_id
   * @param {number} provider.provider_type
   * @param {string} provider.first_name
   * @param {string} provider.last_name
   * @param {string} provider.organization_name
   * @param {Boolean} shouldAppendTaxId
   * @return {object}
   */
  static extractProviderOption = (provider, shouldAppendTaxId = false) => {
    const providerName = CreateAppealAPI.extractProviderName(provider);

    const providerId = provider.provider_id;
    const clinicId = provider.clinic_id;

    const taxId = _.get(provider, 'tax_id', '');
    const taxIdString = taxId ? ` - (${taxId})` : '';
    return {
      key: providerId,
      value: !shouldAppendTaxId
        ? providerName
        : `${providerName}${taxIdString}`,
      clinicId,
      isActive: provider.is_active,
      data: provider,
    };
  };

  static parseBillingProviders = (providers) => {
    return providers.map((provider) => {
      const value = CreateAppealAPI.extractProviderName(provider);

      return {
        key: provider.provider_id,
        value,
        clinicId: provider.clinic_id,
      };
    });
  };

  static parseProviders(providers, disableLastNameFirstNameFormatting = false) {
    return providers.data.map((item) => {
      const value = CreateAppealAPI.extractProviderName(
        item.attributes,
        disableLastNameFirstNameFormatting
      );

      return {
        key: item.id,
        value,
        clinicId: item.attributes.clinic_id,
        practiceIdentifiers: item.attributes.practice_identifiers,
        isActive: item.attributes.is_active,
      };
    });
  }

  static parseClinics(clinics) {
    return clinics.data.map((item) => {
      return { key: item.id, value: item.name };
    });
  }

  /**
   * Parses payers for dropdown options.
   * @param {Object} payers
   */
  static parsePayers(payers) {
    return payers.data.map((item) => {
      return { key: item.id, value: item.attributes.name };
    });
  }

  /**
   * Parses payers data.
   * @param {Object} payers
   */
  static parsePayerData(payers) {
    return payers.data.map((payer) => ({
      id: payer.id,
      ...payer.attributes,
    }));
  }

  /**
   * Parses practice data.
   * @param {Object} practices
   */
  static parsePracticeData(practices) {
    return practices.map((practice) => {
      const camelizeObject = camelizeKeys(practice);

      return {
        ...camelizeObject,
        key: practice.id,
        value: camelizeObject.name,
      };
    });
  }

  static parseTemplates(templates) {
    return templates.data.map((item) => {
      return {
        id: item.id,
        type: item.attributes.template_type,
        title: item.attributes.name,
        content: item.attributes.letter,
        rank: item.attributes.rank,
      };
    });
  }

  static createAppealAndSendPatientInfo(patientInfo) {
    if (!!patientInfo.appealID && !!patientInfo.patientID) {
      return CreateAppealAPI.updateAppealAndPatientInfo(patientInfo);
    }

    const serializedAppeal = serializeAppealInfo(patientInfo);

    const appealsRequest = createVndApiRequest('appeals', serializedAppeal);

    return createJSONFetch(appealsRequest).then((appealsResponse) => {
      const serializedPatient = serializePatientInfo(
        patientInfo,
        appealsResponse.data.id,
        true
      );
      const patientsRequest = createVndApiRequest(
        'patients',
        serializedPatient
      );
      return createJSONFetch(patientsRequest).then((patientsResponse) => {
        return Promise.all([
          parseAppeal(appealsResponse),
          parsePatient(patientsResponse),
        ]).then(([appeal, patient]) => {
          return Promise.resolve({ appeal, patient });
        });
      });
    });
  }

  static updateAppealAndPatientInfo(patientInfo) {
    const updateAppealRequest = CreateAppealAPI.updateAppealInfo(
      patientInfo.appealID,
      patientInfo
    );
    const updatePatientRequest = CreateAppealAPI.updatePatient(
      patientInfo.patientID,
      patientInfo
    );

    return Promise.all([updateAppealRequest, updatePatientRequest]).then(
      ([appealResponse, patientResponse]) => {
        return Promise.all([
          parseAppeal(appealResponse),
          parsePatient(patientResponse),
        ]).then(([appeal, patient]) => {
          return Promise.resolve({ appeal, patient });
        });
      }
    );
  }

  static updateBillingAgent(appealID, billingAgentID) {
    const updateAppealRequest = CreateAppealAPI.updateAppealAgent(appealID, {
      agent: billingAgentID,
    });
    return Promise.all([updateAppealRequest]);
  }

  static updatePatient(patientID, patientInfo) {
    const serializedPatient = serializePatientInfo(
      { ...patientInfo, id: patientID },
      patientInfo.appealID
    );
    const request = createVndApiRequest(
      `patients/${patientID}`,
      serializedPatient,
      'PATCH'
    );
    return createJSONFetch(request);
  }

  static updateAppealInfo(appealID, attrs) {
    const serializedAppeal = serializeAppealInfo({ ...attrs, id: appealID });
    const request = createVndApiRequest(
      `appeals/${appealID}`,
      serializedAppeal,
      'PATCH'
    );
    return createJSONFetch(request);
  }

  static updateAppealAgent(appealID, attrs) {
    const serializedAppeal = serializeAppealAgent({ ...attrs, id: appealID });
    const request = createVndApiRequest(
      `appeals/${appealID}`,
      serializedAppeal,
      'PATCH'
    );
    return createJSONFetch(request);
  }

  static updateAppeal(appealID, stepNumber, attrs) {
    if (arguments.length < 3) {
      // it allows to use updateAppeal with two arguments like here
      // CreateAppealAPI.updateAppeal(this.props.appealID, { statusCode: AppealStatusCode.Paid }).
      return CreateAppealAPI.updateAppeal(appealID, null, arguments[1]);
    }

    if (stepNumber != null) {
      attrs.step = stepNumber;
    }

    const serializedAppeal = serializeAppeal(appealID, attrs);
    const request = createVndApiRequest(
      `appeals/${appealID}`,
      serializedAppeal,
      'PATCH'
    );
    return createJSONFetch(request);
  }

  static updateDocumentsRank = (documentIds) => {
    const request = createRequest(
      'appeal_documents',
      {
        documents: documentIds,
      },
      {
        method: 'PUT',
      }
    );

    return fetch(request).then(handleErrors);
  };

  static deleteSubmissionDocuments = (submissionId) => {
    const request = createRequest(
      'appeal_documents',
      {},
      {
        params: {
          submission: submissionId,
        },
        method: 'DELETE',
      }
    );

    return fetch(request).then(handleErrors);
  };

  static createOrUpdateEOBProcedures(eobProcedures, eobID) {
    const serializedProcedures = eobProcedures.map((procedure) =>
      serializeEOBProcedure(procedure, eobID)
    );
    const requests = serializedProcedures.map((procedure) => {
      const hasID = !!procedure.data.id;
      const path = hasID
        ? `eob_procedures/${procedure.data.id}/`
        : 'eob_procedures';

      return createVndApiRequest(path, procedure, hasID ? 'PATCH' : 'POST');
    });

    return requests.reduce(async (previousPromise, request) => {
      await previousPromise;

      return fetch(request).then(handleErrors).then(handleJSONParse);
    }, Promise.resolve());
  }

  static deleteEOBProcedures(eobProcedureIds) {
    const requests = eobProcedureIds.map((eobProcedureId) => {
      const request = createDeleteRequest(`eob_procedures/${eobProcedureId}`);

      return fetch(request)
        .then(handleErrors)
        .then(() => Promise.resolve());
    });

    return Promise.all(requests);
  }

  static updateAppealAndCreateProcedures(
    { appealID, stepNumber, attrs },
    { procedures, eobID },
    procedureIdsToDelete = []
  ) {
    return Promise.all([
      CreateAppealAPI.createOrUpdateEOBProcedures(procedures, eobID),
      CreateAppealAPI.deleteEOBProcedures(procedureIdsToDelete),
    ]).then(() => CreateAppealAPI.updateAppeal(appealID, stepNumber, attrs));
  }

  static calculateDeadline(appealID, denialDate, appealRound = undefined) {
    const request = createGetRequest(`appeals/${appealID}/calculate_deadline`, {
      denial_date: denialDate,
      appeal_round: appealRound,
    });
    return createJSONFetch(request);
  }

  static async getPayerById(payerId) {
    const request = createGetRequest(`payers/${payerId}`);

    const response = await createJSONFetch(request);

    const payerData = response.data;

    return {
      id: payerData.id,
      ..._.mapKeys(payerData.attributes, (v, k) => _.camelCase(k)),
    };
  }

  static extractPayerIdFromPayload = (response) => {
    return _.get(response, 'data.attributes.payer_id', null);
  };

  static getAppealById(appealID, include = defaultIncludeOptions) {
    const includeQuery = include.join(',');
    const request = createGetRequest(`appeals/${appealID}`, {
      include: includeQuery,
    });

    return createJSONFetch(request).then(async (res) => {
      const payerId = await this.extractPayerIdFromPayload(res);
      const billingProviderId = _.get(
        res,
        'data.relationships.billing_provider.data.id',
        null
      );

      const parsedData = await parseAppealWithNestedAppeals(res);

      const payerData = payerId ? await this.getPayerById(payerId) : null;

      return {
        ...parsedData,
        payer: payerData,
        billingProviderId,
      };
    });
  }

  static createAppealLetter(appealLetter) {
    let request;
    if (appealLetter.content.type === 'html') {
      request = createVndApiRequest(
        'appeal_letters',
        serializeAppealLetter(appealLetter)
      );
    } else {
      request = createFormDataRequest(
        `appeals/${appealLetter.appealId}/letter_pdfs`,
        serializeAppealLetterForm(appealLetter)
      );
    }

    return createJSONFetch(request);
  }

  static deleteAppealLetter(letterID) {
    const request = createDeleteRequest(`appeal_letters/${letterID}`);

    return fetch(request)
      .then(handleErrors)
      .then((res) => {
        return Promise.resolve();
      });
  }

  static updateAppealLetter(appealLetter) {
    let request;

    const appealLetterRequestPath = `appeal_letters/${appealLetter.id}`;
    const appealFormRequestPath = `letter_pdfs/${appealLetter.id}`;

    if (appealLetter.content) {
      if (appealLetter.content.type === 'html') {
        request = createVndApiRequest(
          appealLetterRequestPath,
          serializeAppealLetter(appealLetter),
          'PATCH'
        );
      } else {
        request = createFormDataRequest(
          appealFormRequestPath,
          serializeAppealLetterForm(appealLetter),
          'PATCH'
        );
      }
    } else {
      const requestPath =
        appealLetter.letter_type === 'pdf'
          ? appealFormRequestPath
          : appealLetterRequestPath;

      request = createVndApiRequest(
        requestPath,
        serializeAppealLetterWithoutContent(appealLetter),
        'PATCH'
      );
    }

    return fetch(request).then(handleErrors);
  }

  static deleteAppealDocument(documentID) {
    const request = createDeleteRequest(`appeal_documents/${documentID}`);

    return fetch(request).then(handleErrors);
  }

  static async fetchDocuments(fetchID, isAppeal = false) {
    const fetchProperty = isAppeal ? 'appeal' : 'submission';
    const request = createGetRequest(APPEAL_DOCUMENTS, {
      [fetchProperty]: fetchID,
      document_category: [
        DOCUMENT_CATEGORY.APPEAL_ATTACHMENT,
        DOCUMENT_CATEGORY.EOB,
        DOCUMENT_CATEGORY.CMS1500,
      ],
    });

    const { data } = await createJSONFetch(request);

    return data.map(({ id, attributes }) => {
      return camelizeKeys({
        id,
        ...attributes,
      });
    });
  }

  /**
   * Fetches email documents for a submission.
   * @param {Number} submissionId
   * @returns {Promise<Array>}
   */
  static async fetchEmailDocuments(submissionId) {
    const request = createGetRequest(APPEAL_DOCUMENTS, {
      submission: submissionId,
      document_category: [DOCUMENT_CATEGORY.EMAIL_ATTACHMENT],
    });

    const { data } = await createJSONFetch(request);

    return data.map(({ id, attributes }) => {
      const name = attributes.link_path.split('/').pop();

      return camelizeKeys({
        id,
        name,
        ...attributes,
      });
    });
  }

  /**
   * Fetches the appeal document.
   * @param {Number} documentId
   * @returns {Promise<Blob>}
   */
  static fetchAppealDocument(documentId) {
    const request = createRequest(
      interpolate(DOWNLOAD_APPEAL_DOCUMENT, {
        documentId,
      })
    );

    return fetch(request).then((res) => {
      return res.blob();
    });
  }

  static createReAppeal(appealID, appealAttributes, procedures) {
    const request = createVndApiRequest(`appeals/${appealID}/reappeal`);

    return createJSONFetch(request).then((res) => {
      return parseAppeal(res).then((appeal) => {
        const newlyCreatedAppealID = appeal.id;
        const eobID = appeal.eob.id;
        const updateProceduresParams = {
          procedures: procedures.map((procedure) => {
            return { ...procedure, id: undefined };
          }),
          eobID,
        };

        const updateAppealParams = {
          appealID: newlyCreatedAppealID,
          stepNumber: 2,
          attrs: appealAttributes,
        };

        return CreateAppealAPI.updateAppealAndCreateProcedures(
          updateAppealParams,
          updateProceduresParams
        ).then((res) => {
          return CreateAppealAPI.getAppealById(newlyCreatedAppealID);
        });
      });
    });
  }

  static createFinalAppeal(
    appealID,
    finalStatus,
    appealAttributes,
    procedures
  ) {
    const request = createVndApiRequest(`appeals/${appealID}/final_appeal`);

    return createJSONFetch(request).then((res) => {
      return parseAppeal(res).then((appeal) => {
        const newlyCreatedAppealID = appeal.id;
        const eobID = appeal.eob.id;
        const updateProceduresParams = {
          procedures: procedures.map((procedure) => {
            return { ...procedure, id: undefined };
          }),
          eobID,
        };

        const updateAppealParams = {
          appealID: newlyCreatedAppealID,
          status: finalStatus,
          attrs: appealAttributes,
        };

        updateAppealParams.attrs.status = finalStatus;

        return CreateAppealAPI.updateAppealAndCreateProcedures(
          updateAppealParams,
          updateProceduresParams
        ).then((res) => {
          return CreateAppealAPI.getAppealById(newlyCreatedAppealID);
        });
      });
    });
  }

  static submitFax(appealID, attrs) {
    const serializedSubmitFax = serializeAppealSubmitFax({
      appealID,
      ...attrs,
    });
    const request = createVndApiRequest(
      'appeal_submit_fax',
      serializedSubmitFax
    );
    return createJSONFetch(request);
  }

  static submitMail(appealID, attrs) {
    const serializedSubmitMail = serializeAppealSubmitMail({
      appealID,
      ...attrs,
    });
    const request = createVndApiRequest(
      'appeal_submit_mail',
      serializedSubmitMail
    );
    return createJSONFetch(request);
  }

  static submitEmail(appealID, attrs) {
    const serializedSubmitEmail = serializeAppealSubmitEmail({
      appealID,
      ...attrs,
    });
    const request = createVndApiRequest(
      'appeal_submit_email',
      serializedSubmitEmail
    );
    return createJSONFetch(request);
  }

  static submitDirectUHCApi(
    appealID,
    submissionId,
    submissionType,
    deliveryOptions
  ) {
    const request = createVndApiRequest(
      APPEAL_SUBMIT_DIRECT,
      serializeAppealSubmitDirect({
        appealID,
        submissionId,
        submissionType,
        deliveryOptions,
      })
    );
    return createJSONFetch(request);
  }

  static submitPayerPortalForStandAlone = (submissionId, payerPortalUrl) => {
    const requestBody = {
      submission_id: submissionId,
      payer_portal_url: payerPortalUrl,
    };
    const request = createPostRequest(APPEAL_SUBMIT_PAYER_PORTAL, requestBody);
    return fetch(request).then(handleErrors).then(handleJSONParse);
  };

  static extractPreviewURL(
    submissionId,
    includeCoverLetter = false,
    faxParameters = {},
    customQueryParams = {}
  ) {
    const authToken = APIConfig.authToken;
    const filteredFaxParameters =
      AppealFaxSubmitSerializer.serialize(faxParameters);
    const url = createURLWithParams(`package-preview/${submissionId}`, {
      auth_token: authToken,
      include_cover_letter: includeCoverLetter,
      ...filteredFaxParameters.data.attributes,
      ...customQueryParams,
    });
    return url;
  }

  static createPreview(submissionId, query = {}) {
    const request = createGetRequest(`package-preview/${submissionId}/`, query);
    return fetch(request)
      .then(handleErrors)
      .then((res) => res);
  }

  static createPreviewURL(submissionId, query = {}) {
    const request = createGetRequest(`package-preview/${submissionId}/`, query);

    return fetch(request)
      .then(handleErrors)
      .then((response) => response.blob())
      .then((packageFile) => {
        const fileUrl = window.URL.createObjectURL(packageFile);
        return fileUrl;
      });
  }

  /**
   * Creates a URL for fetching the preview file for a submission.
   * This new function was created to provide a dedicated endpoint
   * for fetching the merged PDF preview without additional operations.
   *
   * @param {number} submissionId
   * @param {object} queryParams
   * @param {AbortSignal} signal
   * @returns {Promise<string>} Preview file URL
   */
  static createSubmissionPreviewURL(submissionId, queryParams = {}, signal) {
    const endpoint = `/submissions/${submissionId}/preview/`;
    const request = createGetRequest(endpoint, queryParams);

    return fetch(request, { signal })
      .then(handleErrors)
      .then((response) => response.blob())
      .then((previewFile) => {
        const fileUrl = window.URL.createObjectURL(previewFile);
        return fileUrl;
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          // Request was cancelled, do nothing
          return null;
        }
        throw error;
      });
  }

  static downloadFileFromObjectURL(fileUrl, fileName) {
    saveAs(fileUrl, fileName);
  }

  static downloadPreviewFile(submissionId, fileName) {
    const endpoint = `/submissions/${submissionId}/preview/`;
    const request = createGetRequest(endpoint);

    return fetch(request)
      .then(handleErrors)
      .then(async (res) => {
        const blob = await res.blob();

        saveAs(blob, fileName);
      });
  }

  static getClientInformation() {
    const request = createGetRequest('clients');

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => res);
  }

  static async reAppeal(appealId, requestBody) {
    const request = createPostRequest(
      `appeals/${appealId}/reappeal-v2`,
      requestBody
    );

    const {
      data: { id },
    } = await fetch(request).then(handleErrors).then(handleJSONParse);

    return CreateAppealAPI.getAppealById(id);
  }

  static async saveResponseAndClose(appealId, requestBody) {
    const request = createPostRequest(
      `appeals/${appealId}/save-close-response`,
      requestBody
    );

    const {
      data: { id },
    } = await fetch(request).then(handleErrors).then(handleJSONParse);

    return CreateAppealAPI.getAppealById(id);
  }

  static async saveResponse(appealId, requestBody) {
    const request = createPostRequest(
      `appeals/${appealId}/save-response`,
      requestBody
    );

    const {
      data: { id },
    } = await fetch(request).then(handleErrors).then(handleJSONParse);

    return CreateAppealAPI.getAppealById(id);
  }

  /**
   * Sync claim status.
   *
   * @param {String} clientPartitionId
   * @param {String} payerClaimId
   * @param {Number} status Status should be 1 when saving the appeal 2 when finalizing appeal
   * @returns {Promise}
   */
  static async syncClaimStatus(clientPartitionId, payerClaimId, status) {
    const requestBody = {
      client_id: clientPartitionId,
      claim_number: payerClaimId,
      status,
    };

    const request = createPostRequest('sync_claim_status/', requestBody, {
      baseUrl: INTEGRATION_API_BASE_URL,
    });

    return fetch(request).then(handleErrors);
  }

  /**
   * Get prefill appeal data based on the payer claim id
   *
   * @param {String} payerClaimId
   * @returns {Promise}
   */
  static async getPrefillAppealData(payerClaimId) {
    const request = createGetRequest(
      `existing-claim`,
      {
        payer_claim: payerClaimId,
      },
      INTEGRATION_API_BASE_URL
    );

    const response = await fetch(request).then(handleErrors);
    if (response.status === 204) {
      return null;
    }
    return handleJSONParse(response);
  }

  /**
   * Log appeal submission error.
   *
   * @param {Number} appealId
   * @param {Number} errorStatusCode
   * @returns {Promise}
   */
  static logAppealSubmissionError = (appealId, errorStatusCode) => {
    const request = createPostRequest(`appeals/${appealId}/log_error/`, {
      error_status_code: errorStatusCode,
    });

    return fetch(request).then(handleErrors);
  };

  /**
   * API to create "Direct To Payer" in Payer contact
   */
  static createPayerDirectToContacts = async (PayerId) => {
    const request = createPostRequest(`payer-direct-contacts/${PayerId}`);
    return await fetch(request).then(handleErrors).then(handleJSONParse);
  };

  /**
   * Preview the EOB document for the given appeal ID.
   *
   * @param {Number} appealId - The ID of the appeal to preview the EOB for.
   * @returns {Promise<String>} A promise that resolves to the EOB document URL.
   */
  static previewEobDocument(appealId) {
    const request = createGetRequest(`${PREVIEW_EOB_DOCUMENT}/${appealId}/`);

    return fetch(request)
      .then(handleErrors)
      .then((response) => response.blob())
      .then((packageFile) => {
        const fileUrl = window.URL.createObjectURL(packageFile);
        return fileUrl;
      });
  }

  /**
   * Preview the CMS 1500 document for the given appeal ID.
   *
   * @param {Number} appealId - The ID of the appeal to preview the EOB for.
   * @returns {Promise<String>} A promise that resolves to the CMS 1500 document URL.
   */
  static previewCMS1500Document(appealId) {
    const request = createGetRequest(
      `${PREVIEW_CMS1500_DOCUMENT}/${appealId}/`
    );

    return fetch(request)
      .then(handleErrors)
      .then((response) => response.blob())
      .then((packageFile) => {
        const fileUrl = window.URL.createObjectURL(packageFile);
        return fileUrl;
      });
  }

  /**
   * Check if the CMS 1500 document is available for the given appeal ID.
   *
   * @param {Number} appealId - The ID of the appeal to preview the EOB for.
   * @returns {Promise<Boolean>} A promise that resolves to availability of the CMS 1500 document.
   */
  static checkCMS1500DocumentAvailability(appealId) {
    const request = createGetRequest(
      `${PREVIEW_CMS1500_DOCUMENT}/${appealId}/`,
      {
        check_file_availability: true,
      }
    );

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => {
        return res.can_generate_file;
      });
  }

  /**
   * Check if the email submission is available for the given submission ID.
   *
   * @param {Number} submissionId
   * @returns {Promise<Boolean>}
   */
  static isEmailSubmissionAvailable(submissionId) {
    const request = createGetRequest(
      `${EMAIL_SUBMISSION_ALLOWED}/${submissionId}/`
    );

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => {
        return res.can_submit_via_email;
      });
  }
}
