import React from 'react';
import { startCase } from 'lodash';

import Input from 'components/common/input';
import CustomDropdown from 'components/common/dropdown/ButtonDropdown/CustomDropdown';

const ErrorRow = ({
  idx,
  value,
  rowKey,
  rowNumber,
  options,
  selectedOption,
  handleReplacementChange,
  numOfRowsWithThisError,
}) => {
  return (
    <div key={value}>
      <div className="row find-and-replace-row">
        <div className="col-lg-1 find-and-replace-row__sn">{idx + 1}.</div>
        <div className="col-lg-1 find-and-replace-row__row">
          <Input disabled={true} label="Row" value={rowNumber} />
        </div>
        <div className="col-lg-5">
          <Input label={startCase(rowKey)} value={value} disabled={true} />
        </div>
        <div className="col-lg-5">
          <CustomDropdown
            options={options}
            label="Replace all with"
            onChange={(selectedValue) =>
              handleReplacementChange(value, selectedValue)
            }
            placeholder={`Select from ${startCase(rowKey)}s`}
            input={{
              value: selectedOption,
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-offset-1 find-and-replace-row__info">
          * This error appears in {numOfRowsWithThisError} total row(s)
        </div>
      </div>
    </div>
  );
};

export default ErrorRow;
