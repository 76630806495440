import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { secureDownload } from '../../API/Config';

export default class LinkList extends Component {
  static propTypes = {
    listClassName: PropTypes.string,
    elementClassName: PropTypes.string,
    links: PropTypes.array,
  };

  static defaultProps = {
    listClassName: 'appeal-history-card__files-list',
    elementClassName: 'appeal-history-card__files-list-item',
    links: [],
  };

  render() {
    return (
      <ul className={this.props.listClassName} datacy="link-list">
        {this.renderLinks()}
      </ul>
    );
  }

  renderLinks() {
    return this.props.links.map((item, index) => {
      return (
        <li key={index} className={this.props.elementClassName}>
          <span
            ref={`${item.prefix}${index}`}
            onClick={() => secureDownload(item.link, item.params)}
            datacy="link-list-link"
          >
            {item.title}
          </span>
        </li>
      );
    });
  }
}
