import React from 'react';
import PropTypes from 'prop-types';
import ChartistGraph from 'react-chartist';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';

import {
  extractRelativeNumber,
  extractRelativeNumberFixedPoint,
} from 'helpers/utils';

const ScatterPlot = (props) => {
  const { data, highPoint, scatterPlotRef } = props;
  const options = {
    showLine: false,
    width: '100%',
    height: '650px',
    axisX: {
      offset: 60,
      position: 'end',
      labelOffset: {
        x: -16,
        y: 22,
      },
      showLabel: true,
      showGrid: true,
      labelInterpolationFnc: (value, index) => {
        if (!value) return '';
        return `${value}`;
      },
      scaleMinSpace: 30,
      onlyInteger: false,
    },
    axisY: {
      offset: 65,
      position: 'start',
      labelOffset: {
        y: 5,
      },
      showLabel: true,
      showGrid: true,
      labelInterpolationFnc: function (value, index) {
        return `$${extractRelativeNumber(value)}`;
      },
      scaleMinSpace: 50,
      onlyInteger: false,
    },
    high: highPoint,
    low: 0,
    referenceValue: null,
    seriesBarDistance: 0,
    reverseData: false,
    showGridBackground: true,
    plugins: [
      ChartistTooltip({
        transformTooltipTextFnc: (value) => {
          const fixedNotationCurrency = parseFloat(value).toFixed(2);
          return `$${extractRelativeNumberFixedPoint(fixedNotationCurrency)}`;
        },
      }),
    ],
  };

  return (
    <div className="ap-scatter-plot-wrapper">
      <div className="average-notation-wrapper">
        <span className="average-notation"></span>
        <span>Average</span>
      </div>
      <div className="ap-scatter-plot" ref={scatterPlotRef}>
        <h5 className="ap-scatter-plot__y-label">Average Allowed Amount</h5>
        <ChartistGraph data={data} options={options} type="Line" />
        <div className="ap-scatter-plot__label">
          <p>Procedure Code</p>
          <p>Claim Count</p>
        </div>
        <hr className="scatter-plot-x-axis-line" />
        <h5 className="ap-scatter-plot__x-label">Top 10 Procedure Codes</h5>
      </div>
    </div>
  );
};

ScatterPlot.propTypes = {
  ref: PropTypes.func,
  data: PropTypes.object.isRequired,
  maxValue: PropTypes.number,
};

export default ScatterPlot;
