import { validateZipCode } from 'helpers/validators';

const validateExists = (value) => (value ? null : 'Required');

const fieldCharacterValidation = (
  field,
  value,
  maxLength,
  isRequired = true
) => {
  if (isRequired && !value) {
    return `${field} is required`;
  }
  if (value && value.length > maxLength) {
    return `${field} should be less than ${maxLength} characters`;
  }
  return null;
};

export const validateMailForm = (values) => {
  const errors = {};
  const displayErrors = [];

  const notEmptyFields = [
    { field: 'name', label: 'Shipping Address Name' },
    { field: 'address_line1', label: 'Shipping Address Line 1' },
    { field: 'city', label: 'Shipping City' },
    { field: 'state', label: 'Shipping State' },
    { field: 'zipcode', label: 'Shipping Zip' },
    { field: 'return_address_name', label: 'Return Address Name' },
    { field: 'return_address_line1', label: 'Return Address Line 1' },
    { field: 'return_city', label: 'Return City' },
    { field: 'return_state', label: 'Return State' },
    { field: 'return_zipcode', label: 'Return Zip' },
  ];

  notEmptyFields.forEach(({ field, label }) => {
    errors[field] = validateExists(values.get(field));
    if (errors[field]) {
      const errorMessage = `${label} is required`;
      errors[field] = errorMessage;
      displayErrors.push({ message: errorMessage });
    }
  });

  errors.zipcode = validateZipCode(values.get('zipcode'));
  if (errors.zipcode) {
    displayErrors.push({ message: `Shipping Zip: ${errors.zipcode}` });
  }

  errors.return_zipcode = validateZipCode(values.get('return_zipcode'));
  if (errors.return_zipcode) {
    displayErrors.push({ message: `Return Zip: ${errors.return_zipcode}` });
  }

  errors.return_address_name = fieldCharacterValidation(
    'Return Address Name',
    values.get('return_address_name'),
    40
  );
  if (errors.return_address_name) {
    displayErrors.push({ message: errors.return_address_name });
  }

  errors.attention_line = fieldCharacterValidation(
    'Attention Line',
    values.get('attention_line'),
    40,
    false
  );
  if (errors.attention_line) {
    displayErrors.push({ message: errors.attention_line });
  }

  errors.name = fieldCharacterValidation(
    'Shipping Address Name',
    values.get('name'),
    40
  );
  if (errors.name) {
    displayErrors.push({ message: errors.name });
  }

  if (displayErrors.length > 0) {
    errors.displayErrors = displayErrors;
  }

  return errors;
};

export const formatMailFormValues = (values) => {
  const formattedValues = {
    address_line1: values.get('address_line1') || '',
    address_line2: values.get('address_line2') || '',
    certified: values.get('certified') || false,
    city: values.get('city') || '',
    country: 'US',
    delivery_options: {
      attention_line: values.get('attention_line') || '',
      color: values.get('color') === 'COLOR' ? '1' : '0',
      double_sided: values.get('side') === 'DOUBLE',
      is_paper_claim: false,
      odd_page_submission_padding: true,
      return: {
        address_line1: values.get('return_address_line1') || '',
        address_line2: values.get('return_address_line2') || '',
        city: values.get('return_city') || '',
        country: 'US',
        name: values.get('return_address_name') || '',
        state: values.get('return_state') || '',
        zipcode: values.get('return_zipcode') || '',
      },
      shipping: 'ship mail',
    },
    state: values.get('state') || '',
    zipcode: values.get('zipcode') || '',
  };

  return formattedValues;
};
