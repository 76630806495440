import React from 'react';
import { NavLink } from 'react-router-dom';

import './style.css';

const SecondaryNav = (props) => {
  const { navItems } = props;
  return (
    <ul className="secondary-nav" datacy="secondary-nav-ul">
      {navItems.map((navItem, index) => (
        <li key={index} className="secondary-nav__item">
          <NavLink
            className="secondary-nav__link secondary-nav__link--primary"
            activeClassName="active"
            to={navItem.to}
          >
            {navItem.title}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default SecondaryNav;
