import { debounce } from 'lodash';
import classnames from 'classnames';
import React, { useState } from 'react';

import CreateAppealAPI from 'API/CreateAppealAPI';

import Autocomplete from 'components/common/autocomplete';

const fetchMemberIdDropdownOptions = async (inputValue) => {
  try {
    if (inputValue.length < 4) {
      return [];
    }

    const patients = await CreateAppealAPI.fetchPatientSuggestions({
      mem_id: inputValue,
    });

    const options = patients.map((data) => ({
      label: data.memberId,
      value: data.memberId,
      data,
    }));

    return options;
  } catch (error) {
    return [];
  }
};

const ItemRenderer = (item, isHighlighted, idx) => {
  const { data } = item;
  const { firstName = '', lastName = '', memberId = '' } = data;

  return (
    <div
      key={item.label}
      className={classnames('autocomplete-option', {
        'autocomplete-option-highlighted': isHighlighted,
      })}
    >
      ({memberId}) {firstName} {lastName}
    </div>
  );
};

const MemberIdInput = (props) => {
  const [options, setOptions] = useState([]);

  const debouncedSearch = React.useRef(
    debounce(async (criteria) => {
      setOptions(await fetchMemberIdDropdownOptions(criteria));
    }, 300)
  ).current;

  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleOnChange = (event) => {
    props.input.onChange(event);
    debouncedSearch(event.target.value);
  };

  const handleOnSelect = (value, item) => {
    props.input.onChange(value);
    props.handleChange(value, item.data);
  };

  return (
    <React.Fragment>
      <Autocomplete
        options={options}
        {...props}
        onChange={handleOnChange}
        label={null}
        getItemValue={(item) => item.label}
        renderItem={ItemRenderer}
        onSelect={handleOnSelect}
      />
    </React.Fragment>
  );
};

export default MemberIdInput;
