import { Map } from 'immutable';

import { DROPDOWN_OPTIONS_STATE_KEYS } from 'redux/reducers/dropdownOptionsStore';

/**
 * Generates an action type string for a dropdown action.
 *
 * @param {string} key - The key for the dropdown action.
 * @returns {string} The generated action type string.
 */
export const generateDropdownActionType = (key) => `FETCH_${key}`;

/**
 * Generates a key from a dropdown action type.
 *
 * @param {string} actionType - The dropdown action type.
 * @returns {string} The extracted key from the action type.
 */
export const generateKeyFromDropdownActionType = (actionType) =>
  actionType.substring(6);

export const getActionTypeFromStateKey = (stateKey) => {
  return generateDropdownActionType(
    Object.keys(DROPDOWN_OPTIONS_STATE_KEYS).find(
      (key) => DROPDOWN_OPTIONS_STATE_KEYS[key] === stateKey
    )
  );
};

/**
 * Generates dropdown action types object based on option keys.
 *
 * @param {object} stateKeys - Object containing dropdown option keys.
 * @returns {Object} Object containing generated action types.
 */
export const generateDropdownActionTypes = (stateKeys) => {
  const actionTypes = {};

  for (const key of Object.keys(stateKeys)) {
    actionTypes[generateDropdownActionType(key)] =
      generateDropdownActionType(key);
  }

  return actionTypes;
};

/**
 * Generates initial state for dropdown options based on option keys.
 *
 * @param {object} optionKeys - Object containing dropdown option keys.
 * @returns {Immutable.Map} Initial state for dropdown options.
 */
export const generateDropdownInitialState = (optionKeys) => {
  return Map(
    Object.values(optionKeys).reduce(
      (acc, key) => ({
        ...acc,
        [key]: Map({
          data: [],
          isFetching: false,
        }),
      }),
      {}
    )
  );
};
