import { useEffect } from 'react';
import { Userpilot } from 'userpilot';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { USER_ROLE_LABEL } from 'constants/appConstants';

import { getUserInfo } from 'redux/reducers/loginStore';
import {
  isUserAuthorizedForDenialsPage,
  isAppealioExpressUser,
} from 'Auth/AuthUtils';

const UserpilotComponent = (props) => {
  const { userInfo } = props;
  const history = useHistory();

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    const userPilotToken = process.env.REACT_APP_USERPILOT_TOKEN;
    if (userPilotToken) {
      const isCypressUser =
        userInfo.firstName?.toLowerCase() === 'cypress' &&
        userInfo.lastName?.toLowerCase().includes('test');
      if (isCypressUser) {
        return;
      }

      Userpilot.initialize(userPilotToken);
      const userRole = USER_ROLE_LABEL[userInfo.role];
      Userpilot.identify(userInfo.id, {
        email: userInfo.email,
        first_name: userInfo.firstName,
        last_name: userInfo.lastName,
        role: userRole,
        name: userInfo.firstName + ' ' + userInfo.lastName,
        userId: userInfo.id,
        client: userInfo.clientName,
        is_integrated_client: isUserAuthorizedForDenialsPage(userInfo),
        is_appealio_express_user: isAppealioExpressUser(userInfo),
      });

      const unlisten = history.listen(() => {
        Userpilot.reload();
      });

      return () => {
        unlisten();
      };
    }
  }, [userInfo, history]);

  return null;
};

function mapStateToProps(state, ownProps) {
  const userInfo = getUserInfo(state);
  return { userInfo };
}

export default connect(mapStateToProps, () => ({}))(UserpilotComponent);
