const statusOptions = [
  {
    indicatorColor: 'blue',
    status: 'In Progress',
  },
  {
    indicatorColor: 'blue-gray',
    status: 'Submitted',
  },
  {
    indicatorColor: 'green',
    status: 'Successful',
  },
  {
    indicatorColor: 'red',
    status: 'Denied',
  },
  {
    indicatorColor: 'yellow',
    status: 'Not Received',
  },
  {
    indicatorColor: 'orange',
    status: 'Partially Paid',
  },
  {
    indicatorColor: 'black',
    status: 'Closed',
  },
];

export const appealStatus = {
  submitted: 'Submitted',
  notReceived: 'Not Received',
  partiallyPaid: 'Partially Paid',
  successful: 'Successful',
  inProgress: 'In Progress',
  denied: 'Denied',
  closed: 'Closed',
};

export default statusOptions;
