import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import questionImg from 'img/question-help-circle.svg';

const Widget = (props) => {
  const {
    widgetTitle: { title = '', titleClassName = null } = {},
    isClearable: {
      clearTitle = 'Reset',
      onClear,
      isVisible = false,
      isActive = false,
    } = {},
    icon = null,
    children,
    className = null,
    info = '',
  } = props;

  return (
    <div className={classnames('v3__analytics-widget', className)}>
      <div
        className={classnames('v3__analytics-widget__top', {
          'v3__analaytics-widget__top--icon': icon,
        })}
      >
        {icon}
        <h6 className={classnames('v3__widget-title', titleClassName)}>
          {title}
        </h6>
        {info && <img src={questionImg} alt={info} className="ml-8" />}
        {isVisible && (
          <button
            className={classnames('btn--reset', {
              'btn--reset-active': isActive,
            })}
            onClick={onClear}
          >
            {clearTitle}
          </button>
        )}
      </div>
      {children}
    </div>
  );
};

Widget.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  onClear: PropTypes.func,
  children: PropTypes.node,
  withIcon: PropTypes.bool,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  clearTitle: PropTypes.string,
  isClearable: PropTypes.object,
};

export default Widget;
