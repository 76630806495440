/**
 * Created by alex on 10/6/16.
 */

import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { Component } from 'react';

export default class SectionHeader extends Component {
  static props = {
    headerTitle: PropTypes.string.isRequired,
    headerSubtitle: PropTypes.string,
    className: PropTypes.string,
    isWithLine: PropTypes.bool,
  };

  static defaultProps = {
    headerTitle: '',
    isWithLine: true,
    headerSubtitle: null,
  };

  render() {
    return (
      <div
        className={classNames('row', 'section-header', this.props.className)}
      >
        <div className="section-header__title">{this.props.headerTitle}</div>

        <div className={this.props.isWithLine ? 'section-header__line' : ''} />
        {this.renderSubtitle()}
      </div>
    );
  }

  renderSubtitle = () => {
    if (this.props.headerSubtitle != null) {
      return (
        <div className="section-header__subtitle">
          {this.props.headerSubtitle}
        </div>
      );
    }
  };
}
