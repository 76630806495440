import PropTypes from 'prop-types';
import classnames from 'classnames';
import { BiX } from 'react-icons/bi';
import React, { useState } from 'react';
import { MdSave } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';

import { DEADLINE_LIMIT } from './Payer';

import { snakeCaseKeys } from 'helpers/object';
import { handleError } from 'helpers/errorHandler';

import * as toast from 'components/Shared/toast';
import ActionButton from 'components/common/actionButton/ActionButton';

import { updatePayerDeadline } from 'API/AccountSettingsAPI';

const AppealDeadlineInput = (props) => {
  const { idx, payer, fetchPayers, disabled = false } = props;

  const [appealDeadline, setAppealDeadline] = useState({
    editIndex: null,
    isError: false,
    deadline: payer.appealDeadlineLevelOne,
  });

  const handleAppealDeadlineInputChange = (e) => {
    const deadline = e.target.value;
    setAppealDeadline({
      ...appealDeadline,
      deadline,
      isError: false,
    });
  };

  const validateAppealDeadline = (deadline) => {
    if (deadline > DEADLINE_LIMIT.MAX || deadline < DEADLINE_LIMIT.MIN) {
      setAppealDeadline({
        ...appealDeadline,
        isError: true,
      });
      toast.error({
        title: 'Please enter the correct value',
        message: `Appeal Deadline(Level 1) value ranges between ${DEADLINE_LIMIT.MIN} and ${DEADLINE_LIMIT.MAX}.`,
      });
      return false;
    }
    return true;
  };

  const handleCancel = () =>
    setAppealDeadline({
      ...appealDeadline,
      deadline: payer.appealDeadlineLevelOne,
      editIndex: null,
    });

  const handleSave = async (payer) => {
    const data = {
      ...payer,
      appealDeadlineLevelOne: appealDeadline.deadline,
    };
    if (validateAppealDeadline(appealDeadline.deadline)) {
      try {
        await updatePayerDeadline(snakeCaseKeys(data));
        toast.success({
          title: 'Success',
          message: 'Appeal deadline updated successfully',
        });
      } catch (error) {
        handleError(error);
      }
      handleCancel();
      fetchPayers();
    }
  };

  return (
    <React.Fragment>
      {idx === appealDeadline.editIndex ? (
        <div className="d-flex align-items-center">
          <input
            type="number"
            min={DEADLINE_LIMIT.MIN}
            max={DEADLINE_LIMIT.MAX}
            value={appealDeadline.deadline}
            className={classnames('editable-data-input', {
              'border-color-red': appealDeadline.isError,
            })}
            onChange={handleAppealDeadlineInputChange}
          />

          <ActionButton
            Icon={MdSave}
            className="ml-12"
            dataTip="Save"
            onClick={() => handleSave(payer)}
            datacy="save-ActionButton"
          />

          <ActionButton
            Icon={BiX}
            className="ml-12"
            dataTip="Cancel"
            onClick={handleCancel}
            datacy="cancel-ActionButton"
          />
          <ReactTooltip effect="solid" multiline={true} />
        </div>
      ) : (
        <div className="d-inline-flex align-items-center">
          <span
            className="editable-data-span"
            onClick={() => {
              if (!disabled) {
                setAppealDeadline({
                  ...appealDeadline,
                  editIndex: idx,
                });
              }
            }}
          >
            {payer.appealDeadlineLevelOne || 'N/A'}
          </span>
          <span className="ml-10">day(s)</span>
        </div>
      )}
    </React.Fragment>
  );
};

AppealDeadlineInput.propTypes = {
  idx: PropTypes.number,
  payer: PropTypes.object,
  fetchPayers: PropTypes.func,
};

export default AppealDeadlineInput;
