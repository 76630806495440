import React from 'react';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { MdInfoOutline } from 'react-icons/md';
import ReactSelect, { components } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';

import './style.css';

import { generateUUID } from 'helpers/utils';

const CustomOption = ({ children, ...props }) => {
  const {
    isActive = true,
    tooltip = null,
    isToolTipDataHtml = false,
  } = props.data;
  const id = generateUUID();
  return (
    <components.Option
      className={classnames({
        'opacity-50': isActive === false,
      })}
      {...props}
    >
      <div
        {...(!tooltip ? {} : { 'data-tip': tooltip })}
        data-html={isToolTipDataHtml}
        data-for={id}
      >
        {children}
      </div>
      <ReactTooltip effect="solid" place="top" multiline={true} id={id}>
        {isToolTipDataHtml ? tooltip : null}
      </ReactTooltip>
    </components.Option>
  );
};

const ErrorMessage = ({ touched, error, useErrorSpace = false }) => {
  if (!touched && !error && !useErrorSpace) return null;
  if (!touched && !useErrorSpace) return null;
  if (!touched) return <span className="ap-input-error">&nbsp;</span>;
  return <span className="ap-input-error">{error}</span>;
};

const Select = (props) => {
  const {
    name,
    label,
    onChange,
    options,
    placeholder = label || '',
    hideMultiValueRemove = false,
    reactSelectProps = {},
    input = {},
    isLoading = false,
    hasError = false,
    classNamePrefix,
    className,
    meta: { touched = false, error = '' } = {},
    useAsyncCreatableSelect = false,
    loadOptions = () => {},
    isFocused = false,
    isDisabled = false,
    labelHelperToolTip = false,
    showLabelTooltip = false,
    useErrorSpace = false,
    renderCustomOptions,
    onCreateOption,
    formatCreateLabel,
    noOptionsMessage,
    showTooltipOnSelected = false,
  } = props;
  const handleBlur = () => input.onBlur;

  let selectValue = props.value;
  if (input && input.value) {
    selectValue = input.value.toJS ? input.value.toJS() : input.value;
  }

  const renderMultiValueRemove = (selectProps) => {
    if (hideMultiValueRemove || _get(selectProps, 'data.isFixed', false)) {
      return <div className="pr-4" />;
    }

    return <components.MultiValueRemove {...selectProps} />;
  };

  /**
   * Custom MultiValueLabel component.
   * @function MultiValueLabel
   * @param {object} selectProps - Props passed by react-select.
   * @return {boolean}
   */
  const MultiValueLabel = (selectProps) => {
    const label = _get(selectProps, 'data.label', '');
    const dataTip = showLabelTooltip ? label : null;

    return (
      <div className="ap-select-tooltip">
        <span data-tip={dataTip}>{label}</span>
        <ReactTooltip />
      </div>
    );
  };

  const CustomValueContainer = ({ children, ...props }) => {
    const {
      selectProps: { value },
    } = props;
    const randomId = Math.random().toString(36).slice(2, 9);
    return (
      <components.ValueContainer
        {...props}
        innerProps={{
          ...props.innerProps,
          ...{
            'data-tip': value?.label,
            'data-for': `valueContainer-${randomId}`,
          },
        }}
      >
        {children}
        <ReactTooltip
          className="ap-info-tooltip-background--mw-800 break-word"
          id={`valueContainer-${randomId}`}
        />
      </components.ValueContainer>
    );
  };

  const Select = useAsyncCreatableSelect ? AsyncCreatableSelect : ReactSelect;

  return (
    <div className={classnames('ap-select-container', className)}>
      <label
        className={classnames('ap-select-label', {
          'd-flex align-items-center': labelHelperToolTip,
          'd-none': !label,
        })}
        htmlFor={name}
      >
        {label}
        {labelHelperToolTip && (
          <React.Fragment>
            <MdInfoOutline
              size="16"
              className="ml-8"
              data-tip={labelHelperToolTip}
              data-for="dropdown-tooltip"
            />
            <ReactTooltip
              effect="solid"
              place="top"
              multiline={true}
              id="dropdown-tooltip"
              type="info"
              className="appealio-custom-tooltip--info appealio-custom-tooltip--info-auto appealio-custom--tooltip--text-left"
            />
          </React.Fragment>
        )}
      </label>
      <Select
        classNamePrefix={classNamePrefix}
        className={classnames('ap-select', {
          'ap-select--error': hasError,
          'ap-select--focus': isFocused,
        })}
        placeholder={placeholder}
        isDisabled={isDisabled}
        options={options}
        components={{
          IndicatorSeparator: () => null,
          MultiValueRemove: (selectProps) =>
            renderMultiValueRemove(selectProps),
          Option: renderCustomOptions || CustomOption,
          ...(showTooltipOnSelected && {
            ValueContainer: CustomValueContainer,
          }),
          ...(showLabelTooltip && { MultiValueLabel }),
        }}
        onChange={onChange}
        {...reactSelectProps}
        {...input}
        onBlur={handleBlur}
        value={selectValue || null}
        isLoading={isLoading}
        loadOptions={loadOptions}
        datacy={props.datacy}
        onCreateOption={onCreateOption}
        formatCreateLabel={formatCreateLabel}
        noOptionsMessage={noOptionsMessage}
      />
      <ErrorMessage
        touched={touched}
        error={error}
        useErrorSpace={useErrorSpace}
      />
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Select;
