import React from 'react';
import classnames from 'classnames';
import { useTable, useSortBy } from 'react-table';

import ArrowUp from 'img/keyboard-arrow-up.svg';
import ArrowDown from 'img/keyboard-arrow-down.svg';

import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import EmptyTableMessage from 'components/common/table/EmptyTableMessage';

import './style.css';

const RuleTable = ({ columns, data, defaultSorting, isLoading = false }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    className,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: defaultSorting,
    },
    useSortBy
  );

  if (data.length < 1 && !isLoading) {
    return <EmptyTableMessage />;
  }

  return (
    <table {...getTableProps()} className={`${className || ''}rule-table`}>
      <thead>
        {headerGroups.map((headerGroup, idx) => (
          <tr
            key={idx}
            {...headerGroup.getHeaderGroupProps()}
            className={classnames('rule-table__header-row')}
          >
            {headerGroup.headers.map((column, idx) => (
              <th
                key={idx}
                {...column.getHeaderProps({
                  ...column.getSortByToggleProps({ title: undefined }),
                  className: classnames('rule-table__header-col'),
                  style: { width: column.width, maxWidth: column.maxWidth },
                })}
              >
                {column.render('Header')}
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <img src={ArrowDown} alt="sort-desc" />
                  ) : (
                    <img src={ArrowUp} alt="sort-asc" />
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="rule-table__body">
        {isLoading ? (
          <tr>
            <td colSpan="100%">
              <LoadingBlockHelper
                className="dashboard-bottom-loader"
                isLoading={true}
              />
            </td>
          </tr>
        ) : (
          rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                key={index}
                {...row.getRowProps()}
                className={classnames('rule-table__body-row')}
              >
                {row.cells.map((cell, idx) => {
                  if (idx === 0) {
                    // Render row number in the first cell
                    return (
                      <td
                        {...cell.getCellProps({
                          className: classnames('rule-table__body-td', {
                            [cell.column.className]: cell.column.className,
                          }),
                          style: {
                            width: cell.column.width,
                            maxWidth: cell.column.maxWidth,
                          },
                        })}
                        key={idx}
                      >
                        {' '}
                        <div className="d-flex">
                          <div className="mr-4">{index + 1}.</div>{' '}
                          {cell.render('Cell')}
                        </div>
                      </td>
                    );
                  }
                  return (
                    <td
                      key={idx}
                      {...cell.getCellProps({
                        className: classnames('rule-table__body-td', {
                          [cell.column.className]: cell.column.className,
                          'background-cell--grey': cell.column.isSorted,
                        }),
                        style: {
                          width: cell.column.width,
                          maxWidth: cell.column.maxWidth,
                        },
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
};

export default RuleTable;
