import {
  handleErrors,
  handleJSONParse,
  INTEGRATION_API_BASE_URL,
  createRequest as createPostRequest,
} from './Config';

import { camelizeKeys, snakeCaseKeys } from 'helpers/object';

import { NOTIFICATION } from 'constants/endpoints';

/**
 * Fetches notifications based on the specified action in the payload.
 * @param {Object} payload - The payload object for the notification request.
 * @param {string} payload.action - The action type for the notification request (e.g., 'reminder_notification').
 * @param {Object} [payload.filters] - Optional filters to apply to the notification request.
 * @returns {Promise<Object>} A promise that resolves to the notification data.
 */
export const fetchNotifications = (payload) => {
  const request = createPostRequest(NOTIFICATION, snakeCaseKeys(payload), {
    baseUrl: INTEGRATION_API_BASE_URL,
  });
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const fetchAppealioNotifications = async (payload) => {
  const request = createPostRequest(NOTIFICATION, snakeCaseKeys(payload), {});

  const { data } = await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);

  return {
    data: {
      inProgress: {
        count: data?.inProgress?.count,
        latestCreatedOrUpdatedAt: data?.inProgress?.latestTimestamp,
      },
      underReview: {
        count: data?.underReview?.count,
        latestCreatedOrUpdatedAt: data?.underReview?.latestTimestamp,
      },
    },
  };
};
