import React from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import Dropdown from '../../../Shared/Dropdown';
import SearchStringFilter from '../../../Dashboard/Table/TableFilter/SearchStringFilter';

import { INTEGRATION_TYPE } from '../../../../constants/appConstants';
import { SEARCH_BY_PATIENT_DROPDOWN_OPTIONS } from '../../../../constants/options';

const extractSearchStringDropdownOptions = (integrationType) => {
  if (integrationType === INTEGRATION_TYPE.APM) {
    return SEARCH_BY_PATIENT_DROPDOWN_OPTIONS.filter(
      ({ value }) => !['claim_control_number'].includes(value)
    );
  }

  return SEARCH_BY_PATIENT_DROPDOWN_OPTIONS.filter(
    ({ value }) => !['voucher_id'].includes(value)
  );
};

const DeniedClaimsFilter = (props) => {
  const {
    filters,
    defaultFilters,
    handleFilterChange,
    handleClearFilter,
    deniedClaimsFilterOptions,
    isDefaultFiltersSelected,
    integrationType,
  } = props;

  const payerSelectOptions = deniedClaimsFilterOptions.payers?.data;
  const cptCodeSelectOptions = deniedClaimsFilterOptions.cptCodes?.data;
  const procedureModifiersSelectOptions =
    deniedClaimsFilterOptions.procedureModifiers?.data;
  const providerSelectOptions = deniedClaimsFilterOptions.providers?.data;
  const practiceSelectOptions = deniedClaimsFilterOptions.practices?.data;
  const reasonCodeSelectOptions = deniedClaimsFilterOptions.reasonCodes?.data;
  const remarkCodeSelectOptions = deniedClaimsFilterOptions.remarkCodes?.data;

  const updateSelectedFilter = (filterType, filterValue) => {
    if (filterValue.value === '') {
      return handleFilterChange({
        ...filters,
        [filterType]: null,
      });
    }

    handleFilterChange({
      ...filters,
      [filterType]: filterValue,
    });
  };

  const extractedSearchStringDropdownOptions =
    extractSearchStringDropdownOptions(integrationType);

  const handleSelectedPayerChange = (selectedPayer) =>
    updateSelectedFilter('selectedPayer', selectedPayer);

  const handleSelectedReasonCodeChange = (selectedReasonCode) =>
    updateSelectedFilter('selectedReasonCode', selectedReasonCode);

  const handleSelectedRemarkCodeChange = (selectedRemarkCode) =>
    updateSelectedFilter('selectedRemarkCode', selectedRemarkCode);

  const handleSelectedCptCodeChange = (selectedReasonCode) =>
    updateSelectedFilter('selectedCptCode', selectedReasonCode);

  const handleSelectedProcedureModifierChange = (selectedReasonCode) =>
    updateSelectedFilter('selectedCptModifierCode', selectedReasonCode);

  const handleSelectedProviderChange = (selectedProvider) =>
    updateSelectedFilter('selectedProvider', selectedProvider);

  const handleSelectedPracticeChange = (selectedPractice) => {
    handleFilterChange({
      ...filters,
      selectedPractice: selectedPractice.value ? selectedPractice : null,
      selectedProviderName: null,
    });
  };

  const selectedGeneralSearchTypeIndex =
    extractedSearchStringDropdownOptions.findIndex(
      ({ value }) => value === filters.selectedGeneralSearchType
    );

  const searchStringFilterTypeOptions =
    extractedSearchStringDropdownOptions.map(({ label, value }) => ({
      display: label,
      filterId: value,
    }));

  const onGeneralSearchFilterChange = (searchText, type) => {
    const newFilters = {
      ...defaultFilters,
      selectedGeneralSearchType: type,
      generalSearchInputText: searchText,
    };

    handleFilterChange(newFilters);
  };

  /**
   * Info: Hide practice filter if there is only one practice as option.
   * practiceSelectOptions = [{label: 'Any'}, ...practice];
   */
  const isPracticeFilterVisible =
    practiceSelectOptions && practiceSelectOptions.length > 2;
  const debounceOnGeneralSearchFilterChange = debounce(
    onGeneralSearchFilterChange,
    400
  );

  return (
    <div
      className="denied-claims-filter__container"
      datacy="denied-claims-filter"
    >
      <div className="denied-claims-filter__row">
        <div className="denied-claims-filter__search denied-claims-filter__row-item">
          <div className="denied-claims-filter__label">&nbsp;</div>
          <div className="denied-claims-filter__search_string_filter">
            <SearchStringFilter
              lastSearchStringSubmitted={filters.generalSearchInputText}
              onSearchStringFilterChange={debounceOnGeneralSearchFilterChange}
              searchStringFilterTypeOptions={searchStringFilterTypeOptions}
              searchStringFilterTypeIndexSelected={
                selectedGeneralSearchTypeIndex
              }
              disabled={false}
              allowEmptyString={true}
              datacy="denied-claims-filter-SearchStringFilter"
            />
          </div>
        </div>
        <div className="denied-claims-filter__select denied-claims-filter__row-item">
          <Dropdown
            label="Payer"
            name="payer"
            placeholder="All"
            onChange={handleSelectedPayerChange}
            value={filters.selectedPayer}
            isClearable={false}
            options={payerSelectOptions}
            isLoading={deniedClaimsFilterOptions.payers?.isFetching}
            datacy="payer-Dropdown"
          />
        </div>
        {isPracticeFilterVisible && (
          <div className="denied-claims-filter__select denied-claims-filter__row-item">
            <Dropdown
              label="Practice"
              name="practice"
              placeholder="All"
              isClearable={false}
              onChange={handleSelectedPracticeChange}
              value={filters.selectedPractice}
              options={practiceSelectOptions}
              isLoading={deniedClaimsFilterOptions.practices?.isFetching}
              datacy="practice-Dropdown"
            />
          </div>
        )}
        <div className="denied-claims-filter__select denied-claims-filter__row-item">
          <Dropdown
            label="Provider"
            name="Provider"
            placeholder="All"
            onChange={handleSelectedProviderChange}
            value={filters.selectedProvider}
            isClearable={false}
            options={providerSelectOptions}
            isLoading={deniedClaimsFilterOptions.providers?.isFetching}
            datacy="provider-Dropdown"
          />
        </div>
        <div className="denied-claims-filter__select denied-claims-filter__row-item width-150">
          <Dropdown
            label="Procedure"
            name="cpt-code"
            placeholder="All"
            onChange={handleSelectedCptCodeChange}
            value={filters.selectedCptCode}
            isClearable={false}
            options={cptCodeSelectOptions}
            isLoading={deniedClaimsFilterOptions.cptCodes?.isFetching}
            datacy="procedure-Dropdown"
          />
        </div>
        <div className="denied-claims-filter__select denied-claims-filter__row-item width-150">
          <Dropdown
            label="Modifier"
            name="procedure-modifiers"
            placeholder="All"
            onChange={handleSelectedProcedureModifierChange}
            value={filters.selectedCptModifierCode}
            isClearable={false}
            options={procedureModifiersSelectOptions}
            isLoading={deniedClaimsFilterOptions.procedureModifiers?.isFetching}
            datacy="modifier-Dropdown"
          />
        </div>
        <div className="denied-claims-filter__select denied-claims-filter__row-item width-150">
          <Dropdown
            label="Reason"
            name="reason-code"
            placeholder="All"
            onChange={handleSelectedReasonCodeChange}
            value={filters.selectedReasonCode}
            isClearable={false}
            options={reasonCodeSelectOptions}
            isLoading={deniedClaimsFilterOptions.reasonCodes?.isFetching}
            datacy="reason-Dropdown"
          />
        </div>
        <div className="denied-claims-filter__select denied-claims-filter__row-item width-150">
          <Dropdown
            label="Remark"
            name="remark-codes"
            placeholder="All"
            onChange={handleSelectedRemarkCodeChange}
            value={filters.selectedRemarkCode}
            isClearable={false}
            options={remarkCodeSelectOptions}
            isLoading={deniedClaimsFilterOptions.remarkCodes?.isFetching}
            datacy="remark-Dropdown"
          />
        </div>

        <div className="denied-claims-filter__row-item denied-claims-filter__action-buttons-container">
          {!isDefaultFiltersSelected && (
            <span
              className="denied-claims-filter__action-buttons--clear"
              onClick={handleClearFilter}
            >
              Reset
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

DeniedClaimsFilter.propTypes = {
  filters: PropTypes.object.isRequired,
  defaultFilters: PropTypes.object.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleClearFilter: PropTypes.func.isRequired,
  deniedClaimsFilterOptions: PropTypes.object.isRequired,
  isDefaultFiltersSelected: PropTypes.bool.isRequired,
  integrationType: PropTypes.oneOf(Object.values(INTEGRATION_TYPE)),
};

export default DeniedClaimsFilter;
