import moment from 'moment';
import PropTypes from 'prop-types';
import { change } from 'redux-form';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { replace } from 'connected-react-router';
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from 'redux-form/immutable';

import CPTList from './CPTList';
import SectionHeader from '../SectionHeader';
import DenialDateInput from './DenialDateInput';
import { renderField } from '../../Shared/textfield';
import { readAmountsFromCPTForm } from './CPTRowConfig';
import { ErrorHandling } from '../../Shared/Errors/ErrorHandling';
import ErrorBannerSection from '../../Shared/Errors/ErrorBannerSection';
import { CreateAppealMode, CreateAppealModes } from './CreateAppealMode';
import SaveContinueFooterComponent from '../../Shared/SaveContinueFooterComponent';
import {
  EmptyEOBProceduresWarningPopup,
  PastDeadlinePopUp,
} from '../Popups/Popups';

import { extractEmptyEobProceduresWarnings, validate } from './validate';
import { MASK_DATE } from '../../../helpers/masks';
import { CREATE_APPEAL_PATIENT_INFO } from 'constants/routes';
import { renderReduxMaskedInput } from '../reduxMaskedInput';
import { INTEGRATION_TYPE } from '../../../constants/appConstants';
import { transformDateString } from '../../../API/Serializers/Transforms';

class DenialInformation extends Component {
  static propTypes = {
    onFormSubmit: PropTypes.func,
    mode: PropTypes.oneOf(CreateAppealModes),
    shouldDisplayFooter: PropTypes.bool,
    shouldShowPreviousButton: PropTypes.bool,
    isWorkerCompensationPayer: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    setSubmitting: PropTypes.func,
    isImportedAppeal: PropTypes.bool,
  };

  static defaultProps = {
    shouldShowPreviousButton: true,
    isLoading: false,
    isSubmitting: false,
    setSubmitting: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      isPastDeadlineDialogOpen: false,
      confirmPastDeadline: false,
      isEmptyEOBProceduresDialogOpen: false,
      emptyEOBProceduresWarnings: [],
      confirmEmptyEOBProcedures: false,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.props.actions.change(
      'denial_info',
      'deadlineHidden',
      newProps.deadline
    );
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div>
        <div className="row appeal-box__row-no-padding">
          <ErrorBannerSection
            className="col-xs-offset-1 col-xs-10"
            errors={this.props.displayErrors}
          />
          <div className="col-xs-offset-1 col-xs-10 appeal-box">
            <form onSubmit={handleSubmit(this.submit)}>
              {this.renderClaimInformation()}
              {this.renderEOBInformation()}
              <div className="row divider default-border-bottom" />
              {this.props.shouldDisplayFooter && this.renderFooter()}
            </form>
          </div>
        </div>
        {this.renderPastDeadlineDialog()}
        {this.renderEmptyEOBProcedureValuesDialog()}
      </div>
    );
  }

  renderClaimInformation() {
    const shouldBeDisabled =
      [CreateAppealMode.ReAppeal, CreateAppealMode.HistoryAppeal].indexOf(
        this.props.mode
      ) !== -1;
    if (
      this.isPayerClaimIdDisabled() &&
      this.isEmployerAndInjuryDateDisabled()
    ) {
      return;
    }

    return (
      <div className="row">
        <div className="col-xs-11 col-xs-offset-1">
          <SectionHeader
            headerTitle="Claim Information"
            className="appeal-box__section-header"
          />
          <div className="col-xs-3">
            <Field
              disabled={shouldBeDisabled || this.isPayerClaimIdDisabled()}
              label="Payer Claim Id:"
              name="claimNumber"
              maxLength="50"
              autoFocus={true}
              required={true}
              component={renderField}
            />
          </div>
          {this.renderEmployerAndInjury()}
        </div>
      </div>
    );
  }

  renderEmployerAndInjury() {
    if (this.isEmployerAndInjuryDateDisabled()) {
      return;
    }

    const shouldBeDisabled =
      [CreateAppealMode.ReAppeal, CreateAppealMode.HistoryAppeal].indexOf(
        this.props.mode
      ) !== -1;

    return (
      <div>
        <div className="col-xs-3 col-xs-offset-1">
          <Field
            disabled={shouldBeDisabled}
            label="Employer name"
            name="employer"
            maxLength="150"
            required={true}
            component={renderField}
          />
        </div>
        <div className="col-xs-3 col-xs-offset-1">
          <Field
            disabled={shouldBeDisabled}
            label="Date of Injury:"
            name="injuryDate"
            size="10"
            type="string"
            required={true}
            mask={MASK_DATE}
            placeholder="MM/DD/YYYY"
            component={renderReduxMaskedInput}
          />
        </div>
      </div>
    );
  }

  renderEOBInformation() {
    const disableEOBDate = this.isEOBDateDisabled();

    return (
      <div className="row">
        <div className="col-xs-11 col-xs-offset-1">
          <SectionHeader
            headerTitle="EOB Information"
            className="appeal-box__section-header"
          />
        </div>

        <div className="col-xs-10 col-xs-offset-1">
          <DenialDateInput
            appealID={this.props.appealID}
            mode={this.props.mode}
            initialValues={this.props.initialValues}
            shouldCalculateDeadline={
              this.props.mode !== CreateAppealMode.ReAppeal
            }
            disabled={
              this.props.mode === CreateAppealMode.HistoryAppeal ||
              disableEOBDate
            }
          />
          <Field
            name="deadlineHidden"
            input={{ type: 'hidden' }}
            component={renderField}
          />
          <div className="divider" />
        </div>
        <div className="col-xs-11 col-xs-offset-1">
          <SectionHeader
            headerTitle="Claim Lines (optional)"
            className="appeal-box__section-header"
          />
        </div>
        <div className="col-xs-10 col-xs-offset-1">
          <CPTList {...this.props} />
        </div>
      </div>
    );
  }

  // renderTotalDeniedAmount() {
  //   const {totalDeniedAmount} = this.props.sums;
  //   return <TotalDeniedAmount totalDeniedAmount={totalDeniedAmount}/>
  // }

  renderFooter() {
    return (
      <div className="mt-24">
        <SaveContinueFooterComponent
          isLoading={this.props.isSubmitting}
          shouldShowPreviousButton={this.props.shouldShowPreviousButton}
          previousButtonClassName="col-xs-2"
          onPreviousPressed={this.onPreviousPressed}
          shouldShowDiscardAndSkipButton={this.props.isImportedAppeal}
          discardAndSkipBtnTitle="Skip to Attach Documents"
          onDiscardAndSkipPressed={() =>
            this.props.handleSubmit((values) => this.submit(values, true))()
          }
          shouldShowCancelButton={!this.props.isImportedAppeal}
        />
      </div>
    );
  }

  renderPastDeadlineDialog() {
    const popupParams = {
      onClosePressed: this.onPastDeadlineCloseDialog,
      onActionButtonPressed: this.onPastDeadlineActionButtonPressed,
      actionPopupClassName: 'denial-info__discard-popup',
    };

    if (this.state.isPastDeadlineDialogOpen === true) {
      return <PastDeadlinePopUp {...popupParams} />;
    }
  }

  onCloseEmptyEOBProcedureValuesDialog = () => {
    this.setState({
      isEmptyEOBProceduresDialogOpen: false,
      emptyEOBProceduresWarnings: [],
    });
  };

  onEmptyEOBProcedureValuesDialogActionButtonPressed = (buttonIndex) => {
    if (buttonIndex === 1) {
      this.setState(
        {
          isEmptyEOBProceduresDialogOpen: false,
          emptyEOBProceduresWarnings: [],
          confirmEmptyEOBProcedures: true,
        },
        () => setTimeout(this.props.handleSubmit(this.submit), 50)
      );
    } else {
      this.onCloseEmptyEOBProcedureValuesDialog();
    }
  };

  renderEmptyEOBProcedureValuesDialog() {
    const popupParams = {
      onClosePressed: this.onCloseEmptyEOBProcedureValuesDialog,
      onActionButtonPressed:
        this.onEmptyEOBProcedureValuesDialogActionButtonPressed,
      actionPopupClassName: 'denial-info__discard-popup',
      warnings: this.state.emptyEOBProceduresWarnings,
    };

    if (this.state.isEmptyEOBProceduresDialogOpen === true) {
      return <EmptyEOBProceduresWarningPopup {...popupParams} />;
    }
  }

  isAllInputFieldsDisabled() {
    return (
      this.isEOBDateDisabled &&
      this.isPayerClaimIdDisabled() &&
      this.isCPTInputsDisabled() &&
      this.isEmployerAndInjuryDateDisabled()
    );
  }

  isEmployerAndInjuryDateDisabled() {
    return !this.props.isWorkerCompensationPayer;
  }

  isEOBDateDisabled() {
    const initialDenialDate =
      this.props.initialValues && this.props.initialValues.get('denialDate');

    return (
      this.props.isImportedAppeal &&
      initialDenialDate &&
      this.props.integrationType === INTEGRATION_TYPE.EDI
    );
  }

  isPayerClaimIdDisabled() {
    return (
      this.props.initialValues && this.props.initialValues.get('claimNumber')
    );
  }

  onPastDeadlineCloseDialog = () => {
    this.setState({
      isPastDeadlineDialogOpen: false,
      confirmPastDeadline: false,
    });
  };

  onPastDeadlineActionButtonPressed = (buttonIndex) => {
    if (buttonIndex === 1) {
      this.setState(
        { isPastDeadlineDialogOpen: false, confirmPastDeadline: true },
        () => {
          setTimeout(
            this.props.handleSubmit((values) =>
              this.submit(values, this.state.skipStep)
            ),
            50
          );
        }
      );
    } else {
      this.onPastDeadlineCloseDialog();
    }
  };

  onPreviousPressed = () => {
    this.props.actions.replace(
      `${CREATE_APPEAL_PATIENT_INFO}?appealId=${this.props.appealID}`
    );
  };

  submit = (values, skipStep = false) => {
    this.props.setSubmitting(true);
    return new Promise((resolve, reject) => {
      const errors = this.props.validateForm(values, validate);
      if (errors.displayErrors.length > 0) {
        this.props.setSubmitting(false);
        window.scrollTo(0, 0);
        throw new SubmissionError(errors);
      } else {
        values.set('denialDate', transformDateString(values.get('denialDate')));

        const deadline = values.get('deadlineHidden');
        if (
          !this.state.confirmPastDeadline &&
          deadline &&
          !moment(transformDateString(deadline)).isAfter(
            moment().startOf('day'),
            'day'
          )
        ) {
          this.setState({
            isPastDeadlineDialogOpen: true,
            confirmPastDeadline: false,
            skipStep,
          });
          this.props.setSubmitting(false);
          return reject(new Error('deadline in past'));
        }

        const emptyEOBProceduresWarnings = extractEmptyEobProceduresWarnings(
          values.get('cpts')
        );

        if (
          !this.props.isImportedAppeal &&
          !this.state.confirmEmptyEOBProcedures &&
          !isEmpty(emptyEOBProceduresWarnings)
        ) {
          this.setState({
            isEmptyEOBProceduresDialogOpen: true,
            emptyEOBProceduresWarnings,
          });

          this.props.setSubmitting(false);

          return reject(new Error('empty cpt code'));
        }

        const jsValues = values.toJS();
        const proceduresParams = {
          procedures: jsValues.cpts,
          eobID: this.props.eobID,
        };
        const updateAppealParams = {
          appealID: this.props.appealID,
          stepNumber: 2,
          attrs: {
            claimNumber: jsValues.claimNumber,
            deadline: this.props.deadline,
            deniedAt: this.props.denialDate,
          },
        };
        if (this.props.isWorkerCompensationPayer) {
          updateAppealParams.attrs.employer = jsValues.employer;
          updateAppealParams.attrs.injuryDate = transformDateString(
            jsValues.injuryDate
          );
        }
        resolve(
          this.props.onFormSubmit(
            proceduresParams,
            updateAppealParams,
            skipStep
          )
        );
      }
    });
  };

  isCreatingNewAppeal = () => {
    return this.props.mode === CreateAppealMode.NewAppeal;
  };
}

const selector = formValueSelector('denial_info');

function mapStateToProps(state, ownProps) {
  const cpts = selector(state, 'cpts');
  const denialDate = selector(state, 'denialDate');
  const appealID = ownProps.appealID;
  const isSubmitting = ownProps.isSubmitting;

  const deadlineStore = state.get('deadlineStore');

  let deadline;
  if (deadlineStore.has(appealID)) {
    deadline = deadlineStore.get(appealID).get('deadlineDate');
  }

  if (cpts == null) {
    return {
      sums: {
        billedAmount: 0,
        amountAllowed: 0,
        deductible: 0,
        coInsurance: 0,
        paymentAmount: 0,
        totalDeniedAmount: 0,
      },
      denialDate,
      deadline,
      isSubmitting,
    };
  } else {
    const sums = readAmountsFromCPTForm(cpts);
    return { sums, denialDate, deadline, isSubmitting };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ change, replace }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ErrorHandling(
    reduxForm({
      form: 'denial_info',
      persistentSubmitErrors: true,
      enableReinitialize: true,
    })(DenialInformation)
  )
);
