import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { BsSearch } from 'react-icons/bs';
import { bindActionCreators } from 'redux';

import './style.css';

import { DATA_TYPE } from '../hook';

import { getUserInfo } from 'redux/reducers/loginStore';
import { openUhcClaimStatusWidget } from 'redux/actions/uhcClaimStatusWidgetActions';
import { openAvailityClaimStatusWidget } from 'redux/actions/availityClaimStatusWidgetActions';

import {
  isValidDate,
  getFormattedDate,
  VALIDATION_DATE_FORMAT,
} from 'helpers/dateFormats';
import { DENIALS_FLYOVER_TITLE } from 'constants/appConstants';

const SEARCH_LIST_TITLE = {
  [DATA_TYPE.APPEAL]: 'In Progress',
  [DATA_TYPE.DENIAL]: 'Denials',
  [DATA_TYPE.ARCHIVE]: 'Archived Claims',
  [DATA_TYPE.DELIVERY_TRACKER]: 'Delivery Tracker',
  [DATA_TYPE.CLAIM]: DENIALS_FLYOVER_TITLE,
  [DATA_TYPE.ACTION_LOG_TODO]: 'Pending Actions',
  [DATA_TYPE.ACTION_LOG_COMPLETED]: 'Completed Actions',
  [DATA_TYPE.ARCHIVE_DENIALS]: 'Archived DQ',
  [DATA_TYPE.ARCHIVE_ACTION_LOG_TODO]: 'Archived Pending',
  [DATA_TYPE.ARCHIVE_ACTION_LOG_COMPLETED]: 'Archived Completed',
};

function SearchResultContainer(props) {
  const {
    data = [],
    loading = false,
    handleTitleClick,
    handleRowClick,
    NoResultComponent = () => <strong>No results found</strong>,
    SearchListFooterComponent = () => null,
    showSubmitterClaimId = false,
  } = props;
  return (
    <div className={classnames('search-result-container', props.className)}>
      {loading ? (
        <strong className="ml-12">Searching...</strong>
      ) : data.length ? (
        <div>
          <p className="pl-8">Select a claim from the list below:</p>
          {data.map(({ type, data, count }, idx) => {
            return (
              <div key={idx} className="mb-4">
                <div
                  className="search-list-title"
                  onClick={() => handleTitleClick(type, data)}
                >
                  <div>
                    {SEARCH_LIST_TITLE[type]} ({count})
                  </div>
                  <div>{'->'}</div>
                </div>
                <div className="search-list">
                  {data.map((item, idx) => (
                    <div
                      className="row no-gutter search-list-item"
                      key={idx}
                      onClick={() => handleRowClick(type, item)}
                    >
                      <div className="col-lg-4 text-overflow-ellipsis">
                        <div className="search-list-item-title">Name</div>
                        <div>{item.patientName}</div>
                      </div>
                      <div className="col-lg-4 text-overflow-ellipsis">
                        <div className="search-list-item-title">
                          {showSubmitterClaimId
                            ? 'Claim ID'
                            : 'Claim Control Number'}
                        </div>
                        <div>
                          {showSubmitterClaimId
                            ? item?.submitterClaimId || 'N/A'
                            : item?.claimNumber || 'N/A'}
                        </div>
                      </div>
                      <div className="col-lg-2 text-overflow-ellipsis">
                        <div className="search-list-item-title">EOB Date</div>
                        <div>
                          {item.eobDate && isValidDate(item.eobDate)
                            ? getFormattedDate(
                                item.eobDate,
                                VALIDATION_DATE_FORMAT
                              )
                            : 'N/A'}
                        </div>
                      </div>
                      <div className="col-lg-2">
                        {(item.uhcClaimStatusSearchData ||
                          item.availityClaimStatusSearchData) && (
                          <div className="d-flex justify-content-flex-end mr-8">
                            <button
                              data-tip={`Claim Status Check via ${
                                item.availityClaimStatusSearchData
                                  ? 'Availity'
                                  : 'UHC'
                              }`}
                              className="ap-button ap-button--action search-list-item-search-claim-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (item.availityClaimStatusSearchData) {
                                  return props.actions.openAvailityClaimStatusWidget(
                                    item.availityClaimStatusSearchData
                                  );
                                }
                                props.actions.openUhcClaimStatusWidget(
                                  item.uhcClaimStatusSearchData
                                );
                              }}
                            >
                              <BsSearch className="search-list-item-search-claim-btn-icon" />
                            </button>
                            <ReactTooltip
                              effect="solid"
                              place="top"
                              multiline={true}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
          <SearchListFooterComponent />
        </div>
      ) : (
        <NoResultComponent />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);
  if (!userInfo) {
    return {};
  }

  return {
    userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { openUhcClaimStatusWidget, openAvailityClaimStatusWidget },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultContainer);
