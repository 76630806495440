import {
  handleErrors,
  BASE_API_URL,
  createRequest,
  handleJSONParse,
} from './Config';
import { camelizeKeys, snakeCaseKeys } from 'helpers/object';

import { CREATE_IDENTICAL_IN_PROGRESS } from 'constants/endpoints';

/**
 * Creates a new claim with a different attachment than an existing claim.
 *
 * @param {Object} values - The values to create the new claim with.
 * @returns {Promise<Object>} - The response from the API call.
 */
export const createSameClaimDifferentAttachment = (values) => {
  const request = createRequest(
    CREATE_IDENTICAL_IN_PROGRESS,
    snakeCaseKeys(values),
    {
      baseUrl: BASE_API_URL,
      method: 'POST',
    }
  );
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};
