import React from 'react';
import { debounce } from 'lodash';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

const PageNavigator = (props) => {
  const {
    totalPages,
    activePage,
    onPageChange,
    isDisabled,
    onDisableBtnClick,
  } = props;

  const handleBackBtnClick = () => {
    if (isDisabled) {
      return onDisableBtnClick();
    }

    if (activePage !== 1) {
      onPageChange(activePage - 1);
    }
  };
  const handleNextBtnClick = () => {
    if (isDisabled) {
      return onDisableBtnClick();
    }

    if (activePage !== totalPages) {
      onPageChange(activePage + 1);
    }
  };

  return (
    <div className="page-navigator__container" datacy="page-navigator">
      <div>
        Page {activePage} of {totalPages}
      </div>
      <div className="page-navigator__action-buttons">
        <BsChevronLeft
          onClick={debounce(handleBackBtnClick, 500)}
          className="page-navigator__action-button"
          datacy="page-navigator-BsChevronLeft"
        />
        <BsChevronRight
          onClick={debounce(handleNextBtnClick, 500)}
          className="page-navigator__action-button"
          datacy="page-navigator-BsChevronRight"
        />
      </div>
    </div>
  );
};

export default PageNavigator;
