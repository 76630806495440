import * as routes from 'constants/routes';
import { HOME_PAGE_OPTIONS } from 'constants/options';

/**
 * Retrieves the home page route for the given user based on their configuration.
 *
 * @param {Object} userInfo - User information containing client configurations.
 * @returns {string} - The route for the user's home page or the default denied queue route.
 */
export const getHomePageRouteForUser = (userInfo) => {
  const homePage = userInfo?.clientConfig?.home_page;
  const selectedOption = HOME_PAGE_OPTIONS.filter((option) =>
    option.checkPermission(userInfo)
  ).find((option) => option.value === homePage);
  return selectedOption?.route || routes.DENIED_QUEUE;
};
