import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ActionButton = (props) => {
  const {
    className,
    Icon,
    dataTip,
    onClick,
    iconClassName,
    disabled,
    type = 'button',
    datacy = 'action-button',
  } = props;
  return (
    <button
      type={type}
      data-tip={dataTip}
      disabled={disabled}
      className={classnames('ap-button ap-button--action', className)}
      data-delay-show="200"
      onClick={onClick}
      datacy={datacy}
    >
      <Icon className={classnames('appeal__action', iconClassName)} />
    </button>
  );
};

ActionButton.propTypes = {
  className: PropTypes.string,
  Icon: PropTypes.any.isRequired,
  dataTip: PropTypes.string,
  onClick: PropTypes.func,
  iconClassName: PropTypes.string,
};

export default ActionButton;
