import { useCallback, useEffect, useState } from 'react';

import { fetchRulesOverview } from 'API/DenialsQueueAnalyticsAPI';

/**
 * Use fetch payers hook.
 * @returns {Promise}
 */
export const useFetchRulesOverview = ({
  sortBy,
  userPracticeId,
  userSecretKey,
  search,
}) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetch = useCallback(async () => {
    const queryParams = {};
    try {
      const data = await fetchRulesOverview({
        queryParams,
        sortBy,
        userPracticeId,
        userSecretKey,
        search,
      });

      setData(data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, [sortBy, search, userPracticeId, userSecretKey]);

  useEffect(() => {
    setLoading(true);
    fetch();
  }, [fetch]);

  return { loading, error, data };
};
