import {
  handleErrors,
  createRequest,
  handleJSONParse,
  INTEGRATION_API_BASE_URL,
} from './Config';

import { camelizeKeys } from 'helpers/object';

export const searchCodes = ({ carcs = [], rarcs = [] }) => {
  const request = createRequest(
    'codes/search/',
    {
      carcs,
      rarcs,
    },
    {
      baseUrl: INTEGRATION_API_BASE_URL,
      isBearer: true,
    }
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};
