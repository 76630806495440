import { Virtuoso } from 'react-virtuoso';
import { snakeCase, startCase } from 'lodash';
import React, { useState, useEffect, useMemo, useCallback } from 'react';

import ErrorRow from './ErrorRow';
import Button from 'components/common/button';
import AppealioPopup from 'components/Shared/AppealioPopup';

import {
  fetchBillingProviders,
  fetchRenderingProviders,
} from 'API/AccountSettingsAPI';
import { handleError } from 'helpers/errorHandler';

import './style.css';

import { removeEmptyOrInactiveOptions } from 'helpers/utils';

const BulkFindAndReplace = (props) => {
  const { errorTypeToFix, data, rowKey, onFindAndReplace, dropdownOptions } =
    props;
  const [replacementValues, setReplacementValues] = useState({});
  const [billingProviderOptions, setBillingProviderOptions] = useState({});
  const [renderingProviderOptions, setRenderingProviderOptions] = useState({});

  const handleReplacementChange = useCallback((value, replacementValue) => {
    if (!replacementValue || !replacementValue.length) {
      setReplacementValues((prevState) => {
        delete prevState[value];
        return { ...prevState };
      });
      return;
    }

    setReplacementValues((prevState) => ({
      ...prevState,
      [value]: replacementValue[0].label,
    }));
  }, []);

  const hasPracticeError = useCallback((row) => {
    return row.errors.some(
      (error) =>
        error.rowKey === 'practice' && error.error === 'PRACTICE_NOT_FOUND'
    );
  }, []);

  const hasRenderingProviderError = useCallback((row) => {
    return row.errors.some(
      (error) =>
        error.rowKey === 'renderingProvider' &&
        error.error === 'RENDERING_PROVIDER_NOT_FOUND'
    );
  }, []);

  const updatePractice = useCallback((row, replacementValue) => {
    if (!replacementValue) {
      return row;
    }
    const updatedErrors = row.errors.filter(
      (error) =>
        !(error.rowKey === 'practice' && error.error === 'PRACTICE_NOT_FOUND')
    );

    return {
      ...row,
      practice: replacementValue,
      errors: updatedErrors,
    };
  }, []);

  const updatePayer = useCallback((row, replacementValue) => {
    if (!replacementValue) {
      return row;
    }
    const updatedErrors = row.errors.filter(
      (error) =>
        !(error.rowKey === 'payer' && error.error === 'PAYER_NOT_FOUND')
    );

    return {
      ...row,
      payer: replacementValue,

      errors: updatedErrors,
    };
  }, []);

  const updateRenderingProvider = useCallback((row, replacementValue) => {
    if (!replacementValue) {
      return row;
    }
    const updatedErrors = row.errors.filter(
      (error) =>
        !(
          error.rowKey === snakeCase('renderingProvider') &&
          error.error === 'RENDERING_PROVIDER_NOT_FOUND'
        )
    );

    return {
      ...row,
      renderingProvider: replacementValue,
      errors: updatedErrors,
    };
  }, []);

  const updateBillingProvider = useCallback((row, replacementValue) => {
    if (!replacementValue) {
      return row;
    }

    const updatedErrors = row.errors.filter(
      (error) =>
        !(
          error.rowKey === snakeCase('billingProvider') &&
          error.error === 'BILLING_PROVIDER_NOT_FOUND'
        )
    );

    return {
      ...row,
      billingProvider: replacementValue,
      errors: updatedErrors,
    };
  }, []);

  const filterErrorDataToBeUpdated = useCallback(
    (row) => {
      if (rowKey === 'renderingProvider' && hasPracticeError(row)) {
        return false;
      }

      if (
        rowKey === 'billingProvider' &&
        (hasPracticeError(row) || hasRenderingProviderError(row))
      ) {
        return false;
      }

      return row.errors.some(
        (error) =>
          error.rowKey === snakeCase(rowKey) && error.error === errorTypeToFix
      );
    },
    [rowKey, hasPracticeError, hasRenderingProviderError, errorTypeToFix]
  );

  const errorData = useMemo(
    () => data.filter(filterErrorDataToBeUpdated),
    [data, filterErrorDataToBeUpdated]
  );

  const uniqueErrors = useMemo(
    () => [...new Set(errorData.map((row) => row[rowKey]))],
    [errorData, rowKey]
  );

  const getUpdatedRows = useCallback(() => {
    return data.map((row) => {
      if (uniqueErrors.includes(row[rowKey])) {
        if (rowKey === 'practice') {
          return updatePractice(row, replacementValues[row[rowKey]]);
        } else if (rowKey === 'payer') {
          return updatePayer(row, replacementValues[row[rowKey]]);
        } else if (rowKey === 'renderingProvider') {
          if (!hasPracticeError(row)) {
            return updateRenderingProvider(row, replacementValues[row[rowKey]]);
          }
        } else if (rowKey === 'billingProvider') {
          if (!hasPracticeError(row) && !hasRenderingProviderError(row)) {
            return updateBillingProvider(row, replacementValues[row[rowKey]]);
          }
        }
      }
      return row;
    });
  }, [
    data,
    uniqueErrors,
    rowKey,
    updatePractice,
    replacementValues,
    updatePayer,
    hasPracticeError,
    updateRenderingProvider,
    hasRenderingProviderError,
    updateBillingProvider,
  ]);

  const handleReplaceClick = useCallback(() => {
    const updatedData = getUpdatedRows();
    onFindAndReplace(updatedData);
  }, [getUpdatedRows, onFindAndReplace]);

  const fetchRenderingProviderOptionsForPractice = useCallback(
    async (practiceName) => {
      try {
        const { data } = await fetchRenderingProviders({
          practice_name: practiceName,
          format_for_dropdown: true,
        });
        const renderingProviderOptions = data.map((provider) => ({
          ...provider,
          label: provider.providerName,
          value: provider.providerName,
        }));
        setRenderingProviderOptions((prevOptions) => ({
          ...prevOptions,
          [practiceName]: renderingProviderOptions,
        }));
      } catch (error) {
        handleError(error);
      }
    },
    []
  );

  const fetchBillingProviderOptionsForProvider = useCallback(
    async (practiceName, providerName) => {
      try {
        const { data } = await fetchBillingProviders({
          practice_name: practiceName,
          rendering_provider_name: providerName,
          format_for_dropdown: true,
        });
        const providerOptions = data.map((provider) => ({
          ...provider,
          label: provider.providerName,
          value: provider.providerName,
        }));
        setBillingProviderOptions((prevOptions) => ({
          ...prevOptions,
          [`${practiceName}_${providerName}`]: providerOptions,
        }));
      } catch (error) {
        handleError(error);
      }
    },
    []
  );

  const getDropdownOptions = useCallback(
    (practiceName, providerName) => {
      if (errorTypeToFix === 'PRACTICE_NOT_FOUND') {
        return removeEmptyOrInactiveOptions(dropdownOptions?.practices?.data);
      } else if (errorTypeToFix === 'PAYER_NOT_FOUND') {
        return removeEmptyOrInactiveOptions(
          dropdownOptions?.payers?.data,
          false
        );
      } else if (errorTypeToFix === 'RENDERING_PROVIDER_NOT_FOUND') {
        return removeEmptyOrInactiveOptions(
          renderingProviderOptions[practiceName]
        );
      } else if (errorTypeToFix === 'BILLING_PROVIDER_NOT_FOUND') {
        return removeEmptyOrInactiveOptions(
          billingProviderOptions[`${practiceName}_${providerName}`]
        );
      }
      return [];
    },
    [
      errorTypeToFix,
      dropdownOptions,
      renderingProviderOptions,
      billingProviderOptions,
    ]
  );

  const uniqueErrorPracticeNames = useMemo(
    () => [...new Set(errorData.map((row) => row.practice))],
    [errorData]
  );

  useEffect(() => {
    if (errorTypeToFix === 'RENDERING_PROVIDER_NOT_FOUND') {
      uniqueErrorPracticeNames.forEach((practiceName) => {
        if (!renderingProviderOptions[practiceName]) {
          fetchRenderingProviderOptionsForPractice(practiceName);
        }
      });
    } else if (errorTypeToFix === 'BILLING_PROVIDER_NOT_FOUND') {
      const uniqueProviderPracticeNamesObj = errorData.reduce((acc, row) => {
        const practiceName = row.practice;
        const providerName = row.renderingProvider;
        if (!acc[`${practiceName}_${providerName}`]) {
          acc[`${practiceName}_${providerName}`] = {
            practiceName,
            providerName,
          };
        }
        return acc;
      }, {});

      const uniqueProviderPracticeNames = Object.values(
        uniqueProviderPracticeNamesObj
      );
      uniqueProviderPracticeNames.forEach(({ practiceName, providerName }) => {
        if (!billingProviderOptions[`${practiceName}_${providerName}`]) {
          fetchBillingProviderOptionsForProvider(practiceName, providerName);
        }
      });
    }
  }, [
    errorTypeToFix,
    errorData,
    uniqueErrorPracticeNames,
    fetchRenderingProviderOptionsForPractice,
    fetchBillingProviderOptionsForProvider,
    renderingProviderOptions,
    billingProviderOptions,
  ]);

  const rowsToBeUpdated = useMemo(() => {
    return (
      errorData.length -
      getUpdatedRows().filter((row) => filterErrorDataToBeUpdated(row)).length
    );
  }, [errorData.length, getUpdatedRows, filterErrorDataToBeUpdated]);

  const rowsNotToBeUpdated = errorData.length - rowsToBeUpdated;

  return (
    <AppealioPopup
      className="appealio-popup appealio-action-popup csv-claims-import-find-and-replace-popup"
      titleClassName="appealio-action-popup__header csv-claims-import-confirm-popup__title"
      title={`Find & Replace ${startCase(rowKey)}`}
      onClosePressed={props.onCloseClick}
    >
      <div>
        <div className="csv-claims-import-find-and-replace-popup__info">
          {uniqueErrors.length} Unique {startCase(rowKey)} as Errors
        </div>
        <Virtuoso
          className="csv-claims-import-find-and-replace-popup__content"
          data={uniqueErrors}
          itemContent={(index, value) => {
            const options = getDropdownOptions(
              errorData.find((row) => row[rowKey] === value).practice,
              errorData.find((row) => row[rowKey] === value).renderingProvider
            );
            const selectedOption = options.filter(
              (option) => option.label === replacementValues[value]
            );
            const numOfRowsWithThisError = errorData.filter(
              (row) => row[rowKey] === value
            ).length;
            const rowNumber = errorData.find(
              (row) => row[rowKey] === value
            ).rowNumber;

            return (
              <ErrorRow
                key={value}
                idx={index}
                value={value}
                rowKey={rowKey}
                rowNumber={rowNumber}
                options={options}
                selectedOption={selectedOption}
                handleReplacementChange={handleReplacementChange}
                numOfRowsWithThisError={numOfRowsWithThisError}
              />
            );
          }}
        />
      </div>

      <div className="d-flex justify-content-flex-end align-item-center">
        {rowsToBeUpdated === 0 && (
          <span>{rowsNotToBeUpdated} errored row(s) found</span>
        )}
        {!(rowsToBeUpdated === 0) && rowsNotToBeUpdated > 0 && (
          <span>{rowsNotToBeUpdated} Errored row(s) will not be updated</span>
        )}
        <Button
          onClick={handleReplaceClick}
          disabled={rowsToBeUpdated === 0}
          className="ap-button--secondary mb-0 ml-8"
        >
          Update {rowsToBeUpdated > 0 ? rowsToBeUpdated : ''} Row(s)
        </Button>
      </div>
    </AppealioPopup>
  );
};

export default BulkFindAndReplace;
