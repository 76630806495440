import React from 'react';

import emptyIcon from 'img/empty.svg';

const NoDataFound = () => (
  <div className="no-data-container">
    <img src={emptyIcon} alt="No Data Found" />
    <h4>No Data Found</h4>
  </div>
);

export default NoDataFound;
