import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import React, { Component } from 'react';

import OutsideClickWrapper from 'components/common/outsideClickWrapper/OutsideClickWrapper';

import {
  isAppealioExpressUser,
  isSuperUserAndStaffUser,
  isClientAdminOrSupervisor,
} from 'Auth/AuthUtils';

export default class AccountMenu extends Component {
  static propTypes = {
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };

  onSignOutClick = (e) => {
    if (this.props.onSignOutClick) {
      this.props.onSignOutClick(e);
    }
  };

  redirectToAdminPanel = () => {
    const adminDashboardUrl = process.env.REACT_APP_ADMIN_DASHBOARD;
    window.location = adminDashboardUrl;
  };

  renderAdminAccessLinks = () => {
    const isSuperuserAndStaff = isSuperUserAndStaffUser(this.props.userInfo);
    if (isSuperuserAndStaff) {
      return (
        <>
          <div
            className="accountmenu-content-item accountmenu-content-item--link"
            onClick={this.props.onClaimSearchClick}
          >
            Claim Search
          </div>
          <div
            className="accountmenu-content-item accountmenu-content-item--link"
            onClick={this.redirectToAdminPanel}
          >
            Admin Panel
          </div>
        </>
      );
    }
  };

  render() {
    return (
      <OutsideClickWrapper handleOutsideClick={this.props.onClickedOutside}>
        <div className="accountmenu" datacy="account-menu">
          <div className="accountmenu-content">
            <div className="accountmenu-content-topbox">
              <div className="accountmenu-content-topbox-details">
                <div className="accountmenu-content-topbox-details-name">
                  <Truncate lines={1} datacy="accountmenu-fullname-Truncate">
                    {this.props.fullName}
                  </Truncate>
                </div>
                <div className="accountmenu-content-topbox-details-email">
                  <Truncate lines={1} datacy="accountmenu-email-Truncate">
                    {this.props.email}
                  </Truncate>
                </div>
              </div>
            </div>
            {isClientAdminOrSupervisor(this.props.role) &&
              !isAppealioExpressUser(this.props.userInfo) && (
                <div
                  className="accountmenu-content-item accountmenu-content-item--link"
                  onClick={this.props.onSettingsClick}
                  datacy="accountmenu-settings-div"
                >
                  Settings
                </div>
              )}
            {isClientAdminOrSupervisor(this.props.role) &&
              isAppealioExpressUser(this.props.userInfo) && (
                <div
                  className="accountmenu-content-item accountmenu-content-item--link"
                  onClick={this.props.onSettingsClickForExpressUser}
                  datacy="accountmenu-settings-div"
                >
                  Settings
                </div>
              )}
            {this.renderAdminAccessLinks()}
            <div
              onClick={this.onSignOutClick}
              className="accountmenu-content-item accountmenu-content-item--signout"
              datacy="accountmenu-sign-out-div"
            >
              Sign Out
            </div>
          </div>
        </div>
      </OutsideClickWrapper>
    );
  }
}
