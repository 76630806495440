/**
 * Created by alex on 12/16/16.
 */

import { Serializer as JSONAPISerializer } from 'jsonapi-serializer';

export const HelpSerializer = new JSONAPISerializer('HelpMessage', {
  attributes: ['text', 'email'],
  pluralizeType: false,
  keyForAttribute: 'underscore_case',
});
