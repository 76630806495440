import React from 'react';

import Button from 'components/common/button';
import { BUTTON_TYPE } from 'components/common/button/Button';

import './styles.css';

import * as routes from 'constants/routes';

const SuccessfulAccountCreation = (props) => {
  return (
    <div className="successful-account-wrapper">
      <div className="successful-account-container">
        <div>
          <div className="successful-account-title">Congratulations!</div>
          <div className="successful-account-subtitle">
            You have completed setting up your Appealio account. Below is a
            summary of what has been added to Appealio.
          </div>
        </div>

        <div className="count-container">
          <div className="practice-count-card">
            <div className="practice-count-card__count">
              {props.practices.length}
            </div>
            <div className="practice-count-card__title">Practice</div>
          </div>

          <div className="practice-count-card">
            <div className="practice-count-card__count">
              {props.clinics.length}
            </div>
            <div className="practice-count-card__title">Clinics</div>
          </div>

          <div className="practice-count-card">
            <div className="practice-count-card__count">
              {props.billingProviders.length}
            </div>
            <div className="practice-count-card__title">Billing Providers</div>
          </div>

          <div className="practice-count-card">
            <div className="practice-count-card__count">
              {props.renderingProviders.length}
            </div>
            <div className="practice-count-card__title">
              Rendering Providers
            </div>
          </div>

          <div className="practice-count-card">
            <div className="practice-count-card__count">
              {props.payerIds.length}
            </div>
            <div className="practice-count-card__title">Payers</div>
          </div>

          <div className="practice-count-card">
            <div className="practice-count-card__count">
              {props.templateIds.length}
            </div>
            <div className="practice-count-card__title">Templates</div>
          </div>
        </div>

        <div className="action-button-container">
          <Button
            className="action-button"
            title="Create my first Appeal"
            onClick={() => {
              window.location = routes.SUBMISSIONS_IN_PROGRESS;
            }}
          />

          <Button
            className="action-button secondary-outline"
            title="Take me to my Dashboard"
            type={BUTTON_TYPE.LIGHT}
            onClick={() => {
              window.location = routes.MANAGER_DASHBOARD_TEAM;
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SuccessfulAccountCreation;
