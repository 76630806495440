import classnames from 'classnames';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { BsPencil } from 'react-icons/bs';
import { IoMdCloseCircleOutline } from 'react-icons/io';

import Card from 'components/common/card';
import AddClinicForm from './AddClinicForm';
import { generateUUID } from 'helpers/utils';
import { APPEALIO_SUPPORT_EMAIL } from 'constants/appConstants';
import ActionPopup, { TYPE_ACCEPT } from 'components/Shared/ActionPopup';

import infoIcon from 'img/info.svg';
import warningIcon from 'img/incomplete.svg';

const AddClinicContainer = (props) => {
  const { practices, clinics, updateFormData, onClinicDelete } = props;

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [clinicToUpdate, setClinicToUpdate] = useState(null);
  const [selectedPractice, setSelectedPractice] = useState(practices[0]);
  const [deleteClinicModal, setDeleteClinicModal] = useState({
    isOpen: false,
    clinic: null,
  });
  const resetDeleteClinicModal = () =>
    setDeleteClinicModal({
      isOpen: false,
      clinic: null,
    });
  const openDeleteClinicModal = (clinic) =>
    setDeleteClinicModal({
      isOpen: true,
      clinic,
    });

  const resetFormVisibility = () => {
    setIsFormVisible(false);
    setClinicToUpdate(null);
  };

  const handleSubmit = (values) => {
    const valuesWithPractice = {
      ...values,
      practiceUuid: selectedPractice.uuid,
    };

    if (!clinicToUpdate) {
      resetFormVisibility();

      return updateFormData([
        ...clinics,
        { uuid: generateUUID(), ...valuesWithPractice },
      ]);
    }

    const updatedClinics = clinics.map((clinic) =>
      clinic.uuid === clinicToUpdate.uuid
        ? { uuid: clinic.uuid, ...valuesWithPractice }
        : clinic
    );

    resetFormVisibility();
    updateFormData(updatedClinics);
  };

  const handleDelete = (clinicToDelete) => {
    const newClinics = clinics.filter(
      (clinic) => clinic.uuid !== clinicToDelete.uuid
    );

    onClinicDelete(clinicToDelete.uuid);
    updateFormData(newClinics);
  };

  const handlePracticeClick = (practice) => setSelectedPractice(practice);

  const clinicsToRender =
    selectedPractice &&
    clinics.filter(
      ({ practiceUuid }) => practiceUuid === selectedPractice.uuid
    );

  const renderDeleteConfirmation = () => {
    if (!deleteClinicModal.isOpen) return;
    const handleClosePressed = () => resetDeleteClinicModal(null);
    const handleActionButtonPress = (index) => {
      if (index !== 1) {
        return handleClosePressed();
      }
      handleDelete(deleteClinicModal.clinic);
      handleClosePressed();
    };
    return (
      <ActionPopup
        title={'Delete Clinic?'}
        subtitle="Deleting the clinic will remove its association with related rendering providers."
        actionButtons={[
          {
            title: 'No',
          },
          {
            title: 'Yes',
            type: TYPE_ACCEPT,
          },
        ]}
        icon={warningIcon}
        onClosePressed={handleClosePressed}
        onActionButtonPressed={handleActionButtonPress}
      />
    );
  };

  return (
    <div className="card-wrapper">
      <Card className="min-height-40 mb-58">
        <div className="row form-info-row">
          <div className="col-md-9">
            <div className="form-section-title">
              Add Clinic(s) to your Practices
            </div>
            <div className="form-section-description">
              <div>
                Please add Clinic(s) associated with each of your Practice(s).
                If you have any questions, please feel free to contact us at{' '}
                {APPEALIO_SUPPORT_EMAIL}
              </div>
              <div>
                <span>
                  <img src={infoIcon} alt="info" className="mr-4" />A clinic is
                  a facility that provides healthcare to patients.
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-offset-1 col-md-2">
            {selectedPractice && clinicsToRender.length > 0 && (
              <button
                onClick={() => {
                  setClinicToUpdate(null);
                  setIsFormVisible(true);
                }}
                className="ap-button ap-button--secondary add-btn--main"
              >
                <span className="mr-4">+</span> Add Clinic
              </button>
            )}
          </div>
        </div>

        <div className="row mt-12">
          <div className="col-lg-3 border-right-grey">
            {practices.map((practice, idx) => {
              return (
                <div
                  key={idx}
                  onClick={() => handlePracticeClick(practice)}
                  className={classnames('create-account-list-item mb-8', {
                    'create-account-list-item--selected':
                      selectedPractice &&
                      selectedPractice.uuid === practice.uuid,
                  })}
                >
                  {practice.name}
                </div>
              );
            })}
          </div>
          <div className="col-lg-9">
            {selectedPractice && clinicsToRender.length > 0 && (
              <div className="row mb-16 create-acount-list-item-header">
                <div className="col-lg-2">Clinic Name</div>
                <div className="col-lg-2">Address 1</div>
                <div className="col-lg-2">Address 2</div>
                <div className="col-lg-2">City</div>
                <div className="col-lg-1">State</div>
                <div className="col-lg-1">Zip</div>
                <div className="col-lg-2">&nbsp;</div>
              </div>
            )}
            {selectedPractice &&
              clinicsToRender.length > 0 &&
              clinicsToRender.map((clinic, idx) => {
                return (
                  <div key={idx} className="create-account-list-item row mb-16">
                    <div className="col-lg-2">
                      <span data-tip={clinic.name}>{clinic.name}</span>
                    </div>
                    <div className="col-lg-2">
                      <span data-tip={clinic.address1}>{clinic.address1}</span>
                    </div>
                    <div className="col-lg-2">
                      <span data-tip={clinic.address2}>{clinic.address2}</span>
                    </div>
                    <div className="col-lg-2">
                      <span data-tip={clinic.city}>{clinic.city}</span>
                    </div>
                    <div className="col-lg-1">
                      <span data-tip={clinic.state}>{clinic.state}</span>
                    </div>
                    <div className="col-lg-1">
                      <span data-tip={clinic.zipcode}>{clinic.zipcode}</span>
                    </div>
                    <div className="col-lg-2">
                      <BsPencil
                        title="Edit"
                        size={16}
                        className="mr-16"
                        onClick={(e) => {
                          setClinicToUpdate(clinic);
                          setIsFormVisible(true);
                        }}
                      />
                      <IoMdCloseCircleOutline
                        title="Delete"
                        size={16}
                        onClick={() => openDeleteClinicModal(clinic)}
                      />
                    </div>
                    <ReactTooltip effect="solid" place="top" multiline={true} />
                  </div>
                );
              })}

            {selectedPractice &&
              !isFormVisible &&
              clinicsToRender.length < 1 && (
                <button
                  onClick={() => {
                    setClinicToUpdate(null);
                    setIsFormVisible(true);
                  }}
                  className="add-item-btn"
                >
                  <span className="mr-4">+</span> Add Clinic
                </button>
              )}

            {isFormVisible && (
              <AddClinicForm
                onSubmit={handleSubmit}
                initialValues={{ address2: '', ...clinicToUpdate }}
                onCancel={resetFormVisibility}
                practice={selectedPractice}
              />
            )}
          </div>
        </div>
      </Card>
      {renderDeleteConfirmation()}
    </div>
  );
};

export default AddClinicContainer;
