import { useEffect, useState } from 'react';

import { fetchClaimStatusHistoryById } from 'API/PayerAPI';

export const useFetchClaimStatusHistoryById = (id) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      setData(null);
      try {
        const { data } = await fetchClaimStatusHistoryById(id);
        setData(data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    fetch();
  }, [id]);

  return { loading, error, data, fetch, setData };
};
