import {
  createRequest,
  handleErrors,
  handleJSONParse,
  createGetRequest,
  INTEGRATION_API_BASE_URL,
} from './Config';

import { RULE_STATUSES } from 'constants/appConstants';
import { APPLIED_RULE_COUNT, RULES, SOP } from 'constants/endpoints';

import { camelizeKeys, snakeCaseKeys } from 'helpers/object';

export const fetchImportRulesOptions = () => {
  const request = createGetRequest(
    `${RULES}/`,
    {
      few: 1,
      status: [RULE_STATUSES.ACTIVE],
      all: 1,
    },
    INTEGRATION_API_BASE_URL,
    true
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => res.map(camelizeKeys));
};

export const fetchImportRules = (queryParams = {}) => {
  const params = {
    ...queryParams,
    status: [RULE_STATUSES.ACTIVE, RULE_STATUSES.PAUSED],
  };

  const request = createGetRequest(
    `${RULES}`,
    params,
    INTEGRATION_API_BASE_URL,
    true
  );
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) =>
      queryParams?.all ? res.map(camelizeKeys) : res.data.map(camelizeKeys)
    );
};

export const fetchImportRulesCount = () => {
  const request = createGetRequest(
    `${RULES}/count`,
    {
      status: [RULE_STATUSES.ACTIVE],
    },
    INTEGRATION_API_BASE_URL,
    true
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const createImportRule = (data) => {
  const request = createRequest('/rules/', snakeCaseKeys(data), {
    baseUrl: INTEGRATION_API_BASE_URL,
    isBearer: true,
  });
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const updateImportRule = (id, data) => {
  const request = createRequest(`/rules/${id}/`, snakeCaseKeys(data), {
    baseUrl: INTEGRATION_API_BASE_URL,
    isBearer: true,
    method: 'PUT',
  });

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const deleteImportRule = (id) => {
  const request = createRequest(
    `${RULES}/${id}/`,
    {},
    {
      baseUrl: INTEGRATION_API_BASE_URL,
      isBearer: true,
      method: 'DELETE',
    }
  );
  return fetch(request);
};

export const fetchRuleActions = () => {
  const request = createGetRequest(
    `${RULES}/actions`,
    {},
    INTEGRATION_API_BASE_URL,
    true
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => res.data.map(camelizeKeys));
};

export const fetchAppliedRuleCount = (action = null, completed = null) => {
  const request = createGetRequest(
    `${APPLIED_RULE_COUNT}`,
    {
      ...(action && { action }),
      ...(completed && { completed }),
    },
    INTEGRATION_API_BASE_URL,
    true
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => res.data.map(camelizeKeys));
};

export const fetchSop = async (id) => {
  const request = createGetRequest(
    SOP + id,
    {},
    INTEGRATION_API_BASE_URL,
    true
  );
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => {
      return camelizeKeys(res).data;
    });
};
