import { Editor } from '@tinymce/tinymce-react';
import React, { useState, useEffect, useCallback, useRef } from 'react';

import { EDITOR_CONFIG } from 'components/CreateAppeal/AppealLetter/RichTextEditor';
import { fillTemplate } from 'components/CreateAppeal/AppealLetter/TemplateFiller';

const FormatLetter = ({ appealLetterTemplates, onProcessedContentChange }) => {
  const [processedTemplates, setProcessedTemplates] = useState([]);
  const editorRefs = useRef([]);

  const processTemplates = useCallback(async () => {
    try {
      const processed = await Promise.all(
        appealLetterTemplates.map(async (template) => ({
          ...template,
          content: await template.content,
        }))
      );
      setProcessedTemplates(processed);
    } catch (error) {
      if (onProcessedContentChange) {
        onProcessedContentChange(null, error);
      }
    }
  }, [appealLetterTemplates, onProcessedContentChange]);

  useEffect(() => {
    processTemplates();
  }, [processTemplates]);

  useEffect(() => {
    if (
      processedTemplates.length > 0 &&
      editorRefs.current.length === processedTemplates.length
    ) {
      const finalProcessedTemplates = processedTemplates.map(
        (template, index) => ({
          ...template,
          content: fillTemplate(editorRefs.current[index].getContent()),
        })
      );

      if (onProcessedContentChange) {
        onProcessedContentChange(finalProcessedTemplates);
      }
    }
  }, [processedTemplates, onProcessedContentChange]);

  const handleEditorInit = (e, index) => {
    editorRefs.current[index] = e.target;
    editorRefs.current[index].getDoc().body.style.fontFamily = 'Arial';
    setTimeout(() => {
      if (processedTemplates[index].content) {
        editorRefs.current[index].setContent(processedTemplates[index].content);
      }
    }, 200);
  };

  return (
    <div style={{ display: 'none' }}>
      {processedTemplates.map((template, index) => (
        <Editor
          key={template.id}
          initialValue={template.content}
          init={EDITOR_CONFIG}
          onInit={(e) => handleEditorInit(e, index)}
        />
      ))}
    </div>
  );
};

export default FormatLetter;
