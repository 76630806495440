/**
 * Created by alex on 10/28/16.
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import _ from 'lodash';

export default class TabBar extends Component {
  static propTypes = {
    defaultActiveTabKey: PropTypes.string.isRequired,
  };

  static defaultProps = {
    defaultActiveTabIndex: '0',
  };

  render() {
    return (
      <div className="tabbar__height" datacy="tab-bar">
        <Tabs
          defaultActiveKey={this.props.defaultActiveTabKey}
          onChange={this.callback()}
          renderTabBar={() => <ScrollableInkTabBar />}
          renderTabContent={() => <TabContent />}
          datacy="tab-bar-Tabs"
        >
          {this.renderTabs()}
        </Tabs>
      </div>
    );
  }

  renderTabs = () => {
    if (this.props.children) {
      const tabs = _.compact(this.props.children);
      return tabs.map((item, index) => {
        return (
          <TabPane
            tab={item.props.title}
            key={index}
            className="tabbar"
            datacy={`tab-bar-TabPane-${index}`}
          >
            <div className="tabbar__content">{item}</div>
          </TabPane>
        );
      });
    }
  };

  callback = function (key) {};
}
