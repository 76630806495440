import { Virtuoso } from 'react-virtuoso';
import { snakeCase, startCase } from 'lodash';
import React, { useState, useCallback } from 'react';

import Input from 'components/common/input';
import Button from 'components/common/button';
import * as toast from 'components/Shared/toast';
import Select from 'components/common/select/Select';
import AppealioPopup from 'components/Shared/AppealioPopup';

import './style.css';

import { removeEmptyOrInactiveOptions } from 'helpers/utils';
import { validateDateString, validatePayerClaimId } from 'helpers/validators';

import { MASK_DATE } from 'helpers/masks';
import {
  PAYER_CLAIM_ID_FIELD_INCORRECT_FORMAT,
  SUBMITTER_CLAIM_ID_FIELD_INCORRECT_FORMAT,
} from 'components/Shared/Errors/errorMessages';

const MAX_LENGTH_MAP = {
  claimControlNumber: 30,
  claimNumber: 30,
  patientFirstName: 255,
  patientLastName: 255,
};

const IndividualRowEditor = (props) => {
  const {
    errorTypeToFix,
    data,
    rowKey,
    onIndividualRowUpdate,
    onCloseClick,
    dropdownOptions,
  } = props;

  const [editedValues, setEditedValues] = useState({});

  /**
   * Returns an error message based on the validity of the provided service date.
   *
   * @param {string} value - The service date to validate.
   * @returns {string|undefined} - Returns an error message if the service date is invalid, otherwise undefined.
   */
  const getServiceDateErrorMessage = (value) => {
    if (validateDateString(value, true)) {
      return 'Date must be in the past and in MM/DD/YYYYY format';
    }
    return undefined;
  };
  const handleInputChange = useCallback(
    (rowIndex, value) => {
      if (rowKey === 'payer' || rowKey === 'practice') {
        setEditedValues((prevState) => ({
          ...prevState,
          [rowIndex]: value ? value.label : '',
        }));
        return;
      }

      const maxLength = MAX_LENGTH_MAP[rowKey];

      if (!value) {
        setEditedValues((prevState) => {
          const newState = { ...prevState };
          delete newState[rowIndex];
          return newState;
        });
        return;
      }

      if (maxLength && value.length > maxLength) {
        value = value.slice(0, maxLength);
      }

      if (rowKey === 'claimControlNumber' || rowKey === 'claimNumber') {
        const validationError = validatePayerClaimId(
          value,
          rowKey === 'claimControlNumber'
            ? PAYER_CLAIM_ID_FIELD_INCORRECT_FORMAT
            : SUBMITTER_CLAIM_ID_FIELD_INCORRECT_FORMAT
        );
        if (validationError) {
          toast.error({
            title: '',
            message: validationError,
          });
          return;
        }
      }

      if (rowKey === 'serviceDate') {
        if (value?.replace('_', '').length === MASK_DATE.length) {
          const errorMessage = getServiceDateErrorMessage(value);
          if (errorMessage) {
            toast.error({
              title: '',
              message: errorMessage,
            });
            setEditedValues((prevState) => ({
              ...prevState,
              [rowIndex]: '',
            }));
            return;
          }
        }
      }

      setEditedValues((prevState) => ({
        ...prevState,
        [rowIndex]: value,
      }));
    },
    [rowKey]
  );

  const filterErrorData = useCallback(
    (row) => {
      return row.errors.some(
        (error) =>
          error.rowKey === snakeCase(rowKey) && error.error === errorTypeToFix
      );
    },
    [rowKey, errorTypeToFix]
  );

  const errorData = data.filter(filterErrorData);

  const getUpdatedRows = useCallback(() => {
    return data.map((row) => {
      if (filterErrorData(row)) {
        if (!editedValues[row.rowNumber]) {
          return row;
        }

        if (rowKey === 'serviceDate') {
          const errorMessage = getServiceDateErrorMessage(
            editedValues[row.rowNumber]
          );
          if (errorMessage) {
            return row;
          }
        }

        const updatedErrors = row.errors.filter(
          (error) =>
            !(
              error.rowKey === snakeCase(rowKey) &&
              error.error === errorTypeToFix
            )
        );

        return {
          ...row,
          [rowKey]: editedValues[row.rowNumber] || row[rowKey],
          errors: updatedErrors,
        };
      }
      return row;
    });
  }, [data, filterErrorData, rowKey, errorTypeToFix, editedValues]);

  const handleSaveClick = useCallback(() => {
    if (rowKey === 'serviceDate') {
      // Check if any of the edited service dates are invalid
      const invalidDates = Object.values(editedValues).filter((value) =>
        getServiceDateErrorMessage(value)
      );

      if (invalidDates.length > 0) {
        toast.error({
          title: `${invalidDates.length} service date(s) are invalid`,
          message: getServiceDateErrorMessage(invalidDates[0]),
        });
        return;
      }
    }
    const updatedData = getUpdatedRows();
    onIndividualRowUpdate(updatedData);
  }, [editedValues, getUpdatedRows, onIndividualRowUpdate, rowKey]);

  const getKeyName = (key) => {
    if (key === 'claimControlNumber') {
      return 'Payer Claim ID';
    }
    if (key === 'claimNumber') {
      return 'Claim ID';
    }

    return startCase(key);
  };

  const getDropdownOptions = useCallback(
    (rowKey) => {
      if (rowKey === 'practice') {
        return removeEmptyOrInactiveOptions(dropdownOptions?.practices?.data);
      } else if (rowKey === 'payer') {
        return removeEmptyOrInactiveOptions(
          dropdownOptions?.payers?.data,
          false
        );
      }
      return [];
    },
    [dropdownOptions]
  );

  return (
    <AppealioPopup
      className="appealio-popup appealio-action-popup csv-claims-import-individual-row-editor-popup"
      titleClassName="appealio-action-popup__header csv-claims-import-confirm-popup__title"
      title={`Edit ${getKeyName(rowKey)} Errors`}
      onClosePressed={onCloseClick}
    >
      <div>
        <div className="csv-claims-import-individual-row-editor-popup__info">
          {errorData.length} {getKeyName(rowKey)} Errors
        </div>
        <Virtuoso
          className="csv-claims-import-individual-row-editor-popup__content"
          data={errorData}
          itemContent={(index, row) => {
            let inputField;

            if (rowKey === 'payer' || rowKey === 'practice') {
              const options = getDropdownOptions(rowKey);
              const selectedOption = options.filter(
                (option) => option.label === editedValues[row.rowNumber]
              );

              inputField = (
                <Select
                  label="Update Value"
                  value={selectedOption}
                  onChange={(selectedOption) =>
                    handleInputChange(row.rowNumber, selectedOption)
                  }
                  placeholder="Select a value"
                  options={options}
                />
              );
            } else {
              let inputProps = {
                label: 'Update Value',
                value: editedValues[row.rowNumber] || '',
                onChange: (e) =>
                  handleInputChange(row.rowNumber, e.target.value),
                placeholder: 'Enter new value',
                maxLength: MAX_LENGTH_MAP[rowKey],
              };

              if (rowKey === 'serviceDate') {
                inputProps = {
                  ...inputProps,
                  type: 'text',
                  maskedInput: true,
                  mask: MASK_DATE,
                  placeholder: 'MM/DD/YYYY',
                  input: {
                    value: editedValues[row.rowNumber] || '',
                    onChange: (e) =>
                      handleInputChange(row.rowNumber, e.target.value),
                  },
                };
              }

              inputField = <Input {...inputProps} />;
            }

            return (
              <div key={index} className="row individual-row-editor-row">
                <div className="col-lg-1 individual-row-editor-row__sn">
                  {index + 1}.
                </div>
                <div className="col-lg-1 individual-row-editor-row__row">
                  <Input
                    disabled={true}
                    readOnly={true}
                    label="Row"
                    value={String(row.rowNumber)}
                  />
                </div>
                <div className="col-lg-5">
                  <Input
                    label={getKeyName(rowKey)}
                    value={row[rowKey]}
                    disabled={true}
                  />
                </div>
                <div className="col-lg-5">{inputField}</div>
              </div>
            );
          }}
        />
      </div>

      <div className="d-flex justify-content-flex-end align-item-center">
        <Button
          onClick={handleSaveClick}
          disabled={Object.keys(editedValues).length === 0}
          className="ap-button--secondary mb-0"
        >
          Save Changes
        </Button>
      </div>
    </AppealioPopup>
  );
};

export default IndividualRowEditor;
