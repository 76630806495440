import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class DashboardNavBar extends Component {
  render() {
    const { navItems } = this.props;

    return (
      <div className="dashboard-navbar">
        {navItems.map((navItem, index) => (
          <NavLink
            key={index}
            activeClassName="dashboard-navbar__item--active"
            to={navItem.to}
            className="dashboard-navbar__item"
            datacy="dashboard-navbar-NavLink"
          >
            <img alt="overview icon" src={navItem.icon} />
            <div>{navItem.title}</div>
          </NavLink>
        ))}
      </div>
    );
  }
}
