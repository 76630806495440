import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class SubmitButton extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    title: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    wrapInDiv: PropTypes.bool,
    type: PropTypes.string,
    imgSrc: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    title: 'Log in to Account',
    onClick: () => {},
    className: 'submit__button',
    wrapInDiv: true,
    type: 'submit',
  };

  render() {
    const button = (
      <button
        onClick={this.props.onClick}
        className={this.props.className}
        type={this.props.type}
        datacy={this.props.datacy}
        disabled={this.props.disabled}
      >
        {this.renderButtonIcon()}
        {this.props.title}
      </button>
    );
    if (this.props.wrapInDiv) {
      return <div>{button}</div>;
    } else {
      return button;
    }
  }

  renderButtonIcon() {
    if (!this.props.imgSrc) return;

    return (
      <img
        alt="Submit Button"
        className="submit__button--icon submit__button--icon--sm"
        src={this.props.imgSrc}
      />
    );
  }
}
