import { DATA_TYPE } from './hook';

import {
  SUBMISSIONS,
  DENIED_QUEUE,
  DENIED_QUEUE_LOGS,
  DENIED_QUEUE_ARCHIVED,
  SUBMISSIONS_IN_PROGRESS,
  DENIED_QUEUE_LOGS_ARCHIVED,
  DENIED_QUEUE_COMPLETED_LOGS,
  DENIED_QUEUE_COMPLETED_LOGS_ARCHIVED,
} from 'constants/routes';

export const extractUrlAndQueryParam = (type, searchValue) => {
  let url;
  let queryParam;

  if (type === DATA_TYPE.APPEAL) {
    url = SUBMISSIONS_IN_PROGRESS;
    queryParam = {
      set_filters: true,
      search: searchValue,
    };
  }
  if (type === DATA_TYPE.DENIAL) {
    url = DENIED_QUEUE;
    queryParam = {
      search: searchValue,
    };
  }
  if (type === DATA_TYPE.ARCHIVE_DENIALS) {
    url = DENIED_QUEUE_ARCHIVED;
    queryParam = {
      search: searchValue,
    };
  }
  if (type === DATA_TYPE.ARCHIVE_ACTION_LOG_TODO) {
    url = DENIED_QUEUE_LOGS_ARCHIVED;
    queryParam = {
      search: searchValue,
    };
  }
  if (type === DATA_TYPE.ARCHIVE_ACTION_LOG_COMPLETED) {
    url = DENIED_QUEUE_COMPLETED_LOGS_ARCHIVED;
    queryParam = {
      search: searchValue,
    };
  }
  if (type === DATA_TYPE.DELIVERY_TRACKER) {
    url = SUBMISSIONS;
    queryParam = {
      search: searchValue,
    };
  }
  if ([DATA_TYPE.ACTION_LOG_TODO].includes(type)) {
    url = DENIED_QUEUE_LOGS;
    queryParam = {
      search: searchValue,
      completed: type === DATA_TYPE.ACTION_LOG_COMPLETED ? 1 : 0,
    };
  }
  if ([DATA_TYPE.ACTION_LOG_COMPLETED].includes(type)) {
    url = DENIED_QUEUE_COMPLETED_LOGS;
    queryParam = {
      search: searchValue,
      completed: type === DATA_TYPE.ACTION_LOG_COMPLETED ? 1 : 0,
    };
  }
  if (type === DATA_TYPE.CLAIM) {
    url = DENIED_QUEUE;
    queryParam = {
      search: searchValue,
      flyover: true,
    };
  }

  return { url, queryParam };
};
