import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { change, focus } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import plusIcon from '../../img/plus.svg';
import _ from 'lodash';

export function appendSeparator(currentValue, separator) {
  if (!currentValue) {
    return currentValue;
  }

  if (_.endsWith(currentValue, separator)) {
    return currentValue;
  }

  return `${currentValue}${separator}`;
}

class MultiValueInput extends Component {
  static propTypes = {
    separator: PropTypes.string,
    isMultiValue: PropTypes.bool,
    formName: PropTypes.string,
  };

  static defaultProps = {
    separator: ',',
    isMultiValue: true,
    formName: 'denial_info',
  };

  render() {
    const { input, label } = this.props;

    return (
      <div datacy="multi-value-input">
        {label && (
          <label className="textfield__label" htmlFor={input.name}>
            {label}
          </label>
        )}
        {this.renderMultiValueInput(this.props)}
        {this.renderInput(this.props)}
      </div>
    );
  }

  renderMultiValueInput({
    isMultiValue,
    input,
    disabled,
    key,
    label,
    maxLength,
    placeholder,
    meta: { touched, error },
  }) {
    if (this.props.isMultiValue === true) {
      return (
        <div className="multivalueinput__container">
          <input
            {...input}
            key={key}
            placeholder={placeholder}
            maxLength={maxLength}
            disabled={disabled}
            ref="multiValueInput"
            className={classNames(
              'textfield__input',
              {
                'textfield__input--error': error && touched,
              },
              {
                'textfield__input--disabled': disabled,
              },
              'multivalueinput__input'
            )}
          />
          {!disabled && this.renderPlusIcon()}
        </div>
      );
    }
  }

  renderPlusIcon() {
    const hasEnteredAnything = !_.isEmpty(this.props.input.value);
    if (hasEnteredAnything) {
      return (
        <img
          alt="Plus Icon"
          src={plusIcon}
          className="multivalueinput__img"
          onClick={this.onPlusClicked}
        />
      );
    }
  }

  renderInput({
    isMultiValue,
    input,
    disabled,
    key,
    label,
    maxLength,
    placeholder,
    meta: { touched, error },
  }) {
    if (this.props.isMultiValue === false) {
      return (
        <div>
          <span>
            <input
              {...input}
              key={key}
              placeholder={placeholder}
              maxLength={maxLength}
              disabled={disabled}
              className={classNames(
                'textfield__input',
                {
                  'textfield__input--error': error && touched,
                },
                {
                  'textfield__input--disabled': disabled,
                }
              )}
            />
          </span>
        </div>
      );
    }
  }

  onPlusClicked = () => {
    const currentValue = this.props.input.value;
    const inputName = this.props.input.name;
    const formName = this.props.formName;

    this.props.actions.change(
      formName,
      inputName,
      appendSeparator(currentValue, this.props.separator)
    );
    this.props.actions.focus(formName, inputName);
    this.refs.multiValueInput.focus();
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ change, focus }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(MultiValueInput);
