import classnames from 'classnames';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { BsPencil } from 'react-icons/bs';
import { IoMdCloseCircleOutline } from 'react-icons/io';

import { generateUUID } from 'helpers/utils';

import Card from 'components/common/card';
import AddRenderingProviderForm from './AddRenderingProviderForm';
import { APPEALIO_SUPPORT_EMAIL } from 'constants/appConstants';
import ActionPopup, { TYPE_ACCEPT } from 'components/Shared/ActionPopup';

import infoIcon from 'img/info.svg';
import warningIcon from 'img/incomplete.svg';

const AddRenderingProviderContainer = (props) => {
  const {
    practices,
    clinics,
    billingProviders,
    renderingProviders,
    updateFormData,
  } = props;

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [renderingProviderToUpdate, setRenderingProviderToUpdate] =
    useState(null);
  const [selectedPractice, setSelectedPractice] = useState(practices[0]);
  const [deleteProviderModal, setDeleteProviderModal] = useState({
    isOpen: false,
    provider: null,
  });
  const resetDeleteProviderModal = () =>
    setDeleteProviderModal({
      isOpen: false,
      provider: null,
    });
  const openDeleteProviderModal = (provider) =>
    setDeleteProviderModal({
      isOpen: true,
      provider,
    });

  const resetFormVisibility = () => {
    setIsFormVisible(false);
    setRenderingProviderToUpdate(null);
  };

  const handleSubmit = (values) => {
    const valuesWithPractice = {
      ...values,
      practiceUuid: selectedPractice.uuid,
    };
    if (!renderingProviderToUpdate) {
      resetFormVisibility();

      return updateFormData([
        ...renderingProviders,
        { uuid: generateUUID(), ...valuesWithPractice },
      ]);
    }

    const updatedRenderingProviders = renderingProviders.map(
      (renderingProvider) =>
        renderingProvider.uuid === renderingProviderToUpdate.uuid
          ? { uuid: renderingProvider.uuid, ...valuesWithPractice }
          : renderingProvider
    );

    resetFormVisibility();
    updateFormData(updatedRenderingProviders);
  };

  const handleDelete = (renderingProviderToDelete) => {
    const newRenderingProviders = renderingProviders.filter(
      (renderingProvider) =>
        renderingProvider.uuid !== renderingProviderToDelete.uuid
    );

    updateFormData(newRenderingProviders);
  };

  const renderingProviderToRender =
    selectedPractice &&
    renderingProviders.filter(
      ({ practiceUuid }) => practiceUuid === selectedPractice.uuid
    );

  const handlePracticeClick = (practice) => setSelectedPractice(practice);

  const renderDeleteConfirmation = () => {
    if (!deleteProviderModal.isOpen) return;
    const handleClosePressed = () => resetDeleteProviderModal(null);
    const handleActionButtonPress = (index) => {
      if (index !== 1) {
        return handleClosePressed();
      }

      handleDelete(deleteProviderModal.provider);
      handleClosePressed();
    };
    return (
      <ActionPopup
        title={'Delete Rendering Provider?'}
        actionButtons={[
          {
            title: 'No',
          },
          {
            title: 'Yes',
            type: TYPE_ACCEPT,
          },
        ]}
        icon={warningIcon}
        onClosePressed={handleClosePressed}
        onActionButtonPressed={handleActionButtonPress}
      />
    );
  };

  const clinicDropdownOptions = clinics.map(({ uuid, name }) => ({
    label: name,
    value: uuid,
  }));
  const billingProviderDropdownOptions = billingProviders.map(
    ({ id, uuid, firstName, lastName, organizationName }) => {
      const name = organizationName || `${firstName} ${lastName}`;

      return { label: name, value: uuid };
    }
  );

  const initialClinicIds =
    renderingProviderToUpdate && renderingProviderToUpdate.clinicsUuids
      ? renderingProviderToUpdate.clinicsUuids
      : [];
  const selectedClinics = clinicDropdownOptions.filter((option) =>
    initialClinicIds.includes(option.value)
  );

  const initialBillingProviderIds =
    renderingProviderToUpdate && renderingProviderToUpdate.billingProvidersUuids
      ? renderingProviderToUpdate.billingProvidersUuids
      : [];
  const selectedBillingProviders = billingProviderDropdownOptions.filter(
    (option) => initialBillingProviderIds.includes(option.value)
  );

  return (
    <div className="card-wrapper">
      <Card className="min-height-40 mb-58">
        <div className="row form-info-row">
          <div className="col-md-9">
            <div className="form-section-title">Add Rendering Provider(s)</div>
            <div className="form-section-description">
              <div>
                Add Rendering Providers to your practices. If you have any
                questions, please feel free to contact us at{' '}
                {APPEALIO_SUPPORT_EMAIL}
              </div>

              <div className="mt-2">
                <span>
                  <img src={infoIcon} alt="info" className="mr-4" />A rendering
                  provider is an individual or organization that provides
                  Healthcare directly to Patients.
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            {selectedPractice && renderingProviderToRender.length > 0 && (
              <button
                onClick={() => {
                  setRenderingProviderToUpdate(null);
                  setIsFormVisible(true);
                }}
                className="ap-button ap-button--secondary add-btn--main"
              >
                <span className="mr-4">+</span> Add Rendering Provider
              </button>
            )}
          </div>
        </div>
        <div className="row mt-12">
          <div className="col-lg-3 border-right-grey">
            {practices.map((practice, idx) => {
              return (
                <div
                  key={idx}
                  onClick={() => handlePracticeClick(practice)}
                  className={classnames('create-account-list-item mb-8', {
                    'create-account-list-item--selected':
                      selectedPractice &&
                      selectedPractice.uuid === practice.uuid,
                  })}
                >
                  {practice.name}
                </div>
              );
            })}
          </div>
          <div className="col-lg-9">
            {selectedPractice && renderingProviderToRender.length > 0 && (
              <div className="row mb-8 create-acount-list-item-header">
                <div className="col-lg-2 p-0">Name</div>
                <div className="col-lg-2">Provider Type</div>
                <div className="col-lg-1">Tax Id</div>
                <div className="col-lg-2">Billing Providers</div>
                <div className="col-lg-2">Clinics</div>
              </div>
            )}
            {selectedPractice &&
              renderingProviders
                .filter(
                  ({ practiceUuid }) => practiceUuid === selectedPractice.uuid
                )
                .map((renderingProvider, idx) => {
                  const organizationOrProviderName =
                    renderingProvider.organizationName ||
                    `${renderingProvider.firstName} ${renderingProvider.lastName}`;

                  const providerType = renderingProvider.organizationName
                    ? 'Organization'
                    : 'Individual';

                  const taxId = renderingProvider.taxId;
                  const billingProvidersList = billingProviders
                    .filter(({ uuid }) =>
                      (renderingProvider.billingProvidersUuids || []).includes(
                        uuid
                      )
                    )
                    .map(
                      ({ organizationName, firstName, lastName }) =>
                        `${organizationName}` || `${firstName} ${lastName}`
                    )
                    .join(', ');

                  const clinicsList = clinics
                    .filter(({ uuid }) =>
                      renderingProvider.clinicsUuids.includes(uuid)
                    )
                    .map(({ name }) => name)
                    .join(', ');

                  return (
                    <div
                      key={idx}
                      className="create-account-list-item row mb-16"
                    >
                      <div className="col-lg-2">
                        <span data-tip={organizationOrProviderName}>
                          {organizationOrProviderName}
                        </span>
                      </div>
                      <div className="col-lg-2">
                        <span data-tip={providerType}>{providerType}</span>
                      </div>
                      <div className="col-lg-1">
                        <span data-tip={taxId}>{taxId}</span>
                      </div>
                      <div className="col-lg-2">
                        <span data-tip={billingProvidersList}>
                          {billingProvidersList}
                        </span>
                      </div>
                      <div className="col-lg-2">
                        <span data-tip={clinicsList}>{clinicsList}</span>
                      </div>

                      <div className="col-lg-2">
                        <BsPencil
                          title="Edit"
                          className="mr-16"
                          size={16}
                          onClick={(e) => {
                            setRenderingProviderToUpdate(renderingProvider);
                            setIsFormVisible(true);
                          }}
                        />
                        <IoMdCloseCircleOutline
                          title="Delete"
                          size={16}
                          onClick={() =>
                            openDeleteProviderModal(renderingProvider)
                          }
                        />
                      </div>
                      <ReactTooltip
                        effect="solid"
                        place="top"
                        multiline={true}
                      />
                    </div>
                  );
                })}

            {selectedPractice &&
              !isFormVisible &&
              renderingProviderToRender.length < 1 && (
                <button
                  onClick={() => {
                    setRenderingProviderToUpdate(null);
                    setIsFormVisible(true);
                  }}
                  className="add-item-btn"
                >
                  <span className="mr-4">+</span> Add Rendering Provider
                </button>
              )}

            {isFormVisible && (
              <AddRenderingProviderForm
                initialValues={{
                  ...renderingProviderToUpdate,
                  clinicsUuids: selectedClinics,
                  billingProvidersUuids: selectedBillingProviders,
                }}
                clinics={clinics.filter(
                  (clinic) => clinic.practiceUuid === selectedPractice.uuid
                )}
                renderingProviders={renderingProviders}
                billingProviders={billingProviders.filter(
                  (billingProvider) =>
                    billingProvider.practiceUuid === selectedPractice.uuid
                )}
                clinicDropdownOptions={clinicDropdownOptions}
                billingProviderDropdownOptions={billingProviderDropdownOptions}
                onSubmit={handleSubmit}
                onCancel={resetFormVisibility}
              />
            )}
          </div>
        </div>
      </Card>
      {renderDeleteConfirmation()}
    </div>
  );
};

export default AddRenderingProviderContainer;
