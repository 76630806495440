import querystring from 'query-string';
import urlJoin from 'url-join';

import {
  LOGIN_AUTH,
  LOGIN_SET_AUTOLOGIN_INFO,
} from '../redux/actions/actionTypes';

import FileSaver, { saveAs } from 'file-saver';

const PLATFORM_API_URL =
  process.env.REACT_APP_PCE_API_BASE_URL || 'http://localhost:8001/api/';
const INTEGRATION_API_URL =
  process.env.REACT_APP_INTEGRATION_API_BASE_URL ||
  'http://localhost:8081/api/';
export const BASE_API_URL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000/api/';
export const INTEGRATION_API_BASE_URL = INTEGRATION_API_URL;
export const PCE_API_BASE_URL = PLATFORM_API_URL;

export const RPA_API_BASE_URL =
  process.env.REACT_APP_RPA_API_BASE_URL || 'http://localhost:3000';

export const RPA_API_BROWSER_WS_URL =
  process.env.REACT_APP_RPA_BROWSER_WS_URL || 'ws://localhost:3001/devtools';

export const IS_DASHBOARD_ENABLED = Boolean(
  process.env.REACT_APP_IS_DASHBOARD_ENABLED
);

export const ANALYTICS_API_BASE_URL =
  process.env.REACT_APP_ANALYTICS_API_BASE_URL;

export const CURRENT_DEPLOYMENT_INSTANCE =
  process.env.REACT_APP_DEPLOYMENT_INSTANCE || '';

export const APIConfig = {
  processHeaders: (headers) => headers,
  processParams: (params) => params,
  authToken: null,
};

export const PDFJS_EXPRESS_LICENSE_KEY =
  process.env.REACT_APP_PDFJS_EXPRESS_LICENSE_KEY || '';
export const PDFJS_EXPRESS_UTIL_OPTIONS = {
  clientKey: process.env.REACT_APP_PDFJS_EXPRESS_UTIL_CLIENT_KEY,
  serverKey: process.env.REACT_APP_PDFJS_EXPRESS_UTIL_SERVER_KEY,
};

export function createURL(path, apiBaseUrl) {
  let pathWithSlashAtEnd = path;
  if (!path.endsWith('/')) {
    pathWithSlashAtEnd = path + '/';
  }

  if (apiBaseUrl) {
    return urlJoin(apiBaseUrl, pathWithSlashAtEnd);
  }

  return urlJoin(BASE_API_URL, pathWithSlashAtEnd);
}

export function createURLWithParams(
  path,
  params,
  isAbsolutePath = false,
  baseUrl = '',
  ignoreSlash = false
) {
  let newPath;
  if (!isAbsolutePath) {
    newPath = createURL(path, baseUrl);
  } else {
    if (!path.endsWith('/') && !ignoreSlash) {
      newPath = path + '/';
    } else {
      newPath = path;
    }
  }

  const arrayFormatSeparator = [BASE_API_URL, ''].includes(baseUrl)
    ? 'comma'
    : 'none';

  const query = extractQueryParams(params, arrayFormatSeparator);

  if (query) {
    newPath += '?';
    newPath += query;
  }
  return newPath;
}

export function extractQueryParams(params, arrayFormatSeparator = 'comma') {
  return querystring.stringify(params, { arrayFormat: arrayFormatSeparator });
}

export function handleErrors(response) {
  if (!response.ok) {
    if ([419, 429].includes(response.status)) {
      return response.json().then((res) => {
        const lockError = new Error(res.message);
        lockError.response = response;

        throw lockError;
      });
    }

    const error = new Error(response.statusText);
    error.response = response;

    if ([401, 403].indexOf(response.status) !== -1) {
      // TODO: use proper approach to handle unauthorize response
      // browserHistory.replace({ pathname: '/login', query: { expired: true } });
      // window.location.reload(true);
      window.location.href = '/login?expired=true';
    }
    throw error;
  } else {
    return response;
  }
}

export function handleErrorsNoRedirect(response) {
  if (!response.ok) {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  } else {
    return response;
  }
}

export function handleJSONParse(response) {
  return response.json();
}

export function createHeaders(
  contentType = 'application/json',
  isBearer = false
) {
  const headers = {
    'Content-Type': contentType,
  };

  const processedHeaders = APIConfig.processHeaders(headers, isBearer);
  return processedHeaders;
}

export function createVndApiHeaders() {
  return createHeaders('application/vnd.api+json');
}

/**
 * @param {String} url The url for the api request (without the base).
 * @param {Object} body An object that will be sent in the request
 * @param {Object} [config]
 * @param {Object} [config.baseUrl] The base url for the api request.
 * @param {Object} [config.headers] Headers to be included on the api request.
 * @returns {Promise}
 */
export function createRequest(
  path,
  body = {},
  {
    baseUrl = '',
    headers = {},
    params = {},
    isBearer = false,
    method = 'POST',
  } = {}
) {
  const requestHeaders = {
    ...createHeaders('application/json', isBearer),
    ...headers,
  };

  const reqBody = method === 'GET' ? {} : { body: JSON.stringify(body) };
  const request = new Request(
    createURLWithParams(path, params, false, baseUrl),
    {
      method,
      headers: requestHeaders,
      ...reqBody,
    }
  );

  return request;
}

export function createFormDataRequest(
  path,
  formData,
  method = 'POST',
  baseUrl = ''
) {
  const request = new Request(createURL(path, baseUrl), {
    method,
    headers: APIConfig.processHeaders({}),
    body: formData,
  });
  return request;
}

export function createVndApiRequest(path, body, method = 'POST') {
  const request = new Request(createURL(path), {
    method,
    headers: createVndApiHeaders(),
    body: JSON.stringify(body),
  });
  return request;
}

export function createGetRequest(
  path,
  params = {},
  baseUrl = '',
  isBearer = false,
  customHeaders = {}
) {
  const request = new Request(
    createURLWithParams(path, params, false, baseUrl),
    {
      method: 'GET',
      headers: {
        ...createHeaders('application/json', isBearer),
        ...customHeaders,
      },
    }
  );

  return request;
}

export function createDeleteRequest(path, params) {
  const request = createRequestWithoutBody(path, { method: 'DELETE', params });
  return request;
}

export function createDeleteRequestForClaims(
  path,
  apiBaseUrl,
  isBearer = true
) {
  const request = new Request(createURL(path, apiBaseUrl), {
    method: 'DELETE',
    headers: { ...createHeaders('application/json', isBearer) },
  });
  return request;
}

export function createJSONFetch(request) {
  return fetch(request).then(handleErrors).then(handleJSONParse);
}

export function appendAuthTokenIfPresent(headers, authToken, isBearer = false) {
  if (!authToken) {
    return headers;
  }

  return {
    ...headers,
    Authorization: `${isBearer ? 'Bearer' : 'Token'} ${authToken}`,
  };
}

export function appendAuthTokenParamIfPresent(params, authToken) {
  if (!authToken) {
    return params;
  }

  return {
    ...params,
    auth_token: authToken,
  };
}

export function apiConfigMiddleware() {
  return ({ getState }) =>
    (next) =>
    (action) => {
      if (action.type === `${LOGIN_AUTH}_FULFILLED`) {
        const { authToken } = action.payload;
        APIConfig.processHeaders = (headers, isBearer = false) =>
          appendAuthTokenIfPresent(headers, authToken, isBearer);
        APIConfig.processParams = (params) =>
          appendAuthTokenParamIfPresent(params, authToken);
        APIConfig.authToken = authToken;
      } else if (action.type === LOGIN_SET_AUTOLOGIN_INFO) {
        APIConfig.processHeaders = (headers, isBearer = false) =>
          appendAuthTokenIfPresent(headers, action.authToken, isBearer);
        APIConfig.processParams = (params) =>
          appendAuthTokenParamIfPresent(params, action.authToken);
        APIConfig.authToken = action.authToken;
      }
      return next(action);
    };
}

export function downloadFile(link, name) {
  FileSaver.saveAs(link, name);
}

export function secureDownload(
  url,
  params,
  filename = null,
  extras = {
    method: 'POST',
    params: {},
  }
) {
  const req = createRequest(url, params, extras);

  return fetch(req)
    .then(handleErrors)
    .then((res) => {
      if (!filename) {
        const filename = res.headers
          .get('content-disposition')
          .split('filename=')[1];
        const extension = res.headers.get('content-type').split('/')[1];
        const letter = `${filename.trim().replace(/['"]+/g, '')}.${extension}`;

        return res.blob().then((blob) => {
          saveAs(blob, letter);
        });
      }

      return res.blob().then((blob) => {
        saveAs(blob, filename);
      });
    });
}

export function extractIntegrationAPIHeaders({ secretKey, practiceId }) {
  return {
    'Access-Control-Allow-Headers': 'practice-id, secret-key',
    'secret-key': secretKey,
    'practice-id': practiceId,
  };
}

export function createRequestWithoutBody(
  path,
  {
    baseUrl = '',
    headers = {},
    params = {},
    isBearer = false,
    method = 'POST',
  } = {}
) {
  const requestHeaders = {
    ...createHeaders('application/json', isBearer),
    ...headers,
  };
  const request = new Request(
    createURLWithParams(path, params, false, baseUrl),
    {
      method,
      headers: requestHeaders,
    }
  );
  return request;
}
