import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import placeholderImg from '../../../img/empty.svg';

const EmptyWidgetPlaceholder = ({
  hideImage = false,
  title = 'No data available',
  className = '',
}) => {
  return (
    <div
      className={classnames('empty-widget-placeholder', {
        [className]: className,
      })}
    >
      {!hideImage && (
        <img
          className="empty-widget-placeholder__img"
          alt="Empty Placeholder"
          src={placeholderImg}
        />
      )}
      <span className="empty-widget-placeholder__title">{title}</span>
    </div>
  );
};

EmptyWidgetPlaceholder.propTypes = {
  title: PropTypes.string,
  hideImage: PropTypes.bool,
};

export default EmptyWidgetPlaceholder;
