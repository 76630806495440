import { parse } from 'qs';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import './style.css';

import DeliveryLogsTable from './DeliveryLogsTable';
import DeliveryTrackerActionBar from './DeliveryTrackerActionBar';
import TransactionSummaryPopup from './AppealioExpress/TransactionSummaryPopup';

import { useFetchDeliveryLogs } from './hooks';

import { TABLE_PAGE_LIMIT } from 'constants/appConstants';

import {
  getIsOpen,
  getIsMinimized,
} from 'redux/reducers/batchUploadWidgetStore';
import { getUserInfo } from 'redux/reducers/loginStore';

import { isAppealioExpressUser } from 'Auth/AuthUtils';

import { useFetchTransactionSummary } from './AppealioExpress/hooks';

const DEFAULT_FILTERS = {
  status: null,
  method: null,
  types: null,
  search: '',
  payer_id: '',
  provider_id: '',
  agent_id: null,
  practice_id: '',
  to_date: '',
  from_date: '',
  record_response_status: '',
  notification_redirect: false,
};

const DeliveryTracking = (props) => {
  const defaultSortBy = {
    id: 'submittedAt',
    desc: true,
  };

  const parseFilter = () => {
    const {
      agent_id: agentId,
      payer_id: payerId,
      provider_id: providerId,
      practice_id: practiceId,
      types,
      search,
      from_date: fromDate,
      to_date: toDate,
      notificationRedirect,
    } = parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    if (
      agentId ||
      payerId ||
      providerId ||
      practiceId ||
      types ||
      fromDate ||
      toDate ||
      notificationRedirect
    ) {
      return {
        ...DEFAULT_FILTERS,
        agent_id: agentId,
        payer_id: payerId || '',
        provider_id: providerId || '',
        practice_id: practiceId || '',
        types,
        from_date: fromDate,
        to_date: toDate,
        notification_redirect: notificationRedirect === 'true',
      };
    }
    if (search) {
      return { ...DEFAULT_FILTERS, search };
    }

    return DEFAULT_FILTERS;
  };

  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(defaultSortBy);
  const [filters, setFilters] = useState(parseFilter());
  const [showTransactionPopup, setShowTransactionPopup] = useState(false);
  const [agentsList, setAgentsList] = useState([]);

  const location = useLocation();

  const {
    loading: isLoadingDeliveryLogs,
    deliveryLogs,
    totalDeliveryLogs,
    fetch: fetchDeliveryLogs,
  } = useFetchDeliveryLogs(sortBy, filters, page);

  const { loading: isBalanceLoading, transactionSummary } =
    useFetchTransactionSummary({
      balance: isAppealioExpressUser(props.userInfo),
      isStandAloneUser: isAppealioExpressUser(props.userInfo),
    });

  useEffect(() => {
    setFilters(parseFilter());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const onSortByClick = (sortBy) => setSortBy(sortBy);
  const onFilterChange = (filters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...filters }), setPage(1));
    window.history.pushState({}, document.title, window.location.pathname);
  };
  const onPageChange = (page) => setPage(page);

  const pageCount = Math.ceil(totalDeliveryLogs / TABLE_PAGE_LIMIT);

  const handleResetClick = () => setFilters(DEFAULT_FILTERS);

  const updateAgentsList = useCallback((agents) => {
    setAgentsList(agents);
  }, []);

  const renderTable = useCallback(() => {
    if (props.shouldHideTable) {
      return null;
    }
    return (
      <DeliveryLogsTable
        isLoading={isLoadingDeliveryLogs}
        data={deliveryLogs}
        dataCount={totalDeliveryLogs}
        page={page}
        pageCount={pageCount}
        sortBy={sortBy}
        onSort={onSortByClick}
        onPageChange={onPageChange}
        userInfo={props.userInfo}
        fetchDeliveryLogs={fetchDeliveryLogs}
        agentsList={agentsList}
        handleResetClick={handleResetClick}
      />
    );
  }, [
    agentsList,
    deliveryLogs,
    fetchDeliveryLogs,
    isLoadingDeliveryLogs,
    page,
    pageCount,
    props.shouldHideTable,
    props.userInfo,
    sortBy,
    totalDeliveryLogs,
  ]);

  return (
    <div className="delivery-tracking-container background-color-grey-1">
      <div className="delivery-tracking-table-container delivery-tracking-table-container--lg">
        <DeliveryTrackerActionBar
          filters={filters}
          onFilterChange={onFilterChange}
          isResetActive={!isEqual(filters, DEFAULT_FILTERS)}
          onResetClick={handleResetClick}
          deliveryLogs={deliveryLogs}
          page={page}
          totalDeliveryLogs={totalDeliveryLogs}
          pageCount={pageCount}
          onPageChange={onPageChange}
          defaultFilters={DEFAULT_FILTERS}
          updateAgentsList={updateAgentsList}
        />
        {renderTable()}
        {isAppealioExpressUser(props.userInfo) && !isBalanceLoading && (
          <button
            className="ap-button appealio-express__balance cursor-pointer"
            onClick={() => setShowTransactionPopup(true)}
          >
            {' '}
            Balance:{' '}
            <span className="link-text ml-4">
              ${transactionSummary.currentBalance || '0.00'}
            </span>
          </button>
        )}
      </div>
      {isAppealioExpressUser(props.userInfo) && showTransactionPopup && (
        <TransactionSummaryPopup
          setShowTransactionPopup={setShowTransactionPopup}
        />
      )}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const shouldHideTable = getIsOpen(state) && !getIsMinimized(state);
  return {
    userInfo: getUserInfo(state),
    shouldHideTable,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryTracking);
