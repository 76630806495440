import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MaskedInput from 'react-text-mask';

import './style.css';

import Label from './Label';

const ErrorMessage = ({ errorMsg, useErrorSpace = false }) => {
  if (!errorMsg && !useErrorSpace) return null;

  return <span className="ap-input-error">{errorMsg || '\u00A0'}</span>;
};

const Input = (props) => {
  const {
    name,
    label,
    value,
    onChange,
    placeholder = '',
    readOnly = false,
    input = {},
    meta: { touched, error = '' } = {},
    inputRef = null,
    type,
    min,
    max,
    step,
    mask,
    maskedInput = false,
    maxLength,
    customError,
    datacy,
    pattern,
    title,
    useErrorSpace = false,
    showErrorInputField = false,
    className,
    dataTip = '',
  } = props;

  const errorMsg = customError || (touched && error ? error : '');

  return (
    <div className={classnames('ap-input-container', className)}>
      <Label label={label} name={name} dataTip={dataTip} />
      {!maskedInput ? (
        <input
          value={value}
          className={classnames('ap-input', {
            'ap-input--disabled': readOnly,
            'ap-input--error': showErrorInputField && touched && error,
          })}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          ref={inputRef}
          type={type}
          min={min}
          max={max}
          step={step}
          readOnly={readOnly}
          pattern={pattern}
          title={title}
          maxLength={maxLength}
          datacy={datacy}
          {...input}
        />
      ) : (
        <MaskedInput
          name={name}
          mask={mask}
          tabIndex={0}
          placeholder={placeholder}
          className={classnames('ap-input', {
            'ap-input--disabled': readOnly,
            'ap-input--error': showErrorInputField && touched && error,
          })}
          onChange={input.onChange}
          value={input.value}
          type={type}
          guide={true}
          datacy={datacy}
          pattern={pattern}
          title={title}
          {...input}
        />
      )}

      <ErrorMessage errorMsg={errorMsg} useErrorSpace={useErrorSpace} />
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default Input;
