import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useExpanded,
} from 'react-table';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import EmptyTableMessage from 'components/common/table/EmptyTableMessage';

import sortChevronUpIconBold from 'img/sort-chevron-up-bold.svg';
import sortChevronDownIconBold from 'img/sort-chevron-down-bold.svg';

import './style.css';

/**
 * Common Table Component.
 *
 * @param {Object} Props
 *
 * @returns {Component}
 */
const Table = (props) => {
  const {
    columns,
    data,
    isLoading = false,
    noDataMessage = 'No data found',
    showNoDataMessage = true,
    onRowClick = () => ({}),
    defaultSortBy = {},
    filterText,
    className,
    onSort,
    wrapperClassName,
    emptyTableComponent,
    rowProps = () => ({}),
    disableRowOnReadOnly = true,
  } = props;

  const [isDefaultSortByApplied, setIsDefaultSortByApplied] = useState(
    !!isEmpty(defaultSortBy)
  );

  const extractInitialSortBy = useCallback(() => {
    if (isEmpty(defaultSortBy)) return [];

    const initialSortByColumn = columns.find((column) =>
      [column.accessor, column.sortKey].includes(defaultSortBy.id)
    );

    if (!initialSortByColumn) return [];

    return [
      {
        ...defaultSortBy,
        id: initialSortByColumn.accessor,
      },
    ];
  }, [defaultSortBy, columns]);

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    setGlobalFilter,
    footerGroups,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      autoResetPage: false,
      disableSortRemove: true,
      initialState: {
        sortBy: extractInitialSortBy(),
        hiddenColumns: [],
      },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    if (!isDefaultSortByApplied) {
      return setIsDefaultSortByApplied(true);
    }

    if (sortBy.length === 0) {
      return onSort(null);
    }
    const [newSortBy] = sortBy;
    const sortedColumn = columns.find((column) =>
      [column.accessor, column.sortKey].includes(newSortBy.id)
    );
    const sortKey = sortedColumn.sortKey
      ? sortedColumn.sortKey
      : sortedColumn.accessor;

    onSort({
      id: sortKey,
      desc: newSortBy.desc,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  useEffect(() => {
    setGlobalFilter(filterText);
  }, [setGlobalFilter, filterText]);

  if (data.length < 1 && !isLoading) {
    return emptyTableComponent || <EmptyTableMessage />;
  }

  return (
    <div className={classnames(wrapperClassName)}>
      <table
        {...getTableProps()}
        className={`${className || ''} appealio-table`}
        datacy="table"
      >
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps({
                    ...column.getSortByToggleProps({ title: undefined }),
                    className: classnames('appealio-table__body-col', {
                      [column.className]: column.className,
                      'fw-bold': column.isSorted,
                      'cursor-default': column.disableSortBy,
                    }),
                    style: { width: column.width, maxWidth: column.maxWidth },
                  })}
                  key={index}
                >
                  <div className="d-flex justify-content--space-between align-items-center">
                    <div className="appealio-table__header-col-content">
                      {column.render('Header')}
                    </div>
                    <span>
                      {column.isSorted && column.canSort ? (
                        column.isSortedDesc ? (
                          <img
                            src={sortChevronDownIconBold}
                            alt="sort-arrow-img"
                          />
                        ) : (
                          <img
                            src={sortChevronUpIconBold}
                            alt="sort-arrow-img"
                          />
                        )
                      ) : column.canSort ? null : null}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {isLoading && (
            <tr>
              <td colSpan="100%">
                <LoadingBlockHelper
                  className="dashboard-bottom-loader"
                  isLoading={isLoading}
                />
              </td>
            </tr>
          )}
          {rows.map((row, i) => {
            prepareRow(row);
            const { className = false, dataTip = false } = row.getRowProps(
              rowProps(row)
            );
            const { readOnly, className: customClassName } = row.original;
            return (
              <tr
                {...row.getRowProps()}
                className={classnames('appealio-table__body-row', {
                  'appealio-table__body-row--disabled':
                    readOnly && disableRowOnReadOnly,
                  [className]: className,
                  [customClassName]: customClassName,
                })}
                key={i}
                onClick={(e) => {
                  onRowClick(row.original);
                }}
                {...(dataTip && { 'data-tip': dataTip })}
              >
                {row.cells.map((cell, index) => {
                  const onCellClick = (e) => {
                    if (!cell.column.onCellClick) return;

                    e.stopPropagation();
                    e.preventDefault();
                    cell.column.onCellClick(row.original);
                  };
                  return (
                    <td
                      onClick={onCellClick}
                      data-cy={
                        cell.column.datacy
                          ? `table-body-cell-${cell.column.datacy}`
                          : null
                      }
                      key={index}
                      {...cell.getCellProps({
                        className: classnames('appealio-table__body-col', {
                          [cell.column.className]: cell.column.className,
                          [cell.column.bodyClassName]:
                            cell.column.bodyClassName,
                          'appealio-table__body-col--sorted':
                            cell.column.isSorted,
                        }),
                        style: {
                          width: cell.column.width,
                          maxWidth: cell.column.maxWidth,
                        },
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}

          {!rows.length && !isLoading && showNoDataMessage && (
            <tr>
              <td colSpan={1000} style={{ paddingTop: 20, paddingBottom: 20 }}>
                <span className="d-flex justify-content-center">
                  {noDataMessage}
                </span>
              </td>
            </tr>
          )}
        </tbody>

        <tfoot>
          {footerGroups.map((group, i) => {
            const isFooterAvailable =
              group.headers.filter(
                (column) => column.Footer && typeof column.Footer !== 'function'
              ).length > 0;

            return (
              isFooterAvailable && (
                <tr {...group.getFooterGroupProps()} key={i}>
                  {group.headers.map((column, index) => {
                    return (
                      <td {...column.getFooterProps()} key={index}>
                        {column.render('Footer')}
                      </td>
                    );
                  })}
                </tr>
              )
            );
          })}
        </tfoot>
      </table>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  filterText: PropTypes.any,
  isLoading: PropTypes.bool,
  onRowClick: PropTypes.func,
  noDataMessage: PropTypes.string,
  showNoDataMessage: PropTypes.bool,
  sortBy: PropTypes.array,
  wrapperClassName: PropTypes.string,
};

export default Table;
