import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import CardView from '../Shared/CardView';
import ForgotPasswordEnterEmailForm from './ForgotPasswordEnterEmailForm';

import * as routes from 'constants/routes';

import forgotPasswordIcon from '../../img/forgot-pass.svg';

class ForgotPassword extends Component {
  render() {
    const isRequestCreatePasswordPath =
      this.props.location.pathname === routes.REQUEST_NEW_PASSWORD;

    const heading = !isRequestCreatePasswordPath
      ? 'Forgot Password?'
      : 'Request set password link';
    const description = !isRequestCreatePasswordPath
      ? 'Please enter your email to reset your password.'
      : 'Please enter your email to request set password link.';

    return (
      <div className="login">
        <CardView>
          <div className="login__header">
            <img
              alt="Forget Password Icon"
              className="login__header-logo"
              src={forgotPasswordIcon}
            />
            <h2 datacy="forgot-password-header"> {heading}</h2>
          </div>

          <div
            className="login__subheader"
            datacy="forgot-password-description"
          >
            {description}
          </div>

          <ForgotPasswordEnterEmailForm
            onEmailSent={this.onEmailSent}
            isRequestCreatePasswordPath={isRequestCreatePasswordPath}
          />
        </CardView>
      </div>
    );
  }

  onEmailSent = () => {
    this.props.actions.push('password/sent');
  };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ push }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
