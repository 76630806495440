import React, { useEffect, useState } from 'react';

import '../style.css';

import { downloadFile } from 'API/Config';
import CreateAppealAPI from 'API/CreateAppealAPI';

import { handleError } from 'helpers/errorHandler';

const EmailSubmissionDetails = ({ data, tags, submissionId = null }) => {
  const { deliverStatus, message, recipientEmail, subject, ccEmails } = data;
  const [emailFiles, setEmailFiles] = useState([]);

  useEffect(() => {
    const fetchAndSetEmailFiles = async () => {
      try {
        const emailFiles = await CreateAppealAPI.fetchEmailDocuments(
          submissionId
        );
        const files = await Promise.all(
          emailFiles.map(async (file) => {
            const blob = await CreateAppealAPI.fetchAppealDocument(file.id);

            return new File([blob], file.name, { type: document.docType });
          })
        );
        setEmailFiles(files);
      } catch (error) {
        handleError(error);
      }
    };

    fetchAndSetEmailFiles();
  }, [submissionId]);

  return (
    <div>
      {data ? (
        <div>
          <div className="submission-details-title">Email Submission</div>
          <div className="submission-details-header">
            <p>
              <label className="submission-label">Status Message:</label>
              <span>{deliverStatus}</span>
            </p>
            <p>
              <label className="submission-label">Recipient Email:</label>
              <span>{recipientEmail}</span>
            </p>
            {ccEmails && ccEmails.length > 0 && (
              <p>
                <label className="submission-label">CC Emails:</label>
                <span>{ccEmails.join(', ')}</span>
              </p>
            )}
            <p>
              <label className="submission-label">Subject:</label>
              <span>{subject}</span>
            </p>

            <p>
              <label className="submission-label">Message:</label>
              <span>{message}</span>
            </p>

            {emailFiles.length > 0 && (
              <div>
                <label className="submission-label">Excel Attachments:</label>
                <ul>
                  {emailFiles.map((file, index) => (
                    <li key={index}>
                      <span
                        className="link-url"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          downloadFile(file, file.name);
                        }}
                      >
                        {file.name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {tags && tags.length > 0 && (
            <p>
              <label>Tags:</label>
              {tags.join(', ')}
            </p>
          )}
        </div>
      ) : (
        <p className="error-message">
          Email does not have any associated data yet.
        </p>
      )}
    </div>
  );
};

export default EmailSubmissionDetails;
