import { useCallback, useEffect, useState } from 'react';

import { fetchPmNotesInfo } from 'API/DenialsAPI';
import { handleError } from 'helpers/errorHandler';

export const useFetchPmNotesInfo = (appealId) => {
  const [loading, setLoading] = useState(false);
  const [pmNoteData, setPmNoteData] = useState({});

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      const data = await fetchPmNotesInfo(appealId);
      setPmNoteData(data);
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  }, [appealId]);

  useEffect(() => {
    fetch();
  }, [fetch, appealId]);

  return { loading, pmNoteData };
};
