import moment from 'moment';
import { get } from 'lodash';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import { Field, reduxForm, SubmissionError } from 'redux-form/immutable';

import ErrorPopup from './ErrorPopup';
import { MASK_DATE } from 'helpers/masks';
import Input from 'components/common/input';
import Button from 'components/common/button';
import Select from 'components/common/select/Select';
import AppealioPopup from 'components/Shared/AppealioPopup';
import LoadingIndicator from 'components/Shared/LoadingIndicator';

import { fetchAvailityClaimStatus } from 'API/PayerAPI';
import errorAnimation from '../UhcClaimStatusCheck/errorAnimation.json';

import './style.css';

import { wait } from 'helpers/utils';
import { sortArrayOfObjectsByPropertyOrder } from 'helpers/object';
import {
  format,
  normalizeMoney,
} from 'components/CreateAppeal/DenialInformation/CPTRowConfig';

const CLAIM_INFORMATION_FIELD_KEYS_APPEALIO_LABEL = {
  // claimNumber: "Payer Claim ID",
  claimAmount: 'Billed Amount',
  'providers.lastName': 'Billing Provider Name ',
  'providers.npi': 'Billing Provider NPI ',
  'providers.taxId': 'Billing Provider Tax ID ',
  fromDate: 'Service Date (From)',
  toDate: 'Service Date (To)',
};

const CLAIM_INFORMATION_FIELDS_KEYS = Object.keys(
  CLAIM_INFORMATION_FIELD_KEYS_APPEALIO_LABEL
);

const PATIENT_FIELD_KEYS_APPEALIO_LABEL = {
  'subscriber.memberId': 'Member ID ',
  'patient.lastName': 'Patient Last Name ',
  'patient.birthDate': 'Patient DOB ',
  'patient.accountNumber': 'Claim ID',
};
const PATIENT_FIELDS_KEYS = Object.keys(PATIENT_FIELD_KEYS_APPEALIO_LABEL);
const SYSTEM_FIELDS_KEYS_APPEALIO_LABEL = {
  'submitter.id': 'Submitter ID ',
  'submitter.lastName': 'Submitter Name ',
};

const AVAILITY_FORM_FIELD_KEYS = Object.keys({
  ...CLAIM_INFORMATION_FIELD_KEYS_APPEALIO_LABEL,
  ...PATIENT_FIELD_KEYS_APPEALIO_LABEL,
  ...SYSTEM_FIELDS_KEYS_APPEALIO_LABEL,
});

function convertKeysFormat(obj, toDot = false) {
  for (const key in obj) {
    const newKey = toDot ? key.replace('_', '.') : key.replace('.', '_');
    if (newKey !== key) {
      obj[newKey] = obj[key];
      delete obj[key];
    }
  }
  return obj;
}

const convertKeyToUnderscore = (obj) => {
  return {
    ...obj,
    key: obj.key.replace('.', '_'),
  };
};
const required = (value) => (value ? undefined : 'This field is required');

const generateFormFields = (fields, className) => {
  const getValidateProp = (field) => {
    if (field.required) {
      return [required];
    }
    return null;
  };
  const commonClassName = classnames('mb-8', className);

  return fields
    .map((field) => {
      const allLabels = {
        ...SYSTEM_FIELDS_KEYS_APPEALIO_LABEL,
        ...CLAIM_INFORMATION_FIELD_KEYS_APPEALIO_LABEL,
        ...PATIENT_FIELD_KEYS_APPEALIO_LABEL,
      };
      const label = get(allLabels, field.key, field.label);

      return {
        ...field,
        label,
      };
    })
    .map(convertKeyToUnderscore)
    .map((field) => {
      let formField;
      const isMoneyField = field.key.endsWith('Amount');
      const moneyFieldInputProps = isMoneyField
        ? { format, normalize: normalizeMoney }
        : {};
      const validate = getValidateProp(field);
      switch (field.type) {
        case 'Text':
          formField = (
            <div key={field.key} className={commonClassName}>
              <Field
                name={field.key}
                label={`${field.label}${field.required ? ' *' : ''}`}
                component={Input}
                type="text"
                maxLength={field.maxLength}
                required={field.required}
                validate={validate}
                useErrorSpace
                showErrorInputField
                {...moneyFieldInputProps}
              />
            </div>
          );
          break;
        case 'Date':
          formField = (
            <div key={field.key} className={commonClassName}>
              <Field
                name={field.key}
                label={`${field.label}${field.required ? ' *' : ''}`}
                component={Input}
                type="text"
                maxLength={field.maxLength}
                required={field.required}
                maskedInput={true}
                mask={MASK_DATE}
                placeholder="MM/DD/YYYY"
                validate={validate}
                useErrorSpace
                showErrorInputField
              />
            </div>
          );
          break;
        case 'Enumeration':
          // eslint-disable-next-line no-case-declarations
          const options = field.values.map(({ code, value }) => ({
            value: code,
            label: value,
          }));
          formField = (
            <div key={field.key} className={commonClassName}>
              <Field
                name={field.key}
                label={`${field.label}${field.required ? ' *' : ''}`}
                options={options}
                component={Select}
                required={field.required}
                validate={validate}
                useErrorSpace
              />
            </div>
          );
          break;
        default:
          formField = (
            <div key={field.key}>
              <label>{field.label}</label>
              <Field
                name={field.key}
                component="input"
                type="text"
                disabled={true}
                value={field.errorMessage}
                validate={validate}
                showErrorInputField
              />
            </div>
          );
      }
      return formField;
    });
};

let AvailityForm = (props) => {
  const {
    onCloseClick,
    prefillData,
    payerInputFields,
    handleSubmit,
    ediPayerId,
    claimControlNumber,
    onClaimStatusCheckSuccess,
    onClaimStatusCheckError,
    submitting,
    invalid,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [
    showCommunicatingWithHealthPlanPopup,
    setShowCommunicatingWithHealthPlanPopup,
  ] = useState(false);

  const availityFormFields = payerInputFields.filter(
    (field) => field.required || AVAILITY_FORM_FIELD_KEYS.includes(field.key)
  );

  const claimInformationFields = sortArrayOfObjectsByPropertyOrder(
    availityFormFields.filter(
      (f) =>
        f.key.startsWith('providers.') ||
        CLAIM_INFORMATION_FIELDS_KEYS.includes(f.key)
    ),
    CLAIM_INFORMATION_FIELDS_KEYS,
    'key'
  );

  const patientFields = sortArrayOfObjectsByPropertyOrder(
    availityFormFields.filter(
      (f) => f.key.startsWith('patient') || PATIENT_FIELDS_KEYS.includes(f.key)
    ),
    PATIENT_FIELDS_KEYS,
    'key'
  );

  // system fields are other fields than patient and claim information fields
  const systemFields = availityFormFields.filter(
    (f) =>
      [
        ...patientFields.map((f) => f.key),
        ...claimInformationFields.map((f) => f.key),
      ].indexOf(f.key) === -1
  );

  useEffect(() => {
    setTimeout(() => {
      if (prefillData) {
        const prefillDataKeys = Object.keys(prefillData);
        const prefillDataValues = Object.values(prefillData);
        const prefillDataObject = {};
        prefillDataKeys.forEach((key, index) => {
          prefillDataObject[key] = prefillDataValues[index];
        });
        props.initialize(convertKeysFormat(prefillDataObject, false));
      }
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateDateValue = (key, value) => {
    const DATE_KEYS_FOR_VALIDATION = [
      'fromDate',
      'toDate',
      'patient_birthDate',
    ];
    if (!value) return null;
    if (!DATE_KEYS_FOR_VALIDATION.includes(key)) return null;

    const momentDate = moment(value, 'MM/DD/YYYY');
    if (!momentDate.isValid()) return 'Invalid date';
    if (momentDate.isAfter(moment())) return 'Date cannot be in the future';

    return null;
  };

  const validateValues = (values) => {
    const errors = {};

    availityFormFields.forEach((field) => {
      const { key, pattern, errorMessage } = convertKeyToUnderscore(field);
      if (field.required && !values[key]) {
        errors[key] = 'This field is required';
      }

      if (pattern && values[key] && !new RegExp(pattern).test(values[key])) {
        errors[key] = errorMessage;
      }

      const dateValidationError = validateDateValue(key, values[key]);
      if (dateValidationError) {
        errors[key] = dateValidationError;
      }
    });

    if (Object.keys(errors).length > 0) {
      throw new SubmissionError(errors);
    }
  };
  const handleFormSubmit = async (formValues) => {
    validateValues(formValues.toJS());
    const values = convertKeysFormat(formValues.toJS(), true);
    try {
      setIsSubmitting(true);
      const requestData = {
        ...values,
        'payer.id': ediPayerId,
        edi_payer_id: ediPayerId,
        claim_control_number: claimControlNumber,
        tax_id: values['providers.taxId'],
      };
      let responseData = await fetchAvailityClaimStatus(requestData);

      // if status is in progress, check again after 5 seconds
      if (String(responseData?.statusCode) === '0') {
        await wait(5000);
        responseData = await fetchAvailityClaimStatus(requestData);
      }

      // if status is still in progress, show communicating with health plan popup
      if (String(responseData?.statusCode) === '0') {
        setShowCommunicatingWithHealthPlanPopup(true);
        setIsSubmitting(false);
        return;
      }

      const isInvalidResponse =
        !responseData?.claimStatuses ||
        responseData?.claimStatuses.every((claimStatus) => {
          const statusDetail = get(claimStatus, 'statusDetails[0]');
          const claimNumber = get(claimStatus, 'claimControlNumber');
          const categoryCode = get(statusDetail, 'categoryCode');
          const statusCode = get(statusDetail, 'statusCode');

          return !claimNumber || !categoryCode || !statusCode;
        });

      if (isInvalidResponse) {
        return onClaimStatusCheckError(
          `Availity is unable to find a claim associated with Claim ID: ${claimControlNumber}`
        );
      }
      onClaimStatusCheckSuccess(responseData, requestData);
    } catch (error) {
      if (error?.response?.status === 400) {
        const response = await error?.response?.json();
        const fieldErrors = response?.errors?.errors;
        if (fieldErrors && fieldErrors.length > 0) {
          const errors = {};
          fieldErrors.forEach((fieldError) => {
            errors[fieldError.field.replace('.', '_')] =
              fieldError.errorMessage;
          });
          throw new SubmissionError(errors);
        }
      }
      onClaimStatusCheckError(
        `Availity is unable to find a claim associated with Claim ID: ${claimControlNumber}`
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderClaimInformationFields = () => {
    const payerClaimAmountKey = 'claimAmount';

    const payerClaimAmountField = payerInputFields.find(
      (field) => field.key === payerClaimAmountKey
    );

    const groupedServiceDatesFieldKeys = ['fromDate', 'toDate'];
    const serviceDatesFields = claimInformationFields.filter((field) =>
      groupedServiceDatesFieldKeys.includes(field.key)
    );
    const shouldShowGroupedServiceDatesFields = serviceDatesFields.length === 2;
    const groupedBillingProviderFields = ['providers.npi', 'providers.taxId'];
    const billingProviderFields = claimInformationFields.filter((field) =>
      groupedBillingProviderFields.includes(field.key)
    );
    const shouldShowGroupedBillingProviderFields =
      billingProviderFields.length === 2;
    const fieldsToExclude = [
      'claimAmount',
      ...(shouldShowGroupedServiceDatesFields ? ['fromDate', 'toDate'] : []),
      ...(shouldShowGroupedBillingProviderFields
        ? ['providers.npi', 'providers.taxId']
        : []),
    ];

    const claimInformationFieldsWithoutGroupedFields =
      claimInformationFields.filter(
        (field) => !fieldsToExclude.includes(field.key)
      );

    if (claimInformationFields.length === 0) {
      return null;
    }

    return (
      <div>
        <h5 className="font-style-italic">Please fill in required(*) fields</h5>
        <h3>Claim Information</h3>
        <div className="row ">
          {generateFormFields([payerClaimAmountField], 'col-lg-3')}
          {generateFormFields(
            claimInformationFieldsWithoutGroupedFields,
            'col-lg-6'
          )}
          {shouldShowGroupedBillingProviderFields &&
            generateFormFields(billingProviderFields, 'col-lg-3')}
          {shouldShowGroupedServiceDatesFields &&
            generateFormFields(serviceDatesFields, 'col-lg-3')}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {showCommunicatingWithHealthPlanPopup && (
        <ErrorPopup
          message="Availity is communicating with the health plan. Check back in a few seconds."
          animationData={errorAnimation}
          onCloseClick={() => setShowCommunicatingWithHealthPlanPopup(false)}
        />
      )}
      <AppealioPopup
        title={`Claim Status Check for: ${claimControlNumber}`}
        onClosePressed={onCloseClick}
        className={classnames('availity-claim-status-check-form', {
          'd-none': showCommunicatingWithHealthPlanPopup,
        })}
        useDimmerComponent={!showCommunicatingWithHealthPlanPopup}
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          {renderClaimInformationFields()}
          {patientFields.length > 0 && (
            <div>
              <h3>Patient Information</h3>
              <div className="row">
                {generateFormFields(patientFields, 'col-lg-6')}
              </div>
            </div>
          )}
          {systemFields.length > 0 && (
            <div>
              <h3>Clearinghouse</h3>
              <div className="row">
                {generateFormFields(systemFields, 'col-lg-6')}
              </div>
            </div>
          )}
          <div className="d-flex justify-content-flex-end mt-24">
            <Button
              type="secondary"
              className="position-relative submit-button ap-button--block justify-content-center"
              disabled={submitting || invalid}
            >
              {isSubmitting ? (
                <LoadingIndicator isLightIndicator showing />
              ) : (
                'Submit'
              )}
            </Button>
          </div>
        </form>
      </AppealioPopup>
    </React.Fragment>
  );
};

AvailityForm = reduxForm({
  form: 'availityClaimStatusForm',
})(AvailityForm);

export default AvailityForm;
