import moment from 'moment';

// date formats used across the app
export const DATE_FORMATS = ['MM/DD/YYYY', 'YYYY-MM-DD'];
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
export const DATE_FORMAT_MM_DD_YYYY = 'MM_DD_YYYY';
export const VALIDATION_DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_WITH_TIME_FORMAT = 'MM/DD/YYYY HH:mm';
export const DATE_WITH_TIME_FORMAT_AM_PM = 'MM/DD/YYYY h:mm A';
export const DATE_FORMAT_WITH_MMMM_D_YYYY = 'MMMM D, YYYY';
export const DATE_FORMAT_MMM_D_YYYY = 'MMM. D, YYYY';
export const DATE_WITH_TIME_MMM_D_YYYY = 'MMM. D, YYYY, h:mm:ss a';

/**
 * Checks if the given date is valid.
 *
 * @param {Date} date
 * @returns {Boolean}
 */
export const isValidDate = (date) => moment(date).isValid();

/**
 * Formats a date according to the given format.
 * If the date does not contain timezone information, it neutralizes the timezone offset before formatting.
 * If the date contains timezone information, it formats the date as is.
 *
 * @param {String|Date} date - The date or date string to format.
 * @param {String} format - The format to apply to the date. Defaults to VALIDATION_DATE_FORMAT.
 * @returns {String} - The formatted date string.
 */
export const getFormattedDate = (date, format = VALIDATION_DATE_FORMAT) => {
  // Check if the date string contains timezone information
  const hasTimezoneInfo = /Z|[+-]\d{2}:\d{2}$/.test(date);

  let formattedDate;
  if (hasTimezoneInfo) {
    formattedDate = moment(date).format(format);
  } else {
    // If there is no timezone info, neutralize the offset by treating it as UTC
    formattedDate = moment.utc(date).format(format);
  }

  return formattedDate;
};

/**
 * Get a string representation of a date, either "today", "yesterday", or in the format "MM/DD/YYYY".
 * @param {Date} date - The input date.
 * @returns {string} - A string representation of the date.
 */

export const getDateString = (date, format = VALIDATION_DATE_FORMAT) => {
  const momentDate = moment(date);
  const today = moment();
  const yesterday = moment().subtract(1, 'days');

  if (momentDate.isSame(today, 'day')) {
    return 'Today';
  } else if (momentDate.isSame(yesterday, 'day')) {
    return 'Yesterday';
  } else {
    return momentDate.format(format);
  }
};

/**
 * Constants for time units in seconds
 */
const MINUTE = 60;
const HOUR = 3600;
const DAY = 86400;
const WEEK = 7 * DAY;

/**
 * Convert a duration given in seconds to a human-readable relative time.
 *
 * @param {number} inputSeconds - The number of seconds to convert.
 * @returns {string} - The converted relative time string.
 */
export function convertSecondsToRelativeTime(inputSeconds) {
  if (
    typeof inputSeconds !== 'number' ||
    inputSeconds === null ||
    inputSeconds === undefined
  ) {
    return null;
  }

  const weeks = Math.floor(inputSeconds / WEEK);
  const days = Math.floor((inputSeconds % WEEK) / DAY);
  const hours = Math.floor((inputSeconds % DAY) / HOUR);
  const minutes = Math.floor((inputSeconds % HOUR) / MINUTE);

  const components = [];

  if (days || weeks) components.push(`${days} day${days === 1 ? '' : 's'}`);
  if (hours || days || weeks)
    components.push(`${hours} hr${hours === 1 ? '' : 's'}`);
  if (minutes || hours || days || weeks)
    components.push(`${minutes} min${minutes === 1 ? '' : 's'}`);
  else components.push(`${minutes} mins`);

  return components.join(' ');
}

/**
 * Calculates the difference in days between two dates.
 * Returns null if either date is null.
 *
 * @param {string|null} date1 - The first date in "MM/DD/YYYY" format or null.
 * @param {string|null} date2 - The second date in "MM/DD/YYYY" format or null.
 * @returns {number|null} - The difference in days, or null if either date is null.
 */
export function calculateDateDifference(date1, date2) {
  if (!date1 || !date2) {
    return null;
  }

  const momentDate1 = moment(date1, 'MM/DD/YYYY');
  const momentDate2 = moment(date2, 'MM/DD/YYYY');

  const differenceInDays = momentDate2.diff(momentDate1, 'days');

  return differenceInDays;
}

export const getDateMonthsAgo = (
  monthsAgo,
  dateFormat = DATE_FORMAT_YYYY_MM_DD
) => {
  // Create a moment object for the current date
  const today = moment();

  // Subtract the specified number of months
  const firstDayOfMonthsAgo = today.subtract(monthsAgo, 'months');

  // Set the day to 1 to get the first day of that month

  // Format the date as "MM-DD-YYYY"
  const formattedDate = firstDayOfMonthsAgo.format(dateFormat);

  return formattedDate;
};

/**
 * Formats a UTC date string into a specified format using Moment.js and converts it to local time.
 *
 * @param {String} utcDateString - The UTC date string to format.
 * @param {String} format - The format to apply to the date. Defaults to 'YYYY-MM-DD HH:mm:ss'.
 * @returns {String} - The formatted date and time string in local time.
 */
export const formatUtcToLocalDateTime = (
  utcDateString,
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  const utcMoment = moment.utc(utcDateString, moment.ISO_8601);
  if (!utcMoment.isValid()) {
    return 'N/A';
  }
  const localMoment = utcMoment.local();
  const formattedLocalDateTime = localMoment.format(format);

  return formattedLocalDateTime;
};

/**
 * Calculates the date after a specified number of business days from today.
 * Business days are considered Monday through Friday, excluding weekends.
 *
 * @param {Number} days The number of business days to add to the current date.
 * @returns {Object} A moment object representing the future date after adding the specified number of business days.
 */
export const getBusinessDaysFromToday = (days) => {
  let date = moment();
  let businessDays = 0;
  while (businessDays < days) {
    date = date.add(1, 'days');
    if (date.isoWeekday() <= 5) {
      // Monday = 1, Friday = 5
      businessDays++;
    }
  }
  return date;
};

/**
 * Formats the given date to a human-readable format.
 * @param {Date} date - The date to be formatted.
 * @returns {string} The formatted date in 'MMM D, YYYY' format.
 */
export const formatToHumanReadable = (date) => {
  return moment(date).format('MMM D, YYYY');
};

/**
 * Compares two dates and checks if the first date is after the second date
 *
 * @param {string|Date} date1 - First date to compare
 * @param {string|Date} date2 - Second date to compare
 * @param {string} format - Date format (defaults to VALIDATION_DATE_FORMAT)
 * @returns {boolean} - Returns true if date1 is after date2, false otherwise
 */
export const isDateAfter = (date1, date2, format = VALIDATION_DATE_FORMAT) => {
  if (!date1 || !date2) {
    return false;
  }

  const momentDate1 = moment(date1, format);
  const momentDate2 = moment(date2, format);

  if (!momentDate1.isValid() || !momentDate2.isValid()) {
    return false;
  }

  return momentDate1.isAfter(momentDate2);
};
