import React from 'react';
import TextArea from 'components/common/textarea';
import { Field, reduxForm, SubmissionError } from 'redux-form/immutable';

const PROPOSED_ACTIONS_CHARACTERS_LIMIT = 255;

const ProposedActionForm = ({
  handleSubmit,
  handleFormSubmit,
  pristine,
  submitting,
}) => {
  const onSubmit = (formValues) => {
    const values = formValues.toJS();
    const errors = {};
    if (!values.description) {
      errors.description = 'Proposed Action is required';
    }
    if (!values.description?.trim()) {
      errors.description = 'Proposed Action can not  be empty.';
    }
    if (
      values.description &&
      values.description.length > PROPOSED_ACTIONS_CHARACTERS_LIMIT
    ) {
      errors.description =
        'Proposed Action cannot be more than 255 characters.';
    }
    if (Object.keys(errors).length) {
      throw new SubmissionError(errors);
    }
    handleFormSubmit(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        label="Proposed Action"
        component={TextArea}
        name="description"
        resize={false}
        rows="3"
        styles={{
          minWidth: '308px',
          resize: 'none',
        }}
        max={PROPOSED_ACTIONS_CHARACTERS_LIMIT}
        maxLength={PROPOSED_ACTIONS_CHARACTERS_LIMIT}
        showCharactersCounter
      />
      <button
        type="submit"
        className="ap-button ap-button--button width-120 ap-button ap-button--secondary justify-content-center fw-normal height-40"
        disabled={pristine || submitting}
      >
        Save
      </button>
    </form>
  );
};

export default reduxForm({
  form: 'proposed-action-name',
})(ProposedActionForm);
