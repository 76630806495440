import classnames from 'classnames';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import ProcedureRow from './ProcedureRow';
import Select from 'components/common/select/Select';
import LoadingBlockHelper from '../../../Shared/LoadingBlockHelper';
import CardContainer from '../../Common/CardContainer/CardContainer';

import infoIcon from '../../../../img/info.svg';

import { useFetchCPTs, useFetchEstimate } from './hook';

import { moneyFormatter } from '../../../../helpers/money';

const CalculateEstimate = (props) => {
  const { procedures, setProcedures } = props;

  const [isEstimateButtonVisible, setIsEstimateButtonVisible] = useState(false);

  const { cpts, loading: isLoadingCPTs } = useFetchCPTs();
  const { loading, estimates } = useFetchEstimate(
    props.patient,
    props.eligibility.id,
    props.insuranceId,
    procedures,
    props.manualBenefitInfo
  );

  const cptOptions = cpts.map((cpt) => {
    const label = `${cpt.procedureCategory} ${cpt.description} (${cpt.code})`;
    const isSelectedCptCode = procedures.some(
      (procedure) => procedure.code === cpt.code
    );

    return { label, isDisabled: isSelectedCptCode, value: cpt.code, data: cpt };
  });

  /**
   * removes the selected row from procedure table
   * @param {String} procedure_code
   */
  const removeProcedure = (code) => {
    const filteredProcedures = procedures.filter(
      (procedure) => procedure.code !== code
    );
    setProcedures(filteredProcedures);
    setIsEstimateButtonVisible(true);
  };

  /**
   * Updates the quantity of procedure
   * @param {String} value  quantity
   * @param {String} code procedure code
   */
  const changeQuantity = (value, code) => {
    const data = procedures.map((procedure) => {
      if (procedure.code === code) {
        return { code: procedure.code, quantity: parseInt(value) };
      }
      return procedure;
    });

    setProcedures(data);
    setIsEstimateButtonVisible(true);
  };

  /**
   * add the selected cpt codes to procedures list
   */
  const onAddCpt = (selectedCPTOption) => {
    const { value } = selectedCPTOption;

    setProcedures((prevProcedures) => [
      ...prevProcedures,
      { code: value, quantity: 1 },
    ]);
    setIsEstimateButtonVisible(true);
  };

  return (
    <React.Fragment>
      <CardContainer
        className={classnames({
          'no-print': !procedures.length,
        })}
        datacy="calculate-estimate-CardContainer"
      >
        <h4 className="patient-cost-estimator__title">Estimate</h4>
        <div className="fw-500">
          Health Plan: {props.eligibility.healthPlan || 'Unavailable'}
        </div>
        <div className="cost-estimator-create-estimates-table">
          {loading ? (
            <LoadingBlockHelper className="pb-24" isLoading={loading} />
          ) : estimates && estimates.procedures.length ? (
            <table width="100%">
              <thead>
                <tr>
                  <th>
                    <span className="ml-12">&nbsp;</span>
                  </th>
                  <th>Procedure Code</th>
                  <th>Quantity</th>
                  <th>Description</th>
                  <th className="text-right">
                    <div className="d-flex">
                      <span>Estimated Allowed Amount</span>
                      <img
                        data-for="capped-info"
                        src={infoIcon}
                        alt="info"
                        data-tip="Estimated allowed amount capped at $600"
                        className="ml-10 no-print"
                      />
                      <ReactTooltip id="capped-info" effect="solid" />
                    </div>
                  </th>
                  <th className="text-right">Deductible Applied</th>
                  <th className="text-right">Coinsured Amount</th>
                  <th className="text-right">Copayment Amount</th>
                  <th className="text-center">Patient Coinsurance %</th>
                  <th className="table__highlighted-cell text-right">
                    Patient Cost
                  </th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {estimates.procedures.map((procedure, index) => (
                  <ProcedureRow
                    procedure={procedure}
                    index={index}
                    key={index}
                    onChangeQuantity={changeQuantity}
                    removeProcedure={removeProcedure}
                    datacy={`calculate-estimate-ProcedureRow-${index}`}
                  />
                ))}
              </tbody>
            </table>
          ) : null}
        </div>
        <div className="d-flex align-items-center mt-20 mb-20 no-print">
          <Select
            placeholder="Add Procedure Code"
            options={cptOptions}
            isLoading={isLoadingCPTs}
            onChange={onAddCpt}
            className="width-full pr-12"
            value={null}
            datacy="add-procedure-code-Select"
          />
        </div>
        {!isEstimateButtonVisible && procedures.length ? (
          <React.Fragment>
            <hr className="separator-line" />
            <div className="cost-estimation-details">
              <table width="50%">
                <thead>
                  <tr>
                    <th>Estimated Cost to Patient</th>
                    <th>
                      {moneyFormatter().format(estimates.estimate.patientCost)}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Total Deductible Applied</td>
                    <td>
                      {moneyFormatter().format(
                        estimates.estimate.deductible.applied
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Estimated Remaining Deductible</td>
                    <td>
                      {moneyFormatter().format(
                        estimates.estimate.deductible.remaining
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </React.Fragment>
        ) : null}
      </CardContainer>
      {isEstimateButtonVisible ? (
        <div className="mt-40 mb-40">
          <button
            className="btn--block btn--round no-print"
            onClick={() => setIsEstimateButtonVisible(false)}
          >
            Estimate
          </button>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default CalculateEstimate;
