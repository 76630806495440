import classnames from 'classnames';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { BsPencil } from 'react-icons/bs';
import { IoMdCloseCircleOutline } from 'react-icons/io';

import Card from 'components/common/card';
import AddBillingProviderForm from './AddBillingProviderForm';

import { generateUUID } from 'helpers/utils';
import { APPEALIO_SUPPORT_EMAIL } from 'constants/appConstants';
import ActionPopup, { TYPE_ACCEPT } from 'components/Shared/ActionPopup';

import infoIcon from 'img/info.svg';
import warningIcon from 'img/incomplete.svg';

const AddBillingProviderContainer = (props) => {
  const { practices, billingProviders, updateFormData } = props;

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [billingProviderToUpdate, setBillingProviderToUpdate] = useState(null);
  const [selectedPractice, setSelectedPractice] = useState(practices[0]);
  const [deleteProviderModal, setDeleteProviderModal] = useState({
    isOpen: false,
    provider: null,
  });
  const resetDeleteProviderModal = () =>
    setDeleteProviderModal({
      isOpen: false,
      provider: null,
    });
  const openDeleteProviderModal = (provider) =>
    setDeleteProviderModal({
      isOpen: true,
      provider,
    });
  const resetFormVisibility = () => {
    setIsFormVisible(false);
    setBillingProviderToUpdate(null);
  };

  const handleSubmit = (values) => {
    const formattedValues = {
      ...values,
      practiceUuid: selectedPractice.uuid,
    };

    if (!billingProviderToUpdate) {
      resetFormVisibility();

      return updateFormData([
        ...billingProviders,
        { uuid: generateUUID(), ...formattedValues },
      ]);
    }

    const updatedClinics = billingProviders.map((billingProvider) =>
      billingProvider.uuid === billingProviderToUpdate.uuid
        ? { uuid: billingProvider.uuid, ...formattedValues }
        : billingProvider
    );

    resetFormVisibility();
    updateFormData(updatedClinics);
  };

  const handleDelete = (billingProviderToDelete) => {
    const newBillingProviders = billingProviders.filter(
      (billingProvider) => billingProvider.uuid !== billingProviderToDelete.uuid
    );

    updateFormData(newBillingProviders);
  };

  const billingProviderToRender =
    selectedPractice &&
    billingProviders.filter(
      ({ practiceUuid }) => practiceUuid === selectedPractice.uuid
    );

  const handlePracticeClick = (practice) => setSelectedPractice(practice);

  const renderDeleteConfirmation = () => {
    if (!deleteProviderModal.isOpen) return;
    const handleClosePressed = () => resetDeleteProviderModal(null);
    const handleActionButtonPress = (index) => {
      if (index !== 1) {
        return handleClosePressed();
      }

      handleDelete(deleteProviderModal.provider);
      handleClosePressed();
    };
    return (
      <ActionPopup
        title={'Delete Billing Provider?'}
        subtitle="Deleting the billing provider will remove its association with related rendering providers."
        actionButtons={[
          {
            title: 'No',
          },
          {
            title: 'Yes',
            type: TYPE_ACCEPT,
          },
        ]}
        icon={warningIcon}
        onClosePressed={handleClosePressed}
        onActionButtonPressed={handleActionButtonPress}
      />
    );
  };

  return (
    <div className="card-wrapper">
      <Card className="min-height-40 mb-58">
        <div className="row form-info-row">
          <div className="col-md-9">
            <div className="form-section-title">Add Billing Provider(s)</div>
            <div className="form-section-description">
              <div>
                Add Billing Provider(s) to your Practices. If you have any
                questions, please feel free to contact us at{' '}
                {APPEALIO_SUPPORT_EMAIL}
              </div>
              <div className="mt-2">
                <span>
                  <img src={infoIcon} alt="info" className="mr-4" />A billing
                  provider is a legal entity that submits Claims to Payers and
                  collects payments from Patients.
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-offset-1 col-md-2">
            {selectedPractice && billingProviderToRender.length > 0 && (
              <button
                onClick={() => {
                  setBillingProviderToUpdate(null);
                  setIsFormVisible(true);
                }}
                className="ap-button ap-button--secondary add-btn--main"
              >
                <span className="mr-4">+</span> Add Billing Provider
              </button>
            )}
          </div>
        </div>
        <div className="row mt-12">
          <div className="col-lg-3 border-right-grey">
            {practices.map((practice, idx) => {
              return (
                <div
                  key={idx}
                  onClick={() => handlePracticeClick(practice)}
                  className={classnames('create-account-list-item mb-8', {
                    'create-account-list-item--selected':
                      selectedPractice &&
                      selectedPractice.uuid === practice.uuid,
                  })}
                >
                  {practice.name}
                </div>
              );
            })}
          </div>
          <div className="col-lg-9">
            {selectedPractice && billingProviderToRender.length > 0 && (
              <div className="row mb-16 create-acount-list-item-header">
                <div className="col-lg-3">Name</div>
                <div className="col-lg-3">Provider Type</div>
                <div className="col-lg-2">NPI</div>
                <div className="col-lg-2">Tax Id</div>
              </div>
            )}
            {selectedPractice &&
              billingProviders
                .filter(
                  ({ practiceUuid }) => practiceUuid === selectedPractice.uuid
                )
                .map((billingProvider, idx) => {
                  return (
                    <div
                      key={idx}
                      className="create-account-list-item row mb-16"
                    >
                      <div className="col-lg-3">
                        <span
                          data-tip={
                            billingProvider.organizationName ||
                            `${billingProvider.firstName} ${billingProvider.lastName}`
                          }
                        >
                          {' '}
                          {billingProvider.organizationName ||
                            `${billingProvider.firstName} ${billingProvider.lastName}`}
                        </span>
                      </div>
                      <div className="col-lg-3">
                        <span
                          data-tip={
                            billingProvider.organizationName
                              ? 'Organization'
                              : 'Individual'
                          }
                        >
                          {billingProvider.organizationName
                            ? 'Organization'
                            : 'Individual'}
                        </span>
                      </div>
                      <div className="col-lg-2">
                        <span data-tip={billingProvider.npi}>
                          {billingProvider.npi}
                        </span>
                      </div>
                      <div className="col-lg-2">
                        <span data-tip={billingProvider.taxId}>
                          {billingProvider.taxId}
                        </span>
                      </div>
                      <div className="col-lg-2">
                        <BsPencil
                          title="Edit"
                          className="mr-16"
                          size={16}
                          onClick={(e) => {
                            setBillingProviderToUpdate(billingProvider);
                            setIsFormVisible(true);
                          }}
                        />
                        <IoMdCloseCircleOutline
                          title="Delete"
                          size={16}
                          onClick={() =>
                            openDeleteProviderModal(billingProvider)
                          }
                        />
                      </div>
                      <ReactTooltip
                        effect="solid"
                        place="top"
                        multiline={true}
                      />
                    </div>
                  );
                })}

            {selectedPractice &&
              !isFormVisible &&
              billingProviderToRender.length < 1 && (
                <button
                  onClick={() => {
                    setBillingProviderToUpdate(null);
                    setIsFormVisible(true);
                  }}
                  className="add-item-btn"
                >
                  <span className="mr-4">+</span> Add Billing Provider
                </button>
              )}

            {isFormVisible && (
              <AddBillingProviderForm
                onSubmit={handleSubmit}
                initialValues={billingProviderToUpdate}
                onCancel={resetFormVisibility}
              />
            )}
          </div>
        </div>
      </Card>
      {renderDeleteConfirmation()}
    </div>
  );
};

export default AddBillingProviderContainer;
