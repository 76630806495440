import React from 'react';
import classnames from 'classnames';

import './style.css';

import closeIcon from 'img/close-modal.svg';

import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';

const AlertDialog = (props) => {
  return (
    <ScreenDimmerComponent>
      <div
        className={classnames('alert-dialog', props.className)}
        datacy="alert-dialog"
      >
        {props.onClosePressed && (
          <div className="alert-dialog__close-btn">
            <img
              alt="Close"
              onClick={() => props.onClosePressed()}
              className="alert-dialog__close-icon"
              src={closeIcon}
            />
          </div>
        )}

        {props.statusIcon && (
          <div className="alert-dialog__status-icon">
            <img
              src={props.statusIcon}
              alt="status"
              className="alert-dialog__status-icon-img"
            />
          </div>
        )}

        <div className="alert-dialog__content">{props.children}</div>
      </div>
    </ScreenDimmerComponent>
  );
};

export default AlertDialog;
