import classnames from 'classnames';
import React, { useState } from 'react';

import moment from 'moment';

import Dropdown from '../../Shared/Dropdown';
import calendar from 'img/calendar-icon.svg';
import { isInclusivelyAfterDay } from 'react-dates';
import { DatePicker } from 'components/common/datepicker';
import { DATE_FORMAT_YYYY_MM_DD } from 'helpers/dateFormats';

const DashboardFilter = (props) => {
  const {
    filterOptions,
    handleFilterChange,
    filters,
    handleResetFilter,
    isFiltersApplied,
    isPracticeFilterVisible,
    isAuthorizedForTeamsOptions,
  } = props;
  const { dateRange } = filters;

  const [error, setError] = useState();

  const updateSelectedFilter = (filterType, filterValue) => {
    if (!filterValue.value) {
      return handleFilterChange({
        ...filters,
        [filterType]: null,
      });
    }

    handleFilterChange({
      ...filters,
      [filterType]: filterValue,
    });
  };

  const handleDaterangeFilterChange = (label, value) => {
    const { dateRange } = filters;
    const newDateRange = { ...dateRange };
    if (label === 'fromDate') {
      newDateRange.fromDate = value.fromDate;
    } else if (label === 'toDate') {
      newDateRange.toDate = value.toDate;
    }

    handleFilterChange({
      ...filters,
      dateRange: newDateRange,
    });
  };

  const handlePayerChange = (selectedPayer) =>
    updateSelectedFilter('payer', selectedPayer);

  const handleAgentChange = (selectedAgent) =>
    updateSelectedFilter('agent', selectedAgent);

  const handleTeamChange = (selectedTeam) =>
    updateSelectedFilter('team', selectedTeam);

  const handlePracticeChange = (selectedPractice) => {
    handleFilterChange({
      ...filters,
      practice: selectedPractice.value ? selectedPractice : null,
      renderingProvider: null,
    });
  };

  const handleRenderingProviderChange = (selectedRenderingProvider) =>
    updateSelectedFilter('renderingProvider', selectedRenderingProvider);

  const appendAllOption = (options) => [
    { value: null, label: 'All' },
    ...options,
  ];

  const getSelectedPropertyOption = (property, filters, filterOptions) => {
    return (
      (filters?.[property]?.value &&
        filterOptions.find(
          (option) => option.value === filters[property].value
        )) ||
      filters[property]
    );
  };

  const selectedPayerOption = getSelectedPropertyOption(
    'payer',
    filters,
    filterOptions.payers
  );

  const selectedAgentOption = getSelectedPropertyOption(
    'agent',
    filters,
    filterOptions.agents
  );

  const selectedTeamOption = getSelectedPropertyOption(
    'team',
    filters,
    filterOptions.teams
  );
  const selectedProviderOption = getSelectedPropertyOption(
    'renderingProvider',
    filters,
    filterOptions.renderingProviders
  );
  const selectedPracticeOption = getSelectedPropertyOption(
    'practice',
    filters,
    filterOptions.practices
  );

  return (
    <div className="ap-dashboard-widget__container d-flex align-item-flex-end">
      <div className="ap-dashboard-filter">
        <div className="ap-dashboard-filter__header">Select Date Range</div>
        <div className="d-flex">
          {/* start date  */}
          <div className="ap-dashboard-filter-time-range__container mr-16">
            <DatePicker
              displayFormat="LL"
              customInputIcon={
                <img className="form-icon" src={calendar} alt="Calendar" />
              }
              isOutsideRange={(d) =>
                isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                d.isAfter(moment(dateRange?.toDate), 'day')
              }
              date={dateRange?.fromDate ? moment(dateRange.fromDate) : moment()}
              placeholder="Start Date"
              verticalSpacing={10}
              onDateChange={(date) => {
                if (!date) {
                  setError({
                    ...error,
                    fromDate: null,
                  });
                }
                date = moment(date);
                if (!date.isValid()) {
                  handleDaterangeFilterChange('fromDate', { fromDate: null });
                  return;
                }
                setError({
                  ...error,
                  fromDate: null,
                });
                handleDaterangeFilterChange('fromDate', {
                  fromDate: date.format(DATE_FORMAT_YYYY_MM_DD),
                });
              }}
              numberOfMonths={1}
              orientation="horizontal"
              block={true}
            />
            {error && error.startDate && (
              <p className="error mt-8">{error.startDate}</p>
            )}
          </div>

          {/* end date  */}
          <div className="ap-dashboard-filter-time-range__container mr-16">
            <DatePicker
              displayFormat="LL"
              customInputIcon={
                <img className="form-icon" src={calendar} alt="Calendar" />
              }
              isOutsideRange={(d) =>
                isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                d.isBefore(moment(dateRange.fromDate), 'day')
              }
              date={dateRange.toDate ? moment(dateRange.toDate) : moment()}
              placeholder="End Date"
              verticalSpacing={10}
              onDateChange={(date) => {
                if (!date) {
                  setError({
                    ...error,
                    toDate: null,
                  });
                }
                date = moment(date);
                if (!date.isValid()) {
                  handleFilterChange('toDate', { toDate: null });
                  return;
                }
                setError({
                  ...error,
                  toDate: null,
                });
                handleDaterangeFilterChange('toDate', {
                  toDate: date.format(DATE_FORMAT_YYYY_MM_DD),
                });
              }}
              numberOfMonths={1}
              orientation="horizontal"
              block={true}
            />
            {error && error.toDate && (
              <p className="error mt-8">{error.toDate}</p>
            )}
          </div>
        </div>
      </div>

      {filterOptions.payers && (
        <div className="ap-dashboard-filter width-140 mr-16">
          <div className="ap-dashboard-filter__header">Payer</div>
          <div className="ap-dashboard-filter__select">
            <Dropdown
              placeholder="All"
              onChange={handlePayerChange}
              options={appendAllOption(filterOptions.payers)}
              value={selectedPayerOption}
              toolTip={selectedPayerOption?.label}
              classNamePrefix=""
              className=""
            />
          </div>
        </div>
      )}

      {filterOptions.practices && isPracticeFilterVisible && (
        <div className="ap-dashboard-filter width-140 mr-16">
          <div className="ap-dashboard-filter__header">Practice</div>
          <div className="ap-dashboard-filter__select">
            <Dropdown
              placeholder="All"
              options={appendAllOption(filterOptions.practices)}
              value={selectedPracticeOption}
              toolTip={selectedPracticeOption?.label}
              onChange={handlePracticeChange}
              datacy="practice-Dropdown"
            />
          </div>
        </div>
      )}

      {filterOptions.agents && (
        <div className="ap-dashboard-filter width-140 mr-16">
          <div className="ap-dashboard-filter__header">Agent</div>
          <div className="ap-dashboard-filter__select">
            <Dropdown
              placeholder="All"
              options={appendAllOption(filterOptions.agents)}
              value={selectedAgentOption}
              toolTip={selectedAgentOption?.label}
              onChange={handleAgentChange}
              datacy="agent-Dropdown"
            />
          </div>
        </div>
      )}

      {filterOptions.teams && isAuthorizedForTeamsOptions && (
        <div className="ap-dashboard-filter width-140 mr-16">
          <div className="ap-dashboard-filter__header">Team</div>
          <div className="ap-dashboard-filter__select">
            <Dropdown
              placeholder="All"
              options={appendAllOption(filterOptions.teams)}
              value={selectedTeamOption}
              toolTip={selectedTeamOption?.label}
              onChange={handleTeamChange}
              datacy="team-Dropdown"
            />
          </div>
        </div>
      )}

      {filterOptions.renderingProviders && (
        <div className="ap-dashboard-filter width-140 mr-16">
          <div className="ap-dashboard-filter__header">Rendering Provider</div>
          <div className="ap-dashboard-filter__select">
            <Dropdown
              placeholder="All"
              options={appendAllOption(filterOptions.renderingProviders)}
              value={selectedProviderOption}
              toolTip={selectedProviderOption?.label}
              onChange={handleRenderingProviderChange}
              classNamePrefix=""
              className=""
              datacy="rendering-provider-Dropdown"
            />
          </div>
        </div>
      )}

      <button
        disabled={!isFiltersApplied}
        className={classnames('ap-dashboard-filter-reset', {
          'visibility-hidden': !isFiltersApplied,
        })}
        onClick={handleResetFilter}
      >
        Reset
      </button>
    </div>
  );
};

export default DashboardFilter;
