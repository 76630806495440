import React from 'react';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';

export const renderReduxMaskedInput = ({
  input,
  showError = false,
  className,
  label,
  mask,
  type,
  placeholder,
  disabled,
  datacy,
  autoFocus = false,
  required = false,
  meta: { touched, error },
  Icon,
}) => (
  <div className={classNames(className)}>
    {label && (
      <label
        htmlFor={input.name}
        className={classNames('textfield__label', {
          'textfield__label--required': required,
        })}
      >
        {label} {required ? '*' : ''}
      </label>
    )}
    <div>
      <span>
        <MaskedInput
          mask={mask}
          tabIndex={0}
          placeholder={placeholder}
          className={classNames(
            'textfield__input',
            {
              'textfield__input--error': error && touched,
            },
            {
              'textfield__input--disabled': disabled,
            }
          )}
          onKeyUp={(e) => {
            e.persist();
            input.onChange(e);
          }}
          value={input.value}
          type={type}
          guide={true}
          autoFocus={autoFocus}
          disabled={disabled}
          datacy={datacy}
        />
        {Icon && <Icon className="masked-input-icon" />}
        {showError && error && <span className="ap-input-error">{error}</span>}
      </span>
    </div>
  </div>
);
