import { Map } from 'immutable';
import _ from 'lodash';

import * as types from '../actions/actionTypes';

const initialState = Map({});

export function deadlineStore(state = initialState, action = {}) {
  let appealID;
  if (_.startsWith(action.type, types.GET_DEADLINE_DATE)) {
    appealID = action.meta.appealID;
  }

  switch (action.type) {
    case `${types.GET_DEADLINE_DATE}_PENDING`:
      return state.set(
        appealID,
        Map({
          isLoading: true,
          denialDate: action.meta.denialDate,
        })
      );
    case `${types.GET_DEADLINE_DATE}_FULFILLED`:
      return state.set(
        appealID,
        Map({
          isLoading: false,
          deadlineDate: action.payload.deadline,
          denialDate: action.meta.denialDate,
        })
      );
    case `${types.GET_DEADLINE_DATE}_REJECTED`:
      return state;
    case `${types.SAVE_DEADLINE_DATE}`:
      return state.set(
        action.appealID,
        Map({
          isLoading: false,
          deadlineDate: action.deadline,
          denialDate: action.denialDate,
        })
      );
    default:
      return state;
  }
}
