import JSZip from 'jszip';
import download from 'downloadjs';
import { PDFDocument } from 'pdf-lib';

import {
  handleErrors,
  handleJSONParse,
  createGetRequest,
  createFormDataRequest,
} from './Config';

import { camelizeKeys } from 'helpers/object';

import * as endpoints from 'constants/endpoints';
import { isUserAuthorizedForOddPageSubmissionPadding } from 'Auth/FeatureFlags';

export default class MergeUtilityAPI {
  static async mergeDocument(formData, mergeType, userInfo) {
    const fileName = (formData && formData.get('upload_file').name) || 'file';
    const request = createFormDataRequest(
      endpoints.CLAIMS_FORM_MERGE_UTILITY,
      formData
    );

    const response = await fetch(request);
    await handleErrors(response);
    const blob = await response.blob();

    const pdfDoc = await PDFDocument.load(await blob.arrayBuffer());
    const pageCount = pdfDoc.getPageCount();

    const pagesPerDocument = isUserAuthorizedForOddPageSubmissionPadding(
      userInfo
    )
      ? 2
      : 1;

    if (pageCount > pagesPerDocument) {
      const zip = new JSZip();
      for (
        let startIndex = 0;
        startIndex < pageCount;
        startIndex += pagesPerDocument
      ) {
        const end = Math.min(pageCount, startIndex + pagesPerDocument);
        const newPdfDoc = await PDFDocument.create();

        for (let i = startIndex; i < end; i++) {
          const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [i]);
          newPdfDoc.addPage(copiedPage);
        }

        const newBlob = new Blob([await newPdfDoc.save()], {
          type: 'application/pdf',
        });
        zip.file(
          `${mergeType}-${fileName}-${Math.ceil(
            (startIndex + 1) / pagesPerDocument
          )}.pdf`,
          newBlob
        );
      }

      const zipBlob = await zip.generateAsync({ type: 'blob' });
      download(zipBlob, `${mergeType}-${fileName}.zip`);
    } else {
      // If the page count does not exceed the required limit, download the merged PDF directly
      download(blob, `${mergeType}-${fileName}.pdf`, 'application/pdf');
    }
  }

  static async getUtilityConfigs(toolType) {
    const request = createGetRequest(endpoints.MERGE_UTILITY_CONFIGS, {
      type: toolType,
    });
    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then(camelizeKeys);
  }
}
