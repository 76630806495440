import SubmitButton from 'components/Shared/SubmitButton';
import React from 'react';

const PayerPortalFormFields = (props) => (
  <div>
    <div className="mb-10 appealio-card row no-gutter">
      <div className="col-lg-8">
        <div className="appealio-card-title mb-8">1. Download Package</div>

        <div className="appealio-card-info">
          Click the following link to download package.
        </div>
      </div>
      <div className="col-lg-4 d-flex flex-direction--column align-item-flex-end">
        <SubmitButton
          className="submit__button submit__button--fullwidth button--bg-primary mb-8"
          title={'Download'}
          type="button"
          disabled={props.isDownloadingPDFPackage}
          onClick={() =>
            props.onDownloadPDFPackage(
              props.submissionId,
              props.downloadFileName
            )
          }
        />
        <span className="info-text pr-1">
          {props.downloadFileName} is ready for download
        </span>
      </div>
    </div>

    <div className="mb-10"></div>

    <div className="appealio-card row mb-30 no-gutter">
      <div className="col-lg-8">
        <div className="appealio-card-title mt-12">
          2. Visit Payer Portal & Submit Standalone
        </div>
      </div>

      <div className="col-lg-4 d-flex flex-direction--column align-item-flex-end mt-8">
        <a
          href={props.payerPortalUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <SubmitButton
            className="submit__button submit__button--fullwidth button--bg-primary"
            title="Open Payer Portal"
            type="button"
          />
        </a>
      </div>
    </div>
  </div>
);

export default PayerPortalFormFields;
