import { Serializer as JSONAPISerializer } from 'jsonapi-serializer';
import { transformDateString } from './Transforms';

export const AppealSerializerTransform = (object) => {
  if (object.deniedAt != null) {
    object.deniedAt = transformDateString(object.deniedAt);
  }
  if (object.injuryDate != null) {
    object.injuryDate = transformDateString(object.injuryDate);
  }

  return object;
};

export const AppealSerializer = new JSONAPISerializer('Appeal', {
  attributes: [
    'claimNumber',
    'employer',
    'step',
    'deadline',
    'deniedAt',
    'injuryDate',
    'statusCode',
    'submittedAt',
    'status',
    'pmSystemAccountId',
    'pageCount',
    'includeEOBDocument',
    'includeCMS1500Document',
  ],
  pluralizeType: false,
  keyForAttribute: 'underscore_case',
});
