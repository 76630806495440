import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AvailityClaimStatusCheck from './AvailityClaimStatusCheck';
import { closeAvailityClaimStatusWidget } from 'redux/actions/availityClaimStatusWidgetActions';

const AvailityClaimStatusCheckWidget = (props) => {
  const { data, isOpen } = props;
  if (!isOpen) return null;

  return (
    <AvailityClaimStatusCheck
      onCloseClick={props.actions.closeAvailityClaimStatusWidget}
      data={data}
    />
  );
};

AvailityClaimStatusCheckWidget.propTypes = {};

const mapStateToProps = (state) => {
  const { isOpen, data } = state
    .get('availityClaimStatusStoreWidgetStore')
    .toJS();
  return {
    isOpen,
    data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        closeAvailityClaimStatusWidget,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailityClaimStatusCheckWidget);
