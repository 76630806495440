import React from 'react';
import classnames from 'classnames';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';

import closeIcon from '../../img/close-modal-white.svg';

import ScreenDimmerComponent from './ScreenDimmerComponent';

const ContainerComponent = (props) => {
  if (props.useDimmerComponent) {
    return (
      <ScreenDimmerComponent className="custom-dimmer">
        {props.children}
      </ScreenDimmerComponent>
    );
  }

  return <div>{props.children}</div>;
};

const AppealioPopup = (props) => {
  const {
    useDimmerComponent = true,
    titleClassName = '',
    showMinimizeMaximizeButton = false,
    isMinimized = false,
    onToggleMinimizeMaximize = () => {},
    isPopupAboveFlyover = false,
  } = props;

  return (
    <ContainerComponent useDimmerComponent={useDimmerComponent}>
      <div
        className={classnames('appealio-popup', {
          [props.className]: props.className,
          'appealio-popup--custom-dimmer': isPopupAboveFlyover,
        })}
        datacy="appealio-popup"
      >
        <div className={classnames('appealio-popup-title', titleClassName)}>
          {props.title}

          <div className="d-flex">
            {showMinimizeMaximizeButton &&
              (!isMinimized ? (
                <FiMinimize2
                  title="Minimize"
                  className="appealio-popup-minimize-maximize"
                  onClick={onToggleMinimizeMaximize}
                  datacy="appealio-popup-FiMinimize2"
                />
              ) : (
                <FiMaximize2
                  title="Maximize"
                  className="appealio-popup-minimize-maximize"
                  onClick={onToggleMinimizeMaximize}
                  datacy="appealio-popup-FiMaximize2"
                />
              ))}
            {props.onClosePressed && (
              <img
                alt="Close"
                onClick={() => props.onClosePressed()}
                className="appealio-popup-close ml-8"
                src={closeIcon}
              />
            )}
          </div>
        </div>
        <div className="appealio-popup-content">{props.children}</div>
      </div>
    </ContainerComponent>
  );
};

export default AppealioPopup;
