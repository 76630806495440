import React from 'react';

const DenialActionHeader = () => (
  <div className="row mb-16 create-acount-list-item-header">
    <div className="col-lg-3">Action Name</div>
    <div className="col-lg-3 d-flex align-items-center">Auto-Reminder</div>
    <div className="col-lg-3">Reminder Note</div>
    <div className="col-lg-2">PM Note Template</div>
    <div className="col-lg-1">&nbsp;</div>
  </div>
);

export default DenialActionHeader;
