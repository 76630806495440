import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DownloadIcon from '../../../../img/download.svg';
import OneLineInfo from './OneLineInfo';
import _ from 'lodash';
import { readAmountsFromCPTForm } from '../../../CreateAppeal/DenialInformation/CPTRowConfig';
import Immutable from 'immutable';
import { moneyFormatter } from '../../../../helpers/money';
import { APIConfig, secureDownload } from '../../../../API/Config';

import * as toast from 'components/Shared/toast';

class AppealClaims extends Component {
  static propTypes = {
    appeal: PropTypes.object.isRequired,
    formattedDocuments: PropTypes.array.isRequired,
    formattedLetters: PropTypes.array.isRequired,
    formattedDeadline: PropTypes.string.isRequired,
  };

  renderDocuments = () => {
    if (this.props.formattedDocuments.length > 0) {
      return (
        <div className="col-xs-6">
          <div className="tabbar__title">Documents</div>
          <ul className="tabbar__list">
            {this.getList(this.props.formattedDocuments)}
          </ul>
        </div>
      );
    }
  };

  downloadAllDocuments = () => {
    this.clickAllLinks(
      this.props.formattedLetters.length,
      this.props.formattedLetters[0].prefix
    );
    if (typeof this.props.formattedDocuments.length !== 'undefined') {
      this.clickAllLinks(
        this.props.formattedDocuments.length,
        this.props.formattedDocuments[0].prefix
      );
    }
  };

  clickAllLinks(range, prefix) {
    _.range(0, range).forEach((item, index) => {
      this.timeout = setTimeout(
        // eslint-disable-next-line react/no-string-refs
        () => this.refs[`${prefix}${index}`].click(),
        index * 500
      );
    });
  }

  getList(listArray) {
    return listArray.map((item, index) => {
      return (
        <li className="tabbar__list-iten" key={index}>
          <span
            className="tabbar__list-item--link"
            // eslint-disable-next-line react/no-string-refs
            ref={`${item.prefix}${index}`}
            onClick={() => secureDownload(item.link, item.title)}
          >
            {item.title}
          </span>
        </li>
      );
    });
  }

  downloadPdfFile = async () => {
    try {
      await secureDownload(
        'final-package/',
        APIConfig.processParams(),
        'final-package.pdf',
        {
          method: 'GET',
          params: { appeal: this.props.appeal.id },
        }
      );
    } catch (e) {
      return toast.error({
        message: 'Package is not available at the moment',
        title: 'Error',
      });
    }
  };

  getListForLetters(listArray) {
    return listArray.map((item, index) => {
      return (
        <li className="tabbar__list-iten" key={index}>
          <span
            className="tabbar__list-item--link"
            ref={`${item.prefix}${index}`}
            onClick={() => secureDownload(item.link, item.params)}
          >
            {item.title}
          </span>
        </li>
      );
    });
  }

  renderSubmissionDetails() {
    if (!this.props.formattedSubmissionDate) {
      return '';
    }

    const mailingAddress = _.get(this.props.appeal, 'mailingAddress', '');
    const faxNumber = _.get(this.props.appeal, 'faxNumber', '');
    const submittedTo = faxNumber || mailingAddress;

    return (
      <React.Fragment>
        <div className="tabbar__title tabbar__title-margin-top tabbar__line">
          Submission Details
        </div>
        <OneLineInfo
          title={'Submission Date'}
          value={this.props.formattedSubmissionDate}
        />
        {submittedTo && (
          <OneLineInfo
            title={faxNumber ? 'Fax Number' : 'Mailing Address'}
            value={submittedTo}
          />
        )}
      </React.Fragment>
    );
  }

  render() {
    const {
      appeal: { isMedicalRecordsSubmission },
    } = this.props;
    return (
      <div title="Claims" className="tabbar__row-no-padding">
        <div className="tabbar__title">
          {isMedicalRecordsSubmission ? 'Record' : 'Claim'} Overview
        </div>
        {this.props.appeal.submitterClaimId && (
          <div className="tabbar__id tabbar__id--bold mb-0">
            Submitter Claim ID: {this.props.appeal.submitterClaimId}
          </div>
        )}
        <div className="tabbar__id tabbar__id--bold ">
          Payer Claim ID: {this.props.appeal.claimNumber}
        </div>
        <OneLineInfo
          title="Payer"
          value={_.get(this.props.appeal, 'payer.name', '')}
        />
        <OneLineInfo title="Agent" value={this.props.appeal.agentName} />
        <OneLineInfo
          title="Author"
          value={this.props.appeal.authorName || '-'}
        />
        {this.renderAmounts()}
        <div className="tabbar__title tabbar__title-margin-top tabbar__line">
          Current Deadline
        </div>
        <div className="tabbar__row-text">{this.props.formattedDeadline}</div>
        {this.renderSubmissionDetails()}
        <div className="tabbar__claim-footer-download">
          <img
            alt="Download"
            className="tabbar__claim-footer-download--image"
            src={DownloadIcon}
          />
          <span
            className="tabbar__claim-footer-download--text"
            onClick={this.downloadPdfFile}
          >
            Download Submitted Package
          </span>
        </div>
      </div>
    );
  }

  renderAmounts() {
    const {
      formattedBilledAmount,
      formattedTotalDenied,
      appeal: { isMedicalRecordsSubmission },
    } = this.props;
    return (
      <React.Fragment>
        {!isMedicalRecordsSubmission && (
          <div>
            <OneLineInfo title="Total Denied" value={formattedTotalDenied} />
            <OneLineInfo title="Total Billed" value={formattedBilledAmount} />
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const cpts = Immutable.fromJS(ownProps.appeal.eob.procedures);
  const sums = readAmountsFromCPTForm(cpts);
  const formattedTotalDenied = moneyFormatter().format(sums.totalDeniedAmount);
  const formattedBilledAmount = moneyFormatter().format(sums.billedAmount);

  return {
    formattedBilledAmount,
    formattedTotalDenied,
  };
}

export default connect(mapStateToProps, null)(AppealClaims);
