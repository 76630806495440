import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import './style.css';

import closeIcon from 'img/close-modal-white.svg';
import manualDenialClaimIcon from 'img/manual-claim-dq.svg';

import BatchUpload from './BatchUpload';
import Tabs from 'components/common/tabs';
import AddSingleClaimForm from './AddSingleClaim';
import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';
import ConfirmExitReviewAndUpload from './BatchUpload/ReviewAndUpload/Popups/ConfirmTabChangePopup';

import { isClientAdmin } from 'Auth/AuthUtils';
import { isUserAuthorizedForBatchUploadManualClaim } from 'Auth/FeatureFlags';

const MANUAL_UPLOAD_TABS = {
  BATCH_UPLOAD: 'BATCH_UPLOAD',
  ADD_SINGLE_CLAIM: 'ADD_SINGLE_CLAIM',
};

const AddManualClaimPopup = ({
  closeAddManualClaimPopup,
  dropdownOptions,
  userInfo,
  fetchDenials,
}) => {
  const [activeTab, setActiveTab] = useState(
    MANUAL_UPLOAD_TABS.ADD_SINGLE_CLAIM
  );
  const [isBatchFileUploaded, setIsBatchFileUploaded] = useState(false);
  const [showConfirmStepChangePopup, setShowConfirmStepChangePopup] =
    useState(false);

  const setTabToAddSingleClaim = () => {
    setActiveTab(MANUAL_UPLOAD_TABS.ADD_SINGLE_CLAIM);
    setIsBatchFileUploaded(false);
  };

  const handleAddSingleClaimClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isBatchFileUploaded) {
        setShowConfirmStepChangePopup(true);
        return;
      }
      setTabToAddSingleClaim();
    },
    [isBatchFileUploaded]
  );

  const handleBatchUploadClick = useCallback(() => {
    setActiveTab(MANUAL_UPLOAD_TABS.BATCH_UPLOAD);
  }, []);

  const tabs = useMemo(() => {
    const defaultTabs = [
      {
        title: 'Add Single Claim',
        onClick: handleAddSingleClaimClick,
        isActive: activeTab === MANUAL_UPLOAD_TABS.ADD_SINGLE_CLAIM,
      },
    ];

    if (!isUserAuthorizedForBatchUploadManualClaim(userInfo)) {
      return defaultTabs;
    }

    return [
      ...defaultTabs,
      {
        title: 'Batch Upload',
        disabled: !isClientAdmin(userInfo),
        onClick: handleBatchUploadClick,
        isActive: activeTab === MANUAL_UPLOAD_TABS.BATCH_UPLOAD,
        dataTip: !isClientAdmin(userInfo)
          ? 'Only Client Admins can upload batch files'
          : null,
      },
    ];
  }, [activeTab, handleAddSingleClaimClick, handleBatchUploadClick, userInfo]);

  return (
    <ScreenDimmerComponent>
      <div className="appealio-popup appealio-action-popup add-manual-claim-popup">
        <div className="appealio-action-popup__header">
          <h4 className="appealio-action-popup__title">
            <img
              src={manualDenialClaimIcon}
              className="mr-4"
              alt="Add Claim to Denial Queue"
            />
            Add Claim to Denial Queue
          </h4>
          <img
            alt="Close"
            onClick={closeAddManualClaimPopup}
            className="close-icon"
            src={closeIcon}
          />
        </div>
        <Tabs className="add-manual-claim-popup__tabs" tabs={tabs} />
        <div className="add-manual-claim-popup__content p-32 pb-10">
          {activeTab === MANUAL_UPLOAD_TABS.ADD_SINGLE_CLAIM ? (
            <AddSingleClaimForm
              closeAddManualClaimPopup={closeAddManualClaimPopup}
              dropdownOptions={dropdownOptions}
              fetchDenials={fetchDenials}
            />
          ) : (
            <BatchUpload
              closeAddManualClaimPopup={closeAddManualClaimPopup}
              setIsBatchFileUploaded={setIsBatchFileUploaded}
              dropdownOptions={dropdownOptions}
            />
          )}
        </div>
      </div>
      {showConfirmStepChangePopup && (
        <ConfirmExitReviewAndUpload
          onCancelClick={() => setShowConfirmStepChangePopup(false)}
          onConfirmClick={() => {
            setShowConfirmStepChangePopup(false);
            setTabToAddSingleClaim();
          }}
        />
      )}
    </ScreenDimmerComponent>
  );
};

AddManualClaimPopup.propTypes = {
  closeAddManualClaimPopup: PropTypes.func.isRequired,
  dropdownOptions: PropTypes.array,
};

export default AddManualClaimPopup;
