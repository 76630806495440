import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class TitleHeader extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div
        className="row appeal-header__row-no-padding appeal-header"
        datacy="title-header"
      >
        <div className="col-xs-4 appeal-header__text-container">
          <span className="appeal-header__left-text">{this.props.title}</span>
        </div>
      </div>
    );
  }
}
