import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class CardView extends Component {
  static propTypes = {
    children: PropTypes.array.isRequired,
  };

  render() {
    return (
      <div
        className="card col-xs-8 col-xs-offset-2 col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4"
        datacy="card-view"
      >
        <div className="card__content">{this.props.children}</div>
      </div>
    );
  }
}
