import Immutable from 'immutable';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router/immutable';

import { history } from './history';
import rootReducer from '../reducers';
import { apiConfigMiddleware } from '../../API/Config';

const routingMiddleware = routerMiddleware(history);

const initialState = Immutable.Map();

export default function configureStore() {
  const store = createStore(
    rootReducer(history),
    initialState,
    compose(
      applyMiddleware(thunkMiddleware),
      applyMiddleware(promiseMiddleware()),
      applyMiddleware(routingMiddleware),
      applyMiddleware(apiConfigMiddleware())
    )
  );

  return store;
}
