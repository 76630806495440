import { Map } from 'immutable';
import {
  SET_SHIPPING_ADDRESS,
  RESET_SHIPPING_ADDRESS,
} from 'redux/actions/actionTypes';

const initialState = Map({
  submissionInfo: Map({}),
});

export const appealFormPrefillStore = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHIPPING_ADDRESS:
      return state.setIn(['submissionInfo'], action.payload.shippingAddress);
    case RESET_SHIPPING_ADDRESS:
      return state.setIn(['submissionInfo'], Map({}));
    default:
      return state;
  }
};
