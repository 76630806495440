/**
 * Created by alex on 10/19/16.
 */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { replace } from 'connected-react-router';

import BackButton from './BackButton';
import SubmitButton from './SubmitButton';
import Button from 'components/common/button';
import LoadingBlockHelper from './LoadingBlockHelper';
import { BUTTON_TYPE } from 'components/common/button/Button';

import previousIcon from '../../img/ic-chevron-back.svg';

class SaveContinueFooterComponent extends Component {
  static propTypes = {
    onSavePressed: PropTypes.func,
    onCancelPressed: PropTypes.func,
    onDiscardPressed: PropTypes.func,
    onPreviousPressed: PropTypes.func,
    onCustomPressed: PropTypes.func,
    submitBtnTitle: PropTypes.string,
    previousBtnTitle: PropTypes.string,
    submitDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    backButtonClassName: PropTypes.string,
    submitButtonClassName: PropTypes.string,
    previousButtonClassName: PropTypes.string,
    shouldShowPreviousButton: PropTypes.bool,
    shouldShowCancelButton: PropTypes.bool,
    shouldShowCustomButton: PropTypes.bool,
    customButtonTitle: PropTypes.string,
    shouldShowSubmitBtn: PropTypes.bool,
    disableCustomButton: PropTypes.bool,
    shouldShowDiscardAndSkipButton: PropTypes.bool,
  };

  static defaultProps = {
    submitBtnTitle: 'Continue',
    previousBtnTitle: 'Previous',
    discardBtnTitle: 'Discard',
    submitDisabled: false,
    isLoading: false,
    shouldShowPreviousButton: false,
    shouldShowCancelButton: true,
    backButtonClassName: 'col-xs-5 footer__cancel',
    previousButtonClassName: 'col-xs-offset-4 col-xs-3',
    submitButtonClassName: 'col-xs-7',
    shouldShowCustomButton: false,
    shouldShowSubmitBtn: true,
  };

  render() {
    const {
      shouldShowPreviousButton,
      shouldShowCancelButton,
      shouldShowCustomButton,
      shouldShowDiscardAndSkipButton,
    } = this.props;
    const isShowingMoreThanOneButton =
      shouldShowPreviousButton ||
      shouldShowCancelButton ||
      shouldShowCustomButton ||
      shouldShowDiscardAndSkipButton;
    return (
      <div
        className="save_continue__wrapper"
        datacy="save-continue-footer-component"
      >
        <LoadingBlockHelper isLoading={this.props.isLoading}>
          <div className="clearfix">
            {this.renderPreviousBtn()}
            <div
              className={classNames({
                'footer_float-right': isShowingMoreThanOneButton,
                'd-flex': this.props.shouldShowDiscardAndSkipButton,
              })}
            >
              {this.renderDiscardAndSkipButton()}
              {this.renderCustomButton()}
              {this.renderCancelButton()}
              {this.props.shouldShowSubmitBtn && (
                <div className="footer__button">
                  <SubmitButton
                    disabled={this.props.submitDisabled}
                    title={this.props.submitBtnTitle}
                    onClick={this.props.onSavePressed}
                    datacy="save-continue-footer-SubmitButton"
                  />
                </div>
              )}
            </div>
          </div>
        </LoadingBlockHelper>
      </div>
    );
  }

  renderCustomButton() {
    if (this.props.shouldShowCustomButton === true) {
      return (
        <div className="footer__button footer__cancel">
          <BackButton
            disabled={this.props.disableCustomButton}
            onClick={this.props.onCustomPressed}
            title={this.props.customButtonTitle}
            datacy="save-continue-footer-custom-BackButton"
          />
        </div>
      );
    }
  }

  renderCancelButton() {
    if (this.props.shouldShowCancelButton === true) {
      return (
        <div className="footer__button footer__cancel">
          <BackButton
            onClick={
              this.props.onCancelPressed || this.props.actions.goToDashboard
            }
            datacy="save-continue-footer-cancel-BackButton"
          />
        </div>
      );
    }
  }

  renderDiscardAndSkipButton() {
    if (
      this.props.shouldShowDiscardAndSkipButton === true &&
      !this.props.shouldShowDiscardButton
    ) {
      return (
        <div className="footer__button">
          <Button
            title={this.props.discardAndSkipBtnTitle}
            type={BUTTON_TYPE.LIGHT}
            className="footer__cancel--skip-btn justify-content-center fw-normal mr-16"
            onClick={this.props.onDiscardAndSkipPressed}
            datacy="discard-skip-Button"
            buttonType="button"
          />
        </div>
      );
    }
  }

  renderPreviousBtn() {
    if (!this.props.shouldShowPreviousButton) return;

    return (
      <div className="footer_float-left">
        <SubmitButton
          imgSrc={previousIcon}
          type="button"
          className="footer__previous"
          title={this.props.previousBtnTitle}
          onClick={this.props.onPreviousPressed}
          datacy="save-continue-footer-previous-SubmitButton"
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  const replaceAction = bindActionCreators({ replace }, dispatch).replace;
  const goToDashboard = () => replaceAction('/dashboard');
  return {
    actions: { goToDashboard },
  };
}

export default connect(null, mapDispatchToProps)(SaveContinueFooterComponent);
