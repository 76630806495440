import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';

import './scheduleInfo.css';

import Input from 'components/common/input';
import Button from 'components/common/button';
import * as toast from 'components/Shared/toast';
import Select from 'components/common/select/Select';

import Clock from 'img/tracker-clock.svg';

import { toTitleCase } from 'helpers/title_case';

import { VALIDATION_DATE_FORMAT } from 'helpers/dateFormats';
import { CLAIM_STATUS_CHECK_CRON_OPTIONS } from 'constants/options';

const MAXIMUM_ATTEMPTS = {
  MIN: 1,
  MAX: 20,
};
const ScheduleInformation = (props) => {
  const {
    appealioClaimSchedule,
    handleSubmit,
    onSubmit,
    hideHistoryView = false,
    onViewHistoryClick,
  } = props;
  const nextScheduledCheck = !appealioClaimSchedule.nextScheduledAt
    ? 'Tomorrow'
    : moment(appealioClaimSchedule.nextScheduledAt).format(
        VALIDATION_DATE_FORMAT
      );

  const [showScheduleForm, setShowScheduleForm] = useState(false);
  const toggleScheduleForm = () => setShowScheduleForm(!showScheduleForm);

  const handleFormSubmit = async (formValues) => {
    if (!formValues.get('schedule')) {
      return toast.error({
        title: '',
        message: 'Please select a schedule.',
      });
    }
    await onSubmit(formValues);
    setShowScheduleForm(false);
  };

  const statusCheckOption = CLAIM_STATUS_CHECK_CRON_OPTIONS.find(
    (option) => option.value === appealioClaimSchedule.schedule
  );
  const scheduledTitle = statusCheckOption ? statusCheckOption.label : '';

  return (
    <div className="status-schedule-info">
      <object
        className="status-schedule-info__icon"
        data={Clock}
        type="image/svg+xml"
      >
        Info Icon
      </object>
      <div className="status-schedule-info__wrapper">
        <table className="status-schedule-info-table">
          <thead>
            <tr>
              <th>Next Scheduled Check</th>
              <th>Remaining</th>
              <th>At an interval of</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {appealioClaimSchedule.schedule ? nextScheduledCheck : 'N/A'}
              </td>
              <td>
                {appealioClaimSchedule.schedule
                  ? appealioClaimSchedule.noOfRetries
                  : 'N/A'}
              </td>
              <td>
                {appealioClaimSchedule.schedule
                  ? toTitleCase(scheduledTitle)
                  : 'N/A'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {!showScheduleForm ? (
        <div className="status-schedule-info__action">
          {!hideHistoryView && (
            <button onClick={onViewHistoryClick}>View History</button>
          )}
          <button onClick={toggleScheduleForm}>Edit</button>
        </div>
      ) : (
        <div className="status-schedule-info__schedule-form">
          <form
            className="d-flex align-items-center"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <div className="claim-status-tracker__schedule-times">
              <Field
                component={Input}
                name="noOfRetries"
                className="custom-input"
                type="number"
                min={MAXIMUM_ATTEMPTS.MIN}
                max={MAXIMUM_ATTEMPTS.MAX}
              />
            </div>
            <div className="d-flex align-items-center schedule-info__days_select ml-4">
              <Field
                component={Select}
                options={CLAIM_STATUS_CHECK_CRON_OPTIONS}
                name="schedule"
                placeholder=""
                reactSelectProps={{
                  menuPlacement: 'auto',
                }}
                datacy="schedule-days"
              />
            </div>
            <Button type="secondary" className="ml-4">
              Confirm
            </Button>
            <Button
              type="light"
              className="cancel-btn ml-4"
              onClick={() => setShowScheduleForm(false)}
            >
              Cancel
            </Button>
          </form>
        </div>
      )}
    </div>
  );
};

ScheduleInformation.propTypes = {
  appealioClaimSchedule: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'update-claim-status-schedule-form',
  enableReinitialize: true,
})(ScheduleInformation);
