import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form/immutable';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { validate, renderField } from './inputs';
import SubmitButton from '../Shared/SubmitButton';
import LoadingBlockHelper from '../Shared/LoadingBlockHelper';
import { resetPassword } from '../../redux/actions/loginActions';
import Recaptcha from 'react-recaptcha';

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVerified: false,
    };
  }

  static propTypes = {
    onPasswordReset: PropTypes.func.isRequired,
  };

  reCaptchaLoaded() {
    //
  }

  verifyCallback = (res) => {
    if (res) {
      this.setState({
        isVerified: true,
      });
    }
  };

  handleCaptchaExpired = () => {
    this.setState({
      isVerified: false,
    });
  };

  render() {
    const siteKey = process.env.REACT_APP_RECAPTCHA_KEY;
    const { error, handleSubmit, pristine, submitting, invalid } = this.props;

    return (
      <div>
        <div>
          <Field
            label="New Password"
            showCheckmarkIfOk={false}
            type="password"
            name="password"
            datacy="new-password-Field"
            component={renderField}
          />
        </div>

        <div>
          <Field
            label="Retype Password"
            showCheckmarkIfOk={false}
            type="password"
            name="confirm_password"
            datacy="retype-password-Field"
            component={renderField}
          />
        </div>

        {error && (
          <div className="login__error-wrapper">
            {' '}
            <strong className="login__error">{error}</strong>{' '}
          </div>
        )}

        <div className="submit__button-wrapper">
          <LoadingBlockHelper isLoading={submitting}>
            {this.state.isVerified && (
              <SubmitButton
                onClick={handleSubmit(this.submit)}
                className="submit__button submit__button--fullwidth mb-16"
                title={`${
                  this.props.isCreatePasswordForm ? 'Create' : 'Change'
                } Password`}
                disabled={submitting || pristine || invalid}
                datacy="reset-password-SubmitButton"
              />
            )}
            <Recaptcha
              sitekey={siteKey}
              render="explicit"
              verifyCallback={this.verifyCallback}
              onloadCallback={this.reCaptchaLoaded}
              expiredCallback={this.handleCaptchaExpired}
              expiredCallbackName="handleCaptchaExpired"
            />
          </LoadingBlockHelper>
        </div>
      </div>
    );
  }

  submit = (values) => {
    if (this.state.isVerified) {
      if (values.get('password') !== values.get('confirm_password')) {
        return Promise.resolve({}).then(() => {
          throw new SubmissionError({ _error: 'Passwords do not match.' });
        });
      } else {
        const { uid, token } = this.props;
        const newPassword = values.get('password');

        return this.props.actions
          .resetPassword(
            uid,
            token,
            newPassword,
            this.props.isCreatePasswordForm
          )
          .then((res) => {
            this.props.onPasswordReset();
          })
          .catch(async (e) => {
            const {
              new_password: newPassword,
              non_field_errors: nonFieldErrors,
            } = await e.response.json();

            if (newPassword) {
              throw new SubmissionError({ _error: newPassword.join() });
            }
            if (nonFieldErrors) {
              throw new SubmissionError({
                _error: `Your password ${
                  this.props.isCreatePasswordForm ? 'create' : 'reset'
                } link has expired. Please try again.`,
              });
            }

            throw new SubmissionError({
              _error: 'Error while resetting password. Please try again.',
            });
          });
      }
    }
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ resetPassword }, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: 'reset_password',
    validate,
  })(ResetPasswordForm)
);
