import React from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import infoIcon from '../../../../img/info.svg';
import { moneyFormatter } from '../../../../helpers/money';
import { getUserInfo } from '../../../../redux/reducers/loginStore';

const ProcedureRow = ({
  procedure,
  index,
  onChangeQuantity,
  removeProcedure,
  clientName,
}) => {
  const debounceQuantity = debounce((value) => {
    onChangeQuantity(value, procedure.code);
  }, 3000);

  const renderValue = (value, showValue = false, displayMessage = 'N/A') => {
    if (procedure.message === 'Not Estimated' && !showValue) {
      return <span className="color-red">{displayMessage}</span>;
    }

    return value;
  };

  const extractCalculationMessage = (message) => {
    if (message === 'Not Estimated') {
      return `${clientName} Default`;
    }

    return message;
  };

  return (
    <tr key={index}>
      <td>{++index}</td>
      <td>{procedure.code}</td>
      <td>
        <input
          type="number"
          name="quantity"
          className="cost-estimator-create-estimates-table__input cost-estimator-create-estimates-table__input--sm "
          defaultValue={procedure.quantity}
          onChange={(e) => {
            debounceQuantity(e.target.value);
          }}
          datacy="procedure-row-quantity-input"
        />
      </td>
      <td className="table__description-cell">
        <span data-tip={procedure.description}>{procedure.description}</span>
        <ReactTooltip effect="solid" place="right" arrowColor="transparent" />
      </td>
      <td className="text-right">
        <span>
          {renderValue(
            procedure.predictedAmount !== null
              ? moneyFormatter().format(procedure.predictedAmount || 0)
              : 'Unavailable',
            false,
            'Unavailable'
          )}
        </span>
      </td>
      <td className="text-right">
        <span>
          {renderValue(
            procedure.deductibleApplied !== null
              ? moneyFormatter().format(procedure.deductibleApplied || 0)
              : 'Unavailable',
            true
          )}
        </span>
      </td>
      <td className="text-right">
        <span>
          {renderValue(
            procedure.coinsurance.amount !== null
              ? moneyFormatter().format(procedure.coinsurance.amount || 0)
              : 'Unavailable'
          )}
        </span>
      </td>
      <td className="text-right">
        <span>
          {renderValue(
            procedure.copaymentAmount !== null
              ? moneyFormatter().format(procedure.copaymentAmount || 0)
              : 'Unavailable'
          )}
        </span>
      </td>
      <td className="text-center">
        <span>
          {renderValue(
            procedure.coinsurance.percentage !== null
              ? `${Number(procedure.coinsurance.percentage || 0)}%`
              : 'Unavailable'
          )}
        </span>
      </td>
      <td className="table__highlighted-cell text-right">
        <span className="d-flex justify-content-flex-end">
          {moneyFormatter().format(procedure.patientCost || 0)}{' '}
          {procedure.message === 'Not Estimated' && (
            <React.Fragment>
              <img
                data-for={`procedure-row-${index}`}
                src={infoIcon}
                alt="info"
                data-tip={extractCalculationMessage(procedure.message)}
                className="ml-10 no-print"
              />
              <ReactTooltip id={`procedure-row-${index}`} effect="solid" />
            </React.Fragment>
          )}{' '}
        </span>
      </td>
      <td>
        <span
          className="btn btn--link btn--link-secondary no-print"
          onClick={() => removeProcedure(procedure.code)}
        >
          Remove
        </span>
      </td>
    </tr>
  );
};

ProcedureRow.propTypes = {
  procedure: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  removeProcedure: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { clientName = 'Client' } = getUserInfo(state);

  return {
    clientName,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureRow);
