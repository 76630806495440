import { useCallback, useEffect, useState } from 'react';

import { fetchTeam, fetchTeams } from 'API/AccountSettingsAPI';

/**
 * Custom hook to fetch teams.
 *
 * @param {number} page - The page number to fetch.
 * @returns {Object} - An object containing loading state, error, fetch function, total teams, and teams data.
 */
export const useFetchTeams = (page) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalTeams, setTotalTeams] = useState(0);
  const [teams, setTeams] = useState([]);

  /**
   * Fetches teams  data.
   */
  const fetch = useCallback(async () => {
    setLoading(true);
    setTeams([]);
    setTotalTeams(0);

    try {
      const queryParam = page ? { page } : {};

      const { data, meta } = await fetchTeams(queryParam);
      setTeams(data);
      setTotalTeams(meta.total);
    } catch (fetchError) {
      setError(fetchError);
    } finally {
      setLoading(false);
    }
  }, [page]);

  useEffect(() => {
    fetch();
  }, [page, fetch]);

  return {
    loading,
    error,
    fetch,
    totalTeams,
    teams,
  };
};

/**
 * Custom hook to fetch a single team by its ID.
 *
 * @param {string|number} id - The ID of the team to fetch.
 * @returns {Object} - An object containing loading state, error, fetch function, and team data.
 */
export const useFetchTeam = (id) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [team, setTeam] = useState(null);

  /**
   * Fetches a single team data by its ID.
   */
  const fetch = useCallback(async () => {
    setLoading(true);
    setTeam(null);

    try {
      const { data } = await fetchTeam(id);
      setTeam(data);
    } catch (fetchError) {
      setError(fetchError);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetch();
  }, [id, fetch]);

  return { loading, error, fetch: fetchTeam, team };
};
