import {
  createRequest,
  createGetRequest,
  handleErrors,
  handleJSONParse,
  BASE_API_URL,
} from './Config';

import { MANAGE_USERS } from 'constants/endpoints';

import { camelizeKeys, snakeCaseKeys } from '../helpers/object';

export const fetchUsersAccessControl = (queryParam) => {
  const request = createGetRequest(
    MANAGE_USERS,
    {
      ...queryParam,
    },
    BASE_API_URL,
    true
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const updateUserAccessControl = (id, data) => {
  const request = createRequest(
    `${MANAGE_USERS}${id}/`,
    {
      ...snakeCaseKeys(data),
    },
    {
      baseUrl: BASE_API_URL,
      isBearer: true,
      method: 'PUT',
    }
  );

  return fetch(request).then(handleErrors);
};
