import React from 'react';

import Widget from '../Widget';
import TopPayersTable from './TopPayersTable';

const TopPayers = (props) => {
  const {
    filters,
    setSelectedThreePayerIds,
    selectedThreePayerIds,
    setPayersId,
    isFetchingData,
    setIsFetchingData,
    payersDropdownOptions,
    setPayerNotInTopPayerList,
    setIsTopPayersLoading,
  } = props;

  return (
    <Widget
      widgetTitle={{
        title: 'Top Payers by Total Allowed Amount',
        titleClassName: 'v3__widget-title--lg',
      }}
      className="v3__analytics-widget--no-radius-right"
    >
      <TopPayersTable
        filters={filters}
        setSelectedThreePayerIds={setSelectedThreePayerIds}
        selectedThreePayerIds={selectedThreePayerIds}
        setPayersId={setPayersId}
        isFetchingData={isFetchingData}
        setIsFetchingData={setIsFetchingData}
        payersDropdownOptions={payersDropdownOptions}
        setPayerNotInTopPayerList={setPayerNotInTopPayerList}
        setIsTopPayersLoading={setIsTopPayersLoading}
      />
    </Widget>
  );
};

TopPayers.propTypes = {};

export default TopPayers;
