export const STATUS_OPTIONS = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'In Transit',
    value: 'In Transit',
  },
  {
    label: 'Delivered',
    value: 'Delivered',
  },
  {
    label: 'Under Review',
    value: 'Under Review',
  },
  {
    label: 'Queued',
    value: 'Queued',
  },
];

export const STATUS_OPTIONS_TYPE = {
  IN_TRANSIT: 'In Transit',
  DELIVERED: 'Delivered',
  UNDER_REVIEW: 'Under Review',
  QUEUED: 'Queued',
};

export const METHOD_OPTIONS = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Mail',
    value: 'mail',
  },
  {
    label: 'Mail (Certified)',
    value: 'certified',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Fax',
    value: 'fax',
  },
  {
    label: 'Direct',
    value: 'direct',
  },
  {
    label: 'Payer Portal',
    value: 'portal',
  },
];

export const TYPE_OPTIONS = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Appeal',
    value: 'Appeal',
  },
  {
    label: 'Reconsideration',
    value: 'Reconsideration',
  },
  {
    label: 'Record',
    value: 'Record',
  },
  {
    label: 'Standalone',
    value: 'Standalone',
  },
];
