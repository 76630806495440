import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import React, { useState, createRef } from 'react';

import './style.css';

import { mimeTypes, ZIP_MIME_TYPES } from 'helpers/mimeTypes';

import * as toast from 'components/Shared/toast';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';
import SelectFileToUploadButton from 'components/CreateAppeal/DocumentsUpload/SelectFileToUploadButton';
import S3FileUploader from 'components/CreateAppeal/DocumentsUpload/ContentProcessing/S3FileUploader';

import fileIcon from 'img/file.svg';
import xIcon from 'img/close-copy.svg';
import closeIcon from 'img/close-modal.svg';
import placeholderImg from 'img/document-drop.png';

import DocumentsUploadAPI from 'API/DocumentsUploadAPI';

const UPLOAD_LIMIT = {
  ZIP: 1,
  ALERT: 10,
  MAX: 50,
};

const ManualERAUpload = () => {
  const dropzoneRef = createRef();
  const [files, setFiles] = useState([]);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const acceptedTypes = [
    mimeTypes.TEXT_PLAIN,
    mimeTypes.APPLICATION_ZIP,
    ZIP_MIME_TYPES.OCTET_STREAM,
    ZIP_MIME_TYPES.X_COMPRESSED,
    ZIP_MIME_TYPES.MULTIPART_X_ZIP,
    ZIP_MIME_TYPES.X_ZIP_COMPRESSED,
    'EDI',
    '835',
    'DAT',
    'RMT',
    'ZIP',
  ];

  const onDrop = (files) => {
    if (files.length === 0) {
      toast.error({
        title: 'Invalid File',
        message: 'File content empty. Please upload valid file.',
      });
    }
  };

  const checkForValidFiles = (uploadFiles) => {
    const zipFileTypes = [
      mimeTypes.APPLICATION_ZIP,
      ZIP_MIME_TYPES.OCTET_STREAM,
      ZIP_MIME_TYPES.X_COMPRESSED,
      ZIP_MIME_TYPES.MULTIPART_X_ZIP,
      ZIP_MIME_TYPES.X_ZIP_COMPRESSED,
    ];

    const fileLength = uploadFiles.length;
    const isZipFileAlreadyExists = files.some((file) =>
      zipFileTypes.includes(file.type)
    );
    const zipFileCount = uploadFiles.filter((file) =>
      zipFileTypes.includes(file.type)
    ).length;
    if (zipFileCount > UPLOAD_LIMIT.ZIP || isZipFileAlreadyExists) {
      toast.error({
        title: 'Error',
        message: 'You cannot add more than one zip file at a time.',
      });

      return;
    }

    if (fileLength > UPLOAD_LIMIT.ALERT && fileLength <= UPLOAD_LIMIT.MAX) {
      setShowAlertMessage(true);
    }

    if (fileLength > UPLOAD_LIMIT.MAX) {
      toast.error({
        title: 'Error',
        message: `You cannot add more than ${UPLOAD_LIMIT.MAX} files at a time. It is recommended that you compress them into a single zip file before uploading into Appealio.`,
      });

      return;
    }

    const validFiles = uploadFiles.filter((file) => {
      const filename = file.name;
      const fileType = file.type || file.name.split('.').pop().toUpperCase();
      if (!acceptedTypes.includes(fileType)) {
        toast.error({
          title: 'Invalid File Type.',
          message: `File '${file.name}' is not an EDI, 835, DAT, RMT, TXT, or ZIP file. Please contact at cx@docvocate.com to add your missing file type.`,
        });
        return false;
      }
      if (file.size === 0) {
        toast.error({
          title: '',
          message: `File content empty. Please upload valid file. Filename: ${filename}.`,
        });

        return false;
      }

      return true;
    });

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const renderPlaceholderIfEmpty = () => {
    if (files.length === 0) {
      return (
        <div className="documents__placeholder">
          <p className="documents__description">
            Acceptable File Types: EDI, 835, DAT, RMT, TXT, and ZIP <br />{' '}
            <span>Zip file shouldn’t contain folder(s).</span>
          </p>
          <img alt="Empty Placeholder" src={placeholderImg} width="150" />
          <div>
            <span className="section-header">Drag & Drop</span>
            <br />
            <span className="documents__description">
              Drag your file(s) here or
            </span>
          </div>
        </div>
      );
    }
  };

  const onSelectFileClicked = () => {
    dropzoneRef.current.open();
  };

  const uploadFile = async () => {
    const fileOptions = {
      ...DocumentsUploadAPI.createOptions(),
      signingUrlQueryParams: {
        era: 1,
      },
    };

    try {
      setIsUploading(true);
      await Promise.all(
        files.map(async (file) => {
          const uploader = new S3FileUploader(file, fileOptions);
          uploader.on('error', (status) => {
            toast.error({
              title: 'Error',
              message: `We are having trouble uploading your document [${file.name}]. Please contact cx@docvocate.com for support.`,
            });
          });
          return uploader.start();
        })
      );

      toast.success({
        title: '',
        message: 'Your file(s) have been successfully uploaded.',
      });

      setIsUploading(false);
      setFiles([]);
    } catch (error) {
      toast.error({
        title: 'Upload failed',
        message:
          'Issue with uploading the file(s), please get in touch with cx@docvocate.com for assistance.',
      });
    }
  };

  const renderSelectFileButton = () => {
    return (
      <div>
        <SelectFileToUploadButton
          onClick={onSelectFileClicked}
          btnTitle="Select File to Upload"
        />
        <div className="file-download-wrapper mt-40">
          <button
            onClick={uploadFile}
            className={classNames(
              'row select_file_button select-file--disabled',
              {
                'select_file_button--right file_button--download':
                  files.length > 0,
              }
            )}
          >
            Upload File(s)
          </button>
        </div>
      </div>
    );
  };

  const renderListIfNonEmpty = () => {
    const onFileCancelClicked = (file) => {
      setFiles((prevFiles) =>
        prevFiles.filter((prevFile) => prevFile.name !== file.name)
      );
    };

    if (files.length > 0) {
      return (
        <div className="file_list">
          {files.map((file, idx) => {
            return (
              <div className="row documents_upload__file" key={idx}>
                <div className="file_item-icon">
                  <img alt="file icon" src={fileIcon} />
                </div>
                <div className="file_item-content">
                  <span className="file_item-filename">{file.name}</span>
                  <span
                    className="file_item__x"
                    onClick={() => onFileCancelClicked(file)}
                  >
                    <img alt="Cancel Button Icon" src={xIcon} />
                  </span>
                  <div className="file-item_progress__wrapper">
                    <progress
                      className="file__item-progress"
                      value={100}
                      max="100"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const renderAlertPopup = () => {
    return (
      <ScreenDimmerComponent>
        <div className="appealio-popup ap-file-popup" datacy="ap-file-popup">
          <div className="ap-file-popup__title">
            <img
              alt="Close"
              onClick={() => setShowAlertMessage(false)}
              className="alert-dialog__close-icon"
              src={closeIcon}
            />
          </div>
          <div className="ap-file-popup__content">
            You are uploading more than 10 files at once. It is recommended that
            you compress them into a single zip file before uploading into
            Appealio.
          </div>
          <div className="ap-file-popup__footer">
            <button
              onClick={() => setShowAlertMessage(false)}
              className="ap-button ap-button--primary ap-button--block"
            >
              Continue
            </button>
          </div>
        </div>
      </ScreenDimmerComponent>
    );
  };

  return (
    <div className="dashboard background-color-grey-1">
      <div className="dashboard__header clearfix">
        <div className="dashboard__header-userinfo">Upload ERAs(835)</div>
      </div>
      <div className="col-xs-offset-3 col-xs-6 mt-40 upload-era-document-wrapper">
        <p className="note text-center">
          File(s) uploaded before 5PM PST will be uploaded the following
          business day.
        </p>
        {isUploading ? (
          <div className="documents__box documents__box--md min-height-40">
            <LoadingIndicator showing={true}>
              <p className="text-center">Uploading...</p>
            </LoadingIndicator>
          </div>
        ) : (
          <Dropzone
            style={{}}
            disableClick={true}
            ref={dropzoneRef}
            onDrop={onDrop}
            onDropAccepted={checkForValidFiles}
          >
            <div className="documents__box documents__box--md documents__era-upload">
              {renderPlaceholderIfEmpty()}
              {renderListIfNonEmpty()}
              {renderSelectFileButton()}
            </div>
          </Dropzone>
        )}
      </div>
      {showAlertMessage && renderAlertPopup()}
    </div>
  );
};

export default ManualERAUpload;
