import React, {
  useMemo,
  useState,
  createRef,
  useEffect,
  useCallback,
} from 'react';
import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
} from 'redux-form/immutable';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';

import './style.css';

import pdfIcon from 'img/pdf-icon.svg';
import uploadIcon from 'img/upload.svg';

import { ReactComponent as DownloadIcon } from 'img/download-white.svg';

import Input from 'components/common/input';
import Button from 'components/common/button';
import * as toast from 'components/Shared/toast';
import Select from 'components/common/select/Select';
import { AlertDialog } from 'components/common/popup';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import ProgressBar from 'components/common/popup/ProgressBarPopup';

import { useFetchMergeConfigs, OTHER_OPTION_NAME } from './hooks';

import { mimeTypes } from 'helpers/mimeTypes';
import { BUTTON_TYPE } from 'components/common/button/Button';

import { MERGE_TOOL_TYPE } from 'constants/appConstants';

import { getPDFPageCount } from 'helpers/pdf';
import { handleError } from 'helpers/errorHandler';

import MergeUtilityAPI from 'API/MergeUtilityAPI';

import { getUserInfo } from 'redux/reducers/loginStore';

const TitleCard = ({ title, children, isDisabled, className }) => (
  <div
    className={classNames('title-card', className, { disabled: isDisabled })}
  >
    <h1>{title}</h1>
    {children}
  </div>
);

const ALTERNATIVE_OPTION = { label: 'Alternative', value: 'alternative' };

const MergeTool = (props) => {
  const {
    handleSubmit,
    selectedConfig,
    submitting,
    change,
    mergeToolType = MERGE_TOOL_TYPE.CMS_1500,
  } = props;
  const MAX_PAGES = mergeToolType === MERGE_TOOL_TYPE.CMS_1500 ? 100 : 50;

  const [uploadedFile, setUploadedFile] = useState(null);
  const dropzoneRef = createRef();
  const [isDragOver, setIsDragOver] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [errorDialog, setErrorDialog] = useState({
    isOpen: false,
    errorMessage: '',
  });

  const { loading, configsDropdownOptions } =
    useFetchMergeConfigs(mergeToolType);
  const isOtherSelected = useMemo(
    () => selectedConfig?.label === OTHER_OPTION_NAME,
    [selectedConfig]
  );

  useEffect(() => {
    return () => {
      change('spacingOption', null);
    };
  }, [change]);

  const closeErrorDialog = () => {
    setErrorDialog({ ...errorDialog, isOpen: false });
  };

  /**
   * Generates spacing options based on the selected configuration.
   */
  const SPACING_OPTIONS_TO_RENDER = useMemo(() => {
    if (!selectedConfig) {
      return [];
    }
    const configOptions = selectedConfig?.configOptions || [];
    const isOtherSelected = selectedConfig?.label === OTHER_OPTION_NAME;

    if (isOtherSelected) {
      return [...configOptions, ALTERNATIVE_OPTION];
    }

    const labelPrefix = `Default for ${selectedConfig?.label}`;

    return [
      ...configOptions.map((option, index) => {
        const labelSuffix =
          configOptions.length > 1
            ? ` #${index + 1}${option?.label ? ` (${option.label})` : ''}`
            : '';

        return {
          ...option,
          label: `${labelPrefix}${labelSuffix}`,
        };
      }),
      {
        ...ALTERNATIVE_OPTION,
        label: ALTERNATIVE_OPTION.label + ` for ${selectedConfig?.label}`,
      },
    ];
  }, [selectedConfig]);

  useEffect(() => {
    change('spacingOption', SPACING_OPTIONS_TO_RENDER[0]);
  }, [SPACING_OPTIONS_TO_RENDER, change, selectedConfig]);

  /**
   * Opens the dropzone file picker.
   */
  const handleUploadButtonClick = useCallback(
    () => dropzoneRef.current.open(),
    [dropzoneRef]
  );

  /**
   * Handles the form submission.
   * @param {object} formValues - The form values.
   * @throws {SubmissionError} When there's a validation error.
   */
  const onSubmit = useCallback(
    async (formValues) => {
      setShowProgressBar(true);
      const selectedConfig = formValues.get('pmSystemConfig');
      const isOtherSelected = selectedConfig?.label === OTHER_OPTION_NAME;

      if (!selectedConfig) {
        throw new SubmissionError({
          pmSystemConfig: 'Please select source PM System',
        });
      }

      if (isOtherSelected && !formValues.get('sourcePMSystemName')?.trim()) {
        throw new SubmissionError({
          sourcePMSystemName: 'Enter source PM system name',
        });
      }

      const formData = new FormData();
      formData.append('upload_file', uploadedFile);
      formData.append('merge_utility_type', mergeToolType);
      const spacingOptionValue = formValues.get('spacingOption').value;

      formData.append('merge_utility_config', selectedConfig.value);

      const isAlternativeSelected =
        spacingOptionValue === ALTERNATIVE_OPTION.value;

      if (isAlternativeSelected) {
        formData.append('use_alternative_config', 1);
      } else {
        formData.append('merge_config_item', spacingOptionValue);
      }

      if (isOtherSelected) {
        formData.append(
          'source_pm_system_name',
          formValues.get('sourcePMSystemName')
        );
      }

      try {
        await MergeUtilityAPI.mergeDocument(
          formData,
          mergeToolType,
          props.userInfo
        );
        toast.success({
          title: '',
          message: 'Downloaded successfully',
        });
      } catch (error) {
        handleError(error);
      } finally {
        setUploadedFile(null);
        setShowProgressBar(false);
      }
    },
    [uploadedFile, mergeToolType, props.userInfo]
  );

  /**
   * Validates the uploaded files.
   * @param {Array} files - The uploaded files.
   * @returns {boolean} True if files are valid, false otherwise.
   */
  const validateFiles = async (files) => {
    let errorMessage = '';

    if (files.length === 0) {
      errorMessage = 'Only PDF file is accepted';
    } else if (files.length > 1) {
      errorMessage = 'Only single PDF file is allowed';
    } else if (files[0].size === 0) {
      errorMessage = `File content empty. Please upload valid file. Filename: ${files[0].name}.`;
    }

    const arrayBuffer = await files[0].arrayBuffer();
    const pages = await getPDFPageCount(arrayBuffer);
    if (pages > MAX_PAGES) {
      setErrorDialog({
        isOpen: true,
        errorMessage: `You’ve uploaded a file that is more than the ${MAX_PAGES} page limit. Please reduce the number of pages and try again.`,
      });
      setUploadedFile(null);
      return;
    }

    if (errorMessage) {
      toast.error({
        title: '',
        message: errorMessage,
      });
      return false;
    }

    return true;
  };

  /**
   * Handles the file drop event.
   * @param {Array} files - The dropped files.
   */
  const onDrop = (files) => {
    if (validateFiles(files)) {
      setUploadedFile(files[0]);
    }
  };

  return (
    <div className="dashboard background-color-grey-1">
      <div className="dashboard__header clearfix">
        <div className="dashboard__header-userinfo">
          {mergeToolType} Merge Tool
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="cms-merge-tool-wrapper">
          <div className="cms-merge-tool-title">
            Use this tool to easily compile Insurance Claim Forms.
          </div>
          <div className="cms-merge-tool-description">
            You can convert a single form or multiple forms at one time. If
            converting multiple forms, please upload a single PDF file that
            contains all of the forms that need to be converted.
          </div>

          <div className="d-flex title-card-container mt-40">
            <TitleCard title="1. Select Source" className="mr-10">
              <div className="d-flex">
                <Field
                  name="pmSystemConfig"
                  component={Select}
                  label="PM System"
                  options={configsDropdownOptions}
                  placeholder="Select PM System"
                  isLoading={loading}
                />
                {isOtherSelected && (
                  <Field
                    name="sourcePMSystemName"
                    className="ml-10"
                    component={Input}
                    label="Source PM system name"
                  />
                )}
              </div>
            </TitleCard>

            <TitleCard
              title="2. Upload Info"
              isDisabled={!selectedConfig || submitting}
              className="mr-20 ml-20"
            >
              <div>
                <label className="ap-select-label">
                  Drag & drop your file below or use the upload button
                </label>
                <Button
                  icon={uploadIcon}
                  className="width-full justify-content-flex-start"
                  type={BUTTON_TYPE.SECONDARY}
                  onClick={handleUploadButtonClick}
                  buttonType="button"
                >
                  <span className="cms-file-upload-title">
                    {uploadedFile ? uploadedFile.name : 'Upload'}
                  </span>
                </Button>
              </div>
            </TitleCard>

            <TitleCard
              title={
                <div>
                  3. Adjust Spacing{' '}
                  <span className="opt-text-color">(opt.)</span>
                </div>
              }
              isDisabled={!uploadedFile || submitting}
              className="ml-10"
            >
              <Field
                name="spacingOption"
                component={Select}
                label="Select an option below"
                options={SPACING_OPTIONS_TO_RENDER}
              />
            </TitleCard>
          </div>
          <div className="d-flex justify-content-center mt-24">
            <Button
              className="ap-button  download-cms-1500-btn"
              type={BUTTON_TYPE.PRIMARY}
              disabled={!uploadedFile || !selectedConfig || submitting}
            >
              {submitting ? (
                <LoadingIndicator showing />
              ) : (
                <div className="d-flex align-items-center">
                  <DownloadIcon alt="downloadIcon" className="mr-8" />
                  Download {mergeToolType} Form
                </div>
              )}
            </Button>
          </div>
          {showProgressBar && (
            <ProgressBar
              title="Please wait while Appealio converts your claim form(s)."
              showProgressBar={showProgressBar}
              isDocumentLoaded={!submitting}
              onProgressComplete={() => {
                setShowProgressBar(false);
              }}
              timeOutDuration={500}
              successfulMessage="Converted successfully."
            />
          )}
          <Dropzone
            accept={mimeTypes.APPLICATION_PDF}
            style={{}}
            disableClick={true}
            ref={dropzoneRef}
            onDrop={validateFiles}
            onDropAccepted={onDrop}
            disabled={!selectedConfig}
          >
            <div
              className={classNames('cms-merge-tool-upload-wrapper', {
                'cms-merge-tool-upload-wrapper--dragover': isDragOver,
                'cms-merge-tool-upload-wrapper--disabled': !selectedConfig,
              })}
              onDragOver={(e) => {
                setIsDragOver(true);
              }}
              onDragLeave={(e) => {
                setIsDragOver(false);
              }}
              onDrop={(e) => {
                setIsDragOver(false);
              }}
            >
              {selectedConfig && (
                <div className="cms-1500-form-upload-file">
                  <div className="d-flex flex-direction--column align-items-center justify-content-center">
                    <div>
                      <img src={pdfIcon} alt="pdfIcon" />
                    </div>
                    <div>
                      {!uploadedFile
                        ? 'Drag & Drop your file here'
                        : 'File uploaded successfully'}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Dropzone>
        </div>
      </form>

      {errorDialog.isOpen && (
        <AlertDialog onClosePressed={closeErrorDialog}>
          {errorDialog.errorMessage}
        </AlertDialog>
      )}
    </div>
  );
};

const FORM_NAME = 'MergeToolForm';

const mapStateToProps = (state) => {
  const selector = formValueSelector(FORM_NAME);
  const selectedConfig = selector(state, 'pmSystemConfig');
  return {
    selectedConfig,
    userInfo: getUserInfo(state),
  };
};

export default connect(mapStateToProps)(
  reduxForm({ form: FORM_NAME })(MergeTool)
);
