import { parse } from 'qs';
import { isEqual, get } from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { FaSearch } from 'react-icons/fa';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { GrUserManager } from 'react-icons/gr';
import React, { useEffect, useState, useCallback, useMemo } from 'react';

import DeleteSubmission from './DeleteSubmission';
import AppealioTable from 'components/common/table';
import InProgressSubmissionActionBar from './InProgressSubmissionActionBar';
import {
  CustomCell,
  AgentCell,
  BuildDateCell,
  ClaimIDCell,
  ClaimNumberCell,
  BuildArrayCell,
  CustomProviderCell,
  ProviderNameCustomCell,
  ProcedureModifierCellHeader,
  BuildProcedureModifierCell,
  SubmissionTypeCell,
  DeadlineEobDateCellHeader,
  BuildDeadlineEobDateCell,
} from './columnCell';

import { CREATE_APPEAL } from 'constants/routes';
import { TABLE_PAGE_LIMIT, PAYER_API_TYPE } from 'constants/appConstants';

import { useFetchInProgressSubmissions } from './hooks';

import {
  isAvailityClaimStatusCheckEnabledForUser,
  isUHCClaimStatusCheckEnabledForUser,
} from 'Auth/FeatureFlags';

import {
  getAvailityClaimStatusSearchDataForAppeal,
  getUhcClaimStatusSearchDataForAppeal,
} from 'components/GlobalSearch/hook';

import CreateAppealAPI from 'API/CreateAppealAPI';

import { getUserInfo } from 'redux/reducers/loginStore';
import { setAppealData } from 'redux/actions/createAppealActions';

import { openUhcClaimStatusWidget } from 'redux/actions/uhcClaimStatusWidgetActions';
import { openAvailityClaimStatusWidget } from 'redux/actions/availityClaimStatusWidgetActions';

import { MdOutlineEventNote } from 'react-icons/md';

import './style.css';
import { isDateWithinPastDays } from 'helpers/utils';
import {
  getSopIds,
  hasSopIds,
  useFetchNotesCounts,
  useFetchSopAndRuleInfo,
} from '../DeliveryTracking/hooks';
import NotesPopup from 'components/DenialQueue/Notes';
import SopInfoIcon from 'components/Shared/SopInfoIcon';

const DEFAULT_FILTERS = {
  payerId: null,
  providerId: null,
  agentId: null,
  practiceIdentifier: null,
  search: '',
  submissionType: null,
  notificationRedirect: false,
};

const parseFilter = () => {
  const {
    agent_id: agentId,
    payer_id: payerId,
    provider_id: providerId,
    practice_identifier: practiceIdentifier,
    search,
    submissionType,
    notificationRedirect,
  } = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  if (
    agentId ||
    submissionType ||
    payerId ||
    providerId ||
    practiceIdentifier ||
    notificationRedirect
  ) {
    return {
      ...DEFAULT_FILTERS,
      ...(agentId && { agentId }),
      ...(payerId && { payerId }),
      ...(providerId && { providerId }),
      ...(practiceIdentifier && { practiceIdentifier }),
      ...(submissionType && { submissionType }),
      ...(notificationRedirect && {
        notificationRedirect: notificationRedirect === 'true',
      }),
    };
  }
  if (search) {
    return { ...DEFAULT_FILTERS, search };
  }

  return DEFAULT_FILTERS;
};

const defaultSortBy = {
  id: 'createdAt',
  desc: true,
};

const notesDefaultInfo = {
  isOpen: false,
  appealId: null,
  claimId: null,
  claimNumber: null,
};

const InProgressSubmissions = (props) => {
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(defaultSortBy);
  const [filters, setFilters] = useState(parseFilter());
  const [selectedAppeal, setSelectedAppeal] = useState();
  const [deleteSubmissionPopup, setDeleteSubmissionPopup] = useState({
    isOpen: false,
    appeal: null,
  });

  const [notesPopup, setNotesPopup] = useState(notesDefaultInfo);

  const [agentsList, setAgentsList] = useState([]);

  const {
    loading,
    inProgressSubmissions,
    totalSubmissions,
    fetch: fetchInProgressSubmissions,
  } = useFetchInProgressSubmissions(sortBy, filters, page);
  const appealIds = useMemo(
    () => inProgressSubmissions.map(({ id }) => Number(id)),
    [inProgressSubmissions]
  );

  const {
    notesCount,
    notesLatestCreatedTime,
    fetch: fetchNotesCount,
  } = useFetchNotesCounts(appealIds, true);

  const { sopAndRuleInfo } = useFetchSopAndRuleInfo(appealIds, true);
  const handleResetClick = () => setFilters(DEFAULT_FILTERS);

  const resetDeleteSubmissionPopup = () => {
    setDeleteSubmissionPopup({
      isOpen: false,
      appeal: null,
    });
  };

  const updateAgentsList = useCallback((agents) => {
    setAgentsList(agents);
  }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    setFilters(parseFilter());
  }, [location.search]);

  const onFilterChange = (filters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...filters }), setPage(1));
    window.history.pushState({}, document.title, window.location.pathname);
  };

  const handleClaimSelection = useCallback(
    (claim) => {
      const newSelectedClaim = isEqual(selectedAppeal, claim) ? null : claim;
      setSelectedAppeal(newSelectedClaim);
    },
    [selectedAppeal]
  );

  const onRowPressed = async (rowData) => {
    const appeal = await CreateAppealAPI.getAppealById(rowData.id);

    props.actions.setAppealData({
      appeals: [
        {
          ...appeal,
          step: appeal.step || 0,
        },
      ],
      name: appeal.patientName,
    });

    setTimeout(() => props.actions.push(CREATE_APPEAL), 100);
  };

  const BuildNestedAppealsNumberCell = useCallback(
    ({
      appeal,
      openUhcClaimStatusWidget,
      openAvailityClaimStatusWidget,
      userInfo,
    }) => {
      const showClaimResolutionBtn = ['Denied', 'Successful'].includes(
        appeal.status
      );

      const isUhcClaim =
        [
          appeal.ediPayerId,
          appeal.claimNumber,
          appeal.billingProviderTaxId,
        ].every((val) => val !== '--') &&
        appeal.claimApi === PAYER_API_TYPE.UHC;
      const isAvailityClaim = appeal.claimApi === PAYER_API_TYPE.AVAILITY;

      const isGetClaimStatusAPIEnabled =
        ((appeal.payerApi === PAYER_API_TYPE.UHC &&
          isUHCClaimStatusCheckEnabledForUser(userInfo)) ||
          (appeal.payerApi === PAYER_API_TYPE.AVAILITY &&
            isAvailityClaimStatusCheckEnabledForUser(userInfo))) &&
        appeal.payerApi &&
        (isUhcClaim || isAvailityClaim);

      const isGetClaimStatusBtnDisabled = !isGetClaimStatusAPIEnabled;

      const handleClaimStatusCheck = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (isGetClaimStatusAPIEnabled) {
          if (isAvailityClaim) {
            return openAvailityClaimStatusWidget(
              getAvailityClaimStatusSearchDataForAppeal(appeal, userInfo)
            );
          }
          return openUhcClaimStatusWidget(
            getUhcClaimStatusSearchDataForAppeal(appeal, userInfo)
          );
        }
      };

      if (showClaimResolutionBtn) {
        return (
          <React.Fragment>
            <button
              data-tip={`Claim Status Check Via ${
                isAvailityClaim ? 'Availity' : 'UHC'
              }`}
              className={classnames('ap-button ap-button--action mr-4', {
                'visibility-hidden': isGetClaimStatusBtnDisabled,
              })}
              onClick={handleClaimStatusCheck}
            >
              <FaSearch />
            </button>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <button
            data-tip={`Claim Status Check Via ${
              isAvailityClaim ? 'Availity' : 'UHC'
            }`}
            className={classnames('ap-button ap-button--action mr-4', {
              'visibility-hidden': isGetClaimStatusBtnDisabled,
            })}
            onClick={handleClaimStatusCheck}
          >
            <FaSearch />
          </button>
        </React.Fragment>
      );
    },
    []
  );

  const NoteCell = ({
    appealId,
    submitterClaimId,
    claimNumber,
    notesCount,
    notesLatestCreatedTime,
  }) => {
    const count = get(notesCount, appealId);
    const latestCreatedTime = get(notesLatestCreatedTime, appealId);
    const isNotesRecent = isDateWithinPastDays(latestCreatedTime, 7);
    return (
      <div className="appealio-table__custom-cell">
        <div
          className={classnames('custom-badge', {
            'custom-badge--border-0': !count,
            'custom-badge--primary': isNotesRecent,
          })}
          onClick={(e) => {
            e.stopPropagation();
            setNotesPopup({
              isOpen: true,
              appealId,
              claimId: submitterClaimId === '--' ? null : submitterClaimId,
              claimNumber: claimNumber === '--' ? null : claimNumber,
            });
          }}
        >
          <MdOutlineEventNote
            size="20"
            className={classnames('cursor-pointer custom-badge__icon')}
          />
          <span> {count > 0 && count} </span>
        </div>
      </div>
    );
  };

  const ClaimStatusAndNoteColumn = useCallback(
    ({ row: { original: appeal } }) => {
      const { id, submitterClaimId, claimNumber } = appeal;
      return (
        <>
          {!appeal.readOnly && (
            <div className="table-status__cell--action">
              <BuildNestedAppealsNumberCell
                appeal={appeal}
                openUhcClaimStatusWidget={
                  props.actions.openUhcClaimStatusWidget
                }
                openAvailityClaimStatusWidget={
                  props.actions.openAvailityClaimStatusWidget
                }
                userInfo={props.userInfo}
              />
              <NoteCell
                userInfo={props.userInfo}
                appealId={id}
                submitterClaimId={submitterClaimId}
                claimNumber={claimNumber}
                notesCount={notesCount}
                notesLatestCreatedTime={notesLatestCreatedTime}
              />
            </div>
          )}
        </>
      );
    },
    [
      props.actions.openUhcClaimStatusWidget,
      props.actions.openAvailityClaimStatusWidget,
      props.userInfo,
      notesCount,
      notesLatestCreatedTime,
    ]
  );

  const PatientCellHeader = () => {
    return <div className="ml-30">Patient</div>;
  };

  const PatientCell = ({ row, sopAndRuleInfo }) => {
    const { original: appeal } = row;
    const isAppealImported = appeal.imported === true;
    return (
      <div className="appealio-table__custom-cell">
        <div className="table-status__cell--action">
          <span className="ingested-appeal-indicator__container">
            {!isAppealImported && (
              <GrUserManager
                data-tip="Manual"
                className="ingested-appeal-indicator"
              />
            )}
          </span>

          <CustomCell value={appeal.patientName} />

          {hasSopIds(sopAndRuleInfo, row.original.id) && (
            <SopInfoIcon
              appliedRule={get(
                sopAndRuleInfo,
                `${row.original.id}.appliedRule`
              )}
              sopIds={getSopIds(sopAndRuleInfo, row.original.id)}
            />
          )}
        </div>
      </div>
    );
  };

  const onCloseNotesPopup = () => {
    setNotesPopup(notesDefaultInfo);
    fetchNotesCount();
  };
  const SelectCell = useCallback(
    (props) => {
      const rowData = props.row.original;
      const isChecked = selectedAppeal?.id === rowData.id;
      const isDisabled = selectedAppeal && selectedAppeal?.id !== rowData.id;
      return (
        <div>
          <input
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              e.stopPropagation();
              handleClaimSelection(rowData);
            }}
            checked={isChecked}
            type="checkbox"
            disabled={isDisabled}
            className="denials-queue-td-checkbox"
          />
        </div>
      );
    },
    [selectedAppeal, handleClaimSelection]
  );

  const columns = [
    {
      Header: '',
      accessor: 'select',
      disableSortBy: true,
      Cell: SelectCell,
      width: 20,
    },
    {
      Header: PatientCellHeader,
      accessor: 'patientName',
      width: 140,
      className: 'position-relative appealio-table-cell__border--right',
      sortKey: 'patient_name',
      Cell: (props) => (
        <PatientCell {...props} sopAndRuleInfo={sopAndRuleInfo} />
      ),
    },
    {
      Header: () => <div className="ml-16">Type</div>,
      accessor: 'isMedicalRecordsSubmission',
      width: 104,
      sortKey: 'submission_type',
      disableSortBy: true,
      Cell: SubmissionTypeCell,
    },
    {
      Header: '',
      accessor: 'claimStatus',
      className: 'pr-0',
      width: 100,
      disableSortBy: true,
      Cell: ClaimStatusAndNoteColumn,
    },
    {
      Header: 'Claim ID',
      accessor: 'submitterClaimId',
      width: 100,
      sortKey: 'submitter_claim_id',
      Cell: ClaimIDCell,
    },
    {
      Header: 'Payer',
      accessor: 'payerName',
      width: 100,
      sortKey: 'payer_name',
      Cell: CustomCell,
    },
    {
      Header: 'Payer Claim ID',
      accessor: 'claimNumber',
      width: 115,
      sortKey: 'claimNumber',
      Cell: ClaimNumberCell,
    },
    {
      Header: ProcedureModifierCellHeader,
      accessor: 'cptCode',
      width: 80,
      sortKey: '',
      disableSortBy: true,
      Cell: BuildProcedureModifierCell,
    },
    {
      Header: 'Reason',
      accessor: 'reasonCode',
      width: 80,
      sortKey: '',
      disableSortBy: true,
      Cell: BuildArrayCell,
    },
    {
      Header: DeadlineEobDateCellHeader,
      accessor: 'deadline',
      width: 110,
      sortKey: '',
      disableSortBy: true,
      Cell: BuildDeadlineEobDateCell,
    },
    {
      Header: ProviderNameCustomCell,
      accessor: 'providerName',
      width: 120,
      sortKey: 'providerName',
      Cell: CustomProviderCell,
      disableSortBy: true,
    },
    {
      Header: 'Agent',
      accessor: 'agentName',
      width: loading ? 130 : 100,
      sortKey: 'agent_name',
      Cell: AgentCell,
    },
    {
      Header: 'Date Created',
      accessor: 'createdAt',
      width: loading ? 200 : 100,
      className: 'appealio-table-cell__border--left',
      sortKey: 'createdAt',
      Cell: BuildDateCell,
    },
  ];

  const onTableSort = (sortBy) => setSortBy(sortBy);

  const pageCount = Math.ceil(totalSubmissions / TABLE_PAGE_LIMIT);
  const onPageChange = (page) => setPage(page);

  const batchUploadAppealIds = queryParams.get('batchUploadAppealIds');
  const updatedSubmissions = inProgressSubmissions.map((submission) => {
    if (batchUploadAppealIds?.includes(submission.id)) {
      return { ...submission, className: 'batch-created-appeal' };
    }
    return submission;
  });

  return (
    <div className="in-progress-submissions-wrapper">
      <InProgressSubmissionActionBar
        filters={filters}
        setFilters={setFilters}
        isResetActive={!isEqual(filters, DEFAULT_FILTERS)}
        handleResetClick={handleResetClick}
        userInfo={props.userInfo}
        data={inProgressSubmissions}
        totalSubmissions={totalSubmissions}
        pageCount={pageCount}
        page={page}
        onPageChange={onPageChange}
        onFilterChange={onFilterChange}
        defaultFilters={DEFAULT_FILTERS}
        agentsList={updateAgentsList}
        selectedAppeal={selectedAppeal}
        setDeleteSubmissionPopup={setDeleteSubmissionPopup}
      />
      <AppealioTable
        columns={columns}
        onSort={onTableSort}
        data={updatedSubmissions}
        isLoading={loading}
        sortBy={sortBy}
        className="inprogress-submissions-table"
        onRowClick={onRowPressed}
        wrapperClassName="dashboard-min-height"
        defaultSortBy={defaultSortBy}
      />
      {deleteSubmissionPopup.isOpen && (
        <DeleteSubmission
          appeal={deleteSubmissionPopup.appeal}
          onClosePressed={resetDeleteSubmissionPopup}
          onDeleteSubmissionSuccess={() => {
            setPage(1);
            resetDeleteSubmissionPopup();
            setSelectedAppeal(null);
            fetchInProgressSubmissions();
          }}
        />
      )}

      {notesPopup.isOpen && (
        <NotesPopup
          appealId={notesPopup.appealId}
          claimId={notesPopup.claimId}
          claimNumber={notesPopup.claimNumber}
          userInfo={props.userInfo}
          agents={agentsList}
          setShowNotesPopup={onCloseNotesPopup}
          isAppeal={true}
        />
      )}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    userInfo: getUserInfo(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        push,
        setAppealData,
        openUhcClaimStatusWidget,
        openAvailityClaimStatusWidget,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InProgressSubmissions);
