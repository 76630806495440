import React from 'react';
import classnames from 'classnames';

import './style.css';

const Pill = ({ status, className, onClick, datacy = 'pill', dataTip }) => {
  return (
    <div
      className={classnames('pill', {
        [`pill--${className}`]: className,
      })}
      onClick={onClick}
      datacy={datacy}
      data-tip={dataTip}
    >
      {status}
    </div>
  );
};

export default Pill;
