import React from 'react';

import CustomDropdown from 'components/common/dropdown/ButtonDropdown/CustomDropdown';

const LetterDropdown = (props) => {
  const { input, options } = props;

  return (
    <CustomDropdown
      {...props}
      options={options}
      disableRemovalOfOptions={input.value?.length === 1}
    />
  );
};

export default LetterDropdown;
