import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useState, useCallback, useEffect } from 'react';

import { useFetchUsersAccessControl } from '../../hooks';

import AddUser from './AddUser';
import UserListItem from './UserListItem';

import Pagination from 'components/common/pagination';
import SearchInput from 'components/common/input/SearchInput';

import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';

import { SETTINGS_PAGE_LIMIT } from 'constants/appConstants';

import emptyIcon from 'img/empty.svg';

import {
  getDropdownOptions,
  DROPDOWN_OPTIONS_STATE_KEYS,
} from 'redux/reducers/dropdownOptionsStore';
import { getUserInfo } from 'redux/reducers/loginStore';
import {
  fetchPracticeGroupOptions,
  fetchDenialsPracticeOptions,
} from 'redux/actions/dropdownOptionsActions';

import { isAuthorizedForPracticeGroup, isClientAdmin } from 'Auth/AuthUtils';

const Users = (props) => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [userToEdit, setUserToEdit] = useState(null);
  const [copiedUser, setCopiedUser] = useState(null);
  const [isUserPopupOpen, setIsUserPopupOpen] = useState(false);

  const { fetchPracticeGroupOptions, fetchDenialsPracticeOptions } =
    props.actions;

  useEffect(() => {
    if (isAuthorizedForPracticeGroup(props.userInfo)) {
      fetchPracticeGroupOptions();
    }
  }, [fetchPracticeGroupOptions, props.userInfo]);

  useEffect(() => {
    fetchDenialsPracticeOptions();
  }, [fetchDenialsPracticeOptions]);

  const handleSearchSubmit = (event) => {
    setPage(1);
    setSearch(event.target.value?.trim());
  };

  const onSearch = useCallback(
    (args) => debounce(handleSearchSubmit, 800)(args),
    []
  );

  const onClosePressed = () => {
    setIsUserPopupOpen(false);
  };

  const {
    loading,
    usersAccessControl,
    setUsersAccessControl,
    totalUsers,
    fetch,
  } = useFetchUsersAccessControl(search, page);

  const onPageChange = (page) => setPage(page);
  const pageCount = Math.ceil(totalUsers / SETTINGS_PAGE_LIMIT);

  const handleEditUserClick = (selectedUser) => setUserToEdit(selectedUser);
  const handleCopyPracticeClick = (selectedUser) => setCopiedUser(selectedUser);

  const handleUserAccessUpdate = (userAccess) => {
    setUsersAccessControl(
      usersAccessControl.map((accessControl) => {
        if (accessControl.userId === userAccess.userId) {
          return userAccess;
        }
        return accessControl;
      })
    );
  };

  return (
    <div className="settings-content-wrapper">
      <div className="d-flex align-items-center justify-content--space-between settings-content__top-action">
        <SearchInput
          placeholder="Search by Name"
          onSearch={onSearch}
          onReset={() => setSearch('')}
          datacy="users-SearchBox"
        />
        {isClientAdmin(props.userInfo) && (
          <button
            onClick={() => {
              setIsUserPopupOpen(true);
            }}
            className="ap-button ap-button--secondary ml-auto settings-add-button"
            datacy="settings-add-button"
          >
            <span className="mr-4">+</span> Add User
          </button>
        )}
      </div>
      <div className="ap-card-list-wraper mt-12" datacy="users">
        {loading && loading ? (
          <LoadingBlockHelper
            className="dashboard-bottom-loader"
            isLoading={loading}
          />
        ) : usersAccessControl.length === 0 ? (
          <div className="no-data-container">
            <img src={emptyIcon} alt="No Data Found" />
            <h4>No Data Found</h4>
          </div>
        ) : (
          <React.Fragment>
            <div className="row ap-list-header">
              <div className="col-md-2 ap-list-header__col--sm">Name</div>
              <div className="col-md-2 ap-list-header__col--sm">Role</div>
              <div className="col-md-8 ap-list-header__col--lg">Practice</div>
            </div>
            <ul className="ap-card-list">
              {usersAccessControl &&
                usersAccessControl.map((userAccessControl, index) => {
                  return (
                    <UserListItem
                      key={index}
                      userAccessControl={userAccessControl}
                      copiedUser={copiedUser}
                      onEditClick={handleEditUserClick}
                      onCopyClick={handleCopyPracticeClick}
                      onCancelClick={() => setUserToEdit(null)}
                      formEnabled={
                        userToEdit &&
                        userToEdit.userId === userAccessControl.userId
                      }
                      form={`user-access-control-form-${userAccessControl.userId}`}
                      userToEdit={userToEdit}
                      onUpdateUserAccess={handleUserAccessUpdate}
                      datacy={`user-${index}-UserListItem`}
                      searchQuery={search}
                    />
                  );
                })}
            </ul>
          </React.Fragment>
        )}
        {isUserPopupOpen && (
          <AddUser
            onClosePressed={onClosePressed}
            datacy="user-AddUser"
            fetchUsers={fetch}
          />
        )}

        {!loading && totalUsers && pageCount > 1 ? (
          <div className="d-flex justify-content-center">
            <Pagination
              pageCount={pageCount}
              activePage={page}
              onPageChange={onPageChange}
              datacy="users-Pagination"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const userInfo = getUserInfo(state);

  return {
    dropdownOptions: {
      practiceGroupOptions: getDropdownOptions(
        state,
        DROPDOWN_OPTIONS_STATE_KEYS.PRACTICE_GROUP_OPTIONS
      ),
    },
    userInfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchPracticeGroupOptions,
      fetchDenialsPracticeOptions,
    },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(Users);
