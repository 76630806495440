import React, { useState, useEffect } from 'react';
import AppealioPopup from '../../AppealioPopup';
import LoadingIndicator from '../../LoadingIndicator';
import './style.css';

const SopPopup = (props) => {
  const { onClose, appliedRule, sopIds, sopData, loading, error } = props;
  const [selectedSopId, setSelectedSopId] = useState(null);

  useEffect(() => {
    if (sopIds.length > 0 && !selectedSopId) {
      setSelectedSopId(sopIds[0]);
    }
  }, [sopIds, selectedSopId]);

  const renderSopContent = (sopId) => {
    const data = sopData[sopId];
    if (!data) return null;
    return (
      <div className="sop-info-table-container">
        <div className="sop-info-description">SOP Name: {data.name}</div>
        <table className="sop-info-table">
          <thead>
            <tr>
              <th className="sop-info-sn">Step</th>
              <th className="sop-info-action">Proposed Action</th>
              <th className="sop-info-role">Role</th>
              <th className="sop-info-instruction">Instruction</th>
            </tr>
          </thead>
          <tbody>
            {data.steps.map((step, index) => (
              <tr key={index}>
                <td className="sop-info-sn">{index + 1}</td>
                <td className="sop-info-action">{step.action.description}</td>
                <td>{step.role.value}</td>
                <td className="sop-info-instruction">
                  {step.instruction.trim()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <AppealioPopup
      title={<div>Rule Name: {appliedRule}</div>}
      onClosePressed={onClose}
      className="sop-popup-container appealio-popup--v3"
      titleClassName="appealio-popup--v3__title-wrapper"
    >
      {loading && <LoadingIndicator />}
      {error && <div className="error-message">Error: {error}</div>}
      {!loading && !error && (
        <div className="sop-content-wrapper">
          <div className="sop-sidebar">
            <h3>Your SOP(s)</h3>
            <div className="sop-list">
              {sopIds.map((sopId) => (
                <div
                  key={sopId}
                  className={`sop-list-item ${
                    selectedSopId === sopId ? 'selected' : ''
                  }`}
                  onClick={() => setSelectedSopId(sopId)}
                >
                  {sopData[sopId]?.name || `SOP ${sopId}`}
                </div>
              ))}
            </div>
          </div>
          <div className="sop-main-content">
            {selectedSopId && renderSopContent(selectedSopId)}
          </div>
        </div>
      )}
    </AppealioPopup>
  );
};

export default SopPopup;
