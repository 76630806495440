import { isNull } from 'lodash';
import { useEffect, useState } from 'react';

import { calculateEstimate, fetchCPTs } from '../../../../API/CostEstimatorAPI';

/**
 * Use benefits hook.
 * @param {Object} patient
 * @param {String} eligibilityCheckId
 * @param {String} insuranceId
 * @param {Array} procedures
 * @returns {Promise}
 */
export const useFetchEstimate = (
  patient,
  eligibilityCheckId,
  insuranceId,
  procedures,
  manualBenefitInfo
) => {
  const [error, setError] = useState();
  const [estimates, setEstimates] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetch = async () => {
      if (procedures && procedures.length === 0) {
        setLoading(false);
        return setEstimates(null);
      }

      try {
        const shouldUseOverride =
          !Object.values(manualBenefitInfo).every(isNull);
        const override = shouldUseOverride ? manualBenefitInfo : null;

        const data = await calculateEstimate(
          patient,
          eligibilityCheckId,
          insuranceId,
          procedures,
          override
        );
        setEstimates(data);
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    };

    fetch();
  }, [patient, eligibilityCheckId, procedures, manualBenefitInfo, insuranceId]);

  return { loading, estimates, error };
};

/**
 * Use fetch cpts hook.
 * @returns {Promise}
 */
export const useFetchCPTs = () => {
  const [error, setError] = useState();
  const [cpts, setCpts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setCpts([]);
    setLoading(true);
    const fetch = async () => {
      try {
        const data = await fetchCPTs();
        setCpts(data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    fetch();
  }, []);

  return { loading, cpts, error };
};
