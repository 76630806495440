import { useEffect, useState } from 'react';

import { snakeCaseKeys } from 'helpers/object';
import * as AnalyticsDashboardAPI from 'API/AnalyticsDashboardAPI';

export const useFetchTopPayers = (filters, sort, topPayerIds) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [payers, setPayers] = useState([]);
  const [isNotTopPayer, setIsNotTopPayer] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      setError(null);
      try {
        const queryParam = {
          ...filters,
          sort: sort.sortOrder,
          by: sort.sortBy,
        };

        if (filters.payer && !topPayerIds.includes(filters.payer)) {
          setLoading(false);
          setIsNotTopPayer(true);
          return;
        }
        setPayers([]);
        const data = await AnalyticsDashboardAPI.fetchTopPayers(queryParam);
        setPayers(data);
        setIsNotTopPayer(false);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    fetch();
    // eslint-disable-next-line
  }, [filters, sort]);

  return { loading, error, payers, fetch, isNotTopPayer };
};

export const useFetchAverageAllowedAmountByProcedureCodes = (
  payersId,
  filters
) => {
  const { payer, ...filtersWithoutPayer } = filters;
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [averageAmounts, setAverageAmounts] = useState([]);
  const payersList = (payersId && payersId.filter(Boolean).join(',')) || null;

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      setAverageAmounts([]);
      setError(null);
      try {
        const queryParam = {
          ...snakeCaseKeys({
            by: '',
            ...(payersList && { payersList }),
            ...filtersWithoutPayer,
          }),
        };
        const data =
          await AnalyticsDashboardAPI.fetchAverageAllowedAmountsByProcedureCodes(
            queryParam
          );
        setAverageAmounts(data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };
    fetch();
    // eslint-disable-next-line
  }, [payersId]);

  return { loading, error, averageAmounts, fetch };
};

export const useFetchCptCodeCounts = (filters) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [cptCodeCounts, setCptCodeCounts] = useState([]);
  const fetch = async () => {
    setLoading(true);
    setCptCodeCounts({});
    setError(null);
    try {
      const data = await AnalyticsDashboardAPI.fetchAnalyticsClaimsInformation(
        filters
      );
      setCptCodeCounts(data.cptCodes);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [filters]);

  return { loading, error, cptCodeCounts };
};
