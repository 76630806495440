import PropTypes from 'prop-types';
import React, { useState, useEffect, forwardRef, memo, useRef } from 'react';

import Filter from './Filter';
import Widget from '../Widget';
import StackGraph from './StackGraph';
import OverviewBox from './OverviewBox';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import EmptyWidgetPlaceholder from 'components/ApDashboard/Manager/EmptyWidgetPlaceholder';

import { useFetchClaimsPerformance } from './hooks';

import { handleError } from 'helpers/errorHandler';
import { convertToFixDecimalPoint } from 'helpers/utils';
import { fetchAnalyticsClaimsInformation } from 'API/AnalyticsDashboardAPI';

export const TIME_INTERVAL = {
  month: 'month',
  quarter: 'quarter',
};

const ClaimPerformanceOverview = ({ total }) => {
  const overViewStatusData = [
    {
      title: 'Allowed Amount Ratio',
      value: total && total.unclassifiedAdjustmentPercentage,
      color: 'green',
    },
    {
      title: 'Denial Amount Ratio',
      value: total && total.denialsAndUnderpaymentsPercentage,
      color: 'red',
    },
    {
      title: 'Routine Adjustment Ratio',
      value: total && total.routineAdjustmentPercentage,
    },
  ];
  return (
    <div className="overview-container">
      {overViewStatusData.map((stat, idx) => (
        <OverviewBox
          key={idx}
          color={stat.color}
          title={stat.title}
          value={stat.value}
        />
      ))}
    </div>
  );
};

const ClaimsPerformance = memo(
  forwardRef((props, ref) => {
    const { filters, claimsCount, isDownloading } = props;
    const [interval, setInterval] = useState(null);
    const initialRender = useRef(true);
    const [claimsCountInfo, setClaimsCountInfo] = useState(null);
    const {
      error,
      loading,
      claimsPerformance: { breakdown: data, total },
    } = useFetchClaimsPerformance(filters, interval);

    const chartRef = useRef();
    const current = chartRef ? chartRef.current : null;
    const [charImgSrc, setCharImgSrc] = useState(null);

    useEffect(() => {
      if (!current) return;
      const svg = current.querySelector('svg');
      if (!svg) return;
      const svgData = new XMLSerializer().serializeToString(svg);
      const imgSrc = 'data:image/svg+xml;base64,' + btoa(svgData);
      setCharImgSrc(imgSrc);
    }, [current]);

    const [showSvg, setShowSvg] = useState(false);
    const totalAveragePerformanceClaims =
      total && convertToFixDecimalPoint(total, 1);

    useEffect(() => {
      if (isDownloading && !showSvg) {
        setShowSvg(true);
      }
      if (!isDownloading && showSvg) {
        setShowSvg(false);
      }
    }, [isDownloading, showSvg, setShowSvg]);

    useEffect(() => {
      const fetchData = async () => {
        if (initialRender.current) {
          initialRender.current = false;
        } else {
          setClaimsCountInfo(null);

          try {
            const { claims } = await fetchAnalyticsClaimsInformation({
              ...filters,
              interval,
            });
            setClaimsCountInfo({
              breakdown: claims.breakdown,
              count: claims.count,
            });
          } catch (error) {
            handleError(error);
          }
        }
      };

      fetchData();
    }, [filters, interval]);

    if (error) {
      return (
        <Widget
          widgetTitle={{
            title: 'Claims Performance Overview',
            titleClassName: 'v3__widget-title--lg',
          }}
        >
          <div className="ap-dashboard-widget__status-filter">
            <Filter
              interval={interval}
              setInterval={setInterval}
              isFetchingData={loading}
            />
          </div>
          <EmptyWidgetPlaceholder title="Opps! Something went wrong!" />
        </Widget>
      );
    }

    if (data && !data.length) {
      return (
        <Widget
          widgetTitle={{
            title: 'Claims Performance Overview',
            titleClassName: 'v3__widget-title--lg',
          }}
        >
          <div className="ap-dashboard-widget__status-filter">
            <Filter
              interval={interval}
              setInterval={setInterval}
              isFetchingData={loading}
            />
          </div>
          <EmptyWidgetPlaceholder title="No Data Available" />
        </Widget>
      );
    }

    const handleResetBtnClick = () => {
      // eslint-disable-next-line no-implied-eval
      setInterval(TIME_INTERVAL.month);
    };
    const resetButton = (
      <button ref={ref} onClick={handleResetBtnClick} className="d-none">
        reset
      </button>
    );

    return (
      <Widget
        widgetTitle={{
          title: 'Claims Performance Overview',
          titleClassName: 'v3__widget-title--lg',
        }}
      >
        <div className="ap-dashboard-widget__status-filter">
          <Filter
            interval={interval}
            setInterval={setInterval}
            isFetchingData={loading}
          />
          {resetButton}
        </div>
        {loading ? (
          <LoadingBlockHelper
            isLoading={true}
            className="v3__widget-loading-helper"
          />
        ) : (
          <React.Fragment>
            <ClaimPerformanceOverview total={totalAveragePerformanceClaims} />
            {isDownloading ? (
              <div>
                <img alt="Chart" src={charImgSrc} />
              </div>
            ) : (
              <div ref={chartRef}>
                <StackGraph
                  claimsPerformance={data}
                  total={totalAveragePerformanceClaims}
                  interval={interval}
                  claimsCountInfo={
                    !claimsCountInfo ? claimsCount : claimsCountInfo
                  }
                />
              </div>
            )}
          </React.Fragment>
        )}
      </Widget>
    );
  })
);

ClaimsPerformance.propTypes = {
  filters: PropTypes.object.isRequired,
};

export default ClaimsPerformance;
