import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import {
  Field,
  reduxForm,
  change,
  SubmissionError,
} from 'redux-form/immutable';

import Input from 'components/common/input';

import { MASK_PHONE } from 'helpers/masks';
import { handleError } from 'helpers/errorHandler';
import {
  fieldCharacterValidation,
  validateEmail,
  validatePhoneNumber,
  validateAlphaNumericString,
} from 'helpers/validators';

let AddUserForm = (props) => {
  const { handleSubmit, onSubmit, initialValues, invalid, submitting } = props;

  const validate = (values) => {
    const errors = {
      firstName: 'User first name is required',
      lastName: 'User last name is required',
      emailAddress: 'Email address is required',
      username: 'Username is required',
    };

    if (
      !values.get('firstName') &&
      !values.get('lastName') &&
      !values.get('emailAddress') &&
      !values.get('username')
    ) {
      throw new SubmissionError(errors);
    }

    if (values.get('firstName')) {
      delete errors.firstName;
      const firstNameValidation = fieldCharacterValidation(
        'User First Name',
        values.get('firstName'),
        150 // 150 max len from AbstractUser on backend
      );
      if (firstNameValidation) {
        errors.firstName = firstNameValidation;
      }

      if (!validateAlphaNumericString(values.get('firstName'))) {
        errors.firstName =
          'First Name must only contain alphanumeric characters';
      }
    }

    if (values.get('lastName')) {
      delete errors.lastName;
      const lastNameValidation = fieldCharacterValidation(
        'User Last Name',
        values.get('lastName'),
        150 // 150 max len from AbstractUser on backend
      );
      if (lastNameValidation) {
        errors.lastName = lastNameValidation;
      }

      if (!validateAlphaNumericString(values.get('lastName'))) {
        errors.lastName = 'Last Name must only contain alphanumeric characters';
      }
    }

    if (values.get('emailAddress')) {
      delete errors.emailAddress;
      const emailValidation = validateEmail(values.get('emailAddress'));
      if (emailValidation) {
        errors.emailAddress = emailValidation;
      }
    }

    if (values.get('username')) {
      delete errors.username;
      const usernameValidation = fieldCharacterValidation(
        'User Last Name',
        values.get('username')
      );
      if (usernameValidation) {
        errors.name = usernameValidation;
      }
    }

    if (values.get('phone')) {
      const phoneValidation = validatePhoneNumber(values.get('phone'));
      if (phoneValidation) {
        errors.phone = phoneValidation;
      }
    }

    if (values.get('fax')) {
      const faxValidation = validatePhoneNumber(values.get('fax'));
      if (faxValidation) {
        errors.fax = faxValidation;
      }
    }

    if (Object.keys(errors).length) {
      throw new SubmissionError(errors);
    }
  };

  const handleFormSubmit = async (formValues) => {
    const values = formValues.toJS();
    let formData = {};

    validate(formValues);

    formData = {
      ...values,
    };

    try {
      await onSubmit(formData);
    } catch (error) {
      if (error && error.name === 'SubmissionError') {
        throw error;
      }
      handleError(error);
    }
  };

  useEffect(() => {
    if (initialValues) {
      const values = initialValues.toJS();

      props.initialize({
        ...values,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} datacy="add-user-form">
      <div className="form-step__wrapper">
        <h5 className="form-step__title">Enter New User Info</h5>
        <div className="form-step__content">
          <div className="row mb-12">
            <div className="col-md-6">
              <Field
                component={Input}
                name="firstName"
                label="First Name *"
                placeholder="John"
                required
                datacy="user-first-name-Field"
              />
            </div>
            <div className="col-md-6">
              <Field
                component={Input}
                name="lastName"
                label="Last Name *"
                placeholder="Doe"
                datacy="user-last-name-Field"
              />
            </div>
          </div>
          <div className="row mb-12">
            <div className="col-md-6">
              <Field
                component={Input}
                name="emailAddress"
                label="Email Address *"
                placeholder="john.doe@company.com"
                required
                datacy="user-email-address-Field"
              />
            </div>
            <div className="col-md-6">
              <Field
                component={Input}
                name="username"
                label="Username *"
                placeholder="johndoe"
                required
                datacy="user-username-Field"
              />
            </div>
          </div>
          <div className="row mb-12">
            <div className="col-md-6">
              <Field
                component={Input}
                name="phone"
                label="Phone Number"
                maskedInput={true}
                mask={MASK_PHONE}
                placeholder="(DDD) DDD-DDDD"
                datacy="user-phone-Field"
              />
            </div>
            <div className="col-md-6">
              <Field
                component={Input}
                name="fax"
                label="Fax Number"
                maskedInput={true}
                mask={MASK_PHONE}
                placeholder="(DDD) DDD-DDDD"
                datacy="user-fax-Field"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
        <button
          type="submit"
          disabled={invalid || submitting}
          className="ap-button ap-button--primary-dark ap-button--primary-md"
          datacy="add-user-form-submit-button"
        >
          Add
        </button>
      </div>
    </form>
  );
};

AddUserForm.propTypes = {};

AddUserForm = reduxForm({
  form: 'addUserForm',
})(AddUserForm);

const mapStateToProps = (state, ownProps) => {};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ change }, dispatch),
  };
};

AddUserForm = connect(mapStateToProps, mapDispatchToProps)(AddUserForm);

export default AddUserForm;
