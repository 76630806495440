import ReactTooltip from 'react-tooltip';
import React, { useCallback, useState } from 'react';

import DenialsAPI from 'API/DenialsAPI';

import { useFetchPmNotesInfo } from './hooks';
import { copyToClipboard } from 'helpers/utils';
import { handleError } from 'helpers/errorHandler';
import { generatePmNote } from 'components/DenialQueue/ActinLogs/utils';

import * as toast from 'components/Shared/toast';

import pmNoteGrayIcon from 'img/note-icon-gray-3x.png';
import pmNoteGreenIcon from 'img/note-icon-green-3x.png';

const PmNoteInfo = (props) => {
  const [isPmNotesCopied, setIsPmNotesCopied] = useState(false);

  const { appealId } = props;

  const { loading, pmNoteData } = useFetchPmNotesInfo(appealId);

  const pmNote = generatePmNote(pmNoteData?.pmNotes);

  const copyNoteToClipboard = useCallback((pmNote) => {
    copyToClipboard(pmNote);
    toast.success({
      title: 'Success',
      message: 'Note copied to clipboard.',
    });
  }, []);

  const handleNoteCopy = useCallback(async () => {
    const { dqId } = pmNoteData;
    if (!dqId) {
      return;
    }
    copyNoteToClipboard(pmNote);
    if (!isPmNotesCopied) {
      try {
        await DenialsAPI.copyPmNote(dqId);
        setIsPmNotesCopied(true);
      } catch (error) {
        setIsPmNotesCopied(false);
        handleError(error);
      }
    }
  }, [copyNoteToClipboard, isPmNotesCopied, pmNote, pmNoteData]);

  return (
    pmNoteData?.pmNotes && (
      <div className="col-xs-12 d-flex justify-content-center successful-submission-pm-note">
        <button
          className="submit__button submit__button--fullwidth ap-button--secondary-outline"
          data-tip={!loading ? pmNote : ''}
          data-for="pmNoteTooltip"
          onClick={!loading ? handleNoteCopy : undefined}
        >
          <img
            className="cursor-pointer pm-notes--icon"
            src={!isPmNotesCopied ? pmNoteGreenIcon : pmNoteGrayIcon}
            alt="PM note"
          />
          <p className="mb-0">Copy PM Note</p>
        </button>
        <ReactTooltip
          id="pmNoteTooltip"
          className="ap-info-tooltip-background ap-info-tooltip-background--mw-800 ap-info-tooltip"
          effect="solid"
        />
      </div>
    )
  );
};

export default PmNoteInfo;
