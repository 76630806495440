import React from 'react';
import TextArea from 'react-textarea-autosize';
import classNames from 'classnames';

export const renderReduxTextarea = ({
  input,
  label,
  disabled,
  key,
  maxLength,
  placeholder,
  className,
  textAreaClassName,
  meta: { touched, error },
}) => (
  <div className={classNames(className)}>
    <label className="textfield__label" htmlFor={input.name}>
      {label}
    </label>
    <div>
      <span>
        <TextArea
          tabIndex={0}
          placeholder={placeholder}
          className={classNames(
            'textfield__input',
            textAreaClassName,
            {
              'textfield__input--error': error && touched,
            },
            {
              'textfield__input--disabled': disabled,
            }
          )}
          maxLength={maxLength}
          onChange={input.onChange}
          value={input.value}
          disabled={disabled}
          key={key}
        />
      </span>
    </div>
  </div>
);
