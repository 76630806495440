import ReactDOM from 'react-dom/client';
import React, { useCallback, useEffect, useRef } from 'react';

import PortalInfo from './PortalInfo';
import * as toast from 'components/Shared/toast';

import { useFetchPortalInfo } from './hooks';

const OpenWindowWithPortalInfo = ({
  portalInfo,
  payerName,
  payerContactName,
}) => {
  const newWindowRef = useRef(null);
  const rootRef = useRef(null);
  const openWindowWithDocumentRequest = useCallback(async () => {
    newWindowRef.current = await window.documentPictureInPicture.requestWindow({
      width: '350',
      height: '750',
    });

    const pipDiv = newWindowRef.current.document.createElement('div');
    pipDiv.setAttribute('id', 'pip-root');
    newWindowRef.current.document.body.append(pipDiv);
    newWindowRef.current.document.label = 'Portal Assist';
    newWindowRef.current.document.body.style.margin = 0;
    newWindowRef.current.document.body.style.background = '#fff';
    const pipRoot = ReactDOM.createRoot(
      newWindowRef.current.document.getElementById('pip-root')
    );
    pipRoot.render(
      <PortalInfo
        newWindowRef={newWindowRef}
        data={portalInfo}
        payerName={payerName}
        payerContactName={payerContactName}
      />
    );
  }, [portalInfo, payerName, payerContactName]);

  const openWindow = useCallback(() => {
    if (newWindowRef.current && !newWindowRef.current.closed) {
      if (rootRef.current) {
        rootRef.current.render(
          <PortalInfo
            newWindowRef={newWindowRef}
            data={portalInfo}
            payerName={payerName}
            payerContactName={payerContactName}
          />
        );
      }
      return;
    }

    newWindowRef.current = window.open(
      '',
      '_blank',
      'width=350,height=750,left=40,top=160'
    );

    if (
      !newWindowRef.current ||
      newWindowRef.current.closed ||
      typeof newWindowRef.current.closed === 'undefined'
    ) {
      toast.error({
        title: 'Error',
        message:
          'Unable to open the portal assist window. Please allow the popup and try again.',
      });
      return;
    }

    newWindowRef.current.onload = () => {
      newWindowRef.current.blur();
      newWindowRef.current.focus();
      newWindowRef.resizeTo(350, 750);
      newWindowRef.moveTo(40, 160);
    };

    newWindowRef.current.document.label = 'Portal Assist';
    newWindowRef.current.document.body.style.margin = 0;
    newWindowRef.current.document.body.style.background = '#fff';

    const container = newWindowRef.current.document.createElement('div');
    container.id = 'root';
    newWindowRef.current.document.body.appendChild(container);

    rootRef.current = ReactDOM.createRoot(container);
    rootRef.current.render(
      <PortalInfo
        newWindowRef={newWindowRef}
        data={portalInfo}
        payerName={payerName}
        payerContactName={payerContactName}
      />
    );

    newWindowRef.current.addEventListener('unload', (event) => {
      if (rootRef.current) {
        rootRef.current.unmount();
      }
      newWindowRef.current = null;
      rootRef.current = null;
    });
  }, [portalInfo, payerName, payerContactName]);

  useEffect(() => {
    if (!window.documentPictureInPicture) {
      openWindow();
    } else {
      openWindowWithDocumentRequest();
    }

    return () => {
      if (newWindowRef.current) {
        newWindowRef.current.close();
      }
    };
  }, [openWindow, openWindowWithDocumentRequest]);

  return null;
};
const PortalAssist = ({ appealId, appealData }) => {
  const { portalInfo, loading } = useFetchPortalInfo(appealId, appealData);

  if (loading) {
    return null;
  }

  return (
    <OpenWindowWithPortalInfo
      portalInfo={portalInfo}
      payerName={appealData.payer.name}
      payerContactName={appealData.payerContact.name}
    />
  );
};

PortalAssist.propTypes = {};

export default PortalAssist;
