const filters = [
  {
    name: 'status',
    query: false,
    useAsURLParam: true,
  },
  {
    name: 'payer_id',
    query: false,
    useAsURLParam: true,
  },
  {
    name: 'agent_id',
    query: false,
    useAsURLParam: true,
  },
  {
    name: 'reason_code',
    query: false,
    useAsURLParam: true,
  },
  {
    name: 'cpt_code',
    query: false,
    useAsURLParam: true,
  },
  {
    name: 'provider_id',
    query: false,
    useAsURLParam: true,
  },
  {
    name: 'practice_identifier',
    query: false,
    useAsURLParam: true,
  },
  {
    name: 'claim',
    query: false,
    useAsURLParam: true,
  },
  {
    name: 'patient',
    query: false,
    useAsURLParam: true,
  },
  {
    name: 'search',
    query: false,
    useAsURLParam: true,
  },
  {
    name: 'custom_patient_id',
    query: false,
    useAsURLParam: true,
  },
  {
    name: 'claimNumberSort',
    query: false,
    useAsURLParam: false,
  },
  {
    name: 'pmSystemAccountIdSort',
    query: false,
    useAsURLParam: false,
  },
  {
    name: 'customPatientIdSort',
    query: false,
    useAsURLParam: false,
  },
  {
    name: 'deadlineSort',
    query: false,
    useAsURLParam: false,
  },
  {
    name: 'submittedAtSort',
    query: false,
    useAsURLParam: false,
  },
  {
    name: 'patientNameSort',
    query: false,
    useAsURLParam: false,
  },
  {
    name: 'billedAmountSort',
    query: false,
    useAsURLParam: false,
  },
  {
    name: 'payerNameSort',
    query: false,
    useAsURLParam: false,
  },
  {
    name: 'agentNameSort',
    query: false,
    useAsURLParam: false,
  },
  {
    name: 'statusSort',
    query: false,
    useAsURLParam: false,
  },
  {
    name: 'deniedAmountSort',
    query: false,
    useAsURLParam: false,
  },
  {
    name: 'reasonCodeSort',
    query: false,
    useAsURLParam: false,
  },
  {
    name: 'cptCodeSort',
    query: false,
    useAsURLParam: false,
  },
  {
    name: 'providerNameSort',
    query: false,
    useAsURLParam: false,
  },
  {
    name: 'daysSinceSubmissionSort',
    query: false,
    useAsURLParam: false,
  },
  {
    name: 'ordering',
    query: false,
    useAsURLParam: true,
  },
  {
    name: 'after',
    query: false,
    useAsURLParam: true,
  },
];

export default filters;
