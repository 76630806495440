import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import errorImg from '../../../img/error.svg';
import closeIcon from '../../../img/close-modal.svg';
import { GENERIC_ERROR_TITLE } from './errorMessages';

export default class ErrorBanner extends Component {
  static propTypes = {
    errors: PropTypes.array.isRequired,
  };

  constructor() {
    super();
    this.state = { hasClosed: false };
  }

  hideError = () => {
    this.setState({ hasClosed: true });
  };

  getBannerClass = () => {
    return classNames('error-banner', { hide: this.state.hasClosed });
  };

  renderErrorBody = () => {
    const errors = this.props.errors;
    const messages = [];
    let title;
    if (errors.length === 1) {
      title = errors[0].title;
    } else {
      title = GENERIC_ERROR_TITLE;
    }
    errors.forEach((error, i) => {
      messages.push(
        <li key={'error_' + i} className="error-banner__text-list-message">
          {error.linkTo ? (
            <Link to={error.linkTo} datacy="error-banner-Link">
              {' '}
              {error.message}
            </Link>
          ) : (
            error.message
          )}
        </li>
      );
    });

    return (
      <div className="error-banner__text" datacy="ErrorBanner">
        <div className="error-banner__text-title">{title}</div>
        <ul className="error-banner__text-list">{messages}</ul>
      </div>
    );
  };

  render() {
    return (
      <div className={this.getBannerClass()}>
        <div className="error-banner__rightside">
          <img
            alt="Error"
            className="error-banner__rightside-img"
            src={errorImg}
          />
        </div>
        {this.renderErrorBody()}
        <img
          alt="Hide error"
          className="error-banner__closeicon"
          src={closeIcon}
          onClick={this.hideError}
        />
      </div>
    );
  }
}
