/**
 * Created by goer on 11/30/16.
 */

import { Serializer as JSONAPISerializer } from 'jsonapi-serializer';

export const AppealFaxSubmitSerializer = new JSONAPISerializer(
  'AppealSubmitFax',
  {
    attributes: [
      'fax',
      'coverLetterPhone',
      'coverLetterRe',
      'coverLetterCc',
      'coverLetterText',
      'externalLink',
      'submissionId',
      'deliveryOptions',
      'Appeal',
      'faxFrom',
      'faxTo',
    ],
    Appeal: {
      ref: 'id',
      included: false,
    },
    pluralizeType: false,
    keyForAttribute: 'underscore_case',
  }
);

export const AppealDirectSubmitSerializer = new JSONAPISerializer(
  'AppealSubmitDirect',
  {
    attributes: ['submissionId', 'submissionType', 'deliveryOptions'],
    pluralizeType: false,
    keyForAttribute: 'underscore_case',
  }
);

export const AppealMailSubmitSerializer = new JSONAPISerializer(
  'AppealSubmitMail',
  {
    attributes: [
      'address_line1',
      'address_line2',
      'city',
      'state',
      'zipcode',
      'country',
      'externalLink',
      'submissionId',
      'deliveryOptions',
      'Appeal',
      'certified',
    ],
    Appeal: {
      ref: 'id',
      included: false,
    },
    pluralizeType: false,
    keyForAttribute: 'underscore_case',
  }
);

export const AppealEmailSubmitSerializer = new JSONAPISerializer(
  'AppealSubmitEmail',
  {
    attributes: [
      'subject',
      'message',
      'recipientEmail',
      'ccEmails',
      'submissionId',
      'Appeal',
    ],
    Appeal: {
      ref: 'id',
      included: false,
    },
    pluralizeType: false,
    keyForAttribute: 'underscore_case',
  }
);
