import moment from 'moment';
import { get } from 'lodash';
import classnames from 'classnames';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import './style.css';

import Button from 'components/common/button';
import * as toast from 'components/Shared/toast';
import AppealioPopup from 'components/Shared/AppealioPopup';

import { formatToMoney } from 'helpers/money';

import { CLAIM_STATUS_TRACKER } from 'constants/routes';
import { CLAIM_STATUS_CHECK_CRON_OPTIONS } from 'constants/options';
import ScheduleInformation from 'components/UhcClaimStatusCheck/ScheduleInformation';
import ScheduleForm from 'components/UhcClaimStatusCheck/ScheduleForm';
import {
  addClaimToStatusTrackerAviality,
  sortAvailityClaimStatuses,
} from 'API/PayerAPI';
import { getFormattedDate } from 'helpers/dateFormats';

const TitleText = ({ title, className }) => (
  <div className={classnames('success-modal-title', className)}>{title}</div>
);
const Value = ({ value, isBold, className }) => (
  <div
    className={classnames('success-modal-value', className, {
      'success-modal-value-bold': isBold,
    })}
  >
    {value}
  </div>
);

const ClaimData = ({
  label,
  value,
  labelClassName = 'col-lg-6',
  valueClassName = 'col-lg-6',
}) => {
  return (
    <div className="row mb-4">
      <div className={labelClassName}>
        <Value value={label} isBold />
      </div>
      <div className={valueClassName}>
        <Value value={value} />
      </div>
    </div>
  );
};

/**
 * Transforms Availity date formats to 'MM/DD/YYYY' for consistency with the system's internal date format.
 * Availity dates are provided with timezone information (e.g., "2023-08-07T04:00:00.000+00:00"), (T04:00:00.000+00:00) common for both fromDate and toDate,
 * but the system stores dates as 'YYYY-MM-DD' (e.g., "2023-08-07"). This function adjusts the format
 * for display or processing and returns 'N/A' if the date is not provided.
 *
 * @param {String} date - The service date string from Availity, possibly with timezone information.
 * @returns {String} - Formatted date in 'MM/DD/YYYY' format, or 'N/A' for null/undefined input.
 */
const formatServiceDate = (date) => {
  if (!date) {
    return 'N/A';
  }

  const dateWithoutTimezone = moment.utc(date).format('YYYY-MM-DD');

  return getFormattedDate(dateWithoutTimezone, 'MM/DD/YYYY', true);
};

const ClaimDetailView = ({ claimStatus, claimInfo, rowNumber }) => {
  const statusDetail = get(claimStatus, 'statusDetails[0]');
  const claimNumber = get(claimStatus, 'claimControlNumber');
  const patientInformation = get(claimInfo, 'patient');
  const subscriberInformation = get(claimInfo, 'subscriber');

  return (
    <div className="claim-detail-view">
      <div>
        <TitleText title="Patient Information" className="mb-12" />
        {Object.entries(patientInformation).length === 0 ? (
          <div>No Patient Information </div>
        ) : (
          <div>
            <div>
              <ClaimData
                label="First Name"
                labelClassName="col-lg-3"
                valueClassName="col-lg-9"
                value={get(patientInformation, 'firstName') || 'N/A'}
              />
            </div>
            <div>
              <ClaimData
                label="Last Name"
                labelClassName="col-lg-3"
                valueClassName="col-lg-9"
                value={get(patientInformation, 'lastName') || 'N/A'}
              />
            </div>
            <div>
              <ClaimData
                label="Date Of Birth"
                labelClassName="col-lg-3"
                valueClassName="col-lg-9"
                value={
                  getFormattedDate(get(patientInformation, 'birthDate')) ||
                  'N/A'
                }
              />
            </div>
            <div>
              <ClaimData
                label="Patient Account #"
                labelClassName="col-lg-3"
                valueClassName="col-lg-9"
                value={get(patientInformation, 'accountNumber') || 'N/A'}
              />
            </div>
            <div>
              <ClaimData
                label="Member ID"
                labelClassName="col-lg-3"
                valueClassName="col-lg-9"
                value={get(subscriberInformation, 'memberId') || 'N/A'}
              />
            </div>
          </div>
        )}
      </div>
      <div>
        <TitleText
          title={
            rowNumber ? `Claim Information #${rowNumber}` : `Claim Information`
          }
          className="mt-12 mb-12"
        />
        {rowNumber && (
          <div>
            <p className="mb-4">
              <span className="text-bold">Status</span>
              <span>: {get(statusDetail, 'category')}</span>
            </p>
          </div>
        )}
        <div>
          <p className="mb-4">
            <span className="text-bold">
              {get(statusDetail, 'categoryCode')}
            </span>
            <span>: {get(statusDetail, 'category')}</span>
          </p>
          <p>
            <span className="text-bold">{get(statusDetail, 'statusCode')}</span>
            <span>: {get(statusDetail, 'status')}</span>
          </p>
        </div>
        <div>
          <ClaimData
            label="Claim ID"
            value={claimNumber || 'N/A'}
            labelClassName="col-lg-3"
            valueClassName="col-lg-9"
          />
        </div>
        <div className="row">
          <div className="col-lg-6">
            <ClaimData
              label="Check Number"
              value={get(statusDetail, 'checkNumber') || 'N/A'}
            />
          </div>
          <div className="col-lg-6">
            <ClaimData
              label="Check Date"
              value={
                getFormattedDate(get(statusDetail, 'remittanceDate')) || 'N/A'
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <ClaimData
              label="Service Date From"
              value={formatServiceDate(get(claimInfo, 'fromDate'))}
            />
          </div>
          <div className="col-lg-6">
            <ClaimData
              label="Service Date To"
              value={formatServiceDate(get(claimInfo, 'toDate'))}
            />
          </div>
        </div>
        <div className="row mb-12">
          <div className="col-lg-6">
            <ClaimData
              label="Effective Date"
              value={
                getFormattedDate(get(statusDetail, 'effectiveDate')) || 'N/A'
              }
            />
          </div>
          <div className="col-lg-6">
            <ClaimData
              label="Finalized Date"
              value={
                getFormattedDate(get(statusDetail, 'finalizedDate')) || 'N/A'
              }
            />
          </div>
        </div>
      </div>
      <div>
        <TitleText title="Line Level Information" className="mt-12 mb-12" />
        {get(claimStatus, 'serviceLines', []).length === 0 ? (
          <div>No line level information</div>
        ) : (
          <div>
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-2">
                <Value value="Billed Amount" isBold />
              </div>
              <div className="col-lg-2">
                <Value value="Paid Amount" isBold />
              </div>
              <div className="col-lg-2">
                <Value value="Quantity" isBold />
              </div>
              <div className="col-lg-2">
                <Value value="Procedure Code" isBold />
              </div>
              <div className="col-lg-2">
                <Value value="Procedure Qualifier Code" isBold />
              </div>
            </div>
            {get(claimStatus, 'serviceLines', []).map((item, idx) => (
              <div className="row" key={idx}>
                <div className="col-lg-1">{idx + 1}</div>
                <div className="col-lg-2">
                  <Value
                    value={formatToMoney(get(item, 'chargeAmount'), true)}
                  />
                </div>
                <div className="col-lg-2">
                  <Value
                    value={formatToMoney(get(item, 'paymentAmount'), true)}
                  />
                </div>
                <div className="col-lg-2">
                  <Value value={get(item, 'quantity')} />
                </div>
                <div className="col-lg-2">
                  <Value value={get(item, 'procedureCode')} />
                </div>
                <div className="col-lg-2">
                  <Value value={get(item, 'procedureQualifierCode')} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const SuccessModal = (props) => {
  const {
    claimInfo,
    setClaimInfo,
    onCloseClick,
    requestData,
    showQuickActions = true,
  } = props;

  const [isExpandedView, setIsExpandedView] = useState(false);

  const [isScheduleFormOpen, setIsScheduleFormOpen] = useState(false);
  const history = useHistory();

  const onSubmitForm = async (data) => {
    const { schedule, noOfRetries } = data.toJS();
    try {
      await addClaimToStatusTrackerAviality({
        ...requestData,
        schedule: schedule.value,
        no_of_retries: noOfRetries,
      });
      toast.success({
        title: '',
        message:
          'Your Claim has been successfully added to the Status Tracker.',
      });

      setClaimInfo({
        ...claimInfo,
        appealioClaimSchedule: {
          ...claimInfo.appealioClaimSchedule,
          schedule: schedule.value,
          noOfRetries,
        },
      });
    } catch (error) {
      toast.error({
        title: '',
        message:
          'Sorry, we could not add this Claim to the Status Tracker. Please try again later.',
      });
    }
    setIsScheduleFormOpen(false);
  };
  const appealioClaimSchedule = claimInfo && claimInfo.appealioClaimSchedule;
  const handleViewHistoryClick = () => {
    history.push({
      pathname: CLAIM_STATUS_TRACKER,
      search: `?id=${appealioClaimSchedule.id}&openHistory=true`,
    });
    onCloseClick();
  };

  const claimStatus = get(
    claimInfo,
    'claimStatuses[0].statusDetails[0].category'
  );

  const DATA_SEARCH_UNSUCCESSFUL_CODE = 'D0';
  const statusCode = get(
    claimInfo,
    'claimStatuses[0].statusDetails[0].categoryCode'
  );

  const isMultipleStatus = get(claimInfo, 'claimStatuses', []).length > 1;

  const sortedClaimStatuses = sortAvailityClaimStatuses(
    get(claimInfo, 'claimStatuses', []),
    claimInfo.claimNumber
  );
  const claimStatuses = isExpandedView
    ? sortedClaimStatuses
    : sortedClaimStatuses.slice(0, 1);

  return (
    <AppealioPopup
      title="Claim Status Details"
      className="claim-status-detail-popup uhc-claim-status-check-success-popup"
      isPopupAboveFlyover={true}
    >
      <div>
        <div
          className={classnames(
            'd-flex align-items-center justify-content--space-between',
            {
              'mb-12 mt-12': !showQuickActions,
            }
          )}
        >
          <div className="uhc-claim-message">
            {showQuickActions && (
              <div className="uhc-claim-message__title">
                We found your claim!
              </div>
            )}
            {!isMultipleStatus ? (
              statusCode !== DATA_SEARCH_UNSUCCESSFUL_CODE ? (
                <div>
                  {showQuickActions ? 'Latest' : ''} Claim Status for{' '}
                  <strong>
                    {`${claimInfo.patient.lastName}`}
                    ’s{' '}
                    {claimInfo.claimNumber
                      ? `[Claim #: ${claimInfo.claimNumber}]`
                      : 'Claim'}
                  </strong>{' '}
                  is <strong>{claimStatus || 'N/A'}</strong> as of{' '}
                  <strong>
                    {getFormattedDate(get(claimInfo, 'updatedDate'))}
                  </strong>
                  .
                </div>
              ) : (
                <div>
                  {showQuickActions ? 'Latest' : ''} Claim Status for{' '}
                  <strong>
                    {`${claimInfo.patient.lastName}`}
                    ’s{' '}
                    {claimInfo.claimNumber
                      ? `[Claim #: ${claimInfo.claimNumber}]`
                      : 'Claim'}
                  </strong>{' '}
                  can not be found. The payer is unable to return status on the
                  requested claim based on the submitted search criteria.
                </div>
              )
            ) : (
              <div>
                Multiple statuses found for{' '}
                <strong>
                  {`${claimInfo.patient.lastName}`}
                  ’s{' '}
                  {claimInfo.claimNumber
                    ? `[Claim #: ${claimInfo.claimNumber}]`
                    : 'Claim'}
                </strong>
              </div>
            )}
          </div>
          <div className="uhc-claim-status-check-close-btn-container">
            <Button type="secondary-outline" onClick={onCloseClick}>
              Close
            </Button>
          </div>
        </div>
        {showQuickActions && (
          <div className="claim-status-tracker-action-container">
            {isScheduleFormOpen ? (
              <ScheduleForm
                onSubmit={onSubmitForm}
                setIsScheduleFormOpen={setIsScheduleFormOpen}
              />
            ) : !appealioClaimSchedule.schedule ? (
              <Button
                type="secondary"
                className="add-claim-status-tracker"
                onClick={() => setIsScheduleFormOpen(true)}
              >
                Schedule Automatic Status Checks
              </Button>
            ) : (
              <ScheduleInformation
                appealioClaimSchedule={appealioClaimSchedule}
                onSubmit={onSubmitForm}
                initialValues={{
                  noOfRetries: appealioClaimSchedule.noOfRetries,
                  schedule: CLAIM_STATUS_CHECK_CRON_OPTIONS.find(
                    (option) => option.value === appealioClaimSchedule.schedule
                  ),
                }}
                onViewHistoryClick={handleViewHistoryClick}
              />
            )}
          </div>
        )}
      </div>
      {!isMultipleStatus ? (
        <ClaimDetailView
          claimInfo={claimInfo}
          claimStatus={get(claimInfo, 'claimStatuses[0]')}
        />
      ) : (
        <div className="claim-status-detail-container">
          {claimStatuses.map((claimStatus, idx) => (
            <ClaimDetailView
              key={idx}
              rowNumber={idx + 1}
              claimInfo={claimInfo}
              claimStatus={claimStatus}
            />
          ))}
        </div>
      )}

      {isMultipleStatus && (
        <div className="d-flex justify-content-center">
          <Button
            className="mt-8"
            onClick={() => setIsExpandedView(!isExpandedView)}
          >
            {isExpandedView ? 'View Less' : 'View More'}
          </Button>
        </div>
      )}
    </AppealioPopup>
  );
};

export default SuccessModal;
