import React from 'react';
import { Field } from 'redux-form/immutable';

import Input from 'components/common/input';
import { renderCheckbox } from '../MailFormFields/MailFormFields';
import { renderReduxTextarea } from 'components/CreateAppeal/reduxTextarea';
import { renderReduxDropdownV2 } from 'components/CreateAppeal/reduxReactSelect';
import { normalizeMoney } from 'components/CreateAppeal/DenialInformation/CPTRowConfig';

import './style.css';

const DirectSubmissionFields = ({
  disableAmountOwed,
  change,
  reconReasons,
  isDirectRecon,
}) => {
  return (
    <div>
      {isDirectRecon && (
        <div className="mb-10">
          <div className="row mb-8">
            <div className="col-lg-6">
              <Field
                name="amtOwed"
                label="Amount Requested: *"
                placeholder="$0"
                component={Input}
                format={(value) => {
                  if (!value) return value;
                  if (isNaN(value) || Number(value) <= 0) {
                    return '';
                  }
                  let formattedCurrency = String(value);
                  if (!formattedCurrency.startsWith('$')) {
                    formattedCurrency = '$' + value;
                  }
                  return formattedCurrency;
                }}
                normalize={normalizeMoney}
                className="mb-4"
                disabled={disableAmountOwed}
                onChange={(e) => {
                  change('dontKnowDollarAmt', false);
                }}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-normal ml-8">
                <Field
                  name="dontKnowDollarAmt"
                  component={renderCheckbox}
                  type="checkbox"
                  className="mr-4 mt-34"
                  defaultChecked={false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      change('amtOwed', '$0');
                    }
                  }}
                />
                I don't know
              </label>
            </div>
          </div>
          <div className="row mb-8">
            <div className="col-lg-6">
              <label className="fw-normal">Request Reason: *</label>
              <Field
                name="reqReason"
                placeholder=""
                component={renderReduxDropdownV2}
                dropdownOptions={reconReasons.map((reason) => ({
                  value: reason,
                  key: reason,
                }))}
                hideIcon
              />
            </div>
          </div>
        </div>
      )}
      <div className="mb-10">
        <Field
          name="provComments"
          label="Provider Comments: *"
          placeholder=""
          className="direct-form-field__provider-comments"
          component={renderReduxTextarea}
          required={true}
        />
      </div>
    </div>
  );
};

export default DirectSubmissionFields;
