import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Steps from './Steps.js';

export default class AppealHeader extends Component {
  static props = {
    stepItems: PropTypes.array.isRequired,
    activeStep: PropTypes.number,
    isStandAlone: PropTypes.bool,
  };

  static defaultProps = {
    stepItems: [{ showSteps: false }],
    activeStep: 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      stepTitles: this.getStepTitles(),
    };
  }

  getStepTitles() {
    return this.props.stepItems.reduce((collected, item) => {
      if (item.showSteps !== false) return [...collected, item.title];
      return collected;
    }, []);
  }

  render() {
    if (this.props.isStandAlone) {
      return (
        <div className="row appeal-header__row-no-padding appeal-header">
          <div className="col-xs-4 appeal-header__text-container">
            <span className="appeal-header__left-text">Package Submission</span>
          </div>
          <div className="col-xs-4">{this.renderSteps()}</div>
        </div>
      );
    }

    return (
      <div className="row appeal-header__row-no-padding appeal-header">
        <div className="col-xs-4 appeal-header__text-container">
          <span datacy="active-step-title" className="appeal-header__left-text">
            {this.props.stepItems[this.props.activeStep].title}
          </span>
        </div>
        <div className="col-xs-4">{this.renderSteps()}</div>
      </div>
    );
  }

  renderSteps() {
    if (this.props.stepItems[this.props.activeStep].showSteps !== false) {
      const stepTitles = this.getStepTitles();
      return (
        <Steps stepTitles={stepTitles} activeStep={this.props.activeStep} />
      );
    }
  }
}
