import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push, replace } from 'connected-react-router';
import { SubmissionError } from 'redux-form/immutable';

import DenialInformation from './DenialInformation';
import { defaultMoneyInitialValues } from './CPTRowConfig';
import LoadingBlockHelper from '../../Shared/LoadingBlockHelper';
import { CreateAppealMode, CreateAppealModes } from './CreateAppealMode';

import {
  getAppealFromState,
  isAppealReadyToUse,
} from '../../../redux/reducers/AppealStore';
import {
  getCurrentAppealData,
  getCurrentAppealMode,
  getCurrentAppealPreFillData,
  getCurrentAppealStep,
  isCurrentAppealMedicalRecord,
} from '../../../redux/reducers/createAppealStore';
import {
  getAppealById,
  setCurrentStep,
  createDenialInformation,
  setAppealPreFillData,
} from '../../../redux/actions/createAppealActions';
import CreateAppealAPI from '../../../API/CreateAppealAPI';
import { handleError } from '../../../helpers/errorHandler';
import { getUserInfo } from '../../../redux/reducers/loginStore';
import { transformToDateInput } from '../../../API/Serializers/Transforms';
import {
  CREATE_APPEAL_APPEAL_LETTER,
  CREATE_APPEAL_DOCUMENTS,
} from 'constants/routes';
import { APPEAL_STEPS } from '../../../constants/appConstants';

import { validateAppealStep } from 'components/CreateAppeal/createAppealUtils';
class DenialInformationContainer extends Component {
  static propTypes = {
    mode: PropTypes.oneOf(CreateAppealModes),
    shouldDisplayFooter: PropTypes.bool,
    isWorkerCompensationPayer: PropTypes.bool,
    isImportedAppeal: PropTypes.bool,
  };

  static defaultProps = {
    mode: CreateAppealMode.NewAppeal,
    shouldDisplayFooter: true,
    isWorkerCompensationPayer: false,
    isImportedAppeal: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  async componentDidMount() {
    this.props.actions.setCurrentStep(1);
    await this.props.actions
      .getAppealById(this.props.appealID)
      .then(this.fetchAndSetPreFillData);
    validateAppealStep(
      this.props.appealStep,
      APPEAL_STEPS.DENIAL_INFO,
      this.props.currentAppealID,
      this.props.actions.replace
    );
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.isMedicalRecordsSubmission === true) {
      this.props.actions.push(
        `${CREATE_APPEAL_APPEAL_LETTER}?appealId=${this.props.appealID}`
      );
    }
  }

  fetchAndSetPreFillData = async ({ value }) => {
    if (!value.claimNumber) {
      return;
    }

    try {
      const data = await CreateAppealAPI.getPrefillAppealData(
        value.claimNumber
      );
      if (data) {
        this.props.actions.setAppealPreFillData(data);
      }
    } catch (error) {
      handleError(error);
    }
  };

  render() {
    return (
      <div className="row denial-info--container">
        <LoadingBlockHelper
          isLoading={this.props.isLoading}
          className="appeal-box__loading"
        >
          <DenialInformation
            initialValues={this.props.initialValues}
            onFormSubmit={this.submit}
            integrationType={this.props.integrationType}
            mode={this.props.mode}
            appealID={this.props.appealID}
            shouldDisplayFooter={this.props.shouldDisplayFooter}
            eobID={this.props.eobID}
            isImportedAppeal={this.props.isImportedAppeal}
            isWorkerCompensationPayer={this.props.isWorkerCompensationPayer}
            isSubmitting={this.state.isSubmitting}
            setSubmitting={this.setSubmitting}
          />
        </LoadingBlockHelper>
      </div>
    );
  }

  syncClaimStatus = (clientPartitionId, claimNumber) =>
    CreateAppealAPI.syncClaimStatus(clientPartitionId, claimNumber, 1).catch(
      handleError
    );

  submit = (proceduresParams, updateAppealParams, skipStep) => {
    this.setState({ isLoading: true });
    const initialEobProcedures = _.get(this.props, 'initialValues.cpts', []);
    const finalEobProcedures = _.get(proceduresParams, 'procedures', []);

    const deletedEobProcedures = _.differenceBy(
      initialEobProcedures,
      finalEobProcedures,
      'id'
    );
    const procedureIdsToDelete = deletedEobProcedures.map(({ id }) => id);

    const { claimNumber } = updateAppealParams.attrs;
    const { clientPartitionId, isImportedAppeal } = this.props;

    return this.props.actions
      .createDenialInformation(
        updateAppealParams,
        proceduresParams,
        procedureIdsToDelete
      )
      .then((res) => {
        if (!isImportedAppeal && clientPartitionId && claimNumber) {
          this.syncClaimStatus(clientPartitionId, claimNumber);
        }

        this.setState({ isLoading: false });
        this.props.actions.getAppealById(this.props.appealID);

        const route =
          isImportedAppeal && skipStep === true
            ? CREATE_APPEAL_DOCUMENTS
            : CREATE_APPEAL_APPEAL_LETTER;
        this.props.actions.push(`${route}?appealID=${this.props.appealID}`);
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        throw new SubmissionError();
      });
  };

  setSubmitting = (isSubmitting) => {
    this.setState({ isSubmitting });
  };
}

function mapStateToProps(state, ownProps) {
  const { practice: clientPartitionId } = getUserInfo(state);
  const currentAppeal = getCurrentAppealData(state);
  const isImportedAppeal = currentAppeal && currentAppeal.get('imported');

  let appealAndEOBIds = {};
  if (currentAppeal) {
    appealAndEOBIds = {
      appealID: currentAppeal.get('id'),
      eobID: currentAppeal.getIn(['eob', 'id']),
    };
  }

  const mode = getCurrentAppealMode(state);
  const prefillData = getCurrentAppealPreFillData(state);

  const appealState = getAppealFromState(state, appealAndEOBIds.appealID);
  const isAppealReady = isAppealReadyToUse(state, appealAndEOBIds.appealID);

  if (!isAppealReady) {
    return {
      ...appealAndEOBIds,
      isLoading: true,
      mode,
    };
  } else {
    const { appeal } = appealState;
    const isWorkerCompensationPayer = _.get(
      appeal,
      'payer.isWorkerCompensationPayer',
      false
    );
    const { claimNumber } = appeal;
    const prefillEOBProcedures = prefillData
      ? prefillData.get('eob_procedures', [])
      : [];
    const prefillDeniedAt = prefillData ? prefillData.get('denied_at', '') : '';

    const appealProcedures = !_.isEmpty(appeal.eob.procedures)
      ? appeal.eob.procedures
      : prefillEOBProcedures;
    const denialDate = appeal.deniedAt
      ? transformToDateInput(appeal.deniedAt)
      : prefillDeniedAt;

    const deadline = appeal.deadline
      ? transformToDateInput(appeal.deadline)
      : '';
    // we always show one empty EOBProcedure so if appeal has empty procedures array we
    // prepare placeholder procedure
    const procedures = _.isEmpty(appealProcedures) ? [{}] : appealProcedures;

    procedures.forEach((procedure) => {
      _.defaults(procedure, defaultMoneyInitialValues);
    });

    /**
     * INFO: Reset deductible, coInsurance & adjCodes for manual appeal with worker compensation enabled payer
     */
    const extractedCpts = procedures.map((procedure) => {
      if (isImportedAppeal || !isWorkerCompensationPayer) {
        return procedure;
      }

      return {
        ...procedure,
        deductible: '',
        co_insurance: '',
        adj_codes: '',
      };
    });

    // Select the single claim line by default
    const extractAndSelectCpts =
      extractedCpts.length === 1
        ? [{ ...extractedCpts[0], is_selected: true }]
        : extractedCpts;

    let workerCompensationPayerFields = {};

    if (isWorkerCompensationPayer) {
      const employer = appeal.employer;
      const injuryDate = appeal.injuryDate
        ? transformToDateInput(appeal.injuryDate)
        : '';

      workerCompensationPayerFields = {
        employer,
        injuryDate,
      };
    }
    const userInfo = getUserInfo(state);
    const integrationType = userInfo && userInfo.integrationType;

    return {
      ...appealAndEOBIds,
      isLoading: false,
      isWorkerCompensationPayer,
      initialValues: {
        claimNumber,
        denialDate,
        deadline,
        cpts: extractAndSelectCpts,
        ...workerCompensationPayerFields,
      },
      isImportedAppeal,
      mode,
      integrationType,
      clientPartitionId,
      currentAppealCreationStep: getCurrentAppealStep(state),
      isMedicalRecordsSubmission: isCurrentAppealMedicalRecord(state),
      appealStep: appeal.step,
    };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setCurrentStep,
        replace,
        push,
        setAppealPreFillData,
        createDenialInformation,
        getAppealById,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DenialInformationContainer);
