import React from 'react';
import classnames from 'classnames';

import emptyIcon from 'img/empty.svg';

const EmptyTableMessage = ({ className = '' }) => {
  return (
    <div
      className={classnames('emptytable', className)}
      datacy="Empty-Table-Message"
    >
      <img alt="Empty Table" className="emptytable__img" src={emptyIcon} />
      <div className="emptytable__text">No results found.</div>
    </div>
  );
};

export default EmptyTableMessage;
