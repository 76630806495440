export const TYPE_OPTIONS = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Appeal',
    value: 'appeal',
  },
  {
    label: 'Record',
    value: 'record',
  },
];

export const IN_PROGRESS_STATUS = 'In Progress';
