import React from 'react';
import { get } from 'lodash';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import ReactSelect, { components } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { BiSearch } from 'react-icons/bi';

const CustomOption = ({ children, ...props }) => {
  const { isActive = true } = props.data;

  return (
    <components.Option
      className={classNames({
        'opacity-50': isActive === false,
      })}
      {...props}
    >
      {children}
    </components.Option>
  );
};

export const renderReduxDropdownV2 = ({
  input,
  datacy,
  dropdownOptions,
  placeholder,
  defaultValue,
  isLoading,
  isDisabled = false,
  isClearable = false,
  hideIcon = false,
  formatOptionLabel = null,
  id,
  useAsyncCreatableSelect = false,
  onCreateOption = () => ({}),
  meta: { touched, error },
  loadOptions,
  handleChange,
  noOptionsMessage,
  reactSelectProps = {},
  dataTip,
}) => {
  const Select = useAsyncCreatableSelect ? AsyncCreatableSelect : ReactSelect;

  const handleOnChange = (value) => {
    const selectedValue = get(value, ['value'], null);
    const data = get(value, ['data'], null);

    input.onChange(selectedValue);
    if (handleChange) {
      handleChange(selectedValue, data);
    }
  };

  const formattedOptionsForReactSelect = dropdownOptions.map(
    ({ key, value, data = null, isActive = true }) => ({
      label: value,
      value: key,
      data,
      isActive,
    })
  );

  const formattedValue =
    formattedOptionsForReactSelect.find(
      (option) => String(option.value) === String(input.value)
    ) || null;

  const formattedDefaultValue =
    formattedOptionsForReactSelect.find(
      (option) => String(option.value) === String(defaultValue)
    ) || null;

  const ValueContainer = ({ children, ...props }) => {
    const { selectProps } = props;
    const { dataTip } = selectProps;
    const randomId = Math.random().toString(36).slice(2, 9);
    return (
      components.ValueContainer && (
        <components.ValueContainer
          {...props}
          {...(dataTip
            ? {
                innerProps: {
                  ...props.innerProps,
                  ...{
                    'data-tip': dataTip,
                    'data-for': `valueContainer-${randomId}`,
                    'data-html': true,
                  },
                },
              }
            : {})}
        >
          {!hideIcon && (
            <BiSearch
              className="search-icon"
              style={{
                position: 'absolute',
                left: 8,
                height: '20px',
                width: '20px',
              }}
            />
          )}
          {children}
          {dataTip && <ReactTooltip id={`valueContainer-${randomId}`} />}
        </components.ValueContainer>
      )
    );
  };

  const styles = {
    valueContainer: (base) => {
      if (hideIcon) {
        return base;
      }

      return {
        ...base,
        paddingLeft: 28,
      };
    },
  };

  return (
    <React.Fragment>
      <Select
        className={classNames('ap-react-select', {
          'ap-react-select--has-error': error,
        })}
        components={{
          IndicatorSeparator: () => null,
          ValueContainer,
          Option: CustomOption,
        }}
        styles={styles}
        prefix="appealio-react-select"
        placeholder={placeholder}
        options={formattedOptionsForReactSelect}
        value={formattedValue}
        defaultValue={formattedDefaultValue}
        onChange={handleOnChange}
        isLoading={isLoading}
        isDisabled={isDisabled}
        isClearable={isClearable}
        formatOptionLabel={formatOptionLabel}
        id={id}
        onCreateOption={onCreateOption}
        loadOptions={loadOptions}
        noOptionsMessage={noOptionsMessage}
        datacy={datacy}
        {...reactSelectProps}
        dataTip={dataTip}
      />
    </React.Fragment>
  );
};
