import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

export default class LoadingIndicator extends Component {
  static propTypes = {
    showing: PropTypes.bool.isRequired,
    isLightIndicator: PropTypes.bool,
  };

  static defaultProps = {
    showing: false,
    isLightIndicator: false,
  };

  render() {
    const contClass = classNames('loading-indicator', this.props.className, {
      hidden: this.props.showing === false,
    });
    return (
      <div className={contClass} datacy="loading-indicator">
        <div
          className={classNames('loading-indicator__animation', {
            'loading-indicator__animation--light': this.props.isLightIndicator,
          })}
        ></div>
        {this.props.children}
      </div>
    );
  }
}
