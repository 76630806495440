import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MaskedInput from 'react-text-mask';

import createNumberMask from 'helpers/createNumberMask';

const defaultMaskOptions = {
  prefix: '$ ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CurrencyInput = ({ input, meta, maskOptions, readOnly, ...props }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  return (
    <div>
      <div>
        <MaskedInput
          readOnly={readOnly}
          {...input}
          mask={currencyMask}
          {...props}
          className={classnames('ap-input', {
            'ap-input--disabled': readOnly,
            'ap-input--error': meta?.touched && meta?.error,
          })}
        />
      </div>
      {meta?.touched && meta?.error && (
        <span className="error">{meta?.error}</span>
      )}
    </div>
  );
};

CurrencyInput.defaultProps = {
  inputMode: 'numeric',
  maskOptions: {},
};

CurrencyInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.bool,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.bool,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.number,
    requireDecimal: PropTypes.bool,
    allowNegative: PropTypes.bool,
    allowLeadingZeroes: PropTypes.bool,
    integerLimit: PropTypes.number,
  }),
};

export default CurrencyInput;
