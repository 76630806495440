import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { BiSearch, BiX } from 'react-icons/bi';
import React, { useState, useEffect, useCallback } from 'react';

import './style.css';

const SearchInput = (props) => {
  const {
    onSearch,
    placeholder = 'Search',
    onReset,
    isOptionChanged = false,
  } = props;
  const [searchString, setSearchString] = useState('');
  const handleReset = useCallback(() => {
    setSearchString('');
    onReset();
  }, [onReset, setSearchString]);

  useEffect(() => {
    if (isOptionChanged) {
      handleReset();
    }
  }, [handleReset, isOptionChanged]);

  const debounceSearch = debounce((e) => onSearch(e), 800);
  const handleChange = (e) => {
    e.persist();
    debounceSearch(e);
    setSearchString(e.target.value);
  };

  return (
    <div className="input-search-box">
      <BiSearch size="20" color="#5E6871" />
      <input
        type="text"
        placeholder={placeholder}
        value={searchString}
        className=""
        onChange={handleChange}
      />
      {searchString.length > 0 && (
        <BiX
          size="24"
          color="#5E6871"
          onClick={handleReset}
          className="input-search-box--clear"
        />
      )}
    </div>
  );
};

SearchInput.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default SearchInput;
