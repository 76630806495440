import React from 'react';
import classnames from 'classnames';

import './style.css';

import closeIcon from 'img/close-modal-white.svg';

import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';

const AppealioPopupWithFooter = ({
  className = '',
  onClosePressed,
  title,
  children,
  actionBtnTitle,
  onActionBtnClick,
  isFooterOutside = true,
  bodyWrapperClass = '',
}) => {
  return (
    <ScreenDimmerComponent>
      <div
        className={classnames('appealio-popup appealio-popup--v3', {
          [className]: className,
        })}
      >
        <div
          className={classnames('appealio-popup--v3__title-wrapper', {
            [bodyWrapperClass]: bodyWrapperClass,
          })}
        >
          <span className="appealio-popup--v3__title">{title}</span>

          {onClosePressed && (
            <img
              alt="Close"
              onClick={() => onClosePressed()}
              className="appealio-popup--v3__close"
              src={closeIcon}
              datacy="popup-close-img"
            />
          )}
        </div>
        <div className="appealio-popup--v3__content">{children}</div>
        {isFooterOutside && (
          <div className="appealio-popup--v3__footer">
            <button
              onClick={() => onActionBtnClick()}
              className="ap-button ap-button--primary-dark ap-button--primary-md "
              datacy="appealio-popup-button"
            >
              {actionBtnTitle}
            </button>
          </div>
        )}
      </div>
    </ScreenDimmerComponent>
  );
};

AppealioPopupWithFooter.propTypes = {};

export default AppealioPopupWithFooter;
