import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import './style.css';

import { AppealStatusCode } from 'API/AppealStatusCode';

const STATUS_CODES = {
  [AppealStatusCode.Denied]: {
    symbol: 'D',
    toolTip: 'Denied',
    className: 'status-circle--denied',
  },
  [AppealStatusCode.Paid]: {
    symbol: 'S',
    toolTip: 'Successful',
    className: 'status-circle--successful',
  },
};

const StatusCircle = ({ statusCode, className = '' }) => {
  const status = STATUS_CODES[statusCode]?.symbol;
  const statusClassName = STATUS_CODES[statusCode]?.className;
  const toolTip = STATUS_CODES[statusCode]?.toolTip || '';
  return (
    status && (
      <div
        className={`status-circle ${statusClassName} ${className}`}
        data-tip={toolTip}
      >
        {status}
        <ReactTooltip effect="solid" place="top" />
      </div>
    )
  );
};

StatusCircle.propTypes = {
  statusCode: PropTypes.number,
  className: PropTypes.string,
};

export default StatusCircle;
