/**
 * Created by alex on 10/28/16.
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ScreenDimmerComponent from '../Shared/ScreenDimmerComponent';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
export default class RightSlideOut extends Component {
  static propTypes = {
    onDimRegionPressed: PropTypes.func,
    isOpen: PropTypes.bool,
  };

  render() {
    return (
      <div datacy="right-slide-out">
        <ReactCSSTransitionGroup
          transitionName="opacity"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
          datacy="opacity-ReactCSSTransitionGroup"
        >
          {this.renderDimmerIfOpened()}
        </ReactCSSTransitionGroup>
        <ReactCSSTransitionGroup
          transitionName="slide"
          transitionEnterTimeout={400}
          transitionLeaveTimeout={500}
          datacy="slide-ReactCSSTransitionGroup"
        >
          {this.renderSlideOutIfOpened()}
        </ReactCSSTransitionGroup>
      </div>
    );
  }

  renderSlideOutIfOpened() {
    if (this.props.isOpen === true) {
      return this.props.children;
    }
  }

  renderDimmerIfOpened() {
    if (this.props.isOpen === true) {
      return <ScreenDimmerComponent onClick={this.props.onDimRegionPressed} />;
    }
  }
}
