import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { SubmissionError, initialize } from 'redux-form';

import Input from 'components/common/input';
import Select from 'components/common/select/Select';

import { handleError } from 'helpers/errorHandler';
import * as AccountsAPI from 'API/AccountSettingsAPI';

let AddPayerIdentifierForm = (props) => {
  const { onSubmit, invalid, submitting, handleSubmit } = props;

  const [loading, setLoading] = useState(true);
  const [partnersInfo, setPartnersInfo] = useState({});

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      setPartnersInfo([]);

      try {
        const res = await AccountsAPI.fetchPartnersInfo();
        const partners = res?.data.map((state) => ({
          label: state.name,
          value: state.id,
        }));
        setPartnersInfo(partners);
      } catch (error) {
        handleError(error);
      }
      setLoading(false);
    };
    fetch();
  }, []);

  const validate = (values) => {
    const errors = {};

    if (!values.partner) {
      errors.partner = 'Partner is required.';
    }
    if (!values.identificationNumber) {
      errors.identificationNumber = 'Identification Number is required.';
    }

    if (Object.keys(errors).length) {
      throw new SubmissionError(errors);
    }
  };

  const handleEDIPayerFormSubmit = async (formValues) => {
    const values = formValues.toJS();
    validate(values);

    await onSubmit(values);
  };

  return (
    <form
      onSubmit={handleSubmit(handleEDIPayerFormSubmit)}
      datacy="add-edi-payer-form"
    >
      <div className="form-step__wrapper">
        <h5 className="form-step__title">Enter new EDI Payer ID</h5>
        <div className="form-step__content">
          <div className="row mb-12">
            <div className="col-md-12">
              <Field
                component={Select}
                name="partner"
                label="Partner *"
                placeholder="Partner"
                options={partnersInfo}
                required
                isDisabled={loading}
              />
            </div>
            <div className="col-md-12 mt-8">
              <Field
                component={Input}
                name="identificationNumber"
                label="Identifier *"
                placeholder="Identifier *"
              />
            </div>
          </div>
        </div>
        <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
          <button
            type="submit"
            disabled={invalid || submitting}
            className="ap-button ap-button--primary-dark ap-button--primary-md"
            datacy="add-edi-payerId-form-submit-button"
          >
            Add
          </button>
        </div>
      </div>
    </form>
  );
};

AddPayerIdentifierForm = reduxForm({
  form: 'addPayerIdentifierForm',
})(AddPayerIdentifierForm);

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ initialize }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPayerIdentifierForm);
