import { xor } from 'lodash';
import React, { useState } from 'react';

import Card from 'components/common/card';
import CheckBox from 'components/Shared/CheckBox';
import AppealioPopup from 'components/Shared/AppealioPopup';

import { APIConfig, createURLWithParams } from 'API/Config';
import { useFetchAppealTemplates, useFetchPayers } from './hooks';
import LoadingIndicator from 'components/Shared/LoadingIndicator';

const AddTemplatesContainer = (props) => {
  const [previewItem, setPreviewItem] = useState(null);
  const { payers = [], loading: isPayersLoading } = useFetchPayers();
  const { appealTemplates, loading: isAppealTemplatesLoading } =
    useFetchAppealTemplates();

  const documentPreviewUrl =
    previewItem && previewItem.template_type === 'pdf'
      ? previewItem &&
        createURLWithParams(
          `appeal_template_pdfs/${previewItem.id}`,
          APIConfig.processParams()
        )
      : previewItem && previewItem.letter;

  return (
    <div>
      <div className="row mt-20">
        <div className="col-lg-6">
          <Card className="p-24">
            <div className="row form-info-row">
              <div className="col-sm-12">
                <div className="form-section-title mb-8">Payers</div>
                <div className="form-section-description">
                  Select Payer(s) from our generic list to get started. You will
                  be able to add a custom Payer from within your Account
                  Settings.
                </div>
              </div>
            </div>
            <div className="create-account-card-content">
              {!isPayersLoading ? (
                payers.map((payer, idx) => {
                  return (
                    <div key={idx} className="template-list mt-12">
                      <CheckBox
                        title={payer.name}
                        checked={props.payerIds.includes(payer.id)}
                        onChange={() => {
                          const newPayerIds = xor(props.payerIds, [payer.id]);

                          props.onPayerIdsChange(newPayerIds);
                        }}
                        className="mb-0"
                      />
                    </div>
                  );
                })
              ) : (
                <div>
                  <LoadingIndicator className="mt-18" showing={true} />
                </div>
              )}
            </div>
          </Card>
        </div>

        <div className="col-lg-6">
          <Card className="p-24">
            <div className="row form-info-row">
              <div className="col-sm-12">
                <div className="form-section-title mb-8">
                  Appeal Letter Templates
                </div>
                <div className="form-section-description">
                  Select Template(s) from our generic list to get started. You
                  will be able to add custom Templates from within your Account
                  Settings.
                </div>
              </div>
            </div>
            <div>
              {!isAppealTemplatesLoading ? (
                appealTemplates.map((template, idx) => {
                  return (
                    <div className="template-list mt-12" key={idx}>
                      <CheckBox
                        title={template.name}
                        checked={props.templateIds.includes(template.id)}
                        onChange={() => {
                          const newTemplatedIds = xor(props.templateIds, [
                            template.id,
                          ]);

                          props.onTemplateIdsChange(newTemplatedIds);
                        }}
                        className="mb-0"
                      />
                      <div
                        className="template-list-preview"
                        onClick={() => setPreviewItem(template)}
                      >
                        Preview Template
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <LoadingIndicator className="mt-18" showing={true} />
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>

      {previewItem && (
        <AppealioPopup
          title="Preview"
          className="import-rule-popup"
          onClosePressed={() => setPreviewItem(null)}
        >
          {previewItem.template_type === 'pdf' ? (
            <object
              className="template-preview"
              data={documentPreviewUrl}
              type="application/pdf"
            >
              <iframe
                title="Preview"
                className="template-preview"
                src={documentPreviewUrl}
              ></iframe>
            </object>
          ) : (
            <div className="template-preview">
              <span
                className="template-preview__content"
                dangerouslySetInnerHTML={{
                  __html: previewItem.letter,
                }}
              />
            </div>
          )}
        </AppealioPopup>
      )}
    </div>
  );
};

export default AddTemplatesContainer;
