import {
  CREATE_PACKAGE_SUBMISSION,
  SET_STANDALONE_SUBMISSION_ID,
  SET_SUBMIT_CONFIRM_PREFILL_DATA,
} from './actionTypes';

import { createSubmitPackageAPI } from 'API/SubmitPackageAPI';

export function createSubmitPackage(data) {
  return {
    type: CREATE_PACKAGE_SUBMISSION,
    payload: createSubmitPackageAPI(data),
  };
}

export function setStandaloneSubmissionId(data) {
  return {
    type: SET_STANDALONE_SUBMISSION_ID,
    payload: data,
  };
}

export function setSubmitConfirmPrefillData(data) {
  return {
    type: SET_SUBMIT_CONFIRM_PREFILL_DATA,
    payload: data,
  };
}
