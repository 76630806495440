import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm, SubmissionError } from 'redux-form/immutable';

import calendar from 'img/calendar-icon.svg';

import Input from 'components/common/input';
import TextArea from 'components/common/textarea';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import ReduxSingleDatePicker from 'components/common/datepicker/ReduxSingleDatePicker';

import { handleError } from 'helpers/errorHandler';
import { fieldCharacterValidation } from 'helpers/validators';

const REMINDER_CHARACTERS_LIMIT = 500;

const AddOrUpdateReminder = (props) => {
  const {
    handleSubmit,
    onSubmit,
    invalid,
    submitting,
    reminderId,
    isReminderDataLoading,
    pristine,
    onDeleteReminderClick,
    onResolveReminderClick,
  } = props;

  const validate = (values) => {
    const errors = {
      reminderDate: 'Reminder Date is required',
      note: 'Note is required',
    };

    if (!values.get('reminderDate') && !values.get('note')) {
      throw new SubmissionError(errors);
    }

    if (values.get('note')) {
      delete errors.note;
      const noteValidation = fieldCharacterValidation(
        'Note',
        values.get('note'),
        REMINDER_CHARACTERS_LIMIT
      );
      if (noteValidation) {
        errors.note = noteValidation;
      }
    }

    if (values.get('reminderDate')) {
      delete errors.reminderDate;
      const date = moment(values.get('reminderDate'));
      if (!date.isValid()) {
        errors.reminderDate = 'Invalid date';
      }
      if (date.isBefore(moment(), 'day') || date.isSame(moment(), 'day')) {
        errors.reminderDate = 'Reminder Date must be in the future';
      }
    }

    if (Object.keys(errors).length) {
      throw new SubmissionError(errors);
    }
  };

  const handleFormSubmit = async (formValues) => {
    const values = formValues.toJS();
    let formData = {};
    validate(formValues);

    formData = {
      ...values,
    };

    try {
      await onSubmit(formData);
    } catch (error) {
      if (error && error.name === 'SubmissionError') {
        throw error;
      }
      handleError(error);
    }
  };

  return (
    <>
      {isReminderDataLoading ? (
        <LoadingIndicator showing={true} />
      ) : (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="form-step__wrapper">
            <div className="form-step__content">
              <div className="row mb-12">
                <div className="col-md-12">
                  <Field
                    component={Input}
                    name="claimId"
                    label="Claim ID"
                    required
                    readOnly
                  />
                </div>
              </div>
              <div className="row mb-12 row--date-picker-row">
                <div className="col-md-12">
                  <label className="ap-input-label">Reminder Date *</label>
                  <Field
                    component={ReduxSingleDatePicker}
                    name="reminderDate"
                    placeholder="Select Reminder Date"
                    block={true}
                    displayFormat="MMM D, YYYY"
                    verticalSpacing={10}
                    isOutsideRange={(day) => {
                      return (
                        day.isSame(moment(), 'day') ||
                        day.isBefore(moment(), 'day')
                      );
                    }}
                    customInputIcon={
                      <img
                        className="form-icon"
                        src={calendar}
                        alt="Calendar"
                      />
                    }
                  />
                </div>
              </div>

              <div className="row mb-12">
                <div className="col-md-12">
                  <Field
                    label={reminderId ? 'Note' : 'Note *'}
                    component={TextArea}
                    name="note"
                    resize={false}
                    rows="3"
                    styles={{
                      minWidth: '308px',
                      resize: 'none',
                    }}
                    readOnly={reminderId}
                    max={REMINDER_CHARACTERS_LIMIT}
                    maxLength={REMINDER_CHARACTERS_LIMIT}
                    showCharactersCounter
                  />
                </div>
              </div>
            </div>
          </div>
          {reminderId ? (
            <div className="d-flex align-items-center justify-content--space-between">
              <button
                className="btn--reset btn--reset-active text-decoration-underline fw-normal"
                onClick={onDeleteReminderClick}
              >
                Delete Reminder
              </button>
              <div className="d-flex align-items-center justify-content-flex-end">
                <button
                  onClick={onResolveReminderClick}
                  className="ap-button ap-button--primary-dark  mr-12"
                >
                  Resolve Reminder
                </button>
                <button
                  type="submit"
                  disabled={invalid || submitting || pristine}
                  className="ap-button ap-button--secondary "
                >
                  Save Reminder
                </button>
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center justify-content-flex-end">
              <button
                type="submit"
                disabled={invalid || submitting || pristine}
                className="ap-button ap-button--secondary "
              >
                Create Reminder
              </button>
            </div>
          )}
        </form>
      )}
    </>
  );
};

AddOrUpdateReminder.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
};

const FORM_NAME = 'add-update-reminder-form';

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(AddOrUpdateReminder);
