import { useState, useEffect, useCallback } from 'react';

import { fetchReminderForDenial } from 'API/ReminderAPI';

/**
 * A custom React hook that fetches reminder data for a given denial ID.
 *
 * @param {string} denialId - The ID of the denial to fetch the reminder data for.
 * @param {string} reminderId - The ID of the reminder to fetch.
 * @returns {object} An object containing the loading state, error (if any), and the fetched reminder data.
 */
export const useFetchReminderData = (denialId) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [reminderData, setReminderData] = useState([]);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await fetchReminderForDenial(denialId);
      setReminderData(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [denialId]);

  useEffect(() => {
    if (!denialId) {
      setLoading(false);
      return;
    }
    fetch();
  }, [denialId, fetch]);

  return { loading, error, reminderData };
};
