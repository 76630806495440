import React from 'react';

import './styles.css';

const CodeCard = ({ code, description = '' }) => {
  return (
    <div className="code-card" datacy="code-card">
      <div className="code-card__title">{code}</div>
      <div className="code-card__description">
        <span>{description}</span>
      </div>
    </div>
  );
};

export default CodeCard;
