import { useCallback, useEffect, useState } from 'react';

import MergeUtilityAPI from 'API/MergeUtilityAPI';

export const OTHER_OPTION_NAME = 'Other';

const sortOptions = (a, b) => {
  if (a.label.toLowerCase() === OTHER_OPTION_NAME.toLowerCase()) return 1;
  if (b.label.toLowerCase() === OTHER_OPTION_NAME.toLowerCase()) return -1;
  return a.label.localeCompare(b.label); // Sort by name in ascending order
};

export const useFetchMergeConfigs = (mergeToolType) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [configs, setConfigs] = useState([]);
  const [configsDropdownOptions, setConfigsDropdownOptions] = useState([]);
  const fetch = useCallback(async () => {
    setLoading(true);
    setConfigs([]);
    setError(null);
    try {
      const configs = await MergeUtilityAPI.getUtilityConfigs(mergeToolType);
      const configsDropdownOptions = configs
        .map(({ id: value, name: label, configs }) => ({
          value,
          label,
          configOptions: configs.map((config) => ({
            value: config.id,
            label: config.configName,
          })),
        }))
        .sort(sortOptions);
      setConfigs(configs);
      setConfigsDropdownOptions(configsDropdownOptions);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [mergeToolType]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);
  return { loading, error, configs, configsDropdownOptions };
};
