import Immutable, { Map } from 'immutable';
import * as types from '../actions/actionTypes';

import { snakeCaseKeys } from '../../helpers/object';
import { CreateAppealMode } from '../../components/CreateAppeal/DenialInformation/CreateAppealMode';

const initialState = Map({
  submissionPreview: Map({
    isFetchingPreview: false,
    previewUrl: null,
    error: null,
    abortController: null,
  }),
});

export function createAppealStore(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_CURRENT_STEP:
      return state.set('currentAppealCreationStep', action.value);

    case `${types.CREATE_APPEAL_SEND_PATIENT_INFO}_FULFILLED`: {
      const { appeal, patient } = action.payload;

      return state
        .set(
          'currentAppealData',
          Immutable.fromJS({
            ...appeal,
            patient,
            patientName: `${patient.firstName} ${patient.lastName}`,
          })
        )
        .set('currentMode', CreateAppealMode.NewAppeal);
    }
    case `${types.CREATE_PACKAGE_SUBMISSION}_FULFILLED`: {
      const isStandAlone = action.payload.type === 'submissionPackage';
      const currentAPpealData =
        state.get('currentAppealData') && state.get('currentAppealData').toJS()
          ? state.get('currentAppealData').toJS()
          : {};
      return state
        .set(
          'currentAppealData',
          Immutable.fromJS({
            ...currentAPpealData,
            isStandAlone,
            submissionId: action.payload.id,
          })
        )
        .set(
          'currentMode',
          isStandAlone ? CreateAppealMode.NewAppeal : CreateAppealMode.Resume
        );
    }

    case types.SET_STANDALONE_SUBMISSION_ID: {
      return state
        .set(
          'currentAppealData',
          Immutable.fromJS({
            isStandAlone: true,
            submissionId: action.payload,
          })
        )
        .set('currentMode', CreateAppealMode.Resume);
    }

    case types.SET_SUBMIT_CONFIRM_PREFILL_DATA: {
      return state.set(
        'submitConfirmPrefillData',
        Immutable.fromJS(action.payload)
      );
    }

    case types.SET_APPEAL_DATA:
      return state
        .set('currentAppealData', Immutable.fromJS(action.data.appeals[0]))
        .set('currentMode', CreateAppealMode.Resume);

    case types.SET_APPEAL_PREFILL_DATA:
      return state.set(
        'preFillAppealData',
        Immutable.fromJS(snakeCaseKeys(action.data))
      );

    case types.CLEAR_CURRENT_APPEAL_DATA:
      return state
        .set(
          'currentAppealData',
          action.initialAppealData
            ? Immutable.fromJS(action.initialAppealData)
            : null
        )
        .set('currentMode', CreateAppealMode.NewAppeal)
        .set('preFillAppealData', null)
        .set('submissionPreview', initialState.get('submissionPreview'));

    case `${types.CREATE_REAPPEAL}_FULFILLED`:
      return state
        .set('currentMode', CreateAppealMode.ReAppeal)
        .set('currentAppealData', Immutable.fromJS(action.payload));
    case `${types.CLEAR_AUTH_TOKEN}`:
      return initialState;

    case `${types.SET_TOTAL_DOCUMENTS_PAGE_NUMBERS}`: {
      const existingAppealData =
        state.get('currentAppealData') && state.get('currentAppealData').toJS()
          ? state.get('currentAppealData').toJS()
          : {};
      return state.set(
        'currentAppealData',
        Immutable.fromJS({
          ...existingAppealData,
          pageCount: action.value,
        })
      );
    }

    case `${types.SET_DOCUMENTS_UPLOAD_FOR_STANDALONE}`: {
      const existingAppealDataList =
        state.get('currentAppealData') && state.get('currentAppealData').toJS()
          ? state.get('currentAppealData').toJS()
          : {};
      return state.set(
        'currentAppealData',
        Immutable.fromJS({
          ...existingAppealDataList,
          isDocumentUploadForStandAlone: action.value,
        })
      );
    }

    case types.SET_INCLUDE_EOB_DOCUMENT: {
      const existingAppealData =
        state.get('currentAppealData') && state.get('currentAppealData').toJS()
          ? state.get('currentAppealData').toJS()
          : {};
      return state.set(
        'currentAppealData',
        Immutable.fromJS({
          ...existingAppealData,
          includeEobDocument: action.value,
        })
      );
    }

    case types.SET_INCLUDE_CMS_1500_DOCUMENT: {
      const existingAppealData =
        state.get('currentAppealData') && state.get('currentAppealData').toJS()
          ? state.get('currentAppealData').toJS()
          : {};
      return state.set(
        'currentAppealData',
        Immutable.fromJS({
          ...existingAppealData,
          includeCMS1500Document: action.value,
        })
      );
    }

    case types.FETCH_SUBMISSION_PREVIEW_START:
      return state
        .setIn(['submissionPreview', 'isFetchingPreview'], true)
        .setIn(['submissionPreview', 'previewUrl'], null)
        .setIn(['submissionPreview', 'error'], null);
    case types.FETCH_SUBMISSION_PREVIEW_SUCCESS:
      return state
        .setIn(['submissionPreview', 'isFetchingPreview'], false)
        .setIn(['submissionPreview', 'previewUrl'], action.payload.previewUrl);
    case types.FETCH_SUBMISSION_PREVIEW_FAILURE:
      return state
        .setIn(['submissionPreview', 'isFetchingPreview'], false)
        .setIn(['submissionPreview', 'error'], action.payload.error);
    case types.FETCH_SUBMISSION_PREVIEW_CANCEL:
      return state.setIn(['submissionPreview', 'isFetchingPreview'], false);
    case types.RESET_SUBMISSION_PREVIEW:
      return state.set(
        'submissionPreview',
        initialState.get('submissionPreview')
      );
    default:
      return state;
  }
}

export function getCurrentAppealID(state) {
  const appealData = state.get('createAppealStore').get('currentAppealData');
  if (!appealData) {
    return null;
  }

  return appealData.get('id');
}

export function getCurrentAppealData(state) {
  return state.get('createAppealStore').get('currentAppealData');
}

export function isCurrentAppealMedicalRecord(state) {
  const appealData = getCurrentAppealData(state);
  if (!appealData) {
    return false;
  }

  return appealData.get('isMedicalRecordsSubmission') === true;
}

export function getCurrentAppealStep(state) {
  return state.get('createAppealStore').get('currentAppealCreationStep');
}

export function getCurrentAppealPreFillData(state) {
  return state.get('createAppealStore').get('preFillAppealData');
}

export function getCurrentAppealMode(state) {
  if (!state.get('createAppealStore').has('currentMode')) {
    return CreateAppealMode.NewAppeal;
  } else {
    return state.get('createAppealStore').get('currentMode');
  }
}

export function getSubmitConfirmPrefillData(state) {
  return state.get('createAppealStore').get('submitConfirmPrefillData');
}

export function isStandAloneSubmission(state) {
  const appealData = getCurrentAppealData(state);
  if (!appealData) {
    return false;
  }
  return appealData.get('isStandAlone') === true;
}

export function getSubmissionPreviewState(state) {
  return state.get('createAppealStore').get('submissionPreview');
}

export function getIsFetchingPreview(state) {
  return getSubmissionPreviewState(state).get('isFetchingPreview');
}

export function getPreviewUrl(state) {
  return getSubmissionPreviewState(state).get('previewUrl');
}

export function getPreviewError(state) {
  return getSubmissionPreviewState(state).get('error');
}
