import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import ReactDOMServer from 'react-dom/server';

import React, { useState, useMemo } from 'react';
import {
  Field,
  reduxForm,
  change,
  formValueSelector,
} from 'redux-form/immutable';

import './style.css';

import SopAccordion from './SopAccordion';
import Input from 'components/common/input';
import Button from 'components/common/button';
import ToggleSwitch from 'components/common/toggle';
import Select from 'components/common/select/Select';
import { AlertDialog } from 'components/common/popup';
import { BUTTON_TYPE } from 'components/common/button/Button';
import AddOrUpdateSopStepsForm from './AddOrUpdateSopStepsForm';
import CustomDropdown from 'components/common/dropdown/ButtonDropdown/CustomDropdown';
import CreateProposedActionPopup from 'components/ProposedActions/CreateProposedActionPopup';

import { SOP_FORM_STEPS } from './SopContainer';
import { ZERO_PAY_RULE_OPTIONS } from 'constants/appConstants';

import { sortByCount } from 'components/common/dropdown/ButtonDropdown/utils';

import { useFetchProposedActions } from '../hooks';

import { renderArray } from '../ImportRule/ImportRule';

const CLAIM_LINE_RELATED_DROPDOWN_LABEL = {
  reasonCodes: 'Reason(s)',
  remarkCodes: 'Remark(s)',
  procedureCodes: 'Procedure(s)',
  procedureModifierCodes: 'Modifier(s)',
};

export const INITIAL_FORM_VALUES = {
  name: '',
  payers: [],
  excludedPayers: [],
  minimumDeniedAmount: '',
  procedureCodes: [],
  excludedProcedureCodes: [],
  reasonCodes: [],
  excludedReasonCodes: [],
  remarkCodes: [],
  excludedRemarkCodes: [],
  agents: [],
  providers: [],
};

export const TOGGLE_OPTIONS = {
  option1: '',
  option2: 'Exclusions',
};
const removeEmptyOptions = (options) => options.filter(({ value }) => value);

let AddOrUpdateSopForm = (props) => {
  const {
    sopId,
    importRule = {},
    sopFormDropdownOptions,
    handleSubmit,
    error,
    pristine,
    invalid,
    submitting,
    selectedPayers,
    excludedPayers,
    selectedRemarks,
    excludedRemarks,
    selectedProcedureCodes,
    excludedProcedureCodes,
    selectedReasonCodes,
    excludedReasonCodes,
    selectedProcedureModifierCodes,
    excludedProcedureModifierCodes,
    isZeroPayAnyClaimLineDisabled,
    isSelectedZeoClaimLineOptionWithoutAnyClaimLine,
    handleFormSubmit,
    initialValues,
    isFetchingSop,
    deleteSopForRule,
    deleteSopStep,
    ruleName,
    sopListLength,
    sopStep,
    setSopStep,
  } = props;
  const {
    proposedActions: actionList,
    isLoading: isActionListLoading,
    fetch: fetchProposedAction,
  } = useFetchProposedActions();

  const [enableEditForm, setEnableEditForm] = useState(false);
  const [isExclusionToggleSelected, setIsExclusionToggleSelected] =
    useState(false);
  const [claimLinePopup, setClaimLinePopup] = useState({
    isOpen: false,
    dropdownName: false,
    options: [],
  });
  const [showProposedPopup, setShowProposedPopup] = useState(false);
  const [sopDeleteAlertPopup, setSopDeleteAlert] = useState({
    isOpen: false,
    message: '',
  });

  const payers = sopFormDropdownOptions?.payerOptions || [];
  const reasonCodes = sopFormDropdownOptions?.reasonCodeOptions || [];
  const cptCodes = sopFormDropdownOptions?.procedureCodeOptions || [];
  const remarkCodes = sopFormDropdownOptions?.remarkOptions || [];
  const procedureModifiers =
    sopFormDropdownOptions?.procedureModifierCodeOptions || [];

  const handleToggle = () =>
    setIsExclusionToggleSelected(!isExclusionToggleSelected);

  const shouldDisableDropdownOptions = (selectedItems, excludedItems) => {
    const isSelectedItemsEmpty =
      selectedItems.length === 0 || selectedItems.size === 0;
    const isExcludedItemsEmpty =
      excludedItems.length === 0 || excludedItems.size === 0;
    const disableSelectedItems = isSelectedItemsEmpty && !isExcludedItemsEmpty;
    const disableExcludedItems = isExcludedItemsEmpty && !isSelectedItemsEmpty;

    return {
      disableSelectedItems,
      disableExcludedItems,
    };
  };

  const {
    disableSelectedItems: disablePayers,
    disableExcludedItems: disabledExcludedPayers,
  } = shouldDisableDropdownOptions(selectedPayers, excludedPayers);

  const {
    disableSelectedItems: disableRemarks,
    disableExcludedItems: disabledExcludedRemarks,
  } = shouldDisableDropdownOptions(selectedRemarks, excludedRemarks);

  const {
    disableSelectedItems: disableProceduresCodes,
    disableExcludedItems: disabledExcludedProceduresCodes,
  } = shouldDisableDropdownOptions(
    selectedProcedureCodes,
    excludedProcedureCodes
  );

  const {
    disableSelectedItems: disableModifierCodes,
    disableExcludedItems: disabledExcludedModifierCodes,
  } = shouldDisableDropdownOptions(
    selectedProcedureModifierCodes,
    excludedProcedureModifierCodes
  );

  const {
    disableSelectedItems: disableReasonCodes,
    disableExcludedItems: disabledExcludedReasonCodes,
  } = shouldDisableDropdownOptions(selectedReasonCodes, excludedReasonCodes);

  const zeroPayClaimLineOptions = useMemo(() => {
    return ZERO_PAY_RULE_OPTIONS.map((option) =>
      option.value === 'ANY_CLAIM_LINE'
        ? {
            ...option,
            isDisabled: isZeroPayAnyClaimLineDisabled,
            tooltip: ReactDOMServer.renderToStaticMarkup(
              <div>
                The "Zero Pay - Any Claim Line" option is available when the
                Procedure Code, Modifier, Remark Code, <br />
                and Reason Code fields contain a single selection or "All".
              </div>
            ),
            isToolTipDataHtml: true,
          }
        : option
    );
  }, [isZeroPayAnyClaimLineDisabled]);

  const checkAndUpdateZeroPayOptions = (selectedValues, key) => {
    if (
      selectedValues?.length > 1 &&
      !isSelectedZeoClaimLineOptionWithoutAnyClaimLine
    ) {
      setClaimLinePopup({
        isOpen: true,
        options: selectedValues,
        dropdownName: key,
      });
    }
  };

  const handleCancelClaimLinePopup = () => {
    const { dropdownName, options } = claimLinePopup;
    props.change(dropdownName, options.slice(0, -1));
    setClaimLinePopup({
      isOpen: false,
      options: [],
      dropdownName: '',
    });
  };

  const handleContinueClaimLinePopup = () => {
    setClaimLinePopup({
      isOpen: false,
      dropdownName: '',
      options: [],
    });
    props.change('onlyZeroPayRule', ZERO_PAY_RULE_OPTIONS[0]);
  };

  const onNextClick = (formValues) => {
    validate(formValues);
    setEnableEditForm(true);
    setSopStep(SOP_FORM_STEPS.STEP2);
  };

  const renderSopForm = () => {
    return (
      <>
        <div className="row mb-24">
          <div className="col-lg-6">
            <Field
              component={Input}
              name="name"
              label="SOP Name *"
              placeholder="Name"
              datacy="sop-name-Field"
              readOnly={Boolean(sopId)}
            />
          </div>
          <div className="col-lg-4 mt-24">
            <ToggleSwitch
              className={classnames('justify-content-center ', {
                'toggle-wrapper--off': !isExclusionToggleSelected,
              })}
              options={TOGGLE_OPTIONS}
              checked={isExclusionToggleSelected}
              handleToggle={handleToggle}
            />
          </div>
        </div>
        <div className="row mb-24">
          <div className="col-lg-3">
            <Field
              component={CustomDropdown}
              name="payers"
              options={removeEmptyOptions(payers)}
              label="Payer(s)"
              className={classnames('d-block', {
                'd-none': isExclusionToggleSelected,
              })}
              placeholder="All"
              isMulti
              datacy="payers-Field"
              {...(disablePayers && {
                dataTipMessage:
                  "Since you've chosen to exclude payers, including them is not an option.",
              })}
              isDisabled={disablePayers}
              maxListItems={4}
            />
            <Field
              component={CustomDropdown}
              name="excludedPayers"
              className={classnames('dd-exclusion-default', {
                'd-block': isExclusionToggleSelected,
              })}
              options={removeEmptyOptions(payers)}
              label="Excluded Payer(s)"
              placeholder="None Selected"
              isMulti
              datacy="payers-Field"
              isDisabled={disabledExcludedPayers}
              {...(disabledExcludedPayers && {
                dataTipMessage:
                  "Since you've chosen to include payers, excluding them is not an option.",
              })}
              maxListItems={4}
            />
          </div>

          <div className="col-lg-3">
            <Field
              component={CustomDropdown}
              options={removeEmptyOptions(remarkCodes)}
              name="remarkCodes"
              onChange={(v) => checkAndUpdateZeroPayOptions(v, 'remarkCodes')}
              className={classnames('d-block', {
                'd-none': isExclusionToggleSelected,
              })}
              isMulti
              label="Remark(s)"
              placeholder="All"
              datacy="remark-codes-Field"
              {...(disableRemarks && {
                dataTipMessage:
                  "Since you've chosen to exclude Remarks, including them is not an option.",
              })}
              isDisabled={disableRemarks}
              sortFunc={sortByCount}
              maxListItems={4}
            />
            <Field
              component={CustomDropdown}
              name="excludedRemarkCodes"
              className={classnames('dd-exclusion-default', {
                'd-block': isExclusionToggleSelected,
              })}
              options={removeEmptyOptions(remarkCodes)}
              label="Excluded Remark(s)"
              placeholder="None Selected"
              isMulti
              datacy="excluded-remark-codes-Field"
              {...(disabledExcludedRemarks && {
                dataTipMessage:
                  "Since you've chosen to include Remarks, excluding them is not an option.",
              })}
              isDisabled={disabledExcludedRemarks}
              maxListItems={4}
            />
          </div>
          <div className="col-lg-3">
            <Field
              component={CustomDropdown}
              options={removeEmptyOptions(cptCodes)}
              onChange={(v) =>
                checkAndUpdateZeroPayOptions(v, 'procedureCodes')
              }
              name="procedureCodes"
              className={classnames('d-block', {
                'd-none': isExclusionToggleSelected,
              })}
              label="Procedure(s)"
              placeholder="All"
              isMulti
              datacy="procedure-codes-Field"
              {...(disableProceduresCodes && {
                dataTipMessage:
                  "Since you've chosen to exclude Procedures, including them is not an option.",
              })}
              isDisabled={disableProceduresCodes}
              maxListItems={4}
            />
            <Field
              component={CustomDropdown}
              name="excludedProcedureCodes"
              className={classnames('dd-exclusion-default', {
                'd-block': isExclusionToggleSelected,
              })}
              options={removeEmptyOptions(cptCodes)}
              label="Excluded Procedure(s)"
              placeholder="None Selected"
              isMulti
              datacy="excluded-procedure-codes-Field"
              {...(disabledExcludedProceduresCodes && {
                dataTipMessage:
                  "Since you've chosen to include Procedures, excluding them is not an option.",
              })}
              isDisabled={disabledExcludedProceduresCodes}
              maxListItems={4}
            />
          </div>
        </div>

        <div className="row mb-24">
          <div className="col-lg-3">
            <Field
              component={CustomDropdown}
              options={removeEmptyOptions(procedureModifiers)}
              name="procedureModifierCodes"
              onChange={(v) =>
                checkAndUpdateZeroPayOptions(v, 'procedureModifierCodes')
              }
              label="Modifier(s)"
              className={classnames('d-block', {
                'd-none': isExclusionToggleSelected,
              })}
              isMulti
              placeholder="All"
              datacy="procedure-modifier-codes-Field"
              {...(disableModifierCodes && {
                dataTipMessage:
                  "Since you've chosen to exclude Modifiers, including them is not an option.",
              })}
              isDisabled={disableModifierCodes}
              maxListItems="4"
            />
            <Field
              component={CustomDropdown}
              name="excludedProcedureModifierCodes"
              className={classnames('dd-exclusion-default', {
                'd-block': isExclusionToggleSelected,
              })}
              options={removeEmptyOptions(procedureModifiers)}
              label="Excluded Modifier(s)"
              placeholder="None Selected"
              isMulti
              datacy="excluded-modifier-codes-Field"
              {...(disabledExcludedModifierCodes && {
                dataTipMessage:
                  "Since you've chosen to exclude Modifiers, including them is not an option.",
              })}
              isDisabled={disabledExcludedModifierCodes}
              maxListItems="4"
            />
          </div>

          <div className="col-lg-3">
            <Field
              component={CustomDropdown}
              options={removeEmptyOptions(reasonCodes)}
              onChange={(v) => checkAndUpdateZeroPayOptions(v, 'reasonCodes')}
              name="reasonCodes"
              label="Reason(s)"
              placeholder="All"
              className={classnames('d-block', {
                'd-none': isExclusionToggleSelected,
              })}
              isMulti
              datacy="reason-codes-Field"
              {...(disableReasonCodes && {
                dataTipMessage:
                  "Since you've chosen to exclude Reasons, including them is not an option.",
              })}
              isDisabled={disableReasonCodes}
              sortFunc={sortByCount}
              maxListItems="4"
            />
            <Field
              component={CustomDropdown}
              name="excludedReasonCodes"
              className={classnames('dd-exclusion-default', {
                'd-block': isExclusionToggleSelected,
              })}
              options={removeEmptyOptions(reasonCodes)}
              label="Excluded Reason(s)"
              placeholder="None Selected"
              isMulti
              datacy="excluded-reason-codes-Field"
              {...(disabledExcludedReasonCodes && {
                dataTipMessage:
                  "Since you've chosen to exclude Reasons, including them is not an option.",
                dataToolTipPlace: 'left',
              })}
              isDisabled={disabledExcludedReasonCodes}
              maxListItems="4"
            />
          </div>
          <div
            className={classnames('col-lg-5', {
              'd-none': isExclusionToggleSelected,
            })}
          >
            <div className="d-flex align-items-center">
              <p className="dd-label">Payment Amount</p>
            </div>
            <div className="d-flex">
              <label
                className="fw-normal mt-8 width-120"
                data-tip
                data-for={`zero-pay-form-${importRule.id}`}
              >
                <Field
                  name="onlyZeroPay"
                  type="checkbox"
                  component="input"
                  label="Zero Pay"
                  onChange={(e) => {
                    props.change('onlyZeroPay', e.target.checked);
                    props.change('onlyZeroPayRule', ZERO_PAY_RULE_OPTIONS[0]);
                  }}
                />
                <span className="ml-8">Zero Pay</span>
              </label>
              <ReactTooltip
                id={`zero-pay-form-${importRule.id}`}
                place="right"
                effect="solid"
              >
                Check this option to import claim(s) with '$0' payment amount.
              </ReactTooltip>
              <Field
                component={Select}
                name="onlyZeroPayRule"
                isDisabled={!props?.isOnlyZeroPaySelected}
                options={zeroPayClaimLineOptions}
                placeholder="None Selected"
                datacy="excluded-procedure-codes-Field"
                reactSelectProps={{
                  menuPlacement: 'top',
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const isReviewForm = !!sopId;
  const sopForm = renderSopForm();
  const renderAddOrUpdateStepsForm = () => (
    <AddOrUpdateSopStepsForm
      handleFormSubmit={handleFormSubmit}
      initialValues={initialValues}
      isFetchingSop={isFetchingSop}
      sopId={sopId}
      deleteSopForRule={deleteSopForRule}
      deleteSopStep={deleteSopStep}
      ruleName={ruleName}
      sopListLength={sopListLength}
      setSopDeleteAlert={setSopDeleteAlert}
      sopDeleteAlertPopup={sopDeleteAlertPopup}
      setShowProposedPopup={setShowProposedPopup}
      actionList={actionList}
      isActionListLoading={isActionListLoading}
      enableEditForm={enableEditForm}
    />
  );

  const headerDetails = () => {
    const formInitialValues = initialValues && initialValues?.toJS();
    return (
      <>
        <div className="sop-accordion-header-details__item">
          <div className="sop-accordion-header-details__item-title">Payers</div>
          <div className="sop-accordion-header-details__item-details">
            {renderArray(formInitialValues?.payers, {
              isPlainArray: false,
            })}
          </div>
        </div>
        <div className="sop-accordion-header-details__item">
          <div className="sop-accordion-header-details__item-title">
            Remarks
          </div>
          <div className="sop-accordion-header-details__item-details">
            {renderArray(formInitialValues?.remarkCodes, {
              isPlainArray: false,
            })}
          </div>
        </div>
        <div className="sop-accordion-header-details__item">
          <div className="sop-accordion-header-details__item-title">
            Procedures
          </div>
          <div className="sop-accordion-header-details__item-details">
            {renderArray(formInitialValues?.procedureCodes, {
              isPlainArray: false,
            })}
          </div>
        </div>
        <div className="sop-accordion-header-details__item">
          <div className="sop-accordion-header-details__item-title">
            Modifiers
          </div>
          <div className="sop-accordion-header-details__item-details">
            {renderArray(formInitialValues?.procedureModifierCodes, {
              isPlainArray: false,
            })}
          </div>
        </div>
        <div className="sop-accordion-header-details__item">
          <div className="sop-accordion-header-details__item-title">
            Reasons
          </div>
          <div className="sop-accordion-header-details__item-details">
            {renderArray(formInitialValues?.reasonCodes, {
              isPlainArray: false,
            })}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="add-update-sop-container">
      {isReviewForm && !isFetchingSop ? (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="sop-accordion-wrapper">
            <SopAccordion
              title={enableEditForm ? 'Edit SOP Parameters' : 'SOP Parameters'}
              headerDetails={headerDetails()}
              content={sopForm}
              onFormSubmit={handleSubmit(handleFormSubmit)}
              disableSubmitButton={props.syncErrorExists}
              hideToggleButton={enableEditForm}
            />
          </div>
          <div className="add-update-sop-steps-wrapper">
            {renderAddOrUpdateStepsForm()}
          </div>
        </form>
      ) : (
        <form
          className="sop-form"
          onSubmit={handleSubmit(handleFormSubmit)}
          datacy="sop-form"
        >
          {sopStep === SOP_FORM_STEPS.STEP1 && sopForm}
          {sopStep === SOP_FORM_STEPS.STEP2 && renderAddOrUpdateStepsForm()}
          <div className="fs-14">{error}</div>
        </form>
      )}
      <div
        className={classnames('d-flex sop-submit-button-wrapper', {
          'justify-content-flex-end':
            (sopStep === SOP_FORM_STEPS.STEP1 && !isReviewForm) ||
            (enableEditForm && sopStep !== SOP_FORM_STEPS.STEP2),
          'justify-content-flex-start': isReviewForm && !enableEditForm,
          'justify-content--space-between': sopStep === SOP_FORM_STEPS.STEP2,
        })}
      >
        {sopStep === SOP_FORM_STEPS.STEP2 &&
          !isReviewForm &&
          enableEditForm && (
            <Button
              title="<- Modify Parameters"
              type={BUTTON_TYPE.LIGHT}
              className="width-180 justify-content-center fw-normal mr-8"
              onClick={() => {
                setSopStep(SOP_FORM_STEPS.STEP1);
                setEnableEditForm(false);
              }}
              datacy="previous-Button"
              buttonType="reset"
            />
          )}
        {isReviewForm && !enableEditForm && (
          <Button
            title="Modify SOP"
            type={BUTTON_TYPE.LIGHT}
            className="width-160 justify-content-center fw-normal mr-8"
            onClick={() => setEnableEditForm(true)}
            datacy="previous-Button"
            buttonType="reset"
          />
        )}
        <div className="d-flex justify-content-flex-end">
          {sopId && enableEditForm && (
            <Button
              title="Delete SOP"
              type={BUTTON_TYPE.LIGHT}
              className="width-120 justify-content-center fw-normal mr-8"
              onClick={() => {
                setSopDeleteAlert({
                  isOpen: true,
                  message: `Are you sure you want to delete the SOP for '${ruleName}' ?`,
                  deleteFunc: deleteSopForRule,
                });
              }}
              datacy="delete-Button"
              buttonType="reset"
              disabled={!sopId}
            />
          )}
          {sopStep === SOP_FORM_STEPS.STEP1 && !isReviewForm && (
            <Button
              title="Save & Continue"
              buttonType="button"
              onClick={handleSubmit(onNextClick)}
              className="width-160 ap-button--secondary justify-content-center height-40"
              disabled={invalid || (!sopId ? pristine : false)}
              datacy="update-create-Button"
            />
          )}
          {enableEditForm ? (
            <Button
              type="button"
              className="width-120 ap-button ap-button--secondary justify-content-center fw-normal height-40"
              onClick={handleSubmit(handleFormSubmit)}
              disabled={pristine || submitting}
            >
              Save
            </Button>
          ) : null}
        </div>
      </div>
      {claimLinePopup.isOpen && (
        <AlertDialog
          onClosePressed={() => {
            setClaimLinePopup({
              isOpen: false,
              dropdownName: '',
              options: [],
            });
          }}
          className="import-rule-practice-agent-permission"
          datacy="import-form-AlertDialog"
        >
          <h3>Are you sure you would like to continue?</h3>
          <p>
            Selecting a second{' '}
            {CLAIM_LINE_RELATED_DROPDOWN_LABEL[claimLinePopup.dropdownName]} is
            not compatible with “Zero Pay - Any Claim Line selection”.
            Continuing will automatically change the Zero Pay filter to “Zero
            Pay - Across Entire Claim”.
          </p>
          <hr className="card-horizontal-line" />
          <div className="import-rule-practice-agent-permission__buttons-wrapper">
            <Button
              type="secondary"
              title="Continue"
              onClick={handleContinueClaimLinePopup}
            />
            <Button
              title="Cancel"
              type={BUTTON_TYPE.LIGHT}
              className="width-80 justify-content-center fw-normal ml-8"
              onClick={handleCancelClaimLinePopup}
              datacy="cancel-Button"
            />
          </div>
        </AlertDialog>
      )}
      {sopDeleteAlertPopup.isOpen && (
        <AlertDialog
          onClosePressed={() => {
            setSopDeleteAlert({
              isOpen: false,
              message: '',
            });
          }}
          className="import-rule-practice-agent-permission sop-delete-alert--popup"
        >
          {sopDeleteAlertPopup.message}
          <hr className="card-horizontal-line" />
          <div className="import-rule-practice-agent-permission__buttons-wrapper">
            <button
              className="ap-button ap-button--secondary width-80 justify-content-center"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                sopDeleteAlertPopup.deleteFunc(e);
              }}
            >
              Continue
            </button>
            <Button
              title="Cancel"
              type={BUTTON_TYPE.LIGHT}
              className="width-80 justify-content-center fw-normal ml-8"
              onClick={() => {
                setSopDeleteAlert({
                  isOpen: false,
                  message: '',
                });
              }}
              datacy="cancel-Button"
            />
          </div>
        </AlertDialog>
      )}
      {showProposedPopup && (
        <CreateProposedActionPopup
          onClosePressed={() => setShowProposedPopup(false)}
          fetchProposedAction={fetchProposedAction}
        />
      )}
    </div>
  );
};

const validate = (formValues) => {
  const values = formValues.toJS();
  const errors = {};

  if (!values.name) {
    errors.name = 'SOP name is required';
  }

  if (values.name && values.name.length >= 40) {
    errors.name = 'SOP name cannot be more than 40 characters.';
  }

  return errors;
};

AddOrUpdateSopForm.propTypes = {};

AddOrUpdateSopForm = reduxForm({
  enableReinitialize: true,
  validate,
  touchOnBlur: false,
  initialValues: INITIAL_FORM_VALUES,
})(AddOrUpdateSopForm);

const mapStateToProps = (state, props) => {
  const selector = formValueSelector(props.form);
  const syncErrors = state.toJS().form[props.form]?.syncErrors;
  const syncErrorExists = syncErrors?.steps?.some((step) => !isEmpty(step));
  const mapToJSValues = (key, defaultValue = []) =>
    (selector(state, key)?.toJS?.() ?? selector(state, key)) || defaultValue;

  const selectedPayers = mapToJSValues('payers');
  const excludedPayers = mapToJSValues('excludedPayers');
  const selectedRemarks = mapToJSValues('remarkCodes');
  const excludedRemarks = mapToJSValues('excludedRemarkCodes');
  const selectedProcedureCodes = mapToJSValues('procedureCodes');
  const excludedProcedureCodes = mapToJSValues('excludedProcedureCodes');
  const isOnlyZeroPaySelected = mapToJSValues('onlyZeroPay', false);
  const selectedReasonCodes = mapToJSValues('reasonCodes');
  const excludedReasonCodes = mapToJSValues('excludedReasonCodes');
  const selectedProcedureModifierCodes = mapToJSValues(
    'procedureModifierCodes'
  );
  const excludedProcedureModifierCodes = mapToJSValues(
    'excludedProcedureModifierCodes'
  );

  const selectedZeroClaimLineOption = mapToJSValues('onlyZeroPayRule', {});
  const isSelectedZeoClaimLineOptionWithoutAnyClaimLine =
    selectedZeroClaimLineOption?.value !== 'ANY_CLAIM_LINE';

  const isZeroPayAnyClaimLineDisabled =
    selectedProcedureCodes.length > 1 ||
    selectedProcedureModifierCodes.length > 1 ||
    selectedRemarks.length > 1 ||
    selectedReasonCodes.length > 1;
  return {
    syncErrorExists,
    selectedPayers,
    excludedPayers,
    selectedRemarks,
    excludedRemarks,
    selectedProcedureCodes,
    excludedProcedureCodes,
    selectedReasonCodes,
    excludedReasonCodes,
    selectedProcedureModifierCodes,
    excludedProcedureModifierCodes,
    isOnlyZeroPaySelected,
    isZeroPayAnyClaimLineDisabled,
    isSelectedZeoClaimLineOptionWithoutAnyClaimLine,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ change }, dispatch),
  };
};

AddOrUpdateSopForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddOrUpdateSopForm);

export default AddOrUpdateSopForm;
