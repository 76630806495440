import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoadingBlockHelper from '../../../Shared/LoadingBlockHelper';
import { connect } from 'react-redux';
import { getAppealFromState } from '../../../../redux/reducers/AppealStore';
import classNames from 'classnames';
import { transformToDateInput } from '../../../../API/Serializers/Transforms';

class AppealSubmitLogs extends Component {
  static propTypes = {
    appealID: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className="appeal-logs">
        <LoadingBlockHelper isLoading={this.props.isLoading}>
          {this.renderSubmissionsTables()}
        </LoadingBlockHelper>
      </div>
    );
  }

  renderSubmissionsTables() {
    const submitLogs = this.props.appeal.submitLogs;
    const logsBySubmit = new Map();
    submitLogs.forEach((log) => {
      const type = log.submit_mail ? 'mail' : 'fax';
      const submit = log.submit_mail ? log.submit_mail : log.submit_fax;
      const key = `${type}.${submit.id}`;
      if (!logsBySubmit.has(key)) {
        logsBySubmit.set(key, []);
      }
      logsBySubmit.get(key).push(log);
    });
    const tables = [];
    logsBySubmit.forEach((logs, k) => {
      tables.push(this.renderSubmissionTable(logs, k));
    });
    return tables;
  }

  renderSubmissionTable(logs, key) {
    const submission = logs[0].submit_mail
      ? logs[0].submit_mail
      : logs[0].submit_fax;
    const type = logs[0].submit_type === 'fax' ? 'Fax' : 'Mail';
    // const service = logs[0].submit_type === 'fax' ? 'Phaxio' : 'Lob';
    // const external_id = submission.external_id;
    // const external_link = submission.external_link;
    // const datetime = submission.datetime;

    return (
      <table className="appeal-logs__table" key={key}>
        <tbody>
          <tr>
            <th className="maintitle" colSpan={3}>
              {type}
            </th>
          </tr>
          {this.renderSubmissionData(submission, type)}
          <tr>
            <th className="title">Date</th>
            <th className="title">Deliver status</th>
            <th className="title">Success</th>
          </tr>
          {logs.map((log) => {
            return this.renderSubmissionLog(log);
          })}
        </tbody>
      </table>
    );
  }

  renderSubmissionData(submission, type = 'Fax') {
    if (type === 'Mail') {
      return (
        <tr>
          <td className="subheader cell" colSpan={1}>
            <ul>
              <li>
                <strong>Address 1</strong>: {submission.address_line1}
              </li>
              <li>
                <strong>Address 2</strong>: {submission.address_line2}
              </li>
              <li>
                <strong>City</strong>: {submission.city}
              </li>
              <li>
                <strong>State</strong>: {submission.state}
              </li>
              <li>
                <strong>ZIP</strong>: {submission.zipcode}
              </li>
            </ul>
          </td>
          <td className="subheader cell" colSpan={2}>
            <ul>
              <li>
                <strong>Carrier</strong>: {submission.carrier}
              </li>
              <li>
                <strong>Expected Delivery Date</strong>:{' '}
                {submission?.expected_delivery_date
                  ? transformToDateInput(submission.expected_delivery_date)
                  : 'N/A'}
              </li>
            </ul>
          </td>
        </tr>
      );
    } else if (type === 'Fax') {
      return (
        <tr>
          <td className="subheader cell" colSpan={3}>
            <ul>
              <li>
                <strong>Fax</strong>: {submission.fax}
              </li>
            </ul>
          </td>
        </tr>
      );
    }
  }

  renderSubmissionLog(log) {
    const successClass = classNames(
      'cell',
      { 'success--green': log.success },
      { 'success--red': !log.success }
    );
    const isQueuedForSubmission =
      !log.success && log.deliver_status === 'Queued for Submission';
    return (
      <tr key={log.id}>
        <td className="cell">{log.datetime}</td>
        <td className="cell">{log.deliver_status}</td>
        {!isQueuedForSubmission ? (
          <td className={successClass}>{log.success ? 'Success' : 'Failed'}</td>
        ) : (
          <td className="cell">Queued</td>
        )}
      </tr>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const appealDetailsWrapper = getAppealFromState(state, ownProps.appealID);
  if (
    !appealDetailsWrapper ||
    appealDetailsWrapper.isLoading ||
    appealDetailsWrapper.isRejected
  ) {
    return {
      isLoading: true,
    };
  } else {
    return {
      appeal: appealDetailsWrapper.appeal,
      isLoading: false,
    };
  }
}

export default connect(mapStateToProps, null)(AppealSubmitLogs);
