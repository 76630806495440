import React from 'react';
import { Field, formValueSelector } from 'redux-form/immutable';

import { SUBMISSIONS_REASONS_CHARACTER_LIMIT } from 'constants/appConstants';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextArea from 'components/common/textarea';
import { useFetchSubmissionReasonList } from './hooks';
import { renderReduxDropdownV2 } from 'components/CreateAppeal/reduxReactSelect';

const SubmissionReasonForm = (props) => {
  const { isOtherSelected } = props;
  const { submissionsReasonList, isLoading } = useFetchSubmissionReasonList();

  return (
    <div>
      <div className="dropdown__label">Reason for Submission:*</div>
      <Field
        name="submissionReason"
        placeholder="Select Reason for Submission"
        className="ap-select"
        isLoading={isLoading}
        component={renderReduxDropdownV2}
        dropdownOptions={submissionsReasonList}
      />

      {isOtherSelected && (
        <div className="mt-10">
          <Field
            component={TextArea}
            name="submissionReasonText"
            resize={false}
            rows="3"
            styles={{
              minWidth: '308px',
              resize: 'none',
            }}
            max={SUBMISSIONS_REASONS_CHARACTER_LIMIT}
            maxLength={SUBMISSIONS_REASONS_CHARACTER_LIMIT}
            showCharactersCounter
            showErrorInputField={true}
          />
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const selector = formValueSelector(ownProps?.formName);
  const selectedSubmissionReason = selector(state, 'submissionReason');
  const isOtherSelected = selectedSubmissionReason === 0;
  return {
    selectedSubmissionReason,
    isOtherSelected,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmissionReasonForm);
