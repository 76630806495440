import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { filter, some, includes, isEqual } from 'lodash';
import { bindActionCreators } from 'redux';
import {
  Field,
  reduxForm,
  change,
  formValueSelector,
} from 'redux-form/immutable';

import Select from 'components/common/select/Select';
import { ALL_AGENTS_OPTION_VALUE } from 'constants/appConstants';
import {
  DROPDOWN_OPTIONS_STATE_KEYS,
  getDropdownOptions,
} from 'redux/reducers/dropdownOptionsStore';
import { getUserPracticeIdentifiers } from 'helpers/user';

const ALL_AGENTS_OPTION = [
  {
    label: 'All',
    value: ALL_AGENTS_OPTION_VALUE,
  },
];

const UNASSIGNED_OPTION = {
  label: 'Unassigned',
  value: '',
};

let AgentList = (props) => {
  const {
    setAgent,
    assignAgentDialog,
    users,
    practiceGroups,
    selectedClaims,
    isFetchingAgents,
    disableSelect,
  } = props;

  const getCommonAssignedAgent = () => {
    let agentUsernames = selectedClaims
      .map(({ agentsUsername }) => agentsUsername)
      .filter(Boolean);

    if (
      agentUsernames?.length > 0 &&
      agentUsernames.every((arr) => isEqual(arr, ALL_AGENTS_OPTION_VALUE))
    ) {
      return ALL_AGENTS_OPTION_VALUE;
    }

    agentUsernames = agentUsernames.filter(
      (arr) => !isEqual(arr, ALL_AGENTS_OPTION_VALUE)
    );

    if (agentUsernames.length === 0) return [];

    let intersection = new Set(agentUsernames[0]);

    agentUsernames.forEach((usernames) => {
      const currentSet = new Set(usernames);

      intersection = new Set(
        [...intersection].filter((x) => currentSet.has(x))
      );
    });

    return Array.from(intersection);
  };

  const selectedClaimsPracticeIds = selectedClaims.map(
    ({ practiceId }) => practiceId
  );
  const matchedUsers = filter(users, (user) => {
    const userAllowedPractices = getUserPracticeIdentifiers(
      user,
      practiceGroups?.data,
      selectedClaimsPracticeIds
    );
    return some(selectedClaimsPracticeIds, (id) =>
      includes(userAllowedPractices, id)
    );
  });
  const parseAgentList = matchedUsers.map(({ username, name }) => ({
    value: username,
    label: name,
  }));

  const commonAssignedAgent = getCommonAssignedAgent();

  useEffect(() => {
    const agentsUsername =
      selectedClaims.length === 1
        ? selectedClaims[0].agentsUsername
        : commonAssignedAgent;
    const initialAgents = Array.isArray(agentsUsername)
      ? parseAgentList.filter(({ value }) => agentsUsername.includes(value))
      : [];

    const selectedAgents =
      agentsUsername === ALL_AGENTS_OPTION_VALUE
        ? ALL_AGENTS_OPTION
        : initialAgents.length
        ? initialAgents
        : [UNASSIGNED_OPTION];

    setAgent({
      ...assignAgentDialog,
      selectedAgents,
    });
    props.change('agents', selectedAgents);
    // eslint-disable-next-line
  }, []);

  const isAllAgentsSelected = (selectedAgents) => {
    return selectedAgents.some(
      (option) => option.value === ALL_AGENTS_OPTION_VALUE
    );
  };

  const isUnassignedSelected = (selectedAgents) => {
    return selectedAgents.some(
      (option) => option.value === UNASSIGNED_OPTION.value
    );
  };

  const handleAgentChange = (selectedOptions) => {
    const isUnassignedSelectedPrev = isUnassignedSelected(props.selectedAgents);
    const isUnassignedSelectedCur = isUnassignedSelected(selectedOptions);
    const isAllAgentsSelectedPrev = isAllAgentsSelected(props.selectedAgents);
    const isAllAgentsSelectedCur = isAllAgentsSelected(selectedOptions);
    if (!isUnassignedSelectedPrev && isUnassignedSelectedCur) {
      setAgent({ ...assignAgentDialog, selectedAgents: [UNASSIGNED_OPTION] });
      return props.change('agents', [UNASSIGNED_OPTION]);
    } else if (!isAllAgentsSelectedPrev && isAllAgentsSelectedCur) {
      setAgent({ ...assignAgentDialog, selectedAgents: ALL_AGENTS_OPTION });
      return props.change('agents', ALL_AGENTS_OPTION);
    } else if (
      (isAllAgentsSelectedCur || isUnassignedSelectedCur) &&
      selectedOptions.length > 1
    ) {
      const optionsWithoutAllOption = selectedOptions.filter(
        (option) =>
          ![ALL_AGENTS_OPTION_VALUE, UNASSIGNED_OPTION.value].includes(
            option.value
          )
      );
      setAgent({
        ...assignAgentDialog,
        selectedAgents: optionsWithoutAllOption,
      });
      return props.change('agents', optionsWithoutAllOption);
    } else {
      setAgent({ ...assignAgentDialog, selectedAgents: selectedOptions });
      return props.change('agents', selectedOptions);
    }
  };

  return (
    <form className="agent-form">
      {!commonAssignedAgent && selectedClaims[0].agentsUsername ? (
        <p className="fw-bold mb-16">
          {' '}
          Multiple agents have already been assigned to the selected claims.
          Re-assigning the claims will automatically reset the previous
          assignment.
        </p>
      ) : null}
      <div className="dq-reason-to-remove-container" datacy="reason-to-remove">
        <div className="dq-reason-to-remove__select-wrapper">
          <Field
            component={Select}
            name="agents"
            isLoading={isFetchingAgents}
            options={
              !isFetchingAgents
                ? [...ALL_AGENTS_OPTION, UNASSIGNED_OPTION, ...parseAgentList]
                : []
            }
            placeholder="Select agent(s)"
            reactSelectProps={{
              isMulti: true,
              isClearable: true,
            }}
            onChange={handleAgentChange}
            datacy="agent-List"
            isDisabled={disableSelect}
            hideMultiValueRemove={disableSelect}
          />
        </div>
      </div>
    </form>
  );
};

AgentList.propTypes = {
  setAgent: PropTypes.func.isRequired,
};

AgentList = reduxForm({
  form: `Agent-form`,
  enableReinitialize: true,
})(AgentList);

const mapStateToProps = (state) => {
  const users =
    getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.AGENT_OPTIONS
    )?.data?.filter((user) => user.value) || [];

  const selector = formValueSelector('Agent-form');
  const selectedAgents = selector(state, 'agents') || [];
  return {
    users,
    selectedAgents,
    practiceGroups: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.PRACTICE_GROUP_OPTIONS
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ change }, dispatch),
  };
};

AgentList = connect(mapStateToProps, mapDispatchToProps)(AgentList);

export default AgentList;
