import moment from 'moment';
import React, { useState } from 'react';

import PatientList from './PatientList/PatientList';
import BenefitList from './BenefitList/BenefitList';
import SearchPatient from './SearchPatient/SearchPatient';
import InsuranceList from './InsuranceList/InsuranceList';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import CardContainer from '../Common/CardContainer/CardContainer';
import CalculateEstimate from './CalculateEstimate/CalculateEstimate';
import FloatingPrintButton from 'components/common/floatingPrintButton';

import * as toast from '../../Shared/toast';

import {
  fetchPatientInsuranceEligibility,
  verifyPatientInsuranceEligibility,
} from '../../../API/CostEstimatorAPI';

const CreateEstimate = (props) => {
  const [patientSearchValue, setPatientSearchValue] = useState({
    firstName: '',
    lastName: '',
    dob: '',
  });

  const [verifyEligibility, setVerifyEligibility] = useState({
    isVerifying: false,
    isEligible: false,
    eligibility: null,
  });

  const [manualBenefitInfo, setManualBenefitInfo] = useState({
    deductible: null,
    copayment: null,
    coinsurance: null,
  });

  const resetManualBenefitInfo = () =>
    setManualBenefitInfo({
      deductible: null,
      copayment: null,
      coinsurance: null,
    });

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedInsuranceId, setSelectedInsuranceId] = useState(null);
  const [procedures, setProcedures] = useState([]);

  const resetEligibility = () => {
    setVerifyEligibility({
      isVerifying: false,
      isEligible: false,
      eligibility: null,
    });
    setProcedures([]);
    resetManualBenefitInfo();
  };

  const isPatientListVisible = (patientSearchValue) => {
    if (patientSearchValue.dob && !moment(patientSearchValue.dob).isValid()) {
      return false;
    }

    return Object.keys(patientSearchValue).every((k) => patientSearchValue[k]);
  };

  const handlePatientSearch = (searchValue) => {
    setPatientSearchValue({
      ...searchValue,
    });
    setSelectedPatient(null);
    setSelectedInsuranceId(null);
    resetEligibility();

    if (!isPatientListVisible(searchValue)) {
      if (searchValue.dob && !moment(searchValue.dob).isValid()) {
        return toast.warning({
          title: '',
          message: 'Invalid date format for Date of Birth',
        });
      }

      toast.warning({
        title: '',
        message: 'First Name, Last Name and Date of Birth is required',
      });
    }
  };

  const handlePatientSelect = (patient) => {
    setSelectedPatient(patient);
    setSelectedInsuranceId(null);
    resetEligibility();
  };

  const handleInsuranceSelect = (insurance) => {
    if (!insurance.plan.name) {
      toast.warning({
        title: '',
        message: `Insurance Plan information is unavailable for Practice Management System Insurance Package ID: ${insurance.packageid}`,
      });
    }

    setSelectedInsuranceId(insurance.id);
    resetEligibility();
  };

  const handleVerifyEligibilityClick = async () => {
    try {
      setVerifyEligibility({
        isVerifying: true,
        isEligible: false,
        eligibility: null,
      });

      const isEligible = await verifyPatientInsuranceEligibility(
        selectedPatient.id,
        selectedInsuranceId
      );
      if (!isEligible) {
        toast.warning({
          title: 'Not Eligible',
          message: '',
        });
        return setVerifyEligibility({
          isVerifying: true,
          isEligible,
          eligibility: null,
        });
      }
      const eligibility = await fetchPatientInsuranceEligibility(
        selectedPatient.id,
        selectedInsuranceId
      );

      if (eligibility.status === 'ACTIVE') {
        toast.success({
          title: 'Eligible',
          message: '',
        });
      } else {
        toast.error({
          title: 'Not Eligible',
          message: '',
        });
      }

      setVerifyEligibility({
        isVerifying: true,
        isEligible,
        eligibility,
      });
    } catch (error) {
      toast.error({
        title: 'Not Eligible',
        message: '',
      });
      setVerifyEligibility({
        isVerifying: false,
        isEligible: false,
        eligibility: null,
      });
    }
  };

  const canCalculateEstimate =
    verifyEligibility.isEligible &&
    verifyEligibility.eligibility &&
    verifyEligibility.eligibility.status === 'ACTIVE';

  return (
    <div className="custom-container" datacy="create-estimate">
      <div className="max-width-1310 ml-auto mr-auto">
        <div className="patient-cost-estimator">
          <CardContainer className="mb-22">
            <SearchPatient
              initialValue={patientSearchValue}
              onSearch={handlePatientSearch}
              className="no-print"
              datacy="create-estimate-SearchPatient"
            />
            {isPatientListVisible(patientSearchValue) && (
              <PatientList
                searchFilter={patientSearchValue}
                onSelectPatient={handlePatientSelect}
                datacy="create-estimate-PatientList"
              />
            )}
            {selectedPatient && selectedPatient.id && (
              <InsuranceList
                patientId={selectedPatient.id}
                patientName={`${selectedPatient.firstName} ${selectedPatient.lastName}`}
                onSelectInsurance={handleInsuranceSelect}
                datacy="create-estimate-InsuranceList"
              />
            )}
            {selectedInsuranceId && !verifyEligibility.isEligible && (
              <button
                className="btn--block btn--round verify-eligibility-btn"
                onClick={handleVerifyEligibilityClick}
              >
                <div className="d-flex justify-content-center position-relative">
                  {verifyEligibility.isVerifying ? (
                    <div className="position-relative mr-24">
                      <LoadingIndicator
                        showing={true}
                        isLightIndicator={true}
                      />
                    </div>
                  ) : (
                    'Verify Eligibility'
                  )}
                </div>
              </button>
            )}
            {verifyEligibility.isEligible && verifyEligibility.eligibility && (
              <BenefitList
                eligibility={verifyEligibility.eligibility}
                manualBenefitInfo={manualBenefitInfo}
                setManualBenefitInfo={setManualBenefitInfo}
                resetManualBenefitInfo={resetManualBenefitInfo}
                isProcedureCodesSelected={procedures.length > 0}
                selectedPatient={selectedPatient}
                datacy="create-estimate-BenefitList"
              />
            )}
          </CardContainer>
          {canCalculateEstimate && (
            <CalculateEstimate
              patient={{
                firstName: selectedPatient.firstName,
                lastName: selectedPatient.lastName,
                dob: selectedPatient.dob,
                id: selectedPatient.id,
              }}
              patientId={selectedPatient.id}
              insuranceId={selectedInsuranceId}
              eligibility={verifyEligibility.eligibility}
              manualBenefitInfo={manualBenefitInfo}
              procedures={procedures}
              setProcedures={setProcedures}
              datacy="create-estimate-CalculateEstimate"
            />
          )}
        </div>
      </div>

      {canCalculateEstimate && <FloatingPrintButton />}
    </div>
  );
};

export default CreateEstimate;
