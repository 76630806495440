import classNames from 'classnames';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { AiOutlineFolder } from 'react-icons/ai';

import PracticeList from './PracticeList';
import { AppealioPopupWithFooter } from 'components/common/popup';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';

import { useFetchPracticeGroup } from './hooks';

import {
  getDropdownOptions,
  DROPDOWN_OPTIONS_STATE_KEYS,
} from 'redux/reducers/dropdownOptionsStore';
import { fetchPracticeOptions } from 'API/DropdownOptions';
import { fetchDenialsPracticeOptions } from 'redux/actions/dropdownOptionsActions';

const PracticeListPopup = ({
  practiceGroupId,
  onClosePressed,
  practiceOptions,
  title,
}) => {
  useEffect(() => {
    fetchPracticeOptions();
  }, []);

  const { practiceGroup, loading } = useFetchPracticeGroup(practiceGroupId);

  const practiceIdentifiers = practiceGroup?.practices || [];

  const practiceList = practiceOptions?.data.filter(({ value }) =>
    practiceIdentifiers.includes(value)
  );
  return (
    <AppealioPopupWithFooter
      className={classNames(
        'appealio-popup--v3 appealio-popup--v3--overflow-hidden empty-practice-popup'
      )}
      onClosePressed={onClosePressed}
      title={
        <div className="d-flex align-items-center">
          <AiOutlineFolder size="22" className="mr-4" />
          {title}
        </div>
      }
      isFooterOutside={false}
    >
      {loading ? (
        <LoadingBlockHelper className="mt-8" isLoading />
      ) : (
        <PracticeList practices={practiceList} />
      )}
    </AppealioPopupWithFooter>
  );
};

const mapStateToProps = (state) => {
  const practiceOptions = getDropdownOptions(
    state,
    DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PRACTICE_OPTIONS
  );
  return {
    isFetchingPracticeOptions: practiceOptions.isFetching,
    practiceOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { fetchPracticeOptions: fetchDenialsPracticeOptions },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PracticeListPopup);
