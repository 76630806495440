// This file merely configures the store for hot reloading.
// This boilerplate file is likely to be the same for each project that uses Redux.
// With Redux, the actual stores are in /reducers.
// This file merely configures the store for hot reloading.
// This boilerplate file is likely to be the same for each project that uses Redux.
// With Redux, the actual stores are in /reducers.

import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router/immutable';

import { history } from './history';
import rootReducer from '../reducers';
import { apiConfigMiddleware } from '../../API/Config';

const routingMiddleware = routerMiddleware(history);

const configureStore = (initialState) => {
  const store = createStore(
    rootReducer(history),
    initialState,
    compose(
      applyMiddleware(thunkMiddleware),
      applyMiddleware(promiseMiddleware()),
      applyMiddleware(routingMiddleware),
      applyMiddleware(apiConfigMiddleware()),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }

  return store;
};

export default configureStore;
