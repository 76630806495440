import { MOCK_ESTIMATE_RESPONSE } from './CostEstimatorAPI.mock';

import {
  handleErrors,
  createRequest,
  handleJSONParse,
  createGetRequest,
  PCE_API_BASE_URL,
  INTEGRATION_API_BASE_URL,
} from './Config';
import { interpolate } from '../helpers/string';
import { camelizeKeys, snakeCaseKeys } from '../helpers/object';
import * as endpoints from '../constants/endpoints';

const mockAPICall = (data) =>
  new Promise((resolve, reject) => {
    setTimeout(() => resolve(data), 300);
  });

export const fetchEstimates = (filters) => {
  return mockAPICall(MOCK_ESTIMATE_RESPONSE);
};

export const fetchPatients = async (filters) => {
  const url = endpoints.PCE_SEARCH_PATIENTS;

  const requestBody = {
    criteria: { ...filters },
  };

  const request = createRequest(url, snakeCaseKeys(requestBody), {
    baseUrl: PCE_API_BASE_URL,
    isBearer: true,
  });

  const data = await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => res.data.map(camelizeKeys));

  return data;
};

export const fetchPatientInsurances = async (patientId) => {
  const url = interpolate(endpoints.PCE_PATIENT_INSURANCES, { patientId });

  const request = createGetRequest(url, {}, PCE_API_BASE_URL, true);

  const data = await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => res.data.map(camelizeKeys));

  return data;
};

export const verifyPatientInsuranceEligibility = async (
  patientId,
  insuranceId
) => {
  const url = interpolate(
    endpoints.PCE_VERIFY_PATIENT_INSURANCE_LATEST_ELIGIBILITY,
    { patientId, insuranceId }
  );
  const request = createRequest(
    url,
    {},
    {
      baseUrl: PCE_API_BASE_URL,
      isBearer: true,
      method: 'PUT',
    }
  );

  const response = await fetch(request).then(handleErrors);

  return response.status === 204;
};

export const fetchPatientInsuranceEligibility = async (
  patientId,
  insuranceId
) => {
  const url = interpolate(endpoints.PCE_PATIENT_INSURANCE_LATEST_ELIGIBILITY, {
    patientId,
    insuranceId,
  });

  const request = createGetRequest(url, {}, PCE_API_BASE_URL, true);

  const { data } = await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);

  return data;
};

export const fetchCPTs = async (code = '') => {
  const url = interpolate(endpoints.PCE_CPT_SEARCH, {
    code,
  });
  const request = createGetRequest(
    url,
    { estimate: 1 },
    INTEGRATION_API_BASE_URL,
    true
  );

  const { data } = await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);

  return data;
};

export const calculateEstimate = async (
  patient,
  checkId,
  insuranceId,
  procedures,
  override
) => {
  const url = endpoints.PCE_CALCULATE;

  const requestBody = {
    version: 1,
    patient,
    checkId,
    insuranceId,
    procedures: [...procedures],
  };

  if (override) {
    requestBody.override = override;
  }

  const request = createRequest(url, snakeCaseKeys(requestBody), {
    baseUrl: PCE_API_BASE_URL,
    isBearer: true,
  });

  const { data } = await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);

  return data;
};
