// SearchBox.js
import React from 'react';
import { FiSearch, FiX } from 'react-icons/fi';

import './searchBox.css';

const SearchBox = ({ placeholder, onChange, searchValue, clearSearch }) => {
  return (
    <div className="dd-search-box">
      <FiSearch className="dd-search-icon" size="16" />
      <input
        type="text"
        value={searchValue}
        placeholder={placeholder}
        onChange={onChange}
        autoFocus
      />
      {searchValue && (
        <FiX onClick={clearSearch} className="dd-search-clear" size="16" />
      )}
    </div>
  );
};

export default SearchBox;
