/**
 * Pre-defined background colors.
 * @type {string[]}
 */
export const PRE_DEFINED_BACKGROUND_COLORS = [
  '#00E676',
  '#F06292',
  '#BA68C8',
  '#1976D2',
  '#4CAF50',
];

/**
 * Other hex value.
 * @type {string}
 */
export const OTHER_HEX = '#FF9800';
