import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Select, { components } from 'react-select';
import { GrCircleInformation } from 'react-icons/gr';

import PracticeListPopup from '../../PracticeGroup/PracticeListPopup';

import { ALL_OPTION, OPTION_TYPES } from './constant';

import { addTypeToOptions } from './utils';

const PracticeSelect = ({
  input = {},
  practiceGroupOptions,
  practiceOptions,
  isDisabled = false,
  placeholder = 'All practices (by default)',
  ...rest
}) => {
  const [showPracticeList, setShowPracticeList] = useState({
    isOpen: false,
    practiceGroupId: null,
    practiceName: null,
  });
  const groupedOptions = [
    ALL_OPTION,
    {
      label: 'Practice Groups',
      options: addTypeToOptions(
        practiceGroupOptions,
        OPTION_TYPES.PRACTICE_GROUP
      ),
    },
    {
      label: 'Practices',
      options: addTypeToOptions(practiceOptions, OPTION_TYPES.PRACTICE),
    },
  ];

  const handleOpenPracticeGroupPopup = (practiceName, practiceGroupId) => {
    setShowPracticeList({
      isOpen: true,
      practiceGroupId,
      practiceName,
    });
  };

  const ValueContainer = ({ children, ...props }) => {
    return (
      <div
        onClick={(e) => {
          if (isDisabled) {
            e.preventDefault();
            return;
          }
          e.stopPropagation();
        }}
      >
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      </div>
    );
  };

  const PracticeGroupContent = ({ label, value }) => {
    const handleOpenPracticeGroup = (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleOpenPracticeGroupPopup(label, value);
    };
    return (
      <div className="d-flex align-items-center justify-content-between">
        <span>{label}</span>
        <div
          className="d-flex align-items-center px-2 py-2 cursor-pointer"
          onClick={handleOpenPracticeGroup}
          data-tip="Click here to view Practice(s)"
          data-for="practice-group-content-tooltip"
        >
          <GrCircleInformation />
        </div>
        {!isDisabled && (
          <ReactTooltip
            id="practice-group-content-tooltip"
            effect="solid"
            place="top"
          />
        )}
      </div>
    );
  };

  const Option = (props) => (
    <components.Option {...props}>
      {props.data.type === OPTION_TYPES.PRACTICE_GROUP ? (
        <PracticeGroupContent
          label={props.data.label}
          value={props.data.value}
        />
      ) : (
        props.label
      )}
    </components.Option>
  );

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      {props.data.type === OPTION_TYPES.PRACTICE_GROUP ? (
        <PracticeGroupContent
          label={props.data.label}
          value={props.data.value}
        />
      ) : (
        <span>{props.data.label}</span>
      )}
    </components.MultiValue>
  );

  const MultiValueRemove = (selectProps) =>
    isDisabled ? (
      <div className="pr-4" />
    ) : (
      <components.MultiValueRemove {...selectProps} />
    );

  return (
    <div style={{ position: 'relative' }}>
      <Select
        placeholder={placeholder}
        components={{ Option, MultiValue, MultiValueRemove, ValueContainer }}
        {...input}
        value={input.value?.toJS ? input.value?.toJS() : input.value}
        {...rest}
        options={groupedOptions}
        onChange={(selectedOptions) => {
          if (
            selectedOptions.some(
              (option) => option.value === ALL_OPTION.value
            ) &&
            input.value.every((option) => option.value !== ALL_OPTION.value)
          ) {
            input.onChange([ALL_OPTION]);
            return;
          }

          if (
            selectedOptions.length > 1 &&
            selectedOptions.some((option) => option.value === ALL_OPTION.value)
          ) {
            const newValue = selectedOptions.find(
              (option) => option.value !== ALL_OPTION.value
            );
            input.onChange([newValue]);
            return;
          }

          input.onChange(selectedOptions);
        }}
        isMulti
        onBlur={() => input.onBlur(input.value)}
        isDisabled={isDisabled}
      />

      {showPracticeList.isOpen && (
        <PracticeListPopup
          practiceGroupId={showPracticeList.practiceGroupId}
          title={showPracticeList.practiceName}
          onClosePressed={() => {
            setShowPracticeList({
              isOpen: false,
              practiceGroupId: null,
              practiceName: null,
            });
          }}
        />
      )}
    </div>
  );
};

export default PracticeSelect;
