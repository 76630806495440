import moment from 'moment';
import { useEffect, useState, useCallback } from 'react';
import { identity, isEmpty, pickBy, snakeCase, sortBy } from 'lodash';

import AppealsAPI from 'API/AppealsAPI';
import CreateAppealAPI from 'API/CreateAppealAPI';
import {
  fetchDeliveryLogs,
  fetchNotesCount,
  fetchSopAndRuleInfo as fetchSopAndRuleInfoAPI,
} from 'API/DeliveryLogsAPI';

import { DATE_FORMAT_YYYY_MM_DD } from 'helpers/dateFormats';

export const fetchNotesCounts = async (ids, isAppealIds = true) => {
  if (isEmpty(ids)) {
    return { notesCount: {}, notesLatestCreatedTime: {} };
  }

  try {
    const mappedId = isAppealIds ? { appeal: ids } : { dq: ids };
    const { data } = await fetchNotesCount(mappedId);
    const { newCount, latestCreatedTime } = data.reduce(
      (acc, cur) => {
        const id = isAppealIds ? cur.appealId : cur.dqId;
        acc.newCount[id] = cur.count;
        acc.latestCreatedTime[id] = cur.latestCreatedAt
          ? moment(cur.latestCreatedAt).format(DATE_FORMAT_YYYY_MM_DD)
          : null;
        return acc;
      },
      { newCount: {}, latestCreatedTime: {} }
    );
    return { notesCount: newCount, notesLatestCreatedTime: latestCreatedTime };
  } catch (error) {
    return { notesCount: {}, notesLatestCreatedTime: {} };
  }
};

export const useFetchNotesCounts = (ids, isAppealIds = true) => {
  const [notesCount, setNotesCount] = useState({});
  const [notesLatestCreatedTime, setNotesLatestCreatedTime] = useState({});
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(async () => {
    setLoading(true);
    const { notesCount, notesLatestCreatedTime } = await fetchNotesCounts(
      ids,
      isAppealIds
    );
    setNotesCount(notesCount);
    setNotesLatestCreatedTime(notesLatestCreatedTime);
    setLoading(false);
  }, [ids, isAppealIds]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { loading, notesCount, fetch, notesLatestCreatedTime };
};

// Function to check if sopIds exist for a given id
export const hasSopIds = (dataByItemId, id) => {
  return !!dataByItemId[id]?.sopIds && dataByItemId[id].sopIds.length > 0;
};

// Function to get the sopIds for a given id
export const getSopIds = (dataByItemId, id) => {
  return dataByItemId[id]?.sopIds || [];
};

export const fetchSopAndRuleInfo = async (ids, isAppeals = false) => {
  if (!ids || ids.length === 0) {
    return {};
  }

  try {
    const queryParams = {
      [isAppeals ? 'appeal_ids' : 'denials_queue_ids']: ids,
    };

    const response = await fetchSopAndRuleInfoAPI(queryParams);
    const dataByItemId = response.data.reduce((acc, cur) => {
      const { denialsQueueId, appealId, appliedRule, ruleId, sopIds } = cur;
      const key = isAppeals ? appealId : denialsQueueId;
      if (key) {
        acc[key] = {
          appealId,
          appliedRule,
          ruleId,
          sopIds,
        };
      }
      return acc;
    }, {});

    return dataByItemId;
  } catch (error) {
    return {};
  }
};

export const useFetchSopAndRuleInfo = (ids, isAppeals = false) => {
  const [sopAndRuleInfo, setSopAndRuleInfo] = useState({});
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(async () => {
    setLoading(true);
    const sopAndRuleInfo = await fetchSopAndRuleInfo(ids, isAppeals);
    setSopAndRuleInfo(sopAndRuleInfo);
    setLoading(false);
  }, [ids, isAppeals]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { sopAndRuleInfo, loading };
};

export const useFetchDeliveryLogs = (sortBy, filters, page) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [totalDeliveryLogs, setTotalDeliveryLogs] = useState(0);
  const [deliveryLogs, setDeliveryLogs] = useState([]);

  const fetch = useCallback(async () => {
    setLoading(true);
    setDeliveryLogs([]);
    setTotalDeliveryLogs(0);

    try {
      const queryParam = {
        ...filters,
        page,
      };

      if (!isEmpty(sortBy)) {
        queryParam.ordering = sortBy.desc
          ? `-${snakeCase(sortBy.id)}`
          : snakeCase(sortBy.id);
      }

      const { results, count } = await fetchDeliveryLogs(
        pickBy(queryParam, identity)
      );

      setDeliveryLogs(results);
      setTotalDeliveryLogs(count);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [sortBy, filters, page]);

  useEffect(() => {
    fetch();
  }, [sortBy, filters, page, fetch]);

  return { loading, error, fetch, totalDeliveryLogs, deliveryLogs };
};

const DEFAULT_OPTIONS = {
  payers: [],
  providers: [],
  agents: [],
  practices: [],
};

export const useFetchDeliveryLogsOptions = (
  isAppealioExpressUser = false,
  userInfo
) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState(DEFAULT_OPTIONS);

  const fetch = async () => {
    setLoading(true);
    setError(null);
    setOptions(DEFAULT_OPTIONS);

    try {
      const [payerResponse, providerResponse, agentsResponse] =
        await Promise.all([
          !isAppealioExpressUser ? AppealsAPI.getPayersOptions() : [],
          !isAppealioExpressUser
            ? AppealsAPI.getDropdownOptions('providers')
            : { data: [] },
          AppealsAPI.getDropdownOptions('users'),
        ]);
      const parsedProviders = CreateAppealAPI.parseProviders(
        providerResponse,
        true
      );
      const agents = CreateAppealAPI.parseAgents(agentsResponse);
      const userPractices = userInfo.relatedPractices || [];

      setOptions({
        payers: [
          {
            label: 'All',
            value: '',
          },
          ...payerResponse.map(({ name: label, id: value }) => ({
            label,
            value,
          })),
        ],
        providers: [
          {
            label: 'All',
            value: '',
          },
          ...parsedProviders.map(({ key: value, value: label }) => ({
            value,
            label,
          })),
        ],
        agents: [
          {
            label: 'All',
            value: '',
          },
          ...sortBy(agents, ({ isActive }) => !isActive).map(
            ({ id, name, isActive }) => ({
              label: !isActive ? `${name} (Inactive)` : name,
              value: id,
              isActive,
            })
          ),
        ],
        practices: [
          {
            label: 'All',
            value: '',
          },
          ...userPractices.map(
            ({ name: label, id: value, practiceIdentifier }) => ({
              label,
              value,
              practiceIdentifier,
            })
          ),
        ],
      });
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, error, options };
};
