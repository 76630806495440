import React, { useState } from 'react';

import './style.css';

import Input from 'components/common/input';

const PracticeList = ({ practices }) => {
  const [searchValue, setSearchValue] = useState('');

  /**
   * Handles the search change.
   * @param {Object} e
   */
  const handleSearchChange = (e) => setSearchValue(e.target.value);

  const filteredPractices = practices.filter((practice) =>
    practice.label.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <>
      <div className="practice-group__sticky-search-bar row pb-0">
        <div className="col-lg-12">
          <Input
            label="Search for Practice"
            placeholder="Practice Name"
            onChange={handleSearchChange}
            value={searchValue}
          />
        </div>
      </div>
      <div className="col-lg-12  mt-2  mb-12">
        <div className="d-flex justify-content-flex-end ">
          <span className="fs-12">Total: {filteredPractices.length}</span>
        </div>
      </div>
      <div className="row mb-12">
        <div className="col-lg-12">
          <ul className="practice-group__practice-list">
            {filteredPractices.map((practice, idx) => (
              <li className="practice-group__practice-list--item" key={idx}>
                <span>{practice.label}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default PracticeList;
