import React, { useState } from 'react';
import { FiCalendar } from 'react-icons/fi';
import { SingleDatePicker } from 'react-dates';
import { ICON_AFTER_POSITION } from 'react-dates/constants';

const DatePicker = (props) => {
  const [focused, setFocused] = useState(false);

  return (
    <SingleDatePicker
      id="date"
      placeholder="Select Date"
      focused={focused}
      displayFormat="LL"
      numberOfMonths={1}
      customInputIcon={<FiCalendar size="20px" />}
      onFocusChange={({ focused }) => setFocused(focused)}
      inputIconPosition={ICON_AFTER_POSITION}
      hideKeyboardShortcutsPanel={true}
      {...props}
    />
  );
};

export default DatePicker;
