import React from 'react';
import AppealioPopup from '../Shared/AppealioPopup';

const PendingApprovalPopup = (props) => {
  return (
    <AppealioPopup
      title="Pending Approval"
      className="pending-approval-popup"
      onClosePressed={props.onClosePressed}
      datacy="pending-approval-Appealio-Popup"
    >
      <div className="pending-approval-info">
        Your Request has been sent to Appealio’s CX team. They will be in touch
        with you shortly.
        <br />
        <br />
        Thank you for your patience!
      </div>

      <button
        onClick={props.onClosePressed}
        className="return-to-denials-queue-btn mt-24"
        datacy="return-to-denials-queue-button"
      >
        Return to Denials Queue
      </button>
    </AppealioPopup>
  );
};

export default PendingApprovalPopup;
