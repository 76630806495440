import { orderBy, get } from 'lodash';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';

import './style.css';

import * as toast from 'components/Shared/toast';
import { AppealioPopupWithFooter } from 'components/common/popup';

import { handleError } from 'helpers/errorHandler';
import * as AccountsAPI from 'API/AccountSettingsAPI';
import { useFetchAssociatedProviders } from './hooks';
import ProvidersList from './ProviderList/ProvidersList';

import { getUserInfo } from 'redux/reducers/loginStore';
import { setUserPractices } from 'redux/actions/loginActions';

const ChangePracticeStatusPopup = (props) => {
  const {
    title,
    onClosePressed,
    practice,
    toDeactivate,
    onSubmitSuccess,
    userPractices,
  } = props;

  const { data, loading } = useFetchAssociatedProviders(practice.id);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmitClick = async () => {
    try {
      setIsSubmitting(true);
      const updatePractices = (userPractices, practice) => {
        const filteredPractices = userPractices.filter(
          (userPractice) => userPractice.id !== practice.id
        );
        return [...filteredPractices, { ...practice, isActive: false }];
      };

      if (toDeactivate) {
        await AccountsAPI.deactivatePractice(practice.id);
        props.actions.setUserPractices(
          orderBy(updatePractices(userPractices, practice), ['name'], ['asc'])
        );
      } else {
        await AccountsAPI.requestReactivate(practice.id, 'practice');
      }
      setIsSubmitting(false);
      const toastMessage = toDeactivate
        ? "Practice's status updated successfully"
        : "Practice's status change requested to the CX team successfully";
      toast.success({
        title: '',
        message: toastMessage,
      });
      onSubmitSuccess();
    } catch (error) {
      setIsSubmitting(false);
      handleError(error);
    }
  };

  return (
    <AppealioPopupWithFooter
      className="appealio-popup--v3 change-practice-status-popup"
      title={title}
      onClosePressed={onClosePressed}
      isFooterOutside={false}
      datacy="change-practice-status-AppealioPopupWithFooter"
    >
      <div>
        <div className="mb-16">
          <div className="fs-14 fw-bold">Practice Name</div>
          <div>{practice.name}</div>
        </div>
        <div className="mb-16">
          <div className="fs-14 fw-bold">Address</div>
          <div>
            <div>{practice.address1}</div>
            {practice.address2 && <div>{practice.address2}</div>}
            <div>
              {practice.city && `${practice.city}, `}
              {practice.state} {practice.zipcode}
            </div>
            <div>US</div>
          </div>
        </div>

        <div className="mb-16">
          <div className="fs-14 fw-bold mb-12">
            Associated Rendering Provider(s)
          </div>
          <div className="mb-12">
            The following associated rendering provider(s) will also be
            {toDeactivate ? ' deactivated' : ' reactivated'}.
          </div>
          {loading ? (
            <div>Loading ...</div>
          ) : data.renderingProviders.length > 0 ? (
            <div>
              <ProvidersList
                providers={data.renderingProviders.map((provider) => provider)}
                datacy="change-practice-status-ProvidersList"
              />
            </div>
          ) : (
            <div>None</div>
          )}
        </div>

        <div>
          <div className="fs-14 fw-bold mb-12">
            Associated Billing Provider(s)
          </div>
          <div className="mb-12">
            The following associated billing provider(s) will also be
            {toDeactivate ? ' deactivated' : ' reactivated'}.
          </div>
          {loading ? (
            <div>Loading ...</div>
          ) : data.billingProviders.length > 0 ? (
            <div>
              <ProvidersList
                providers={data.billingProviders.map((provider) => provider)}
              />
            </div>
          ) : (
            <div>None</div>
          )}
        </div>

        <div>
          <div className="fs-14 fw-bold mb-12">Associated Clinics</div>
          <div className="mb-12">
            The following associated clinics will also be
            {toDeactivate ? ' deactivated' : ' reactivated'}.
          </div>
          {loading ? (
            <div>Loading ...</div>
          ) : data.clinics.length > 0 ? (
            <div>
              <ProvidersList
                providers={data.clinics.map((provider) => provider)}
                datacy="change-practice-status-ProvidersList"
              />
            </div>
          ) : (
            <div>None</div>
          )}
        </div>
      </div>
      <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
        <div className="d-flex justify-content-flex-end">
          <button
            disabled={isSubmitting}
            className="ml-0 mr-16 ap-button ap-button--primary-red ap-button--primary-md"
            onClick={onClosePressed}
          >
            Cancel
          </button>
          <button
            disabled={isSubmitting}
            className="ml-0 ap-button ap-button--primary-dark ap-button--primary-md"
            onClick={onSubmitClick}
          >
            {toDeactivate ? 'Deactivate' : 'Reactivate'}
          </button>
        </div>
      </div>
    </AppealioPopupWithFooter>
  );
};

ChangePracticeStatusPopup.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setUserPractices }, dispatch),
});

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);
  const userPractices = get(userInfo, 'relatedPractices', []);

  return {
    userPractices,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePracticeStatusPopup);
