export const shippingAddressSerializer = (shippingAddress) => {
  const {
    name,
    addressLine1,
    addressLine2,
    city,
    state,
    attentionLine,
    zipcode,
  } = shippingAddress;
  return {
    name,
    address_line1: addressLine1,
    address_line2: addressLine2,
    attention_line: attentionLine,
    city,
    state,
    zipcode,
  };
};
