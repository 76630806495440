import React from 'react';
import PropTypes from 'prop-types';

import SecondaryNav from 'components/common/nav/SecondaryNav';

import { EXPRESS_SETTINGS_NAV_ITEMS } from 'constants/Navigation';

const AppealioExpressSettings = (props) => {
  return (
    <div>
      <SecondaryNav navItems={EXPRESS_SETTINGS_NAV_ITEMS} />
      {props.children}
    </div>
  );
};

AppealioExpressSettings.propTypes = {
  children: PropTypes.node,
};

export default AppealioExpressSettings;
