import React from 'react';

export const ErrorHandling = (ComposedComponent) =>
  class extends React.Component {
    static displayName = 'ComponentEnhancedWithIntervalHOC';

    constructor(props) {
      super(props);
      this.state = {
        displayErrors: [],
      };
    }

    validateForm = (values, validate) => {
      const formErrors = validate(values);
      if (!formErrors.displayErrors) {
        formErrors.displayErrors = [];
      }
      this.setState({ displayErrors: formErrors.displayErrors });
      return formErrors;
    };

    updateErrors = (displayErrors) => {
      this.setState({ displayErrors });
    };

    render() {
      return (
        <ComposedComponent
          {...this.props}
          {...this.state}
          validateForm={this.validateForm}
          updateErrors={this.updateErrors}
        />
      );
    }
  };
