export const preventBackNavigation = () => {
  window.history.pushState(null, '', window.location.href);

  window.addEventListener('popstate', handlePopState);
};

const handlePopState = (event) => {
  window.removeEventListener('popstate', handlePopState);
  // Redirect to the root path
  window.location.href = '/';
};
