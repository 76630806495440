import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { fetchDenialsReminders } from 'API/RemindersAPI';

export const fetchAndPrepareRemindersData = async (
  ids,
  isAppealsIds = true
) => {
  if (isEmpty(ids)) {
    return { remindersData: {}, error: null };
  }

  try {
    const payload = !isAppealsIds
      ? { denialsQueueIds: ids }
      : { appealIds: ids };
    const response = await fetchDenialsReminders(payload);

    const remindersData = response.data.reduce((acc, reminder) => {
      const id = !isAppealsIds ? reminder.denialsQueueId : reminder.appealId;
      acc[id] = reminder;
      return acc;
    }, {});

    return { remindersData, error: null };
  } catch (err) {
    return { remindersData: {}, error: err };
  }
};

export const useRemindersData = (ids, isAppealsIds = true) => {
  const [remindersData, setRemindersData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchReminders = useCallback(async () => {
    setLoading(true);
    const { remindersData: newData, error: newError } =
      await fetchAndPrepareRemindersData(ids, isAppealsIds);
    setRemindersData(newData);
    setError(newError);
    setLoading(false);
  }, [ids, isAppealsIds]);

  useEffect(() => {
    fetchReminders();
  }, [fetchReminders]);

  return {
    loading,
    error,
    remindersData,
    fetchReminders,
  };
};
