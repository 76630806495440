import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';

import Card from 'components/common/card';
import Input from 'components/common/input';
import Select from 'components/common/select/Select';

import { MASK_PHONE } from 'helpers/masks';
import { states } from 'components/CreateAppeal/state-mock-data';
import { validatePhoneNumber, validateZipCode } from 'helpers/validators';

const validate = (formValues) => {
  const values = formValues.toJS();
  const errors = {};

  const nonEmptyFields = [
    {
      key: 'name',
      label: 'Company Name',
    },
    {
      key: 'address1',
      label: 'Company Address',
    },
    {
      key: 'city',
      label: 'City',
    },
    {
      key: 'state',
      label: 'State',
    },
    {
      key: 'zipcode',
      label: 'Zip Code',
    },
    {
      key: 'phone',
      label: 'Phone',
    },
    {
      key: 'firstName',
      label: 'First Name',
    },
    {
      key: 'lastName',
      label: 'Last Name',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'username',
      label: 'Username',
    },
  ];

  nonEmptyFields.forEach((field) => {
    if (!values[field.key]) {
      errors[field.key] = `${field.label} is Required`;
    }

    if (
      field.key === 'email' &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }
  });

  if (!errors.zipcode && values.zipcode && validateZipCode(values.zipcode)) {
    errors.zipcode = 'Invalid Zip code';
  }

  if (!errors.phone && values.phone && validatePhoneNumber(values.phone)) {
    errors.phone = 'Invalid Phone number';
  }

  return errors;
};

let AccountInformation = (props) => {
  const { handleSubmit, onSubmit } = props;

  const handleFormSubmit = (values) => {
    const formValues = values.toJS();
    onSubmit({
      ...formValues,
      state: formValues.state.value,
    });
  };

  const stateOptions = states.map((state) => ({
    label: state.value,
    value: state.key,
  }));

  return (
    <Card>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="form-section">
          <div className="row form-info-row">
            <div className="col-sm-12">
              <div className="form-section-title">Company Information</div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <Field
                component={Input}
                name="name"
                label="Company Name *"
                type="text"
                placeholder="Company name"
                required={true}
                maxLength={100}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <Field
                label="Phone *"
                name="phone"
                placeholder="(123) 456-7890"
                type="string"
                mask={MASK_PHONE}
                size="10"
                required={true}
                component={Input}
                maskedInput
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <Field
                label="Company Address Line 1 *"
                name="address1"
                component={Input}
                placeholder="Company Address Line 1"
                type="text"
                maxLength={50}
              />
            </div>
            <div className="col-sm-6">
              <Field
                label="Company Address Line 2"
                name="address2"
                placeholder="Company Address Line 2"
                component={Input}
                type="text"
                maxLength={50}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4">
              <Field
                label="City *"
                name="city"
                placeholder="City"
                component={Input}
                type="text"
                maxLength={50}
              />
            </div>
            <div className="col-sm-4">
              <Field
                component={Select}
                options={stateOptions}
                name="state"
                defaultValue={null}
                label="State *"
                placeholder="State"
              />
            </div>
            <div className="col-sm-4">
              <Field
                label="Zip Code *"
                name="zipcode"
                component={Input}
                type="text"
                placeholder="Zip Code"
              />
            </div>
          </div>
        </div>
        <hr className="card-horizontal-line" />
        <div className="form-section">
          <div className="row form-info-row">
            <div className="col-sm-12">
              <div className="form-section-title">Personal Information</div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <Field
                label="First Name *"
                name="firstName"
                component={Input}
                type="text"
                placeholder="First Name"
                maxLength={30}
              />
            </div>
            <div className="col-sm-6">
              <Field
                label="Last Name *"
                name="lastName"
                component={Input}
                type="text"
                placeholder="Last Name"
                maxLength={150}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <Field
                label="Email *"
                name="email"
                component={Input}
                type="email"
                placeholder="wireframy@email.com"
              />
            </div>
            <div className="col-sm-6">
              <Field
                label="Username *"
                name="username"
                component={Input}
                type="text"
                placeholder="Username"
                maxLength={150}
              />
            </div>
          </div>
        </div>

        <hr className="card-horizontal-line" />

        <div className="form-button-wrapper">
          <button className="mr-8 ap-button ap-button--secondary" type="submit">
            Create Account
          </button>
        </div>
      </form>
    </Card>
  );
};

AccountInformation = reduxForm({
  form: 'accountInformation',
  validate,
})(AccountInformation);

export default AccountInformation;
