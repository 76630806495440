import { useCallback, useEffect, useState } from 'react';
import { fetchDvVerifiedContacts } from 'API/AccountSettingsAPI';

export const useFetchDvVerifiedMailAndFaxDropdown = (payerId) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const fetch = useCallback(async () => {
    if (!payerId) {
      return;
    }
    setLoading(true);
    setContacts([]);
    setError(null);
    try {
      const { data } = await fetchDvVerifiedContacts({ payer_id: payerId });
      setContacts(data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [payerId]);

  useEffect(() => {
    fetch();
  }, [fetch]);
  return { loading, error, contacts };
};
