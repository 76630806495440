import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './stepperWithBar.css';

const StepperWithBar = (props) => {
  const { steps = [], activeStep = null } = props;

  const getStepperBarClassNames = (step) => {
    return classnames('ap-stepper-with-bar__bar', {
      'ap-stepper-with-bar__bar--active': step === activeStep,
      'ap-stepper-with-bar__bar--completed': step < activeStep,
    });
  };

  return (
    <div className="ap-stepper-with-bar">
      {steps?.map(({ step, title }, index) => (
        <div className="ap-stepper-with-bar__wrapper" key={step}>
          <div className="ap-stepper-with-bar__title">
            {step}. {title}
          </div>
          <div className={getStepperBarClassNames(step)}></div>
        </div>
      ))}
    </div>
  );
};

StepperWithBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeStep: PropTypes.number,
};

export default StepperWithBar;
