import classnames from 'classnames';
import Dropzone from 'react-dropzone';
import ReactTooltip from 'react-tooltip';
import React, { useRef, useState } from 'react';

import * as toast from 'components/Shared/toast';

import closeImg from 'img/close-copy.svg';

import { mimeTypes } from 'helpers/mimeTypes';

import './style.css';

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB in bytes
const MAX_FILE_COUNT = 5; // Maximum number of files allowed

const DEFAULT_ACCEPTED_TYPES = [
  mimeTypes.APPLICATION_VND_MS_EXCEL,
  mimeTypes.APPLICATION__OFFICEDOCUMENT_SPREADSHEETML_SHEET,
];

const EmailFileDropzone = ({
  files,
  onFilesChange,
  className,
  acceptedTypes = DEFAULT_ACCEPTED_TYPES,
  onFileDelete = () => ({}),
}) => {
  const dropzoneRef = useRef(null);
  const [isDragOver, setIsDragOver] = useState(false);

  const onDrop = (acceptedFiles) => {
    if (files.length + acceptedFiles.length > MAX_FILE_COUNT) {
      toast.error({
        title: 'File limit exceeded',
        message: `You can upload maximum ${MAX_FILE_COUNT} files.`,
      });
      return;
    }
    const validFiles = acceptedFiles.filter((file) => {
      if (!acceptedTypes.includes(file.type)) {
        return false;
      }

      if (file.size === 0) {
        toast.error({
          title: 'Empty file',
          message: `${file.name} is empty.`,
        });
        return false;
      }

      if (file.size > MAX_FILE_SIZE) {
        toast.error({
          title: 'File size limit exceeded',
          message: `File size should be less than 20 MB. ${
            file.name
          } is ${Math.round(file.size / 1024 / 1024)} MB`,
        });
        return false;
      }

      return true;
    });

    onFilesChange([...files, ...validFiles]);
  };

  const onFileRemove = (fileToRemove) => {
    if (fileToRemove?.id) {
      onFileDelete(fileToRemove.id);
    }
    onFilesChange(files.filter((file) => file !== fileToRemove));
  };

  return (
    <div
      className={classnames('file-dropzone', {
        className,
      })}
    >
      <Dropzone
        ref={dropzoneRef}
        onDrop={onDrop}
        accept={acceptedTypes}
        multiple={true}
        className="width-100"
      >
        <div
          className={`dropzone ${isDragOver ? 'dropzone-active' : ''}`}
          onDragOver={(e) => {
            setIsDragOver(true);
          }}
          onDragLeave={(e) => {
            setIsDragOver(false);
          }}
          onDrop={(e) => {
            setIsDragOver(false);
          }}
        >
          <p>
            Drag & drop files here, or click to select files. <br />{' '}
            <strong>Acceptable File Formats:</strong> xls,xlsx
          </p>
        </div>
      </Dropzone>
      {files.length > 0 && (
        <div className="file-list">
          {files.map((file, index) => (
            <div key={index} className="file-item">
              <span className="file-name">{file.name}</span>
              <button
                className="delete-document-button"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onFileRemove(file);
                }}
                data-tip="Delete Attachment"
              >
                <img
                  className="delete-document-icon"
                  src={closeImg}
                  draggable="false"
                />
              </button>
            </div>
          ))}
          <ReactTooltip place="top" effect="solid" />
        </div>
      )}
    </div>
  );
};

export default EmailFileDropzone;
