import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';

import TabBar from '../../Shared/TabBar';
import AppealNotes from './Tabs/AppealNotes';
import AppealClaims from './Tabs/AppealClaims';
import AppealHistory from './Tabs/AppealHistory';
import AppealSubmitLogs from './Tabs/AppealSubmitLogs';
import AppealPatientInfo from './Tabs/AppealPatientInfo';
import LoadingBlockHelper from '../../Shared/LoadingBlockHelper';

import { getAppealFromState } from '../../../redux/reducers/AppealStore';
import { getAppealById } from '../../../redux/actions/createAppealActions';

import { APIConfig } from '../../../API/Config';
import { defaultIncludeOptions } from '../../../API/CreateAppealAPI';
import { transformLinkPathToBasename } from '../../../API/Serializers/Transforms';

class AppealDetails extends Component {
  static propTypes = {
    appealId: PropTypes.string.isRequired,
    defaultActiveTabKey: PropTypes.string.isRequired,
  };

  static defaultProps = {
    defaultActiveTabKey: '0',
  };

  componentDidMount() {
    this.props.actions.getAppealById(this.props.appealId, [
      ...defaultIncludeOptions,
      'history_appeals',
      'submit_logs',
    ]);
  }

  renderLoadingBlock() {
    if (!this.props.appealData || this.props.appealData.isLoading === true) {
      return (
        <LoadingBlockHelper isLoading={true} className="tabbar__loading" />
      );
    }
  }

  renderTabs() {
    if (this.props.appealData && this.props.appealData.appeal) {
      const appeal = this.props.appealData.appeal;
      const hasSubmitLogs = !_.isEmpty(appeal.submitLogs);
      const isNotPayerPortal =
        hasSubmitLogs &&
        !appeal.submitLogs.some((log) => log.submit_type === 'portal');

      return (
        <div
          className="detail__height"
          style={{ minHeight: 900 + this.props.itemsListLength * 16 }}
        >
          <div className="detail__header">
            <div className="detail__header-name">{appeal.patientName}</div>
            <div className="tabbar__id">Status: {appeal.status}</div>
          </div>
          <TabBar defaultActiveTabKey={this.props.defaultActiveTabKey}>
            <AppealClaims
              title={
                !this.props.isMedicalRecordsSubmission ? 'Claim' : 'Record'
              }
              appeal={appeal}
              formattedDocuments={this.props.formattedDocuments}
              formattedLetters={this.props.formattedLetters}
              formattedSubmissionDate={this.props.formattedSubmissionDate}
              formattedDeadline={this.props.formattedDeadline}
            />
            <AppealPatientInfo
              title="Patient"
              appeal={appeal}
              formattedDOB={this.props.formattedDOB}
            ></AppealPatientInfo>
            <AppealNotes title="Notes" appealId={this.props.appealId} />
            {hasSubmitLogs && isNotPayerPortal && this.renderSubmissionsTab()}
            {!this.props.isMedicalRecordsSubmission && this.renderHistoryTab()}
          </TabBar>
        </div>
      );
    }
  }

  renderHistoryTab() {
    return <AppealHistory appealID={this.props.appealId} title="History" />;
  }

  renderSubmissionsTab() {
    return (
      <AppealSubmitLogs
        title="Submission Events"
        appealID={this.props.appealId}
      />
    );
  }

  render() {
    return (
      <div className="detail__container">
        {this.renderLoadingBlock()}
        {this.renderTabs()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const appealDetailsWrapper = getAppealFromState(state, ownProps.appealId);
  const params = APIConfig.processParams();
  let deadline, dob, letters, documents, submissionDate;
  if (appealDetailsWrapper && appealDetailsWrapper.appeal) {
    const deadlineProp = appealDetailsWrapper.appeal.deadline;

    deadline = deadlineProp ? moment(deadlineProp).format('MM/DD/YYYY') : '';
    dob = moment(appealDetailsWrapper.appeal.patient.dob, 'YYYY-MM-DD').format(
      'MM/DD/YYYY'
    );

    submissionDate =
      appealDetailsWrapper.appeal.submittedAt &&
      moment(appealDetailsWrapper.appeal.submittedAt).format('MM/DD/YYYY');

    documents = appealDetailsWrapper.appeal.documents.map((item) => {
      return {
        title: transformLinkPathToBasename(item.linkPath),
        link: `/download_appeal_document/${item.id}`,
        prefix: 'document_link_',
      };
    });

    letters = appealDetailsWrapper.appeal.letters.map((item) => {
      return {
        title: `${item.name}.PDF`,
        params,
        link: `appeal_letter_pdfs/${item.id}`,
        prefix: 'letter_link_',
      };
    });
  }

  return {
    appealData: appealDetailsWrapper,
    formattedDeadline: deadline,
    formattedSubmissionDate: submissionDate,
    formattedDOB: dob,
    formattedDocuments: documents,
    formattedLetters: letters,
    itemsListLength: Math.max(
      documents ? documents.length : 0,
      letters ? letters.length : 0
    ),
    isMedicalRecordsSubmission: _.get(
      appealDetailsWrapper,
      'appeal.isMedicalRecordsSubmission',
      true
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getAppealById }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppealDetails);
