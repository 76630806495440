import { get } from 'lodash';

const { sortAvailityClaimStatuses } = require('API/PayerAPI');
const { PAYER_API_TYPE } = require('constants/appConstants');

export const extractStatus = (historyItem) => {
  if (historyItem.api === PAYER_API_TYPE.AVAILITY) {
    if (!historyItem.responseJson) return 'N/A';
    const sortedClaimStatuses = sortAvailityClaimStatuses(
      get(historyItem, 'responseJson.claimStatuses', []),
      historyItem?.claimControlNumber
    );
    if (!sortedClaimStatuses || sortedClaimStatuses.length === 0) return 'N/A';
    const statusCategoryCode = get(
      sortedClaimStatuses,
      '[0].statusDetails[0].categoryCode',
      ''
    );
    return getStatusCategory(statusCategoryCode);
  }
  return historyItem.status;
};

function getStatusCategory(statusCode) {
  statusCode = statusCode.toUpperCase();
  if (statusCode.startsWith('A')) {
    return 'Acknowledgements';
  } else if (statusCode.startsWith('DR')) {
    return 'Data Reporting Acknowledgments';
  } else if (statusCode.startsWith('P')) {
    return 'Pending';
  } else if (statusCode.startsWith('F')) {
    if (statusCode === 'F2') {
      return 'Denied';
    } else {
      return 'Finalized';
    }
  } else if (statusCode.startsWith('R')) {
    return 'Requests for additional Information';
  } else if (statusCode.startsWith('E')) {
    return 'Error';
  } else if (statusCode === 'D0') {
    return 'Unsuccessful';
  } else {
    return 'Unknown status code';
  }
}
