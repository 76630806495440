import React from 'react';
import classnames from 'classnames';

import './style.css';

const ToggleSwitch = ({
  options: { option1, option2 },
  checked,
  handleToggle,
  className,
  optionClassName,
}) => {
  return (
    <div
      className={classnames(
        'd-flex align-items-center toggle-wrapper',
        className
      )}
      datacy="toggle"
    >
      <p className={classnames('toggle-option', optionClassName)}>{option1}</p>
      <input
        type="checkbox"
        className="toggle"
        checked={checked}
        onChange={handleToggle}
      />
      <p className={classnames('toggle-option', optionClassName)}>{option2}</p>
    </div>
  );
};

export default ToggleSwitch;
