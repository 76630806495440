export const extractInitials = (string) => {
  const names = string.split(' ');

  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};

/**
 * Build supplied string by interpolating properties after delimiter ':' with the given parameters.
 *
 * @example
 * interpolate('Hi! :name.', {name: 'Matteo'})
 * => 'Hi! Matteo.'
 *
 * @param {string} str
 * @param {object} params
 *
 * @returns string
 */
export const interpolate = (str, params = {}) => {
  let formattedString = str;
  for (const [key, value] of Object.entries(params)) {
    const val = value || '';
    formattedString = formattedString.replace(
      new RegExp(':' + key, 'gi'),
      val.toString()
    );
  }

  return formattedString;
};

/**
 * Capitalize the first letter of a string.
 *
 * @param {String} string
 * @returns {String}
 */
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Convert a string to an alphanumeric string by removing all non-alphanumeric characters.
 *
 * @param {string} str - The input string.
 * @returns {string} - The alphanumeric string.
 */
export const toAlphanumeric = (str) => {
  return str.replace(/[^a-z0-9]/gi, '');
};
