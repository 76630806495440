import React, { useState } from 'react';

import * as toast from 'components/Shared/toast';
import { AppealioPopupWithFooter } from 'components/common/popup';

import { handleError } from 'helpers/errorHandler';
import * as AccountsAPI from 'API/AccountSettingsAPI';

const ChangeContactStatusPopup = (props) => {
  const {
    title,
    onClosePressed,
    contact,
    toDeactivate,
    onSubmitSuccess,
    label,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmitClick = async () => {
    try {
      setIsSubmitting(true);
      if (toDeactivate) {
        await AccountsAPI.deactivateContact(contact.id);
      } else {
        const data = {
          name: contact.name,
          isActive: true,
        };
        await AccountsAPI.activateContact(data, contact.id);
      }
      setIsSubmitting(false);
      const toastMessage = toDeactivate
        ? `${label} has been deactivated successfully`
        : `${label} has been activated successfully`;
      toast.success({
        title: '',
        message: toastMessage,
      });
      onSubmitSuccess();
    } catch (error) {
      setIsSubmitting(false);
      handleError(error);
    }
  };

  return (
    <AppealioPopupWithFooter
      className="appealio-popup--v3"
      title={title}
      onClosePressed={onClosePressed}
      isFooterOutside={false}
    >
      <div>
        <div className="mb-16">
          <div className="fs-14 fw-bold">Payer Contact Name</div>
          <div>{contact.name}</div>
        </div>
      </div>
      <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
        <div className="d-flex justify-content-flex-end">
          <button
            disabled={isSubmitting}
            className="ml-0 mr-16 ap-button ap-button--primary-red ap-button--primary-md"
            onClick={onClosePressed}
          >
            Cancel
          </button>
          <button
            disabled={isSubmitting}
            className="ml-0 ap-button ap-button--primary-dark ap-button--primary-md"
            onClick={onSubmitClick}
          >
            {toDeactivate ? 'Deactivate' : 'Reactivate'}
          </button>
        </div>
      </div>
    </AppealioPopupWithFooter>
  );
};

ChangeContactStatusPopup.propTypes = {};

export default ChangeContactStatusPopup;
