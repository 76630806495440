import React from 'react';

import AddUserForm from './AddUserForm';
import * as toast from 'components/Shared/toast';
import { AppealioPopupWithFooter } from 'components/common/popup';

import { handleError } from 'helpers/errorHandler';

import * as AccountsAPI from 'API/AccountSettingsAPI';

const AddUser = (props) => {
  const { onClosePressed, fetchUsers } = props;

  const handleUserCreateError = async (error) => {
    const errorResponse = error.response;
    if (errorResponse.status === 400) {
      const response = await error.response.json();
      if (response.username) {
        return toast.error({
          message: response.username[0],
          title: 'Error',
        });
      }
    } else if (errorResponse.status > 400) {
      return toast.error({
        message: 'Unknown issue creating new user.',
        title: 'Error',
      });
    }
    handleError(error);
  };

  const onUserSubmit = async (values) => {
    const {
      id,
      firstName,
      lastName,
      username,
      emailAddress,
      phone,
      fax,
      allowedItems,
      role,
    } = values;

    const formData = {
      ...(id && { id }),
      firstName,
      lastName,
      username,
      email: emailAddress,
      phone,
      fax,
      allowedItems,
      role,
    };

    try {
      await AccountsAPI.createUser(formData);
      toast.success({
        title: 'Success',
        message: 'User added successfully.',
      });
      onClosePressed();
      fetchUsers();
    } catch (error) {
      handleUserCreateError(error);
    }
  };

  const title = 'Add User';

  return (
    <AppealioPopupWithFooter
      className="appealio-popup--v3"
      onClosePressed={onClosePressed}
      title={title}
      isFooterOutside={false}
      datacy="add-user-AppealioPopupWithFooter"
    >
      <AddUserForm onSubmit={onUserSubmit} datacy="add-user-AddUserForm" />
    </AppealioPopupWithFooter>
  );
};

AddUser.propTypes = {};

export default AddUser;
