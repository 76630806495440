export const calculatePercentage = (a, b) => (a / b) * 100;

export const isEven = (number) => number % 2 === 0;

/**
 * Formats a number to a relative format.
 *
 * - Numbers >= 1,000,000 are represented in 'M' format.
 * - Numbers >= 100,000 but < 1,000,000 are represented in 'K' format.
 * - Numbers < 100,000 are formatted with commas.
 *
 * @param {number} num - The number to format.
 * @returns {string} The formatted number.
 */
export const formatNumber = (num) => {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  }
  if (num >= 100000) {
    return `${(num / 1000).toFixed(0)}K`;
  }
  return new Intl.NumberFormat().format(num);
};
