import React from 'react';

import Plug from '../../../../img/plug.svg';
import Flag from '../../../../img/flag.svg';
import ActionOverView from './ActionOverview';
import CommonSystemOverView from './CommonSystemOverview';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';

import { formatNumber } from 'helpers/number';

const SystemOverView = ({ filters, loading, data }) => {
  return (
    <div className="system-overview__card">
      {loading ? (
        <LoadingBlockHelper isLoading className="mt-20" />
      ) : (
        <div>
          <div className="d-flex flex-wrap--wrap mb-10">
            <h4 className="system-overview__card-heading">System Overview</h4>
            <CommonSystemOverView
              systemNumber={formatNumber(data?.totalActiveRules)}
              systemImage={Plug}
              systemName="Rules"
            />
            <CommonSystemOverView
              systemNumber={formatNumber(data?.totalDenialFlagged)}
              systemImage={Flag}
              systemName="Denials Flagged"
            />
            <div className="system-overview__line"></div>
            <CommonSystemOverView
              systemNumber={`$${formatNumber(data?.totalBilledAmount)}`}
              systemName="Billed Amount"
            />
          </div>
          <div className="d-flex flex-wrap--wrap">
            <ActionOverView data={data} filters={filters} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SystemOverView;
