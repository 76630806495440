/**
 * Created by alex on 10/5/16.
 */

import { parse } from 'qs';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { replace, push } from 'connected-react-router';

import * as routes from '../../constants/routes';

import Footer from '../Shared/Footer';
import AppealHeader from './AppealHeader/AppealHeader';
import LoadingIndicator from '../Shared/LoadingIndicator';
import CreateAppealSubHeader from './CreateAppealSubHeader';
import { CreateAppealMode } from './DenialInformation/CreateAppealMode';
import SlidingAppealDetails from '../Dashboard/AppealDetails/SlidingAppealDetails';

import {
  getCurrentAppealData,
  getCurrentAppealMode,
  getCurrentAppealPreFillData,
  isCurrentAppealMedicalRecord,
  isStandAloneSubmission,
} from '../../redux/reducers/createAppealStore';
import { clearCurrentShippingAddress } from 'redux/actions/appealFormPrefillActions';
import {
  getAppealById,
  setAppealData,
  setBillingAgent,
  clearCurrentAppealData,
} from '../../redux/actions/createAppealActions';
import { getAppealFromState } from '../../redux/reducers/AppealStore';
import { isSubmittedAppeal } from './createAppealUtils';

class CreateAppealComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAppealDetailsOpen: false,
      isFetchingAppeal: true,
    };
  }

  async componentDidMount() {
    const mode = this.props.mode;
    const parsedAppealId = this.props.parsedAppealId;

    if (parsedAppealId) {
      this.setState({ isFetchingAppeal: true });
      try {
        const data = await this.props.actions.getAppealById(parsedAppealId);
        const {
          value: { status },
        } = data;

        if (isSubmittedAppeal(status)) {
          this.props.actions.replace('/');
        }

        this.props.actions.setAppealData({
          appeals: [
            {
              ...data.value,
            },
          ],
          name: data.value.patientName,
        });
        return this.setStepAndRedirect(parsedAppealId, data.value.step);
      } catch (err) {
        this.props.actions.replace('/');
      }
    }

    if (mode === CreateAppealMode.ReAppeal) {
      this.props.actions.replace(
        `${
          routes.CREATE_APPEAL_APPEAL_LETTER
        }?appealId=${this.props.selectedAppeal.get('id')}`
      );
    } else if (mode === CreateAppealMode.Resume) {
      if (this.props.isStandAlone) {
        this.setState({ isFetchingAppeal: false });
        return this.props.actions.push(routes.CREATE_APPEAL_SUBMIT_CONFIRM);
      }
      const step = this.props.selectedAppeal.get('step');
      const appealId = this.props.selectedAppeal.get('id');
      this.setStepAndRedirect(appealId, step);
    } else if (mode === CreateAppealMode.NewAppeal) {
      if (this.props.isStandAlone) {
        this.setState({ isFetchingAppeal: false });
        return this.props.actions.push(routes.CREATE_APPEAL_DOCUMENTS);
      }

      if (isEmpty(this.props.perFillData)) {
        return this.props.actions.push('/');
      }

      this.props.actions.push(routes.CREATE_APPEAL_PATIENT_INFO);
    }
    this.setState({ isFetchingAppeal: false });
  }

  setStepAndRedirect(appealId, step) {
    const pathname = this.props.location.pathname;

    const search = `?appealId=${appealId}`;
    if (pathname !== routes.CREATE_APPEAL && appealId) {
      this.setState({ isFetchingAppeal: false });
      return;
    }
    switch (step) {
      case 0:
        this.props.actions.push({
          pathname: routes.CREATE_APPEAL_PATIENT_INFO,
          search,
        });
        break;
      case 1:
        if (this.props.isMedicalRecordsSubmission) {
          this.props.actions.replace({
            pathname: routes.CREATE_APPEAL_APPEAL_LETTER,
            search,
          });
        } else {
          this.props.actions.replace({
            pathname: routes.CREATE_APPEAL_DENIAL_INFO,
            search,
          });
        }
        break;
      case 2:
        this.props.actions.replace({
          pathname: routes.CREATE_APPEAL_APPEAL_LETTER,
          search,
        });
        break;
      case 3:
        this.props.actions.replace({
          pathname: routes.CREATE_APPEAL_DOCUMENTS,
          search,
        });
        break;
      case 4:
        this.props.actions.replace({
          pathname: routes.CREATE_APPEAL_REVIEW,
          search,
        });
        break;
      case 5:
        this.props.actions.replace({
          pathname: routes.CREATE_APPEAL_SUBMIT_CONFIRM,
          search,
        });
        break;
      default:
        this.props.actions.replace(routes.CREATE_APPEAL);
    }
  }

  componentWillUnmount() {
    this.props.actions.clearCurrentAppealData();
    this.props.actions.clearCurrentShippingAddress();
  }

  renderSubHeader = () => {
    let subHeader = '';
    const step = this.props.currentAppealCreationStep;
    if (
      step > 0 &&
      this.props.currentAppealData &&
      !this.props.isStandAlone &&
      this.props.currentAppealData.patient
    ) {
      subHeader = (
        <CreateAppealSubHeader
          data={this.props.currentAppealData}
          onAppealDetailsClicked={this.onAppealDetailsClicked}
          agentsDropdownOptions={this.state.agentsDropdownOptions}
          onBillingAgentChange={this.onBillingAgentChange}
          displayAgentDropdown={true}
        />
      );
    }
    return subHeader;
  };

  onAppealDetailsClicked = () => {
    this.setState({
      isAppealDetailsOpen: true,
    });
  };

  onBillingAgentChange = (agentID) => {
    return this.props.actions
      .setBillingAgent(this.props.currentAppealData.id, agentID)
      .then((appealData) => {
        this.setState({ currentAppealData: appealData });
      });
  };

  getStepItems = () => {
    if (this.props.isStandAlone) {
      return [{ title: 'Documents' }, { title: 'Review and Submit' }];
    }

    return [
      { title: 'Patient Information' },
      { title: 'Claim Information' },
      { title: 'Letters and Forms' },
      { title: 'Documents' },
      { title: 'Review and Submit' },
      {
        title: !this.props.isMedicalRecordsSubmission
          ? 'Submit Appeal'
          : 'Submit Record',
        showSteps: false,
      },
      { title: 'Submitted', showSteps: false },
    ].filter(({ title }) => {
      if (
        this.props.isMedicalRecordsSubmission &&
        title === 'Claim Information'
      ) {
        return false;
      }
      return true;
    });
  };

  getActiveStep = () => {
    if (this.props.isStandAlone) {
      if (this.props.location.pathname.includes('createappeal/documents')) {
        return 0;
      }
      return 1;
    }

    if (
      this.props.isMedicalRecordsSubmission &&
      this.props.currentAppealCreationStep >= 2
    ) {
      return this.props.currentAppealCreationStep - 1;
    }

    return this.props.currentAppealCreationStep;
  };

  render() {
    if (this.state.isFetchingAppeal) {
      return (
        <div>
          <LoadingIndicator />
        </div>
      );
    }

    return (
      <div>
        <div className="create-appeal">
          <AppealHeader
            activeStep={this.getActiveStep()}
            stepItems={this.getStepItems()}
            isStandAlone={this.props.isStandAlone}
          />
          <div className="create-appeal__separator"></div>
          {this.renderSubHeader()}
          {this.props.children}
        </div>
        {this.renderAppealDetails()}
        <Footer />
      </div>
    );
  }

  renderAppealDetails() {
    if (
      !!this.props.currentAppealData &&
      this.props.currentAppealData.appealRound > 1
    ) {
      return (
        <SlidingAppealDetails
          isOpen={this.state.isAppealDetailsOpen}
          appealID={this.props.currentAppealData.historyAppeals[0].id}
          onDimRegionPressed={() => {
            this.setState({ isAppealDetailsOpen: false });
          }}
        />
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  const parsedQuery = parse(ownProps.location.search, {
    ignoreQueryPrefix: true,
  });

  const parsedAppealId = parsedQuery.appealId;
  const mode = parsedAppealId
    ? CreateAppealMode.Resume
    : getCurrentAppealMode(state);

  const selectedAppeal = getCurrentAppealData(state);
  const perFillData = getCurrentAppealPreFillData(state);

  if (mode === CreateAppealMode.ReAppeal) {
    const fullAppealFetched = getAppealFromState(
      state,
      selectedAppeal.get('id')
    );

    const appealProps = {
      currentAppealCreationStep: state
        .get('createAppealStore')
        .get('currentAppealCreationStep'),
      currentAppealData: fullAppealFetched
        ? fullAppealFetched.appeal
        : undefined,
      mode,
    };

    return appealProps;
  }

  const isStandAlone = isStandAloneSubmission(state);

  const fullAppealInfoWrapper = selectedAppeal
    ? getAppealFromState(state, selectedAppeal.get('id'))
    : undefined;
  return {
    currentAppealCreationStep: state
      .get('createAppealStore')
      .get('currentAppealCreationStep'),
    currentAppealData: fullAppealInfoWrapper
      ? fullAppealInfoWrapper.appeal
      : undefined,
    selectedAppeal,
    mode,
    perFillData,
    isMedicalRecordsSubmission:
      isCurrentAppealMedicalRecord(state) ||
      (perFillData && perFillData.get('is_medical_records_submission')),
    parsedAppealId,
    isStandAlone,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        replace,
        push,
        getAppealById,
        setAppealData,
        setBillingAgent,
        clearCurrentAppealData,
        clearCurrentShippingAddress,
      },
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateAppealComponent)
);
