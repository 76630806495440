import React from 'react';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { useFetchCodesDescriptions } from './hooks';

import { formatToMoney } from 'helpers/money';
import { DENIALS_UNDERPAYMENTS_TOTALS_BY_CATEGORY_COLOR } from './DonutChart';

const ReasonList = (props) => {
  const { codes } = props;
  const { data } = useFetchCodesDescriptions(
    codes.map((code) => code.reasonGroupCode)
  );
  const renderReasonList = () =>
    codes.map((reason, idx) => {
      const reasonCodeDetails =
        data.carcs &&
        data.carcs.filter((carc) =>
          reason.reasonGroupCode.includes(carc.code)
        )[0];
      const description = reasonCodeDetails && reasonCodeDetails.description;
      return (
        <div className="ap-card-list-v2" key={idx}>
          <div className="ap-card-list-v2t__details">
            <p
              data-tip={description || ''}
              data-for="reasonDescription"
              className={`ap-card-list-v2__title ap-card-list-v2__title--${
                DENIALS_UNDERPAYMENTS_TOTALS_BY_CATEGORY_COLOR[
                  camelCase(reason.category)
                ]
              }`}
            >
              {reason.reasonGroupCode}
            </p>
            <span className="ap-card-list-v2__sub">
              {formatToMoney(reason.totalAmount)}
            </span>
          </div>
        </div>
      );
    });

  return (
    <div>
      <h5 className="ap-card-list-v2__title--sm ">Top 5 Reason Codes</h5>
      {renderReasonList()}
      <ReactTooltip
        effect="solid"
        place="top"
        multiline={true}
        type="info"
        className="appealio-custom-tooltip--info"
        id="reasonDescription"
      />
    </div>
  );
};

ReasonList.propTypes = {
  codes: PropTypes.array.isRequired,
};

export default ReasonList;
