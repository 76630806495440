import React from 'react';

import Button from 'components/common/button';
import { AlertDialog } from 'components/common/popup';
import { BUTTON_TYPE } from 'components/common/button/Button';

const IdenticalAppealConfirmationPopup = (props) => {
  const { onClosePressed, onConfirmationPopup } = props;

  return (
    <AlertDialog
      onClosePressed={onClosePressed}
      className="import-rule-practice-agent-permission"
    >
      <p className="full-width">
        Are you sure you want to cancel this duplicate submission?
        <span className="d-block">Unsaved changes will be lost.</span>
      </p>
      <hr className="card-horizontal-line" />
      <div className="import-rule-practice-agent-permission__buttons-wrapper">
        <Button
          type="secondary"
          title="Yes"
          className="width-80 justify-content-center fw-normal ml-8"
          onClick={onClosePressed}
        />
        <Button
          title="Cancel"
          type={BUTTON_TYPE.LIGHT}
          className="width-80 justify-content-center fw-normal ml-8"
          onClick={() => onConfirmationPopup()}
        />
      </div>
    </AlertDialog>
  );
};

export default IdenticalAppealConfirmationPopup;
