export function arrayMoveMutable(array, fromIndex, toIndex) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function arrayMoveImmutable(array, fromIndex, toIndex) {
  const newArray = [...array];
  arrayMoveMutable(newArray, fromIndex, toIndex);
  return newArray;
}

/**
 * Returns the difference between two arrays.
 *
 * @param {Array} a - The first array.
 * @param {Array} b - The second array.
 * @returns {Array} - An array containing elements that are in array 'a' but not in array 'b'.
 */
export function arrayDifference(a, b) {
  return a.filter((item) => !b.includes(item));
}

/**
 * Removes any options from the provided array that have an empty `value` property.
 *
 * @param {Array} options - An array of options, each with a `value` property.
 * @returns {Array} - A new array containing only the options with a non-empty `value` property.
 */
export const removeEmptyOptions = (options) =>
  options.filter(({ value }) => value);
