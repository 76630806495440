import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { BiDownload } from 'react-icons/bi';

const DownloadDashboard = (props) => {
  const { size = 24, dataTip = 'Download Dashboard', onClick } = props;
  return (
    <div className="position-relative download-dashboard-wrapper">
      <BiDownload
        size={size}
        data-tip={dataTip}
        data-for="download-btn"
        className="download-analytics-image"
        onClick={onClick}
      />
      <ReactTooltip effect="solid" id="download-btn" multiline={true} />
    </div>
  );
};

DownloadDashboard.propTypes = {
  size: PropTypes.number,
  dataTip: PropTypes.string,
  onClick: PropTypes.func,
};

export default DownloadDashboard;
