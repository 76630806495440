import React from 'react';

import '../style.css';

const FaxSubmissionDetails = ({ data, tags }) => {
  return (
    <div>
      {data && data.fax ? (
        <div>
          <div className="submission-details-title">Fax</div>

          <div className="submission-details-header">
            <p>
              <label>Fax</label>:<span>{data.fax}</span>
            </p>
            {data.eta && (
              <div>
                <p>
                  <label>Estimated Submission Time</label>:
                  <span>{data.eta.replace("'", '')}(s)</span>
                </p>
              </div>
            )}
            <div className="log-props-status">
              <p>
                <label className="submission-label">Status Message:</label>
                <span>{data.deliverStatus}</span>
              </p>

              <p>
                <label>Delivery Status:</label>
                <p>
                  {data.deliverStatus === 'Sent' ? (
                    <span className="submission-details-status-success">
                      Delivered
                    </span>
                  ) : data.deliverStatus === 'Fax Delivered' ? (
                    <span className="submission-details-status-success">
                      Delivered
                    </span>
                  ) : data.deliverStatus === 'Fax queued for sending' ? (
                    <span className="submission-details-status-success">
                      In Transit
                    </span>
                  ) : data.deliverStatus === 'Queued for Submission' ? (
                    <span className="submission-details-status-success">
                      Queued
                    </span>
                  ) : data.deliverStatus === 'Under Review by Appealio Team' ? (
                    <span className="submission-details-status-success">
                      In Transit
                    </span>
                  ) : data.deliverStatus === 'Fax queued for resending' ? (
                    <span className="submission-details-status-success">
                      In Transit
                    </span>
                  ) : (
                    <span className="submission-details-status-failed">
                      Under Review
                    </span>
                  )}
                </p>
              </p>
              {tags && tags.length > 0 && (
                <p>
                  <label>Tags:</label>
                  {tags.join(', ')}
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <p className="error-message">
          Fax does not have any associated data yet.
        </p>
      )}
    </div>
  );
};

export default FaxSubmissionDetails;
