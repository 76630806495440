const ENTER_KEY = 13;
const UP_ARROW_KEY = 38;
const DOWN_ARROW_KEY = 40;
const FIRST_ALPHA_KEY = 65;
const LAST_ALPHA_KEY = 90;
const FIRST_NUMERIC_KEY = 48;
const LAST_NUMERIC_KEY = 57;

export {
  ENTER_KEY,
  UP_ARROW_KEY,
  DOWN_ARROW_KEY,
  FIRST_ALPHA_KEY,
  LAST_ALPHA_KEY,
  FIRST_NUMERIC_KEY,
  LAST_NUMERIC_KEY,
};
