import { interpolate } from 'helpers/string';

import {
  handleErrors,
  handleJSONParse,
  createGetRequest,
  BASE_API_URL,
} from './Config';

import { camelizeKeys } from '../helpers/object';

import * as endpoints from 'constants/endpoints';

/**
 * Fetches the portal info for the appeal with the given id.
 *
 * @param {number} appealId - The id of the appeal to fetch the portal info for.
 *
 * @returns {Promise} Resolves with the portal info for the appeal,
 *   or rejects with an error if the request fails.
 */
export const fetchPortalInfo = (appealId) => {
  const url = interpolate(endpoints.FETCH_PORTAL_FIELDS, {
    id: appealId,
  });

  const request = createGetRequest(url, {}, BASE_API_URL);

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};
