import classnames from 'classnames';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { TiArrowSortedUp } from 'react-icons/ti';
import { isEqual, get, isEmpty, orderBy } from 'lodash';

import { getFormattedDate } from 'helpers/dateFormats';
import Avatar from '../../../Shared/Avatar';
import LoadingBlockHelper from '../../../Shared/LoadingBlockHelper';

import DashboardAPI from '../../../../API/DashboardAPI';
import { toTitleCase } from '../../../../helpers/title_case';
import { createURLWithParams } from '../../../../API/Config';
import { handleError } from '../../../../helpers/errorHandler';
import EmptyWidgetPlaceholder from '../EmptyWidgetPlaceholder';

import { SUBMISSIONS } from 'constants/routes';
import { SUBMISSION_TYPE } from 'constants/appConstants';

const TABLE_COLUMNS = {
  agent: {
    key: 'agentName',
    label: 'Agent',
    className: 'agent-performance-table__header--agent-name',
  },
  appealSubmitted: {
    key: 'appealsSubmitted',
    label: 'Total Appeals Submissions',
  },
  recordsSubmitted: {
    key: 'recordsSubmitted',
    label: 'Total Records Submissions',
  },
  standAloneSubmitted: {
    key: 'standaloneSubmitted',
    label: 'Total Standalone Submissions',
  },
  totalSubmitted: {
    key: 'totalSubmitted',
    label: 'Total Submissions',
  },
  lastLogin: {
    key: 'lastLogin',
    label: 'Last Login',
  },
};

const SORT_DIRECTION = {
  ascending: 'asc',
  descending: 'desc',
};

class AgentPerformance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isFetching: false,
      sortBy: {
        column: TABLE_COLUMNS.totalSubmitted.key,
        direction: SORT_DIRECTION.descending,
      },
      total: {
        appealSubmitted: '',
        recordsSubmitted: '',
        standAloneSubmitted: '',
        totalSubmitted: '',
      },
    };
  }

  componentDidMount = () => {
    this.fetchAndSetData();
  };

  componentDidUpdate = (prevProps) => {
    if (!isEqual(prevProps.filters, this.props.filters)) {
      this.fetchAndSetData();
    }
  };

  fetchAndSetData = async () => {
    const { filters } = this.props;

    const filtersForFetch = {
      payer_id: get(filters, 'payer.value'),
      provider_id: get(filters, 'renderingProvider.value'),
      practice_identifier: get(filters, 'practice.value'),
      agent_id: get(filters, 'agent.value'),
      from_date: filters?.dateRange?.fromDate,
      to_date: filters?.dateRange?.toDate,
      team_id: get(filters, 'team.value'),
    };

    try {
      this.setState({ isFetching: true });

      const data = await DashboardAPI.fetchAgentLeaderBoard(filtersForFetch);
      const total = this.calculateTotals(data);

      this.setState({ data, total, isFetching: false });
    } catch (error) {
      this.setState({ isFetching: false });
      handleError(error);
    }
  };

  renderTableData = () => {
    if (this.props.isFetchingFilterOptions || this.state.isFetching) {
      return (
        <tr>
          <td colSpan="6">
            <LoadingBlockHelper
              className="agent-performance-table__loader"
              isLoading={true}
            />
          </td>
        </tr>
      );
    }

    if (isEmpty(this.state.data)) {
      return (
        <tr>
          <td colSpan="4">
            <EmptyWidgetPlaceholder className="mt-8" />
          </td>
        </tr>
      );
    }

    const redirectToDashboard = (data, redirectToRecord = false) => {
      const { filters } = this.props;
      const types = redirectToRecord
        ? SUBMISSION_TYPE.RECORD
        : SUBMISSION_TYPE.APPEAL;

      const appealsTableUrl = createURLWithParams(
        SUBMISSIONS,
        {
          types,
          set_filters: true,
          status: 'Submitted',
          agent_id: data.agentId,
          payer_id: get(filters, 'payer.value'),
          provider_id: get(filters, 'renderingProvider.value'),
          ordering: '-submitted_at',
          from_date: filters?.dateRange?.fromDate,
          to_date: filters?.dateRange?.toDate,
          practice_id: get(filters, 'practice.practiceId'),
        },
        true
      );

      this.props.actions.push(appealsTableUrl);
    };

    const redirectToDeliverTracker = (data, types) => {
      const { filters } = this.props;
      const deliverTrackerTableUrl = createURLWithParams(
        SUBMISSIONS,
        {
          agent_id: data.agentId,
          ...(get(filters, 'payer.value') && {
            payer_id: get(filters, 'payer.value'),
          }),
          ...(get(filters, 'renderingProvider.value') && {
            provider_id: get(filters, 'renderingProvider.value'),
          }),
          ...(get(filters, 'practice.practiceId') && {
            practice_id: get(filters, 'practice.practiceId'),
          }),
          ...(get(filters, 'dateRange.fromDate') && {
            from_date: get(filters, 'dateRange.fromDate'),
          }),
          ...(get(filters, 'dateRange.toDate') && {
            to_date: get(filters, 'dateRange.toDate'),
          }),
          types,
        },
        true
      );

      this.props.actions.push(deliverTrackerTableUrl);
    };

    const { sortBy } = this.state;

    const sortedData = orderBy(
      this.state.data,
      [sortBy.column, TABLE_COLUMNS.agent.key],
      [sortBy.direction]
    );

    // always put null data at the end of an array while sorting by last login
    const data =
      sortBy.column === TABLE_COLUMNS.lastLogin.key
        ? [...sortedData].sort(
            (a, b) => (a.lastLogin === null) - (b.lastLogin === null)
          )
        : sortedData;
    return data.map((data, index) => (
      <tr key={index}>
        <td
          className="cursor-pointer"
          onClick={() => redirectToDashboard(data)}
        >
          <div className="d-flex align-items-center">
            <Avatar className="mr-16" name={data.agentName} />
            <span className="fw-bold hover-underline agent-performance-table__agent-name">
              {toTitleCase(data.agentName)}
            </span>
          </div>
        </td>
        <td
          className="cursor-pointer hover-underline fw-bold link-color--dark-blue"
          onClick={() => redirectToDashboard(data)}
        >
          {data.appealsSubmitted}
        </td>
        <td
          className="cursor-pointer hover-underline fw-bold link-color--dark-blue"
          onClick={() => redirectToDashboard(data, true)}
        >
          {data.recordsSubmitted}
        </td>
        <td
          className="cursor-pointer hover-underline fw-bold link-color--dark-blue"
          onClick={() =>
            redirectToDeliverTracker(data, SUBMISSION_TYPE.STANDALONE)
          }
        >
          {data.standaloneSubmitted}
        </td>
        <td>{data.totalSubmitted}</td>
        <td>{data.lastLogin ? getFormattedDate(data.lastLogin) : 'N/A'}</td>
      </tr>
    ));
  };

  onSortTableClick = (value) => {
    const { sortBy } = this.state;

    if (sortBy.column === value.key) {
      const sortDirection =
        sortBy.direction === SORT_DIRECTION.ascending
          ? SORT_DIRECTION.descending
          : SORT_DIRECTION.ascending;

      return this.setState({
        sortBy: {
          ...sortBy,
          direction: sortDirection,
        },
      });
    }

    this.setState({
      sortBy: { column: value.key, direction: SORT_DIRECTION.descending },
    });
  };

  calculateTotals(data) {
    return data.reduce(
      (
        acc,
        {
          recordsSubmitted,
          appealsSubmitted,
          standaloneSubmitted,
          totalSubmitted,
        }
      ) => ({
        recordsSubmitted: acc.recordsSubmitted + recordsSubmitted,
        appealSubmitted: acc.appealSubmitted + appealsSubmitted,
        standAloneSubmitted: acc.standAloneSubmitted + standaloneSubmitted,
        totalSubmitted: acc.totalSubmitted + totalSubmitted,
      }),
      {
        appealSubmitted: 0,
        recordsSubmitted: 0,
        standAloneSubmitted: 0,
        totalSubmitted: 0,
      }
    );
  }

  render() {
    const { sortBy } = this.state;

    return (
      <div className="ap-dashboard-widget__container">
        <div className="ap-dashboard-widget__title">Agent Performance</div>
        <div className="agent-performance-table__container">
          <table className="agent-performance-table">
            <thead>
              <tr>
                {Object.values(TABLE_COLUMNS).map((value, key) => (
                  <th key={key} onClick={() => this.onSortTableClick(value)}>
                    <span
                      className={classnames(
                        'cursor-pointer agent-performance-table__header-content',
                        {
                          [value.className]: value.className,
                          'agent-performance-table__th--is-sorted-by':
                            sortBy.column === value.key,
                        }
                      )}
                    >
                      {value.label}
                      <TiArrowSortedUp
                        className={classnames(
                          'agent-performance-table__sort-icon',
                          {
                            'visibility-hidden': sortBy.column !== value.key,
                            'agent-performance-table__sort-icon--flip':
                              sortBy.direction !== SORT_DIRECTION.ascending,
                          }
                        )}
                      />
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="fw-500">{this.renderTableData()}</tbody>
            {this.state.data.length &&
            !(this.props.isFetchingFilterOptions || this.state.isFetching) ? (
              <tfoot>
                <tr>
                  <th>
                    <span
                      className={classnames('agent-performance-footer-content')}
                    >
                      Total
                    </span>
                  </th>
                  {Object.keys(this.state.total)
                    .sort()
                    .map((value, key) => (
                      <th key={key}>
                        <span
                          className={classnames(
                            'agent-performance-footer-content'
                          )}
                        >
                          {this.state.total[value]}
                        </span>
                      </th>
                    ))}
                  <th></th>
                </tr>
              </tfoot>
            ) : null}
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ push }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentPerformance);
