import { debounce } from 'lodash';
import { connect } from 'react-redux';
import React, { useState } from 'react';

import NoDataFound from './NoDataFound';
import * as toast from 'components/Shared/toast';
import DenialActionItem from './DenialActionItem';
import DenialActionForm from './DenialActionForm';
import Pagination from 'components/common/pagination';
import DenialActionHeader from './DenialActionHeader';
import SearchInput from 'components/common/input/SearchInput';
import RequestTemplate from './RequestTemplate/RequestTemplate';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';

import { SETTINGS_PAGE_LIMIT } from 'constants/appConstants';

import { isClientAdmin } from 'Auth/AuthUtils';
import { handleError } from 'helpers/errorHandler';

import { useFetchDenialActions } from '../hook';
import { getUserInfo } from 'redux/reducers/loginStore';
import SuccessPopup from './RequestTemplate/SuccessPopup';
import PreviewTemplate from './RequestTemplate/PreviewTemplate';

const DenialAction = ({ userInfo }) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [preview, setPreview] = useState({
    isOpen: false,
    name: '',
    template: null,
  });
  const [denialActionToUpdate, setDenialActionToUpdate] = useState(null);
  const [isDenialActionPopupOpen, setIsDenialActionPopupOpen] = useState(false);
  const [isRequestTemplatePopupOpen, setIsRequestTemplatePopupOpen] =
    useState(false);
  const [isRequestTemplateSuccessful, setIsRequestTemplateSuccessful] =
    useState(false);

  const {
    denialActions,
    totalDenialActions,
    loading,
    fetch: fetchDenialActions,
    toggleDenialActionConfig,
  } = useFetchDenialActions(page, search);

  const onClosePressed = () => {
    setIsDenialActionPopupOpen(false);
    setDenialActionToUpdate(null);
  };

  const onSubmitSuccess = () => {
    onClosePressed();
    toast.success({
      title: 'Success',
      message: 'Configuration updated successfully',
    });
    fetchDenialActions();
  };

  const handleSearch = debounce((event) => {
    setPage(1);
    setSearch(event.target?.value);
  }, 800);

  const clearSearch = () => setSearch('');
  const pageCount = Math.ceil(totalDenialActions / SETTINGS_PAGE_LIMIT);

  const handleAutoReminderToggle = async (action) => {
    try {
      const { autoReminderConfig } = action;
      const { daysUntilReminder, reminderNote } = autoReminderConfig || {};

      if (!daysUntilReminder || !reminderNote) {
        setIsDenialActionPopupOpen(true);
        setDenialActionToUpdate({
          ...action,
          autoReminderConfig: {
            isEnabled: true,
            daysUntilReminder: daysUntilReminder || 30,
            reminderNote: reminderNote || '',
          },
        });
        return;
      }

      toggleDenialActionConfig(action);
    } catch (error) {
      handleError(error);
    }
  };

  const handlePreview = (templatePreviewContent) => {
    const { name, template } = templatePreviewContent;
    setPreview({
      isOpen: true,
      template,
      name,
    });
  };

  return (
    <div className="settings-content-wrapper">
      <div className="d-flex align-items-center justify-content--space-between settings-content__top-action">
        <SearchInput
          placeholder="Search by Action"
          onSearch={handleSearch}
          onReset={clearSearch}
        />
        {isClientAdmin(userInfo) && (
          <button
            onClick={() => {
              setIsRequestTemplatePopupOpen(true);
            }}
            className="ap-button ap-button--secondary ml-auto settings-add-button"
            datacy="denial-actions-settings-add-button"
          >
            <span className="mr-4">+</span> Request PM Note Template
          </button>
        )}
      </div>

      <div className="row mt-12 ap-card-list-wraper">
        <div className="col-lg-12">
          {loading ? (
            <LoadingBlockHelper
              className="dashboard-bottom-loader"
              isLoading={loading}
            />
          ) : denialActions.length > 0 ? (
            <>
              <DenialActionHeader />
              {denialActions.map((action, idx) => (
                <DenialActionItem
                  key={idx}
                  action={action}
                  search={search}
                  isAdmin={isClientAdmin(userInfo)}
                  onAutoReminderToggle={handleAutoReminderToggle}
                  onEdit={() => {
                    setDenialActionToUpdate(action);
                    setIsDenialActionPopupOpen(true);
                  }}
                  handlePreview={handlePreview}
                />
              ))}
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>

      {isDenialActionPopupOpen && (
        <DenialActionForm
          actionId={denialActionToUpdate?.id}
          actionName={denialActionToUpdate?.description}
          initialValues={denialActionToUpdate?.autoReminderConfig}
          onClosePressed={onClosePressed}
          onSubmitSuccess={onSubmitSuccess}
        />
      )}
      {isRequestTemplatePopupOpen && (
        <RequestTemplate
          onClosePressed={() => setIsRequestTemplatePopupOpen(false)}
          onSuccess={() => {
            setIsRequestTemplatePopupOpen(false);
            setIsRequestTemplateSuccessful(true);
          }}
        />
      )}

      {isRequestTemplateSuccessful && (
        <SuccessPopup
          onClosePressed={() => setIsRequestTemplateSuccessful(false)}
        />
      )}

      {preview.isOpen && (
        <PreviewTemplate
          preview={preview}
          onClose={() =>
            setPreview({
              ...preview,
              isOpen: false,
            })
          }
        />
      )}

      {!loading && totalDenialActions && pageCount > 1 && (
        <Pagination
          pageCount={pageCount}
          activePage={page}
          onPageChange={setPage}
          className="d-flex justify-content-center"
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps)(DenialAction);
