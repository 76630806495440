import classnames from 'classnames';
import React, { useMemo, useState } from 'react';

import './style.css';

import RuleCell from './RuleCell';
import ActionBar from './ActionBar';
import RuleTable from './Table/RuleTable';

import { SORT_BY } from './constants';

import { generateRandomHex } from './ActionBar/utils';

import { useFetchRulesOverview } from './hooks';
import { OTHER_HEX, PRE_DEFINED_BACKGROUND_COLORS } from './ActionBar/constant';

const defaultSorting = {
  sortBy: [{ id: 'actionsCompleted', desc: true }],
};

const userDefaultSorting = {
  sortBy: [{ id: 'totalCompletedActions', desc: true }],
};

const RulesOverview = (props) => {
  const [sortBy, setSortBy] = useState(SORT_BY.USERS);
  const handleSortBy = (sortBy) => {
    setSortBy(sortBy);
  };

  const userPracticeId = props.userInfo.practice || '';
  const userSecretKey = props.userInfo.secretKey || '';

  const { data, loading } = useFetchRulesOverview({
    sortBy,
    userPracticeId,
    userSecretKey,
    ...props.filters,
  });

  const actionColorMap = useMemo(() => {
    if (sortBy !== SORT_BY.USERS) return {};

    const flatActionNames = data.flatMap((cur) =>
      (cur.actions || []).map((action) => action.name)
    );
    const uniqueActionNames = [...new Set(flatActionNames)];

    return uniqueActionNames.reduce((map, actionName) => {
      const referenceDataIdx = props?.systemOverviewActionsData?.findIndex(
        ({ name }) => name === actionName
      );
      let backgroundColor;
      if (actionName === 'Other') {
        backgroundColor = OTHER_HEX;
      } else {
        backgroundColor =
          referenceDataIdx !== -1 &&
          referenceDataIdx < PRE_DEFINED_BACKGROUND_COLORS.length
            ? PRE_DEFINED_BACKGROUND_COLORS[referenceDataIdx]
            : generateRandomHex();
      }

      map[actionName] = backgroundColor;

      return map;
    }, {});
  }, [sortBy, data, props.systemOverviewActionsData]);

  const COLUMNS = useMemo(
    () => ({
      [SORT_BY.RULES]: [
        {
          Header: 'Rule Name',
          accessor: 'ruleName',
          Cell: ({ value }) => <RuleCell value={value} />,
          width: 300,
        },
        {
          Header: 'Denials Flagged',
          accessor: 'totalClaimsFlagged',
          Cell: ({ value }) => {
            return <div>{value || 'N/A'}</div>;
          },
          width: 200,
        },
        {
          Header: 'In Active DQ',
          accessor: 'claimsInDqCount',
          Cell: ({ value }) => <span>{value || 'N/A'}</span>,
          width: 200,
        },
        {
          Header: 'In Archived DQ',
          accessor: 'claimsInArchivedDqCount',
          Cell: ({ value }) => <span>{value || 'N/A'}</span>,
        },
        {
          Header: '% of Denials Actioned',
          accessor: 'actionAssignedPercentage',
          Cell: ({ value }) => (
            <span>
              {value !== null && value !== undefined ? `${value}%` : 'N/A'}
            </span>
          ),
        },
        {
          Header: 'Actions Completed',
          accessor: 'actionsCompleted',
          Cell: ({ value }) => <span>{value || 'N/A'}</span>,
        },
      ],
      [SORT_BY.PRACTICES]: [
        {
          Header: 'Practice Name',
          accessor: 'practiceName',
          Cell: ({ value }) => <span className="">{value}</span>,
          width: 350,
        },
        {
          Header: 'Denials Flagged',
          accessor: 'totalClaimsFlagged',
          Cell: ({ value }) => {
            return <div>{value || 'N/A'}</div>;
          },
          width: 190,
        },
        {
          Header: 'In Active DQ',
          accessor: 'claimsInDqCount',
          Cell: ({ value }) => <span>{value || 'N/A'}</span>,
          width: 190,
        },
        {
          Header: 'In Archived DQ',
          accessor: 'claimsInArchivedDqCount',
          Cell: ({ value }) => <span>{value || 'N/A'}</span>,
        },
        {
          Header: '% of Denials Actioned',
          accessor: 'actionAssignedPercentage',
          Cell: ({ value }) => (
            <span>
              {value !== null && value !== undefined ? `${value}%` : 'N/A'}
            </span>
          ),
          width: 180,
        },
        {
          Header: 'Actions Completed',
          accessor: 'actionsCompleted',
          Cell: ({ value }) => <span>{value || 'N/A'}</span>,
        },
      ],
      [SORT_BY.USERS]: [
        {
          Header: 'Name',
          accessor: 'agentName',
          Cell: ({ value }) => <span className="">{value}</span>,
          width: 180,
        },
        {
          Header: 'Actions Completed',
          accessor: 'totalCompletedActions',
          Cell: ({ value }) => {
            return <div>{value || 'N/A'}</div>;
          },
          width: 150,
        },
        {
          Header: 'Action Breakdown',
          accessor: 'actions',
          Cell: ({ value, row }) => {
            const key = `-${row?.original?.agentName}`;
            const searchData = [
              ...(props.filters?.search || []),
              {
                key: 'action_completed_by',
                value: row?.original?.agentUsername,
              },
            ];

            return (
              <ActionBar
                value={value}
                barClassName="rounded-corner position-relative"
                actionBarKey={key}
                searchData={searchData}
                type="large"
                actionColorMap={actionColorMap}
              />
            );
          },
          className: 'd-flex',
          width: 880,
          disableSortBy: true,
        },
      ],
    }),
    [actionColorMap, props.filters?.search]
  );

  return (
    <div className="analytics-widget">
      <h2 className="analytics-widget__title d-inline-flex align-items-center">
        Performance Details
      </h2>
      <div className="analytics-widget__filter-tabs-wrapper">
        <p className="analytics-widget__filter-tabs-title">By:</p>
        <ul className="analytics-widget__filter-tabs">
          <li
            className={classnames('analytics-widget__filter-tabs-item', {
              'analytics-widget__filter-tabs-item--active':
                sortBy === SORT_BY.USERS,
            })}
            onClick={() => handleSortBy(SORT_BY.USERS)}
          >
            Users
          </li>
          <li
            className={classnames('analytics-widget__filter-tabs-item', {
              'analytics-widget__filter-tabs-item--active':
                sortBy === SORT_BY.RULES,
            })}
            onClick={() => handleSortBy(SORT_BY.RULES)}
          >
            Rules
          </li>
          <li
            className={classnames('analytics-widget__filter-tabs-item', {
              'analytics-widget__filter-tabs-item--active':
                sortBy === SORT_BY.PRACTICES,
            })}
            onClick={() => handleSortBy(SORT_BY.PRACTICES)}
          >
            Practices
          </li>
        </ul>
      </div>
      {sortBy === SORT_BY.USERS ? (
        <p>
          <i>
            *Note: User Action data is being tracked from Aug 16, 2023 onward
            only
          </i>
        </p>
      ) : null}
      <div className="rule-table-container">
        <RuleTable
          columns={COLUMNS[sortBy]}
          data={data}
          isLoading={loading}
          defaultSorting={
            sortBy === SORT_BY.USERS ? userDefaultSorting : defaultSorting
          }
        />
      </div>
    </div>
  );
};

RulesOverview.propTypes = {};

export default RulesOverview;
