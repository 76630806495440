import { createGetRequest, handleErrors, handleJSONParse } from './Config';
import moment from 'moment';
import _ from 'lodash';

const _FILTER_STATUS = ['PDF rendered', 'Thumbnails rendered'];

export default class AppealSubmitLogsAPI {
  static parseSubmitLogsData = (res) => {
    const relatedSubmit = {
      fax: [],
      mail: [],
    };
    res.forEach((log) => {
      if (
        log.attributes.submit_type === 'mail' &&
        log.relationships.appeal_submit_mail.data
      ) {
        const mailId = log.relationships.appeal_submit_mail.data.id;
        if (!relatedSubmit.mail[mailId]) {
          relatedSubmit.mail[mailId] =
            AppealSubmitLogsAPI.getSubmitForAppealSubmitId(mailId, 'mail');
        }
      } else if (
        log.attributes.submit_type === 'fax' &&
        log.relationships.appeal_submit_fax.data
      ) {
        const faxId = log.relationships.appeal_submit_fax.data.id;
        if (!relatedSubmit.fax[faxId]) {
          relatedSubmit.fax[faxId] =
            AppealSubmitLogsAPI.getSubmitForAppealSubmitId(faxId, 'fax');
        }
      }
    });
    return Promise.all([
      Promise.all(relatedSubmit.fax),
      Promise.all(relatedSubmit.mail),
    ]).then(([faxes, mails]) => {
      let logs = res.map((log, i) => {
        if (
          log.attributes.submit_type === 'mail' &&
          log.relationships.appeal_submit_mail.data
        ) {
          log.submit_mail = mails[log.relationships.appeal_submit_mail.data.id];
        } else if (
          log.attributes.submit_type === 'fax' &&
          log.relationships.appeal_submit_fax.data
        ) {
          log.submit_fax = faxes[log.relationships.appeal_submit_fax.data.id];
        }
        return AppealSubmitLogsAPI.parseLog(log);
      });

      logs = _.filter(
        logs,
        (log) => !_FILTER_STATUS.includes(log.deliver_status)
      );

      const sortedLogs = logs.sort((x, y) => {
        return moment(y.rawtimestamp) - moment(x.rawtimestamp);
      });

      return sortedLogs;
    });
  };

  static parseLog = (log) => {
    const time = log.attributes.datetime;
    const parsedLog = {
      id: log.id,
      submit_type: log.attributes.submit_type,
      submit_fax: log.submit_fax ? log.submit_fax : null,
      submit_mail: log.submit_mail ? log.submit_mail : null,
      deliver_status: log.attributes.deliver_status,
      success: log.attributes.success,
      datetime: moment(log.attributes.datetime).format('L H:mm:SS a'),
      rawtimestamp: time,
      time: moment(time).format('h:mm a'),
      date: moment(time).format('L'),
    };
    return parsedLog;
  };

  static getSubmitForAppealSubmitId = (id, type = 'mail') => {
    const endpoint = `appeal_submit_${type}/${id}`;
    const request = createGetRequest(endpoint);

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then(AppealSubmitLogsAPI.parseSubmitData);
  };

  static parseSubmitData = (s) => {
    const submit = s.data;
    const time = submit.attributes.created_at;
    const parsedSubmit = _.extend(submit.attributes, {
      id: submit.id,
      external_id: submit.attributes.external_id,
      external_link: submit.attributes.external_link,
      datetime: moment(submit.attributes.datetime).format('L H:mm a'),
      rawtimestamp: time,
      time: moment(time).format('h:mm a'),
      date: moment(time).format('L'),
    });
    return parsedSubmit;
  };
}
