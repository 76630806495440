import React from 'react';

import '../style.css';

const DirectSubmissionDetails = ({ data, tags }) => {
  const header = data.label ? 'Direct Submission' : 'Payer Portal';
  return (
    <div>
      <div className="submission-details-title">{header}</div>

      <div className="submission-details-header">
        <p>
          <label>{data.label}:</label>
          <span>{data.value}</span>
        </p>
        <p>
          <label>Status:</label>
          <span>{data.subStatus}</span>
        </p>
        {data.eta && (
          <p>
            <label>Estimated Submission Time</label>:
            <span>
              {' '}
              <span>{data.eta.replace("'", '')}(s)</span>
            </span>
          </p>
        )}
        <p>
          <label>Last Status Check:</label>
          <span>{data.lastStatusCheck}</span>
        </p>
        <p>
          <label>Last Status Change:</label>
          <span>{data.lastStatusChange}</span>
        </p>
        <p>
          <label>External Appeal ID:</label>
          <span>{data?.externalAppealId || 'N/A'}</span>
        </p>
      </div>
      {tags?.length > 0 && (
        <p>
          <label>Tags:</label>
          <span> {tags.join(', ')}</span>
        </p>
      )}
    </div>
  );
};

export default DirectSubmissionDetails;
