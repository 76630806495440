import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { replace } from 'connected-react-router';
import { xorWith, snakeCase, uniqWith, isEqual, get, orderBy } from 'lodash';

import * as toast from '../../../Shared/toast';
import DeniedClaimsTable from './DeniedClaimsTable';
import DeniedClaimsFilter from './DeniedClaimsFilter';
import PageNavigator from '../../../Shared/PageNavigator';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import CodesInfoPopup from 'components/DenialQueue/CodesInfoPopup';
import RightSlideOut from 'components/Shared/RightSlideOut';
import DenialClaimInformationPopup from 'components/DenialQueue/DenialTable/DenialClaimInformationPopup';

import DenialsAPI from '../../../../API/DenialsAPI';
import { handleError } from '../../../../helpers/errorHandler';
import { startSentryTransaction } from 'helpers/performanceLogging';

import {
  DENIALS_FLYOVER_TITLE,
  INTEGRATION_TYPE,
} from 'constants/appConstants';
import { CLAIMS_FLYOVER_LOAD_EVENT_NAME } from 'constants/performanceLogging';
import {
  SEARCH_BY_PATIENT_DROPDOWN_OPTIONS,
  SEARCH_GENERIC_KEY,
} from '../../../../constants/options';

const PAGE_LIMIT = 10;

class DeniedClaimsTableContainer extends Component {
  static propTypes = {
    userPracticeId: PropTypes.string.isRequired,
    userSecretKey: PropTypes.string.isRequired,
    integrationType: PropTypes.oneOf(Object.values(INTEGRATION_TYPE)),
    onMoveToDenialsQueueSuccess: PropTypes.func.isRequired,
    deniedClaimsFilterOptions: PropTypes.object.isRequired,
    userPracticesNameMap: PropTypes.object,
    userInfo: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.mounted = false;
    this.fetchDenialsOnMountTimeout = null;

    this.state = {
      activePage: 1,
      deniedClaims: [],
      filterOptions: {},
      totalDeniedClaimsCount: 0,
      isFetchingDeniedClaims: true,
      isMovingDenialsToQueue: false,
      showDescriptionsModal: false,
      showingClaimDescriptions: {},
      selectedClaimInfos: [],
      sortBy:
        props.integrationType === INTEGRATION_TYPE.APM
          ? {
              id: 'billedAmount',
              desc: true,
            }
          : {
              id: 'ediPaymentDate',
              desc: true,
            },
      filters: this.getDefaultFilters(this.props.searchParam),
      codesInfoPopup: {
        isOpen: false,
        codes: {
          remarkCodes: [],
          reasonCodes: [],
        },
        claimInfo: {
          claimId: null,
        },
      },
      denialInformationPopup: {
        isOpen: false,
        claimControlNumber: null,
        claimId: null,
      },
    };
  }

  setDefaultCodeInfoPopup = () => {
    this.setState({
      codesInfoPopup: {
        isOpen: false,
        codes: {
          remarkCodes: [],
          reasonCodes: [],
        },
        claimInfo: {
          claimId: null,
        },
      },
    });
  };

  openCodesInfoPopup = ({
    remarkCodes = [],
    reasonCodes = [],
    claimId = '',
  }) => {
    this.setState({
      codesInfoPopup: {
        isOpen: true,
        codes: {
          remarkCodes,
          reasonCodes,
        },
        claimInfo: {
          claimId,
        },
      },
    });
  };

  setDeniedClaims = (deniedClaims, cb) =>
    this.mounted && this.setState({ deniedClaims }, cb);

  setIsFetchingDeniedClaims = (isFetchingDeniedClaims, cb) =>
    this.mounted && this.setState({ isFetchingDeniedClaims }, cb);

  setActivePage = (activePage, cb) =>
    this.mounted && this.setState({ activePage }, cb);

  setSelectedClaimInfos = (selectedClaimInfos, cb) =>
    this.mounted && this.setState({ selectedClaimInfos }, cb);

  setTotalDeniedClaimsCount = (totalDeniedClaimsCount, cb) =>
    this.mounted && this.setState({ totalDeniedClaimsCount });

  setIsMovingDenialsToQueue = (isMovingDenialsToQueue, cb) =>
    this.mounted && this.setState({ isMovingDenialsToQueue });

  setFilters = (filters, cb) => this.mounted && this.setState({ filters }, cb);

  setDenialInformationFlyover = (denialInformationPopup, cb) =>
    this.mounted &&
    this.setState(
      {
        denialInformationPopup,
      },
      cb
    );

  componentDidMount = () => {
    this.mounted = true;

    this.setState({
      filterOptions: {
        ...this.props.deniedClaimsFilterOptions,
      },
    });

    this.fetchDenialsMetaCountsOnMountTimeout = setTimeout(
      this.fetchAndSetDenialsMetaCounts,
      400
    );

    this.fetchDenialsOnMountTimeout = setTimeout(
      this.fetchAndSetDeniedClaims,
      400
    );
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.searchParam &&
      nextProps.searchParam !== this.props.searchParam
    ) {
      this.setState(
        {
          filters: this.getDefaultFilters(nextProps.searchParam),
        },
        () => {
          this.setState({
            deniedClaims: [],
            totalDeniedClaimsCount: 0,
          });
          this.fetchAndSetDeniedClaims();
        }
      );
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
    clearTimeout(this.fetchDenialsOnMountTimeout);
    clearTimeout(this.fetchDenialsMetaCountsOnMountTimeout);
  };

  handleApiErrors = async (error) => {
    handleError(error);

    const errorResponse = error.response;

    if (errorResponse.status === 400) {
      this.props.actions.replace('/');
    }
  };

  openClaimInformationFlyover = (rowData) => {
    const denialInformationPopup = {
      isOpen: true,
      claimId: rowData.claimNumber || null,
      claimControlNumber: rowData.claimControlNumber || null,
    };

    this.setDenialInformationFlyover(denialInformationPopup);
  };

  /**
   * Closes denialInformation popover
   */

  closeDenialInformationFlyover = () => {
    const denialInformationPopup = {
      isOpen: false,
    };
    this.setDenialInformationFlyover(denialInformationPopup);
  };

  /**
   * Returns Default filter.
   * @return {Object}
   */
  getDefaultFilters = (searchText = '') => {
    return {
      selectedGeneralSearchType: SEARCH_BY_PATIENT_DROPDOWN_OPTIONS[0].value,
      genericSearchType: SEARCH_GENERIC_KEY,
      generalSearchInputText: searchText,

      selectedCptCode: null,
      selectedCptModifierCode: null,
      selectedPayer: null,
      selectedProvider: null,
      selectedPractice: null,
      selectedReasonCode: null,
      selectedRemarkCode: null,
    };
  };

  /**
   * Extracts sort param for the request.
   * @returns {Object}
   */
  extractSortParamForRequest = () => {
    const { sortBy } = this.state;

    if (!sortBy.id) {
      return {};
    }

    return {
      sort: snakeCase(sortBy.id),
      order: sortBy.desc ? 'desc' : 'asc',
    };
  };

  /**
   * Extracts search for API request body.
   * @returns {Array}
   */
  extractSearchForRequest = () => {
    const {
      genericSearchType,
      generalSearchInputText,
      selectedPayer,
      selectedReasonCode,
      selectedProvider,
      selectedCptCode,
      selectedPractice,
      selectedRemarkCode,
      selectedCptModifierCode,
    } = this.state.filters;

    const search = [];

    if (generalSearchInputText) {
      search.push({
        key: genericSearchType,
        value: generalSearchInputText,
      });
    }

    if (selectedPayer) {
      search.push({
        key: 'payer',
        value: selectedPayer.value,
      });
    }

    if (selectedReasonCode) {
      search.push({
        key: 'reason_code',
        value: selectedReasonCode.value,
      });
    }

    if (selectedCptCode) {
      search.push({
        key: 'cpt_code',
        value: selectedCptCode.value,
      });
    }

    if (selectedProvider) {
      search.push({
        key: 'provider',
        value: selectedProvider.value,
      });
    }

    if (selectedPractice) {
      search.push({
        key: 'practice_identifier',
        value: selectedPractice.value,
      });
    }

    if (selectedRemarkCode) {
      search.push({
        key: 'remark_codes',
        value: selectedRemarkCode.value,
      });
    }

    if (selectedCptModifierCode) {
      search.push({
        key: 'cpt_modifiers_code',
        value: selectedCptModifierCode.value,
      });
    }

    return search;
  };

  attachPracticeNameToDeniedClaim = (deniedClaim) => {
    const practiceIdentifier = deniedClaim.practiceIdentifier;

    const practiceName = practiceIdentifier
      ? this.props.userPracticesNameMap[practiceIdentifier] || ''
      : '';

    return {
      ...deniedClaim,
      practiceName,
    };
  };

  fetchAndSetDenialsMetaCounts = async () => {
    try {
      const denialsMetaCounts = await DenialsAPI.fetchDenialsCounts();

      const filterOptionsWithCount = this.addCountToFilterOptions(
        this.props.deniedClaimsFilterOptions,
        denialsMetaCounts
      );

      this.setState({
        filterOptions: filterOptionsWithCount,
      });
    } catch (error) {
      handleError(error);
    }
  };

  /**
   * Fetches and sets denied claims.
   */
  fetchAndSetDeniedClaims = async () => {
    const { activePage, filters } = this.state;
    this.setIsFetchingDeniedClaims(true);
    const sentryTransaction = startSentryTransaction(
      CLAIMS_FLYOVER_LOAD_EVENT_NAME,
      this.props.userInfo
    );
    try {
      const search = this.extractSearchForRequest();
      const offset = (activePage - 1) * PAGE_LIMIT;
      const sortParams = this.extractSortParamForRequest();
      const practiceIdentifier = get(filters, 'selectedPractice.value');

      const { rows: deniedClaims, total } = await DenialsAPI.fetchDeniedClaims({
        search,
        sortParams,
        offset,
        practiceId: this.props.userPracticeId,
        secretKey: this.props.userSecretKey,
        practiceIdentifier,
      });

      const deniedClaimsWithPracticeName = deniedClaims.map(
        this.attachPracticeNameToDeniedClaim
      );

      this.setState({
        deniedClaims: deniedClaimsWithPracticeName,
        totalDeniedClaimsCount: total,
        isFetchingDeniedClaims: false,
      });
    } catch (error) {
      this.handleApiErrors(error);
      this.setIsFetchingDeniedClaims(false);
    } finally {
      sentryTransaction?.finish();
    }
  };

  addCountToFilterOptions = (filterOptions, meta) => {
    const {
      cptCodeCounts = [],
      cptModifiersCodeCounts = [],
      reasonCodeCounts = [],
      remarkCodeCounts = [],
      payerCounts = [],
      providerCounts = [],
      practiceIdentifierCounts = [],
    } = meta;

    const extractLabelSuffix = (countValue) => {
      if (!countValue) return '';

      return `(${countValue})`;
    };

    const extractOptionWithCount = (option, countMap) => ({
      ...option,
      label: `${option.label} ${extractLabelSuffix(countMap[option.value])}`,
      count: countMap[option.value],
    });

    const extractObjectMap = (array, objectKey) =>
      array.reduce((acc, cur) => {
        const key = cur[objectKey];

        return {
          ...acc,
          [key]: cur.count,
        };
      }, {});

    const cptCountMap = extractObjectMap(cptCodeCounts, 'cptCode');
    const cptCodeWithCount = filterOptions.cptCodes?.data?.map((option) =>
      extractOptionWithCount(option, cptCountMap)
    );

    const cptModifiersCountMap = extractObjectMap(
      cptModifiersCodeCounts,
      'cptModifiersCode'
    );
    const cptModifiersCodeWithCount =
      filterOptions.procedureModifiers?.data?.map((option) =>
        extractOptionWithCount(option, cptModifiersCountMap)
      );

    const reasonCodeCountMap = extractObjectMap(reasonCodeCounts, 'reasonCode');
    const reasonCodesWithCount = filterOptions.reasonCodes?.data?.map(
      (option) => extractOptionWithCount(option, reasonCodeCountMap)
    );

    const remarkCodeCountMap = extractObjectMap(remarkCodeCounts, 'remarkCode');
    const remarkCodesWithCount = filterOptions.remarkCodes?.data?.map(
      (option) => extractOptionWithCount(option, remarkCodeCountMap)
    );

    const payerCountMap = extractObjectMap(payerCounts, 'payerMasterId');
    const payerWithCount = filterOptions.payers?.data?.map((option) =>
      extractOptionWithCount(option, payerCountMap)
    );

    const providerCountMap = extractObjectMap(
      providerCounts,
      'providerMasterId'
    );
    const providersWithCount = filterOptions.providers?.data?.map((option) =>
      extractOptionWithCount(option, providerCountMap)
    );

    const practiceIdentifierMap = extractObjectMap(
      practiceIdentifierCounts,
      'practiceIdentifier'
    );
    const practicesWithCount = filterOptions.practices?.data?.map((option) =>
      extractOptionWithCount(option, practiceIdentifierMap)
    );

    const sortOptions = (options) =>
      orderBy(
        options,
        [
          (option) => {
            if (option.label.trim() === 'All') {
              return undefined;
            }

            return option.count || 0;
          },
        ],
        ['desc']
      );

    return {
      ...filterOptions,
      cptCodes: { data: sortOptions(cptCodeWithCount), isFetching: false },
      reasonCodes: {
        data: sortOptions(reasonCodesWithCount),
        isFetching: false,
      },
      remarkCodes: {
        data: sortOptions(remarkCodesWithCount),
        isFetching: false,
      },
      payers: { data: sortOptions(payerWithCount), isFetching: false },
      providers: { data: sortOptions(providersWithCount), isFetching: false },
      practices: { data: sortOptions(practicesWithCount), isFetching: false },
      procedureModifiers: {
        data: sortOptions(cptModifiersCodeWithCount),
        isFetching: false,
      },
    };
  };

  /**
   * Resets denials on the state and fetches denied claims.
   */
  resetAndFetchDeniedClaims = () => {
    this.setState(
      {
        activePage: 1,
        deniedClaims: [],
        totalDeniedClaimsCount: 0,
        selectedClaimInfos: [],
      },
      this.fetchAndSetDeniedClaims
    );
  };

  /**
   * Toggles selected claim info from the state.
   *
   * @param {String} claimNumber
   * @param {String} claimControlNumber
   */
  handleToggleClaimSelection = (claimNumber, claimControlNumber) => {
    const newSelectedClaimInfos = xorWith(
      this.state.selectedClaimInfos,
      [
        {
          claimNumber,
          claimControlNumber,
        },
      ],
      isEqual
    );

    this.setSelectedClaimInfos(newSelectedClaimInfos);
  };

  /**
   * Handles sort table click.
   *
   * @param {String} column
   */
  onSortTableClick = (newSortBy) => {
    const { sortBy: prevSortBy, selectedClaimInfos } = this.state;

    if (selectedClaimInfos.length > 0) {
      toast.error({
        title: 'Sorting disabled',
        message: 'Clear claim selections to sort the table.',
      });
      return;
    }

    if (isEqual(prevSortBy, newSortBy)) {
      return;
    }

    this.setState({ sortBy: newSortBy }, this.resetAndFetchDeniedClaims);
  };

  /**
   * Handles move to denials btn click.
   */
  onMoveToDenialsClick = async () => {
    const { selectedClaimInfos } = this.state;
    const { onMoveToDenialsQueueSuccess } = this.props;

    try {
      this.setIsMovingDenialsToQueue(true);

      const payload = {
        claims: selectedClaimInfos,
      };

      await DenialsAPI.addClaimsToDenialQueue(payload);

      const noOfClaims = selectedClaimInfos.length;

      const message =
        noOfClaims > 1
          ? `${noOfClaims} claims added to Denial Queue`
          : `${noOfClaims} claim added to Denial Queue`;

      toast.success({
        title: 'Success',
        message,
      });

      this.setIsMovingDenialsToQueue(false);

      setTimeout(onMoveToDenialsQueueSuccess, 100);
    } catch (error) {
      this.handleApiErrors(error);
      this.setIsMovingDenialsToQueue(false);
    }
  };

  /**
   * Handles clear filter click.
   */
  handleClearFilter = () => {
    this.setState(
      {
        filters: { ...this.getDefaultFilters() },
      },
      this.resetAndFetchDeniedClaims
    );
  };

  /**
   * Handles filter change.
   *
   * @param {Object} filters
   */
  handleFilterChange = (filters) => {
    this.setFilters(filters, this.resetAndFetchDeniedClaims);
  };

  popupDescriptions = (event, claim) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({
      showDescriptionsModal: !this.state.showDescriptionsModal,
      showingClaimDescriptions: !this.state.showDescriptionsModal
        ? { ...claim }
        : {},
    });
  };

  /**
   * Returns count message
   * @returns {String}
   */
  renderCountMessage = () => {
    const { totalDeniedClaimsCount } = this.state;

    if (totalDeniedClaimsCount === 0) {
      return '';
    }

    if (totalDeniedClaimsCount > 1) {
      return `${totalDeniedClaimsCount} Search Results`;
    }

    return `${totalDeniedClaimsCount} Search Result`;
  };

  onPageChange = (newPage) => {
    this.setState(
      {
        deniedClaims: [],
        activePage: newPage,
      },
      this.fetchAndSetDeniedClaims
    );
  };

  renderPageNavigator = () => {
    const {
      activePage,
      totalDeniedClaimsCount,
      selectedClaimInfos,
      isFetchingDeniedClaims,
    } = this.state;

    const isDeniedClaimsEmpty = totalDeniedClaimsCount === 0;
    const isClaimIdsSelected = selectedClaimInfos.length > 0;

    const isPaginationDisabled = isClaimIdsSelected || isFetchingDeniedClaims;

    const totalPages = Math.ceil(totalDeniedClaimsCount / PAGE_LIMIT);

    if (isDeniedClaimsEmpty || totalPages === 1) {
      return '';
    }

    const onDisableButtonClick = () => {
      if (this.state.isFetchingDeniedClaims) {
        return '';
      }

      toast.error({
        title: 'Pagination disabled',
        message: 'Clear claim selections to see more data.',
      });
    };

    return (
      <PageNavigator
        onPageChange={this.onPageChange}
        activePage={activePage}
        totalPages={totalPages}
        isDisabled={isPaginationDisabled}
        onDisableBtnClick={onDisableButtonClick}
        datacy="denied-claims-table-container-PageNavigator"
      />
    );
  };

  onSelectAllDeniedClaimsClicked = () => {
    const { deniedClaims, selectedClaimInfos } = this.state;

    const isSomeClaimsSelected = selectedClaimInfos.length > 0;
    const uniqueClaimInfos = this.extractUniqueClaimInfos(deniedClaims);

    const selectAllClaims =
      !this.isAllClaimsSelected() && !isSomeClaimsSelected;

    const newSelectedClaimInfos = selectAllClaims ? uniqueClaimInfos : [];

    this.setSelectedClaimInfos(newSelectedClaimInfos);
  };

  mapToSelectedClaimInfo = (claim) => ({
    claimNumber: claim.claimNumber,
    claimControlNumber: claim.claimControlNumber || '',
  });

  extractUniqueClaimInfos = (deniedClaims) =>
    uniqWith(deniedClaims.map(this.mapToSelectedClaimInfo), isEqual);

  isAllClaimsSelected = () => {
    const { deniedClaims, selectedClaimInfos } = this.state;

    const uniqueClaimInfos = this.extractUniqueClaimInfos(deniedClaims);

    return (
      selectedClaimInfos.length > 0 &&
      uniqueClaimInfos.length === selectedClaimInfos.length
    );
  };

  extractFilterFilterOptions = (deniedClaimsFilterOptions, appliedFilters) => {
    if (!appliedFilters.selectedPractice) {
      return deniedClaimsFilterOptions;
    }

    const providers = deniedClaimsFilterOptions.providers?.data?.filter(
      ({ practiceIdentifier }) =>
        !practiceIdentifier ||
        practiceIdentifier === appliedFilters.selectedPractice.value
    );

    return {
      ...deniedClaimsFilterOptions,
      providers: { data: providers },
    };
  };

  render() {
    const {
      sortBy,
      filters,
      deniedClaims,
      selectedClaimInfos,
      isFetchingDeniedClaims,
      filterOptions,
    } = this.state;

    const extractedDeniedClaimsFilterOptions = this.extractFilterFilterOptions(
      filterOptions,
      filters
    );

    const isDefaultFiltersSelected = isEqual(filters, this.getDefaultFilters());
    const isAllClaimsSelected = this.isAllClaimsSelected();

    return (
      <div
        className="search-denied-claims-container"
        datacy="denied-claims-table-container"
      >
        <div className="denied-claims-title">
          Search {DENIALS_FLYOVER_TITLE}
        </div>
        <div>
          <div>
            <DeniedClaimsFilter
              filters={filters}
              defaultFilters={this.getDefaultFilters()}
              handleFilterChange={this.handleFilterChange}
              handleClearFilter={this.handleClearFilter}
              deniedClaimsFilterOptions={extractedDeniedClaimsFilterOptions}
              isDefaultFiltersSelected={isDefaultFiltersSelected}
              integrationType={this.props.integrationType}
              datacy="denied-claims-table-container-DeniedClaimsFilter"
            />
          </div>

          <div className="mb-44">
            <div className="denied-claims-count__container">
              <div className="denied-claims-count">
                {this.renderCountMessage()}
              </div>
              {this.renderPageNavigator()}
            </div>
            <div className="denied-claims-table-container">
              <DeniedClaimsTable
                deniedClaims={deniedClaims}
                sortBy={sortBy}
                isAllClaimsSelected={isAllClaimsSelected}
                onSortTableClick={this.onSortTableClick}
                handleToggleClaimSelection={this.handleToggleClaimSelection}
                isFetchingDeniedClaims={isFetchingDeniedClaims}
                selectedClaimInfos={selectedClaimInfos}
                onSelectAllDeniedClaimsClicked={
                  this.onSelectAllDeniedClaimsClicked
                }
                onRowClick={this.openClaimInformationFlyover}
                integrationType={this.props.integrationType}
                popupDescriptions={this.popupDescriptions}
                userInfo={this.props.userInfo}
                onCodesCellClick={this.openCodesInfoPopup}
                datacy="denied-claims-table-container-DeniedClaimsTable"
              />
            </div>
          </div>
          {selectedClaimInfos.length > 0 && (
            <div>
              {this.state.isMovingDenialsToQueue ? (
                <div className="denied-claims-action-container">
                  <button className="aplo-button aplo-button--blue move-denials-btn">
                    <LoadingIndicator
                      showing={true}
                      datacy="move-denials-button"
                    />
                  </button>
                </div>
              ) : (
                <div className="denied-claims-action-container">
                  <button
                    className="aplo-button aplo-button--blue move-denials-btn"
                    disabled={this.state.selectedClaimInfos.length === 0}
                    onClick={this.onMoveToDenialsClick}
                    datacy="add-to-denials-queue-button"
                  >
                    Add to Denials Queue
                  </button>
                </div>
              )}
            </div>
          )}
        </div>

        {this.state.codesInfoPopup.isOpen && (
          <CodesInfoPopup
            onClosePressed={this.setDefaultCodeInfoPopup}
            claimId={this.state.codesInfoPopup.claimInfo.claimId}
            remarkCodes={this.state.codesInfoPopup.codes.remarkCodes}
            reasonCodes={this.state.codesInfoPopup.codes.reasonCodes}
            datacy="denied-claims-table-container-CodesInfoPopup"
          />
        )}
        {this.state.denialInformationPopup.isOpen && (
          <RightSlideOut
            isOpen={this.state.denialInformationPopup.isOpen}
            onDimRegionPressed={this.closeDenialInformationFlyover}
            datacy="denial-queue-container-popup-open-RightSlideOut"
          >
            <DenialClaimInformationPopup
              showCloseButton
              closePopup={this.closeDenialInformationFlyover}
              denialClaimInformation={this.state.denialInformationPopup}
              datacy="denial-queue-container-DenialClaimInformationPopup"
            />
          </RightSlideOut>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ replace }, dispatch),
});

export default connect(
  () => ({}),
  mapDispatchToProps
)(DeniedClaimsTableContainer);
