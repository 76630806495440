import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import React, { useRef, useState, useEffect } from 'react';
import { getFormattedDate } from 'helpers/dateFormats';

import { ALL_AGENTS_OPTION_VALUE } from 'constants/appConstants';

import { fetchRuleDetails } from 'API/DenialsQueueAnalyticsAPI';
import {
  DROPDOWN_OPTIONS_STATE_KEYS,
  getDropdownOptions,
} from 'redux/reducers/dropdownOptionsStore';

import {
  fetchAgentOptions,
  fetchActionLogActions,
  fetchDenialsPayerOptions,
  fetchDenialsPracticeOptions,
} from 'redux/actions/dropdownOptionsActions';

const RuleCell = (props) => {
  const {
    value,
    dropdownOptions,
    actions: {
      fetchAgentOptions,
      fetchActionLogActions,
      fetchDenialsPayerOptions,
      fetchDenialsPracticeOptions,
    },
  } = props;

  useEffect(() => {
    fetchAgentOptions();
    fetchActionLogActions();
    fetchDenialsPayerOptions();
    fetchDenialsPracticeOptions();
  }, [
    fetchAgentOptions,
    fetchActionLogActions,
    fetchDenialsPayerOptions,
    fetchDenialsPracticeOptions,
  ]);

  const myRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [hoverTimer, setHoverTimer] = useState(null);
  const [ruleDetails, setRuleDetails] = useState(null);

  const renderArray = (array = [], config = {}) => {
    const { isPlainArray = true } = config;

    if (array.length === 0) {
      return 'All';
    }
    const plainArray = isPlainArray
      ? [...array]
      : array.map(({ label }) => label);

    const firstArrayElement = plainArray[0];
    const arrayLength = plainArray.length;
    const dataTip =
      arrayLength.length === 1 ? undefined : plainArray.join(', ');

    const arrayElementsToRender =
      plainArray.length === 1
        ? firstArrayElement
        : `${firstArrayElement} +${arrayLength - 1}`;

    const randomId = Math.random().toString(36).slice(2, 9);
    return (
      <React.Fragment>
        <span
          className="text-decoration-underline"
          data-tip={dataTip}
          data-delay-show="600"
          data-for={randomId}
        >
          {arrayElementsToRender}
        </span>
        <ReactTooltip effect="solid" place="top" id={randomId} />
      </React.Fragment>
    );
  };

  const renderAgents = (array) => {
    if (!array || array.length === 0) {
      return 'Unassigned';
    }

    if (array.includes(ALL_AGENTS_OPTION_VALUE)) {
      return 'All';
    }
    const agentUsernameMap = array
      .map((name) => {
        return dropdownOptions.agents?.data?.find(
          ({ username }) => username === name
        );
      })
      .filter((x) => x)
      .map(({ name }) => name);

    return renderArray(agentUsernameMap.sort());
  };

  const renderDate = (date) => {
    if (!date) return '-';
    return getFormattedDate(date, 'MM/DD/YYYY');
  };
  useEffect(() => {
    const handleMouseEnter = () => {
      const timer = setTimeout(() => {
        const fetchAndSetData = async () => {
          const queryParams = {
            name: value,
          };
          if (!ruleDetails) {
            const { data } = await fetchRuleDetails(queryParams);
            setRuleDetails(data[0]);
          }
          setIsLoading(false);
        };
        fetchAndSetData();
      }, 500);
      setHoverTimer(timer);
    };

    const handleMouseLeave = () => {
      if (hoverTimer) {
        clearTimeout(hoverTimer);
        setHoverTimer(null);
      }
    };

    const currentRef = myRef.current;
    if (currentRef) {
      currentRef.addEventListener('mouseenter', handleMouseEnter);
      currentRef.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('mouseenter', handleMouseEnter);
        currentRef.removeEventListener('mouseleave', handleMouseLeave);
      }
      if (hoverTimer) {
        clearTimeout(hoverTimer);
      }
    };
  }, [hoverTimer, value, ruleDetails]);

  const extractSelectedArrayOptions = (options = [], selectedIds = []) =>
    options.filter(({ value }) => selectedIds.includes(value));

  const renderPractices = extractSelectedArrayOptions(
    dropdownOptions.practices?.data,
    ruleDetails?.practiceIds || []
  );

  const renderPayers = extractSelectedArrayOptions(
    dropdownOptions.payers?.data,
    ruleDetails?.payerIds || []
  );
  return (
    <div>
      <div
        ref={myRef}
        data-tip
        data-for={value}
        className="text-decoration-underline"
      >
        {value}
        <ReactTooltip
          id={value}
          effect="solid"
          place="top"
          className="tooltip-details-background"
          arrowColor="transparent"
        >
          {isLoading ? (
            'Loading'
          ) : (
            <div className="tooltip-details">
              <p className="tooltip-details__title">{value}</p>
              <hr className="tooltip-details__line" />
              <div className="d-flex  justify-content--space-between align-items-center">
                <span>Practice</span>
                <span>
                  {renderArray(renderPractices, {
                    isPlainArray: false,
                  })}
                </span>
              </div>
              <div className="d-flex justify-content--space-between align-items-center">
                <span>Procedure</span>
                <span>{renderArray(ruleDetails.procedureCodes)}</span>
              </div>
              <div className="d-flex  justify-content--space-between align-items-center">
                <span>Modifier</span>
                <span>{renderArray(ruleDetails.procedureCodes)}</span>
              </div>
              <div className="d-flex  justify-content--space-between align-items-center">
                <span>Reason</span>
                <span>{renderArray(ruleDetails.reasonCodes)}</span>
              </div>
              <div className="d-flex  justify-content--space-between align-items-center">
                <span>Remark</span>
                <span>{renderArray(ruleDetails.remarkCodes)}</span>
              </div>
              <div className="d-flex  justify-content--space-between align-items-center">
                <span>Payers</span>
                <span>
                  {renderArray(renderPayers, {
                    isPlainArray: false,
                  })}
                </span>
              </div>
              <div className="d-flex  justify-content--space-between align-items-center">
                <span>Agents</span>
                <span>{renderAgents(ruleDetails.agents)}</span>
              </div>
              <hr className="tooltip-details__line" />
              <div className="d-flex  justify-content--space-between align-items-center">
                <span>Date Created</span>
                <span> {renderDate(ruleDetails.createdAt)}</span>
              </div>
              <div className="d-flex  justify-content--space-between align-items-center">
                <span>Effective From</span>
                <span>
                  {' '}
                  {ruleDetails.effectiveDate
                    ? renderDate(ruleDetails.effectiveDate)
                    : renderDate(ruleDetails.createdAt)}
                </span>
              </div>
            </div>
          )}
        </ReactTooltip>
      </div>
    </div>
  );
};

RuleCell.propTypes = {
  value: PropTypes.string,
};

const mapStateToProps = (state) => ({
  dropdownOptions: {
    agents: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.AGENT_OPTIONS
    ),
    payers: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PAYER_OPTIONS
    ),
    practices: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PRACTICE_OPTIONS
    ),
  },
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchAgentOptions,
      fetchActionLogActions,
      fetchDenialsPayerOptions,
      fetchDenialsPracticeOptions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RuleCell);
