import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AppealioPopupWithFooter } from 'components/common/popup';

import './style.css';

import SopContainer from './SopContainer';

import { fetchSopInfo } from 'redux/actions/sopActions';

const SopPopup = (props) => {
  const {
    ruleId,
    ruleName = '',
    fetchSopInfo,
    handleSopChange,
    selectedPayers,
    excludedPayers,
    selectedRemarks,
    excludedRemarks,
    selectedProcedureCodes,
    excludedProcedureCodes,
    selectedReasonCodes,
    excludedReasonCodes,
    selectedProcedureModifierCodes,
    excludedProcedureModifierCodes,
    isZeroPayAnyClaimLineDisabled,
    isSelectedZeoClaimLineOptionWithoutAnyClaimLine,
    dropdownOptions,
    onClosePressed,
  } = props;
  const popupTitle = `SOP(s) for "${ruleName}" Rule`;

  return (
    <AppealioPopupWithFooter
      className="appealio-popup--v3 sop-popup"
      onClosePressed={onClosePressed}
      title={popupTitle}
      isFooterOutside={false}
      datacy="add-user-AppealioPopupWithFooter"
    >
      <SopContainer
        ruleId={ruleId}
        ruleName={ruleName}
        fetchSopInfo={fetchSopInfo}
        handleSopChange={handleSopChange}
        selectedPayers={selectedPayers}
        excludedPayers={excludedPayers}
        selectedRemarks={selectedRemarks}
        excludedRemarks={excludedRemarks}
        selectedProcedureCodes={selectedProcedureCodes}
        excludedProcedureCodes={excludedProcedureCodes}
        selectedReasonCodes={selectedReasonCodes}
        excludedReasonCodes={excludedReasonCodes}
        selectedProcedureModifierCodes={selectedProcedureModifierCodes}
        excludedProcedureModifierCodes={excludedProcedureModifierCodes}
        isZeroPayAnyClaimLineDisabled={isZeroPayAnyClaimLineDisabled}
        isSelectedZeoClaimLineOptionWithoutAnyClaimLine={
          isSelectedZeoClaimLineOptionWithoutAnyClaimLine
        }
        dropdownOptions={dropdownOptions}
      />
    </AppealioPopupWithFooter>
  );
};

SopPopup.propTypes = {
  onClosePressed: PropTypes.func,
  ruleName: PropTypes.string,
  ruleId: PropTypes.number,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  fetchSopInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(SopPopup);
