import React from 'react';
import { connect } from 'react-redux';

import './style.css';

import * as routes from 'constants/routes';

import SubmissionsTab from './SubmissionsTab';
import SubmissionRouter from './SubmissionRouter';

import { getUserInfo } from 'redux/reducers/loginStore';
import { isUserAuthorizedForSubmission } from 'Auth/AuthUtils';

const Submissions = (props) => {
  return (
    <div className="dashboard">
      <div className="submissions-wrapper">
        <div className="appealio-tab-filter appealio-tab-filter--lg">
          {props.canViewSubmissionPage && (
            <div className="d-flex align-items--baseline">
              <SubmissionsTab
                label="In Progress"
                route={{ default: routes.SUBMISSIONS_IN_PROGRESS }}
                activeRoutes={[routes.SUBMISSIONS_IN_PROGRESS]}
              />
            </div>
          )}
          <div className="d-flex align-items--baseline ml-8">
            <SubmissionsTab
              label="Delivery Tracker"
              route={{ default: routes.SUBMISSIONS }}
              activeRoutes={[routes.SUBMISSIONS]}
            />
          </div>
        </div>
        <SubmissionRouter />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const userInfo = getUserInfo(state);
  if (!userInfo) {
    return {};
  }

  const canViewSubmissionPage = isUserAuthorizedForSubmission(userInfo);

  return {
    canViewSubmissionPage,
  };
};

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Submissions);
