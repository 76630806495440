import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { MdInfoOutline } from 'react-icons/md';
import React, { useMemo, useState, useCallback, useEffect } from 'react';

import './style.css';

import FormatLetter from './FormatLetter';
import Button from 'components/common/button';
import * as toast from 'components/Shared/toast';
import ArrayCell from 'components/common/table/ArrayCell';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import ActionPopup, { TYPE_ACCEPT } from 'components/Shared/ActionPopup';
import CustomDropdown from 'components/common/dropdown/ButtonDropdown/CustomDropdown';
import S3FileUploader from 'components/CreateAppeal/DocumentsUpload/ContentProcessing/S3FileUploader';

import { snakeCaseKeys } from 'helpers/object';
import { createURLWithParams } from 'API/Config';
import { handleError } from 'helpers/errorHandler';

import DocumentsUploadAPI from 'API/DocumentsUploadAPI';

import { batchImportClaims, getBatchJobStatus } from 'API/BatchSubmissionAPI';

import { SUPPORTED_FILE_TYPES } from 'helpers/mimeTypes';

import successIcon from 'img/success.svg';
import incompleteIcon from 'img/incomplete.svg';

import {
  STATUS_LOADING,
  STATUS_COMPLETED,
  STATUS_ERROR,
  BATCH_STATUS_COMPLETED,
  BATCH_STATUS_IN_PROGRESS,
  BATCH_STATUS_FAILED,
} from '../constant';
import { SUBMISSIONS_IN_PROGRESS } from 'constants/routes';

const MAX_FILE_SIZE = 20 * 1024 * 1024;

const ERROR_UPLOAD_DOCUMENTS = 'Failed to upload documents';

const POLLING_INTERVAL = 3000;

/**
 * Generates a unique key for a claim.
 * @param {String} claimNumber
 * @param {String} claimControlNumber
 * @returns {String}
 */
const generateClaimKey = (claimNumber, claimControlNumber) =>
  `${claimNumber}-${claimControlNumber}`;

/**
 * Generates a unique file name
 * @param {string} fileName - Original file name
 * @param {Object[]} existingFiles - Array of existing file objects
 * @returns {string} Unique file name
 */
const getUniqueFileName = (fileName, existingFiles) => {
  const name = fileName.substring(0, fileName.lastIndexOf('.'));
  const ext = fileName.substring(fileName.lastIndexOf('.'));
  let newFileName = fileName;
  let counter = 1;

  while (existingFiles.some((file) => file.label === newFileName)) {
    newFileName = `${name}-${counter}${ext}`;
    counter++;
  }

  return newFileName;
};

const BatchSubmission = ({
  appealIdToDuplicate,
  selectedAppeal,
  selectedClaims,
  appealFormTemplateOptions,
  appealLetterTemplateOptions,
  onClaimUpdate,
  onBackButtonClick,
  submittedClaims,
  setSubmittedClaims,
  submissionStatus,
  setSubmissionStatus,
  bulkSubmissionStatus,
  setBulkSubmissionStatus,
  closeBatchUploadWidget,
  push,
}) => {
  const [dragOverIdx, setDragOverIdx] = useState(null);
  const [isLettersProcessed, setIsLettersProcessed] = useState(false);
  const [processedLetters, setProcessedLetters] = useState([]);
  const [showBatchConfirmationPopup, setShowBatchConfirmationPopup] =
    useState(false);
  const [batchSubmissionSuccessPopup, setBatchSubmissionSuccessPopup] =
    useState({
      isOpen: false,
      appealIds: [],
    });

  const formOptions = useMemo(
    () => [
      {
        label: 'Letters',
        options: processedLetters?.length
          ? processedLetters
          : appealLetterTemplateOptions,
      },
      { label: 'Forms', options: appealFormTemplateOptions },
    ],
    [processedLetters, appealLetterTemplateOptions, appealFormTemplateOptions]
  );

  /**
   * Handles form selection change for a claim
   * @param {string} claimNumber
   * @param {string} claimControlNumber
   * @param {Array<Object>} selectedForm
   */
  const handleFormChange = useCallback(
    (claimNumber, claimControlNumber, selectedForm) => {
      onClaimUpdate(claimNumber, claimControlNumber, { selectedForm });
    },
    [onClaimUpdate]
  );

  /**
   * Handles document change for a claim
   * @param {string} claimNumber
   * @param {string} claimControlNumber
   * @param {Array<Object>} documents
   */
  const handleDocumentsChange = useCallback(
    (claimNumber, claimControlNumber, documents) => {
      onClaimUpdate(claimNumber, claimControlNumber, { documents });
    },
    [onClaimUpdate]
  );

  /**
   * Uploads documents for an appeal
   * @param {string} submissionId
   * @param {Array<Object>} documents
   */
  const uploadDocuments = async (submissionId, documents) => {
    const uploaders = documents
      .filter((doc) => doc.isSelected)
      .map(
        (doc) =>
          new S3FileUploader(doc.file, DocumentsUploadAPI.createOptions())
      );

    try {
      await Promise.all(uploaders.map((uploader) => uploader.start()));

      const appealDocumentUploads = uploaders
        .filter((u) => !u.isFailed())
        .map((u, idx) => ({
          type: u.getFileType(),
          signedUrl: u.getResultS3Filename(),
          rank: idx + 1,
        }));

      await Promise.all(
        appealDocumentUploads.map((file) => {
          return DocumentsUploadAPI.uploadDocument(submissionId, file);
        })
      );
    } catch (error) {
      throw new Error(ERROR_UPLOAD_DOCUMENTS);
    }
  };

  /**
   * Gets selected letter template IDs for a claim
   * @param {Claim} claim
   * @returns {Array<number>}
   */
  const getSelectedLetterTemplates = useCallback(
    async (claim) => {
      try {
        const templates = await Promise.all(
          processedLetters
            .filter(({ value }) =>
              claim.selectedForm?.some((form) => form.value === value)
            )
            .map(({ value, content }) => {
              return { id: value, htmlContent: content };
            })
        );
        return templates;
      } catch (error) {
        return [];
      }
    },
    [processedLetters]
  );

  /**
   * Gets selected form template IDs for a claim
   * @param {Claim} claim
   * @returns {Array<number>}
   */
  const getSelectedFormTemplateIds = useCallback(
    (claim) =>
      appealFormTemplateOptions
        .filter(({ value }) =>
          claim.selectedForm?.some((form) => form.value === value)
        )
        .map(({ value }) => value),
    [appealFormTemplateOptions]
  );

  /**
   * Handles submission of a single claim
   * @param {string} claimNumber
   * @param {string} claimControlNumber
   */
  const handleSubmit = async (claimNumber, claimControlNumber) => {
    const statusKey = generateClaimKey(claimNumber, claimControlNumber);
    setSubmissionStatus((prev) => ({ ...prev, [statusKey]: STATUS_LOADING }));
    try {
      const claim = selectedClaims.find(
        (c) =>
          c.claimNumber === claimNumber &&
          c.claimControlNumber === claimControlNumber
      );

      const letterTemplates = await getSelectedLetterTemplates(claim);

      const response = await batchImportClaims({
        claims: snakeCaseKeys([
          {
            claimNumber,
            claimControlNumber,
            letterTemplates,
            formTemplateIds: getSelectedFormTemplateIds(claim),
            documents: [],
            isMedicalRecordsSubmission: Boolean(
              selectedAppeal?.isMedicalRecord
            ),
            manualClaimId: claim.manualClaimId,
            appealDuplicatedFromId: appealIdToDuplicate,
          },
        ]),
      });

      pollJobStatus(response.batchJobId, claimNumber, claimControlNumber);
      setSubmittedClaims((prev) => ({ ...prev, [statusKey]: true }));
    } catch (error) {
      handleError(error);
      setSubmissionStatus((prev) => ({ ...prev, [statusKey]: STATUS_ERROR }));
    }
  };

  /**
   * Handles bulk submission of claims
   */
  const handleBulkSubmit = async () => {
    setBulkSubmissionStatus(STATUS_LOADING);
    const unsubmittedClaims = selectedClaims.filter(
      (claim) =>
        !submittedClaims[
          generateClaimKey(claim.claimNumber, claim.claimControlNumber)
        ]
    );

    if (unsubmittedClaims.length === 0) {
      setBulkSubmissionStatus(STATUS_COMPLETED);
      return;
    }

    setSubmissionStatus((prev) => ({
      ...prev,
      ...Object.fromEntries(
        unsubmittedClaims.map((claim) => [
          generateClaimKey(claim.claimNumber, claim.claimControlNumber),
          STATUS_LOADING,
        ])
      ),
    }));

    try {
      const claims = await Promise.all(
        unsubmittedClaims.map(async (claim) => ({
          claimNumber: claim.claimNumber,
          claimControlNumber: claim.claimControlNumber,
          letterTemplates: await getSelectedLetterTemplates(claim),
          formTemplateIds: getSelectedFormTemplateIds(claim),
          documents: [],
          isMedicalRecordsSubmission: Boolean(selectedAppeal?.isMedicalRecord),
          manualClaimId: claim.manualClaimId,
          appealDuplicatedFromId: appealIdToDuplicate,
        }))
      );
      const response = await batchImportClaims({
        claims: snakeCaseKeys(claims),
      });

      pollJobStatus(response.batchJobId);
      setSubmittedClaims((prev) => ({
        ...prev,
        ...Object.fromEntries(
          unsubmittedClaims.map((claim) => [
            generateClaimKey(claim.claimNumber, claim.claimControlNumber),
            true,
          ])
        ),
      }));
    } catch (error) {
      handleError(error);
      setBulkSubmissionStatus(STATUS_ERROR);
    }
  };

  /**
   * Polls job status and triggers document upload when completed
   * @param {string} jobId
   * @param {string|null} claimNumber
   */
  const pollJobStatus = async (
    jobId,
    claimNumber = null,
    claimControlNumber = null
  ) => {
    try {
      const response = await getBatchJobStatus(jobId);

      if (
        response.batchStatus === BATCH_STATUS_COMPLETED ||
        response.batchStatus === BATCH_STATUS_FAILED
      ) {
        if (claimNumber && claimControlNumber) {
          if (response.batchStatus === BATCH_STATUS_COMPLETED) {
            handleSingleClaimCompletion(
              claimNumber,
              claimControlNumber,
              response
            );
          } else {
            handleJobError(claimNumber, claimControlNumber);
          }
        } else {
          handleBulkClaimCompletion(response);
        }
      } else if (response.batchStatus === BATCH_STATUS_IN_PROGRESS) {
        setTimeout(
          () => pollJobStatus(jobId, claimNumber, claimControlNumber),
          POLLING_INTERVAL
        );
      } else {
        handleJobError(claimNumber, claimControlNumber);
      }
    } catch (error) {
      handleError(error);
      handleJobError(claimNumber, claimControlNumber);
    }
  };

  /**
   * Handles completion of a single claim
   * @param {string} claimNumber
   * @param {Object} response
   */
  const handleSingleClaimCompletion = async (
    claimNumber,
    claimControlNumber,
    response
  ) => {
    const statusKey = generateClaimKey(claimNumber, claimControlNumber);
    const claim = selectedClaims.find(
      (c) =>
        c.claimNumber === claimNumber &&
        c.claimControlNumber === claimControlNumber
    );
    const appealJob = response.appealStatuses.find(
      (s) =>
        s.claimNumber === claimNumber &&
        s.claimControlNumber === claimControlNumber
    );
    if (
      appealJob?.status === BATCH_STATUS_COMPLETED &&
      claim?.documents?.length > 0
    ) {
      await uploadDocuments(appealJob.submissionId, claim.documents);
    }
    setSubmissionStatus((prev) => ({ ...prev, [statusKey]: STATUS_COMPLETED }));
  };

  /**
   * Handles completion of bulk claim submission
   * @param {Object} response
   */
  const handleBulkClaimCompletion = async (response) => {
    const updatedSubmissionStatus = { ...submissionStatus };
    const appealUploads = [];

    for (const appealStatus of response.appealStatuses) {
      const statusKey = generateClaimKey(
        appealStatus.claimNumber,
        appealStatus.claimControlNumber
      );

      if (appealStatus.status === BATCH_STATUS_COMPLETED) {
        updatedSubmissionStatus[statusKey] = STATUS_COMPLETED;
        const claim = selectedClaims.find(
          (c) =>
            c.claimNumber === appealStatus.claimNumber &&
            c.claimControlNumber === appealStatus.claimControlNumber
        );
        if (claim?.documents?.length > 0) {
          appealUploads.push(
            uploadDocuments(appealStatus.submissionId, claim.documents)
          );
        }
      } else {
        updatedSubmissionStatus[statusKey] = STATUS_ERROR;
      }
    }

    await Promise.all(appealUploads);
    const batchUploadSuccess = response.batchStatus === BATCH_STATUS_COMPLETED;

    setSubmissionStatus((prev) => ({ ...prev, ...updatedSubmissionStatus }));
    setBulkSubmissionStatus(
      batchUploadSuccess ? STATUS_COMPLETED : STATUS_ERROR
    );

    if (batchUploadSuccess) {
      const appealIds = response.appealStatuses.map(
        (appeal) => appeal.appealId
      );
      setBatchSubmissionSuccessPopup({ isOpen: true, appealIds });
    }
  };

  /**
   * Handles job error
   * @param {string|null} claimNumber
   */
  const handleJobError = (claimNumber, claimControlNumber) => {
    if (claimNumber && claimControlNumber) {
      const statusKey = generateClaimKey(claimNumber, claimControlNumber);
      setSubmissionStatus((prev) => ({ ...prev, [statusKey]: STATUS_ERROR }));
    } else {
      setBulkSubmissionStatus(STATUS_ERROR);
    }
  };

  const isClaimSubmissionSuccess = useCallback(
    (claim) =>
      submissionStatus[
        generateClaimKey(claim.claimNumber, claim.claimControlNumber)
      ] === STATUS_COMPLETED,
    [submissionStatus]
  );

  const isClaimSubmissionError = useCallback(
    (claim) =>
      submissionStatus[
        generateClaimKey(claim.claimNumber, claim.claimControlNumber)
      ] === STATUS_ERROR,
    [submissionStatus]
  );

  const isCreatingInProgress = useCallback(
    (claim) =>
      submissionStatus[
        generateClaimKey(claim.claimNumber, claim.claimControlNumber)
      ] === STATUS_LOADING,
    [submissionStatus]
  );

  const renderErrorButton = () => (
    <Button
      title="Error"
      className="claim-error-button justify-content-center width-160"
    />
  );

  const bulkSubmissionCompleted = bulkSubmissionStatus === STATUS_COMPLETED;

  const renderSuccessfullButton = () => {
    return (
      <Button
        title="Created"
        className="claim-created-button justify-content-center width-160"
      />
    );
  };

  const handleDragOver = (e, idx) => {
    e.preventDefault();
    e.stopPropagation();
    if (dragOverIdx === idx) {
      return;
    }

    setDragOverIdx(idx);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOverIdx(null);
  };

  const handleDropEvent = (e, claimNumber, claimControlNumber) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOverIdx(null);
    const files = Array.from(e.dataTransfer.files);

    const validFiles = files.filter((file) => {
      const fileType = file.type;

      if (!SUPPORTED_FILE_TYPES.includes(fileType)) {
        toast.error({
          title: 'Invalid File Type',
          message: `File '${file.name}' is not a supported file type.`,
        });
        return false;
      }

      if (file.size === 0) {
        toast.error({
          title: 'Empty File',
          message: `File content empty. Please upload valid file. Filename: ${file.name}.`,
        });
        return false;
      }

      if (file.size > MAX_FILE_SIZE) {
        toast.error({
          title: 'Invalid File Size',
          message: `${file.name} is too large. Maximum file size allowed is 2MB`,
        });
        return false;
      }

      return true;
    });

    const documents =
      selectedClaims.find(
        (claim) =>
          claim.claimNumber === claimNumber &&
          claim.claimControlNumber === claimControlNumber
      )?.documents || [];

    const newDocuments = [
      ...documents,
      ...validFiles.map((file) => {
        const uniqueFileName = getUniqueFileName(file.name, documents);
        return {
          value: uniqueFileName,
          label: uniqueFileName,
          file: new File([file], uniqueFileName, { type: file.type }),
          isSelected: true,
        };
      }),
    ];

    handleDocumentsChange(claimNumber, claimControlNumber, newDocuments);
  };

  const getUnsubmittedClaimsCount = useCallback(() => {
    return selectedClaims.filter(
      (claim) =>
        !submittedClaims[
          generateClaimKey(claim.claimNumber, claim.claimControlNumber)
        ]
    ).length;
  }, [selectedClaims, submittedClaims]);

  const checkAllClaimsInProgress = useCallback(() => {
    const allCompleted = selectedClaims.every(
      (claim) =>
        submissionStatus[
          generateClaimKey(claim.claimNumber, claim.claimControlNumber)
        ] === STATUS_COMPLETED
    );
    if (allCompleted) {
      setBulkSubmissionStatus(STATUS_COMPLETED);
    }
  }, [selectedClaims, setBulkSubmissionStatus, submissionStatus]);

  useEffect(() => {
    checkAllClaimsInProgress();
  }, [submissionStatus, checkAllClaimsInProgress]);

  const handleAppealLetterProcessedContentChange = (
    processedTemplates,
    error
  ) => {
    setIsLettersProcessed(!error);
    setProcessedLetters(processedTemplates);
  };

  const renderBatchSubmissionSuccessPopup = () => {
    if (!batchSubmissionSuccessPopup?.isOpen) return null;
    const isMedicalRecord = selectedAppeal?.isMedicalRecord;

    return (
      <ActionPopup
        title="Submission Successful"
        subtitle={`${batchSubmissionSuccessPopup?.appealIds.length} ${
          isMedicalRecord ? 'record(s)' : 'appeal(s)'
        } have been successfully batch created and moved to your In-Progress folder.`}
        actionButtons={[
          {
            title: `View In-Progress ${
              isMedicalRecord ? 'Records' : 'Appeals'
            }`,
            type: TYPE_ACCEPT,
          },
        ]}
        onActionButtonPressed={(idx) => {
          const queryParams = {
            batchUploadAppealIds:
              batchSubmissionSuccessPopup?.appealIds.join(','),
            batchUploadCompletedAt: Date.now(),
          };
          push(createURLWithParams(SUBMISSIONS_IN_PROGRESS, queryParams, true));
          closeBatchUploadWidget();
        }}
        icon={successIcon}
        hideCloseButton
      />
    );
  };

  const renderBatchSubmissionConfirmationPopup = () => {
    if (!showBatchConfirmationPopup) return null;
    const isMedicalRecord = selectedAppeal?.isMedicalRecord;
    return (
      <ActionPopup
        title="In Progress Submissions"
        subtitle={`Are you sure you would like to create ${getUnsubmittedClaimsCount()} In-Progress ${
          isMedicalRecord ? 'record(s)' : 'appeal(s)'
        }? If so, press Continue and you will be re-directed to your In-Progress folder.`}
        actionButtons={[
          {
            title: 'Cancel',
          },
          {
            title: 'Continue',
            type: TYPE_ACCEPT,
          },
        ]}
        onActionButtonPressed={(idx) => {
          if (idx === 1) {
            handleBulkSubmit();
            setShowBatchConfirmationPopup(false);
          } else {
            setShowBatchConfirmationPopup(false);
          }
        }}
        icon={incompleteIcon}
        onClosePressed={() => setShowBatchConfirmationPopup(false)}
      />
    );
  };

  return (
    <div className="batch-submission-step2">
      <table className="batch-submission-claim-table">
        <thead>
          <tr>
            <th className="batch-submission-claim-table__col-patient">
              Patient
            </th>
            <th className="batch-submission-claim-table__col-claim-id">
              Claim ID
            </th>
            <th className="batch-submission-claim-table__col-service-date">
              Service Date(s)
            </th>
            <th className="batch-submission-claim-table__col-claim-id">
              Payer Claim ID
            </th>
            <th className="batch-submission-claim-table__col-amounts">
              <div>Billed Amt</div>
              <div>(Payment Amt)</div>
            </th>
            <th className="batch-submission-claim-table__col-practice">
              <div>Practice</div>
              <div>(Provider)</div>
            </th>
            <th className="batch-submission-claim-table__col-forms">
              Letters/Forms
            </th>
            <th className="batch-submission-claim-table__col-attachments">
              <div className="d-flex align-items-center">
                Attachment
                <MdInfoOutline
                  size="18"
                  className="ml-4"
                  data-tip={'Drag & Drop anywhere in each row to Upload'}
                />
              </div>
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {selectedClaims.map((claim, idx) => (
            <tr
              key={`${claim.claimNumber}-${claim.claimControlNumber}-${idx}`}
              className={classnames('batch-submission-claim-table__row', {
                'batch-submission-claim-table__row--success':
                  isClaimSubmissionSuccess(claim),
                'batch-submission-claim-table__row--error':
                  isClaimSubmissionError(claim),
                'batch-submission-claim-table__row--drag-over':
                  dragOverIdx === idx,
              })}
              onDragOver={(e) => handleDragOver(e, idx)}
              onDragLeave={(e) => handleDragLeave(e)}
              onDrop={(e) =>
                handleDropEvent(e, claim.claimNumber, claim.claimControlNumber)
              }
            >
              <td className="batch-submission-claim-table__col-patient">
                <span data-tip={claim.patientName}>{claim.patientName}</span>
              </td>
              <td className="batch-submission-claim-table__col-claim-id">
                <span data-tip={claim.claimNumber}>{claim.claimNumber}</span>
              </td>
              <td className="batch-submission-claim-table__col-service-date">
                <ArrayCell value={claim.serviceDates} />
              </td>
              <td className="batch-submission-claim-table__col-claim-id">
                <span data-tip={claim.claimControlNumber}>
                  {claim.claimControlNumber}
                </span>
              </td>
              <td className="batch-submission-claim-table__col-amounts">
                <div>${claim.billedAmount}</div>
                <div>(${claim.paymentAmount})</div>
              </td>
              <td className="batch-submission-claim-table__col-practice">
                <div data-tip={claim.practiceName}>{claim.practiceName}</div>
                {claim.providerName && (
                  <div data-tip={claim.providerName}>
                    ({claim.providerName})
                  </div>
                )}
              </td>
              <td className="batch-submission-claim-table__col-forms">
                <CustomDropdown
                  options={formOptions}
                  isMulti
                  onChange={(value) =>
                    handleFormChange(
                      claim.claimNumber,
                      claim.claimControlNumber,
                      value
                    )
                  }
                  className="batch-submission-form-dropdown"
                  placeholder="Select Letters/Forms"
                  input={{
                    value: claim.selectedForm,
                  }}
                  isDisabled={
                    (appealLetterTemplateOptions?.length > 0 &&
                      !isLettersProcessed) ||
                    (appealLetterTemplateOptions?.length === 0 &&
                      appealFormTemplateOptions?.length === 0)
                  }
                />
              </td>
              <td className="batch-submission-claim-table__col-attachments">
                <CustomDropdown
                  options={claim?.documents || []}
                  isMulti
                  onChange={(selectedOptions) => {
                    const updatedDocuments = selectedOptions.map((doc) => ({
                      ...doc,
                      isSelected: selectedOptions.some(
                        (option) => option.value === doc.value
                      ),
                    }));
                    handleDocumentsChange(
                      claim.claimNumber,
                      claim.claimControlNumber,
                      updatedDocuments
                    );
                  }}
                  className="file-dropdown"
                  placeholder="Select Documents"
                  hideLabel
                  input={{
                    value: claim.documents?.filter((doc) => doc.isSelected),
                  }}
                />
              </td>
              <td className="submit-button-cell">
                {!submittedClaims[
                  generateClaimKey(claim.claimNumber, claim.claimControlNumber)
                ] &&
                  !isCreatingInProgress(claim) && (
                    <Button
                      type="secondary"
                      title="Create In Progress"
                      onClick={() =>
                        handleSubmit(
                          claim.claimNumber,
                          claim.claimControlNumber
                        )
                      }
                      className="submit-button width-160"
                    />
                  )}
                {isCreatingInProgress(claim) &&
                !isClaimSubmissionSuccess(claim) ? (
                  <div
                    type="light"
                    className="position-relative ap-button-loader d-flex justify-content-center"
                    disabled
                  >
                    <LoadingIndicator isLightIndicator showing />
                  </div>
                ) : null}
                {isClaimSubmissionSuccess(claim) && renderSuccessfullButton()}
                {isClaimSubmissionError(claim) && renderErrorButton()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactTooltip id="claim-tooltip" place="top" effect="solid" />

      <div
        className={classnames('batch-submission__footer', {
          'justify-content-flex-end': bulkSubmissionCompleted,
        })}
      >
        {(!bulkSubmissionCompleted ||
          bulkSubmissionStatus === STATUS_LOADING) && (
          <Button
            title="Back"
            onClick={onBackButtonClick}
            className="back-button"
            type="secondary-outline"
          />
        )}
        {!bulkSubmissionCompleted &&
          (bulkSubmissionStatus === STATUS_LOADING ||
            getUnsubmittedClaimsCount() > 0) && (
            <Button
              title={
                bulkSubmissionStatus === STATUS_LOADING
                  ? 'Creating...'
                  : `Create ${getUnsubmittedClaimsCount()} as In Progress`
              }
              onClick={() => setShowBatchConfirmationPopup(true)}
              className="create-in-progress-button"
              disabled={
                getUnsubmittedClaimsCount() === 0 ||
                bulkSubmissionStatus === STATUS_LOADING
              }
              type="secondary-outline"
            />
          )}
        {bulkSubmissionCompleted && (
          <span>In Progress claim(s) created successfully</span>
        )}
      </div>
      {!isLettersProcessed && (
        <FormatLetter
          appealLetterTemplates={appealLetterTemplateOptions}
          onProcessedContentChange={handleAppealLetterProcessedContentChange}
        />
      )}
      {renderBatchSubmissionConfirmationPopup()}

      {renderBatchSubmissionSuccessPopup()}
    </div>
  );
};

export default BatchSubmission;
