import { startCase } from 'lodash';
import Dropzone from 'react-dropzone';
import React, { useEffect, useState, createRef } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form/immutable';

import Input from 'components/common/input';
import * as toast from 'components/Shared/toast';
import Select from 'components/common/select/Select';
import SelectFileToUploadButton from 'components/CreateAppeal/DocumentsUpload/SelectFileToUploadButton';
import S3FileUploader from 'components/CreateAppeal/DocumentsUpload/ContentProcessing/S3FileUploader';

import {
  fieldCharacterValidation,
  zipCodeValidation,
} from 'helpers/validators';

import { mimeTypes } from 'helpers/mimeTypes';
import { getLinkPathFromSignedUrl } from 'helpers/utils';

import { states } from 'components/CreateAppeal/state-mock-data';
import { AppealioPopupWithFooter } from 'components/common/popup';

import xIcon from 'img/close-copy.svg';

import DocumentsUploadAPI from 'API/DocumentsUploadAPI';

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB in bytes

const AddPracticeForm = (props) => {
  const {
    onSubmit,
    submitting,
    handleSubmit,
    initialValues,
    onClosePressed,
    invalid,
    initialize,
  } = props;
  const [files, setFiles] = useState([]);
  const [isUploadViewVisible, setIsUploadViewVisible] = useState(true);
  const dropzoneRef = createRef();
  const acceptedTypes = ['PNG', mimeTypes.IMAGE_PNG];

  const stateOptions = states.map((state) => ({
    label: state.value,
    value: state.key,
  }));

  useEffect(() => {
    if (initialValues) {
      const values = initialValues.toJS();
      const selectedState = stateOptions.find(
        (option) => option.value === values.state
      );

      initialize({
        ...values,
        state: selectedState,
      });

      setIsUploadViewVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const validate = (values) => {
    const formValues = values.toJS();
    const nonEmptyValues = ['name', 'address1', 'city', 'state', 'zipcode'];

    const errors = {};

    nonEmptyValues.forEach((key) => {
      const isEmptyValue = !formValues[key];
      if (!isEmptyValue) {
        delete errors[key];
        return;
      }

      errors[key] =
        !formValues[key] && key === 'address1'
          ? 'Address Line 1 is required'
          : `${startCase(key)} is required`;
    });
    if (!errors.name && formValues.name) {
      const practiceNameValidation = fieldCharacterValidation(
        'Practice Name',
        formValues.name
      );
      if (practiceNameValidation) errors.name = practiceNameValidation;
    }

    if (!errors.zipcode && formValues.zipcode) {
      const zipValidation = zipCodeValidation(formValues.zipcode);
      if (zipValidation) errors.zipcode = zipValidation;
    }

    if (Object.keys(errors).length) {
      throw new SubmissionError(errors);
    }
  };

  const uploadFile = async () => {
    const fileOptions = {
      ...DocumentsUploadAPI.createOptions(),
    };

    try {
      const res = await Promise.all(
        files.map(async (file) => {
          const uploader = new S3FileUploader(file, fileOptions);
          uploader.on('error', (status) => {
            toast.error({
              title: 'Error',
              message: `We are having trouble uploading your document [${file.name}]. Please contact cx@docvocate.com for support.`,
            });
          });
          return uploader.start();
        })
      );
      return res;
    } catch (error) {
      toast.error({
        title: 'Upload failed',
        message:
          'Issue with uploading the file, please get in touch with cx@docvocate.com for assistance.',
      });
    }
  };

  const handleFormSubmit = async (formValues) => {
    let logoLinkPath;
    const values = formValues.toJS();
    validate(formValues);
    if (files.length) {
      const res = await uploadFile();
      const { signedUrl } = res[0];
      logoLinkPath = getLinkPathFromSignedUrl(signedUrl);
    }

    await onSubmit({
      ...values,
      state: values.state ? values.state.value : null,
      logo_link_path: logoLinkPath
        ? logoLinkPath.replace(/attachments\//, '')
        : '',
    });
  };

  const onDrop = (files) => {
    if (files.length === 0) {
      toast.error({
        title: 'Invalid File',
        message: 'File content empty. Please upload valid file.',
      });
    }
  };

  const checkForValidFiles = async (uploadFiles) => {
    if (files.length) {
      toast.error({
        title: '',
        message: 'You can not add more than one logo at the moment.',
      });
      return;
    }
    const validFiles = uploadFiles.filter((file) => {
      const filename = file.name;
      const fileType = file.type || file.name.split('.').pop().toUpperCase();
      if (!acceptedTypes.includes(fileType)) {
        toast.error({
          title: 'Invalid File Type.',
          message: `File '${file.name}' is PNG file.`,
        });

        return false;
      }

      if (file.size === 0) {
        toast.error({
          title: '',
          message: `File content empty. Please upload valid file. Filename: ${filename}.`,
        });

        return false;
      }

      if (file.size > MAX_FILE_SIZE) {
        toast.error({
          title: 'Invalid File Size',
          message: `${file.name} is too large. Maximum file size allowed is 2MB`,
        });
        return false;
      }

      return true;
    });

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const onSelectFileClicked = () => {
    dropzoneRef.current.open();
  };

  const renderSelectFileButton = () => {
    return (
      <div>
        <SelectFileToUploadButton
          onClick={onSelectFileClicked}
          btnTitle="Select Logo to Upload"
          isSelectButtonDisabled={files.length > 0}
          type={'button'}
        />
      </div>
    );
  };

  const renderPlaceholderIfEmpty = () => {
    if (files.length === 0) {
      return (
        <div className="documents__placeholder">
          <p className="documents__description">
            Acceptable File Type: PNG and Acceptable Maximum File Size: 2MB{' '}
            <br />{' '}
          </p>
          <div>
            <span className="section-header">Drag & Drop</span>
            <br />
            <span className="documents__description">
              Drag your file here or
            </span>
          </div>
        </div>
      );
    }
  };

  const renderListIfNonEmpty = () => {
    const onFileCancelClicked = (file) => {
      setFiles((prevFiles) =>
        prevFiles.filter((prevFile) => prevFile.name !== file.name)
      );
    };

    if (files.length > 0) {
      return (
        <div className="file_list">
          {files.map((file, idx) => {
            return (
              <div
                className="row documents_upload__file upload__logo-file"
                key={idx}
              >
                <div className="file__item-preview">
                  <img alt="file icon" src={file.preview} />
                </div>
                <div className="file_item-content">
                  <span className="file_item-filename">{file.name}</span>
                  <span
                    className="file_item__x"
                    onClick={() => onFileCancelClicked(file)}
                  >
                    <img alt="Cancel Button Icon" src={xIcon} />
                  </span>
                  <div className="file-item_progress__wrapper">
                    <progress
                      className="file__item-progress"
                      value={100}
                      max="100"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const RenderUploadComponent = ({ label, name }) => {
    if (!isUploadViewVisible && initialValues.toJS().logoLink) {
      const { logoLink } = initialValues.toJS();
      return (
        <>
          <label className="ap-input-label mb-8" htmlFor={name}>
            {label}
          </label>
          <div className="documents__box documents__box--md upload__logo-box upload__logo-box--preview">
            <div className="row documents_upload__file upload__logo-file">
              <div className="file__item-preview">
                <img alt="logo Link" src={logoLink} />
              </div>
            </div>
            <button
              className="ap-button ap-button--primary-red ml-auto mr-auto mt-22"
              onClick={() => setIsUploadViewVisible(true)}
            >
              Remove Logo
            </button>
          </div>
          <p className="note fs-12">
            Optimal logo dimension for upload is 128 X 128. Logo will be resized
            accordingly when attached to the letter template.
          </p>
        </>
      );
    }

    return (
      <>
        <label className="ap-input-label mb-8" htmlFor={name}>
          {label}
        </label>
        <Dropzone
          style={{}}
          disableClick={true}
          ref={dropzoneRef}
          onDrop={onDrop}
          accept={acceptedTypes}
          maxfiles="1"
          multiple={false}
          onDropAccepted={checkForValidFiles}
        >
          <div className="documents__box documents__box--md upload__logo-box">
            {renderPlaceholderIfEmpty()}
            {renderListIfNonEmpty()}
            {renderSelectFileButton()}
          </div>
        </Dropzone>
        <p className="note fs-12">
          Optimal logo dimension for upload is 128 X 128. Logo will be resized
          accordingly when attached to the letter template.
        </p>
      </>
    );
  };

  return (
    <AppealioPopupWithFooter
      className="appealio-popup--v3 appealio-popup--v3--overflow-hidden"
      onClosePressed={onClosePressed}
      title={`${initialValues ? 'Edit' : 'Add'} Practice`}
      isFooterOutside={false}
      datacy="add-practice-AppealioPopupWithFooter"
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="row mb-12">
          <div className="col-lg-4">
            <Field
              component={Input}
              name="name"
              label="Practice Name *"
              placeholder="Name"
            />
          </div>

          <div className="col-lg-4">
            <Field
              component={Input}
              name="address1"
              label="Address Line 1 *"
              placeholder="Address Line 1"
              maxLength={50}
              datacy="address-line-1-Field"
            />
          </div>

          <div className="col-lg-4">
            <Field
              component={Input}
              name="address2"
              label="Address Line 2"
              placeholder="Address Line 2"
              maxLength={50}
              datacy="address-line-2-Field"
            />
          </div>
        </div>
        <div className="row mb-12">
          <div className="col-lg-4">
            <Field
              component={Input}
              name="city"
              label={'City *'}
              placeholder="City"
              maxLength={50}
              datacy="city-Field"
            />
          </div>
          <div className="col-lg-4">
            <Field
              component={Select}
              options={stateOptions}
              name="state"
              defaultValue={null}
              label={'State *'}
              placeholder="State"
              reactSelectProps={{
                isClearable: true,
              }}
              datacy="state-Field"
            />
          </div>

          <div className="col-lg-4">
            <Field
              component={Input}
              name="zipcode"
              label={'Zip Code *'}
              placeholder="Zip Code"
              datacy="zipcode-Field"
            />
          </div>
        </div>
        <div className="row mb-16">
          <div className="col-lg-12">
            <Field
              component={RenderUploadComponent}
              name="files"
              label={'Upload  Logo'}
            />
          </div>
        </div>
        <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
          <button
            type="submit"
            disabled={invalid || submitting}
            className="ap-button ap-button--primary-dark ap-button--primary-md"
          >
            {initialValues ? 'Update' : 'Add'}{' '}
          </button>
        </div>
      </form>
    </AppealioPopupWithFooter>
  );
};

export default reduxForm({
  form: 'add-practice-form',
})(AddPracticeForm);
