import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';
import { ConnectedRouter } from 'connected-react-router/immutable';

import Router from './router';

import { history } from './redux/store/history';
import configureStore from './redux/store/configureStore';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_NODE_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        startTransactionOnLocationChange: false,
        startTransactionOnPageLoad: false,
      }),
    ],
    tracesSampleRate:
      parseFloat(process.env.REACT_APP_SENTRY_DSN_SAMPLE_RATE) || 0,
  });
}

const store = configureStore();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Router />
    </ConnectedRouter>
  </Provider>
);
