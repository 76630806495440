import { OTHER_HEX, PRE_DEFINED_BACKGROUND_COLORS } from './constant';

/**
 * Generates a random hex color that is not in the pre-defined list or the OTHER_HEX.
 * @returns {string} A random hex color.
 */
export const generateRandomHex = () => {
  let hex;
  do {
    hex = generateRandomColorHex();
  } while (isInvalidHex(hex));
  return hex;
};

function generateRandomColorHex() {
  const hue = Math.random();
  const [r, g, b] = hslToRgb(hue, 0.7, 0.5);
  return rgbToHex(r * 255, g * 255, b * 255);
}

function isInvalidHex(hex) {
  const invalidHexValues = [
    '#0000000000000000',
    OTHER_HEX,
    ...PRE_DEFINED_BACKGROUND_COLORS,
  ];
  return invalidHexValues.includes(hex);
}

function hslToRgb(h, s, l) {
  const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  const p = 2 * l - q;
  const rgb = [
    hue2rgb(p, q, h + 1 / 3),
    hue2rgb(p, q, h),
    hue2rgb(p, q, h - 1 / 3),
  ];
  return rgb;
}

function hue2rgb(p, q, t) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}

function rgbToHex(r, g, b) {
  return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;
}

/**
 * Processes the given values to separate the top 5 and the others.
 * @param {Object[]} value - The array of objects to process.
 * @returns {Object[]} An array containing the top 5 values and the others.
 */
export const processValues = (value) => {
  const otherValue = value.find((item) => item.name === 'Other');
  const withoutOthers = value.filter((item) => item.name !== 'Other');
  const sortedValue = withoutOthers.sort((a, b) => b.count - a.count);
  const top5 = sortedValue.slice(0, 5);
  const others = sortedValue.slice(5).reduce(
    (acc, item) => {
      acc.count += item.count;
      acc.actionNames.push(item.name);
      return acc;
    },
    { name: 'Other', count: otherValue?.count || 0, actionNames: [] }
  );

  const merged = [...top5, others];

  return merged.filter((item) => item.count && item.count > 0);
};

/**
 * Sorts the action data based on the label.
 * @param {Object} actionsData - The data containing actions.
 * @returns {string[]} A sorted array of action labels.
 */
export const sortActions = (actionsData) => {
  return actionsData?.data
    .map((action) => action.label)
    .sort((a, b) =>
      a === 'Other' ? 1 : b === 'Other' ? -1 : a.localeCompare(b)
    );
};
