import URI from 'urijs';
import React from 'react';
import moment from 'moment';
import _get from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';

import { isImage, isPdf } from './mimeTypes';
import { TABLE_PAGE_LIMIT } from 'constants/appConstants';

import { CURRENT_DEPLOYMENT_INSTANCE } from 'API/Config';

export function generateUUID() {
  return uuidv4();
}

export function permute(array, fromIndex, toIndex) {
  return array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
}

export function extractRelativeNumber(value) {
  if (value >= 1000000) {
    return value / 1000000 + 'M';
  } else if (value >= 1000) {
    return value / 1000 + 'K';
  }

  return value;
}

export const extractReactSelectOptions = (arr, removeAllOption = false) => {
  const noneOption = {
    value: '',
    label: 'All',
  };

  const reactSelectOptions = arr.map((value) => ({
    label: value,
    value,
  }));

  if (removeAllOption) {
    return reactSelectOptions;
  }

  return [noneOption, ...reactSelectOptions];
};

export const removeEmptyOrInactiveOptions = (
  options,
  removeInactive = true
) => {
  return (
    options?.filter(({ value, isActive }) => {
      if (!removeInactive) return value;
      return value && isActive;
    }) || []
  );
};

export const iFrameOrImageBody = (mimeType) => {
  if (isPdf(mimeType)) {
    return (link) => (
      <iframe
        title="Document Viewer Iframe"
        frameBorder="0"
        className="document-viewer__body-iframe"
        src={link}
      />
    );
  } else if (isImage(mimeType)) {
    return (link) => (
      <img
        alt="Img Document"
        className="document-viewer__body-image"
        src={link}
      />
    );
  }

  return null;
};

export const extractErrorMessagesHTTPFromError = async (
  error,
  {
    default400StatusMessages = [
      {
        title: 'Error',
        message: 'Something went wrong',
      },
    ],
    default500StatusMessages = [
      {
        title: 'Error',
        message: 'Something went wrong',
      },
    ],
  } = {}
) => {
  if (_get(error, 'response.status') === 400) {
    const errorBody = await error.response.json();
    const errors = _get(errorBody, 'errors', []).map(({ detail }) => ({
      title: 'Error',
      message: detail,
    }));

    const errorMessages =
      errors.length === 0 ? default400StatusMessages : errors;

    return errorMessages;
  }

  if (_get(error, 'response.status') === 500) {
    return default500StatusMessages;
  }
};

export const extractArrayFromCommaSeparatedString = (value) =>
  !value || value === '[]' ? [] : value.split(',').map((str) => str.trim());

export const extractArrayAndRemoveEmptyValues = (array) => {
  if (!Array.isArray(array)) {
    return [];
  }
  return array.filter((item) => item?.trim() !== '');
};

export const isDemoInstance = () =>
  CURRENT_DEPLOYMENT_INSTANCE &&
  CURRENT_DEPLOYMENT_INSTANCE.toLowerCase() === 'demo';

export const isProd2Instance = () =>
  CURRENT_DEPLOYMENT_INSTANCE &&
  CURRENT_DEPLOYMENT_INSTANCE.toLowerCase() === 'prod2';

export const getPageCountMessage = (
  currentPage,
  itemsOnCurrentPage,
  totalItems
) => {
  const lowestCount = TABLE_PAGE_LIMIT * (currentPage - 1) + 1;
  const highestCount =
    itemsOnCurrentPage + TABLE_PAGE_LIMIT * (currentPage - 1);

  return `${lowestCount} - ${highestCount} of ${totalItems}`;
};

export const convertToFixDecimalPoint = (obj, point) => {
  return Object.keys(obj).reduce((result, key) => {
    result[key] = obj[key].toFixed(point);
    return result;
  }, {});
};

export const extractRelativeNumberFixedPoint = (value) => {
  if (value >= 1000000) {
    const relativeNumber = value / 1000000;
    return !isNaN(parseFloat(relativeNumber))
      ? relativeNumber.toFixed(2) + 'M'
      : relativeNumber + 'M';
  } else if (value >= 1000) {
    const relativeNumber = value / 1000;
    return !isNaN(parseFloat(relativeNumber))
      ? relativeNumber.toFixed(2) + 'K'
      : relativeNumber + 'K';
  }

  return value;
};

/**
 * Compares two urls and returns true if they are same.
 * @param {String} url1
 * @param {String} url2
 * @returns {Boolean}
 */
export const areUrlsSame = (url1, url2) => {
  const cleanUrl1 = url1.replace(/\/+$/, '');
  const cleanUrl2 = url2.replace(/\/+$/, '');
  return cleanUrl1 === cleanUrl2;
};

/**
 * Get zip code and add hyphen for 9 digits number.
 * @param {String} value
 * @returns {string}
 */
export const validateAndFormatZipCode = (zipCode) => {
  if (!zipCode) {
    return null;
  }

  const HYPHEN_PATTERN = /-/;
  const ZIP_CODE_LENGTH = 5;
  const ZIP_CODE_PATTERN = /^(\d{5})(\d{4})$/;

  if (zipCode.length > ZIP_CODE_LENGTH && !HYPHEN_PATTERN.test(zipCode)) {
    return zipCode.replace(ZIP_CODE_PATTERN, '$1-$2');
  }
  return zipCode;
};

export const wait = (milliSeconds) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, milliSeconds);
  });
};

export const getLinkPathFromSignedUrl = (signedUrl) => {
  const linkPath = new URI(signedUrl).pathname(true);

  if (linkPath[0] === '/') {
    return linkPath.substring(1);
  }

  return linkPath;
};

/**
 * Get base64 from url
 * @param {String} url
 * @returns {String} base64
 */
export const getBase64FromURL = async (url) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };

      reader.onerror = () => {
        reject(new Error('Failed to read the file as base64.'));
      };

      reader.readAsDataURL(blob);
    });
  } catch (error) {
    throw new Error(`Failed to fetch the image: ${error.message}`);
  }
};

export const urlToBlob = async (url, fileName) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const blob = await response.blob();
    return new File([blob], fileName, { type: blob.type });
  } catch (error) {
    return null;
  }
};

/**
 * Creates an object map based on the given array and key-value properties.
 *
 * @param {Array<Object>} array - The array of objects to be mapped.
 * @param {string} keyProperty - The property to be used as keys in the object map.
 * @param {string} valueProperty - The property to be used as values in the object map.
 * @returns {Object} - An object map where keys are taken from the specified key property,
 *                    and values are taken from the specified value property.
 *
 * @example
 * const payerCounts = [
 *   { payerId: 2468, payerCounts: 410 },
 *   { payerId: 3612, payerCounts: 41 },
 *   // ... (rest of the payerCounts array)
 * ];
 *
 * const payerCountsMap = createObjectMap(payerCounts, 'payerId', 'payerCounts');
 *
 * // Result: { 2468: 410, 3612: 41, ... }
 */
export const createObjectMap = (array, keyProperty, valueProperty) => {
  return array.reduce((map, item) => {
    map[item[keyProperty]] = item[valueProperty];
    return map;
  }, {});
};

/**
 * Sorts the given data array in ascending order based on the `label` property of each object.
 *
 * @param {Array} data - The data array to be sorted.
 * @return {Array} The sorted data array in ascending order.
 */
export const sortDropdownOptionsByLabel = (data) => {
  if (data && !Array.isArray(data)) {
    return data;
  }
  return data.sort((a, b) => a.label.localeCompare(b.label));
};

/**
 * Sorts the given data array in ascending order based on the `label` property of each object.
 *
 * @param {Array} data - The data array to be sorted.
 * @return {Array} The sorted data array in descending order.
 */
export const sortDropdownOptionsByCount = (data) => {
  if (data && !Array.isArray(data)) {
    return data;
  }
  return data.sort((a, b) => b.count - a.count);
};

/**
 * Update the filters counts for different filter options.
 *
 * @param {Array} options - The data array to be attach a count
 * @param {Array} countsMap - The data array to be sorted.
 * @param {string} keyName - The data array to be sorted.
 * @param {string} nameKey - Name key to be used.
 * @return {Array} .
 */

export const updateFilterOptions = (options, countsMap, keyName, nameKey) => {
  return options
    .map((option) => ({
      ...option,
      label: countsMap[option[keyName]]
        ? `${option[nameKey]} (${countsMap[option[keyName]]})`
        : `${option[nameKey]} (0)`,
      count: countsMap[option[keyName]] || 0,
      value: option[keyName] || option.value,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
    .filter((option) => option.id || option.key || option.value)
    .sort((a, b) => b.count - a.count);
};

/**
 * Validates if the provided date is within a specified number of days from the current date.
 *
 * @param {string} providedDate - The date to compare against.
 * @param {number} numberOfDays - The number of days to consider for validation.
 * @returns {boolean} True if the provided date is within the specified number of days from the current date, false otherwise.
 */
export const isDateWithinPastDays = (providedDate, numberOfDays) => {
  if (!providedDate) {
    return;
  }
  const targetDate = moment(providedDate);
  const differenceInDays = moment().diff(targetDate, 'days');
  return differenceInDays < numberOfDays;
};

/**
 * Copies the provided text to the system clipboard.
 *
 * @param {string} text - The text to be copied to the clipboard.
 * @returns {void}
 */
export const copyToClipboard = (text) => navigator.clipboard.writeText(text);

/**
 * Checks if an array of items contains an item with the same name (case-insensitive) as the provided item name.
 *
 * @param {Array} items - The array of items to check.
 * @param {string} itemName - The name to check for duplicates.
 * @param {string} [key='name'] - The key to use for the name comparison.
 * @returns {boolean} True if a duplicate name is found, false otherwise.
 */
export const isDuplicateName = (items = [], itemName = '', key = 'name') =>
  items.map((item) => item[key].toLowerCase()).includes(itemName.toLowerCase());
