import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select, { components } from 'react-select';
import ReactTooltip from 'react-tooltip';

const CustomOption = ({ children, ...props }) => {
  const { isActive = true } = props.data;

  return (
    <components.Option
      className={classnames({
        'opacity-50': isActive === false,
      })}
      {...props}
    >
      {children}
    </components.Option>
  );
};

const CustomValueContainer = ({ children, ...props }) => {
  const { selectProps } = props;
  const { toolTip } = selectProps;
  const randomId = Math.random().toString(36).slice(2, 9);
  return (
    <components.ValueContainer
      {...props}
      innerProps={{
        ...props.innerProps,
        ...{
          'data-tip': toolTip,
          'data-for': `valueContainer-${randomId}`,
        },
      }}
    >
      {children}
      <ReactTooltip id={`valueContainer-${randomId}`} />
    </components.ValueContainer>
  );
};

const Dropdown = (props) => {
  const {
    placeholder = '',
    label,
    onChange,
    className,
    options,
    value,
    isClearable,
    isLoading,
    hideLabel,
    isDisabled,
    dropdownClassName = 'ap-react-select',
    classNamePrefix = 'dropdown',
    datacy = '',
    toolTip = '',
  } = props;

  const getReactSelectComponents = () => {
    const components = {};

    if (!isClearable) {
      components.IndicatorSeparator = () => null;
    }

    components.Option = CustomOption;

    if (toolTip !== '') {
      components.ValueContainer = CustomValueContainer;
    }

    return components;
  };

  const reactSelectComponents = getReactSelectComponents();

  return (
    <div
      className={classnames('dropdown__container', {
        [className]: className,
      })}
      datacy="dropdown"
    >
      {!hideLabel && <div className="dropdown__label">{label}</div>}
      <Select
        className={dropdownClassName}
        classNamePrefix={classNamePrefix}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        isClearable={isClearable}
        options={options}
        isLoading={isLoading}
        isDisabled={isDisabled}
        components={reactSelectComponents}
        datacy={datacy}
        toolTip={toolTip}
      />
    </div>
  );
};

Dropdown.defaultProps = {
  label: '',
  isClearable: false,
  options: [],
  isLoading: false,
  hideLabel: false,
  isDisabled: false,
};

Dropdown.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  dropdownClassName: PropTypes.string,
  classNamePrefix: PropTypes.string,
  hideLabel: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default Dropdown;
