import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTooltip from 'react-tooltip';
import { isInteger, isEmpty } from 'lodash';
import React, { useMemo, useCallback, useState } from 'react';

import AppealioTable from 'components/common/table';

import { moneyFormatter } from '../../../../helpers/money';
import {
  INTEGRATION_TYPE,
  PAYER_API_TYPE,
} from '../../../../constants/appConstants';
import {
  isAvailityClaimStatusCheckEnabledForUser,
  isUHCClaimStatusCheckEnabledForUser,
} from 'Auth/FeatureFlags';
import { BsSearch } from 'react-icons/bs';
import UhcClaimStatusCheck from 'components/UhcClaimStatusCheck';
import { openClaimStatusRpaWidget } from 'redux/actions/rpaWidgetActions';
import { RPA_WIDGET_TYPE } from 'constants/rpa';
import { getFormattedDate } from 'helpers/dateFormats';
import { isUserAuthorizedForRpaWidget } from 'Auth/AuthUtils';
import { openAvailityClaimStatusWidget } from 'redux/actions/availityClaimStatusWidgetActions';
import { extractArrayAndRemoveEmptyValues } from 'helpers/utils';
import { getUHCClaimStatusSearchDataForClaims } from 'components/GlobalSearch/hook';

const CustomCell = ({ value, accessor, delayHide = null }) => {
  const isEmptyString = value === '--';
  let cellValue = isEmptyString ? 'N/A' : value;
  const toolTip = isEmptyString ? '' : value;
  if (accessor === 'agentName') {
    const splitData = value.split(' ');
    cellValue = splitData[0] + ' ' + splitData.at(-1)[0] + '.';
  }

  return (
    <div className="appealio-table__custom-cell">
      <div data-tip={toolTip} data-delay-hide={delayHide}>
        {cellValue}
      </div>
      <ReactTooltip
        effect="solid"
        className="interactive-tooltip"
        place="top"
      />
    </div>
  );
};

const ClaimNumberCell = (props) => {
  return <CustomCell value={props.value} delayHide="400" />;
};

const BuildArrayCell = ({ value: array, isLink = false }) => {
  if (isEmpty(array)) {
    return (
      <div className="appealio-table__custom-cell">
        <span data-tip="None">None</span>
      </div>
    );
  }

  const firstArrayItem = array[0];
  const arrayLength = array.length;

  const dataToRender =
    arrayLength === 1
      ? firstArrayItem
      : `${firstArrayItem} +${arrayLength - 1}`;

  const dataTip = array.length === 1 ? array : array.join(', ');

  return (
    <div
      className={classnames('appealio-table__custom-cell', {
        'appealio-table__custom-cell--is-link': isLink,
      })}
    >
      <span data-for="flyover-array-tooltip" data-tip={dataTip}>
        {dataToRender}
      </span>
      <ReactTooltip id="flyover-array-tooltip" effect="solid" place="top" />
    </div>
  );
};
const BuildDateArrayCell = (props) => {
  const array = extractArrayAndRemoveEmptyValues(props.value).map((x) =>
    getFormattedDate(x)
  );
  return <BuildArrayCell value={array} />;
};

const BuildLinkArrayCell = (props) => {
  const array = extractArrayAndRemoveEmptyValues(props.value);
  return <BuildArrayCell value={array} isLink={true} />;
};

const BuildProviderCell = (props) => {
  const data = props.row.original;

  const providerName = data.providerName;
  const practiceName = data.practiceName || '';

  return (
    <div className={'appealio-table__custom-cell'}>
      <span data-tip={providerName}>{providerName}</span>
      <br />
      {practiceName && <span data-tip={practiceName}>({practiceName})</span>}
    </div>
  );
};

const BuildPatientCell = (props) => {
  const data = props.row.original;
  return (
    <div className={'appealio-table__custom-cell'}>
      <span data-tip={data.patientName}>{data.patientName}</span>
      <br />
      {data.patientNumber && (
        <span data-tip={data.patientNumber}>({data.patientNumber})</span>
      )}
    </div>
  );
};

const PayerCell = (props) => {
  const { appealsPayerName, payerName } = props.row.original;
  const value = appealsPayerName || payerName;
  return <CustomCell value={value} />;
};
const BuildAmountCell = (props) => {
  const amount = props.value;
  if (amount === null) {
    return (
      <div className="appealio-table__custom-cell">
        <span data-tip="N/A">N/A</span>
      </div>
    );
  }
  const formattedAmount = isInteger(amount)
    ? amount
    : parseFloat(amount).toFixed(2);
  const formattedText = moneyFormatter().format(formattedAmount);

  return (
    <div className="appealio-table__custom-cell">
      <span data-tip={formattedText}>{formattedText}</span>
    </div>
  );
};

const DEFAULT_CLAIM_STATUS_CHECK_POPUP_STATE = {
  isOpen: false,
  data: {
    claimControlNumber: '',
    billingProviderTaxId: '',
    ediPayerId: '',
    isClaimSearchViaRpaEnabled: false,
    billingProviderName: '',
    renderingProviderName: '',
    appealId: '',
  },
};

const DeniedClaimsTable = ({
  sortBy,
  deniedClaims,
  onSortTableClick,
  selectedClaimInfos,
  isAllClaimsSelected,
  handleToggleClaimSelection,
  isFetchingDeniedClaims,
  onSelectAllDeniedClaimsClicked,
  integrationType,
  onCodesCellClick,
  userInfo,
  onRowClick,
  actions: { openClaimStatusRpaWidget, openAvailityClaimStatusWidget },
}) => {
  const [claimStatusCheckPopup, setClaimStatusCheckPopup] = useState(
    DEFAULT_CLAIM_STATUS_CHECK_POPUP_STATE
  );
  const handleCodeCellClick = useCallback(
    (rowData) => {
      const {
        reasonCodesArray: reasonCodes,
        remarkCodesArray: remarkCodes,
        claimNumber: claimId,
      } = rowData;

      onCodesCellClick({
        remarkCodes,
        reasonCodes,
        claimId,
      });
    },
    [onCodesCellClick]
  );

  const hasClaimSearchEnabledClaim = useMemo(() => {
    return deniedClaims.some((claim) => {
      const isClaimStatusSearchAvailableForUser =
        isUHCClaimStatusCheckEnabledForUser(userInfo) ||
        isAvailityClaimStatusCheckEnabledForUser(userInfo);
      return (
        (isClaimStatusSearchAvailableForUser && claim.payerApi) ||
        claim.isRpaEnabled
      );
    });
  }, [deniedClaims, userInfo]);
  const SelectCellHeader = useCallback(() => {
    const isSomeClaimsSelected = selectedClaimInfos.length > 0;

    const title =
      !isAllClaimsSelected && !isSomeClaimsSelected
        ? 'Select All'
        : 'Clear All';

    return (
      <div>
        <input
          className="select-all-checkbox"
          data-tip={title}
          onChange={onSelectAllDeniedClaimsClicked}
          type="checkbox"
          checked={isAllClaimsSelected}
          ref={(el) =>
            el &&
            (el.indeterminate = !isAllClaimsSelected && isSomeClaimsSelected)
          }
          datacy="select-all-checkbox-input"
        />
        <ReactTooltip effect="solid" />
      </div>
    );
  }, [selectedClaimInfos, isAllClaimsSelected, onSelectAllDeniedClaimsClicked]);

  const SelectCell = useCallback(
    (props) => {
      const rowData = props.row.original;

      const isChecked = selectedClaimInfos.find(
        ({ claimNumber, claimControlNumber }) =>
          rowData.claimNumber === claimNumber &&
          rowData.claimControlNumber === claimControlNumber
      );

      return (
        <div>
          <input
            onClick={(e) => e.stopPropagation()}
            onChange={() =>
              handleToggleClaimSelection(
                rowData.claimNumber,
                rowData.claimControlNumber
              )
            }
            checked={isChecked}
            type="checkbox"
            className="denials-queue-td-checkbox"
            datacy="claim-selection-checkbox-input"
          />
        </div>
      );
    },
    [selectedClaimInfos, handleToggleClaimSelection]
  );

  const columns = useMemo(
    () =>
      [
        {
          Header: SelectCellHeader,
          accessor: 'select',
          disableSortBy: true,
          Cell: SelectCell,
          width: 40,
        },
        {
          Header: '',
          accessor: 'as',
          width: hasClaimSearchEnabledClaim ? 50 : 0,
          Cell: ({ row }) => {
            const isUhcClaim =
              row.original.payerApi === PAYER_API_TYPE.UHC &&
              isUHCClaimStatusCheckEnabledForUser(userInfo) &&
              row.original.claimControlNumber &&
              row.original.billingProviderTaxId &&
              row.original.ediPayerId;
            const isAvailityClaim =
              row.original.payerApi === PAYER_API_TYPE.AVAILITY &&
              isAvailityClaimStatusCheckEnabledForUser(userInfo);

            const canUseClaimSearchApi = isUhcClaim || isAvailityClaim;

            const canUseRpa =
              isUserAuthorizedForRpaWidget(userInfo) &&
              row.original.isRpaEnabled;

            if (!canUseClaimSearchApi && !canUseRpa) {
              return null;
            }
            const handleClaimStatusCheckClick = async (e) => {
              e.stopPropagation();
              if (!canUseClaimSearchApi) {
                return;
              }
              if (isAvailityClaim) {
                return openAvailityClaimStatusWidget({
                  claimControlNumber: row.original.claimControlNumber,
                  ediPayerId: row.original.ediPayerId,
                });
              }
              return setClaimStatusCheckPopup({
                isOpen: true,
                data: getUHCClaimStatusSearchDataForClaims(
                  row.original,
                  userInfo
                ),
              });
            };

            return (
              <div>
                <button
                  data-tip={`Claim Status Check via ${
                    isAvailityClaim ? 'Availity' : 'UHC'
                  }`}
                  className={classnames('ap-button ap-button--action')}
                  onClick={handleClaimStatusCheckClick}
                >
                  <BsSearch
                    className="appeal__action appeal__action--appeal"
                    datacy="denial-table-BsPencil"
                  />
                </button>
              </div>
            );
          },
        },
        {
          Header: 'Patient',
          accessor: 'patientName',
          className: 'appealio-table-cell__border--right',
          width: 110,
          sortKey: 'patientName',
          Cell: BuildPatientCell,
        },
        {
          Header: 'Claim ID',
          accessor: 'claimNumber',
          width: 90,
          sortKey: 'claimNumber',
          Cell: ClaimNumberCell,
        },
        {
          Header: 'Service Date',
          accessor: 'serviceDate',
          width: 100,
          sortKey: 'serviceDate',
          Cell: BuildDateArrayCell,
        },
        {
          Header: 'Payer Claim ID',
          accessor: 'claimControlNumber',
          width: 130,
          sortKey: 'claimControlNumber',
          Cell: ClaimNumberCell,
        },
        {
          Header: 'Voucher No.',
          accessor: 'voucherKey',
          width: 120,
          sortKey: 'voucherKey',
          Cell: CustomCell,
        },
        {
          Header: 'Billed Amt',
          accessor: 'billedAmount',
          width: 90,
          sortKey: 'billedAmount',
          Cell: BuildAmountCell,
        },
        {
          Header: 'Payment Amt',
          accessor: 'paymentAmount',
          width: 110,
          sortKey: 'paymentAmount',
          Cell: BuildAmountCell,
        },
        {
          Header: 'Payer',
          accessor: 'payerName',
          width: 120,
          sortKey: 'payerName',
          Cell: PayerCell,
        },
        {
          Header: () => (
            <div>
              Provider
              <br />
              (Practice)
            </div>
          ),
          accessor: 'providerName',
          width: 120,
          sortKey: 'providerName',
          Cell: BuildProviderCell,
        },
        {
          Header: 'Procedure',
          accessor: 'cptCodesArray',
          width: 80,
          sortKey: '',
          disableSortBy: true,
          Cell: BuildArrayCell,
        },
        {
          Header: 'Modifier',
          accessor: 'cptModifiersCodesArray',
          width: 80,
          sortKey: '',
          disableSortBy: true,
          Cell: BuildArrayCell,
        },
        {
          Header: 'Reason',
          accessor: 'reasonCodesArray',
          width: 70,
          sortKey: '',
          disableSortBy: true,
          onCellClick: handleCodeCellClick,
          Cell: BuildLinkArrayCell,
        },
        {
          Header: 'Remark',
          accessor: 'remarkCodesArray',
          width: 70,
          sortKey: '',
          disableSortBy: true,
          onCellClick: handleCodeCellClick,
          Cell: BuildLinkArrayCell,
        },
        {
          Header: 'EOB Date',
          accessor: 'ediPaymentDate',
          className: 'appealio-table-cell__border--left',
          width: 100,
          sortKey: 'ediPaymentDate',
          Cell: ({ value }) => (value ? getFormattedDate(value) : '--'),
        },
      ].filter((column) => {
        const columnsToExclude =
          integrationType === INTEGRATION_TYPE.APM
            ? ['claimControlNumber']
            : ['voucherKey'];

        return !columnsToExclude.includes(column.accessor);
      }),
    [
      hasClaimSearchEnabledClaim,
      handleCodeCellClick,
      integrationType,
      userInfo,
      SelectCell,
      openAvailityClaimStatusWidget,
      SelectCellHeader,
    ]
  );

  const onTableSort = useCallback(
    (sortBy) => {
      return onSortTableClick(sortBy);
    },
    [onSortTableClick]
  );

  return (
    <div>
      <AppealioTable
        columns={columns}
        onSort={onTableSort}
        data={deniedClaims}
        onRowClick={onRowClick}
        isLoading={isFetchingDeniedClaims}
        className="appealio-table--default-width denied-claims-table"
        wrapperClassName="dashboard-min-height"
        defaultSortBy={{
          id: 'ediPaymentDate',
          desc: true,
        }}
        datacy="denied-claims-table-AppealioTable"
      />
      {claimStatusCheckPopup.isOpen && (
        <UhcClaimStatusCheck
          onCloseClick={() => {
            setClaimStatusCheckPopup(DEFAULT_CLAIM_STATUS_CHECK_POPUP_STATE);
          }}
          claimControlNumber={claimStatusCheckPopup.data.claimControlNumber}
          claimNumber={claimStatusCheckPopup.data.claimNumber}
          billingProviderTaxId={claimStatusCheckPopup.data.billingProviderTaxId}
          ediPayerId={claimStatusCheckPopup.data.ediPayerId}
          isClaimSearchViaRpaEnabled={
            isUserAuthorizedForRpaWidget(userInfo) &&
            claimStatusCheckPopup.data.isClaimSearchViaRpaEnabled
          }
          billingProviderName={claimStatusCheckPopup.data.billingProviderName}
          renderingProviderName={
            claimStatusCheckPopup.data.renderingProviderName
          }
          onClaimSearchViaRpaClick={() => {
            openClaimStatusRpaWidget(
              {
                appealId: claimStatusCheckPopup.data.appealId,
                claimNumber: claimStatusCheckPopup.data.claimControlNumber,
              },
              RPA_WIDGET_TYPE.CLAIM_STATUS_WIDGET_AVAILITY
            );
            setClaimStatusCheckPopup(DEFAULT_CLAIM_STATUS_CHECK_POPUP_STATE);
          }}
        />
      )}
    </div>
  );
};

DeniedClaimsTable.propTypes = {
  deniedClaims: PropTypes.array.isRequired,
  sortBy: PropTypes.object.isRequired,
  onSortTableClick: PropTypes.func.isRequired,
  handleToggleClaimSelection: PropTypes.func.isRequired,
  isFetchingDeniedClaims: PropTypes.bool.isRequired,
  selectedClaimInfos: PropTypes.array.isRequired,
  onSelectAllDeniedClaimsClicked: PropTypes.func.isRequired,
  integrationType: PropTypes.oneOf(Object.values(INTEGRATION_TYPE)),
};

function mapStateToProps(state, ownProps) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { openClaimStatusRpaWidget, openAvailityClaimStatusWidget },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeniedClaimsTable);
