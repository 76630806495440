/**
 * Created by goer on 11/22/16.
 */
import _ from 'lodash';

export const mimeTypes = {
  APPLICATION_PDF: 'application/pdf',
  IMAGE_PNG: 'image/png',
  IMAGE_JPEG: 'image/jpeg',
  TEXT_PLAIN: 'text/plain',
  APPLICATION_ZIP: 'application/zip',
  TEXT_CSV: 'text/csv',
  APPLICATION_VND_MS_EXCEL: 'application/vnd.ms-excel',
  APPLICATION__OFFICEDOCUMENT_SPREADSHEETML_SHEET:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const ZIP_MIME_TYPES = {
  MULTIPART_X_ZIP: 'multipart/x-zip',
  OCTET_STREAM: 'application/octet-stream',
  X_COMPRESSED: 'application/x-compressed',
  X_ZIP_COMPRESSED: 'application/x-zip-compressed',
};

export const SUPPORTED_FILE_TYPES = Object.keys(mimeTypes).map(
  (key) => mimeTypes[key]
);

export function isImage(mimeType) {
  return _.startsWith(mimeType, 'image/');
}

export function isPdf(mimeType) {
  return mimeType === 'application/pdf';
}
