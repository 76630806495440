import {
  INVALID_DATE_FORMAT_ERROR,
  INVALID_DATE_ERROR,
  INVALID_DATE_FORMAT,
  DATE_STRING_ERROR,
  INVALID_DATE,
  DATE_PAST_ERROR,
} from '../Shared/Errors/errorMessages';

export const addedDateFieldError = (
  displayErrors,
  errorString,
  fieldName = null
) => {
  let fieldNameLabel = '';
  if (typeof fieldName === 'string') {
    fieldNameLabel = `${fieldName}: `;
  }
  if (errorString === INVALID_DATE_FORMAT) {
    displayErrors.push({
      title: INVALID_DATE_FORMAT_ERROR.title,
      message: `${fieldNameLabel}${INVALID_DATE_FORMAT_ERROR.message}`,
    });
    return true;
  } else if (errorString === INVALID_DATE) {
    displayErrors.push({
      title: INVALID_DATE_ERROR.title,
      message: `${fieldNameLabel}${INVALID_DATE_ERROR.message}`,
    });
    return true;
  } else if (errorString === DATE_STRING_ERROR) {
    displayErrors.push({
      title: DATE_PAST_ERROR.title,
      message: `${fieldNameLabel}${DATE_PAST_ERROR.message}`,
    });
  }
  return false;
};
