const sortByLabel = (a, b) => {
  return a.label.localeCompare(b.label);
};

export const sortByCount = (a, b) => {
  return b.count - a.count;
};

export const sortDropdownList = (values, options, sortFunc = sortByLabel) => {
  // Create a set for quick lookup
  const selectedSet = new Set(values.map((val) => val.value));

  // Sorting function
  const sortOptions = (a, b) => {
    const aIsSelected = selectedSet.has(a.value);
    const bIsSelected = selectedSet.has(b.value);

    if (a?.isAppealioAllOption) {
      return -1;
    }

    if (b?.isAppealioAllOption) {
      return 1;
    }

    if (aIsSelected && bIsSelected) {
      return sortFunc(a, b);
    }

    if (aIsSelected) {
      return -1;
    }

    if (bIsSelected) {
      return 1;
    }

    return sortFunc(a, b);
  };

  const sortOptionsGroup = (options) => {
    const withNestedOptions = options.filter((opt) => opt.options);
    const withoutNestedOptions = options.filter((opt) => !opt.options);

    withoutNestedOptions.sort(sortOptions);

    withNestedOptions.forEach((group) => {
      group.options = sortOptionsGroup(group.options);
    });

    return [...withNestedOptions, ...withoutNestedOptions];
  };

  return sortOptionsGroup(options);
};
