import React, { useState, useEffect } from 'react';

import Card from 'components/common/card';
import AddPracticeContainer from './AddPracticeContainer';
import AddClinicContainer from './AddClinic/AddClinicContainer';
import AddTemplateContainer from './AddTemplates/AddTemplatesContainer';
import SuccessfulAccountCreation from './SuccessfulAccountCreation/SuccessfulAccountCreation';
import AddBillingProviderContainer from './AddBillingProviderContainer/AddBillingProviderContainer';
import AddRenderingProviderForm from './AddRenderingProviderContainer/AddRenderingProviderContainer';

import { signupDetail } from 'API/CreateUserAPI';
import { handleError } from 'helpers/errorHandler';

import { SIGNUP_FORM_DATA_KEY } from 'constants/localStorage';
import Button from 'components/common/button';

const STEPS = {
  ADD_PRACTICES: 'ADD_PRACTICES',
  ADD_CLINICS: 'ADD_CLINICS',
  ADD_BILLING_PROVIDER: 'ADD_BILLING_PROVIDER',
  ADD_RENDERING_PROVIDER: 'ADD_RENDERING_PROVIDER',
  ADD_TEMPLATES: 'ADD_TEMPLATES',
  SUCCESSFUL_ACCOUNT_CREATION: 'SUCCESSFUL_ACCOUNT_CREATION',
};

const INITIAL_FORM_DATA = {
  practices: [],
  clinics: [],
  billingProviders: [],
  renderingProviders: [],
  payerIds: [],
  templateIds: [],
};

const CARD_TITLE = {
  ADD_PRACTICES: {
    title: 'Step 1. Add Practice(s)',
    step: 1,
  },
  ADD_CLINICS: {
    title: 'Step 2. Add Clinic(s)',
    step: 2,
  },
  ADD_BILLING_PROVIDER: {
    title: 'Step 3. Add Billing Provider(s)',
    step: 3,
  },
  ADD_RENDERING_PROVIDER: {
    title: 'Step 4. Add Rendering Provider(s)',
    step: 4,
  },
  ADD_TEMPLATES: {
    title: 'Step 5. Payer(s) & Template(s)',
    step: 5,
  },
};

function getNextStep(currStep, getPrevStep = false) {
  const onboardingStepsOrder = Object.keys(STEPS);
  const currStepIndex = onboardingStepsOrder.indexOf(currStep);
  const newStepIndex = getPrevStep ? currStepIndex - 1 : currStepIndex + 1;

  if (getPrevStep && currStepIndex === 0) {
    return currStep;
  }

  if (!getPrevStep && currStepIndex === onboardingStepsOrder.length - 1) {
    return currStep;
  }

  const newStepKey = onboardingStepsOrder[newStepIndex];

  return STEPS[newStepKey];
}

const CreateAppealioAccountDetails = () => {
  const [currentStep, setCurrentStep] = useState(STEPS.ADD_PRACTICES);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);

  useEffect(() => {
    let formDataFromStorage;
    try {
      const encodedFormData = localStorage.getItem(SIGNUP_FORM_DATA_KEY);
      if (encodedFormData) {
        formDataFromStorage = JSON.parse(atob(encodedFormData)); // Decode from base64 and parse the JSON data
      }
    } catch (error) {
      return;
    }

    if (formDataFromStorage && typeof formDataFromStorage === 'object') {
      setFormData(formDataFromStorage);

      const isCurrentStep = (value) => value && value.length > 0;
      if (isCurrentStep(formDataFromStorage.renderingProviders)) {
        return setCurrentStep(STEPS.ADD_RENDERING_PROVIDER);
      }
      if (isCurrentStep(formDataFromStorage.billingProviders)) {
        return setCurrentStep(STEPS.ADD_BILLING_PROVIDER);
      }
      if (isCurrentStep(formDataFromStorage.clinics)) {
        return setCurrentStep(STEPS.ADD_CLINICS);
      }

      return setCurrentStep(STEPS.ADD_PRACTICES);
    }
  }, []);

  useEffect(() => {
    const encodedFormData = btoa(JSON.stringify(formData)); // Encode formData to base64
    localStorage.setItem(SIGNUP_FORM_DATA_KEY, encodedFormData);
  }, [formData]);

  const updateFormData = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  };

  const handleFormSubmit = async () => {
    try {
      await signupDetail(formData);
      setCurrentStep(STEPS.SUCCESSFUL_ACCOUNT_CREATION);
    } catch (error) {
      handleError(error);
    }
  };

  const isCurrentStepDisabled = () => {
    const stepFormDataMap = {
      [STEPS.ADD_PRACTICES]: formData.practices,
      [STEPS.ADD_CLINICS]: formData.clinics,
      [STEPS.ADD_BILLING_PROVIDER]: formData.billingProviders,
      [STEPS.ADD_RENDERING_PROVIDER]: formData.renderingProviders,
      [STEPS.ADD_TEMPLATES]: formData.renderingProviders,
    };

    return stepFormDataMap[currentStep].length === 0;
  };

  const handlePreviousClick = () => {
    setCurrentStep(getNextStep(currentStep, true));
  };

  const handleNextClick = () => {
    if (currentStep === STEPS.ADD_TEMPLATES) {
      handleFormSubmit(formData);
      return;
    }

    setCurrentStep(getNextStep(currentStep));
  };

  const renderStep = () => {
    const steps = {
      ADD_PRACTICES: (
        <AddPracticeContainer
          practices={formData.practices}
          updateFormData={(updatedPractices) =>
            updateFormData('practices', updatedPractices)
          }
          onNextClick={handleNextClick}
          onPracticeDelete={(deletedPracticeUuid) => {
            const getDataWithoutRelatedPractice = (data) =>
              data.filter((item) => item.practiceUuid !== deletedPracticeUuid);
            const clinics = getDataWithoutRelatedPractice(formData.clinics);
            const billingProviders = getDataWithoutRelatedPractice(
              formData.billingProviders
            );
            const renderingProviders = getDataWithoutRelatedPractice(
              formData.renderingProviders
            );

            setFormData((prevFormData) => ({
              ...prevFormData,
              clinics,
              billingProviders,
              renderingProviders,
            }));
          }}
        />
      ),
      ADD_CLINICS: (
        <AddClinicContainer
          practices={formData.practices}
          clinics={formData.clinics}
          updateFormData={(updatedClinics) =>
            updateFormData('clinics', updatedClinics)
          }
          onClinicDelete={(deletedClinicUuid) => {
            const renderingProviders = formData.renderingProviders.map(
              (provider) => ({
                ...provider,
                clinicsUuids: provider.clinicsUuids.filter(
                  (uuid) => deletedClinicUuid !== uuid
                ),
              })
            );
            setFormData((prevFormData) => ({
              ...prevFormData,
              renderingProviders,
            }));
          }}
          onNextClick={handleNextClick}
        />
      ),
      ADD_BILLING_PROVIDER: (
        <AddBillingProviderContainer
          practices={formData.practices}
          billingProviders={formData.billingProviders}
          updateFormData={(billingProviders) =>
            updateFormData('billingProviders', billingProviders)
          }
          onBillingProviderDelete={(billingProviderUuid) => {
            const renderingProviders = formData.renderingProviders.map(
              (provider) => ({
                ...provider,
                billingProvidersUuids: provider.billingProvidersUuids.filter(
                  (uuid) => billingProviderUuid !== uuid
                ),
              })
            );

            setFormData((prevFormData) => ({
              ...prevFormData,
              renderingProviders,
            }));
          }}
          onNextClick={handleNextClick}
        />
      ),
      ADD_RENDERING_PROVIDER: (
        <AddRenderingProviderForm
          practices={formData.practices}
          clinics={formData.clinics}
          renderingProviders={formData.renderingProviders}
          billingProviders={formData.billingProviders}
          updateFormData={(renderingProviders) =>
            updateFormData('renderingProviders', renderingProviders)
          }
          onNextClick={handleNextClick}
        />
      ),
      ADD_TEMPLATES: (
        <AddTemplateContainer
          practices={formData.practices}
          clinics={formData.clinics}
          renderingProviders={formData.renderingProviders}
          billingProviders={formData.billingProviders}
          payerIds={formData.payerIds || []}
          templateIds={formData.templateIds}
          onPayerIdsChange={(payerIds) => updateFormData('payerIds', payerIds)}
          onTemplateIdsChange={(templateIds) =>
            updateFormData('templateIds', templateIds)
          }
          onNextClick={handleNextClick}
        />
      ),
    };

    return steps[currentStep];
  };

  if (currentStep === STEPS.SUCCESSFUL_ACCOUNT_CREATION) {
    return (
      <SuccessfulAccountCreation
        practices={formData.practices}
        clinics={formData.clinics}
        billingProviders={formData.billingProviders}
        renderingProviders={formData.renderingProviders}
        payerIds={formData.payerIds}
        templateIds={formData.templateIds}
      />
    );
  }

  return (
    <div className="appealio-body-wrapper">
      <div className="container--lg">
        <Card
          title={CARD_TITLE[currentStep].title}
          description="Build your Appealio Account"
          stepper
          step={CARD_TITLE[currentStep].step}
        />
        <div>{renderStep()}</div>
      </div>
      <div className="navigation-wrapper">
        <div className="container--lg navigation-container">
          <Button
            className="border-none navigation-button"
            onClick={handleNextClick}
            disabled={isCurrentStepDisabled()}
          >
            Next
          </Button>
          {currentStep !== STEPS.ADD_PRACTICES && (
            <Button
              className="ap-button--secondary navigation-button"
              onClick={handlePreviousClick}
            >
              {'< Back'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateAppealioAccountDetails;
