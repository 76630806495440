import React from 'react';

const Settings = (props) => {
  return (
    <div className="ap-container ap-container--full-width">
      <div className="ap-container-content">
        <h4 className="ap-container-title">Settings</h4>
        {props.children}
      </div>
    </div>
  );
};

export default Settings;
