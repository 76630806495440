import {
  OPEN_AVAILITY_CLAIM_STATUS_WIDGET,
  CLOSE_AVAILITY_CLAIM_STATUS_WIDGET,
} from './actionTypes';

export function openAvailityClaimStatusWidget(payload) {
  return {
    type: OPEN_AVAILITY_CLAIM_STATUS_WIDGET,
    payload,
  };
}

export function closeAvailityClaimStatusWidget() {
  return {
    type: CLOSE_AVAILITY_CLAIM_STATUS_WIDGET,
  };
}
