import { snakeCase } from 'lodash';

import {
  fetchAppealioNotifications,
  fetchNotifications,
} from 'API/NotificationAPI';

import {
  SET_NOTIFICATIONS,
  CLICK_NOTIFICATION,
  VIEW_ALL_NOTIFICATIONS,
  RESET_LATEST_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
} from './actionTypes';
import {
  DATE_FORMAT_YYYY_MM_DD,
  getBusinessDaysFromToday,
} from 'helpers/dateFormats';

export const fetchNotificationsRequest = () => ({
  type: FETCH_NOTIFICATIONS_REQUEST,
});

export const fetchNotificationsSuccess = (notificationsData) => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload: notificationsData,
});

export const fetchNotificationsFailure = (error) => ({
  type: FETCH_NOTIFICATIONS_FAILURE,
  payload: error,
});

export const fetchNotificationsInfo = () => async (dispatch) => {
  dispatch(fetchNotificationsRequest());
  const requestData = {
    action: snakeCase('reminderNotification'),
    filters: {
      upcoming_reminders_max_date: getBusinessDaysFromToday(3).format(
        DATE_FORMAT_YYYY_MM_DD
      ),
    },
  };
  try {
    const [notificationsResponse, appealioResponse] = await Promise.all([
      fetchNotifications(requestData),
      fetchAppealioNotifications({
        action: snakeCase('reminderNotification'),
      }),
    ]);

    const combinedData = {
      ...notificationsResponse.data,
      ...appealioResponse.data,
    };

    dispatch(fetchNotificationsSuccess(combinedData));
  } catch (error) {
    dispatch(fetchNotificationsFailure(error));
  }
};
export const viewAllNotifications = () => ({
  type: VIEW_ALL_NOTIFICATIONS,
});

export const setNotifications = (notifications) => ({
  type: SET_NOTIFICATIONS,
  payload: notifications,
});

export const clickNotification = (notificationType) => ({
  type: CLICK_NOTIFICATION,
  payload: notificationType,
});

export const resetLatestNotifications = () => ({
  type: RESET_LATEST_NOTIFICATIONS,
});
