import { useCallback, useEffect, useState } from 'react';

import { fetchSystemOverview } from 'API/DenialsQueueAnalyticsAPI';

/**
 * Use fetch system overview hook.
 * @returns {Promise}
 */
export const useFetchSystemOverview = (filters, practice, secretKey) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetch = useCallback(async () => {
    try {
      const data = await fetchSystemOverview(filters, practice, secretKey);

      setData(data);
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  }, [filters, practice, secretKey]);
  useEffect(() => {
    setLoading(true);
    fetch();
  }, [filters, fetch]);

  return { loading, error, data };
};
