import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import React, { useEffect, useRef, useState } from 'react';

import './style.css';

import { fetchActionData } from 'API/DenialsQueueAnalyticsAPI';

function aggregateActionData(data) {
  const result = data.reduce(
    (acc, { totalCompleted, totalPending, completionRate }) => {
      acc.totalCompletedSum += totalCompleted;
      acc.totalPendingSum += totalPending;
      acc.completionRateSum += completionRate;
      return acc;
    },
    {
      totalCompletedSum: 0,
      totalPendingSum: 0,
      completionRateSum: 0,
    }
  );

  const completionRate = parseFloat(
    (result.completionRateSum / data.length).toFixed(2)
  );

  return {
    action_reason: 'Others',
    totalCompleted: result.totalCompletedSum,
    totalPending: result.totalPendingSum,
    completionRate,
  };
}
const Bar = ({
  actionName,
  actionNames = [],
  backgroundColor,
  width,
  minWidth,
  percentage,
  searchData = [],
  userInfo,
  barKey: id,
  className,
}) => {
  const myRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hoverTimer, setHoverTimer] = useState(null);
  const [actionData, setActionData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (actionData) return setIsLoading(false);

      setIsLoading(true);
      const requestBody = {
        search: [
          ...searchData,
          { key: 'action', value: actionNames || actionName },
        ],
      };
      const response = await fetchActionData(
        requestBody,
        userInfo?.practice,
        userInfo?.secretKey
      );
      const data = actionNames ? aggregateActionData(response) : response[0];
      setActionData(data);
      setIsLoading(false);
    };

    const handleMouseEnter = () => {
      const timer = setTimeout(fetchData, 500);
      setHoverTimer(timer);
    };

    const handleMouseLeave = () => {
      if (hoverTimer) {
        clearTimeout(hoverTimer);
        setHoverTimer(null);
      }
    };

    const currentRef = myRef.current;
    if (currentRef) {
      currentRef.addEventListener('mouseenter', handleMouseEnter);
      currentRef.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('mouseenter', handleMouseEnter);
        currentRef.removeEventListener('mouseleave', handleMouseLeave);
      }
      if (hoverTimer) {
        clearTimeout(hoverTimer);
      }
    };
  }, [actionData, actionName, actionNames, hoverTimer, searchData, userInfo]);

  return (
    <>
      <div
        data-tip
        data-for={id}
        ref={myRef}
        style={{ width, backgroundColor, cursor: 'pointer' }}
        className={className}
      >
        <div
          className={classNames('action-bar-label', {
            'action-bar-label--small': width === minWidth,
          })}
        >
          <span>{parseFloat(percentage.toFixed(1))}%</span>
          {width !== minWidth && <span className="ml-4">{actionName}</span>}
        </div>
        <ReactTooltip
          id={id}
          effect="solid"
          place="top"
          className="tooltip-details-background"
          arrowColor="transparent"
        >
          <div className="tooltip-details">
            <p
              className="tooltip-details__title action-bar-tooltip-title"
              style={{ color: backgroundColor }}
            >
              {actionName}
            </p>
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <>
                <hr className="tooltip-details__line" />
                <div>{actionData?.completionRate}% Completion Rate</div>
                <hr className="tooltip-details__line" />
                <div>{actionData?.totalPending} Active Actions</div>
                <div>{actionData?.totalCompleted} Actions Completed</div>
              </>
            )}
          </div>
        </ReactTooltip>
      </div>
    </>
  );
};

export default Bar;
