import { startCase } from 'lodash';
import { connect } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Field,
  reduxForm,
  initialize,
  SubmissionError,
} from 'redux-form/immutable';

import './style.css';

import Card from 'components/common/card';
import Input from 'components/common/input';
import Button from 'components/common/button';
import Select from 'components/common/select/Select';
import { BUTTON_TYPE } from 'components/common/button/Button';
import AutoFillButton from 'components/CreateAppeal/Submit/AutoFillButton/AutoFillButton';

import {
  fieldCharacterValidation,
  zipCodeValidation,
} from 'helpers/validators';
import { states } from 'components/CreateAppeal/state-mock-data';

const AddClinicForm = (props) => {
  const {
    change,
    practice,
    onSubmit,
    onCancel,
    submitting,
    handleSubmit,
    isUpdateForm = false,
    initialValues,
    isPopupForm = false,
    invalid,
    isAccountSettings = false,
    initialize,
  } = props;
  const [warningMessage, setWarningMessage] = useState('');

  const stateOptions = states.map((state) => ({
    label: state.value,
    value: state.key,
  }));

  const getSelectedStateOption = useCallback(
    (state) => stateOptions.find((option) => option.value === state),
    [stateOptions]
  );

  useEffect(() => {
    if (initialValues) {
      const values = initialValues.toJS();
      const selectedState = getSelectedStateOption(values.state);
      initialize({
        ...values,
        state: selectedState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleFormSubmit = async (formValues) => {
    const values = formValues.toJS();
    validate(formValues, props);
    await onSubmit({
      ...values,
      ...(values.state && { state: values.state.value }),
    });
  };

  const onAutoFillAddressClick = () => {
    change('address1', practice.address1 || '');
    change('address2', practice.address2 || '');
    change('city', practice.city || '');
    change('zipcode', practice.zipcode || '');
    const selectedState = getSelectedStateOption(practice.state);
    change('state', selectedState);

    const isPracticeInfoEmpty = ['address1', 'city', 'zipcode', 'state'].every(
      (key) => !practice[key]
    );

    if (isPracticeInfoEmpty) {
      setWarningMessage('No address information found for Practice.');
    }
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    change(e.target.name, e.target.value);
    setWarningMessage('');
  };

  const handleSelectChange = (value) => {
    change('state', value);
    setWarningMessage('');
  };

  const form = (
    <React.Fragment>
      <div>
        <AutoFillButton
          onClick={onAutoFillAddressClick}
          title="Default to practice address"
        />
        {warningMessage && (
          <div className="mb-12 fw-bold">{warningMessage}</div>
        )}
      </div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="row mb-12">
          <div className="col-lg-3">
            <Field
              component={Input}
              name="name"
              label="Clinic Name *"
              placeholder="Name"
            />
          </div>

          <div className="col-lg-3">
            <Field
              component={Input}
              name="address1"
              label={`Address Line 1 ${isAccountSettings ? '' : '*'}`}
              placeholder="Address Line 1"
              maxLength={50}
              onChange={handleInputChange}
            />
          </div>

          <div className="col-lg-3">
            <Field
              component={Input}
              name="address2"
              label="Address Line 2"
              placeholder="Address Line 2"
              onChange={handleInputChange}
              maxLength={50}
            />
          </div>
        </div>
        <div className="row mb-12">
          <div className="col-lg-3">
            <Field
              component={Input}
              name="city"
              label={`City ${isAccountSettings ? '' : '*'}`}
              placeholder="City"
              onChange={handleInputChange}
              maxLength={50}
            />
          </div>
          <div className="col-lg-3">
            <Field
              component={Select}
              options={stateOptions}
              name="state"
              defaultValue={null}
              label={`State ${isAccountSettings ? '' : '*'}`}
              onChange={handleSelectChange}
              placeholder="State"
            />
          </div>

          <div className="col-lg-3">
            <Field
              component={Input}
              name="zipcode"
              label={`Zip ${isAccountSettings ? '' : '*'}`}
              onChange={handleInputChange}
              placeholder="Zip Code"
            />
          </div>
        </div>
        {isPopupForm ? (
          <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
            <button
              type="submit"
              disabled={invalid || submitting}
              className="ap-button ap-button--primary-dark ap-button--primary-md"
            >
              {props.initialValues ? 'Update' : 'Add'}{' '}
            </button>
          </div>
        ) : (
          <div className="d-flex justify-content-flex-end pr-15 form-action-button-wrapper">
            <Button
              title={isUpdateForm ? 'Update' : 'Save'}
              className="ap-button--secondary justify-content-center mr-8"
              disabled={invalid || submitting}
            />
            <Button
              title="Cancel"
              type={BUTTON_TYPE.LIGHT}
              className=" justify-content-center fw-normal"
              disabled={submitting}
              onClick={onCancel}
            />
          </div>
        )}
      </form>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {isPopupForm ? form : <Card className="p-24">form</Card>}
    </React.Fragment>
  );
};

function validate(values, props) {
  const formValues = values.toJS();
  const nonEmptyValues = props.isAccountSettings
    ? ['name']
    : ['name', 'address1', 'city', 'state', 'zipcode'];

  const errors = {};

  nonEmptyValues.forEach((key) => {
    const isEmptyValue = !formValues[key];
    if (!isEmptyValue) {
      delete errors[key];
      return;
    }

    errors[key] =
      !formValues[key] && key === 'address1'
        ? 'Address Line 1 is required'
        : `${startCase(key)} is required`;
  });
  if (!errors.name && formValues.name) {
    const clinicNameValidation = fieldCharacterValidation(
      'Clinic Name',
      formValues.name
    );
    if (clinicNameValidation) errors.name = clinicNameValidation;
  }

  if (!errors.zipcode && formValues.zipcode) {
    const zipValidation = zipCodeValidation(formValues.zipcode);
    if (zipValidation) errors.zipcode = zipValidation;
  }
  if (Object.keys(errors).length) {
    throw new SubmissionError(errors);
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => ({
    initialize,
  })
)(
  reduxForm({
    form: 'add-clinic-form',
  })(AddClinicForm)
);
