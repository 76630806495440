import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CheckBox = ({
  className = '',
  title = '',
  checked = true,
  disabled = false,
  titleClassName = '',
  onChange = () => {},
  input = {},
}) => {
  const handleOnChange = (e) => {
    onChange(e.target.checked);
    input?.onChange(e);
  };
  const isChecked = input ? input?.value : checked;
  return (
    <label
      className={classnames('checkbox-container', {
        [className]: className,
      })}
      datacy="checkbox"
    >
      <span className={titleClassName}>{title}</span>
      <input
        disabled={disabled}
        onChange={handleOnChange}
        type="checkbox"
        checked={isChecked}
        datacy="checkbox-input"
        {...input}
      />
      <span className="checkmark"></span>
    </label>
  );
};

CheckBox.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CheckBox;
