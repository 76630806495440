import { camelizeKeys } from '../helpers/object';
import * as endpoints from '../constants/endpoints';
import {
  handleErrors,
  handleJSONParse,
  createGetRequest,
  BASE_API_URL,
} from './Config';

export const fetchTransactionSummary = (params) => {
  const url = endpoints.APPEALIO_EXPRESS_TRANSACTION_DETAILS;
  const request = createGetRequest(
    url,
    {
      ...params,
    },
    BASE_API_URL
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};
