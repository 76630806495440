import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
} from 'redux-form/immutable';

import Input from 'components/common/input';
import Button from 'components/common/button';
import { BUTTON_TYPE } from 'components/common/button/Button';

import { validateProviderTaxId, validateNPI } from 'helpers/validators';
import {
  MASK_INDIVIDUAL_TAX_ID,
  MASK_ORGANIZATION_TAX_ID,
  MASK_PROVIDER_NPI,
} from 'helpers/masks';
import {
  INDIVIDUAL_TYPE_PROVIDER,
  ORGANIZATION_TYPE_PROVIDER,
} from 'API/CreateAppealAPI';

const BILLING_PROVIDER_TYPES = {
  INDIVIDUAL: INDIVIDUAL_TYPE_PROVIDER,
  ORGANIZATION: ORGANIZATION_TYPE_PROVIDER,
};

let AddRenderingProviderForm = (props) => {
  const {
    onSubmit,
    onCancel,
    submitting,
    handleSubmit,
    isUpdateForm = false,
    initialValues,
    isPopupForm = false,
  } = props;

  const [billingProviderType, setBillingProviderType] = useState(
    BILLING_PROVIDER_TYPES.INDIVIDUAL
  );

  useEffect(() => {
    if (initialValues) {
      const values = initialValues.toJS();
      if (values.organizationName) {
        setBillingProviderType(BILLING_PROVIDER_TYPES.ORGANIZATION);
      }
    }
  }, [initialValues]);

  const validate = (formValues) => {
    const errors = {
      firstName: 'First name is required',
      lastName: 'Last name is required',
      organizationName: 'Organization name is required',
      taxId: 'Tax ID is required',
      npi: 'NPI is required',
    };

    if (
      !formValues.organizationName &&
      !formValues.firstName &&
      !formValues.lastName &&
      !formValues.taxId &&
      !formValues.npi
    ) {
      throw new SubmissionError(errors);
    }

    if (billingProviderType === BILLING_PROVIDER_TYPES.INDIVIDUAL) {
      delete errors.organizationName;
      if (formValues.firstName) {
        delete errors.firstName;
      }
      if (formValues.lastName) {
        delete errors.lastName;
      }
    }

    if (billingProviderType === BILLING_PROVIDER_TYPES.ORGANIZATION) {
      delete errors.firstName;
      delete errors.lastName;
      if (formValues.organizationName) {
        delete errors.organizationName;
      }
    }

    if (formValues.npi) {
      const errorNpiMessage = validateNPI(formValues.npi);
      if (errorNpiMessage) {
        errors.npi = errorNpiMessage;
      } else {
        delete errors.npi;
      }
    }

    if (formValues.taxId) {
      delete errors.taxId;
    }

    if (Object.keys(errors).length > 0) {
      throw new SubmissionError(errors);
    }
  };
  const handleFormSubmit = async (formValues) => {
    const values = formValues.toJS();
    values.providerType = billingProviderType;

    if (billingProviderType === BILLING_PROVIDER_TYPES.ORGANIZATION) {
      values.firstName = '';
      values.lastName = '';
    } else {
      values.organizationName = '';
    }
    validate(values);
    if (values.taxId) {
      const errorMessage = validateProviderTaxId(
        values.taxId,
        billingProviderType === BILLING_PROVIDER_TYPES.ORGANIZATION
      );
      if (errorMessage) {
        throw new SubmissionError({
          taxId: errorMessage,
        });
      }
    }

    await onSubmit(values);
  };

  const onProviderTypeChange = (e) => {
    props.change('taxId', '');
    setBillingProviderType(+e.target.value);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="row mb-12"></div>
        <div>
          <div>Billing Provider Type</div>
          <div className="radio-wrapper">
            <div className="d-flex justify-content--space-between align-item-center">
              <div>
                <span className="mr-44">
                  <label>
                    <input
                      className="mr-8"
                      type="radio"
                      checked={
                        billingProviderType ===
                        BILLING_PROVIDER_TYPES.INDIVIDUAL
                      }
                      onChange={onProviderTypeChange}
                      name={BILLING_PROVIDER_TYPES.INDIVIDUAL}
                      value={BILLING_PROVIDER_TYPES.INDIVIDUAL}
                    />
                    Individual
                  </label>
                </span>
                <span>
                  <label>
                    <input
                      type="radio"
                      className="mr-8"
                      checked={
                        billingProviderType ===
                        BILLING_PROVIDER_TYPES.ORGANIZATION
                      }
                      onChange={onProviderTypeChange}
                      name={BILLING_PROVIDER_TYPES.ORGANIZATION}
                      value={BILLING_PROVIDER_TYPES.ORGANIZATION}
                    />
                    Organization
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>

        {billingProviderType === BILLING_PROVIDER_TYPES.INDIVIDUAL ? (
          <div className="row mb-12">
            <div className="col-lg-4">
              <Field
                component={Input}
                name="firstName"
                label="First Name *"
                placeholder="John"
                maxLength={100}
              />
            </div>

            <div className="col-lg-4">
              <Field
                component={Input}
                name="lastName"
                label="Last Name *"
                placeholder="Doe"
                maxLength={100}
              />
            </div>
          </div>
        ) : (
          <div className="row mb-12">
            <div className="col-lg-4">
              <Field
                component={Input}
                name="organizationName"
                label="Organization Name *"
                placeholder="Organization Name"
                maxLength={200}
              />
            </div>
          </div>
        )}
        <div className="row mb-12">
          <div className="col-lg-4">
            <Field
              component={Input}
              name="npi"
              label="NPI *"
              placeholder="NPI"
              className="mr-4"
              maskedInput={true}
              mask={MASK_PROVIDER_NPI}
            />
          </div>
          <div className="col-lg-4">
            <Field
              component={Input}
              name="taxId"
              label="Tax ID *"
              placeholder={
                billingProviderType === BILLING_PROVIDER_TYPES.INDIVIDUAL
                  ? '___-__-____'
                  : '__-_______'
              }
              maskedInput={true}
              mask={
                billingProviderType === BILLING_PROVIDER_TYPES.INDIVIDUAL
                  ? MASK_INDIVIDUAL_TAX_ID
                  : MASK_ORGANIZATION_TAX_ID
              }
            />
          </div>
        </div>
        {isPopupForm ? (
          <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
            <button
              type="submit"
              disabled={props.submitting}
              className="ap-button ap-button--primary-dark ap-button--primary-md"
            >
              {props.initialValues ? 'Update' : 'Add'}{' '}
            </button>
          </div>
        ) : (
          <div className="d-flex justify-content-flex-end pr-15 form-action-button-wrapper">
            <Button
              title={isUpdateForm ? 'Update' : 'Save'}
              className="ap-button ap-button--secondary justify-content-center mr-8"
              disabled={submitting}
            />
            <Button
              title="Cancel"
              type={BUTTON_TYPE.LIGHT}
              className="justify-content-center fw-normal"
              disabled={submitting}
              onClick={onCancel}
            />
          </div>
        )}
      </form>
    </div>
  );
};

const FORM_NAME = 'add-billing-provider-form';

AddRenderingProviderForm = reduxForm({
  form: FORM_NAME,
})(AddRenderingProviderForm);

const selector = formValueSelector(FORM_NAME);
export default connect(
  (state) => {
    const npi = selector(state, 'npi');

    return {
      npi,
    };
  },
  () => ({})
)(AddRenderingProviderForm);
