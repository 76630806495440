import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class ViewAppealDetailsButton extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div
        className="detail_view_button__wrapper"
        datacy="view-appeal-details-button"
      >
        <span onClick={this.props.onClick} className="detail_view_button">
          View Appeal Details
        </span>
      </div>
    );
  }
}
