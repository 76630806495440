import React from 'react';

import Card from 'components/common/card';
import Button from 'components/common/button';
import * as toast from 'components/Shared/toast';

import AuthAPI from 'API/AuthAPI';
import { handleError } from 'helpers/errorHandler';

const ResendEmailConfirmation = ({ email }) => {
  const onSendVerificationEmailClick = async () => {
    try {
      await AuthAPI.sendForgotPasswordEmail(email, true);
      toast.success({
        title: '',
        message: 'Verification email sent.',
      });
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Card>
      <div className="row form-info-row">
        <div className="col-sm-12">
          <div className="form-section-title mb-8">
            Thank you for creating your Appealio Account.
          </div>
          <div className="form-section-description">
            Please check your email to <u>activate</u> you account. From there,
            you will be automatically <u>redirected</u> to setup your account.
          </div>
        </div>
      </div>
      <hr className="card-horizontal-line" />
      <Button
        type="secondary"
        title="Resend Verification Email"
        onClick={onSendVerificationEmailClick}
      />
    </Card>
  );
};

export default ResendEmailConfirmation;
