import Lottie from 'react-lottie';
import React, { useEffect, useState } from 'react';

import errorAnimation from './errorAnimation.json';
import claimFetchingAnimation from './claimFetchingAnimation.json';

import './style.css';

import { fetchClaimStatusViaUHC } from 'API/PayerAPI';

import AppealioPopup from 'components/Shared/AppealioPopup';
import Button from 'components/common/button';
import SuccessModal from './SuccessModal';

const UhcClaimStatusCheck = (props) => {
  const {
    onCloseClick,
    onClaimSearchViaRpaClick,
    isClaimSearchViaRpaEnabled = false,
    billingProviderName = '',
    renderingProviderName = '',
  } = props;

  const [isFetchingStatus, setIsFetchingStatus] = useState(true);
  const [claimInfo, setClaimInfo] = useState(null);
  const [error, setError] = useState(null);

  // Set a timeout used to handle close after outside click check for global search popup
  const handleCloseClick = () => {
    setTimeout(() => onCloseClick(), 200);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetchingStatus(true);
        const data = await fetchClaimStatusViaUHC(
          props.claimControlNumber,
          props.billingProviderTaxId,
          props.ediPayerId,
          props.claimNumber
        );
        setClaimInfo({
          ...data,
          claimNumber: props.claimControlNumber,
        });
        if (!data) {
          setError(
            'Direct claim status check is not available for this claim at this time.'
          );
          setClaimInfo(false);
        }
        setIsFetchingStatus(false);
      } catch (error) {
        setError(
          'Direct claim status check is not available for this claim at this time.'
        );
        setIsFetchingStatus(false);
      }
    };

    fetchData();
  }, [
    props.claimControlNumber,
    props.billingProviderTaxId,
    props.ediPayerId,
    props.claimNumber,
  ]);

  if (isFetchingStatus || error || claimInfo === false) {
    let animation = claimFetchingAnimation;

    if (error) {
      animation = errorAnimation;
    } else if (claimInfo === false) {
      animation = errorAnimation;
    }
    return (
      <AppealioPopup
        title="Claim Status Check"
        onClosePressed={handleCloseClick}
        className="uhc-claim-status-check claim-status-detail-popup"
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animation,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={250}
          width={250}
        />
        {error && <div className="uhc-claim-status-check__error">{error}</div>}
        {error && isClaimSearchViaRpaEnabled && (
          <div
            onClick={onClaimSearchViaRpaClick}
            className="d-flex justify-content-center"
          >
            <Button>Claim Status Check Via Payer Portal</Button>
          </div>
        )}
        {error && (
          <div className="d-flex justify-content-flex-end">
            <Button
              type="light"
              onClick={() => {
                handleCloseClick();
              }}
            >
              Close
            </Button>
          </div>
        )}
      </AppealioPopup>
    );
  }

  return (
    <SuccessModal
      claimInfo={claimInfo}
      setClaimInfo={setClaimInfo}
      onCloseClick={handleCloseClick}
      appealioInfo={{
        billingProviderName,
        renderingProviderName,
        claimNumber: props.claimControlNumber,
        ediPayerId: props.ediPayerId,
        taxId: props.billingProviderTaxId,
      }}
    />
  );
};

UhcClaimStatusCheck.propTypes = {};

export default UhcClaimStatusCheck;
