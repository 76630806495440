import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';

import FlagIconPlain from 'img/flag-plain.svg';
import FlagIconFilled from 'img/flag-filled.svg';

import './style.css';

import { getUserInfo } from 'redux/reducers/loginStore';
import { isUserAuthorizedForClaimLineFlag } from 'Auth/FeatureFlags';

const FlagCheck = (props) => {
  const {
    disabled,
    onChange,
    input = {},
    dataTip = '',
    isAuthorizedForClaimLineFlag,
    ...rest
  } = props;

  if (!isAuthorizedForClaimLineFlag) {
    return null;
  }

  return (
    <label
      className={classnames('flag-check-label', {
        'flag-check-label--disabled': disabled,
      })}
    >
      <input
        className="checkbox-option__input"
        type="checkbox"
        onChange={onChange}
        disabled={disabled}
        {...input}
        {...rest}
      />
      {input?.value ? (
        <img
          className="flag-check-label__img"
          src={FlagIconFilled}
          alt="flag-filled"
        />
      ) : (
        <img
          className="flag-check-label__img"
          src={FlagIconPlain}
          alt="flag-plain"
          data-html="true"
          data-tip={ReactDOMServer.renderToStaticMarkup(dataTip)}
        />
      )}
      <ReactTooltip
        effect="solid"
        place="top"
        multiline={true}
        className="reminder-icon-tooltip flag-check-tooltip white-space--pre-wrap fw-normal"
      />
    </label>
  );
};
FlagCheck.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  input: PropTypes.object,
};

const mapStateToProps = (state) => {
  const userInfo = getUserInfo(state);
  if (!userInfo) {
    return {};
  }

  const isAuthorizedForClaimLineFlag =
    isUserAuthorizedForClaimLineFlag(userInfo);

  return {
    isAuthorizedForClaimLineFlag,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FlagCheck);
