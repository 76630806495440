import { pickBy, identity } from 'lodash';
import * as endpoints from 'constants/endpoints';
import { camelizeKeys, snakeCaseKeys } from '../helpers/object';

import {
  handleErrors,
  handleJSONParse,
  INTEGRATION_API_BASE_URL,
  extractIntegrationAPIHeaders,
  createGetRequest,
  createRequest as createPostRequest,
} from './Config';

import { SORT_BY } from 'components/Analytics/DenialsQueueAnalytics/RulesOverview/constants';

export const fetchRulesOverview = ({
  queryParams,
  sortBy,
  search,
  userSecretKey,
  userPracticeId,
}) => {
  const nonEmptyParams = pickBy(queryParams, identity);
  const requestBody = { ...(search && { search }) };
  const requestHeaders = extractIntegrationAPIHeaders({
    secretKey: userSecretKey,
    practiceId: userPracticeId,
  });
  const url =
    sortBy === SORT_BY.RULES
      ? endpoints.ANALYTICS_PERFORMANCE_DETAILS_RULES
      : `${endpoints.ANALYTICS_PERFORMANCE_DETAILS}${sortBy}`;
  const request = createPostRequest(url, requestBody, {
    baseUrl: INTEGRATION_API_BASE_URL,
    headers: requestHeaders,
    params: { ...snakeCaseKeys(nonEmptyParams) },
  });

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => camelizeKeys(res.data));
};

export const fetchSystemOverview = (requestBody, practiceId, secretKey) => {
  const requestHeaders = extractIntegrationAPIHeaders({
    secretKey,
    practiceId,
  });
  const request = createPostRequest(
    endpoints.ANALYTICS_SYSTEM_OVERVIEW,
    requestBody,
    {
      baseUrl: INTEGRATION_API_BASE_URL,
      headers: requestHeaders,
    }
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => camelizeKeys(res.data));
};

export const fetchRuleDetails = async (queryParam) => {
  const url = endpoints.RULES;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    INTEGRATION_API_BASE_URL,
    true
  );

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const fetchActionData = (requestBody, practiceId, secretKey) => {
  const requestHeaders = extractIntegrationAPIHeaders({
    secretKey,
    practiceId,
  });
  const request = createPostRequest(
    endpoints.ANALYTICS_PERFORMANCE_DETAILS_ACTION_BREAKDOWN,
    requestBody,
    {
      baseUrl: INTEGRATION_API_BASE_URL,
      headers: requestHeaders,
    }
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => camelizeKeys(res.data));
};
