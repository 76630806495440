import { validateFaxNumber, validatePhoneNumber } from 'helpers/validators';

const validateExists = (value) => (value ? null : 'Required');

export const validateFaxForm = (values) => {
  const errors = {};
  const displayErrors = [];

  const faxError = validateExists(values.get('faxNumber'));
  if (faxError) {
    errors.faxNumber = faxError;
    displayErrors.push({ message: 'Fax Number is required' });
  } else {
    const faxValidationError = validateFaxNumber(values.get('faxNumber'));
    if (faxValidationError) {
      errors.faxNumber = faxValidationError;
      displayErrors.push({ message: faxValidationError });
    }
  }

  const requiredFields = [
    { field: 'from', label: 'From' },
    { field: 'to', label: 'To' },
    { field: 'phone', label: 'Phone' },
  ];

  requiredFields.forEach(({ field, label }) => {
    errors[field] = validateExists(values.get(field));
    if (errors[field]) {
      const message = `${label} is required`;
      errors[field] = message;
      displayErrors.push({ message });
    }
  });

  const phoneError = validatePhoneNumber(values.get('phone'));
  if (phoneError) {
    errors.phone = phoneError;
    displayErrors.push({ message: phoneError });
  }

  if (displayErrors.length > 0) {
    errors.displayErrors = displayErrors;
  }

  return errors;
};

export const formatFaxFormValues = (values) => {
  const formattedValues = {
    cover_letter_cc: values.get('cc') || '',
    cover_letter_phone: values.get('phone') || '',
    cover_letter_re: values.get('re') || '',
    cover_letter_text: values.get('coverLetterText') || '',
    delivery_options: {
      cover_letter: 1,
      fax_from: values.get('from') || '',
      fax_to: values.get('to') || '',
    },
    fax: values.get('faxNumber') || '',
    fax_from: values.get('from') || '',
    fax_to: values.get('to') || '',
  };

  return formattedValues;
};
