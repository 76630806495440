import { useCallback, useEffect, useState } from 'react';

import {
  fetchPracticeGroup,
  fetchPracticeGroups,
} from 'API/AccountSettingsAPI';

/**
 * Custom hook to fetch practice groups.
 *
 * @param {number} page - The page number to fetch.
 * @returns {Object} - An object containing loading state, error, fetch function, total practice groups, and practice groups data.
 */
export const useFetchPracticeGroups = (page) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalPracticeGroups, setTotalPracticeGroups] = useState(0);
  const [practiceGroups, setPracticeGroups] = useState([]);

  /**
   * Fetches practice groups data.
   */
  const fetchGroups = useCallback(async () => {
    setLoading(true);
    setPracticeGroups([]);
    setTotalPracticeGroups(0);

    try {
      const queryParam = page ? { page } : {};
      queryParam.few = 1;

      const { data, meta } = await fetchPracticeGroups(queryParam);
      setPracticeGroups(data);
      setTotalPracticeGroups(meta.total);
    } catch (fetchError) {
      setError(fetchError);
    } finally {
      setLoading(false);
    }
  }, [page]);

  useEffect(() => {
    fetchGroups();
  }, [page, fetchGroups]);

  return {
    loading,
    error,
    fetch: fetchGroups,
    totalPracticeGroups,
    practiceGroups,
  };
};

/**
 * Custom hook to fetch a single practice group by its ID.
 *
 * @param {string|number} id - The ID of the practice group to fetch.
 * @returns {Object} - An object containing loading state, error, fetch function, and practice group data.
 */
export const useFetchPracticeGroup = (id) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [practiceGroup, setPracticeGroup] = useState(null);

  /**
   * Fetches a single practice group data by its ID.
   */
  const fetchGroupById = useCallback(async () => {
    setLoading(true);
    setPracticeGroup(null);

    try {
      const data = await fetchPracticeGroup(id);
      setPracticeGroup(data);
    } catch (fetchError) {
      setError(fetchError);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchGroupById();
  }, [id, fetchGroupById]);

  return { loading, error, fetch: fetchGroupById, practiceGroup };
};
