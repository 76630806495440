import React from 'react';
import PropTypes from 'prop-types';

import Contacts from './Contacts';
import { CONTACT_TYPE } from 'constants/appConstants';

function ReturnContacts(props) {
  return <Contacts type={CONTACT_TYPE.RETURN_ADDRESS} />;
}

ReturnContacts.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ReturnContacts;
