import { omit, isEqual } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';

import Button from 'components/common/button';
import Dropdown from 'components/Shared/Dropdown';
import { BUTTON_TYPE } from 'components/common/button/Button';

import closeIcon from 'img/close-modal.svg';
import settingsIcon from 'img/settings-black.svg';

import { DEFAULT_FILTERS } from './StatusTracker';
import StatusTrackerCheckBox from './StatusTrackerCheckBox';

const StatusTrackerPopupFilter = (props) => {
  const { setShowFilters, filters, onFilterChange, filterOptions } = props;
  const filterKeysToOmit = ['search', 'status'];
  const [statusTrackerFilters, setStatusTrackerFilters] = useState(filters);

  const isFilterEmpty = Object.values(
    omit(statusTrackerFilters, filterKeysToOmit)
  ).every((x) => x === null || x === '' || x === undefined);

  const filtersWithoutOmittedKeys = (filters) =>
    omit(filters, filterKeysToOmit);
  const actionFiltersWithoutOmittedKeys = filtersWithoutOmittedKeys(filters);
  const StatusTrackerPopupFiltersWithoutOmittedKeys =
    filtersWithoutOmittedKeys(statusTrackerFilters);
  const isApplyFilterDisabled = isEqual(
    Object.values(StatusTrackerPopupFiltersWithoutOmittedKeys),
    Object.values(actionFiltersWithoutOmittedKeys)
  );

  const handleConfirmFilters = () => {
    onFilterChange({ ...statusTrackerFilters });
    setShowFilters(false);
  };

  const handleDropdownChange = (selectedOption, filterKey) => {
    setStatusTrackerFilters({
      ...statusTrackerFilters,
      [filterKey]: selectedOption.value,
    });
  };

  return (
    <ScreenDimmerComponent>
      <div className="appealio-popup appealio-popup-filter">
        <div className="appealio-popup-filter__header">
          <div className="filter__header-title">
            <img src={settingsIcon} alt={'More filters'} />
            <h6>More Filters </h6>
          </div>
          <div className="appealio-popup-filter__clear-wrapper">
            <div className="filter__header--reset-option">
              {!isFilterEmpty && (
                <p
                  className="denial-table__filter-clear text-decoration-underline"
                  onClick={() => {
                    onFilterChange({
                      ...DEFAULT_FILTERS,
                      appliedRule: filters.appliedRule,
                      search: filters.search,
                      removalReason: filters.removalReason,
                    });
                    setShowFilters(false);
                  }}
                >
                  Reset All
                </p>
              )}
            </div>
            <img
              alt="Close"
              onClick={() => setShowFilters(false)}
              className="close-icon"
              src={closeIcon}
            />
          </div>
        </div>

        <div className="d-flex mt-12 mb-12">
          <StatusTrackerCheckBox
            title="Claim Status Changes"
            isChecked={statusTrackerFilters.onlyRecentChange}
            onCheck={() => {
              setStatusTrackerFilters({
                ...statusTrackerFilters,
                onlyRecentChange: !statusTrackerFilters.onlyRecentChange,
              });
            }}
            hasDefaultBorder
            className="mr-12"
          />
          <StatusTrackerCheckBox
            title="Scheduled Checks"
            isChecked={statusTrackerFilters.onlyScheduledChecks}
            className="ml-2"
            onCheck={() => {
              setStatusTrackerFilters({
                ...statusTrackerFilters,
                onlyScheduledChecks: !statusTrackerFilters.onlyScheduledChecks,
              });
            }}
            hasDefaultBorder
          />
        </div>

        <div className="row mb-12 appealio-popup-filter__options">
          <div className="col-md-6 mb-12">
            <Dropdown
              options={filterOptions.payers}
              onChange={(selectedOption) =>
                handleDropdownChange(selectedOption, 'payerId')
              }
              label="Payer"
              placeholder="All"
              value={filterOptions.payers.find(
                (option) => option.value === statusTrackerFilters.payerId
              )}
              datacy="filters-Dropdown"
            />
          </div>
          <div className="col-md-6 mb-12">
            <Dropdown
              options={filterOptions.providers}
              onChange={(selectedOption) =>
                handleDropdownChange(selectedOption, 'providerId')
              }
              label="Provider"
              placeholder="All"
              value={filterOptions.providers.find(
                (option) => option.value === statusTrackerFilters.providerId
              )}
              datacy="filters-Dropdown"
            />
          </div>
        </div>

        <div className="appealio-popup-filter-buttons">
          <Button
            title="Apply"
            type={BUTTON_TYPE.SECONDARY}
            className="ap-button  ap-button--block"
            onClick={handleConfirmFilters}
            datacy="dataCy-confirm-filters"
            disabled={isApplyFilterDisabled}
          />

          <button
            className="ap-button ap-button--tertiary-outline ap-button--block border-none"
            onClick={() => {
              onFilterChange({
                ...DEFAULT_FILTERS,
                search: filters.search,
                status: filters.status,
              });
              setShowFilters(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </ScreenDimmerComponent>
  );
};

StatusTrackerPopupFilter.propTypes = {
  dropDownFilters: PropTypes.array,
  setShowFilters: PropTypes.func,
  filters: PropTypes.object,
  onFilterChange: PropTypes.func,
  filterOptions: PropTypes.object,
};

export default StatusTrackerPopupFilter;
