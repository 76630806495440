export const RPA_WIDGET_TYPE = Object.freeze({
  CLAIM_STATUS_WIDGET_UHC: 'CLAIM_STATUS_WIDGET_UHC',
  CLAIM_STATUS_WIDGET_AVAILITY: 'CLAIM_STATUS_WIDGET_AVAILITY',
  SELECT_PORTAL_WIDGET: 'SELECT_PORTAL_WIDGET',
});

export const RPA_MODULES = Object.freeze({
  UHC: 'UHC',
  AVAILITY: 'AVAILITY',
});

export const RPA_PORTAL_ACTIONS = Object.freeze({
  CLAIM_STATUS_CHECK: 'CLAIM_STATUS_CHECK',
});

export const RPA_PORTAL_ACTION_LABELS = Object.freeze({
  [RPA_PORTAL_ACTIONS.CLAIM_STATUS_CHECK]: 'Claim Status Check',
});
