import React from 'react';
import { connect } from 'react-redux';

import AddTemplateForm from './AddTemplateForm';
import AppealioPopup from 'components/Shared/AppealioPopup';

import { getUserInfo } from 'redux/reducers/loginStore';

const AddTemplate = (props) => {
  const {
    onClose,
    title,
    contentType,
    onSuccess,
    datacy = 'add-template',
    selectedPayerId = null,
  } = props;
  return (
    <React.Fragment>
      <AppealioPopup
        title={title}
        className="appeal-template-popup import-rule-popup appealio-popup--md"
        onClosePressed={onClose}
      >
        <AddTemplateForm
          userInfo={props.userInfo}
          onSuccess={onSuccess}
          onClose={onClose}
          contentType={contentType}
          datacy={datacy}
          selectedPayerId={selectedPayerId}
        />
      </AppealioPopup>
    </React.Fragment>
  );
};

export default connect((state) => {
  const userInfo = getUserInfo(state);

  return {
    userInfo,
  };
}, {})(AddTemplate);
