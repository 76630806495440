import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field, FieldArray } from 'redux-form/immutable';

import './style.css';

import { USER_ROLE_OPTIONS } from 'constants/options';

import Button from 'components/common/button';
import TextArea from 'components/common/textarea';
import Select from 'components/common/select/Select';
import LoadingIndicator from 'components/Shared/LoadingIndicator';

import binImage from 'img/bin-grey.svg';

const SOP_INSTRUCTIONS_CHARACTERS_LIMIT = 255;

const validateSelectRequired = (value) => {
  const message = 'This field is required';
  if (!value) {
    return message;
  }
  if ((value.toJS && value.toJS().length === 0) || value.length === 0) {
    return message;
  }
  return undefined;
};

const RenderSteps = ({
  fields,
  meta: { touched, error, submitFailed },
  actionList,
  isActionListLoading,
  sopId,
  deleteSopStep,
  setSopDeleteAlert,
  deleteSopForRule,
  setShowProposedPopup,
  enableEditForm,
}) => {
  const handleDeleteStep = (index, sopId) => {
    const stepId = fields.get(index)?.get('id');
    const deleteSopAndSteps = () => {
      deleteSopStep(stepId);
      deleteSopForRule();
    };

    if (sopId && stepId) {
      if (fields.length === 1) {
        setSopDeleteAlert({
          isOpen: true,
          message:
            'This is the only remaining step for your SOP. Deleting this will remove the entire SOP. Do you want to proceed?',
          deleteFunc: deleteSopAndSteps,
        });
      } else {
        deleteSopStep(stepId);
        fields.remove(index);
      }
    } else {
      fields.remove(index);
    }
  };

  if (!sopId && fields.length < 1) {
    fields.push(Map({}));
  }

  return (
    <>
      <ul
        className={classnames('sop-steps-list', {
          'sop-steps-list--disabled': !enableEditForm,
        })}
      >
        <>
          <div
            className={classnames(
              'row sop-steps-list__header no-gutter mb-16',
              {
                'mt-16': sopId,
                'mt-8': !sopId,
              }
            )}
          >
            <div className="col-md-4 ml-32">
              <span className="ml-16 mr-8">Proposed Action</span>
              {enableEditForm && (
                <span
                  className="link-url text-decoration-underline fs-12 cursor-pointer"
                  onClick={() => setShowProposedPopup(true)}
                >
                  (+ Add New)
                </span>
              )}
            </div>
            <div className="col-md-3">
              <span className="ml-24">Role</span>
            </div>
            <div className="col-md-5">Instructions</div>
          </div>
          {fields.map((step, index) => (
            <li key={index}>
              <div className="row no-gutter d-flex align-item-flex-start">
                <div className="col-md-4">
                  <div className="d-flex">
                    <span className="mr-16 mt-8 width-10">{index + 1}.</span>
                    <Field
                      component={Select}
                      classNamePrefix="sop-steps-select"
                      options={actionList}
                      name={`${step}.action`}
                      placeholder="Select an Action"
                      required={true}
                      isLoading={isActionListLoading}
                      validate={validateSelectRequired}
                      reactSelectProps={{
                        maxMenuHeight: 140,
                      }}
                      showTooltipOnSelected={true}
                      isDisabled={!enableEditForm}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex justify-content--space-between">
                    <span className="mr-8 ml-8 mt-8">by</span>
                    <Field
                      component={Select}
                      options={USER_ROLE_OPTIONS}
                      name={`${step}.role`}
                      defaultValue={null}
                      placeholder="Select Role"
                      validate={validateSelectRequired}
                      isDisabled={!enableEditForm}
                      classNamePrefix="sop-steps-select"
                    />
                  </div>
                </div>
                <div className="col-md-5 ml-16">
                  <div className="d-flex align-items-center justify-content--space-between">
                    <Field
                      component={TextArea}
                      name={`${step}.instruction`}
                      resize={false}
                      rows="3"
                      styles={{
                        minWidth: '308px',
                        resize: 'none',
                      }}
                      max={SOP_INSTRUCTIONS_CHARACTERS_LIMIT}
                      maxLength={SOP_INSTRUCTIONS_CHARACTERS_LIMIT}
                      placeholder={
                        !enableEditForm ? ' ' : 'Enter instructions here'
                      }
                      readOnly={!enableEditForm}
                      className="sop-steps-textarea"
                    />
                    {enableEditForm && (
                      <img
                        src={binImage}
                        alt="bin"
                        className="cursor-pointer ml-4 mr-8"
                        onClick={() => handleDeleteStep(index, sopId)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </>
        {enableEditForm && (
          <li className="sop-add-step-button-wrapper">
            <Button
              title="Add Step"
              className="width-120 ap-button--secondary justify-content-center"
              datacy="add-step-Button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                fields.push(Map({}));
              }}
            />
            {(touched || submitFailed) && error && <span>{error}</span>}
          </li>
        )}
      </ul>
    </>
  );
};

const AddOrUpdateSopStepsForm = ({
  isFetchingSop = false,
  sopId,
  deleteSopForRule,
  deleteSopStep,
  setSopDeleteAlert,
  setShowProposedPopup,
  actionList,
  isActionListLoading,
  enableEditForm = false,
}) => {
  if (isFetchingSop) {
    return <LoadingIndicator showing={true} />;
  }

  return (
    <FieldArray
      name="steps"
      component={RenderSteps}
      actionList={actionList}
      isActionListLoading={isActionListLoading}
      deleteSopStep={deleteSopStep}
      sopId={sopId}
      setSopDeleteAlert={setSopDeleteAlert}
      deleteSopForRule={deleteSopForRule}
      setShowProposedPopup={setShowProposedPopup}
      enableEditForm={enableEditForm}
    />
  );
};

AddOrUpdateSopStepsForm.propTypes = {
  isFetchingSop: PropTypes.bool,
  sopId: PropTypes.string,
  deleteSopForRule: PropTypes.func,
  deleteSopStep: PropTypes.func,
  setSopDeleteAlert: PropTypes.func,
  setShowProposedPopup: PropTypes.func,
  actionList: PropTypes.array,
  isActionListLoading: PropTypes.bool,
};

export default AddOrUpdateSopStepsForm;
