import { validateExists } from 'helpers/validators';

export const validatePayerPortalForm = (values) => {
  const errors = {};
  const displayErrors = [];

  const requiredFields = [{ field: 'portal', label: 'Payer Portal' }];

  requiredFields.forEach(({ field, label }) => {
    errors[field] = validateExists(values.field);
    if (errors[field]) {
      const message = `${label} is required`;
      errors[field] = message;
      displayErrors.push({ message });
    }
  });

  return errors;
};

export const formatPayerPortalFormValues = (values) => {
  const formattedValues = {
    payer_portal_url: values.payerPortalUrl || '',
  };

  return formattedValues;
};
