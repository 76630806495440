import React, { useEffect } from 'react';
import { startCase } from 'lodash';
import { Field, reduxForm } from 'redux-form/immutable';

import Card from 'components/common/card';
import Input from 'components/common/input';
import Button from 'components/common/button';
import Select from 'components/common/select/Select';
import { BUTTON_TYPE } from 'components/common/button/Button';

import { validateZipCode } from 'helpers/validators';

import { states } from 'components/CreateAppeal/state-mock-data';

const AddPracticeForm = (props) => {
  const {
    onSubmit,
    onCancel,
    submitting,
    handleSubmit,
    initialValues,
    isUpdateForm = false,
    initialize,
  } = props;

  const stateOptions = states.map((state) => ({
    label: state.value,
    value: state.key,
  }));

  useEffect(() => {
    if (initialValues) {
      const values = initialValues.toJS();
      const selectedState = stateOptions.find(
        (option) => option.value === values.state
      );

      initialize({
        ...values,
        state: selectedState,
      });
    }
  }, [initialValues, initialize, stateOptions]);

  const handleFormSubmit = async (formValues) => {
    const values = formValues.toJS();

    await onSubmit({ ...values, state: values.state.value });
  };

  return (
    <Card>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="row mb-12">
          <div className="col-lg-3">
            <Field
              component={Input}
              name="name"
              label="Practice Name *"
              placeholder="Name"
              maxLength={100}
            />
          </div>

          <div className="col-lg-3">
            <Field
              component={Input}
              name="address1"
              label="Address Line 1 *"
              placeholder="Address Line"
              maxLength={50}
            />
          </div>

          <div className="col-lg-3">
            <Field
              component={Input}
              name="address2"
              label="Address Line 2"
              placeholder="Address Line"
              maxLength={50}
            />
          </div>
        </div>
        <div className="row mb-12">
          <div className="col-lg-3">
            <Field
              component={Input}
              name="city"
              label="City *"
              placeholder="City"
              maxLength={50}
            />
          </div>
          <div className="col-lg-3">
            <Field
              component={Select}
              options={stateOptions}
              name="state"
              defaultValue={null}
              label="State *"
              placeholder="State"
            />
          </div>

          <div className="col-lg-3">
            <Field
              component={Input}
              name="zipcode"
              label="Zip Code *"
              placeholder="Zip Code"
            />
          </div>
        </div>
        <div className="d-flex justify-content-flex-end pr-15 form-action-button-wrapper">
          <Button
            title={isUpdateForm ? 'Update' : 'Save'}
            className="ap-button ap-button--secondary justify-content-center mr-8"
            disabled={submitting}
          />
          <Button
            title="Cancel"
            type={BUTTON_TYPE.LIGHT}
            className="justify-content-center fw-normal"
            disabled={submitting}
            onClick={onCancel}
          />
        </div>
      </form>
    </Card>
  );
};

const validate = (values) => {
  const formValues = values.toJS();
  const nonEmptyValues = ['name', 'address1', 'city', 'state', 'zipcode'];

  const errors = {};

  nonEmptyValues.forEach((key) => {
    if (!formValues[key]) {
      errors[key] = `${startCase(key)} is required`;
    }
  });

  if (
    !errors.zipcode &&
    formValues.zipcode &&
    validateZipCode(formValues.zipcode)
  ) {
    errors.zipcode = 'Invalid Zip code';
  }

  return errors;
};

export default reduxForm({
  validate,
  form: 'add-practice-form',
})(AddPracticeForm);
