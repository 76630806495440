import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { Component } from 'react';

export default class Footer extends Component {
  static props = {
    className: PropTypes.string,
  };

  render() {
    return (
      <div
        className={classNames('footer', this.props.className)}
        datacy="footer"
      >
        <p>
          Product names, logos, brands, and other trademarks featured or
          referenced within Appealio are the property of their respective
          trademark holders.
        </p>
        <p>
          Copyright 2023 DocVocate, Inc. All Rights Reserved{' '}
          {/* • <Link to="/terms_of_service">Terms of Use </Link>• <Link to="/privacy_policy">Privacy Policy</Link> • Security */}
        </p>
      </div>
    );
  }
}
