import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import AppealNoteItemList from './AppealNoteItemList';
import LoadingBlockHelper from '../../../Shared/LoadingBlockHelper';
import LettersAndDocumentList from '../../../Shared/LettersAndDocumentList';

import { getAppealFromState } from '../../../../redux/reducers/AppealStore';
import { getAppealById } from '../../../../redux/actions/createAppealActions';

import { APIConfig } from '../../../../API/Config';
import { defaultIncludeOptions } from '../../../../API/CreateAppealAPI';
import { transformLinkPathToBasename } from '../../../../API/Serializers/Transforms';

import arrowImg from '../../../../img/ic-chevron-up.svg';
import rightArrowImg from '../../../../img/right-arrow-gray.svg';

class AppealHistoryCard extends Component {
  static propTypes = {
    appealData: PropTypes.object.isRequired,
    finalEOB: PropTypes.bool,
  };

  static defaultProps = {
    finalEOB: false,
  };

  constructor() {
    super();
    this.state = {
      cardIsExpanded: false,
    };
  }

  componentDidMount() {
    if (
      !_.has(this.props.appealData, 'documents') ||
      !_.has(this.props.appealData, 'notes')
    ) {
      this.props.actions.getAppealById(this.props.appealData.id, [
        ...defaultIncludeOptions,
        'notes',
      ]);
    }
  }

  toggleExpand = () => {
    const cardIsExpanded = !this.state.cardIsExpanded;
    this.setState({ cardIsExpanded });
  };

  getFullCardClass = () => {
    return classNames({ hidden: !this.state.cardIsExpanded });
  };

  getDropArrowClass = () => {
    return classNames('appeal-history-card__droparrow', {
      'appeal-history-card__droparrow--flip': this.state.cardIsExpanded,
    });
  };

  onViewEOBClick = () => {
    const historyUrl = `/appeal/history/${this.props.appealData.id}`;
    this.props.actions.push(historyUrl);
  };

  renderFullCardIfExpanded() {
    if (this.state.cardIsExpanded) {
      if (this.props.isLoading) {
        return <LoadingBlockHelper isLoading={true} />;
      } else {
        return (
          <div className={this.getFullCardClass()}>
            <LettersAndDocumentList
              formattedLetters={this.props.formattedLetters}
              formattedDocuments={this.props.formattedDocuments}
              appealId={this.props.appealId}
            />
            {this.renderNotesSectionIfThereAreNotes()}
            <div
              className="appeal-history-card__eobsection"
              onClick={this.onViewEOBClick}
            >
              <div className="appeal-history-card__eobtitle">View EOB</div>
              <img
                alt="Expand"
                className="appeal-history-card__sidearrow"
                src={rightArrowImg}
              />
            </div>
          </div>
        );
      }
    }
  }

  renderNotesSectionIfThereAreNotes = () => {
    if (this.props.hasNotes === true) {
      return this.renderNotes();
    } else {
      return <div className="appeal-history-card__empty_section" />;
    }
  };

  renderNotes = () => {
    const notes = this.props.fullDetailsAppeal.notes;
    return (
      <div className="appeal-history-card__notesection">
        <div className="appeal-history-card__sectiontitle">Notes</div>
        <div className="appeal-history-card__notes">
          <AppealNoteItemList
            notes={notes}
            hasTopBorder={false}
            charMax={100}
          />
        </div>
      </div>
    );
  };

  renderFinalEOB = () => {
    return (
      <div className="appeal-history-card">
        <div className="appeal-history-card__titlesection">
          <div className="appeal-history-card__title">
            <span className="cursor-pointer" onClick={this.onViewEOBClick}>
              View EOB
            </span>
          </div>
          <div className="appeal-history-card__date">
            Received - {this.props.formattedSubmitDate}
          </div>
        </div>
      </div>
    );
  };

  renderAppealEOB = () => {
    return (
      <div className="appeal-history-card">
        <div
          className="appeal-history-card__titlesection appeal-history-card__titlesection--no-hover"
          onClick={this.toggleExpand}
        >
          <div className="appeal-history-card__title">
            Appeal Round {this.props.appealData.appealRound}
          </div>
          <div className="appeal-history-card__date">
            Submitted - {this.props.formattedSubmitDate}
          </div>
          <img
            alt="Appeal EOB"
            className={this.getDropArrowClass()}
            src={arrowImg}
          />
        </div>
        {this.renderFullCardIfExpanded()}
      </div>
    );
  };

  render() {
    if (this.props.finalEOB) {
      return this.renderFinalEOB();
    } else {
      return this.renderAppealEOB();
    }
  }
}

function mapStateToProps(state, ownProps) {
  const appealState = getAppealFromState(state, ownProps.appealData.id);
  const params = APIConfig.processParams();
  if (!appealState || appealState.isLoading || appealState.isRejected) {
    return {
      isLoading: true,
    };
  } else {
    const documents = appealState.appeal.documents.map((item) => {
      return {
        title: transformLinkPathToBasename(item.linkPath),
        link: `/download_appeal_document/${item.id}`,
        prefix: 'document_link_',
      };
    });

    const letters = appealState.appeal.letters.map((item) => {
      return {
        title: `${item.name}.PDF`,
        params,
        link: `appeal_letter_pdfs/${item.id}`,
        prefix: 'letter_link_',
      };
    });

    return {
      fullDetailsAppeal: appealState.appeal,
      isLoading: false,
      formattedDocuments: documents,
      formattedLetters: letters,
      hasNotes: !_.isEmpty(appealState.appeal.notes),
      formattedSubmitDate: ownProps.appealData.submittedAt
        ? moment(ownProps.appealData.submittedAt).format('MM/DD/YYYY')
        : null,
      appealId: appealState.appeal.id,
    };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ push, getAppealById }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppealHistoryCard);
