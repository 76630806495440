export const CreateAppealMode = {
  NewAppeal: 'new-appeal',
  ReAppeal: 're-appeal',
  HistoryAppeal: 'history-appeal',
  Resume: 'resume',
};

export const CreateAppealModes = [
  CreateAppealMode.NewAppeal,
  CreateAppealMode.ReAppeal,
  CreateAppealMode.HistoryAppeal,
  CreateAppealMode.Resume,
];
