import classnames from 'classnames';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { get as _get, isNull } from 'lodash';
import { BsPencil, BsArrowCounterclockwise } from 'react-icons/bs';

import './style.css';

import { moneyFormatter } from '../../../../helpers/money';

import UpdateBenefitPopup from './UpdateBenefitPopup/UpdateBenefitPopup';

const BenefitList = (props) => {
  const {
    eligibility,
    selectedPatient,
    manualBenefitInfo,
    setManualBenefitInfo,
    resetManualBenefitInfo,
    isProcedureCodesSelected,
  } = props;
  const [isUpdateBenefitPopupOpen, setIsUpdateBenefitPopupOpen] =
    useState(false);
  const openUpdateBenefitPopup = () => setIsUpdateBenefitPopupOpen(true);
  const closeUpdateBenefitPopup = () => setIsUpdateBenefitPopupOpen(false);

  const extractDependentsFullNames = (dependents = []) => {
    if (dependents.length === 0) {
      return 'N/A';
    }

    return dependents
      .map(({ firstName, lastName }) => [`${firstName} ${lastName}`])
      .join(', ');
  };

  /**
   * Extracts manual, original, and display value for benefits.
   * @param {String} key One of [deductible, coinsurance, copayment]
   * @returns Array
   */
  const getBenefitValues = (key = '') => {
    const manual = _get(manualBenefitInfo, key, 0);
    const personObject = eligibility.subscriber.isMatch
      ? eligibility.subscriber
      : eligibility.dependents.find(({ isMatch }) => isMatch);

    const original = _get(personObject, `benefits.${key}.value`, null);
    const displayValue = manual !== null && !isNaN(manual) ? manual : original;

    return [manual, original, displayValue];
  };

  const [manualDeductible, originalDeductible, displayDeductible] =
    getBenefitValues('deductible');
  const [manualCopayment, originalCopayment, displayCopayment] =
    getBenefitValues('copayment');
  const [manualCoinsurance, originalCoinsurance, displayCoinsurance] =
    getBenefitValues('coinsurance');

  const handleManualBenefitUpdate = (values) => {
    setManualBenefitInfo(values);
    closeUpdateBenefitPopup();
  };

  const isBenefitDetailsManuallyUpdated = ![
    manualDeductible,
    manualCopayment,
    manualCoinsurance,
  ].every(isNull);

  return (
    <div className="patient-cost-estimator-content" datacy="benefit-list">
      <hr className="separator-line no-print" />
      <div className="cost-estimator-create-estimates-table">
        <h4 className="patient-cost-estimator__title">Benefits Details</h4>
        <div className="fw-500">
          Health Plan: {eligibility.healthPlan || 'Unavailable'}
        </div>
        <table>
          <thead>
            <tr>
              <th>Status</th>
              <th>Eligibility Check (Date)</th>
              <th>Patient's DOB</th>
              <th>Subscriber</th>
              <th>Dependents</th>
              <th className="text-right">Remaining Deductible</th>
              <th className="text-center">Copayment Amount</th>
              <th className="text-center">Patient Coinsurance %</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span
                  className={classnames({
                    'color-red': eligibility.status === 'INACTIVE',
                  })}
                >
                  {eligibility.status}
                </span>
              </td>
              <td>{eligibility.checkDate}</td>
              <td>{selectedPatient.dob}</td>
              <td>{`${eligibility.subscriber.firstName} ${eligibility.subscriber.lastName}`}</td>
              <td>{extractDependentsFullNames(eligibility.dependents)}</td>
              <td className="text-right">
                {displayDeductible !== null
                  ? moneyFormatter().format(displayDeductible)
                  : 'N/A'}
              </td>
              <td className="text-right">
                {displayCopayment !== null
                  ? moneyFormatter().format(displayCopayment)
                  : 'N/A'}
              </td>
              <td className="text-center">
                {displayCoinsurance !== null
                  ? `${Number(displayCoinsurance)}%`
                  : 'N/A'}
              </td>
              <td className="text-right">
                <div className="d-flex">
                  <BsPencil
                    onClick={openUpdateBenefitPopup}
                    className="mr-8 cursor-pointer action-icons no-print"
                    data-tip="Click to edit benefits details manually"
                    data-for="update-data"
                    datacy="benefit-list-BsPencil"
                  />
                  <ReactTooltip
                    id="update-data"
                    effect="solid"
                    place="top"
                    multiline={true}
                  />
                  {isBenefitDetailsManuallyUpdated && (
                    <React.Fragment>
                      <BsArrowCounterclockwise
                        className="mr-8 cursor-pointer action-icons no-print"
                        data-tip="Click to reset benefits details"
                        onClick={resetManualBenefitInfo}
                        data-for="manually-updated"
                        datacy="benefit-list-BsArrowCounterclockwise"
                      />
                      <ReactTooltip
                        id="manually-updated"
                        effect="solid"
                        place="top"
                        multiline={true}
                      />
                    </React.Fragment>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {isUpdateBenefitPopupOpen && (
        <UpdateBenefitPopup
          onClose={closeUpdateBenefitPopup}
          onSubmit={handleManualBenefitUpdate}
          initialValues={{
            deductible: displayDeductible || 0,
            coinsurance: displayCoinsurance || 0,
            copayment: displayCopayment || 0,
          }}
          originalValues={{
            deductible: originalDeductible,
            coinsurance: originalCoinsurance,
            copayment: originalCopayment,
          }}
          isProcedureCodesSelected={isProcedureCodesSelected}
          datacy="benefit-list-UpdateBenefitPopup"
        />
      )}
    </div>
  );
};

export default BenefitList;
