import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';

import Widget from '../Widget';
import TopPayers from './TopPayers';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import AverageAllowedAmountByProcedureCodes from './AverageAllowedAmount';
import EmptyWidgetPlaceholder from 'components/ApDashboard/Manager/EmptyWidgetPlaceholder';

const TopPayersAndProcedureCode = memo((props) => {
  const {
    filters,
    isFetchingData,
    setIsFetchingData,
    payersDropdownOptions,
    topPayersAndProcedureCodesRef,
    isDownloading,
  } = props;
  const [payersId, setPayersId] = useState([]);
  const [selectedThreePayerIds, setSelectedThreePayerIds] = useState([]);
  const [payerNotInTopPayerList, setPayerNotInTopPayerList] = useState('');
  const [isTopPayersDataLoading, setIsTopPayersLoading] = useState(false);

  return (
    <div className="row equal mb-32 no-gutter">
      <div className="col-lg-5">
        <TopPayers
          filters={filters}
          selectedThreePayerIds={selectedThreePayerIds}
          setSelectedThreePayerIds={setSelectedThreePayerIds}
          setPayersId={setPayersId}
          isFetchingData={isFetchingData}
          setIsFetchingData={setIsFetchingData}
          payersDropdownOptions={payersDropdownOptions}
          setPayerNotInTopPayerList={setPayerNotInTopPayerList}
          setIsTopPayersLoading={setIsTopPayersLoading}
        />
      </div>
      <div className="col-lg-7">
        {isTopPayersDataLoading ? (
          <Widget
            widgetTitle={{
              title: 'Average Allowed Amount by Top 10 Procedure Code',
              titleClassName: 'v3__widget-title--lg',
            }}
            className=" v3__analytics-widget--no-radius-left"
          >
            <LoadingIndicator showing={true} />
          </Widget>
        ) : payersId.length >= 1 ? (
          <AverageAllowedAmountByProcedureCodes
            filters={filters}
            selectedThreePayerIds={selectedThreePayerIds}
            payersId={payersId}
            isFetchingData={isFetchingData}
            setIsFetchingData={setIsFetchingData}
            payerNotInTopPayerList={payerNotInTopPayerList}
            ref={topPayersAndProcedureCodesRef}
            isDownloading={isDownloading}
          />
        ) : (
          <Widget
            widgetTitle={{
              title: 'Average Allowed Amount by Top 10 Procedure Code',
              titleClassName: 'v3__widget-title--lg',
            }}
            className=" v3__analytics-widget--no-radius-left"
          >
            <EmptyWidgetPlaceholder
              title="No Data Available"
              className="mt-40"
            />
          </Widget>
        )}
      </div>
    </div>
  );
});

TopPayersAndProcedureCode.propTypes = {
  filters: PropTypes.object.isRequired,
};

export default TopPayersAndProcedureCode;
