import classnames from 'classnames';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { BsPencil } from 'react-icons/bs';
import { bindActionCreators } from 'redux';
import { get, isEmpty, uniq } from 'lodash';
import { BiCopy, BiX } from 'react-icons/bi';
import { MdContentPaste, MdSave } from 'react-icons/md';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Field,
  change,
  reduxForm,
  formValueSelector,
} from 'redux-form/immutable';

import { handleError } from 'helpers/errorHandler';
import { USER_ROLE_OPTIONS } from 'constants/options';
import { USER_ROLES_VALUE } from 'constants/appConstants';
import { SEARCH_QUERY_CHARACTERS_OFFSET } from '../constants';

import * as toast from 'components/Shared/toast';
import Select from 'components/common/select/Select';
import PracticeSelect from './PracticeSelect/PracticeSelect';
import TextHighlighter from 'components/common/textHighlighter';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import ActionButton from 'components/common/actionButton/ActionButton';

import { isClientAdmin } from 'Auth/AuthUtils';
import { updateUserAccessControl } from 'API/UsersAccessControls';

import {
  DROPDOWN_OPTIONS_STATE_KEYS,
  getDropdownOptions,
} from 'redux/reducers/dropdownOptionsStore';
import { getUserInfo } from 'redux/reducers/loginStore';
import {
  addTypeToOptions,
  extractPracticeAndPracticeGroupValues,
} from './PracticeSelect/utils';

import { OPTION_TYPES, ALL_OPTION } from './PracticeSelect/constant';

const MANAGER_ROLES = [USER_ROLES_VALUE.MANAGER, USER_ROLES_VALUE.SUPERVISOR];

let UserListItem = (props) => {
  const {
    handleSubmit,
    copiedUser,
    onEditClick,
    onCopyClick,
    onCancelClick,
    pristine,
    userAccessControl,
    onUpdateUserAccess,
    formEnabled = false,
    change,
    practices,
    practiceGroups,
    currentRole,
    searchQuery,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const {
    name,
    userId,
    allowedItems: {
      practice: allowedPractices = [],
      pgroup: allowedPracticeGroups = [],
    } = {},
    role: userRole,
  } = userAccessControl;

  const initialSelectedRole = USER_ROLE_OPTIONS.find(
    (option) => option.value === userRole
  );

  const availableUserPractices = [...practices.data.filter((x) => x.value)];

  const availableUserPracticeGroups = [
    ...practiceGroups.data.filter((x) => x.value && x.label !== 'Ungrouped'),
  ];

  const selectedRoles = [initialSelectedRole.value, props.currentRole.value];

  const shouldExcludeBasedOnRole = () =>
    selectedRoles.some((role) => MANAGER_ROLES.includes(role));

  const filterOptions = (availableOptions, identifiers) => {
    return availableOptions.filter((option) =>
      identifiers.includes(option.value)
    );
  };

  const getPracticeOptions = (practiceIdentifiers = []) => {
    if (shouldExcludeBasedOnRole()) return [];
    return addTypeToOptions(
      filterOptions(availableUserPractices, practiceIdentifiers),
      OPTION_TYPES.PRACTICE
    );
  };

  const getPracticeGroupOptions = (practiceGroupIds = []) => {
    if (shouldExcludeBasedOnRole()) return [];
    return addTypeToOptions(
      filterOptions(availableUserPracticeGroups, practiceGroupIds),
      OPTION_TYPES.PRACTICE_GROUP
    );
  };

  const initialSelectedPractices = getPracticeOptions(allowedPractices);
  const initialSelectedPracticeGroups = getPracticeGroupOptions(
    allowedPracticeGroups
  );

  const practicesInitialized = useRef(false);
  const roleInitialized = useRef(false);

  const setPracticeTest = useCallback(
    (values) => {
      change('practices', !values.length ? [ALL_OPTION] : values);
    },
    [change]
  );

  useEffect(() => {
    if (practiceGroups?.isFetching || practices?.isFetching) return;

    if (!practicesInitialized.current) {
      const values = [
        ...initialSelectedPracticeGroups,
        ...initialSelectedPractices,
      ];
      setPracticeTest(isEmpty(values) ? [ALL_OPTION] : values);
      practicesInitialized.current = true;
    }
  }, [
    initialSelectedPracticeGroups,
    initialSelectedPractices,
    practiceGroups?.isFetching,
    practices?.isFetching,
    setPracticeTest,
  ]);

  useEffect(() => {
    if (!roleInitialized.current && !isEmpty(initialSelectedRole)) {
      change('role', initialSelectedRole);
      roleInitialized.current = true;
    }
  }, [change, initialSelectedRole]);

  const handlePastePractices = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!copiedUser) {
      return;
    }

    if (props.userToEdit && props.userToEdit.userId !== userId) {
      return toast.warning({
        title: 'Please save changes before editing',
        message: "You can't edit this user.",
      });
    }

    onEditClick(userAccessControl);

    const allowedPractices = getPracticeOptions([
      ...copiedUser.allowedItems.practices,
    ]);
    const allowedPracticeGroups = getPracticeGroupOptions([
      ...copiedUser.allowedItems.practices,
    ]);

    const practices = uniq(
      [...allowedPractices, ...allowedPracticeGroups],
      'value'
    );
    props.change('practices', practices);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    const values = [
      ...initialSelectedPracticeGroups,
      ...initialSelectedPractices,
    ];
    setPracticeTest(isEmpty(values) ? [ALL_OPTION] : values);
    props.change('role', initialSelectedRole);
    onCancelClick();
  };

  const updateAccessControl = async (data) => {
    if (!data) return;
    setIsLoading(true);
    try {
      await updateUserAccessControl(userId, data);

      toast.success({
        title: 'Success',
        message: 'User has been successfully updated',
      });
      onUpdateUserAccess({
        ...userAccessControl,
        allowedItems: data.allowedItems,
        role: data.role,
        userId,
      });
      onCancelClick();
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  const onSubmit = (values) => {
    if (pristine) {
      onCancelClick();
      return;
    }

    const formValues = values.toJS();
    const { role, practices = [] } = formValues;
    const userRole = role?.value || initialSelectedRole.value;

    const { practiceGroupValues, practiceValues } =
      extractPracticeAndPracticeGroupValues(practices);
    const data = {
      allowedItems: {
        practice: practiceValues,
        pgroup: practiceGroupValues,
      },
      role: userRole,
    };

    if (userRole === 'Agent') {
      if (!practices.length) {
        showToastError(`Please select one or more practice(s) for ${name}`);
        return;
      }
    }

    updateAccessControl(data);
  };

  const showToastError = (message) => {
    toast.error({
      title: '',
      message,
    });
  };

  const handleOnRoleChange = (role) => {
    if (MANAGER_ROLES.includes(role.value)) {
      props.change('practices', []);
      props.change('practiceGroups', []);
    }
    props.change('role', role);
  };

  const isAgentUser = currentRole && currentRole.value === 'Agent';

  const isPracticesSelectDisabled = !(
    isAgentUser && currentRole.value !== 'Manager'
  );
  const enablePastButton = copiedUser && isAgentUser && formEnabled;

  return (
    <form onSubmit={handleSubmit(onSubmit)} datacy="user-list-item">
      <li
        className={classnames('ap-card-list__item row', {
          'ap-card-list__item--active': formEnabled,
        })}
      >
        <div className="col-md-2 ap-card-list__item--sm">
          {name && searchQuery?.length >= SEARCH_QUERY_CHARACTERS_OFFSET ? (
            <TextHighlighter text={name} query={searchQuery} />
          ) : (
            <span>{name || '--'}</span>
          )}
        </div>
        <div className="col-md-2 ap-card-list__item--sm pl-0">
          <Field
            component={Select}
            options={USER_ROLE_OPTIONS}
            name="role"
            placeholder="Select Agent"
            classNamePrefix="ap-select-v2"
            onChange={handleOnRoleChange}
            reactSelectProps={{
              isDisabled: !formEnabled,
              defaultValue: initialSelectedRole,
            }}
            datacy="select-agent-Field"
          />
        </div>
        <div className="col-md-8 pr-0">
          <div className="row d-flex align-items-center">
            <div className="col-md-9 col-lg-8 ap-card-list__item--lg pr-0">
              <Field
                component={PracticeSelect}
                practiceGroupOptions={availableUserPracticeGroups}
                practiceOptions={availableUserPractices}
                name="practices"
                isDisabled={!formEnabled || isPracticesSelectDisabled}
                clearable={false}
              />
              <LoadingBlockHelper
                className="ap-card-list__item--loader"
                isLoading={isLoading}
              />
            </div>
            {isClientAdmin(props.userInfo) && (
              <div className="col-md-3 col-lg-4 pr-0">
                <div className="action-buttons-wrapper pl-8">
                  <ActionButton
                    Icon={BiCopy}
                    className="mr-16 ml-auto"
                    dataTip="Copy"
                    disabled={!isAgentUser}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const itemToCopy = {
                        ...userAccessControl,
                        allowedItems: {
                          practices: props.selectedPractices.map(
                            ({ value }) => value
                          ),
                        },
                      };

                      toast.success({
                        title: '',
                        message: 'Practice(s) Copied',
                      });
                      onCopyClick(itemToCopy);
                    }}
                    iconClassName="appeal__action--appeal"
                    datacy="copy-ActionButton"
                  />
                  <ActionButton
                    Icon={MdContentPaste}
                    className="mr-16 ml-auto"
                    dataTip={enablePastButton ? 'Paste' : 'Paste (disabled)'}
                    disabled={!enablePastButton}
                    onClick={handlePastePractices}
                    iconClassName="appeal__action--appeal"
                    datacy="paste-ActionButton"
                  />
                  {formEnabled ? (
                    <React.Fragment>
                      <ActionButton
                        Icon={MdSave}
                        className="mr-16 ml-auto"
                        dataTip="Save"
                        iconClassName="appeal__action--appeal"
                        type="submit"
                        datacy="save-ActionButton"
                      />

                      <ActionButton
                        Icon={BiX}
                        className="mr-16 ml-auto"
                        dataTip="Cancel"
                        onClick={handleCancel}
                        iconClassName="appeal__action--appeal"
                        datacy="cancel-ActionButton"
                      />
                    </React.Fragment>
                  ) : (
                    <ActionButton
                      Icon={BsPencil}
                      className="mr-16 ml-auto"
                      dataTip="Edit"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (props.userToEdit) {
                          return toast.warning({
                            title: 'Please save changes before editing',
                            message: "You can't edit this user.",
                          });
                        }
                        onEditClick(userAccessControl);
                      }}
                      iconClassName="appeal__action--appeal"
                      datacy="edit-ActionButton"
                    />
                  )}
                  <ReactTooltip effect="solid" place="top" multiline={true} />
                </div>
              </div>
            )}
          </div>
        </div>
      </li>
    </form>
  );
};

UserListItem = reduxForm()(UserListItem);

const mapStateToProps = (state, ownProps) => {
  const userInfo = getUserInfo(state);
  const userPractices = get(userInfo, 'relatedPractices', []);
  const selector = formValueSelector(ownProps.form);
  const selectedPractices = selector(state, 'practices') || [];
  const selectedPracticeGroups = selector(state, 'practiceGroups') || [];
  const currentRole = selector(state, 'role') || [];

  return {
    selectedPracticeGroups,
    selectedPractices,
    userPractices,
    currentRole,
    userInfo,
    practiceGroups: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.PRACTICE_GROUP_OPTIONS
    ),
    practices: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PRACTICE_OPTIONS
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ change }, dispatch),
  };
};

UserListItem = connect(mapStateToProps, mapDispatchToProps)(UserListItem);

export default UserListItem;
