import { parse } from 'qs';
import moment from 'moment';
import classnames from 'classnames';
import { isSameDay } from 'react-dates';
import { connect } from 'react-redux';
import { BsSearch } from 'react-icons/bs';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { isInteger, isEmpty, get, omit } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useState, useMemo, useEffect, useCallback } from 'react';

import { moneyFormatter } from 'helpers/money';
import { getPageCountMessage } from 'helpers/utils';
import {
  getFormattedDate,
  DATE_WITH_TIME_FORMAT,
  VALIDATION_DATE_FORMAT,
} from 'helpers/dateFormats';

import { getUserInfo } from 'redux/reducers/loginStore';
import { setAppealData } from 'redux/actions/createAppealActions';
import { openUhcClaimStatusWidget } from 'redux/actions/uhcClaimStatusWidgetActions';
import { openAvailityClaimStatusWidget } from 'redux/actions/availityClaimStatusWidgetActions';

import Pill from 'components/common/pill';
import Button from 'components/common/button';
import Avatar from 'components/Shared/Avatar';
import Dropdown from 'components/Shared/Dropdown';
import AppealioTable from 'components/common/table';
import SearchBox from 'components/common/search/SearchBox';
import RightSlideOut from 'components/Shared/RightSlideOut';
import StatusTrackerCheckBox from './StatusTrackerCheckBox';
import { BUTTON_TYPE } from 'components/common/button/Button';
import ClaimStatusCheckHistory from './ClaimStatusCheckHistory';
import StatusTrackerPopupFilter from './StatusTrackerPopupFilter';
import SuccessModalUHC from 'components/UhcClaimStatusCheck/SuccessModal';
import SuccessModalAvaility from 'components/AvailityClaimStatusCheck/SuccessModal/SuccessModal';
import TablePagination from 'components/common/pagination/TablePagination';

import settingsIcon from 'img/settings.svg';
import searchIcon from 'img/search-icon-white.svg';
import filterIconRed from 'img/filter-icon-red.svg';
import searchIconRed from 'img/search-icon-red.svg';

import {
  useFetchClaimStatusHistory,
  useFetchStatusTrackerFilterOptions,
} from './hooks';

import { CLAIM_STATUS_CHECK_FILTER_OPTIONS } from 'constants/options';
import {
  PAYER_API_TYPE,
  TABLE_PAGE_LIMIT,
  UHC_CLAIMS_STATUS,
} from 'constants/appConstants';
import { extractStatus } from './utils';

export const DEFAULT_FILTERS = {
  search: '',
  status: null,
  onlyRecentChange: false,
  onlyScheduledChecks: false,
  payerId: null,
  providerId: null,
};

const DEFAULT_SORTBY = {
  id: 'checkedAt',
  desc: true,
};

const parseQs = () => {
  return parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
};

const parseFilters = () => {
  const { search } = parseQs();

  if (search) {
    return {
      ...DEFAULT_FILTERS,
      search,
    };
  }

  return DEFAULT_FILTERS;
};

export const StatusCell = ({
  onViewStatusClick,
  handleClaimStatusCheckClick,
  status,
  isAvaiiltyClaim = false,
}) => {
  return (
    <div className="table-status__cell">
      <Pill
        dataTip="Click to view latest claim status check details"
        status={status}
        className={classnames({
          xl: status === UHC_CLAIMS_STATUS.MULTIPLE,
          success: status === UHC_CLAIMS_STATUS.FINALIZED,
          outline: status === UHC_CLAIMS_STATUS.PENDING,
          danger: status === UHC_CLAIMS_STATUS.DENIED,
          'success-outline': status === UHC_CLAIMS_STATUS.IN_PROCESS,
        })}
        onClick={onViewStatusClick}
      />
      <div className="ml-1 appeal__action_container">
        <button
          data-tip={`Claim Status Check via ${
            isAvaiiltyClaim ? 'Availity' : 'UHC'
          }`}
          className={classnames('ap-button ap-button--action mr-1')}
          onClick={handleClaimStatusCheckClick}
        >
          <BsSearch
            className="appeal__action appeal__action--appeal"
            datacy="denial-table-BsPencil"
          />
        </button>
      </div>
    </div>
  );
};

const StatusTracker = (props) => {
  const {
    userInfo,
    actions: { openUhcClaimStatusWidget, openAvailityClaimStatusWidget },
  } = props;

  const history = useHistory();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(DEFAULT_SORTBY);
  const [filters, setFilters] = useState(parseFilters());
  const [showFilters, setShowFilters] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [claimInfoModal, setClaimInfoModal] = useState({
    isOpen: false,
    claimInfo: null,
    api: null,
  });
  const [claimHistoryFlyover, setClaimHistoryFlyover] = useState({
    isOpen: false,
    id: null,
  });

  const openClaimHistoryFlyover = (id) =>
    setClaimHistoryFlyover({ isOpen: true, id });
  const closeClaimHistoryFlyover = () => {
    setClaimHistoryFlyover({ isOpen: false, id: null });
    history.replace({
      search: '',
    });
  };
  const openClaimInfoModal = (claimInfo, api) =>
    setClaimInfoModal({
      isOpen: true,
      claimInfo,
      api,
    });

  const closeClaimInfoModal = () =>
    setClaimInfoModal({
      isOpen: false,
      claimInfo: null,
      api: null,
    });

  useEffect(() => {
    const { id, openHistory } = parseQs();
    if (openHistory === 'true' && id) {
      openClaimHistoryFlyover(id);
    }
  }, [location.search]);

  const { options: filterOptions } = useFetchStatusTrackerFilterOptions();

  const {
    loading: isFetchingClaimStatusHistory,
    claimStatusHistory,
    totalCount,
  } = useFetchClaimStatusHistory(sortBy, page, filters);

  const filterKeysToOmit = [
    'search',
    'status',
    'onlyRecentChange',
    'onlyScheduledChecks',
  ];
  const isFiltersApplied = !Object.values(
    omit(filters, filterKeysToOmit)
  ).every((x) => x === null || x === '' || x === undefined);
  const appliedFiltersCount = Object.values(
    omit(filters, filterKeysToOmit)
  ).filter((item) => item).length;

  const onTableSort = (sortBy) => setSortBy(sortBy);

  const handleSearchSubmit = (search) => {
    setFilters({
      ...DEFAULT_FILTERS,
      search,
    });
  };

  const isResetButtonVisible = !Object.values(filters).every(
    (x) => x === null || x === '' || x === undefined || !x
  );

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPage(1);
  };

  const onRowClick = (row) => {
    openClaimHistoryFlyover(row.id);
  };

  const onPageChange = (page) => setPage(page);
  const pageCount = Math.ceil(totalCount / TABLE_PAGE_LIMIT);

  const claimsCountOnPage = claimStatusHistory.length;

  const claimsText =
    claimsCountOnPage === 0 || claimsCountOnPage > 1 ? 'Claims' : 'Claim';
  const claimsCountText =
    claimsCountOnPage > 0
      ? `${getPageCountMessage(
          page,
          claimsCountOnPage,
          totalCount
        )} ${claimsText}`
      : `${totalCount} ${claimsText}`;

  const BuildAmountCell = (props) => {
    const amount =
      props.row.original.responseJson?.claimSummary?.totalChargedAmt || null;
    if (amount === null) {
      return (
        <div className="appealio-table__custom-cell">
          <span data-tip="N/A">N/A</span>
        </div>
      );
    }
    const formattedAmount = isInteger(amount)
      ? amount
      : parseFloat(amount).toFixed(2);
    const formattedText = moneyFormatter().format(formattedAmount);

    return (
      <div className="appealio-table__custom-cell" data-tip={formattedText}>
        {formattedText}
      </div>
    );
  };

  const ClaimCell = (props) => {
    const { value } = props;
    const isEmptyString = value === '--';
    const cellValue = isEmptyString ? 'N/A' : value;
    const toolTip = isEmptyString ? '' : value;

    return (
      <div
        className="appealio-table__custom-cell"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <span data-tip={toolTip} data-delay-hide="400">
          {cellValue}
        </span>
        <ReactTooltip
          effect="solid"
          className="interactive-tooltip"
          place="top"
        />
      </div>
    );
  };

  const CustomCell = (props) => {
    const { value } = props;
    const isEmptyString = !!(value === '--' || isEmpty(value));
    const cellValue = isEmptyString ? 'N/A' : value;
    const toolTip = isEmptyString ? '' : value;
    return (
      <div className="appealio-table__custom-cell">
        <span data-tip={toolTip}>{cellValue}</span>
        <ReactTooltip effect="solid" />
      </div>
    );
  };

  const NextScheduledDateCell = (props) => {
    const { nextScheduledAt, noOfRetries } = props.row.original;

    if (!noOfRetries) return 'N/A';
    if (!nextScheduledAt) {
      return moment().add(1, 'days').format(VALIDATION_DATE_FORMAT);
    }
    return moment(nextScheduledAt).format(VALIDATION_DATE_FORMAT);
  };

  const BuildDateCell = (props) => {
    const date = props.value;
    let formattedDate;
    if (date === '--') {
      formattedDate = 'N/A';
    } else {
      formattedDate = getFormattedDate(date, DATE_WITH_TIME_FORMAT);
    }

    return (
      <div className="appealio-table__custom-cell">
        <span data-tip={formattedDate}>{formattedDate}</span>
      </div>
    );
  };

  const StatusCellContainer = useCallback(
    (props) => {
      const currentData = props.row.original;
      const status = extractStatus(currentData);
      const handleClaimStatusCheckClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const requestJson = currentData.requestJson;
        if (currentData.api === PAYER_API_TYPE.AVAILITY) {
          return openAvailityClaimStatusWidget({
            claimControlNumber: currentData.claimControlNumber,
            billingProviderTaxId: requestJson.taxId,
            ediPayerId: currentData.ediPayerId,
          });
        }
        openUhcClaimStatusWidget({
          claimControlNumber: requestJson.claimControlNumber,
          billingProviderTaxId: requestJson.taxId,
          ediPayerId: requestJson.ediPayerId,
          claimNumber: requestJson.claimNumber,
        });
      };

      const onViewStatusClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        openClaimInfoModal(
          {
            ...props.row.original.responseJson,
            claimNumber: props.row.original.claimControlNumber,
          },
          props.row.original.api
        );
      };

      return (
        <StatusCell
          status={status}
          onViewStatusClick={onViewStatusClick}
          handleClaimStatusCheckClick={handleClaimStatusCheckClick}
          isAvaiiltyClaim={currentData.api === PAYER_API_TYPE.AVAILITY}
        />
      );
    },
    [openUhcClaimStatusWidget, openAvailityClaimStatusWidget]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
        Cell: StatusCellContainer,
        disableSortBy: true,
        width: 220,
      },
      {
        Header: 'Patient',
        accessor: 'patientName',
        className: '',
        width: 160,
        sortKey: 'patientName',
        disableSortBy: true,
        Cell: CustomCell,
      },
      {
        Header: 'Claim ID',
        accessor: 'claimControlNumber',
        width: 120,
        sortKey: 'claimControlNumber',
        disableSortBy: true,
        Cell: ClaimCell,
      },
      {
        Header: 'Payer',
        accessor: 'appealioPayer',
        width: 200,
        sortKey: 'payer',
        disableSortBy: true,
        Cell: CustomCell,
      },
      {
        Header: 'Provider',
        accessor: 'appealioProvider',
        width: 150,
        sortKey: 'provider',
        disableSortBy: true,
        Cell: CustomCell,
      },
      {
        Header: 'Next Scheduled Check',
        accessor: 'nextScheduledAt',
        width: 180,
        disableSortBy: true,
        sortKey: 'nextScheduledAt',
        Cell: NextScheduledDateCell,
      },
      {
        Header: 'Billed Amt',
        accessor: 'billedAmount',
        width: 130,
        sortKey: 'billedAmount',
        disableSortBy: true,
        Cell: BuildAmountCell,
      },
      {
        Header: 'Last Checked',
        accessor: 'checkedAt',
        className: 'appealio-table-cell__border--left',
        width: 150,
        sortKey: 'createdAt',
        disableSortBy: true,
        Cell: BuildDateCell,
      },
    ],
    [StatusCellContainer]
  );

  const selectedStatusOption =
    CLAIM_STATUS_CHECK_FILTER_OPTIONS.find((x) => x.value === filters.status) ||
    null;

  const isStatusEffectiveDateDiff = ({
    previousResponseJson,
    responseJson,
  }) => {
    if (!previousResponseJson || !responseJson) {
      return false;
    }

    const previousResponseClaimSummary = previousResponseJson.claimSummary;
    const currentResponseClaimSummary = responseJson.claimSummary;

    if (
      !previousResponseClaimSummary ||
      !currentResponseClaimSummary ||
      !previousResponseClaimSummary.statusEfctDt
    ) {
      return false;
    }

    const previousDate = moment(previousResponseClaimSummary.statusEfctDt);
    const currentDate = moment(currentResponseClaimSummary.statusEfctDt);

    return !isSameDay(previousDate, currentDate);
  };

  return (
    <div
      className="denials-queue-container background-color-grey-1"
      datacy="statusTracker"
    >
      <React.Fragment>
        <div className="dashboard__header clearfix">
          <div className="dashboard__header-userinfo">{`${userInfo.clientName} Claim Status Tracker`}</div>
        </div>
      </React.Fragment>

      <div className="appealio-table-container mt-40">
        <div className="appealio-table__action-bar row no-gutter">
          <div className="appealio-table__checkbox-action col-md-4">
            <StatusTrackerCheckBox
              title="Claims Status Changes"
              isChecked={filters.onlyRecentChange}
              onCheck={() => {
                setFilters((prev) => ({
                  ...prev,
                  onlyRecentChange: !prev.onlyRecentChange,
                }));
              }}
              className="mr-6"
            />
            <StatusTrackerCheckBox
              title="Scheduled Checks"
              isChecked={filters.onlyScheduledChecks}
              className="ml-2"
              onCheck={() => {
                setFilters((prev) => ({
                  ...prev,
                  onlyScheduledChecks: !prev.onlyScheduledChecks,
                }));
              }}
            />
          </div>
          {showSearchBox && (
            <SearchBox
              value={filters.search}
              onSearch={handleSearchSubmit}
              placeholder="Search by IDs"
            />
          )}

          <div className="appealio-table__filter col-md-4">
            <div className="d-flex align-items-center">
              <Dropdown
                className="width-160 mr-16"
                name="status"
                placeholder="Filter By Status"
                onChange={(selectedOption) => {
                  handleFilterChange({
                    ...filters,
                    status: selectedOption.value,
                  });
                }}
                isClearable={false}
                options={CLAIM_STATUS_CHECK_FILTER_OPTIONS}
                isLoading={false}
                value={selectedStatusOption}
                hideLabel
                datacy="status-dropdown"
              />
              <Button
                title=""
                icon={filters.search ? searchIconRed : searchIcon}
                className="mr-16"
                type={BUTTON_TYPE.SECONDARY}
                onClick={() => setShowSearchBox(!showSearchBox)}
                datacy="more-filters-search-Button"
              />
              <Button
                title=""
                icon={isFiltersApplied ? filterIconRed : settingsIcon}
                className="appealio-filter-button"
                type={BUTTON_TYPE.SECONDARY}
                onClick={() => setShowFilters(!showFilters)}
                datacy="more-filters-search-Button"
              >
                {appliedFiltersCount ? (
                  <Avatar
                    withBorder={false}
                    name={appliedFiltersCount.toString()}
                    size={16}
                    shouldRenderFullName={true}
                    className="ml-4"
                    datacy="denial-queue-container-Avatar"
                  />
                ) : null}
              </Button>
              {isResetButtonVisible && (
                <button
                  className="ap-button--link ap-button--link-danger"
                  onClick={() => {
                    handleFilterChange(DEFAULT_FILTERS);
                  }}
                  datacy="filter-reset-button"
                >
                  Reset
                </button>
              )}
            </div>
          </div>
          <div className="appealio-table__action-bar-pagination col-md-4">
            <span className="denial-table-count">{claimsCountText} </span>
            {!isFetchingClaimStatusHistory && pageCount && pageCount > 1 ? (
              <div className="d-flex justify-content-center">
                <TablePagination
                  pageCount={pageCount}
                  activePage={page}
                  onPageChange={onPageChange}
                  datacy="denial-queue-container-Pagination"
                />
              </div>
            ) : null}
          </div>
        </div>
        <AppealioTable
          columns={columns}
          onSort={onTableSort}
          data={claimStatusHistory}
          isLoading={isFetchingClaimStatusHistory}
          onRowClick={onRowClick}
          rowProps={(row) => {
            if (isStatusEffectiveDateDiff(row.original)) {
              return {
                className: 'appealio-table__body-row--highlighted',
              };
            }
          }}
          className={classnames(`status-tracker-table`)}
          defaultSortBy={sortBy}
          datacy="status-tracker-AppealioTable"
        />
      </div>

      {claimInfoModal.isOpen &&
        (claimInfoModal.api === PAYER_API_TYPE.AVAILITY ? (
          <SuccessModalAvaility
            claimInfo={claimInfoModal.claimInfo}
            onCloseClick={closeClaimInfoModal}
            showQuickActions={false}
          />
        ) : (
          <SuccessModalUHC
            claimInfo={claimInfoModal.claimInfo}
            onCloseClick={closeClaimInfoModal}
            showQuickActions={false}
          />
        ))}
      <RightSlideOut
        isOpen={claimHistoryFlyover.isOpen}
        onDimRegionPressed={closeClaimHistoryFlyover}
        datacy="denial-queue-container-popup-open-RightSlideOut"
      >
        <ClaimStatusCheckHistory id={claimHistoryFlyover.id} />
      </RightSlideOut>
      {showFilters && (
        <StatusTrackerPopupFilter
          filters={filters}
          setShowFilters={setShowFilters}
          filterOptions={filterOptions}
          onFilterChange={handleFilterChange}
          filterKeysToOmit={filterKeysToOmit}
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);
  const userPractices = get(userInfo, 'relatedPractices', []);
  return {
    userInfo,
    userPractices,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        push,
        setAppealData,
        openUhcClaimStatusWidget,
        openAvailityClaimStatusWidget,
      },
      dispatch
    ),
  };
}

StatusTracker.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(StatusTracker);
