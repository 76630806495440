import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { replace } from 'connected-react-router';
import React, { useState } from 'react';

import * as routes from 'constants/routes';

import { setAutoLoginInfo, signOut } from 'redux/actions/loginActions';
import { clearCurrentAppealData } from 'redux/actions/createAppealActions';
import { clearCurrentShippingAddress } from 'redux/actions/appealFormPrefillActions';

import { getUserInfo } from 'redux/reducers/loginStore';
import { createSubmitPackage } from 'redux/actions/createSubmitPackageActions';

import CreateAppealAPI from 'API/CreateAppealAPI';

import { isAppealioExpressUser } from 'Auth/AuthUtils';

import AppealioPopup from 'components/Shared/AppealioPopup';

import mailIcon from 'img/email-action-add.svg';

// TODO: Refactor and move to a separate file
let StandAloneNav = (props) => {
  const [isDisableSubmission, setIsDisableSubmission] = useState(false);
  const getSubmitPackage = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.onClickHideMenu();
    try {
      await props.actions.clearCurrentAppealData({
        isStandAlone: true,
      });
      await props.actions.clearCurrentShippingAddress();
      const {
        value: { id },
      } = await props.actions.createSubmitPackage(
        { single: 1 },
        'submitPackage'
      );
      await CreateAppealAPI.deleteSubmissionDocuments(id);
      props.actions.replace(routes.CREATE_APPEAL_DOCUMENTS);
    } catch (e) {
      if (e.response.status === 402) {
        props.actions.replace(routes.PAYMENT_DETAILS);
      }
      if (e.response.status === 422) {
        setIsDisableSubmission(true);
      }
    }
  };
  const isAuthorizedForStandAloneOnly = isAppealioExpressUser(props.userInfo);
  const resetModal = () => {
    setIsDisableSubmission(false);
  };

  return (
    <>
      <button
        onClick={getSubmitPackage}
        className={classnames(
          `ap-dropmenu__item  ${
            isAuthorizedForStandAloneOnly
              ? 'postal-submission-btn--standalone'
              : ''
          }`
        )}
      >
        <span>Send Mail/Fax Anywhere</span>
        <img src={mailIcon} alt="mail fax anywhere" width="20" />
      </button>
      {isDisableSubmission && (
        <AppealioPopup
          title={'Message'}
          className="submission-details standalone-submission-details"
          onClosePressed={resetModal}
        >
          <div className="text-center">
            Additional submissions cannot be made due to payment issues with
            Stripe. Please contact with{' '}
            <a href="mailto:cx@docvocate.com">cx@docvocate.com</a> for
            assistance.
          </div>
        </AppealioPopup>
      )}
    </>
  );
};

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);
  if (!userInfo) {
    return {};
  }
  return {
    userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setAutoLoginInfo,
        signOut,
        replace,
        createSubmitPackage,
        clearCurrentAppealData,
        clearCurrentShippingAddress,
      },
      dispatch
    ),
  };
}
StandAloneNav = connect(mapStateToProps, mapDispatchToProps)(StandAloneNav);

export default StandAloneNav;
