import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FaInfoCircle } from 'react-icons/fa';

import './style.css';

import * as toast from '../../../Shared/toast';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import LoadingBlockHelper from '../../../Shared/LoadingBlockHelper';

import { fetchPatientInsurances } from '../../../../API/CostEstimatorAPI';
import { get as _get } from 'lodash';

/**
 * Use patients hook.
 * @param {Object} filters
 * @returns {Promise}
 */
const useFetchPatientInsurance = (patientId) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [insurances, setInsurances] = useState([]);

  useEffect(() => {
    setLoading(true);

    const fetch = async () => {
      const data = await fetchPatientInsurances(patientId);
      const eligibleInsurances = data.filter((insurance) => {
        const hasEmptyInsuranceInfo =
          !insurance.number &&
          !(insurance.plan.display || insurance.plan.name) &&
          !insurance.policyHolder.firstName &&
          !insurance.policyHolder.lastName;
        const isValidInsurance =
          _get(insurance, 'eligibility.status') === 'Eligible' &&
          !hasEmptyInsuranceInfo;

        return isValidInsurance;
      });

      setError(null);
      setInsurances(eligibleInsurances);
      setLoading(false);
    };

    fetch();
  }, [patientId]);

  return { loading, error, insurances };
};

const InsuranceList = (props) => {
  const { patientId, onSelectInsurance } = props;
  const { loading, insurances } = useFetchPatientInsurance(patientId);

  const dataNotAvailableMessage = `${props.patientName}'s insurance information is incomplete in Athena. Please enter their insurance information in Athena and try again.`;

  useEffect(() => {
    if (!insurances || insurances.length === 0) return;

    const emptyInsurancesPackageIds = insurances
      .filter((insurance) => !insurance.plan.name)
      .map((insurance) => insurance.packageid);

    if (emptyInsurancesPackageIds.length === 0) return;

    toast.warning({
      title: '',
      message: `Insurance Plan information is unavailable for Practice Management System Insurance Package ID${
        emptyInsurancesPackageIds.length > 1 ? 's' : ''
      }: ${emptyInsurancesPackageIds.join(', ')}`,
    });
  }, [insurances]);

  const handleInsuranceSelect = (insurance) => onSelectInsurance(insurance);

  if (loading) {
    return (
      <LoadingBlockHelper
        className="dashboard-bottom-loader"
        isLoading={loading}
      />
    );
  }

  const renderSubscriberName = (insurance) => {
    if (!insurance.policyHolder.firstName && !insurance.policyHolder.lastName) {
      return <span className="color-red">Unavailable</span>;
    }

    return `${insurance.policyHolder.firstName} ${insurance.policyHolder.lastName}`;
  };

  return (
    <div
      className="patient-cost-estimator-content no-print"
      datacy="insurance-list"
    >
      <hr className="separator-line" />
      <div className="cost-estimator-create-estimates-table">
        <h4 className="patient-cost-estimator__title">
          Insurance Search Results
        </h4>
        {!loading && insurances.length === 0 ? (
          <div className="insurance-not-available">
            <FaInfoCircle className="mt-2 mr-4" />
            {dataNotAvailableMessage}
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>
                  <span className="ml-20">Member ID</span>
                </th>
                <th>Subscriber</th>
                <th>Insurance Plan</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="4">
                    <div className="position-relative">
                      <LoadingIndicator showing={true} />
                    </div>
                  </td>
                </tr>
              ) : (
                insurances.map((insurance, idx) => (
                  <tr key={idx}>
                    <td>
                      <label className="mb-0">
                        <input
                          name="insuranceId"
                          type="radio"
                          onChange={() => handleInsuranceSelect(insurance)}
                          datacy={`insurance-list-radio-${idx}`}
                        />
                        <span
                          className={classnames('ml-8 fw-normal', {
                            'color-red': !insurance.number,
                          })}
                        >
                          {insurance.number || 'Unavailable'}
                        </span>
                      </label>
                    </td>
                    <td>{renderSubscriberName(insurance)}</td>
                    <td
                      className={classnames({
                        'color-red': !(
                          insurance.plan.display || insurance.plan.name
                        ),
                      })}
                    >
                      {insurance.plan.display ||
                        insurance.plan.name ||
                        'Unavailable'}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

InsuranceList.propTypes = {
  patientId: PropTypes.string.isRequired,
  onSelectInsurance: PropTypes.func.isRequired,
};

export default InsuranceList;
