import classnames from 'classnames';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import ReactDOMServer from 'react-dom/server';
import React, { useEffect, useState } from 'react';
import { identity, isEmpty, pickBy } from 'lodash';
import {
  Field,
  reduxForm,
  SubmissionError,
  getFormSyncErrors,
  formValueSelector,
} from 'redux-form/immutable';

import Input from 'components/common/input';
import Select from 'components/common/select/Select';
import DvVerifiedIcon from 'components/common/dvVerified';

import {
  validateURL,
  validateZipCode,
  validateFaxNumber,
  validateEmail,
} from 'helpers/validators';
import { MASK_PHONE } from 'helpers/masks';

import { states } from 'components/CreateAppeal/state-mock-data';
import AccordionPayerContactInfo from '../Accordion/AccordionPayerContact';

import './style.css';

import { getUserInfo } from 'redux/reducers/loginStore';
import {
  isAuthorizedForEmailSubmission,
  isAppealioExpressUser,
  isSuperUserOrStaffUser,
} from 'Auth/AuthUtils';

import { PAYER_CONTACT_TYPES } from 'constants/appConstants';

import { useFetchDvVerifiedMailAndFaxDropdown } from './hooks';

let AddPayerContactForm = (props) => {
  const [dvVerifiedAddresses, setDvVerifiedAddresses] = useState({
    selectedVerifiedMailAddress: null,
    selectedVerifiedFaxAddress: null,
  });
  const {
    handleSubmit,
    onSubmit,
    error,
    formErrors,
    initialValues,
    initialize,
    pristine,
    payerId,
  } = props;
  const stateOptions = states.map((state) => ({
    label: state.value,
    value: state.key,
  }));

  const handleFormSubmit = async (formValues) => {
    const values = formValues.toJS();
    if (
      !values.fax &&
      !values.address1 &&
      !values.payerPortalUrl &&
      !values.email
    ) {
      throw new SubmissionError({
        _error:
          'Requires at least one Mailing Address, Fax Number, or Payer Portal URL or Email',
      });
    }
    await onSubmit({
      name: formValues.get('name') || '',
      address_1: formValues.get('address1') || '',
      city: formValues.get('city') || '',
      state: values?.state?.value || '',
      zipcode: formValues.get('zipcode') || '',
      fax: formValues.get('fax') || '',
      payer_portal_url: formValues.get('payerPortalUrl') || '',
      email: formValues.get('email') || '',
    });
  };

  useEffect(() => {
    const values = initialValues && initialValues.toJS();
    const selectedState = stateOptions.find(
      (option) => option.value === values.state
    );

    initialize({
      ...values,
      state: selectedState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { contacts } = useFetchDvVerifiedMailAndFaxDropdown(payerId);

  const isSameAddress = (contact1, contact2) => {
    return (
      (contact1.address1 || '') === (contact2.address1 || '') &&
      (contact1.address2 || '') === (contact2.address2 || '') &&
      (contact1.city || '') === (contact2.city || '') &&
      (contact1.state || '') === (contact2.state || '') &&
      (contact1.zipcode || '') === (contact2.zipcode || '')
    );
  };

  const isVerifiedMail =
    initialValues.get('dvVerifiedMail') &&
    isSameAddress(
      {
        address1: initialValues.get('address1'),
        city: initialValues.get('city'),
        state: initialValues.get('state'),
        zipcode: initialValues.get('zipcode'),
      },
      props.mailParams
    );

  const renderMailOrFaxAddress = (address) => {
    const dvVerifiedMail = address?.dvVerifiedMail;
    const dvVerifiedFax = address?.dvVerifiedFax;

    const mappedAddressInfo = {
      Address: address.address1,
      'Address 2': address.address2,
      City: address.city,
      State: address.state,
      ZIP: address.zipcode,
    };
    const contactInfo = pickBy(mappedAddressInfo, identity);
    return (
      <div>
        {dvVerifiedMail || dvVerifiedFax ? (
          <p className="dv-verified-legend">
            DV
            <span className="ml-2">Verified</span>
          </p>
        ) : null}
        {address?.address1 ? (
          Object.entries(contactInfo).map(([key, value], idx) => (
            <div key={idx}>
              <strong>{key}</strong>: {value || 'N/A'}
            </div>
          ))
        ) : (
          <div>
            <strong>Fax:</strong>
            {address?.fax || 'N/A'}
          </div>
        )}
      </div>
    );
  };

  const renderSelectAddressOptionLabel = (option) => {
    const tooltipId = String(option.value);
    const isOptionVerified = option?.dvVerifiedMail || option?.dvVerifiedFax;
    const dataTipContent = ReactDOMServer.renderToStaticMarkup(
      renderMailOrFaxAddress(option)
    );
    const isContactOptionMatched = option?.contactExists;

    return (
      <div>
        <div
          className={classnames({
            'position-relative d-flex align-items-center justify-content--space-between':
              isOptionVerified,
            'opacity-50': isContactOptionMatched,
          })}
        >
          <span data-for={tooltipId} data-tip={dataTipContent} data-html={true}>
            {option.label}
            {isOptionVerified ? (
              <DvVerifiedIcon className="dv-verified-field dv-verified-field--select z-index-9 " />
            ) : option?.payerContactsCount ? (
              <span>&nbsp;&nbsp;({option.payerContactsCount})</span>
            ) : null}
          </span>
        </div>
        <ReactTooltip
          id={tooltipId}
          delayShow={300}
          effect="solid"
          place="top"
        />
      </div>
    );
  };

  const autoSelectSubmitTypeDropdown = (contactId, submitType) => {
    if (!contactId) {
      return;
    }
    const getcontacts = submitType === 'Mail' ? contacts?.mail : contacts?.fax;
    const selectedContact = getcontacts.find(
      (item) => item.contactId === contactId
    );

    if (selectedContact) {
      if (submitType === 'Mail') {
        const label =
          `${selectedContact.address1} ${selectedContact.city}, ${selectedContact.state} ${selectedContact.zipcode}` ||
          '';
        setDvVerifiedAddresses((prevState) => ({
          ...prevState,
          selectedVerifiedMailAddress: {
            label,
            value: selectedContact.contactId,
          },
        }));
      }

      if (submitType === 'Fax') {
        setDvVerifiedAddresses((prevState) => ({
          ...prevState,
          selectedVerifiedFaxAddress: {
            label: `${selectedContact.fax}` || '',
            value: selectedContact.contactId,
          },
        }));
      }
    }
  };

  const handleAutofillSelect = (value, submitType) => {
    autoSelectSubmitTypeDropdown(value.contactId, submitType);
    if (submitType === 'Mail') {
      props.change('address1', value.address1);
      props.change('city', value.city);
      const selectedState =
        states?.find((state) => state.key === value.state) || '';
      props.change(
        'state',
        selectedState
          ? {
              label: selectedState.value,
              value: selectedState.key,
            }
          : ''
      );
      props.change('zipcode', value.zipcode);
    }

    if (submitType === 'Fax') {
      props.change('fax', value.fax);
    }
  };

  const sortDataForMailAndFaxDropdownOptions = (a, b, sortBy) => {
    if (a[sortBy] !== b[sortBy]) {
      return b[sortBy] - a[sortBy];
    }
    if (a?.payerContactsCount === undefined) return 1;
    if (b?.payerContactsCount === undefined) return -1;
    return b?.payerContactsCount - a?.payerContactsCount;
  };

  const renderMailForm = () => {
    const mailContact = contacts?.mail;
    const groupContacts = (mailContacts) => {
      if (!mailContacts || mailContacts.length === 0) return [];

      const sortedContacts = mailContacts
        .map((contact) => ({
          label: `${contact.address1} ${contact.city}, ${contact.state} ${contact.zipcode}`,
          value: contact.contactId,
          address1: contact.address1,
          city: contact.city,
          state: contact.state,
          zipcode: contact.zipcode,
          contactId: contact.contactId,
          dvVerifiedMail: contact.dvVerifiedMail,
          ...(contact.contactsCount
            ? { payerContactsCount: contact.contactsCount }
            : {}),
          ...(contact.contactExists
            ? { contactExists: contact.contactExists }
            : {}),
        }))
        .sort((a, b) =>
          sortDataForMailAndFaxDropdownOptions(a, b, 'dvVerifiedMail')
        );

      const dvVerifiedContacts = sortedContacts.filter(
        (contact) => contact.dvVerifiedMail
      );
      const otherContacts = sortedContacts.filter(
        (contact) => !contact.dvVerifiedMail
      );

      return [
        { label: 'DV Verified Mailing Addresses', options: dvVerifiedContacts },
        { label: 'Suggested Mailing Addresses', options: otherContacts },
      ];
    };

    const groupContactsResult = groupContacts(mailContact);

    return (
      <>
        <div className="row mb-12">
          {!isAppealioExpressUser(props.userInfo) && (
            <div className="col-md-12 mb-16">
              <Select
                name="payerContactMail"
                options={groupContactsResult}
                value={dvVerifiedAddresses?.selectedVerifiedMailAddress}
                placeholder={
                  !isEmpty(groupContactsResult)
                    ? isSuperUserOrStaffUser(props.userInfo)
                      ? 'Suggested Mailing Addresses'
                      : 'DV Verified Mailing Addresses'
                    : 'N/A'
                }
                onChange={(value) => handleAutofillSelect(value, 'Mail')}
                reactSelectProps={{
                  formatOptionLabel: (option) => {
                    return renderSelectAddressOptionLabel(option);
                  },
                  maxMenuHeight: 150,
                }}
                isDisabled={isEmpty(groupContactsResult)}
              />
            </div>
          )}

          <div className="col-md-6 position-relative">
            {isVerifiedMail ? (
              <DvVerifiedIcon className="dv-verified-field" />
            ) : null}
            <Field
              component={Input}
              name="address1"
              maxLength={50}
              label="Mailing Address"
              placeholder="Mailing Address"
              required={false}
              datacy="mailing-addess-Field"
            />
          </div>
          <div className="col-md-6">
            <Field
              component={Input}
              name="city"
              label="City"
              maxLength={50}
              placeholder="City"
              required={false}
              datacy="city-Field"
            />
          </div>
        </div>
        <div className="row mb-12">
          <div className="col-md-6">
            <Field
              component={Select}
              options={stateOptions}
              name="state"
              defaultValue={null}
              label="State"
              placeholder="State"
              required={false}
              reactSelectProps={{
                isClearable: true,
              }}
              datacy="state-Field"
            />
          </div>
          <div className="col-md-6">
            <Field
              component={Input}
              required={false}
              name="zipcode"
              label="Zip"
              placeholder="Zip"
              datacy="zipcode-Field"
            />
          </div>
        </div>
      </>
    );
  };

  const renderFaxForm = () => {
    const faxContact = contacts?.fax;

    const groupFaxContacts = (faxContacts) => {
      if (!faxContacts || faxContacts.length === 0) return [];

      const sortedFaxContacts = faxContacts
        .map((contact) => ({
          label: `${contact.fax}`,
          value: contact.contactId,
          fax: contact.fax,
          contactId: contact.contactId,
          dvVerifiedFax: contact.dvVerifiedFax,
          ...(contact.contactsCount
            ? { payerContactsCount: contact.contactsCount }
            : {}),
          ...(contact.contactExists
            ? { contactExists: contact.contactExists }
            : {}),
        }))
        .sort((a, b) =>
          sortDataForMailAndFaxDropdownOptions(a, b, 'dvVerifiedFax')
        );

      const dvVerifiedFaxContacts = sortedFaxContacts.filter(
        (contact) => contact.dvVerifiedFax
      );
      const otherFaxContacts = sortedFaxContacts.filter(
        (contact) => !contact.dvVerifiedFax
      );

      return [
        { label: 'DV Verified Fax Numbers', options: dvVerifiedFaxContacts },
        { label: 'Suggested Fax Numbers', options: otherFaxContacts },
      ];
    };

    const groupFaxContactsOption = groupFaxContacts(faxContact);

    return (
      <div className="row mb-12">
        {
          <div className="col-md-12 mb-16">
            {!isAppealioExpressUser(props.userInfo) && (
              <Select
                options={groupFaxContactsOption}
                value={dvVerifiedAddresses?.selectedVerifiedFaxAddress}
                placeholder={
                  !isEmpty(groupFaxContactsOption)
                    ? isSuperUserOrStaffUser(props.userInfo)
                      ? 'Suggested Fax Numbers'
                      : 'DV Verified Fax Numbers'
                    : 'N/A'
                }
                onChange={(value) => handleAutofillSelect(value, 'Fax')}
                reactSelectProps={{
                  formatOptionLabel: (option) => {
                    return renderSelectAddressOptionLabel(option);
                  },
                  maxMenuHeight: 100,
                }}
                isDisabled={isEmpty(groupFaxContactsOption)}
              />
            )}
          </div>
        }

        <div className="col-md-12 position-relative">
          {initialValues.get('dvVerifiedFax') &&
          initialValues.get('fax') === props.faxParams.fax ? (
            <DvVerifiedIcon className="dv-verified-field" />
          ) : null}
          <Field
            label="Fax Number"
            name="fax"
            placeholder="(DDD) DDD-DDDD"
            type="string"
            mask={MASK_PHONE}
            size="10"
            component={Input}
            datacy="fax-number-Field"
            required={false}
            maskedInput
          />
        </div>
      </div>
    );
  };

  const renderPayerPortalForm = () => {
    return (
      <div className="row mb-12">
        <div className="col-md-12">
          <Field
            required={false}
            component={Input}
            name="payerPortalUrl"
            label="URL"
            placeholder="URL"
            datacy="payer-portal-url-Field"
          />
        </div>
      </div>
    );
  };

  // Added for Secure Email Payer Contacts
  const renderEmailForm = () => {
    return (
      <div className="row mb-12">
        <div className="col-md-12">
          <Field
            required={false}
            component={Input}
            name="email"
            label="Recipient Email"
            placeholder="john.doe@company.com"
            datacy="recipient-email-Field"
          />
        </div>
      </div>
    );
  };

  const payerContactTypes = [
    {
      title: PAYER_CONTACT_TYPES.MAIL,
      content: renderMailForm(),
      formHasError:
        formErrors?.city || formErrors?.state || formErrors?.zipcode,
    },
    {
      title: PAYER_CONTACT_TYPES.FAX,
      content: renderFaxForm(),
      formHasError: formErrors?.fax,
    },
    {
      title: PAYER_CONTACT_TYPES.PAYER_PORTAL,
      content: renderPayerPortalForm(),
      formHasError: formErrors?.payerPortalUrl,
    },
  ];

  if (isAuthorizedForEmailSubmission(props.userInfo)) {
    payerContactTypes.push({
      title: PAYER_CONTACT_TYPES.EMAIL,
      content: renderEmailForm(),
      formHasError: formErrors?.email,
    });
  }

  const accordionPayerContactTypes = isAppealioExpressUser(props.userInfo)
    ? payerContactTypes.filter(
        (contact) => contact.title !== PAYER_CONTACT_TYPES.PAYER_PORTAL
      )
    : payerContactTypes;

  const renderPayerContactForm = () => {
    return (
      <div className="payer-contact-form">
        <p className={classnames('form-step__description')}>
          <i>
            `Submission Destination requires a Fax Number, a Mailing Address,{' '}
            {!isAppealioExpressUser(props.userInfo)
              ? 'a Payer Portal link,'
              : ''}{' '}
            an Email.`
          </i>
        </p>
        {!pristine && (
          <p className="form-step__description--error mt-8">{error}</p>
        )}
        <div className="form-step__wrapper">
          <div className="form-step__content">
            <div className="row mb-12">
              <div className="col-md-12">
                <Field
                  name="name"
                  maxLength={50}
                  label="Contact Name *"
                  placeholder="Contact Name"
                  datacy="contact-name-Field"
                  required={false}
                  component={Input}
                />
              </div>
            </div>
            {/* Mail/Fax/PayerPortal */}
            {accordionPayerContactTypes.map(
              ({ title, content, formHasError }, index) => {
                return (
                  <AccordionPayerContactInfo
                    key={index}
                    title={title}
                    content={content}
                    initialValues={initialValues.toJS()}
                    openContent={Boolean(formHasError)}
                  />
                );
              }
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      datacy="add-payer-contact-form"
    >
      {renderPayerContactForm()}
      <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
        <button
          type="submit"
          disabled={props.submitting}
          className="ap-button ap-button--primary-dark ap-button--primary-md"
          datacy="add-payer-contact-form-submit-button"
        >
          {props?.initialValues?.size > 0 ? 'Update' : 'Add'}
        </button>
      </div>
    </form>
  );
};

AddPayerContactForm.propTypes = {};

const validate = (formValues) => {
  const values = formValues.toJS();
  const validationErrors = {};

  const errorMessages = {
    name: 'Contact Name is required',
    invalidZip:
      'Invalid ZIP code. Please update the ZIP code in the formats: 12345, 1234512345, 12345-6789',
    address1: 'Mailing Address is required',
    longAddress: 'Mailing Address cannot be more than 50 characters',
    city: 'City is required',
    state: 'State is required',
    zipcode: 'zipcode is required',
    invalidFax: 'Fax number is invalid',
    invalidPayerPortalUrl: 'Payer Portal URL is invalid',
  };

  if (!values.name) {
    validationErrors.name = errorMessages.name;
  }

  if (values.zipcode && validateZipCode(values.zipcode)) {
    validationErrors.zipcode = errorMessages.invalidZip;
  }

  if (values.address1 && values.address1.length > 50) {
    validationErrors.address1 = errorMessages.longAddress;
  }

  if (values.address1 && (!values.city || !values.state || !values.zipcode)) {
    if (!values.city) validationErrors.city = errorMessages.city;
    if (!values.state) validationErrors.state = errorMessages.state;
    if (!values.zipcode) validationErrors.zipcode = errorMessages.zipcode;
  }

  if (!values.address1 && (values.city || values.state || values.zipcode)) {
    validationErrors.address1 = errorMessages.address1;
  }

  if (values.fax) {
    const faxNumberValidation = validateFaxNumber(values.fax);
    if (faxNumberValidation) {
      validationErrors.fax = faxNumberValidation;
    }
  }

  if (values.payerPortalUrl && validateURL(values.payerPortalUrl)) {
    validationErrors.payerPortalUrl = errorMessages.invalidPayerPortalUrl;
  }

  // validate Recipient address for Email
  if (values.email) {
    const emailValidation = validateEmail(values.email);
    if (emailValidation) {
      validationErrors.email = emailValidation;
    }
  }

  return validationErrors;
};

AddPayerContactForm = reduxForm({
  form: 'addPayerContactForm',
  validate,
})(AddPayerContactForm);

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);
  const selector = formValueSelector('addPayerContactForm');
  const faxParams = {
    fax: selector(state, 'fax'),
  };
  const mailParams = {
    address1: selector(state, 'address1'),
    city: selector(state, 'city'),
    state: selector(state, 'state')?.toJS()?.value,
    zipcode: selector(state, 'zipcode'),
  };
  const formErrors = getFormSyncErrors('addPayerContactForm')(state);

  return {
    faxParams,
    mailParams,
    formErrors,
    userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ initialize }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPayerContactForm);
