import React from 'react';
import PropTypes from 'prop-types';

import {
  DATE_WITH_TIME_FORMAT_AM_PM,
  formatUtcToLocalDateTime,
} from 'helpers/dateFormats';

import './style.css';

import NotesImg from 'img/no-notes.svg';

const NotesList = (props) => {
  const { notes, agents, userInfo } = props;
  const mapToAgentName = (username) => {
    const user = agents.find((agent) => agent.value === username);
    if (user) {
      const { name } = user;
      return name;
    } else if (username === userInfo.username) {
      return `${userInfo.firstName} ${userInfo.lastName}`;
    } else {
      return 'DocVocate User';
    }
  };

  return notes.length > 0 ? (
    <ul className="note-list">
      {notes
        .sort((a, b) => b.id - a.id)
        .map((note, idx) => (
          <li className="note-list__item" key={idx}>
            <p className="note-list__item-info">
              <span>{mapToAgentName(note.by)}</span>
              <span>
                {formatUtcToLocalDateTime(
                  note.createdAt,
                  DATE_WITH_TIME_FORMAT_AM_PM
                )}
              </span>
            </p>
            <p className="note-list__item-note">{note.note}</p>
          </li>
        ))}
    </ul>
  ) : (
    <div className="no-notes-wrapper">
      <object className="no-notes-mage" data={NotesImg} type="image/svg+xml">
        Notes Icon{' '}
      </object>
    </div>
  );
};

NotesList.propTypes = {
  notes: PropTypes.array,
  agents: PropTypes.array,
};

export default NotesList;
