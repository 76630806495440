import { debounce } from 'lodash';
import ReactTooltip from 'react-tooltip';
import React, { useState, useCallback } from 'react';

import { useFetchUsersAccessControl } from '../../hooks';

import AddUser from './AddUser';

import Pagination from 'components/common/pagination';
import SearchInput from 'components/common/input/SearchInput';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';

import { SETTINGS_PAGE_LIMIT } from 'constants/appConstants';

import emptyIcon from 'img/empty.svg';

const Users = (props) => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [isUserPopupOpen, setIsUserPopupOpen] = useState(false);

  const handleSearchSubmit = (event) => {
    setPage(1);
    setSearch(event.target.value);
  };

  const onSearch = useCallback(
    (args) => debounce(handleSearchSubmit, 800)(args),
    []
  );

  const onClosePressed = () => {
    setIsUserPopupOpen(false);
  };

  const {
    loading,
    usersAccessControl: users,
    totalUsers,
    fetch,
  } = useFetchUsersAccessControl(search, page);

  const onPageChange = (page) => setPage(page);
  const pageCount = Math.ceil(totalUsers / SETTINGS_PAGE_LIMIT);

  return (
    <div className="settings-content-wrapper">
      <div className="d-flex align-items-center justify-content--space-between settings-content__top-action">
        <SearchInput
          value={search}
          placeholder="Search By Name"
          onSearch={onSearch}
          onReset={() => setSearch('')}
          datacy="users-SearchBox"
        />
        <button
          onClick={() => {
            setIsUserPopupOpen(true);
          }}
          className="ap-button ap-button--secondary ml-auto settings-add-button"
          datacy="settings-add-button"
        >
          <span className="mr-4">+</span> Add User
        </button>
      </div>
      <div className="ap-card-list-wraper mt-12" datacy="users">
        {loading && loading ? (
          <LoadingBlockHelper
            className="dashboard-bottom-loader"
            isLoading={loading}
          />
        ) : users.length === 0 ? (
          <div className="no-data-container">
            <img src={emptyIcon} alt="No Data Found" />
            <h4>No Data Found</h4>
          </div>
        ) : (
          <React.Fragment>
            <div className="row ap-list-header create-acount-list-item-header">
              <div className="col-lg-2">First Name</div>
              <div className="col-lg-2">Last Name</div>
              <div className="col-lg-2">Email Address</div>
              <div className="col-lg-2">Username</div>
              <div className="col-lg-2">Phone Number</div>
              <div className="col-lg-2">Fax Number</div>
            </div>
            <ul className="ap-card-list">
              {users &&
                users.map((user, idx) => {
                  return (
                    <div
                      className="create-account-list-item row mb-16"
                      key={idx}
                    >
                      <div className="col-lg-2">
                        <span data-tip={user.firstName}>
                          {user.firstName || '--'}
                        </span>
                      </div>
                      <div className="col-lg-2">
                        <span data-tip={user.lastName}>
                          {user.lastName || '--'}
                        </span>
                      </div>
                      <div className="col-lg-2">
                        <span data-tip={user.email}>{user.email || '--'}</span>
                      </div>
                      <div className="col-lg-2">
                        <span data-tip={user.username}>
                          {user.username || '--'}
                        </span>
                      </div>
                      <div className="col-lg-2">
                        <span data-tip={user.phone}>{user.phone || '--'}</span>
                      </div>
                      <div className="col-lg-2">
                        <span data-tip={user.fax}>{user.fax || '--'}</span>
                      </div>

                      <ReactTooltip effect="solid" multiline={true} />
                    </div>
                  );
                })}
            </ul>
          </React.Fragment>
        )}
        {isUserPopupOpen && (
          <AddUser
            onClosePressed={onClosePressed}
            datacy="user-AddUser"
            fetchUsers={fetch}
          />
        )}

        {!loading && totalUsers && pageCount > 1 ? (
          <div className="d-flex justify-content-center">
            <Pagination
              pageCount={pageCount}
              activePage={page}
              onPageChange={onPageChange}
              datacy="users-Pagination"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Users;
