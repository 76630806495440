import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { Component } from 'react';

import uploadImg from '../../../img/upload-2.svg';

export default class SelectFileToUploadButton extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    type: PropTypes.string,
  };

  static defaultProps = {
    onClick: () => {},
  };

  render() {
    return (
      <div className="select_file_button-wrapper">
        <button
          onClick={this.props.onClick}
          type={this.props.type}
          className={classNames(
            'select_file_button',
            'select_file_button--left'
          )}
          disabled={this.props.isSelectButtonDisabled}
        >
          <img alt="Upload Icon" src={uploadImg} />
        </button>
        <button
          onClick={this.props.onClick}
          type={this.props.type}
          className={classNames(
            'select_file_button',
            'select_file_button--right'
          )}
          disabled={this.props.isSelectButtonDisabled}
        >
          {this.props.btnTitle || 'Select File to Upload'}
        </button>
      </div>
    );
  }
}
