import classNames from 'classnames';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { AiOutlineFolder } from 'react-icons/ai';

import { updatePracticeGroup } from 'API/AccountSettingsAPI';

import { handleError } from 'helpers/errorHandler';

import Input from 'components/common/input';
import Button from 'components/common/button';
import * as toast from 'components/Shared/toast';
import { AlertDialog, AppealioPopupWithFooter } from 'components/common/popup';

import { BUTTON_TYPE } from 'components/common/button/Button';

const DEFAULT_SHOW_CONFIRM_CANCEL_POPUP = {
  isOpen: false,
  addedPractices: [],
  removedPractices: [],
};

const AddedRemovedPracticesList = ({ practices, title, className }) => {
  if (!practices.length) return null;
  const top2Practices = practices
    .slice(0, 2)
    .map((practice) => practice.label)
    .join(', ');
  const allPracticeNames = practices.map((practice) => practice.label);

  const tooltipId = `practice-group-confirm-cancel-popup-tooltip-${title}`;
  return (
    <div
      className={classNames('practice-group-confirm-cancel-popup', className)}
    >
      <span className={`practice-group-confirm-cancel-popup__title`}>
        {title}:
      </span>
      <span data-tip data-for={tooltipId}>
        <span className={`practice-group-confirm-cancel-popup__item`}>
          {top2Practices}
        </span>
        {practices.length > 2 && (
          <span className={`practice-group-confirm-cancel-popup__item`}>
            + {practices.length - 2} practices
          </span>
        )}
      </span>

      <ReactTooltip
        id={tooltipId}
        place="top"
        className="tooltip-details-background"
        arrowColor="transparent"
      >
        {allPracticeNames.map((practiceName, idx) => (
          <div key={idx}>
            {idx + 1}. {practiceName}
          </div>
        ))}
      </ReactTooltip>
    </div>
  );
};

const AssignPractice = ({
  onClosePressed,
  practiceGroupId,
  practiceGroupName,
  onAssignPracticeComplete,
  practices: initialPractices,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [practices, setPractices] = useState([...initialPractices]);
  const [showConfirmCancelPopup, setShowConfirmCancelPopup] = useState(
    DEFAULT_SHOW_CONFIRM_CANCEL_POPUP
  );

  const filteredPractices = practices.filter((practice) =>
    practice.label.toLowerCase().includes(searchValue.toLowerCase())
  );

  /**
   * Handles the search change.
   * @param {Object} e
   */
  const handleSearchChange = (e) => setSearchValue(e.target.value);

  /**
   * Toggles the practice selection.
   * @param {Object} value
   */
  const togglePracticeSelection = (value) =>
    setPractices(
      practices.map((p) => {
        if (p.value === value) {
          return { ...p, selected: !p.selected };
        }
        return p;
      })
    );

  /**
   * Handles the form submission.
   */
  const handleSave = async () => {
    const selectedPractices = practices
      .filter((practice) => practice.selected)
      .map((practice) => practice.value);

    try {
      setIsSubmitting(true);
      await updatePracticeGroup(practiceGroupId, {
        practices: selectedPractices,
        name: practiceGroupName,
      });
      onAssignPracticeComplete();
      toast.success({
        title: 'Success',
        message: `${selectedPractices.length} practice(s) assigned to ${practiceGroupName} practice group.`,
      });
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancelClick = () => {
    const extractSelected = (practicesArray) =>
      practicesArray.filter((practice) => practice.selected);
    const extractValues = (practicesArray) =>
      practicesArray.map((practice) => practice.value);

    const selectedPractices = extractSelected(practices);
    const initialSelectedPractices = extractSelected(initialPractices);

    const selectedPracticeValues = extractValues(selectedPractices);
    const initialPracticeValues = extractValues(initialSelectedPractices);

    const hasChanges =
      !selectedPracticeValues.every((value) =>
        initialPracticeValues.includes(value)
      ) || selectedPracticeValues.length !== initialPracticeValues.length;

    if (!hasChanges) {
      return onClosePressed();
    }

    const findDifference = (base, comparator) =>
      base.filter(
        (baseItem) =>
          !comparator.some(
            (comparatorItem) => comparatorItem.value === baseItem.value
          )
      );

    const addedPractices = findDifference(
      selectedPractices,
      initialSelectedPractices
    );
    const removedPractices = findDifference(
      initialSelectedPractices,
      selectedPractices
    );

    setShowConfirmCancelPopup({
      isOpen: true,
      addedPractices,
      removedPractices,
    });
  };

  return (
    <AppealioPopupWithFooter
      className={classNames(
        'appealio-popup--v3 appealio-popup--v3--overflow-hidden'
      )}
      onClosePressed={handleCancelClick}
      title={
        <div className="d-flex align-items-center">
          <AiOutlineFolder size="22" className="mr-4" />
          Add/Remove Practice(s) from{' '}
          <span className="ml-4 fw-bold"> {practiceGroupName}</span>
        </div>
      }
      isFooterOutside={false}
    >
      <div className="practice-group__sticky-search-bar row mb-12">
        <div className="col-lg-12">
          <Input
            label="Search for Practice"
            placeholder="Practice Name"
            onChange={handleSearchChange}
            value={searchValue}
          />
        </div>
        <div className="col-lg-12">
          <div className="d-flex justify-content-flex-end mt-2">
            <span className="fs-12">
              Total Selected: {practices.filter((x) => x.selected).length}
            </span>
          </div>
        </div>
      </div>
      <div className="row mb-12">
        <div className="col-lg-12">
          <ul className="practice-group__practice-list">
            {filteredPractices.map((practice) => (
              <li
                className="practice-group__practice-list--item cursor-pointer"
                key={practice.value}
                onClick={() => togglePracticeSelection(practice.value)}
              >
                <input type="checkbox" checked={practice.selected} readOnly />
                <span>{practice.label}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
        <button
          onClick={handleSave}
          className="ap-button ap-button--primary-dark ap-button--primary-md justify-content-center"
          disabled={isSubmitting}
        >
          Save
        </button>
      </div>

      {showConfirmCancelPopup.isOpen && (
        <AlertDialog
          onClosePressed={() => {
            setShowConfirmCancelPopup(DEFAULT_SHOW_CONFIRM_CANCEL_POPUP);
          }}
          className="import-rule-practice-agent-permission"
        >
          You have made changes in selection of practice for this practice
          group. If you proceed without saving, your changes will be lost.
          <div className="mt-16">
            <AddedRemovedPracticesList
              practices={showConfirmCancelPopup.addedPractices}
              title="Added Practice(s)"
              className="mb-4 mt-8"
            />
            <AddedRemovedPracticesList
              practices={showConfirmCancelPopup.removedPractices}
              title="Removed Practice(s)"
            />
          </div>
          <hr className="card-horizontal-line" />
          <div className="import-rule-practice-agent-permission__buttons-wrapper">
            <Button
              type="secondary"
              title="Proceed Without Saving"
              onClick={() => onClosePressed()}
            />
            <Button
              title="Cancel"
              type={BUTTON_TYPE.LIGHT}
              className="width-80 justify-content-center fw-normal ml-8"
              onClick={() => {
                setShowConfirmCancelPopup(DEFAULT_SHOW_CONFIRM_CANCEL_POPUP);
              }}
            />
          </div>
        </AlertDialog>
      )}
    </AppealioPopupWithFooter>
  );
};

export default AssignPractice;
