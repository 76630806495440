import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import Footer from '../Shared/Footer';
import SubmitButton from '../Shared/SubmitButton';

import xrayIcon from '../../img/xray.svg';

import * as routes from 'constants/routes';

class NotFound extends Component {
  render() {
    return (
      <div className="not-found" datacy="not-found-component">
        <div className="row not-found__padding-container">
          <img alt="Not Found" className="not-found__icon" src={xrayIcon} />
        </div>
        <div className="row not-found__padding-container">
          <span className="col-xs-offset-3 col-xs-6 not-found__text">
            Yep. It’s definitely broken, but it’s nothing we can’t fix.
          </span>
        </div>
        <div className="row not-found__padding-container">
          <SubmitButton
            className={'col-xs-offset-5 col-xs-2 not-found__button'}
            title={'Take me home'}
            onClick={this.onHomePressed}
            datacy="not-found-SubmitButton"
          />
        </div>
        <div>
          <Footer
            className="not-found__footer-color"
            datacy="not-found-Footer"
          />
        </div>
      </div>
    );
  }

  onHomePressed = () => {
    this.props.actions.push({
      pathname: routes.SUBMISSIONS,
    });
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ push }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(NotFound);
