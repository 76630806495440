import { isEmpty, isEqual } from 'lodash';
import classnames from 'classnames';
import React, { useState } from 'react';

import AddPayerForm from './AddPayerForm';
import * as toast from 'components/Shared/toast';
import AddPayerContactForm from './AddPayerContactForm';
import { AppealioPopupWithFooter } from 'components/common/popup';

import { handleError } from 'helpers/errorHandler';

import * as AccountsAPI from 'API/AccountSettingsAPI';
import { states } from 'components/CreateAppeal/state-mock-data';

const AddPayer = (props) => {
  const {
    onClosePressed,
    payerToUpdate,
    refetchPayers,
    isSuperUser,
    payerIdentifiersOptions,
    isPayerIdentifiersLoading,
    addPayerIdentifierOption,
  } = props;
  const [isNextPage, setIsNextPage] = useState(false);
  const [payerId, setPayerId] = useState(null);

  let initialValues = payerToUpdate;
  if (payerToUpdate) {
    const stateOptions = states.map((state) => ({
      label: state.value,
      value: state.key,
    }));
    const selectedState = stateOptions.find(
      (option) => option.value === payerToUpdate.payerState
    );
    const alias = payerToUpdate?.alias?.join(', ');
    const identifiers = payerToUpdate?.payerIdentifier?.map((identifier) =>
      payerIdentifiersOptions?.find(
        (payerIdentifier) => payerIdentifier.id === identifier.id
      )
    );

    initialValues = {
      ...payerToUpdate,
      ...(payerToUpdate && { state: payerToUpdate.payerState }),
      ...(payerToUpdate && { city: payerToUpdate.payerCity }),
      ...(payerToUpdate && { zipcode: payerToUpdate.payerZipcode }),
      ...(payerToUpdate && { state: selectedState }),
      ...(payerToUpdate && { alias }),
      ...(payerToUpdate && { identifiers }),
    };
  }

  const handlePayerCreateUpdateError = async (error) => {
    const errorResponse = error.response;
    if (errorResponse.status === 400) {
      const response = await error.response.json();
      if (response.name) {
        return toast.error({
          message: response.name[0],
          title: 'Error',
        });
      }
      if (!isEmpty(response)) {
        Object.values(response).forEach((value) => {
          toast.error({
            message: value[0],
            title: 'Error',
          });
        });
      }
    }
    handleError(error);
  };

  const onPayerSubmit = async (values) => {
    const { id, alias, identifiers } = values;
    const hasChanged = (newValue, oldValue) => newValue !== oldValue;
    const formDataAPIfieldMappings = {
      name: 'name',
      payerAddress1: 'payer_address1',
      payerAddress2: 'payer_address2',
      city: 'payer_city',
      state: 'payer_state',
      zipcode: 'payer_zipcode',
    };

    const formData = {};
    if (id && payerToUpdate) {
      formData.id = id;
    }

    if (hasChanged(alias, initialValues ? initialValues?.alias : null)) {
      formData.alias = alias?.split(',');
    }

    const payerIdentifiers = identifiers?.map((identifiers) => identifiers.id);
    const initialPayerIdentifiers = initialValues
      ? initialValues?.identifiers
      : [];

    if (
      !(isEmpty(payerIdentifiers) && isEmpty(initialPayerIdentifiers)) &&
      !isEqual(payerIdentifiers, initialPayerIdentifiers)
    ) {
      formData.identifiers = identifiers?.map((identifier) => identifier.id);
    }

    for (const key in formDataAPIfieldMappings) {
      if (hasChanged(values[key], initialValues ? initialValues[key] : null)) {
        formData[formDataAPIfieldMappings[key]] = values[key];
      }
    }

    if (!payerToUpdate) {
      try {
        const { id } = await AccountsAPI.createPayer(formData);
        toast.success({
          title: 'Success',
          message: 'Payer added successfully.',
        });
        setPayerId(id);
        refetchPayers();
        setIsNextPage(true);
      } catch (error) {
        handlePayerCreateUpdateError(error);
      }
    } else {
      try {
        const { id } = await AccountsAPI.updatePayer(formData);
        refetchPayers();
        toast.success({
          title: 'Success',
          message: 'Payer updated successfully.',
        });
        setPayerId(id);
        onClosePressed();
      } catch (error) {
        handlePayerCreateUpdateError(error);
      }
    }
  };

  const onPayerCloneSubmit = async (values) => {
    try {
      await AccountsAPI.clonePayer(values);
      toast.success({
        title: 'Success',
        message: 'Payer added successfully.',
      });
      refetchPayers();
      onClosePressed();
    } catch (error) {
      handleError(error);
    }
  };

  const onPayerContactSubmit = async (values) => {
    try {
      await AccountsAPI.createPayerContact({
        ...values,
        payerId,
      });
      toast.success({
        title: 'Success',
        message: 'Payer Contact added successfully.',
      });
      refetchPayers();
      onClosePressed();
    } catch (error) {
      handleError(error);
    }
  };

  let title = 'Add Payer';

  if (payerToUpdate && !isNextPage) {
    title = 'Edit Payer';
  }

  if (!payerToUpdate && isNextPage) {
    title = 'Add Payer Contact';
  }

  if (payerToUpdate && isNextPage) {
    title = 'Edit Payer Contact';
  }

  return (
    <AppealioPopupWithFooter
      className={classnames('appealio-popup--v3', {
        'add-payer-contact-popup': isNextPage,
      })}
      onClosePressed={onClosePressed}
      title={title}
      isFooterOutside={false}
      datacy="add-payer-AppealioPopupWithFooter"
    >
      {!isNextPage && (
        <AddPayerForm
          onSubmit={onPayerSubmit}
          onPayerCloneSubmit={onPayerCloneSubmit}
          initialValues={initialValues}
          datacy="add-payer-AddPayerForm"
          isSuperUser={isSuperUser}
          payerIdentifiersOptions={payerIdentifiersOptions}
          isPayerIdentifiersLoading={isPayerIdentifiersLoading}
          addPayerIdentifierOption={addPayerIdentifierOption}
        />
      )}
      {isNextPage && (
        <AddPayerContactForm
          onSubmit={onPayerContactSubmit}
          initialValues={payerToUpdate ? initialValues.contacts[0] : {}}
          datacy="add-payer-AddPayerContactForm"
          className="add-payer-contact-popup"
          payerId={payerId}
        />
      )}
    </AppealioPopupWithFooter>
  );
};

AddPayer.propTypes = {};

export default AddPayer;
