import moment from 'moment';
import { Map, fromJS } from 'immutable';

import {
  SET_NOTIFICATIONS,
  CLICK_NOTIFICATION,
  VIEW_ALL_NOTIFICATIONS,
  RESET_LATEST_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
} from 'redux/actions/actionTypes';

const initialState = Map({
  notifications: Map(),
  isLoading: false,
  error: null,
  allNotificationsViewed: false,
  latestNotificationTimestamp: null,
});
const updateNotification = (
  state,
  category,
  newData,
  prevLatestTimestamp,
  allNotificationsViewed
) => {
  const path = ['notifications', category];
  const existingNotification = state.getIn(path, Map());
  const newTimestamp = moment(newData.get('latestCreatedOrUpdatedAt'));
  const existingTimestamp = moment(
    existingNotification.get('latestCreatedOrUpdatedAt')
  );

  let updatedState = state;
  let isNew = false;

  if (
    !existingNotification.get('count') ||
    newTimestamp.isAfter(existingTimestamp)
  ) {
    updatedState = updatedState
      .setIn(path, newData.set('viewed', false))
      .set('allNotificationsViewed', false);
    isNew = true;
  } else {
    updatedState = updatedState
      .setIn([...path, 'count'], newData.get('count'))
      .setIn(
        [...path, 'latestCreatedOrUpdatedAt'],
        newData.get('latestCreatedOrUpdatedAt')
      );
  }

  const isLatest =
    (!allNotificationsViewed && isNew) ||
    !prevLatestTimestamp ||
    newTimestamp.isAfter(prevLatestTimestamp);
  updatedState = updatedState.setIn([...path, 'isLatest'], isLatest);

  const currentLatestTimestamp = moment(
    updatedState.get('latestNotificationTimestamp')
  );
  if (
    !currentLatestTimestamp.isValid() ||
    newTimestamp.isAfter(currentLatestTimestamp)
  ) {
    updatedState = updatedState.set(
      'latestNotificationTimestamp',
      newTimestamp.toISOString()
    );
  }

  return updatedState;
};

export const notificationStore = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST:
      return state.set('isLoading', true);
    case FETCH_NOTIFICATIONS_SUCCESS:
      return state.withMutations((mutableState) => {
        const newNotifications = fromJS(action.payload);
        const prevLatestTimestamp = moment(
          mutableState.get('latestNotificationTimestamp')
        );
        let allNotificationsViewed =
          mutableState.get('allNotificationsViewed') || false;

        newNotifications.forEach((data, category) => {
          mutableState = updateNotification(
            mutableState,
            category,
            data,
            mutableState.get('latestNotificationTimestamp')
              ? prevLatestTimestamp
              : null,
            allNotificationsViewed
          );
          const newTimestamp = moment(data.get('latestCreatedOrUpdatedAt'));
          if (
            allNotificationsViewed &&
            newTimestamp.isAfter(prevLatestTimestamp)
          ) {
            allNotificationsViewed = false;
          }
        });

        mutableState.set('allNotificationsViewed', allNotificationsViewed);
        mutableState.set('isLoading', false).set('error', null);
      });
    case FETCH_NOTIFICATIONS_FAILURE:
      return state.set('error', action.payload).set('isLoading', false);
    case VIEW_ALL_NOTIFICATIONS:
      return state.set('allNotificationsViewed', true);
    case CLICK_NOTIFICATION:
      return state.withMutations((mutableState) => {
        const category = action.payload;
        mutableState.setIn(['notifications', category, 'viewed'], true);
      });
    case SET_NOTIFICATIONS:
      return state.withMutations((mutableState) => {
        const storedData = fromJS(action.payload);
        mutableState.set('notifications', storedData.get('notifications'));
        mutableState.set(
          'allNotificationsViewed',
          storedData.get('allNotificationsViewed', true)
        );
        mutableState.set(
          'latestNotificationTimestamp',
          storedData.get('latestNotificationTimestamp', null)
        );
      });
    case RESET_LATEST_NOTIFICATIONS:
      return state.update('notifications', (notifications) =>
        notifications.map((notification) => notification.set('isLatest', false))
      );

    default:
      return state;
  }
};

export const getNotifications = (state) =>
  state.getIn(['notificationStore', 'notifications']);

export const getAllNotificationsViewedStatus = (state) =>
  state.getIn(['notificationStore', 'allNotificationsViewed']);

export const getUnreadNotificationCount = (state) => {
  const notifications = getNotifications(state);
  return notifications.reduce(
    (count, notification) =>
      count +
      (!notification.get('viewed') &&
      notification.get('count') > 0 &&
      notification.get('isLatest')
        ? 1
        : 0),
    0
  );
};

export const isNotificationEmpty = (state) => {
  const notifications = getNotifications(state);
  return notifications.every((notification) => notification.get('count') === 0);
};
