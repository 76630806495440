import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';

import Input from 'components/common/input';
import TextArea from 'components/common/textarea';
import Label from 'components/common/input/Label';
import { AppealioPopupWithFooter } from 'components/common/popup';
import AutoReminderToggle from './AutoReminderToggle/AutoReminderToggle';

import { updateDenialActionConfig } from 'API/AccountSettingsAPI';

import { handleError } from 'helpers/errorHandler';

import './style.css';

const validate = (values) => {
  const errors = {};
  const isEnabled = values.get('isEnabled');
  const daysUntilReminder = values.get('daysUntilReminder');

  if (isEnabled) {
    if (!daysUntilReminder && daysUntilReminder !== 0) {
      errors.daysUntilReminder =
        'Days Until Reminder is required when auto-reminder is enabled';
    } else if (isNaN(Number(daysUntilReminder))) {
      errors.daysUntilReminder = 'Days Until Reminder must be a number';
    } else if (Number(daysUntilReminder) <= 0) {
      errors.daysUntilReminder =
        'Days Until Reminder must be greater than zero';
    }

    if (!values.get('reminderNote')) {
      errors.reminderNote =
        'Reminder Note is required when auto-reminder is enabled';
    }
  }

  return errors;
};

const CHARACTERS_LIMIT = 500;

const DenialActionForm = ({
  handleSubmit,
  invalid,
  submitting,
  onClosePressed,
  onSubmitSuccess,
  actionId,
  actionName,
  change,
}) => {
  const onSubmit = async (formValues) => {
    try {
      const values = formValues.toJS();
      if (!values.isEnabled) {
        values.daysUntilReminder = values.daysUntilReminder || 0;
      }
      await updateDenialActionConfig({
        actionType: actionId,
        ...values,
      });
      onSubmitSuccess();
    } catch (error) {
      handleError(error);
    }
  };

  const handleToggle = (isEnabled) => change('isEnabled', isEnabled);

  return (
    <AppealioPopupWithFooter
      className="appealio-popup--v3"
      onClosePressed={onClosePressed}
      title={`Edit Auto Reminder for ${actionName}`}
      isFooterOutside={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-12">
          <div className="col-md-6">
            <Label label="Auto Reminders" />
            <Field
              name="isEnabled"
              component={({ input }) => (
                <AutoReminderToggle
                  checked={input.value}
                  handleToggle={() => handleToggle(!input.value)}
                  showLabels
                />
              )}
            />
          </div>
        </div>
        <div className="row mb-12">
          <div className="col-md-4">
            <Field
              component={Input}
              type="number"
              name="daysUntilReminder"
              label="Remind In (Days) *"
              placeholder="Enter number of days"
              min={1}
            />
          </div>
        </div>
        <div className="row mb-12">
          <div className="col-md-12">
            <Field
              component={TextArea}
              maxLength={CHARACTERS_LIMIT}
              name="reminderNote"
              label="Reminder Note *"
              className="reminder-note--text-area"
              placeholder="Enter reminder note"
            />
            <Field
              name="reminderNote"
              component={({ input }) => (
                <p className="mt-8 mb-0 mr-4 fw-500 fs-12 text-right">
                  {input.value.length}/{CHARACTERS_LIMIT}
                </p>
              )}
            />
          </div>
        </div>
        <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
          <button
            type="submit"
            disabled={invalid || submitting}
            className="ap-button ap-button--primary-dark ap-button--primary-md"
          >
            Submit
          </button>
        </div>
      </form>
    </AppealioPopupWithFooter>
  );
};

export default reduxForm({
  form: 'denialActionForm',
  validate,
})(DenialActionForm);
