import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import './style.css';

const TablePagination = (props) => {
  const { pageCount, activePage, onPageChange } = props;
  return (
    <div className="" datacy="table-pagination">
      <ReactPaginate
        pageCount={pageCount}
        breakLabel="..."
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
        containerClassName={'pagination appealio-table__pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        forcePage={activePage - 1}
        onPageChange={({ selected: selectedIndex }) =>
          onPageChange(selectedIndex + 1)
        }
        previousLabel={<div>&lt;</div>}
        nextLabel={<div>&gt;</div>}
      />
    </div>
  );
};

TablePagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default TablePagination;
