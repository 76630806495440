import { useState, useEffect, useCallback } from 'react';

import { fetchTransactionSummary } from 'API/AppealioExpressAPI';

export const useFetchTransactionSummary = (params) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [transactionSummary, setTransactionSummary] = useState({});
  const fetch = useCallback(async () => {
    setLoading(true);
    setTransactionSummary({});
    try {
      const queryParam = {
        ...(params.balance && { balance: true }),
      };
      const data = await fetchTransactionSummary(queryParam);
      setTransactionSummary(data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [params.balance]);

  useEffect(() => {
    if (!params.isStandAloneUser) return;
    fetch();
  }, [fetch, params.isStandAloneUser]);

  return { loading, error, transactionSummary, fetch };
};
