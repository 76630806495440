export const MOCK_ESTIMATE_RESPONSE = [
  {
    patientName: 'Harry Joe',
    provider: 'Provider',
    payer: 'Payer',
    procedures: 'CO-45',
    estimate: 'Estimate',
    estimateDate: '05/15/2018',
  },
  {
    patientName: 'Harry Joe',
    provider: 'Provider',
    payer: 'Payer',
    procedures: 'CO-45',
    estimate: 'Estimate',
    estimateDate: '05/15/2018',
  },
  {
    patientName: 'Harry Joe',
    provider: 'Provider',
    payer: 'Payer',
    procedures: 'CO-45',
    estimate: 'Estimate',
    estimateDate: '05/15/2018',
  },
  {
    patientName: 'Harry Joe',
    provider: 'Provider',
    payer: 'Payer',
    procedures: 'CO-45',
    estimate: 'Estimate',
    estimateDate: '05/15/2018',
  },
  {
    patientName: 'Harry Joe',
    provider: 'Provider',
    payer: 'Payer',
    procedures: 'CO-45',
    estimate: 'Estimate',
    estimateDate: '05/15/2018',
  },
];

export const MOCK_PATIENT_SEARCH_RESPONSE = {
  data: [
    {
      id: '350601',
      firstName: 'Jason',
      lastName: 'Bourne',
      dob: '1975-11-07',
      address1: '1771 Thousand Oaks Drive',
      address2: null,
      city: 'San Ramon',
      zip: '94583',
    },
    {
      id: '350602',
      firstName: 'Jason',
      lastName: 'Bourne 2',
      dob: '1965-11-07',
      address1: '1641 Thousand Oaks Drive',
      address2: null,
      city: 'San Ramon',
      zip: '94583',
    },
  ],
};

export const MOCK_PATIENT_INSURANCES_RESPONSE = {
  data: [
    {
      id: '1234',
      number: '0578347CC',
      policyHolder: {
        firstName: 'Jason',
        lastName: 'Bourne',
      },
      plan: {
        name: 'BCBS-CA Blue Cross of California - Connected Care (PPO)',
        patientContributionRequired: true,
      },
    },
    {
      id: '1235',
      number: '0118347CC',
      policyHolder: {
        firstName: 'Mason',
        lastName: 'Journe',
      },
      plan: {
        name: 'BCBS-CA Blue Cross of California - Connected Care (PPO)',
        patientContributionRequired: true,
      },
    },
  ],
};

export const MOCK_PATIENT_INSURANCE_LATEST_ELIGIBILITY = {
  id: 'cc0c03bf-0fd1-48db-b482-9e332ae3a69d',
  checkDate: '2021-06-29',
  status: 'ACTIVE',
  subscriber: {
    type: 'PERSON',
    firstName: 'Jason',
    lastName: 'Bourne',
    middleName: 'G',
    statusCode: 1,
    isActive: true,
    coinsurancePercentage: '0',
    copaymentAmount: '0',
    deductible: '0',
  },
  dependents: [
    {
      type: 'PERSON',
      firstName: 'James',
      lastName: 'Bond',
      middleName: 'J',
      statusCode: 1,
      isActive: true,
      coinsurancePercentage: '0',
      copaymentAmount: '0',
      deductible: '0',
    },
  ],
};

export const MOCK_VERIFY_PATIENT_INSURANCE_LATEST_ELIGIBILITY = {
  status: 'success',
};

export const MOCK_CPT_SEARCH_RESPONSE = {
  data: [
    {
      code: '73221',
      description: 'Incision and drainage of abcess',
      category: {
        id: 1,
        name: 'Brain and Neck',
      },
    },
    {
      code: '72141',
      description: 'MRI Cervical Spine w/o Contrast',
      category: {
        id: 2,
        name: 'Spine and Back',
      },
    },
  ],
};

export const MOCK_CALCULATE_ESTIMATE = {
  data: {
    version: 1,
    procedures: [
      {
        code: '73221',
        description: 'MRI Shoulder, Elbow, Wrist or Clavicle w/o contrast',
        quantity: 1,
        coinsurance: {
          amount: '160.00',
          percentage: 20,
        },
        contractedRate: '800.00',
        deductibleApplied: '800.00',
        remainingDeductible: '0.00',
        patientCost: '800.00',
      },
      {
        code: '72141',
        description: 'MRI Cervical Spine w/o Contrast',
        quantity: 2,
        contractedRate: '800.00',
        coinsurance: {
          amount: '120.00',
          percentage: 20,
        },
        deductibleApplied: '0.00',
        remainingDeductible: '0.00',
        patientCost: '120.00',
      },
    ],
    estimate: {
      // optional
      patientCost: '920.00',
      deductible: {
        applied: '800.00',
        remaining: '0.00',
      },
    },
  },
};
