import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { extractInitials } from '../../helpers/string';

const Avatar = ({
  src,
  name,
  size = 32,
  style = {},
  className = '',
  withBorder = true,
  imageClassName = '',
  shouldRenderFullName = false,
}) => {
  const avatarName =
    shouldRenderFullName && name ? name : extractInitials(name || 'A');

  return (
    <span
      className={classnames('avatar', className, { 'with-border': withBorder })}
      style={{ width: size, height: size, ...style }}
      datacy="Avatar"
    >
      {src ? (
        <img alt={name || 'avatar'} src={src} className={imageClassName} />
      ) : (
        <span className="name-container">{avatarName.toUpperCase()}</span>
      )}
    </span>
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
  withBorder: PropTypes.bool,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
};

export default Avatar;
