export const ERROR_TYPE_MAPPING = {
  MISSING_DATA: 'Missing Data',
  PRACTICE_NOT_FOUND: 'Practice Not Found',
  PAYER_NOT_FOUND: 'Payer Not Found',
  RENDERING_PROVIDER_NOT_FOUND: 'Rendering Provider Not Found',
  BILLING_PROVIDER_NOT_FOUND: 'Billing Provider Not Found',
  INCORRECT_FORMATTING: 'Incorrect Formatting',
  UNRECOGNIZED_PROCEDURE: 'Unrecognized Procedure',
  DATA_TOO_LONG: 'Data Too Long',
  INVALID_DATA: 'Invalid Data',
};

export const MAIN_TABS = {
  ALL_ROWS: 'ALL_ROWS',
  CLEAN_ROWS: 'CLEAN_ROWS',
  ERRORS: 'ERRORS',
};

export const CLEAN_TABS = {
  ALL: 'ALL',
  NEW_ROWS: 'NEW_ROWS',
  DUPLICATE_ROWS: 'DUPLICATE_ROWS',
};

export const ERROR_SUB_TABS = {
  ALL_ERRORS: 'ALL_ERRORS',
};

export const columns = [
  { header: '#', key: 'rowNumber' },
  { header: 'Patient First Name', key: 'patientFirstName' },
  { header: 'Patient Last Name', key: 'patientLastName' },
  { header: 'Claim ID', key: 'claimNumber' },
  { header: 'Payer Claim ID', key: 'claimControlNumber' },
  { header: 'Payer', key: 'payer' },
  { header: 'Practice', key: 'practice' },
  { header: 'Service Date', key: 'serviceDate' },
  { header: 'Rendering Provider', key: 'renderingProvider' },
  { header: 'Billing Provider', key: 'billingProvider' },
  { header: 'Procedure', key: 'procedure' },
  { header: 'Remark Code', key: 'remarkCode' },
  { header: 'Reason Code', key: 'reasonCode' },
];
