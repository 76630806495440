import classnames from 'classnames';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import { formatToHumanReadable } from 'helpers/dateFormats';
import React, { useEffect, useMemo, useState } from 'react';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';

import './styles.css';

import emptyIcon from 'img/empty.svg';
import closeIcon from 'img/close-modal.svg';
import appealCheck from 'img/appeal_check.svg';

import { mimeTypes } from 'helpers/mimeTypes';
import {
  useFetchAppealTemplates,
  useFetchPayersList,
  useFetchPayerTemplateCount,
} from '../hook';

import { SETTINGS_PAGE_LIMIT } from 'constants/appConstants';

import * as toast from 'components/Shared/toast';
import ToggleSwitch from 'components/common/toggle';
import Pagination from 'components/common/pagination';
import { AlertDialog } from 'components/common/popup';
import TextHighlighter from 'components/common/textHighlighter';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import DocumentViewer from 'components/CreateAppeal/Review/DocumentViewer';
import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';
import AddTemplate from 'components/CreateAppeal/AppealLetter/AddTemplate/AddTemplate';
import SearchWithOption from 'components/common/search/SearchWithOption/SearchWithOption';

import { isClientAdmin } from 'Auth/AuthUtils';
import { getUserInfo } from 'redux/reducers/loginStore';
import { attachCountsToArrayOfObjects } from '../utils';

import { createRequest, handleErrors } from 'API/Config';
import RichTextEditor from 'components/CreateAppeal/AppealLetter/RichTextEditor';
import {
  fillTemplate,
  formatTemplate,
} from 'components/CreateAppeal/AppealLetter/TemplateFiller';

import { SEARCH_QUERY_CHARACTERS_OFFSET } from '../constants';

export const CONTENT_TYPE = {
  PDF: 'pdf',
  LETTER: 'html',
};

export const TEMPLATES_TYPE_OPTIONS = {
  option1: 'Payer PDF Forms',
  option2: 'Letter Templates',
};

const ALL_PAYERS_OPTION = {
  name: 'All Payers',
  id: 'allPayer',
};

const UNLINKED_PAYERS_OPTION = {
  name: 'Unlinked to Payer',
  id: 'unlinkedPayer',
};

const PAYER_OPTION = {
  label: 'Payer',
  value: 'PAYER',
  isDefault: true,
};

const TEMPLATE_FORM_OPTION = {
  label: 'Template or Form',
  value: 'TEMPLATE_FORM',
};

const SEARCH_TYPES = [PAYER_OPTION, TEMPLATE_FORM_OPTION];

const columns = [
  {
    label: 'Name',
    templateField: 'name',
    width: 3,
  },
  {
    label: 'Created On',
    templateField: 'created_on',
    width: 2,
  },
  {
    label: 'Last Modified',
    templateField: 'last_modified',
    width: 2,
  },
  {
    label: 'Last Used',
    templateField: 'last_used',
    width: 2,
  },
  {
    label: '# of Uses',
    templateField: 'no_of_uses',
    width: 2,
  },
];

const Template = (props) => {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState({
    query: '',
    type: PAYER_OPTION.value,
  });
  const [selectedPayer, setSelectedPayer] = useState();
  const { loading: isPayersLoading, payers } = useFetchPayersList();
  const [isTemplatesTypeAppealTemplate, setIsTemplatesTypeAppealTemplate] =
    useState(false);

  const payersWithAllOption = useMemo(
    () => [
      {
        ...ALL_PAYERS_OPTION,
        name: isTemplatesTypeAppealTemplate
          ? 'All Letter Templates'
          : 'All PDF Forms',
      },
      {
        ...UNLINKED_PAYERS_OPTION,
        name: isTemplatesTypeAppealTemplate
          ? 'Letter Templates Unlinked to Payer'
          : 'Forms Unlinked To Payer',
      },
      ...payers,
    ],
    [payers, isTemplatesTypeAppealTemplate]
  );
  const [listedPayers, setListedPayers] = useState(payersWithAllOption);
  const isPayerSearch = searchQuery.type === PAYER_OPTION.value;
  const templateSearchQuery =
    searchQuery.type === TEMPLATE_FORM_OPTION.value ? searchQuery.query : '';
  const [isTemplatePopupOpen, setIsTemplatePopupOpen] = useState(false);

  const [templateDetails, setTemplateDetails] = useState({
    isTemplateStatusPopupOpen: false,
    templateName: '',
  });

  const defaultPreviewContent = useMemo(
    () => ({
      isOpen: false,
      isLoading: false,
      link: '',
      title: '',
      content: '',
      type: '',
    }),
    []
  );

  const [previewContent, setPreviewContent] = useState(defaultPreviewContent);

  const { appealTemplateCount } = useFetchPayerTemplateCount(
    templateSearchQuery,
    isTemplatesTypeAppealTemplate ? CONTENT_TYPE.LETTER : CONTENT_TYPE.PDF
  );

  const selectedPayerId = selectedPayer ? selectedPayer.id : null;

  const [selectedColumn, setSelectedColumn] = useState('no_of_uses');
  const [sortOrder, setSortOrder] = useState('dsc');
  const [sortBy, setSortBy] = useState('-no_of_uses');

  const { loading, templates, totalTemplates } = useFetchAppealTemplates(
    page,
    isTemplatesTypeAppealTemplate ? CONTENT_TYPE.LETTER : CONTENT_TYPE.PDF,
    selectedPayerId,
    templateSearchQuery,
    sortBy
  );

  const filteredPayers = useMemo(() => {
    if (isPayerSearch) {
      if (!searchQuery.query) {
        if (!selectedPayerId) {
          setSelectedPayer(payersWithAllOption[0]);
        }
        return payersWithAllOption;
      }

      const search = searchQuery.query.toUpperCase();
      return payers.filter((payer) =>
        payer.name.toUpperCase().includes(search)
      );
    }

    if (
      appealTemplateCount?.length > 0 &&
      searchQuery.query.length >= SEARCH_QUERY_CHARACTERS_OFFSET
    ) {
      const payerWithTemplateCounts = attachCountsToArrayOfObjects(
        payers,
        appealTemplateCount,
        'payerId',
        'appealTemplateCount'
      ).filter((payer) => payer.appealTemplateCount);

      const selectedHasTemplate = payerWithTemplateCounts.some(
        (payer) => payer.id === selectedPayerId
      );

      if (
        (templates.length < 1 &&
          payerWithTemplateCounts.length > 0 &&
          !selectedHasTemplate) ||
        payerWithTemplateCounts.length === 1
      ) {
        setSelectedPayer(payerWithTemplateCounts[0]);
      }

      return payerWithTemplateCounts;
    }

    return payersWithAllOption;
  }, [
    payers,
    templates.length,
    selectedPayerId,
    payersWithAllOption,
    isPayerSearch,
    appealTemplateCount,
    searchQuery,
  ]);

  useEffect(() => {
    setListedPayers(filteredPayers);
  }, [filteredPayers, searchQuery]);

  const onPageChange = (page) => setPage(page);
  const pageCount = Math.ceil(totalTemplates / SETTINGS_PAGE_LIMIT);

  const handleSearch = ({ query, type }) => {
    setSearchQuery({
      query,
      type,
    });
  };

  const handleToggle = () => {
    setPage(1);
    setIsTemplatesTypeAppealTemplate(!isTemplatesTypeAppealTemplate);
  };

  const handlePreview = async (template) => {
    const { id, templateType, letter, name } = template;
    setPreviewContent({
      isOpen: true,
      isLoading: true,
      link: '',
      title: name,
      content: letter,
      type: templateType,
    });

    if (templateType === CONTENT_TYPE.PDF) {
      const endpoint = `appeal_template_pdfs/${id}`;
      const request = createRequest(
        endpoint,
        {},
        {
          method: 'GET',
        }
      );

      try {
        const packageFile = await fetch(request)
          .then(handleErrors)
          .then((response) => response.blob());
        const fileUrl = window.URL.createObjectURL(packageFile);
        setPreviewContent({
          isOpen: true,
          isLoading: false,
          link: fileUrl,
          title: name,
          type: templateType,
          content: '',
        });
      } catch (error) {
        setPreviewContent(defaultPreviewContent);
        toast.error({
          title: '',
          message: 'Preview is not available at the moment',
        });
      }
    }
    if (templateType === CONTENT_TYPE.LETTER) {
      setPreviewContent({
        isOpen: true,
        isLoading: false,
        link: '',
        content: letter,
        title: name,
        type: templateType,
      });
    }
  };

  const renderSuccessDialog = (
    <AlertDialog
      onClosePressed={() =>
        setTemplateDetails({
          ...templateDetails,
          isTemplateStatusPopupOpen: false,
        })
      }
      className=""
      statusIcon={appealCheck}
      datacy="template-AlertDialog"
    >
      <p>
        Your <span className="fw-bold">{templateDetails.templateName}</span> was
        successfully submitted.
        <br />
        Our CX team will reach out to you via email within 1 business day.
      </p>
    </AlertDialog>
  );

  const RenderAppealTemplatePopup = ({ title, content, onClose }) => {
    const [renderContent, setRenderContent] = useState('');

    return (
      <ScreenDimmerComponent>
        <div
          className={'ap-popup ap-popup--md show-template-popup ap-popup--p-0'}
        >
          <div className="ap-popup__header ap-popup__header--with-border">
            <div className="ap-popup__title">{title}</div>
            <div className="ap-popup__close" onClick={onClose}>
              <img
                alt="Close"
                className="ap-popup__close-icon"
                src={closeIcon}
              />
            </div>
          </div>
          <div className="ap-popup__content">
            <div className="appeal-letter__preview--background">
              <div className="appeal-letter__preview--content">
                <span
                  dangerouslySetInnerHTML={{
                    __html: fillTemplate(renderContent),
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: 'none',
              }}
            >
              <RichTextEditor
                content={formatTemplate(content, ['test'])}
                onContentChange={setRenderContent}
                datacy="appeal-letter-RichTextEditor"
              />
            </div>
          </div>
        </div>
      </ScreenDimmerComponent>
    );
  };

  const handlePayerClick = (payer) => {
    setPage(1);
    setSelectedPayer(payer);
  };

  const handleSuccess = (templateName) => {
    setTemplateDetails({
      ...templateDetails,
      isTemplateStatusPopupOpen: true,
      templateName,
    });
  };

  const searchBoxPlaceholder = isPayerSearch
    ? 'Search by Payer'
    : isTemplatesTypeAppealTemplate
    ? 'Search by Letter Template'
    : 'Search by Payer PDF Form';

  const getArrow = (column) => {
    if (selectedColumn === column.templateField) {
      return sortOrder === 'asc' ? (
        <TiArrowSortedUp className="column-sort-arrow" />
      ) : (
        <TiArrowSortedDown className="column-sort-arrow" />
      );
    }
    return '';
  };

  const handleColumnClick = (column) => {
    let newSortOrder;
    if (selectedColumn === column.templateField) {
      newSortOrder = sortOrder === 'asc' ? 'dsc' : 'asc';
    } else {
      newSortOrder = 'dsc';
    }

    setSelectedColumn(column.templateField);
    setSortOrder(newSortOrder);
    setSortBy(
      newSortOrder === 'dsc' ? `-${column.templateField}` : column.templateField
    );
    setPage(1);
  };

  return (
    <div className="settings-content-wrapper settings-content-wrapper--templates">
      <p className="setting-content__description">
        All PDF form(s) and Letter Template(s) are organized by their respective
        Payer.
      </p>
      <div
        className={classnames(
          'd-flex row align-items-center settings-content__top-action',
          {
            'justify-content--space-between': isClientAdmin(props.userInfo),
          }
        )}
      >
        <div className="col-lg-5">
          <SearchWithOption
            onSearch={handleSearch}
            options={SEARCH_TYPES}
            placeholder={searchBoxPlaceholder}
            handleReset={() => setSelectedPayer(payersWithAllOption[0])}
          />
        </div>
        <ToggleSwitch
          className="template-switch-toggle  justify-content-center col-lg-4"
          options={TEMPLATES_TYPE_OPTIONS}
          checked={isTemplatesTypeAppealTemplate}
          handleToggle={handleToggle}
        />
        {isClientAdmin(props.userInfo) && (
          <button
            onClick={() => {
              setIsTemplatePopupOpen(true);
            }}
            className="ap-button ap-button--secondary ml-auto settings-add-button"
            datacy="add-letter-templates-button"
          >
            <span className="mr-4">+</span> Add{' '}
            {isTemplatesTypeAppealTemplate ? 'Letter Template' : 'Payer Form'}
          </button>
        )}
      </div>

      <div className="row mt-12 ap-card-list-wraper">
        <div className="col-lg-3 border-right-grey settings-content__left-sidebar">
          {listedPayers.length > 0 ? (
            listedPayers.map((payer, idx) => (
              <div
                key={idx}
                onClick={() => handlePayerClick(payer)}
                className={classnames('create-account-list-item mb-8', {
                  'create-account-list-item--selected':
                    selectedPayer && selectedPayer.id === payer.id,
                  'd-flex justify-content--space-between align-items-center':
                    appealTemplateCount?.length > 0,
                })}
              >
                <span>{payer.name}</span>
                {payer?.appealTemplateCount && (
                  <span>({payer.appealTemplateCount})</span>
                )}
              </div>
            ))
          ) : isPayersLoading && isPayersLoading ? (
            <LoadingBlockHelper
              className="dashboard-bottom-loader"
              isLoading={isPayersLoading}
            />
          ) : (
            <p className="fw-bold"> No Payer found </p>
          )}
        </div>
        <div className="col-lg-9">
          {loading && loading ? (
            <LoadingBlockHelper
              className="dashboard-bottom-loader"
              isLoading={loading}
            />
          ) : templates && templates.length > 0 ? (
            <React.Fragment>
              <div className="row mb-16 create-acount-list-item-header">
                {columns.map((column, index) => (
                  <div
                    key={index}
                    className={`col-lg-${column.width} d-flex align-items-center column-pointer`}
                    onClick={() => handleColumnClick(column)}
                  >
                    <span
                      className={
                        column.templateField === selectedColumn
                          ? 'column-bold'
                          : ''
                      }
                    >
                      {column.label}
                    </span>
                    {getArrow(column)}
                  </div>
                ))}
              </div>
              {templates.map((template, index) => (
                <div className="create-account-list-item row mb-16" key={index}>
                  <div className="col-lg-3">
                    <span data-tip={template.name} data-for="tooltip">
                      {template.name &&
                      searchQuery.query?.length >=
                        SEARCH_QUERY_CHARACTERS_OFFSET ? (
                        <TextHighlighter
                          text={template.name}
                          query={searchQuery.query}
                        />
                      ) : (
                        <span>{template.name || '--'}</span>
                      )}
                    </span>
                  </div>
                  <div className="col-lg-2">
                    {formatToHumanReadable(template.createdOn)}
                  </div>
                  <div className="col-lg-2">
                    {formatToHumanReadable(template.lastModified)}
                  </div>
                  <div className="col-lg-2">
                    {template.lastUsed
                      ? formatToHumanReadable(template.lastUsed)
                      : 'N/A'}
                  </div>
                  <div className="col-lg-2">
                    <div className="d-flex align-items-center justify-content--space-between no-of-uses-col">
                      <div className="no-of-uses-col--value">
                        {template.noOfUses}
                      </div>

                      {template.noOfUses ? (
                        <FaInfoCircle
                          className="no-of-uses-info-btn"
                          data-tip={
                            template.firstUsed
                              ? `Used Since: ${formatToHumanReadable(
                                  template.firstUsed
                                )}`
                              : 'N/A'
                          }
                          data-for="tooltip"
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <ReactTooltip id="tooltip" place="top" effect="solid" />
                  </div>
                  <div className="col-lg-1">
                    <div className="float-right">
                      <button
                        className="ap-button text-right ap-button--link btn--link"
                        onClick={() => handlePreview(template)}
                      >
                        Preview
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </React.Fragment>
          ) : (
            <div className="no-data-container mt-60">
              <img src={emptyIcon} alt="No Data Found" />
              <h4>No Data Found</h4>
            </div>
          )}
        </div>
      </div>
      {isTemplatePopupOpen && (
        <AddTemplate
          onClose={() => setIsTemplatePopupOpen(false)}
          title={
            isTemplatesTypeAppealTemplate
              ? 'Request New Letter Template'
              : 'Request New Payer Form'
          }
          contentType={
            isTemplatesTypeAppealTemplate
              ? CONTENT_TYPE.LETTER
              : CONTENT_TYPE.PDF
          }
          onSuccess={handleSuccess}
          selectedPayerId={selectedPayerId}
        />
      )}

      {templateDetails.isTemplateStatusPopupOpen && renderSuccessDialog}
      {previewContent.isOpen &&
        (previewContent.type === CONTENT_TYPE.PDF ? (
          <DocumentViewer
            documents={[
              {
                link: previewContent.link || '',
                name: previewContent.title,
                docType: mimeTypes.APPLICATION_PDF,
              },
            ]}
            isLoadingDocuments={previewContent.isLoading}
            defaultIndex={0}
            onClosePress={() => setPreviewContent(defaultPreviewContent)}
          />
        ) : (
          <RenderAppealTemplatePopup
            title={previewContent.title}
            content={previewContent.content}
            onClose={() => setPreviewContent(defaultPreviewContent)}
          />
        ))}
      {!loading && totalTemplates && pageCount > 1 ? (
        <div className="d-flex justify-content-center">
          <Pagination
            pageCount={pageCount}
            activePage={page}
            onPageChange={onPageChange}
            datacy="template-Pagination"
          />
        </div>
      ) : null}
    </div>
  );
};

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);

  return {
    userInfo,
  };
}
export default connect(mapStateToProps, {})(Template);
