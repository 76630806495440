import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/common/button';
import AppealioPopup from 'components/Shared/AppealioPopup';

import './style.css';

const ConfirmExitReviewAndUpload = (props) => {
  const { onConfirmClick, onCancelClick } = props;
  return (
    <AppealioPopup
      className="appealio-popup appealio-action-popup"
      titleClassName="appealio-action-popup__header csv-claims-import-confirm-popup__title"
    >
      <div className="mt-10">
        Are you sure you want to cancel the batch upload?
      </div>
      <div className="mt-20">
        <Button
          className="width-full ap-button--secondary"
          onClick={onConfirmClick}
        >
          Confirm
        </Button>
      </div>
      <div className="mt-20">
        <Button
          className="width-full ap-button--secondary csv-claims-import-confirm-popup-close-cancel-btn"
          onClick={onCancelClick}
        >
          Cancel
        </Button>
      </div>
    </AppealioPopup>
  );
};

ConfirmExitReviewAndUpload.propTypes = {
  onConfirmClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};

export default ConfirmExitReviewAndUpload;
