import React from 'react';

import './style.css';

import CodeCard from './CodeCard/CodeCard';
import AppealioPopup from 'components/Shared/AppealioPopup';
import LoadingIndicator from 'components/Shared/LoadingIndicator';

import useFetchCodesDescriptions from './hooks';

const CodesInfoPopup = (props) => {
  const { onClosePressed, claimId, reasonCodes = [], remarkCodes = [] } = props;

  const { loading, data } = useFetchCodesDescriptions(reasonCodes, remarkCodes);

  return (
    <AppealioPopup
      title={`Code Descriptions for Claim ${claimId}`}
      className="codes-info-popup"
      onClosePressed={onClosePressed}
      datacy="code-descriptions-AppealioPopup"
    >
      {loading ? (
        <LoadingIndicator showing={true} />
      ) : (
        <div>
          <div>
            <div className="code-type-title">Reason (CARC)</div>
            {data.carcs.length > 0 ? (
              <div>
                {data.carcs.map((codeInfo, idx) => (
                  <CodeCard
                    key={idx}
                    code={codeInfo.code}
                    description={codeInfo.description}
                    datacy="reason-CodeCard"
                  />
                ))}
              </div>
            ) : (
              <div className="fs-14 mt-8">No data available</div>
            )}
          </div>

          <div className="mt-24">
            <div className="code-type-title">Remark (RARC)</div>
            {data.rarcs.length > 0 ? (
              <div>
                {data.rarcs.map((codeInfo, idx) => (
                  <CodeCard
                    key={idx}
                    code={codeInfo.code}
                    description={codeInfo.description}
                    datacy="remark-CodeCard"
                  />
                ))}
              </div>
            ) : (
              <div className="fs-14 mt-8">No data available</div>
            )}
          </div>
        </div>
      )}
    </AppealioPopup>
  );
};

export default CodesInfoPopup;
