import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { useState } from 'react';
import { FaRegEye, FaCaretDown } from 'react-icons/fa';

import './style.css';

import OutsideClickWrapper from 'components/common/outsideClickWrapper/OutsideClickWrapper';

const ICON_SIZE = 16;

const ButtonDropdown = (props) => {
  const {
    className = '',
    labels,
    filters,
    onFilterChange,
    dropdownLabel = '',
  } = props;

  const [showDropdownOptions, setShowDropdownOptions] = useState(false);

  const appliedFilterLabels = Object.keys(filters).reduce((acc, key) => {
    if (filters[key]) {
      acc.push(labels[key]);
    }
    return acc;
  }, []);

  const label = appliedFilterLabels.join(', ').replace(/,([^,]*)$/, ' &$1');

  return (
    <OutsideClickWrapper
      handleOutsideClick={() => setShowDropdownOptions(false)}
    >
      <div className={classnames(`button-dropdown ${className}`)}>
        <div
          onClick={() => setShowDropdownOptions(!showDropdownOptions)}
          className="button-dropdown__title-container"
        >
          <div className="d-flex align-items-center">
            {dropdownLabel ? (
              <span>{dropdownLabel}</span>
            ) : (
              <FaRegEye size={ICON_SIZE} />
            )}
            <span className="button-dropdown__title">
              {[0, Object.keys(filters).length].includes(
                appliedFilterLabels.length
              )
                ? 'All'
                : label}
            </span>
          </div>
          <FaCaretDown size={ICON_SIZE} />
        </div>
        {showDropdownOptions && (
          <ul className="button-dropdown__menu">
            {Object.entries(filters).map(([key, value]) => {
              return (
                <li key={key} className="button-dropdown__menu-item">
                  <input
                    id={key}
                    type="checkbox"
                    className="mr-4"
                    checked={value}
                    onChange={() => {
                      onFilterChange(key, !value);
                    }}
                  />
                  <label htmlFor={key}>
                    <span>{labels[key]}</span>
                  </label>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </OutsideClickWrapper>
  );
};

ButtonDropdown.propTypes = {
  className: PropTypes.string,
};

export default ButtonDropdown;
