import classnames from 'classnames';
import React, { useState, useEffect } from 'react';

import './style.css';

import { PAYER_CONTACT_TYPES } from 'constants/appConstants';

import sortChevronUpIconBold from 'img/sort-chevron-up-bold.svg';
import sortChevronDownIconBold from 'img/sort-chevron-down-bold.svg';
const AccordionPayerContactInfo = ({
  title,
  content,
  initialValues,
  openContent,
}) => {
  const [isActive, setIsActive] = useState(false);

  const { address1, fax, payerPortalUrl, email } = initialValues;

  useEffect(() => {
    const hasContent = {
      [PAYER_CONTACT_TYPES.MAIL]: address1,
      [PAYER_CONTACT_TYPES.FAX]: fax,
      [PAYER_CONTACT_TYPES.PAYER_PORTAL]: payerPortalUrl,
      [PAYER_CONTACT_TYPES.EMAIL]: email,
    };

    setIsActive(hasContent[title]);
  }, [title, address1, fax, payerPortalUrl, email]);

  const isAccordionOpen = isActive || openContent;
  return (
    <div className="accordion-item">
      <div className="accordion-item__heading">
        <div
          className="d-flex align-items-center justify-content--space-between"
          onClick={() => setIsActive(!isActive)}
        >
          <h5 className="accordion-item__title">{title}</h5>
          <span>
            {!isAccordionOpen ? (
              <img src={sortChevronDownIconBold} alt="sort-arrow-img" />
            ) : (
              <img src={sortChevronUpIconBold} alt="sort-arrow-img" />
            )}
          </span>
        </div>
      </div>
      {
        <div
          className={classnames('accordion-content', {
            'accordion-content--open': isAccordionOpen,
          })}
        >
          {content}
        </div>
      }
    </div>
  );
};

export default AccordionPayerContactInfo;
