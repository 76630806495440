import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import useClickOutside from 'hooks/useClickOutside';

const OutsideClickWrapper = (props) => {
  const ref = useRef(null);

  useClickOutside([ref], props.handleOutsideClick);

  return (
    <div ref={ref} datacy="outside-click-wrapper">
      {props.children}
    </div>
  );
};

OutsideClickWrapper.propTypes = {
  handleOutsideClick: PropTypes.func.isRequired,
};

export default OutsideClickWrapper;
