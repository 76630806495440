import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import OneLineInfo from './OneLineInfo';

export default class AppealPatientInfo extends Component {
  static propTypes = {
    appeal: PropTypes.object.isRequired,
    formattedDOB: PropTypes.string.isRequired,
  };

  render() {
    const patient = this.props.appeal.patient;
    return (
      <div title="Patient">
        <div className="tabbar__title">Patient Overview</div>
        <div className="tabbar__info-padding tabbar__line">
          <OneLineInfo title="DOB" value={this.props.formattedDOB} />
          <OneLineInfo title="Address" value={patient.address1} />
          <OneLineInfo title="City" value={patient.city} />
          <OneLineInfo title="State" value={patient.state} />
          <OneLineInfo title="ZIP" value={patient.zipcode} />
          <div className="tabbar__line" />
        </div>
        <div className="tabbar__title tabbar__info-padding">Insurance</div>
        <div className="tabbar__info-padding">
          <OneLineInfo
            title="Payer"
            value={_.get(this.props.appeal, 'payer.name', '')}
          />
          <OneLineInfo title="Member ID" value={this.props.appeal.memberId} />
          <OneLineInfo title="Group" value={this.props.appeal.groupNumber} />
          <OneLineInfo title="Plan" value={this.props.appeal.planType} />
        </div>
      </div>
    );
  }
}
