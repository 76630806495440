import classNames from 'classnames';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { AiOutlineFolder } from 'react-icons/ai';

import { updateTeam } from 'API/AccountSettingsAPI';

import { handleError } from 'helpers/errorHandler';

import Input from 'components/common/input';
import Button from 'components/common/button';
import * as toast from 'components/Shared/toast';
import { BUTTON_TYPE } from 'components/common/button/Button';
import { AlertDialog, AppealioPopupWithFooter } from 'components/common/popup';

const DEFAULT_SHOW_CONFIRM_CANCEL_POPUP = {
  isOpen: false,
  addedUsers: [],
  removedUsers: [],
};

const AddedRemovedUsersList = ({ users, title, className }) => {
  if (!users.length) return null;
  const top2Users = users
    .slice(0, 2)
    .map((user) => user.label)
    .join(', ');
  const allUserNames = users.map((user) => user.label);

  const tooltipId = `user-team-confirm-cancel-popup-tooltip-${title}`;
  return (
    <div className={classNames('user-team-confirm-cancel-popup', className)}>
      <span className="user-team-confirm-cancel-popup__title">{title}:</span>
      <span data-tip data-for={tooltipId}>
        <span className="user-team-confirm-cancel-popup__item">
          {top2Users}
        </span>
        {users.length > 2 && (
          <span className="user-team-confirm-cancel-popup__item">
            + {users.length - 2} user(s)
          </span>
        )}
      </span>

      <ReactTooltip
        id={tooltipId}
        place="top"
        className="tooltip-details-background"
        arrowColor="transparent"
      >
        {allUserNames.map((userName, idx) => (
          <div key={idx}>
            {idx + 1}. {userName}
          </div>
        ))}
      </ReactTooltip>
    </div>
  );
};

const AssignUsers = ({
  onClosePressed,
  teamId,
  teamName,
  onAssignUsersComplete,
  users: initialUsers,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [users, setUsers] = useState([...initialUsers]);
  const [showConfirmCancelPopup, setShowConfirmCancelPopup] = useState(
    DEFAULT_SHOW_CONFIRM_CANCEL_POPUP
  );

  const filteredUsers = users.filter((user) =>
    user.label.toLowerCase().includes(searchValue.toLowerCase())
  );

  /**
   * Handles the search change.
   * @param {Object} e
   */
  const handleSearchChange = (e) => setSearchValue(e.target.value);

  /**
   * Toggles the User selection.
   * @param {Object} value
   */
  const toggleUserSelection = (value) =>
    setUsers(
      users.map((user) => {
        if (user.value === value) {
          return { ...user, selected: !user.selected };
        }
        return user;
      })
    );

  /**
   * Handles the form submission.
   */
  const handleSave = async () => {
    const selectedUsers = users
      .filter((user) => user.selected)
      .map((user) => user.userId);

    try {
      setIsSubmitting(true);
      await updateTeam(teamId, {
        userIds: selectedUsers,
        name: teamName,
      });
      onAssignUsersComplete();
      toast.success({
        title: 'Success',
        message: `${selectedUsers.length} users(s) assigned to ${teamName} Team.`,
      });
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancelClick = () => {
    const extractSelected = (usersArray) =>
      usersArray.filter((user) => user.selected);
    const extractValues = (usersArray) => usersArray.map((user) => user.value);

    const selectedUsers = extractSelected(users);
    const initialSelectedUsers = extractSelected(initialUsers);

    const selectedUsersValues = extractValues(selectedUsers);
    const initialUsersValues = extractValues(initialSelectedUsers);

    const hasChanges =
      !selectedUsersValues.every((value) =>
        initialUsersValues.includes(value)
      ) || selectedUsersValues.length !== initialUsersValues.length;

    if (!hasChanges) {
      return onClosePressed();
    }

    const findDifference = (base, comparator) =>
      base.filter(
        (baseItem) =>
          !comparator.some(
            (comparatorItem) => comparatorItem.value === baseItem.value
          )
      );

    const addedUsers = findDifference(selectedUsers, initialSelectedUsers);
    const removedUsers = findDifference(initialSelectedUsers, selectedUsers);

    setShowConfirmCancelPopup({
      isOpen: true,
      addedUsers,
      removedUsers,
    });
  };

  return (
    <AppealioPopupWithFooter
      className={classNames(
        'appealio-popup--v3 appealio-popup--v3--overflow-hidden'
      )}
      onClosePressed={handleCancelClick}
      title={
        <div className="d-flex align-items-center">
          <AiOutlineFolder size="22" className="mr-4" />
          Add/Remove User(s) from{' '}
          <span className="ml-4 fw-bold"> {teamName}</span>
        </div>
      }
      isFooterOutside={false}
    >
      <div className="ap-popup-list__sticky-search-bar row mb-12">
        <div className="col-lg-12">
          <Input
            label="Search for User"
            placeholder="User Name"
            onChange={handleSearchChange}
            value={searchValue}
          />
        </div>
        <div className="col-lg-12">
          <div className="d-flex justify-content-flex-end mt-2">
            <span className="fs-12">
              Total Selected: {users.filter((x) => x.selected).length}
            </span>
          </div>
        </div>
      </div>
      <div className="row mb-12">
        <div className="col-lg-12">
          <ul className="ap-popup-list__list">
            {filteredUsers.map((user) => (
              <li
                className="ap-popup-list__list--item cursor-pointer"
                key={user.value}
                onClick={() => toggleUserSelection(user.value)}
              >
                <input type="checkbox" checked={user.selected} readOnly />
                <span>{user.label}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
        <button
          onClick={handleSave}
          className="ap-button ap-button--primary-dark ap-button--primary-md justify-content-center"
          disabled={isSubmitting}
        >
          Save
        </button>
      </div>

      {showConfirmCancelPopup.isOpen && (
        <AlertDialog
          onClosePressed={() => {
            setShowConfirmCancelPopup(DEFAULT_SHOW_CONFIRM_CANCEL_POPUP);
          }}
          className="import-rule-practice-agent-permission"
        >
          You have made changes in selection of User(s) for this Team. If you
          proceed without saving, your changes will be lost.
          <div className="mt-16">
            <AddedRemovedUsersList
              users={showConfirmCancelPopup.addedUsers}
              title="Added User(s)"
              className="mb-4 mt-8"
            />
            <AddedRemovedUsersList
              users={showConfirmCancelPopup.removedUsers}
              title="Removed User(s)"
            />
          </div>
          <hr className="card-horizontal-line" />
          <div className="import-rule-practice-agent-permission__buttons-wrapper">
            <Button
              type="secondary"
              title="Proceed Without Saving"
              onClick={() => onClosePressed()}
            />
            <Button
              title="Cancel"
              type={BUTTON_TYPE.LIGHT}
              className="width-80 justify-content-center fw-normal ml-8"
              onClick={() => {
                setShowConfirmCancelPopup(DEFAULT_SHOW_CONFIRM_CANCEL_POPUP);
              }}
            />
          </div>
        </AlertDialog>
      )}
    </AppealioPopupWithFooter>
  );
};

export default AssignUsers;
