import PropTypes from 'prop-types';
import React, { useRef } from 'react';

const SelectableDraggableContent = ({ children }) => {
  const contentRef = useRef(null);

  const handleDragStart = (event) => {
    if (contentRef.current) {
      event.dataTransfer.setData('text/plain', contentRef.current.innerText);
    }
  };

  const handleMouseDown = async () => {
    const selection = window.getSelection();
    const range = document.createRange();
    if (contentRef.current) {
      range.selectNodeContents(contentRef.current);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  return (
    <div
      ref={contentRef}
      className="selectable"
      draggable="true"
      style={{
        userSelect: 'text',
        cursor: 'grab',
      }}
      onDragStart={handleDragStart}
      onMouseDown={handleMouseDown}
    >
      {children}
    </div>
  );
};

SelectableDraggableContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SelectableDraggableContent;
