import React from 'react';

import Introduction from '../Introduction';
import { SETTINGS_NAVIGATION_INTRO } from 'constants/Navigation';

const PayersPortal = (props) => {
  const {
    payerPortals: { title, description, info },
  } = SETTINGS_NAVIGATION_INTRO;
  return (
    <div>
      {' '}
      <Introduction title={title} description={description} info={info} />
    </div>
  );
};

PayersPortal.propTypes = {};

export default PayersPortal;
