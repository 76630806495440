import { serializeHelpInfo } from './Mappers';
import { createVndApiRequest, createJSONFetch } from './Config';

export default class HelpAPI {
  static sendHelpInfo(attrs) {
    const serializedHelpInfo = serializeHelpInfo(attrs);
    const request = createVndApiRequest('help_messages', serializedHelpInfo);
    return createJSONFetch(request);
  }
}
