import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useEffect, useState } from 'react';

import AddTeam from './AddTeam';
import UsersListPopup from './UsersListPopup';
import Pagination from 'components/common/pagination';
import AddOrRemoveUsersPopup from './AddOrRemoveUsersPopup';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';

import emptyIcon from 'img/empty.svg';

import { SETTINGS_PAGE_LIMIT } from 'constants/appConstants';

import { useFetchTeams } from './hooks';

import { isClientAdmin } from 'Auth/AuthUtils';

import { getUserInfo } from 'redux/reducers/loginStore';
import {
  DROPDOWN_OPTIONS_STATE_KEYS,
  getDropdownOptions,
} from 'redux/reducers/dropdownOptionsStore';
import { fetchAgentOptions } from 'redux/actions/dropdownOptionsActions';

const Teams = ({ actions: { fetchUserOptions }, userInfo, usersOptions }) => {
  const [page, setPage] = useState(1);
  const [showAddTeamsPopup, setShowAddTeamsPopup] = useState(false);

  const [showUsersListPopup, setShowUsersListPopup] = useState({
    isOpen: false,
    teamId: null,
    teamName: null,
  });

  const [showAddOrRemoveUsersPopup, setShowAddOrRemoveUsers] = useState({
    teamId: null,
    teamName: null,
    isOpen: false,
  });

  useEffect(() => {
    fetchUserOptions();
  }, [fetchUserOptions]);

  const { loading, teams, fetch: fetchTeams, totalTeams } = useFetchTeams(page);

  /**
   * Handles the page change.
   * @param {Number} page
   */
  const onPageChange = (page) => setPage(page);

  /**
   * Handles the assign users complete.
   */
  const onAssignUsersComplete = () => {
    setShowAddOrRemoveUsers({
      isOpen: false,
      teamId: null,
      teamName: null,
    });
    fetchAgentOptions(true);
    fetchTeams();
  };

  const handleCloseShowUsersListPopup = () =>
    setShowUsersListPopup((prevState) => ({
      ...prevState,
      isOpen: false,
    }));

  const onAddRemoveUserClick = (team) => {
    setShowAddOrRemoveUsers((prevState) => ({
      ...prevState,
      isOpen: true,
      teamId: team.id,
      teamName: team.name,
    }));

    if (showUsersListPopup.isOpen) {
      handleCloseShowUsersListPopup();
    }
  };

  const pageCount = Math.ceil(totalTeams / SETTINGS_PAGE_LIMIT);
  const isAdminUser = isClientAdmin(userInfo);
  return (
    <div className="settings-content-wrapper">
      <p className="setting-content__description">
        Organize and Manage your Users into Teams.
      </p>
      {isAdminUser && (
        <div
          className={classnames(
            'd-flex row align-items-center settings-content__top-action',
            'justify-content--space-between'
          )}
        >
          <button
            onClick={() => setShowAddTeamsPopup(true)}
            className="ap-button ap-button--secondary ml-auto settings-add-button"
          >
            <span className="mr-4">+</span> Add Team
          </button>
        </div>
      )}
      <div className="row mt-12 ap-card-list-wraper">
        <div className="col-lg-12">
          {usersOptions?.isFetching || loading ? (
            <LoadingBlockHelper
              className="dashboard-bottom-loader"
              isLoading={loading}
            />
          ) : teams.length > 0 ? (
            <>
              <div className="row mb-16 create-acount-list-item-header">
                <div className="col-lg-3">Name</div>
                <div className="col-lg-6">Number of User(s)</div>
              </div>
              {teams.map((team, idx) => {
                return (
                  <div className="create-account-list-item row mb-16" key={idx}>
                    <div className="col-lg-3">{team.name}</div>
                    <div
                      className="col-lg-6 link-url"
                      onClick={() => {
                        setShowUsersListPopup({
                          isOpen: true,
                          teamId: team.id,
                          teamName: team.name,
                        });
                      }}
                    >
                      {team.userCount}
                    </div>
                    <div className="col-lg-3 text-right">
                      <span
                        className="link-url font-style-italic text-decoration-underline"
                        onClick={() => onAddRemoveUserClick(team)}
                      >
                        Add/Remove User(s)
                      </span>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="no-data-container mt-20">
              <img src={emptyIcon} alt="No Data Found" />
              <h4>No Data Found</h4>
            </div>
          )}
          {!usersOptions?.isFetching &&
          !loading &&
          totalTeams &&
          pageCount > 1 ? (
            <div className="d-flex justify-content-center">
              <Pagination
                pageCount={pageCount}
                activePage={page}
                onPageChange={onPageChange}
              />
            </div>
          ) : null}
        </div>
      </div>
      {showAddTeamsPopup && (
        <AddTeam
          onClosePressed={() => setShowAddTeamsPopup(false)}
          onAddTeamsSuccess={async (team) => {
            fetchTeams();
            setShowAddTeamsPopup(false);
            fetchUserOptions(true);
            setShowAddOrRemoveUsers({
              isOpen: true,
              teamId: team.id,
              teamName: team.name,
            });
          }}
        />
      )}
      {showUsersListPopup.isOpen && (
        <UsersListPopup
          onClosePressed={handleCloseShowUsersListPopup}
          usersOptions={usersOptions}
          teamId={showUsersListPopup.teamId}
          teamName={showUsersListPopup.teamName}
          isAdminUser={isAdminUser}
          onAddRemoveUserClick={onAddRemoveUserClick}
        />
      )}

      {showAddOrRemoveUsersPopup.isOpen && (
        <AddOrRemoveUsersPopup
          onClosePressed={() =>
            setShowAddOrRemoveUsers((prevState) => ({
              ...prevState,
              isOpen: false,
            }))
          }
          usersOptions={usersOptions}
          teamId={showAddOrRemoveUsersPopup.teamId}
          teamName={showAddOrRemoveUsersPopup.teamName}
          isAdminUser={isAdminUser}
          onAssignUsersComplete={onAssignUsersComplete}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const agentOptions = getDropdownOptions(
    state,
    DROPDOWN_OPTIONS_STATE_KEYS.AGENT_OPTIONS
  );
  return {
    userInfo: getUserInfo(state),
    usersOptions: agentOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { fetchUserOptions: fetchAgentOptions },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
