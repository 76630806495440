import classNames from 'classnames';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { BiX } from 'react-icons/bi';
import { BsPencil } from 'react-icons/bs';
import { MdInfoOutline } from 'react-icons/md';
import { bindActionCreators } from 'redux';
import { get, debounce, orderBy } from 'lodash';
import React, { useState, useCallback } from 'react';

import emptyIcon from 'img/empty.svg';

import { handleError } from 'helpers/errorHandler';
import { SEARCH_QUERY_CHARACTERS_OFFSET } from '../constants';

import { getUserInfo } from 'redux/reducers/loginStore';
import { setUserPractices } from 'redux/actions/loginActions';

import {
  SETTINGS_PAGE_LIMIT,
  UNGROUPED_PRACTICE_NAME,
} from 'constants/appConstants';

import AddPractice from './AddPractice';
import * as toast from 'components/Shared/toast';
import Pagination from 'components/common/pagination';
import SearchInput from 'components/common/input/SearchInput';
import TextHighlighter from 'components/common/textHighlighter';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import ChangePracticeStatusPopup from './ChangePracticeStatusPopup/ChangePracticeStatusPopup';

import { useFetchPractices } from '../hook';

import * as AccountsAPI from 'API/AccountSettingsAPI';
import ActionButton from 'components/common/actionButton/ActionButton';
import { isAuthorizedForPracticeGroup, isClientAdmin } from 'Auth/AuthUtils';

const Practice = (props) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [practiceToUpdate, setPracticeToUpdate] = useState(null);
  const [isPracticePopupOpen, setIsPracticePopupOpen] = useState(false);
  const [changePracticeStatusPopup, setChangePracticeStatusPopup] = useState({
    isOpen: false,
    practice: null,
    toDeactivate: true,
  });

  const { loading, practices, totalPractices, fetch } = useFetchPractices(
    page,
    search
  );

  const onPageChange = (page) => setPage(page);
  const pageCount = Math.ceil(totalPractices / SETTINGS_PAGE_LIMIT);

  const resetChangePracticeStatusPopup = () => {
    setChangePracticeStatusPopup({
      isOpen: false,
      practice: null,
      toDeactivate: false,
    });
  };

  const handleSubmit = async (values) => {
    const formData = { ...values };
    try {
      const requestPayload = !practiceToUpdate
        ? formData
        : { ...practiceToUpdate, ...values };

      const res = !practiceToUpdate
        ? await AccountsAPI.createPractice(requestPayload)
        : await AccountsAPI.updatePractice(requestPayload);

      const newPractices = !practiceToUpdate
        ? [...props.userPractices, res]
        : props.userPractices.map((practice) => {
            return practice.id === requestPayload.id
              ? requestPayload
              : practice;
          });
      props.actions.setUserPractices(orderBy(newPractices, ['name'], ['asc']));
      toast.success({
        title: 'Success',
        message: !practiceToUpdate
          ? 'Practice added successfully.'
          : 'Practice updated successfully.',
      });
      setIsPracticePopupOpen(false);
    } catch (error) {
      handleError(error);
    }

    fetch();
  };

  const handleSearch = (event) => {
    setPage(1);
    setSearch(event.target.value?.trim());
  };

  const clearSearch = () => setSearch('');

  const onSearch = useCallback((args) => debounce(handleSearch, 800)(args), []);

  const showPracticeGroup = isAuthorizedForPracticeGroup(props.userInfo);

  return (
    <div className="settings-content-wrapper" datacy="practice">
      <div className="d-flex align-items-center justify-content--space-between settings-content__top-action mt-40">
        <SearchInput
          placeholder="Search by Practice"
          onSearch={onSearch}
          onReset={clearSearch}
          datacy="search-for-practice-SearchInput"
        />
        {isClientAdmin(props.userInfo) && (
          <button
            onClick={() => {
              setPracticeToUpdate(null);
              setIsPracticePopupOpen(true);
            }}
            className="ap-button ap-button--secondary ml-auto settings-add-button"
            datacy="add-practice-button"
          >
            <span className="mr-4">+</span> Add Practice
          </button>
        )}
      </div>

      <div className="row mt-12 ap-card-list-wraper">
        <div className="col-lg-12">
          {loading && loading ? (
            <LoadingBlockHelper
              className="dashboard-bottom-loader"
              isLoading={loading}
            />
          ) : practices.length > 0 ? (
            <React.Fragment>
              <div className="row mb-16 create-acount-list-item-header">
                <div className="col-lg-1 d-flex align-items-center">
                  Status
                  <MdInfoOutline
                    size="18"
                    className="ml-8"
                    data-for="tooltip-status-info"
                    data-tip={
                      'Practices that are connected to your clearinghouse data are labeled “Integrated.” Practices that are not connected to your clearinghouse data are labeled “Not Integrated.” '
                    }
                  />
                </div>
                <div className="col-lg-2 ">Practice Name</div>
                <div className="col-lg-1">Logo</div>
                <div
                  className={classNames({
                    'col-lg-2': !showPracticeGroup,
                    'col-lg-1': showPracticeGroup,
                  })}
                >
                  Address Line 1
                </div>
                <div className="col-lg-2">Address Line 2</div>
                <div className="col-lg-1">City</div>
                <div className="col-lg-1">State</div>
                <div className="col-lg-1">Zip Code</div>
                {showPracticeGroup && (
                  <div className="col-lg-1">Practice Group</div>
                )}

                <div className="col-lg-1">&nbsp;</div>
                <ReactTooltip
                  effect="solid"
                  place="top"
                  multiline={true}
                  id="tooltip-status-info"
                  className="info-header__tooltip"
                />
              </div>

              {practices.map((practice, idx) => {
                const practiceType = practice.isIntegrated
                  ? 'Integrated'
                  : 'Not Integrated';
                return (
                  <div className="row mt-12 ap-card-list-wraper" key={idx}>
                    <div className="col-lg-12">
                      <div
                        className={classNames(
                          'create-account-list-item row mb-16',
                          {
                            'create-account-list-item--inactive':
                              !practice.isActive,
                          }
                        )}
                      >
                        <div className="col-lg-1 pl-0 pr-0">
                          <span
                            className={classNames('ap-card-list__pill', {
                              'ap-card-list__pill--integrated':
                                practice.isIntegrated,
                            })}
                          >
                            {practiceType}
                          </span>
                        </div>
                        <div className="col-lg-2">
                          <span data-tip={practice.name}>
                            {practice.name &&
                            search?.length >= SEARCH_QUERY_CHARACTERS_OFFSET ? (
                              <TextHighlighter
                                text={practice.name}
                                query={search}
                              />
                            ) : (
                              <span>{practice.name}</span> || '--'
                            )}
                          </span>
                        </div>
                        <div className="col-lg-1">
                          {practice.logoLink ? (
                            <img
                              width={60}
                              src={practice.logoLink}
                              alt="practice-logo"
                            />
                          ) : (
                            <span>--</span>
                          )}
                        </div>
                        <div
                          className={classNames({
                            'col-lg-2': !showPracticeGroup,
                            'col-lg-1': showPracticeGroup,
                          })}
                        >
                          <span data-tip={practice.address1}>
                            {practice.address1 || '--'}
                          </span>
                        </div>
                        <div className="col-lg-2">
                          <span data-tip={practice.address2}>
                            {practice.address2 || '--'}
                          </span>
                        </div>
                        <div className="col-lg-1">
                          <span data-tip={practice.city}>
                            {practice.city || '--'}
                          </span>
                        </div>
                        <div className="col-lg-1">
                          {' '}
                          <span data-tip={practice.state}>
                            {practice.state || '--'}
                          </span>
                        </div>
                        <div className="col-lg-1">
                          <span data-tip={practice.zipcode}>
                            {practice.zipcode || '--'}
                          </span>
                        </div>
                        {showPracticeGroup && (
                          <div className="col-lg-1">
                            {practice.inGroups
                              .map((name) =>
                                name === UNGROUPED_PRACTICE_NAME
                                  ? 'Ungrouped'
                                  : name
                              )
                              .join(', ')}
                          </div>
                        )}
                        {isClientAdmin(props.userInfo) && (
                          <div className="col-lg-1 d-flex justify-content-flex-end pr-0">
                            {practice.isActive ? (
                              <div className="d-flex">
                                <ActionButton
                                  Icon={BsPencil}
                                  className="mr-16 ml-auto"
                                  dataTip="Edit"
                                  onClick={(e) => {
                                    setPracticeToUpdate(practice);
                                    setIsPracticePopupOpen(true);
                                  }}
                                  iconClassName="appeal__action--appeal"
                                  datacy="practice-edit-ActionButton"
                                />
                                <ActionButton
                                  Icon={BiX}
                                  className="mr-16 ap-button--action-delete"
                                  dataTip="Deactivate"
                                  onClick={(e) => {
                                    setChangePracticeStatusPopup({
                                      isOpen: true,
                                      practice,
                                      toDeactivate: true,
                                    });
                                  }}
                                  iconClassName="appeal__action--appeal"
                                  datacy="practice-deactivate-ActionButton"
                                />
                              </div>
                            ) : (
                              <div>
                                <button
                                  onClick={(e) => {
                                    setChangePracticeStatusPopup({
                                      isOpen: true,
                                      practice,
                                      toDeactivate: false,
                                    });
                                  }}
                                  className="ap-button ap-button--secondary ml-auto settings-reactivate-button"
                                  datacy="practice-reactivate-button"
                                >
                                  Reactivate
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                        <ReactTooltip
                          effect="solid"
                          place="top"
                          multiline={true}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          ) : (
            <div className="no-data-container">
              <img src={emptyIcon} alt="No Data Found" />
              <h4>No Data Found</h4>
            </div>
          )}
        </div>
        {isPracticePopupOpen && (
          <AddPractice
            onClosePressed={() => setIsPracticePopupOpen(false)}
            onSubmit={handleSubmit}
            initialValues={practiceToUpdate}
            datacy="practice-AddPractice"
          />
        )}
        {changePracticeStatusPopup.isOpen && (
          <ChangePracticeStatusPopup
            onClosePressed={resetChangePracticeStatusPopup}
            practice={changePracticeStatusPopup.practice}
            title={`Are you sure want to ${
              changePracticeStatusPopup.toDeactivate
                ? 'deactivate'
                : 'reactivate'
            } this practice?`}
            onSubmitSuccess={() => {
              resetChangePracticeStatusPopup();
              fetch();
            }}
            toDeactivate={changePracticeStatusPopup.toDeactivate}
            datacy="practice-ChangePracticeStatusPopup"
          />
        )}
      </div>

      {!loading && totalPractices && pageCount > 1 ? (
        <div className="d-flex justify-content-center">
          <Pagination
            pageCount={pageCount}
            activePage={page}
            onPageChange={onPageChange}
            datacy="practice-Pagination"
          />
        </div>
      ) : null}
    </div>
  );
};

Practice.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setUserPractices }, dispatch),
});

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);
  const userPractices = get(userInfo, 'relatedPractices', []);

  return {
    userInfo,
    userPractices,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Practice);
