/**
 * Created by alex on 12/5/16.
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Base64 } from 'js-base64';
import classNames from 'classnames';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { GrClose } from 'react-icons/gr';
import * as Sentry from '@sentry/browser';
import { bindActionCreators } from 'redux';
import { replace, goBack, push } from 'connected-react-router';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import PDFForm from './PDFForm';

import {
  SUBMISSION_FAILED_ERROR,
  DELETE_LETTER_ERROR,
  INVALID_PAYER_FORM_PDF,
} from '../../Shared/Errors/errorMessages';
import Dropdown from '../../Shared/Dropdown';
import RichTextEditor from './RichTextEditor';
import Button from 'components/common/button';
import AddTemplate from './AddTemplate/AddTemplate';
import SubmitButton from '../../Shared/SubmitButton';
import { AlertDialog } from 'components/common/popup';
import DocumentViewer from '../Review/DocumentViewer';
import LetterAddedDialog from './LetterModalComponent';
import { formatTemplate, fillTemplate } from './TemplateFiller';
import { ErrorHandling } from '../../Shared/Errors/ErrorHandling';
import LoadingBlockHelper from '../../Shared/LoadingBlockHelper';
import SaveAndContinue from '../../Shared/SaveContinueFooterComponent';
import ErrorBannerSection from '../../Shared/Errors/ErrorBannerSection';
import { LetterIncompletePopup, FormIncompletePopup } from '../Popups/Popups';

import appealCheck from 'img/appeal_check.svg';
import incompleteIcon from '../../../img/incomplete.svg';

import Util from '../../../API/UtilAPI';
import AppealsAPI from 'API/AppealsAPI';
import { fetchPractice } from 'API/AccountSettingsAPI';
import { createURLWithParams } from '../../../API/Config';
import CreateAppealAPI from '../../../API/CreateAppealAPI';

import { getUserInfo } from 'redux/reducers/loginStore';
import { addAppealLetter } from '../../../redux/actions/createAppealActions';
import {
  isCurrentAppealMedicalRecord,
  getCurrentAppealID,
} from 'redux/reducers/createAppealStore';
import {
  CREATE_APPEAL_DENIAL_INFO,
  CREATE_APPEAL_DOCUMENTS,
  CREATE_APPEAL_REVIEW,
  CREATE_APPEAL_PATIENT_INFO,
} from 'constants/routes';

import { mimeTypes } from 'helpers/mimeTypes';
import { handleError } from 'helpers/errorHandler';
import ActionPopup from 'components/Shared/ActionPopup';

import { getBase64FromURL } from 'helpers/utils';

const DEFAULT_EMPTY_LETTER = '<p></p>';
export const CONTENT_TYPE = {
  PDF: 'pdf',
  LETTER: 'html',
};

class AppealLetter extends Component {
  static propTypes = {
    shouldShowPreviousButton: PropTypes.bool,
    appealData: PropTypes.object,
    editorContent: PropTypes.string,
    letterToEdit: PropTypes.object,
    templatesDropdownOptions: PropTypes.array,
    companyLogoImage: PropTypes.string,
    prefillMap: PropTypes.array,
    refetchAppeal: PropTypes.func,
    onEditLetter: PropTypes.func,
    letters: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      pdfValidationErrors: [],
      isInPreview: false,
      isDialogOpen: false,
      defaultEditorContent: this.props.editorContent
        ? this.props.editorContent
        : DEFAULT_EMPTY_LETTER,
      letterToEdit: this.props.letterToEdit,
      templateDropdownIndexSelected: -1,
      templatesDropdownOptions: this.props.templatesDropdownOptions,
      previewContent: '',
      currentNumberOfLetters: this.props.appealData.letters.length,
      isLoading: false,
      isDiscardDialogOpen: false,
      isFormIncompletePopupOpen: false,
      isSubmitting: false,
      selectedContentType: CONTENT_TYPE.LETTER,
      isTemplatePopupOpen: false,
      isAddTemplateStatusPopupOpen: {
        status: false,
        name: '',
      },
      appealLetterPdfPreview: {
        isLoading: false,
        fileUrl: null,
        isOpen: false,
      },
      showPDFErrorPopup: false,
      isFetchingLogo: false,
      practiceLogo: {},
    };
  }

  setAppealLetterPdfPreview = ({
    isLoading = false,
    fileUrl = null,
    isOpen = false,
  }) => {
    this.setState({
      appealLetterPdfPreview: {
        isLoading,
        fileUrl,
        isOpen,
      },
    });
  };

  componentDidMount() {
    if (this.props.letterToEdit) {
      const selectedContentType =
        this.props.letterToEdit.type === CONTENT_TYPE.PDF
          ? CONTENT_TYPE.PDF
          : CONTENT_TYPE.LETTER;

      this.setState({
        selectedContentType,
      });
    }
    this.fetchAndSetPracticeLogo();
  }

  fetchAndSetPracticeLogo = async () => {
    const { practiceId } = this.props.appealData;
    try {
      this.setState({
        isFetchingLogo: true,
      });
      const { logoLink } = await fetchPractice(practiceId);

      let url = null;
      if (logoLink) {
        url = await getBase64FromURL(logoLink);
      }
      this.setState({
        practiceLogo: {
          base64Url: url,
          showPracticeLogo: true,
        },
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({
        isFetchingLogo: false,
      });
    }
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    const newState = {};

    if (
      newProps.templatesDropdownOptions &&
      newProps.templatesDropdownOptions !== this.props.templatesDropdownOptions
    ) {
      newState.templatesDropdownOptions = newProps.templatesDropdownOptions;
    }

    this.setState(newState);
  }

  onContentTypeChange = (e) => {
    this.setState({
      selectedContentType: e.target.value,
      defaultEditorContent: DEFAULT_EMPTY_LETTER,
      templateDropdownIndexSelected: -1,
      isInPreview: false,
      isLoading: false,
    });
  };

  getTemplateDropdownOptions = () => {
    const { templatesDropdownOptions } = this.state;

    const templates = templatesDropdownOptions.filter(
      (option) => option.type === this.state.selectedContentType
    );

    /**
     * Sorts suggested templates by their rank.
     * The lower the rank, the more likely the template is to be suggested.
     * @param {Array} templates
     * @returns {Array}
     */
    const orderSuggestedTemplates = (templates) =>
      _.orderBy(templates, ['rank', 'title'], ['asc', 'asc']);

    const defaultOrderedTemplates = _.orderBy(
      templates,
      ['rank', 'title'],
      ['desc', 'asc']
    );

    const isSuggestedTemplate = (template) => template.rank < 0;

    const suggestedTemplateOptions = orderSuggestedTemplates(
      templates.filter(isSuggestedTemplate)
    ).map(this.extractDropdownOption);

    if (suggestedTemplateOptions.length === 0) {
      return defaultOrderedTemplates.map(this.extractDropdownOption);
    }

    const otherTemplateOptions = defaultOrderedTemplates
      .filter((t) => !isSuggestedTemplate(t))
      .map(this.extractDropdownOption);

    return [
      {
        label: 'Suggested Templates',
        options: suggestedTemplateOptions,
      },
      {
        label: 'Remaining Templates',
        options: otherTemplateOptions,
      },
    ];
  };

  renderContent = () => {
    if (
      !this.state.letterToEdit &&
      this.state.selectedContentType === CONTENT_TYPE.PDF &&
      this.state.templateDropdownIndexSelected === -1
    ) {
      return <div className="appeal-letter__empty-container"></div>;
    }

    return (
      <React.Fragment>
        {this.renderRTFLetter()}
        {this.renderPDFLetter()}
      </React.Fragment>
    );
  };

  onOpenTemplatePopup = () => {
    this.setState({
      isTemplatePopupOpen: true,
    });
  };

  onCloseTemplatePopup = () => {
    this.setState({
      isTemplatePopupOpen: false,
    });
  };

  onTemplateAddition = (templateName) => {
    this.setState({
      isAddTemplateStatusPopupOpen: {
        status: true,
        name: templateName,
      },
    });
  };

  renderTemplatePopup = () => {
    const { isTemplatePopupOpen, selectedContentType } = this.state;
    if (isTemplatePopupOpen) {
      return (
        <AddTemplate
          onClose={this.onCloseTemplatePopup}
          title={
            selectedContentType === CONTENT_TYPE.LETTER
              ? 'Request New Letter Template'
              : 'Request New Payer Form'
          }
          contentType={selectedContentType}
          onSuccess={this.onTemplateAddition}
        />
      );
    }
  };

  attachPracticeLogo = () => {
    const {
      practiceLogo: { base64Url, showPracticeLogo },
    } = this.state;

    let container = '';
    if (!showPracticeLogo || !base64Url) {
      return;
    }
    container = `
    <div>
      <div style="display: block; float: right;">
        <img src="${base64Url}" id="template-logo" style="width: 128px; max-height: 128px; object-fit: contain;" />
      </div>
      <div style="clear: both;">
        ${this.state.previewContent}
      </div>
    </div>
    `;
    this.setState({
      defaultEditorContent: container,
      isAddLogoPopupOpen: false,
    });
  };

  handlePracticeLogo = async () => {
    const {
      practiceLogo: { base64Url, showPracticeLogo },
    } = this.state;

    this.setState({
      practiceLogo: {
        base64Url,
        showPracticeLogo: !showPracticeLogo,
      },
    });

    const selectedDropdownOption =
      this.state.templateDropdownIndexSelected === -1
        ? null
        : this.extractDropdownOption(
            this.state.templatesDropdownOptions[
              this.state.templateDropdownIndexSelected
            ]
          );
    await this.onTemplateChange(selectedDropdownOption);
  };

  closeLetterTemplatePdfPreview = () => {
    this.setAppealLetterPdfPreview({
      isOpen: false,
      isLoading: false,
      fileUrl: null,
    });
  };

  renderLetterTemplatePreview() {
    if (this.state.appealLetterPdfPreview.isOpen) {
      const finalPackage = [
        {
          link: this.state.appealLetterPdfPreview.fileUrl || '',
          name: 'Letter PDF Preview',
          docType: mimeTypes.APPLICATION_PDF,
        },
      ];

      return (
        <DocumentViewer
          documents={finalPackage}
          isLoadingDocuments={this.state.appealLetterPdfPreview.isLoading}
          defaultIndex={0}
          previewTitle="Cover letter"
          onClosePress={this.closeLetterTemplatePdfPreview}
          datacy="submit-confirm-cover-letter-DocumentViewer"
        />
      );
    }
  }

  renderAddTemplateStatusPopup = () => {
    const {
      isAddTemplateStatusPopupOpen: { name },
    } = this.state;
    return (
      <AlertDialog
        onClosePressed={() => {
          this.setState({
            isAddTemplateStatusPopupOpen: {
              status: false,
              name: '',
            },
          });
        }}
        className=""
        statusIcon={appealCheck}
      >
        <p>
          Your <span className="fw-bold">{name}</span> was successfully
          submitted.
          <br />
          Our CX team will reach out to you via email within 1 business day.
        </p>
      </AlertDialog>
    );
  };

  getSelectedDropdownOption = () => {
    const { templateDropdownIndexSelected, templatesDropdownOptions } =
      this.state;
    if (templateDropdownIndexSelected === -1) {
      return null;
    }
    return templatesDropdownOptions[templateDropdownIndexSelected];
  };

  render() {
    const appealLetter = classNames('appeal-letter', {
      'appeal-letter__in-preview': this.state.isInPreview === true,
    });

    const dropdownOptions = this.getTemplateDropdownOptions();
    const selectedDropdownOption =
      this.state.templateDropdownIndexSelected === -1
        ? null
        : this.extractDropdownOption(
            this.state.templatesDropdownOptions[
              this.state.templateDropdownIndexSelected
            ]
          );

    const templateBtnTitle =
      this.state.selectedContentType === CONTENT_TYPE.LETTER
        ? 'Request New Letter Template'
        : 'Request New Payer Form';

    const selectedLetterOrForm =
      this.state.templateDropdownIndexSelected !== -1 ||
      this.state.letterToEdit;

    return (
      <div>
        <div className="row appeal-letter__row-no-padding">
          <div className="appeal-letter__preview-root">
            <div className={appealLetter}>
              <ErrorBannerSection
                className="appeal-letter__content--error"
                errors={this.mergeStateAndPropsErrors()}
              />
              <div className="appeal-letter__content">
                <div className="row d-flex align-items-center appeal-letter-row">
                  <div className="appeal-letter__select-wrapper">
                    <div className="row">
                      <div className="col-xs-offset-1 col-xs-8 mb-8">
                        <div className="fw-bold mb-8">
                          1. Select Payer Form or Letter Template{' '}
                        </div>
                        <div>
                          <span className="mr-44">
                            <label>
                              <input
                                className="mr-8"
                                type="radio"
                                checked={
                                  this.state.selectedContentType ===
                                  CONTENT_TYPE.PDF
                                }
                                onChange={this.onContentTypeChange}
                                name={CONTENT_TYPE.PDF}
                                value={CONTENT_TYPE.PDF}
                                disabled={this.shouldDisableTemplateSelection()}
                                datacy="select-payer-form-radio-input"
                              />
                              Payer Form
                            </label>
                          </span>
                          <span>
                            <label>
                              <input
                                type="radio"
                                className="mr-8"
                                checked={
                                  this.state.selectedContentType ===
                                  CONTENT_TYPE.LETTER
                                }
                                onChange={this.onContentTypeChange}
                                name={CONTENT_TYPE.LETTER}
                                value={CONTENT_TYPE.LETTER}
                                disabled={this.shouldDisableTemplateSelection()}
                                datacy="select-letter-template-radio-input"
                              />
                              Letter Template
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-offset-1 col-xs-4 width-450 mb-8">
                        <div className="fw-bold mb-8">
                          2. Select{' '}
                          {this.state.selectedContentType ===
                          CONTENT_TYPE.LETTER
                            ? 'Letter Template'
                            : 'Payer Form'}{' '}
                        </div>
                        <Dropdown
                          placeholder="Please choose..."
                          options={dropdownOptions}
                          value={selectedDropdownOption}
                          onChange={this.onTemplateChange}
                          isDisabled={
                            this.shouldDisableTemplateSelection() ||
                            this.state.isLoading
                          }
                          classNamePrefix="appeal-letter__dropdown"
                          datacy="appeal-letter-Dropdown"
                        />
                        <label className="add-new-template">
                          <span onClick={this.onOpenTemplatePopup}>
                            {templateBtnTitle}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames('row appeal-letter__separator', {
                    'mb-16': this.state.practiceLogo.base64Url,
                    'mb-28': !this.state.practiceLogo.base64Url,
                  })}
                />
                {!this.isRTFLetterEmpty() &&
                  this.state.selectedContentType === CONTENT_TYPE.LETTER &&
                  this.state.practiceLogo.base64Url && (
                    <div className="d-flex justify-content-flex-end align-items-center">
                      <label className="mr-24 fs-14 d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="mr-4 mt-0"
                          checked={this.state.practiceLogo.showPracticeLogo}
                          onChange={_.debounce(this.handlePracticeLogo, 500)}
                        />
                        Add Logo
                      </label>
                    </div>
                  )}
                {this.renderContent()}
              </div>
            </div>

            <ReactCSSTransitionGroup
              transitionName="slide"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={500}
            >
              {this.renderPreview()}
            </ReactCSSTransitionGroup>
          </div>
          <div className="row">
            <div className="appeal-letter__bottom">
              <LoadingBlockHelper
                isLoading={this.state.isSubmitting}
                className="appeal-box__loading"
              >
                <SaveAndContinue
                  submitDisabled={
                    !this.shouldRenderPDFForm() && this.isRTFLetterEmpty()
                  }
                  shouldShowPreviousButton={this.props.shouldShowPreviousButton}
                  shouldShowCancelButton={false}
                  onPreviousPressed={this.onPreviousPressed}
                  onSavePressed={this.onSavePressed}
                  onCancelPressed={this.onCancelPressed}
                  shouldShowDiscardAndSkipButton
                  discardAndSkipBtnTitle={
                    selectedLetterOrForm
                      ? 'Discard & Skip to Attach Documents'
                      : 'Skip to Attach Documents'
                  }
                  onDiscardAndSkipPressed={
                    selectedLetterOrForm
                      ? this.onDiscardPressed
                      : () => this.onContinuePressed(false)
                  }
                  datacy="SaveAndContinue"
                />
              </LoadingBlockHelper>
            </div>
          </div>
          {this.renderDialog()}
        </div>
        {this.renderIncompleteDialog()}
        {this.renderIncompleteFormDialog()}
        {this.renderTemplatePopup()}
        {this.renderPdfErrorPopup()}
        {this.renderLetterTemplatePreview()}
        {this.state.isAddTemplateStatusPopupOpen.status &&
          this.renderAddTemplateStatusPopup()}
      </div>
    );
  }

  renderRTFLetter() {
    if (this.shouldRenderPDFForm()) return;

    const appealLetterEditor = classNames('appeal-letter__edit', {
      'appeal-letter__edit-in-preview': this.state.isInPreview === true,
    });

    if (this.isRTFLetterEmpty()) {
      return <div className="appeal-letter__empty-container"></div>;
    }

    return (
      <div>
        <div className={appealLetterEditor}>
          <RichTextEditor
            // eslint-disable-next-line react/no-string-refs
            ref="textEditor"
            content={this.state.defaultEditorContent}
            onContentChange={this.handleContentChange}
            datacy="appeal-letter-RichTextEditor"
          />
        </div>
        <div className="appeal-letter__preview-button--layout">
          <SubmitButton
            title={'Preview Letter'}
            className={'appeal-letter__preview-button'}
            onClick={this.togglePreview}
            datacy="preview-letter-SubmitButton"
          />
        </div>
      </div>
    );
  }

  renderPDFLetter() {
    if (!this.shouldRenderPDFForm()) return;
    return (
      <PDFForm
        ref={(ref) => {
          this.pdfform = ref;
        }}
        pdfUrl={this.getPdfDocumentUrl()}
        prefillMap={this.props.prefillMap}
        shouldPrefill={this.shouldPrefillPDFForm()}
        onDocumentLoadError={() => this.setState({ isLoading: false })}
        onDocumentLoad={this.onPDFDocumentLoaded}
        datacy="PDFForm"
      />
    );
  }

  handlePreviewPdfClick = async () => {
    try {
      this.setAppealLetterPdfPreview({
        isOpen: true,
        isLoading: true,
        fileUrl: null,
      });

      const { content } = await this.getCurrentLetterContent();
      const pdfUrl = await AppealsAPI.getAppealLetterPdfPreview(content?.html);
      this.setAppealLetterPdfPreview({
        isOpen: true,
        isLoading: false,
        fileUrl: pdfUrl,
      });
    } catch (error) {
      this.closeLetterTemplatePdfPreview();
      handleError(error);
    }
  };

  renderPreview() {
    if (this.state.isInPreview === true) {
      return (
        <div className="appeal-letter__preview">
          <div className="appeal-letter__preview-header-container">
            <span
              className="d-flex appeal-letter__preview--header"
              datacy="letter-Preview"
            >
              Preview
              {this.props?.userInfo?.isStaff && (
                <Button className="ml-8" onClick={this.handlePreviewPdfClick}>
                  Preview as PDF
                </Button>
              )}
            </span>
            <GrClose
              onClick={this.togglePreview}
              className="appeal-letter__preview-close-btn"
              title="Close Preview"
              datacy="close-preview-GrClose"
            />
          </div>
          <div className="appeal-letter__preview--background">
            <div className="appeal-letter__preview--content">
              <span
                dangerouslySetInnerHTML={{
                  __html: this.addLogoImgTag(this.state.previewContent),
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  renderDialog() {
    if (this.state.isDialogOpen === true) {
      return (
        <LetterAddedDialog
          documentType={this.shouldRenderPDFForm() ? 'pdf' : 'html'}
          fileNames={this.getDialogLettersNames()}
          onClosePressed={this.closeDialog}
          onAddPressed={this.onAddPressed}
          onContinuePressed={this.onContinuePressed}
          onCancelPressed={this.closeDialog}
          isLoading={this.state.isLoading}
          isMedicalRecordsSubmission={this.props.isMedicalRecordsSubmission}
          datacy="LetterAddedDialog"
        />
      );
    }
  }

  renderIncompleteDialog() {
    const { title = '' } = this.getSelectedDropdownOption() || {};
    const fileTitleName = title?.toUpperCase() || '';

    const popupParams = {
      onClosePressed: this.closeDialog,
      onActionButtonPressed: this.onActionButtonPressed,
      actionPopupClassName: 'appeal-letter__discard-popup',
      title: '',
      subtitle: this.state.letterToEdit ? (
        <b>Are you sure you want to continue without saving the changes?</b>
      ) : (
        <b>
          Are you sure you want to Attach Documents and Discard {fileTitleName}?
        </b>
      ),
    };

    if (this.state.isDiscardDialogOpen === true) {
      return <LetterIncompletePopup {...popupParams} />;
    }
  }

  closeIncompleteFormDialog = () => {
    this.setState({
      isFormIncompletePopupOpen: false,
    });
  };

  renderIncompleteFormDialog = () => {
    const handleActionButtonPressed = (index) => {
      if (index === 0) {
        this.closeIncompleteFormDialog();
      } else if (index === 1) {
        this.closeIncompleteFormDialog();
        if (this.state.letterToEdit) {
          this._advanceToReview();
        } else {
          this.setState({ isDialogOpen: true });
        }
      }
    };

    if (this.state.isFormIncompletePopupOpen === true) {
      return (
        <FormIncompletePopup
          onClosePressed={this.closeIncompleteFormDialog}
          onActionButtonPressed={handleActionButtonPressed}
        />
      );
    }
  };

  renderPdfErrorPopup = () => {
    if (!this.state.showPDFErrorPopup) return null;

    return (
      <ActionPopup
        title=""
        actionButtons={[]}
        icon={incompleteIcon}
        onClosePressed={() => this.setState({ showPDFErrorPopup: false })}
      >
        <div className="fs-18 text-center ">
          Our Payer Form auto-fill feature is temporarily under repair and
          unavailable. We apologize for the inconvenience. We hope to resolve
          the issue shortly. Please contact cx@docvocate.com with any questions.
        </div>
      </ActionPopup>
    );
  };

  onLetterDataChange = (action, id) => {
    if (action === 'delete' && this.props.appealData.letters.length > 1) {
      CreateAppealAPI.deleteAppealLetter(id)
        .then(() => {
          const newLettersState = Util.cloneObject(this.state.letters);
          newLettersState.forEach((letter, i) => {
            if (id === letter.id) {
              newLettersState.splice(i, 1);
            }
          });
          this.props.appealData.letters.forEach((letter, i) => {
            if (id === letter.id) {
              this.props.appealData.letters.splice(i, 1);
            }
          });
          this.setState({ letters: newLettersState });
          if (this.state.letterToEdit && this.state.letterToEdit.id === id) {
            this._advanceToReview(false);
          }
        })
        .catch((e) => {
          this.props.updateErrors([DELETE_LETTER_ERROR]);
        });
    }
  };

  onActionButtonPressed = (buttonIndex) => {
    if (buttonIndex === 0) {
      this.closeDialog();
    } else if (buttonIndex === 1) {
      if (this.state.letterToEdit) {
        this.props.actions.replace(
          `${CREATE_APPEAL_REVIEW}?appealId=${this.props.currentAppealID}`
        );
      } else {
        this.onContinuePressed(false);
      }
    }
  };

  mergeStateAndPropsErrors = () => {
    return this.state.pdfValidationErrors.concat(this.props.displayErrors);
  };

  shouldDisableTemplateSelection = () => {
    return !!this.state.letterToEdit;
  };

  shouldRenderPDFForm = () => {
    return (
      (this.state.templateDropdownIndexSelected !== -1 &&
        this.state.templatesDropdownOptions[
          this.state.templateDropdownIndexSelected
        ].type === 'pdf') ||
      (this.state.letterToEdit && this.state.letterToEdit.type === 'pdf')
    );
  };

  shouldPrefillPDFForm = () => {
    return !this.state.letterToEdit;
  };

  getPdfDocumentUrl = () => {
    if (this.state.letterToEdit) {
      return createURLWithParams(
        `appeal_letter_pdfs/${this.state.letterToEdit.id}`
      );
    } else {
      return createURLWithParams(
        `appeal_template_pdfs/${
          this.state.templatesDropdownOptions[
            this.state.templateDropdownIndexSelected
          ].id
        }`
      );
    }
  };

  closeDialog = () => {
    this.setState({ isDialogOpen: false, isDiscardDialogOpen: false });
  };

  handleContentChange = (content) => {
    this.setState({
      previewContent: this.transformPreview(content),
    });
  };

  onPDFDocumentLoaded = () => {
    this.setState({
      isLoading: false,
    });
  };

  extractDropdownOption = (template) => {
    const { id, title } = template;

    return {
      label: title,
      value: id,
    };
  };

  onTemplateChange = async (selectedOption) => {
    const { value } = selectedOption;

    const selectedDropdownIndex = this.state.templatesDropdownOptions.findIndex(
      ({ id }) => id === value
    );

    const selectedDropdownOption =
      selectedDropdownIndex === -1
        ? this.getEmptyLetterOption()
        : this.state.templatesDropdownOptions[selectedDropdownIndex];

    const isTemplatePDF = selectedDropdownOption.type === 'pdf';
    this.setState(
      {
        pdfValidationErrors: [],
        templateDropdownIndexSelected: selectedDropdownIndex,
        defaultEditorContent: this.fillTemplateContent(
          selectedDropdownOption.content
        ),
        isInPreview: !isTemplatePDF,
        isLoading: isTemplatePDF,
      },
      () => setTimeout(this.attachPracticeLogo, 500)
    ); // INFO: Required to delay the call back to render the content in the editor first
  };

  getEmptyLetterOption = () => {
    return { content: DEFAULT_EMPTY_LETTER, type: 'html' };
  };

  fillTemplateContent(templateContent) {
    templateContent = formatTemplate(templateContent, this.props.prefillMap);
    return templateContent;
  }

  addLogoImgTag(content) {
    // Do not add if clinic has no company logo or the encoding is an empty image
    if (
      !this.props.companyLogoImage ||
      this.props.companyLogoImage.length < 24
    ) {
      return content;
    }
    const imgTag = `<img style="display:block; text-align: center; margin: 0 auto;" src=${this.props.companyLogoImage} alt="Logo"/></div>`;
    return imgTag.concat(content);
  }

  togglePreview = () => {
    this.setState({
      isInPreview: !this.state.isInPreview,
      // eslint-disable-next-line react/no-string-refs
      previewContent: this.transformPreview(this.refs.textEditor.getContent()),
    });
  };

  onPreviousPressed = () => {
    if (this.props.isMedicalRecordsSubmission === true) {
      return this.props.actions.replace(
        `${CREATE_APPEAL_PATIENT_INFO}?appealId=${this.props.currentAppealID}`
      );
    }
    this.props.actions.replace(
      `${CREATE_APPEAL_DENIAL_INFO}?appealId=${this.props.currentAppealID}`
    );
  };

  onDiscardPressed = () => {
    this.setState({ isDiscardDialogOpen: true });
  };

  setPdfValidationErrors = (pdfValidationErrors) =>
    this.setState({ pdfValidationErrors });

  resetPdfValidationErrors = (cb) =>
    this.setState({ pdfValidationErrors: [] }, cb);

  onSavePressed = async () => {
    let validationErrors = [];
    if (this.shouldRenderPDFForm()) {
      validationErrors = await this.pdfform.validate();

      this.resetPdfValidationErrors(() =>
        this.setPdfValidationErrors(validationErrors)
      );
    }
    if (validationErrors.length !== 0) {
      this.setState({ isFormIncompletePopupOpen: true });
      window.scrollTo(0, 0);
      return;
    }

    if (this.state.letterToEdit) {
      this.setState({ isSubmitting: true });
      this._advanceToReview();
    } else {
      this.setState({ isDialogOpen: true });
    }
  };

  onCancelPressed = () => {
    if (this.state.letterToEdit) {
      this.props.actions.replace(
        `${CREATE_APPEAL_REVIEW}?appealId=${this.props.currentAppealID}`
      );
    } else {
      this.props.actions.goBack();
    }
  };

  onAddPressed = () => {
    this.setState({ isLoading: true });

    this.sendAppealLetter().then(() => {
      this.setState(
        {
          isDialogOpen: false,
          isInPreview: false,
          previewContent: '',
          defaultEditorContent: DEFAULT_EMPTY_LETTER,
          templateDropdownIndexSelected: -1,
          currentNumberOfLetters: this.state.currentNumberOfLetters + 1,
          isLoading: false,
        },
        () => {
          this.props.refetchAppeal();
        }
      );
    });
  };

  onContinuePressed = (save = true) => {
    this.setState({ isLoading: true });

    if (save) {
      this.sendAppealLetter()
        .then(() => {
          this._advanceNextStep();
        })
        .catch((err) => {
          this.setState({ isLoading: false, isDialogOpen: false });
          if (err && err.type === 'InvalidPDF') {
            this.setPdfValidationErrors([INVALID_PAYER_FORM_PDF]);
            window.scrollTo(0, 0);
          } else {
            throw err;
          }
        });
    } else {
      this._advanceNextStep();
    }
  };

  _advanceToReview = (save = true) => {
    if (!save) {
      this.setState({ isSubmitting: false });
      this.props.actions.replace(
        `${CREATE_APPEAL_REVIEW}?appealId=${this.props.currentAppealID}`
      );
      return;
    }
    this.getCurrentLetterContent().then((content) => {
      this.props.actions.addAppealLetter(
        {
          name: this.state.letterToEdit.name,
          ...content,
          appealId: this.props.appealData.id,
          id: this.state.letterToEdit.id,
        },
        true
      );
      this.setState({ isSubmitting: false });
      this.props.actions.replace(
        `${CREATE_APPEAL_REVIEW}?appealId=${this.props.currentAppealID}`
      );
    });
  };

  _advanceNextStep = () => {
    CreateAppealAPI.updateAppeal(this.props.appealData.id, 3, {})
      .then((res) => {
        this.setState(
          {
            isDialogOpen: false,
            isDiscardDialogOpen: false,
            isLoading: false,
            isSubmitting: false,
          },
          () => {
            this.props.actions.push(
              `${CREATE_APPEAL_DOCUMENTS}?appealId=${this.props.appealData.id}`
            );
          }
        );
      })
      .catch((e) => {
        this.setState({
          isDialogOpen: false,
          isDiscardDialogOpen: false,
          isLoading: false,
          isSubmitting: false,
        });
        this.props.updateErrors([SUBMISSION_FAILED_ERROR]);
      });
  };

  sendAppealLetter = () => {
    return new Promise((resolve, reject) => {
      this.getCurrentLetterContent()
        .then((content) => {
          this.props.actions
            .addAppealLetter(
              {
                name: this.getNextLetterDisplayName(),
                ...content,
                appealId: this.props.appealData.id,
                templateId:
                  this.state.templateDropdownIndexSelected >= 0
                    ? this.state.templatesDropdownOptions[
                        this.state.templateDropdownIndexSelected
                      ].id
                    : undefined,
              },
              false
            )
            .then(() => {
              resolve();
            });
        })
        .catch((err) => reject(err));
    });
  };

  getNextLetterDisplayName = () => {
    let letterName = '';

    const isNoTemplatesSelected =
      this.state.templateDropdownIndexSelected === -1;
    const letterPrefix = isNoTemplatesSelected ? ' #' : ' - ';
    letterName = isNoTemplatesSelected
      ? 'Letter'
      : this.state.templatesDropdownOptions[
          this.state.templateDropdownIndexSelected
        ].title;

    let maxSuffix = 0;
    if (this.props.appealData.letters.length) {
      maxSuffix = 1;
      for (let i = 0; i < this.props.appealData.letters.length; i++) {
        if (_.startsWith(this.props.appealData.letters[i].name, letterName)) {
          const letterNumber = parseInt(
            this.props.appealData.letters[i].name
              .replace(letterName, '')
              .replaceAll(' ', '')
              .replace('#', '')
              .replace('-', ''),
            10
          );
          if (letterNumber + 1 > maxSuffix) {
            maxSuffix = letterNumber + 1;
          }
        }
      }
    }

    return letterName + (maxSuffix > 0 ? `${letterPrefix}${maxSuffix}` : '');
  };

  getDialogLettersNames = () => {
    const currentLetter = this.getNextLetterDisplayName();
    const names = [];

    if (this.props.appealData.letters.length > 0) {
      this.props.appealData.letters.forEach((item) => {
        names.push(item.name);
      });
    }

    names.push(currentLetter);
    return names;
  };

  isRTFLetterEmpty = () => {
    const content = this.state.previewContent;
    const editorContent = this.state.defaultEditorContent;
    return (
      content !== undefined &&
      (content.length === 0 || content === DEFAULT_EMPTY_LETTER) &&
      (editorContent.length === 0 || editorContent === DEFAULT_EMPTY_LETTER)
    );
  };

  transformPreview = (previewContent) => {
    return fillTemplate(previewContent, this.props.prefillMap);
  };

  getCurrentLetterContent = () => {
    return new Promise((resolve, reject) => {
      if (this.shouldRenderPDFForm()) {
        this.pdfform
          .getPdfFileData()
          .then((blob) => {
            resolve({
              content: {
                type: 'pdf',
                blob,
              },
            });
          })
          .catch((err) => {
            Sentry.captureException(err);
            this.setState({
              showPDFErrorPopup: true,
            });
            reject(err);
          });
      } else {
        resolve({
          content: {
            type: 'html',
            html: Base64.encode(
              this.addLogoImgTag(
                this.state.previewContent
                  ? this.state.previewContent
                  : this.state.defaultEditorContent
              )
            ),
          },
        });
      }
    });
  };

  _fetchLettersThumbnail(letters) {
    if (!letters || letters.length < 1) {
      return;
    }
    return letters.map((letter) => {
      return {
        src: createURLWithParams(`appeal_letter_pdfs/${letter.id}/`, {
          type: 'preview',
        }),
        title: letter.name,
        id: letter.id,
      };
    });
  }
}

function mapStateToProps(state) {
  const currentAppealID = getCurrentAppealID(state);
  const userInfo = getUserInfo(state);
  return {
    currentAppealID,
    userInfo,
    isMedicalRecordsSubmission: isCurrentAppealMedicalRecord(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { addAppealLetter, replace, goBack, push },
      dispatch
    ),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandling(AppealLetter));
