import React from 'react';
import classnames from 'classnames';
import { BiDownload } from 'react-icons/bi';
import PropTypes from 'prop-types';

import './downloadCSV.css';

import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';

const DownloadCSV = ({
  onClick,
  disabled = false,
  className,
  isLoading = false,
  datacy,
}) => {
  return (
    <div className="download-csv-wrapper">
      {isLoading && <LoadingBlockHelper isLoading={true} />}
      <button
        data-tip="Download CSV"
        data-place="bottom"
        onClick={onClick}
        className={classnames('ap-button ap-button--download mr-16', {
          [className]: className,
          'ap-button--disabled': disabled || isLoading,
        })}
        disabled={disabled || isLoading}
        datacy="DownloadCSV"
      >
        <React.Fragment>
          <BiDownload size={18} className="mr-8" />
          Export CSV
        </React.Fragment>
      </button>
    </div>
  );
};

DownloadCSV.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default DownloadCSV;
