import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { goBack } from 'connected-react-router';

class BackButton extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    title: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    datacy: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    title: 'Cancel',
  };

  render() {
    return (
      <div>
        <button
          onClick={this.props.onClick || this.props.actions.goBack}
          className="back__button"
          type="button"
          disabled={this.props.disabled}
          style={this.props.style || {}}
          datacy="back-button"
        >
          {' '}
          {this.props.title}
        </button>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ goBack }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(BackButton);
