import { Map } from 'immutable';

import {
  OPEN_UHC_CLAIM_STATUS_WIDGET,
  CLOSE_UHC_CLAIM_STATUS_WIDGET,
} from 'redux/actions/actionTypes';

const DEFAULT_STATE = {
  isOpen: false,
  data: {
    claimControlNumber: '',
    billingProviderTaxId: '',
    ediPayerId: '',
    isClaimSearchViaRpaEnabled: false,
    billingProviderName: '',
    renderingProviderName: '',
    appealId: '',
  },
};

const initialState = Map(DEFAULT_STATE);

export const uhcClaimStatusStoreWidgetStore = (
  state = initialState,
  action = {}
) => {
  switch (action.type) {
    case OPEN_UHC_CLAIM_STATUS_WIDGET:
      return state.set('isOpen', true).set('data', {
        ...DEFAULT_STATE.data,
        ...action.payload,
      });
    case CLOSE_UHC_CLAIM_STATUS_WIDGET:
      return state.set('isOpen', false).set('data', DEFAULT_STATE.data);
    default:
      return state;
  }
};
