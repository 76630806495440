import { pickBy, identity } from 'lodash';

import { camelizeKeys, snakeCaseKeys } from '../helpers/object';
import {
  handleErrors,
  handleJSONParse,
  createGetRequest,
  INTEGRATION_API_BASE_URL,
  createRequest as createPostRequest,
  extractIntegrationAPIHeaders,
} from './Config';

import { TEAM_DASHBOARD_COUNTS } from 'constants/endpoints';

export default class DashboardAPI {
  static fetchAgentLeaderBoard = (filters) => {
    const nonEmptyFilters = pickBy(filters, identity);

    const request = createGetRequest('agent-leaderboard', nonEmptyFilters);

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => res.data.map(camelizeKeys));
  };

  static fetchAgentAppealSubmissionDate = (filters) => {
    const nonEmptyFilters = pickBy(filters, identity);

    const request = createGetRequest(
      'agent-appeal-submission-date',
      nonEmptyFilters
    );

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => res.data.map(camelizeKeys));
  };

  static fetchPayerAppealSubmissionDate = (filters) => {
    const nonEmptyFilters = pickBy(filters, identity);

    const request = createGetRequest(
      'payer-appeal-submission-date',
      nonEmptyFilters
    );

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => res.data.map(camelizeKeys));
  };

  static fetchDeadlinesMetVsAllDeadlines = (filters) => {
    const nonEmptyFilters = pickBy(filters, identity);

    const request = createGetRequest(
      'deadline-met-vs-all-deadlines',
      nonEmptyFilters
    );

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => res.data.map(camelizeKeys));
  };

  static fetchTimeInEachState = (filters) => {
    const nonEmptyFilters = pickBy(filters, identity);

    const request = createGetRequest(
      'agent-time-in-each-state-duration',
      nonEmptyFilters
    );

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => res.data.map(camelizeKeys));
  };

  static fetchAppealsSuccessByAgent = (filters) => {
    const nonEmptyFilters = pickBy(filters, identity);

    const request = createGetRequest(
      'dashboard-appeal-success-by-agent',
      nonEmptyFilters
    );

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => res.data.map(camelizeKeys));
  };

  static fetchAppealsSubmittedByAgent = (filters) => {
    const nonEmptyFilters = pickBy(filters, identity);

    const request = createGetRequest(
      'dashboard-appeal-submitted-by-agent',
      nonEmptyFilters
    );

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => res.data.map(camelizeKeys));
  };

  static fetchAppealsAmountCollectedByAgent = (filters) => {
    const nonEmptyFilters = pickBy(filters, identity);

    const request = createGetRequest(
      'dashboard-appeal-amount-collected-by-agent',
      nonEmptyFilters
    );

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => res.data.map(camelizeKeys));
  };

  static fetchProviderOverview = ({ practiceId, secretKey, filters }) => {
    const nonEmptyFilters = pickBy(filters, identity);

    const requestBody = {
      ...snakeCaseKeys(nonEmptyFilters),
      practice_id: practiceId,
    };

    const requestHeaders = extractIntegrationAPIHeaders({
      secretKey,
      practiceId,
    });

    const request = createPostRequest('provider-overview', requestBody, {
      baseUrl: INTEGRATION_API_BASE_URL,
      headers: requestHeaders,
    });

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => res.data.map(camelizeKeys));
  };

  static fetchClinicOverview = ({ practiceId, secretKey, filters }) => {
    const nonEmptyFilters = pickBy(filters, identity);

    const requestBody = {
      ...snakeCaseKeys(nonEmptyFilters),
      practice_id: practiceId,
    };

    const requestHeaders = extractIntegrationAPIHeaders({
      secretKey,
      practiceId,
    });

    const request = createPostRequest('clinic-overview', requestBody, {
      baseUrl: INTEGRATION_API_BASE_URL,
      headers: requestHeaders,
    });

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => res.data.map(camelizeKeys));
  };

  static fetchDeniedAmountByPayers = ({ practiceId, secretKey, filters }) => {
    const nonEmptyFilters = pickBy(filters, identity);

    const requestHeaders = extractIntegrationAPIHeaders({
      secretKey,
      practiceId,
    });

    const requestBody = {
      ...snakeCaseKeys(nonEmptyFilters),
      practice_id: practiceId,
    };

    const request = createPostRequest('denied-amount-by-payer', requestBody, {
      baseUrl: INTEGRATION_API_BASE_URL,
      headers: requestHeaders,
    });

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => res.data.map(camelizeKeys));
  };

  static fetchDeniedAmountByCPTCode = ({ practiceId, secretKey, filters }) => {
    const nonEmptyFilters = pickBy(filters, identity);

    const requestHeaders = extractIntegrationAPIHeaders({
      secretKey,
      practiceId,
    });

    const requestBody = {
      ...snakeCaseKeys(nonEmptyFilters),
      practice_id: practiceId,
    };

    const request = createPostRequest(
      'denied-amount-by-cpt-code',
      requestBody,
      {
        baseUrl: INTEGRATION_API_BASE_URL,
        headers: requestHeaders,
      }
    );

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => res.data.map(camelizeKeys));
  };

  static fetchDeniedAmountByReasonCode = ({
    practiceId,
    secretKey,
    filters,
  }) => {
    const nonEmptyFilters = pickBy(filters, identity);

    const requestHeaders = extractIntegrationAPIHeaders({
      secretKey,
      practiceId,
    });

    const requestBody = {
      ...snakeCaseKeys(nonEmptyFilters),
      practice_id: practiceId,
    };

    const request = createPostRequest(
      'denied-amount-by-reason-code',
      requestBody,
      {
        baseUrl: INTEGRATION_API_BASE_URL,
        headers: requestHeaders,
      }
    );

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => res.data.map(camelizeKeys));
  };

  static fetchClaimsOverview = ({
    practiceId,
    secretKey,
    filters,
    practiceIdentifier,
  }) => {
    const nonEmptyFilters = pickBy(filters, identity);

    const requestHeaders = extractIntegrationAPIHeaders({
      secretKey,
      practiceId,
    });

    const requestBody = {
      ...snakeCaseKeys(nonEmptyFilters),
      practice_id: practiceId,
    };

    if (practiceIdentifier) {
      requestBody.practice_identifier = practiceIdentifier;
    }

    const request = createPostRequest('claims/overview', requestBody, {
      baseUrl: INTEGRATION_API_BASE_URL,
      headers: requestHeaders,
    });

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => camelizeKeys(res.data));
  };

  static fetchAppealsByStatus = (filters) => {
    const nonEmptyFilters = pickBy(filters, identity);

    const request = createGetRequest(
      'dashboard-appeal-by-status',
      nonEmptyFilters
    );

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => camelizeKeys(res.data));
  };

  static fetchTeamDashboardCounts = (filters) => {
    const nonEmptyFilters = pickBy(filters, identity);

    const request = createGetRequest(TEAM_DASHBOARD_COUNTS, nonEmptyFilters);

    return fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((res) => camelizeKeys(res.data));
  };
}
