import React from 'react';
import classnames from 'classnames';
import CreatableSelect from 'react-select/creatable';

const CustomCreatableSelect = (props) => {
  const {
    handleChange,
    values,
    optionsCharacterLimit,
    meta: { error },
    ...rest
  } = props;

  const validateInput = (inputValue) => {
    if (!inputValue || inputValue.length > optionsCharacterLimit) {
      return false;
    }
    return true;
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: state.selectProps.inputValue
        ? validateInput(state.selectProps.inputValue)
          ? base.border
          : '2px solid #f64d31'
        : base.border,
      boxShadow: state.selectProps.inputValue
        ? validateInput(state.selectProps.inputValue)
          ? base.boxShadow
          : 'none'
        : base.boxShadow,
      '&:hover': {
        border: state.selectProps.inputValue
          ? validateInput(state.selectProps.inputValue)
            ? base.border
            : '2px solid #f64d31'
          : base.border,
      },
    }),
  };

  return (
    <CreatableSelect
      className={classnames('ap-react-select', {
        'ap-react-select--has-error': error,
      })}
      onChange={handleChange}
      value={values}
      {...(optionsCharacterLimit
        ? { isValidNewOption: validateInput, styles: customStyles }
        : {})}
      noOptionsMessage={() =>
        optionsCharacterLimit
          ? `Max ${optionsCharacterLimit} characters allowed`
          : 'No options'
      }
      isMulti
      {...rest}
    />
  );
};

export default CustomCreatableSelect;
