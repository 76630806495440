import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { useState, useEffect } from 'react';

import ReactTooltip from 'react-tooltip';
import { formatToMoney } from 'helpers/money';

import LoadingIndicator from 'components/Shared/LoadingIndicator';
import AppealioDialog from 'components/common/popup/AppealioDialog';
import EmptyWidgetPlaceholder from 'components/ApDashboard/Manager/EmptyWidgetPlaceholder';

import sortChevronUpIconBold from 'img/sort-chevron-up-bold.svg';
import sortChevronDownIconBold from 'img/sort-chevron-down-bold.svg';

import { SORT_ORDER } from 'constants/appConstants.js';

import { useFetchTopPayers } from './hooks';

const columns = [
  {
    label: 'Payer Name',
    key: 'payer',
    info: 'Payer Name',
  },
  {
    label: 'Average',
    key: 'averageAmount',
    info: 'Average Allowed Amount',
  },
  {
    label: 'Total',
    key: 'totalAmount',
    info: ' Total Allowed',
    sortOrder: SORT_ORDER.DESC,
  },
];

const TopPayersTable = (props) => {
  const {
    filters,
    setPayersId,
    setSelectedThreePayerIds,
    selectedThreePayerIds,
    isFetchingData,
    setIsFetchingData,
    payersDropdownOptions,
    setPayerNotInTopPayerList,
    setIsTopPayersLoading,
  } = props;
  const [topPayerIds, setTopPayerIds] = useState([]);
  const [tableColumns, setTableColumns] = useState(columns);
  const [sort, setSort] = useState({
    sortBy: 'total',
    sortOrder: SORT_ORDER.DESC,
  });
  const [payerSelectionAlertPopup, setPayerSelectionAlertPopup] =
    useState(false);

  const {
    loading,
    error,
    payers = [],
    isNotTopPayer,
  } = useFetchTopPayers(filters, sort, topPayerIds);

  useEffect(() => {
    if (payers.length > 0 && topPayerIds.length === 0) {
      setTopPayerIds(payers.map((payer) => payer.id));
    }
    // eslint-disable-next-line
  }, [payers]);

  useEffect(() => {
    if (loading) return;

    const topThreePayers = payers.slice(0, 3).map((payer) => payer.id);
    const fillerPayers =
      payers.length < 3 ? Array(3 - payers.length).fill(null) : [];
    const newSelectedThreePayerIds = [...topThreePayers, ...fillerPayers];

    setSelectedThreePayerIds(newSelectedThreePayerIds);
    setPayersId(payers.map((payer) => payer.id));
    // eslint-disable-next-line
  }, [loading, payers]);

  const onSort = (sortKey, sortOrder) => {
    if (!sortKey) return;

    const newSortOrder =
      sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
    setSort({
      sortBy: sortKey,
      sortOrder: newSortOrder,
    });

    setTableColumns(
      tableColumns.map((column) => {
        if (column.sortKey === sortKey) {
          column.sortOrder = newSortOrder;
        }
        return column;
      })
    );
  };

  const togglePayerSelection = (payerId) => {
    const isPayerSelected = selectedThreePayerIds.includes(payerId);
    if (isPayerSelected) {
      return setSelectedThreePayerIds(
        selectedThreePayerIds.map((id) => {
          if (id === payerId) {
            return null;
          }
          return id;
        })
      );
    }
    if (selectedThreePayerIds.filter(Boolean).length === 3) {
      setPayerSelectionAlertPopup(true);
      return;
    }
    const { newSelectedThreePayerIds } = [...selectedThreePayerIds].reduce(
      (acc, cur) => {
        if (cur === null && !acc.isNewPayerSet) {
          return {
            newSelectedThreePayerIds: [
              ...acc.newSelectedThreePayerIds,
              payerId,
            ],
            isNewPayerSet: true,
          };
        }

        return {
          newSelectedThreePayerIds: [...acc.newSelectedThreePayerIds, cur],
          isNewPayerSet: acc.isNewPayerSet,
        };
      },
      {
        newSelectedThreePayerIds: [],
        isNewPayerSet: false,
      }
    );

    setSelectedThreePayerIds(newSelectedThreePayerIds);
  };

  useEffect(() => {
    setIsFetchingData({
      ...isFetchingData,
      isFetchingTopPayersData: loading,
    });
    setIsTopPayersLoading(loading);
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (isNotTopPayer) {
      const payerName =
        (filters.payer &&
          payersDropdownOptions.find(({ value }) => value === filters.payer)
            .label) ||
        null;
      return setPayerNotInTopPayerList(payerName);
    } else {
      setPayerNotInTopPayerList(null);
    }
    // eslint-disable-next-line
  }, [filters, isNotTopPayer]);

  if (error) {
    return <EmptyWidgetPlaceholder title="Opps! Something went wrong!" />;
  }

  if (isNotTopPayer) {
    return (
      <EmptyWidgetPlaceholder
        className="mt-40"
        title={'N/A: Selected Payer does not fall within the Top 10 Payers.'}
      />
    );
  }

  return (
    <div>
      <table width="100%" className="ap-card-table ap-card-table--medium-cell">
        <thead>
          <tr>
            {tableColumns.map((column, idx) => (
              <th
                key={idx}
                className={classnames({
                  'ap-card-table--highlight-th': column.sortKey === sort.sortBy,
                })}
                onClick={() => onSort(column.sortKey, column.sortOrder)}
              >
                {column.label}
                {column.sortKey ? (
                  column.sortKey === sort.sortBy ? (
                    sort.sortOrder === SORT_ORDER.ASC ? (
                      <img src={sortChevronDownIconBold} alt="sort-arrow-img" />
                    ) : (
                      <img src={sortChevronUpIconBold} alt="sort-arrow-img" />
                    )
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!payers.length && !loading && (
            <tr>
              <td colSpan={3} className="ap-card-table__td--loading">
                <EmptyWidgetPlaceholder title="No Data Available" />
              </td>
            </tr>
          )}

          {loading ? (
            <tr>
              <td colSpan={3} className="ap-card-table__td--loading">
                <LoadingIndicator showing={true} />
              </td>
            </tr>
          ) : (
            payers.map((payer, idx) => {
              const selectedPayerIdx = selectedThreePayerIds.findIndex(
                (payerId) => payerId === payer.id
              );
              const tdCellClassNames = classnames(
                `ap-card-table__highlight-td--${selectedPayerIdx}`
              );
              return (
                <tr
                  className="cursor-pointer"
                  key={idx}
                  onClick={() => togglePayerSelection(payer.id)}
                >
                  <td
                    width="50%"
                    data-tip={payer.payer}
                    data-for="payer-table-tooltip"
                    className={`ap-card-table__ellipsis-td ${tdCellClassNames}`}
                  >
                    {payer.payer}
                    <ReactTooltip
                      effect="solid"
                      place="top"
                      multiline={true}
                      id="payer-table-tooltip"
                      type="info"
                      className="appealio-custom-tooltip--info appealio-custom-tooltip--info-auto"
                    />
                  </td>
                  <td width="25%" className={tdCellClassNames}>
                    {formatToMoney(payer.averageAmount)}
                  </td>
                  <td
                    width="25%"
                    className={classnames(tdCellClassNames, {
                      'fw-bold': (payer.sortKey = sort.sortBy),
                    })}
                  >
                    {formatToMoney(Math.floor(payer.totalAmount))}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      {payerSelectionAlertPopup && (
        <AppealioDialog
          onClosePressed={() => setPayerSelectionAlertPopup(false)}
        >
          <p>
            You can select only 3 payers. Please unselect one and try again.
          </p>
        </AppealioDialog>
      )}
    </div>
  );
};

TopPayersTable.propTypes = {
  filters: PropTypes.object.isRequired,
};

export default TopPayersTable;
