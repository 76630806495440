import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AiOutlineFolder } from 'react-icons/ai';

import PracticeList from './PracticeList';
import AssignPractice from './AssignPractice';
import { AppealioPopupWithFooter } from 'components/common/popup';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';

import { fetchDenialsPracticeOptions } from 'redux/actions/dropdownOptionsActions';

import { useFetchPracticeGroup } from './hooks';

import {
  DROPDOWN_OPTIONS_STATE_KEYS,
  getDropdownOptions,
} from 'redux/reducers/dropdownOptionsStore';

const PracticeGroupPopup = (props) => {
  const { onClosePressed, hidePracticeList, practiceGroupName } = props;

  return (
    <AppealioPopupWithFooter
      className={classNames(
        'appealio-popup--v3 appealio-popup--v3--overflow-hidden',
        {
          'empty-practice-popup': hidePracticeList,
        }
      )}
      onClosePressed={onClosePressed}
      title={
        <div className="d-flex align-items-center">
          <AiOutlineFolder size="22" className="mr-4" />
          Add/Remove Practice(s) from{' '}
          <span className="ml-4 fw-bold"> {practiceGroupName}</span>
        </div>
      }
      isFooterOutside={false}
    >
      {props.children}
    </AppealioPopupWithFooter>
  );
};

const PracticePopup = ({
  onClosePressed,
  isAddOrRemove,
  practiceGroupId,
  practiceGroupName,
  practiceOptions,
  ungroupedPractices,
  isFetchingPracticeOptions,
  onAddRemovePracticeClick,
  onAssignPracticeComplete,
}) => {
  const { practiceGroup, loading } = useFetchPracticeGroup(practiceGroupId);
  const isEmptyPracticeGroup = practiceGroup?.practices.length === 0;
  const hidePracticeList = isEmptyPracticeGroup && !isAddOrRemove;

  const practiceIdentifiers = practiceGroup?.practices || [];
  const selectedPracticeList = practiceOptions?.data
    .filter(({ value }) => practiceIdentifiers.includes(value))
    .map((x) => ({ ...x, selected: true }));
  const unSelectedPracticeList = ungroupedPractices.map((x) => ({
    ...x,
    selected: false,
  }));
  const isLoading = isFetchingPracticeOptions || loading;

  if (isLoading) {
    return (
      <PracticeGroupPopup
        hidePracticeList={hidePracticeList}
        onClosePressed={onClosePressed}
        practiceGroupName={practiceGroupName}
      >
        <LoadingBlockHelper className="dashboard-bottom-loader" isLoading />
      </PracticeGroupPopup>
    );
  }

  if (!isAddOrRemove) {
    return (
      <PracticeGroupPopup
        hidePracticeList={hidePracticeList}
        onClosePressed={onClosePressed}
        practiceGroupName={practiceGroupName}
      >
        <div className="practice-group__sticky-search-bar">
          <div
            className={classNames('d-flex align-items-center', {
              'justify-content--space-between': !isEmptyPracticeGroup,
              'justify-content--flex-end': isEmptyPracticeGroup,
            })}
          >
            {isEmptyPracticeGroup && (
              <div>No Practices are yet assigned to this group.</div>
            )}
            <button
              onClick={onAddRemovePracticeClick}
              className="ap-button ap-button--secondary ml-auto settings-add-button"
            >
              Add/Remove Practice(s)
            </button>
          </div>
        </div>
        {!isEmptyPracticeGroup && (
          <PracticeList practices={selectedPracticeList} />
        )}
      </PracticeGroupPopup>
    );
  }

  return (
    <AssignPractice
      practices={[...selectedPracticeList, ...unSelectedPracticeList]}
      practiceGroupId={practiceGroupId}
      practiceGroupName={practiceGroupName}
      onAssignPracticeComplete={onAssignPracticeComplete}
      onClosePressed={onClosePressed}
    />
  );
};

const mapStateToProps = (state) => {
  const practiceOptions = getDropdownOptions(
    state,
    DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PRACTICE_OPTIONS
  );
  return {
    isFetchingPracticeOptions: practiceOptions.isFetching,
    practiceOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { fetchPracticeOptions: fetchDenialsPracticeOptions },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PracticePopup);
