import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import Footer from '../../Shared/Footer';
import TitleHeader from '../../Shared/TitleHeader';
import DenialInformation from './DenialInformation';
import { CreateAppealMode } from './CreateAppealMode';
import CreateAppealSubHeader from '../CreateAppealSubHeader';

import {
  getAppealById,
  saveDeadlineForAppeal,
} from '../../../redux/actions/createAppealActions';
import { getUserInfo } from '../../../redux/reducers/loginStore';
import { getAppealFromState } from '../../../redux/reducers/AppealStore';
import { transformToDateInput } from '../../../API/Serializers/Transforms';

class HistoryAppealContainer extends Component {
  componentDidMount() {
    this.props.actions.getAppealById(this.props.appealID).then((res) => {
      const appeal = this.props.appeal;
      this.props.actions.saveDeadlineForAppeal(
        this.props.appealID,
        appeal.deniedAt,
        appeal.deadline
      );
    });
  }

  render() {
    return (
      <div>
        <div className="create-appeal">
          {this.renderTitle()}
          <div className="create-appeal__separator"></div>
          {this.renderSubHeader()}
          <div className="row appeal-box__row-no-padding">
            {this.renderHistoryForm()}
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  renderHistoryForm() {
    if (this.props.isLoading === false) {
      return (
        <DenialInformation
          initialValues={this.props.initialValues}
          mode={CreateAppealMode.HistoryAppeal}
          shouldDisplayFooter={false}
          appealID={this.props.appealID}
          integrationType={this.props.integrationType}
          isImportedAppeal={this.props.isImportedAppeal}
        />
      );
    }
  }

  renderTitle() {
    if (this.props.isLoading === false) {
      return (
        <TitleHeader title={`Appeal Round ${this.props.appeal.appealRound}`} />
      );
    }
  }

  renderSubHeader() {
    if (this.props.isLoading === false) {
      return <CreateAppealSubHeader data={this.props.appeal} />;
    }
  }
}

function mapStateToProps(state, ownProps) {
  const appealID = ownProps.match.params.appealID;

  const appealState = getAppealFromState(state, appealID);
  if (!appealState || appealState.isLoading || appealState.isRejected) {
    return {
      isLoading: true,
      appealID,
    };
  } else {
    const { appeal } = appealState;
    const { claimNumber, imported: isImportedAppeal } = appeal;

    const userInfo = getUserInfo(state);
    const integrationType = userInfo && userInfo.integrationType;

    const denialDate = transformToDateInput(appeal.deniedAt);
    const deadline = transformToDateInput(appeal.deadline);

    return {
      appeal,
      appealID,
      isLoading: false,
      initialValues: {
        claimNumber,
        denialDate,
        deadline,
        cpts: appeal.eob.procedures,
      },
      integrationType,
      isImportedAppeal,
    };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { push, getAppealById, saveDeadlineForAppeal },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryAppealContainer);
