import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { get, isEqual } from 'lodash';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import ChartistGraph from 'react-chartist';
import { push } from 'connected-react-router';
import ChartistFillDonut from 'chartist-plugin-fill-donut';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';

import DashboardAPI from '../../../../API/DashboardAPI';
import { handleError } from '../../../../helpers/errorHandler';

import AppealStatusIndicator from './AppealStatusIndicator';
import LoadingBlockHelper from '../../../Shared/LoadingBlockHelper';

import questionIconImg from '../../../../img/question.svg';
import { createURLWithParams } from '../../../../API/Config';

import { SUBMISSIONS, SUBMISSIONS_IN_PROGRESS } from 'constants/routes';
import { SUBMISSION_TYPE, SUBMISSION_STATUS } from 'constants/appConstants';

class AppealsByStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        inProgressCount: 0,
        submittedCount: 0,
        successfulCount: 0,
        deniedCount: 0,
      },
      isFetching: false,
      isMedicalRecord: false,
    };

    this.donutChartRef = React.createRef();
  }

  componentDidMount = () => {
    this.fetchAndSetData();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !isEqual(prevProps.filters, this.props.filters) ||
      prevState.isMedicalRecord !== this.state.isMedicalRecord
    ) {
      this.fetchAndSetData();
    }
  };

  componentWillUnmount() {
    this.removeChartClickListener();
  }

  addChartClickListener = () => {
    // eslint-disable-next-line react/no-find-dom-node
    const chartDOMNode = ReactDOM.findDOMNode(this.donutChartRef);
    chartDOMNode.addEventListener('click', this.onChartClick);
  };

  removeChartClickListener = () => {
    // eslint-disable-next-line react/no-find-dom-node
    const chartDOMNode = ReactDOM.findDOMNode(this.donutChartRef);
    if (chartDOMNode) {
      chartDOMNode.removeEventListener('click', this.onChartClick);
    }
  };

  setViewWidgetBy = (setViewWidgetBy) => this.setState({ setViewWidgetBy });

  fetchAndSetData = async () => {
    const { filters } = this.props;
    const { isMedicalRecord } = this.state;

    const filtersForFetch = {
      payer_id: get(filters, 'payer.value'),
      provider_id: get(filters, 'renderingProvider.value'),
      practice_identifier: get(filters, 'practice.value'),
      agent_id: get(filters, 'agent.value'),
      from_date: filters?.dateRange?.fromDate,
      to_date: filters?.dateRange?.toDate,
      team_id: get(filters, 'team.value'),
      ...(isMedicalRecord && { medical_records: 1 }),
    };

    try {
      this.setState({ isFetching: true });

      const data = await DashboardAPI.fetchAppealsByStatus(filtersForFetch);

      this.setState({ isFetching: false, data });
    } catch (error) {
      this.setState({ isFetching: false });
      handleError(error);
    }
  };

  redirectToSubmissions = (statusFilter) => {
    const { filters } = this.props;
    const { isMedicalRecord } = this.state;

    const submissionUrl =
      statusFilter === SUBMISSION_STATUS.IN_PROGRESS
        ? SUBMISSIONS_IN_PROGRESS
        : SUBMISSIONS;
    const submissionType = isMedicalRecord
      ? SUBMISSION_TYPE.RECORD
      : SUBMISSION_TYPE.APPEAL;

    const appealsTableUrl = createURLWithParams(
      submissionUrl,
      {
        set_filters: true,
        agent_id: get(filters, 'agent.value'),
        payer_id: get(filters, 'payer.value'),
        provider_id: get(filters, 'renderingProvider.value'),
        from_date: filters?.dateRange?.fromDate,
        to_date: filters?.dateRange?.toDate,
        ...(submissionUrl === SUBMISSIONS_IN_PROGRESS
          ? {
              practice_identifier: get(filters, 'practice.value'),
              submissionType: submissionType.toLowerCase(),
            }
          : {
              practice_id: get(filters, 'practice.practiceId'),
              types: submissionType,
            }),
      },
      true
    );
    this.props.actions.push(appealsTableUrl);
  };

  onDonutChartClick = (e) => {
    if (!get(e, 'target.attributes.getNamedItem')) return;

    const ctMeta = e.target.attributes.getNamedItem('ct:meta');
    const metaValue = ctMeta.nodeValue || '';

    if (
      !['In Progress', 'Submitted', 'Successful', 'Denied'].includes(metaValue)
    )
      return;

    this.redirectToSubmissions(metaValue);
  };

  onDonutChartRef = (donutChartRef) => {
    this.donutChartRef = donutChartRef;
    if (!this.donutChartRef) return;

    // eslint-disable-next-line react/no-find-dom-node
    const donutChartDOMNode = ReactDOM.findDOMNode(this.donutChartRef);
    donutChartDOMNode.addEventListener('click', this.onDonutChartClick);
  };

  onFilterChange = () => {
    this.setState((state) => {
      return { isMedicalRecord: !state.isMedicalRecord };
    });
  };

  renderContent() {
    const { inProgressCount, submittedCount } = this.state.data;

    const totalCount = inProgressCount + submittedCount;

    if (this.props.isFetchingFilterOptions || this.state.isFetching) {
      return (
        <LoadingBlockHelper
          className="appeals-by-status__loader"
          isLoading={true}
        />
      );
    }

    return (
      <React.Fragment>
        <div
          className="appeals-by-status__graph ct-chart"
          datacy="appeals-by-status"
        >
          <ChartistGraph
            ref={this.onDonutChartRef}
            data={{
              series: [
                {
                  meta: 'In Progress',
                  value: inProgressCount,
                  className: 'appeal-status-donut-chart--in-progress',
                },
                {
                  meta: 'Submitted',
                  value: submittedCount,
                  className: 'appeal-status-donut-chart--submitted',
                },
                // {
                //   meta: "Successful",
                //   value: successfulCount,
                //   className: "appeal-status-donut-chart--successful",
                // },
                // {
                //   meta: "Denied",
                //   value: deniedCount,
                //   className: "appeal-status-donut-chart--denied",
                // },
              ],
            }}
            options={{
              donut: true,
              donutWidth: 20,
              startAngle: 0,
              showLabel: false,
              width: 152,
              height: 152,
              plugins: [
                ChartistTooltip({
                  appendToBody: false,
                }),
                ChartistFillDonut({
                  items: [
                    {
                      content: `<div class="appeal-status-donut-chart__middle-content">
                        <span>${totalCount}</span>
                      </div>`,
                    },
                  ],
                }),
              ],
            }}
            type="Pie"
          />
        </div>
        <div className="appeals-by-status__stats">
          <div
            className="cursor-pointer mb-16"
            onClick={() =>
              this.redirectToSubmissions(SUBMISSION_STATUS.IN_PROGRESS)
            }
          >
            <AppealStatusIndicator className="mr-8" inProgress={true} />
            <span>{inProgressCount} In Progress</span>
          </div>
          <div
            className="cursor-pointer mb-16"
            onClick={() =>
              this.redirectToSubmissions(SUBMISSION_STATUS.SUBMITTED)
            }
          >
            <AppealStatusIndicator className="mr-8" submitted={true} />
            <span>{submittedCount} Submitted</span>
          </div>
          {/* <div
            className="cursor-pointer mb-16"
            onClick={() => this.redirectToDashboard("Successful")}
          >
            <AppealStatusIndicator className="mr-8" successful={true} />
            <span>{successfulCount} Successful</span>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => this.redirectToDashboard("Denied")}
          >
            <AppealStatusIndicator className="mr-8" denied={true} />
            <span>{deniedCount} Denied</span>
          </div> */}
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { isMedicalRecord } = this.state;
    return (
      <div className="ap-dashboard-widget__container">
        <div className="ap-dashboard-widget__title d-flex justify-content--space-between">
          <span>By Statuses</span>
          <img
            data-tip={`View the number of in progress, submitted ${
              isMedicalRecord ? 'records' : 'appeals'
            }`}
            alt="question icon"
            src={questionIconImg}
          ></img>
          <ReactTooltip effect="solid" place="top" multiline={true} />
        </div>
        <div className="ap-dashboard-widget__status-filter">
          <div className="d-flex aligin-items-center ap-dashboard-widget__status-filter-button-wrapper">
            <button
              onClick={this.onFilterChange}
              className={classnames('ap-button--filter mr-12', {
                'ap-button--filter-active': !isMedicalRecord,
              })}
            >
              Appeals
            </button>
            <button
              onClick={this.onFilterChange}
              className={classnames('ap-button--filter', {
                'ap-button--filter-active': isMedicalRecord,
              })}
            >
              Records
            </button>
          </div>
        </div>

        <div className="appeals-by-status__container">
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ push }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppealsByStatus);
