import _ from 'lodash';
import { Map } from 'immutable';

import * as types from '../actions/actionTypes';

import { transformToDateInput } from '../../API/Serializers/Transforms';

const initialState = Map({});

export function appealStore(state = initialState, action = {}) {
  switch (action.type) {
    case `${types.GET_APPEAL_BY_ID}_PENDING`:
      return state.set(action.meta.appealID, {
        isLoading: true,
        isRejected: true,
      });

    case `${types.GET_APPEAL_BY_ID}_FULFILLED`:
    case `${types.CREATE_REAPPEAL}_FULFILLED`:
    case `${types.CREATE_FINAL_APPEAL}_FULFILLED`:
      return state.set(action.meta.appealID, {
        isLoading: false,
        isRejected: false,
        appeal: action.payload,
      });
    case `${types.GET_APPEAL_BY_ID}_REJECTED`:
      return state.set(action.meta.appealID, {
        isLoading: false,
        isRejected: true,
      });
    case `${types.CLEAR_AUTH_TOKEN}`:
      return initialState;
    case types.REMOVE_DOCUMENT_FROM_APPEAL: {
      const appealState = state.get(action.meta.appealID);
      const appeal = appealState?.appeal;
      if (!appeal || !appeal?.documents) {
        return state;
      }
      const updatedAppeal = {
        ...appeal,
        documents: appeal.documents.filter(
          (document) => document.id !== action.meta.documentID
        ),
      };

      return state.set(action.meta.appealID, {
        ...appealState,
        appeal: updatedAppeal,
      });
    }
    case types.REMOVE_LETTER_FROM_APPEAL: {
      const appealState = state.get(action.meta.appealID);
      const appeal = appealState?.appeal;
      if (!appeal || !appeal?.letters) {
        return state;
      }
      const updatedAppeal = {
        ...appeal,
        letters: appeal.letters.filter(
          (letter) => letter.id !== action.meta.letterID
        ),
      };

      return state.set(action.meta.appealID, {
        ...appealState,
        appeal: updatedAppeal,
      });
    }
    default:
      return state;
  }
}

export const getAppealFromState = (state, appealID) => {
  return state.get('appealStore').get(appealID);
};

export const isAppealReadyToUse = (state, appealID) => {
  const appealState = state.get('appealStore').get(appealID);
  if (!appealState || appealState.isLoading || appealState.isRejected) {
    return false;
  } else {
    return true;
  }
};

export const getEOBInitialValuesFromAppealState = (appealState) => {
  const { appeal } = appealState;
  const { claimNumber, deniedAt } = appeal;
  const appealProcedures = appeal.eob.procedures;
  // we always show one empty EOBProcedure so if appeal has empty procedures array we
  // prepare placeholder procedure
  const procedures = _.isEmpty(appealProcedures)
    ? [{ quantity: 1 }]
    : appealProcedures;
  const workerCompensationFields = {};
  if (appeal.injuryDate) {
    workerCompensationFields.injuryDate = transformToDateInput(
      appeal.injuryDate
    );
  }
  if (appeal.employer) {
    workerCompensationFields.employer = appeal.employer;
  }
  return {
    claimNumber,
    deniedAt,
    ...workerCompensationFields,
    cpts: procedures,
  };
};

export const getPatientInfoInitialValuesFromAppealState = (appealState) => {
  const { appeal } = appealState;
  const { patient } = appeal;

  const dob = patient.dob ? transformToDateInput(patient.dob) : null;

  const {
    planType,
    memberId,
    groupNumber,
    agent,
    author,
    clinic,
    payerContact,
    pmSystemAccountId,
    practiceId,
  } = appeal;

  // ['firstName', 'lastName', 'month', 'day', 'year', 'address', 'city', 'state', 'provider'
  //  , 'payer', 'planType', 'memberId', 'groupNumber']
  return {
    appealID: appeal.id,
    patientID: patient.id,
    agent: agent ? agent.id : null,
    author: author ? author.id : null,
    customPatientId: patient.customPatientId,
    firstName: patient.firstName,
    lastName: patient.lastName,
    address: patient.address1,
    city: patient.city,
    state: patient.state,
    provider: _.get(appeal, 'providerId', null),
    billingProvider: _.get(appeal, 'billingProviderId', null),
    zipCode: patient.zipcode,
    payer: appeal.payerId,
    payerContact: payerContact ? payerContact.id : null,
    clinic: clinic ? clinic.id : null,
    planType,
    memberId,
    groupNumber,
    dob,
    pmSystemAccountId,
    practiceId,
  };
};

export const getPatientInfoPreFillInitialValues = (
  PreFillData,
  userPractices = []
) => {
  if (!PreFillData) {
    return {};
  }

  const practice = userPractices.find(
    ({ practiceIdentifier }) =>
      practiceIdentifier === PreFillData.get('practice_id')
  );
  const practiceId = _.get(practice, 'id', '');

  const isMedicalRecordsSubmission = PreFillData.get(
    'is_medical_records_submission',
    false
  );
  const submitterClaimId = PreFillData.get('submitter_claim_id', '');
  const claimNumber = PreFillData.get('claim_number', '');

  const initialValues = {
    practiceId,
    isMedicalRecordsSubmission,
    firstName: PreFillData.getIn(['patient', 'first_name'], ''),
    lastName: PreFillData.getIn(['patient', 'last_name'], ''),
    customPatientId: PreFillData.getIn(['patient', 'patient_id'], ''),
    dob: PreFillData.getIn(['patient', 'dob'], ''),
    provider: PreFillData.get('provider_id', ''),
    billingProvider: PreFillData.get('billing_provider_id'),
    payer: PreFillData.get('payer_id'),
    payerContact: PreFillData.get('payer_contact_id'),
    clinic: PreFillData.get('clinic_id'),
    memberId: PreFillData.get('member_id'),
    pmSystemAccountId: PreFillData.get('voucher_id'),
    ediPayerId: PreFillData.get('edi_payer_id'),
  };

  if (isMedicalRecordsSubmission) {
    return {
      ...initialValues,
      submitterClaimId,
    };
  }

  return {
    ...initialValues,
    claimNumber,
  };
};
