import React from 'react';

import appealioLogo from 'img/DV.svg';
import siteUnderMaintenance from 'img/site_under_maintenance.svg';

import './style.css';

const UnderMaintenancePage = () => {
  return (
    <div className="site-under-maintenance">
      <div className="topbar no-print">
        <a className="topbar__title" href="#">
          <img
            className="topbar__title__logo"
            alt="Appealio Logo"
            src={appealioLogo}
          />
        </a>
      </div>
      <div className="site-under-maintenance__main">
        <img
          className="site-under-maintenance__image"
          src={siteUnderMaintenance}
          alt="Under Maintenance"
        />
        <div className="site-under-maintenance__content">
          <h1>We’re working on improving Appealio at the moment. </h1>
          <p>
            Appealio will be back up shortly and we are very sorry for any
            inconvenience this has caused you!
          </p>
        </div>
      </div>
    </div>
  );
};

export default UnderMaintenancePage;
