import PropTypes from 'prop-types';
import React, { useState } from 'react';

import './style.css';

import closeIcon from 'img/close-modal.svg';

import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';
import RichTextEditor from 'components/CreateAppeal/AppealLetter/RichTextEditor';
import {
  fillTemplate,
  formatTemplate,
} from 'components/CreateAppeal/AppealLetter/TemplateFiller';

const PreviewTemplate = (props) => {
  const {
    preview: { name = '', template },
    onClose,
  } = props;
  const [renderContent, setRenderContent] = useState('');

  return (
    <ScreenDimmerComponent>
      <div className="ap-popup ap-popup--md show-template-popup ap-popup--p-0 show-pm-note-template-popup">
        <div className="ap-popup__header ap-popup__header--with-border">
          <div className="ap-popup__title">{name} Template</div>
          <div className="ap-popup__close" onClick={onClose}>
            <img alt="Close" className="ap-popup__close-icon" src={closeIcon} />
          </div>
        </div>
        <div className="ap-popup__content">
          <div className="appeal-letter__preview--background">
            <div className="appeal-letter__preview--content">
              <span
                dangerouslySetInnerHTML={{
                  __html: fillTemplate(renderContent),
                }}
              />
            </div>
          </div>
          <div className="d-none">
            <RichTextEditor
              content={formatTemplate(template, ['test'])}
              onContentChange={setRenderContent}
              datacy="appeal-letter-RichTextEditor"
            />
          </div>
        </div>
      </div>
    </ScreenDimmerComponent>
  );
};

PreviewTemplate.propTypes = {
  preview: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PreviewTemplate;
