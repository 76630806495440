import { RPA_WIDGET_TYPE } from 'constants/rpa';

import { CLOSE_RPA_WIDGET, OPEN_RPA_WIDGET } from './actionTypes';

export function openRpaWidget({
  widgetType = RPA_WIDGET_TYPE.CLAIM_STATUS_WIDGET_UHC,
  widgetConfig = null,
}) {
  return {
    type: OPEN_RPA_WIDGET,
    payload: {
      widgetType,
      widgetConfig,
    },
  };
}

export function closeRpaWidget() {
  return {
    type: CLOSE_RPA_WIDGET,
  };
}

export function openClaimStatusRpaWidget(
  widgetConfig = {
    claimNumber: '',
  },
  widgetType = RPA_WIDGET_TYPE.CLAIM_STATUS_WIDGET_UHC
) {
  return openRpaWidget({
    widgetType,
    widgetConfig,
  });
}
