import React, { useState } from 'react';
import { get as _get } from 'lodash';

import Card from 'components/common/card';
import AccountInformation from './AccountInformation';
import ResendEmailConfirmation from './ResendEmailConfirmation';
import { signupInfo } from 'API/CreateUserAPI';
import { handleError } from 'helpers/errorHandler';
import * as toast from 'components/Shared/toast';

const ACCOUNT_INFO_STEP = {
  ACCOUNT_INFO: 'ACCOUNT_INFO',
  RESEND_VERIFICATION_EMAIL: 'RESEND_VERIFICATION_EMAIL',
};

const CreateAppealioAccountIntro = (props) => {
  const [currentStep, setCurrentStep] = useState(
    ACCOUNT_INFO_STEP.ACCOUNT_INFO
  );
  const [userEmail, setUserEmail] = useState('');

  const handleSubmit = async (values) => {
    try {
      setUserEmail(values.email);

      const mappedValues = {
        client: {
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          state: values.state,
          zipcode: values.zipcode,
          name: values.name,
          phone: values.phone,
        },
        user: {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          username: values.username,
        },
      };
      await signupInfo(mappedValues);
    } catch (error) {
      const errorResponse = error.response;

      if (errorResponse.status === 400) {
        const response = await error.response.json();

        const nonFieldErrors = _get(response, 'user.non_field_errors', []);
        nonFieldErrors.forEach((error) => {
          toast.error({
            message: error,
            title: '',
          });
        });
        return;
      }

      return handleError(error);
    }

    setCurrentStep(ACCOUNT_INFO_STEP.RESEND_VERIFICATION_EMAIL);
  };

  const renderStep = () => {
    const steps = {
      [ACCOUNT_INFO_STEP.ACCOUNT_INFO]: (
        <AccountInformation onSubmit={handleSubmit} />
      ),
      [ACCOUNT_INFO_STEP.RESEND_VERIFICATION_EMAIL]: (
        <ResendEmailConfirmation email={userEmail} />
      ),
    };

    return steps[currentStep];
  };
  return (
    <div className="appealio-body-wrapper">
      <div className="container--md">
        <Card title="Create an Appealio account" />
        <div>{renderStep()}</div>
      </div>
    </div>
  );
};

export default CreateAppealioAccountIntro;
