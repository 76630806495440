import React from 'react';
import ReactTooltip from 'react-tooltip';

import './style.css';

import checkCircleIcon from 'img/check-circle.svg';
import addCircleIcon from 'img/add-circle-dark.svg';
import ArrayCell from 'components/common/table/ArrayCell';
import PageNavigator from 'components/Shared/PageNavigator';
import EmptyTableMessage from 'components/common/table/EmptyTableMessage';

const ClaimTable = ({
  claims,
  totalData,
  totalPages,
  currentPage,
  selectedClaims,
  onClaimSelect,
  onPageChange,
}) => {
  if (claims.length === 0) {
    return (
      <div className="claim-table-no-data">
        <EmptyTableMessage className="claim-table__empty" />
      </div>
    );
  }

  return (
    <div className="claim-table-container">
      <div className="pagination-container">
        <div className="total-claims">{totalData} Search Results</div>
        <PageNavigator
          totalPages={totalPages}
          activePage={currentPage}
          onPageChange={onPageChange}
          isDisabled={false}
          onDisableBtnClick={() => {}}
        />
      </div>
      <table className="claim-table">
        <thead>
          <tr>
            <th className="claim-table__col-select"></th>
            <th className="claim-table__col-patient">Patient</th>
            <th className="claim-table__col-claim-id">Claim ID</th>
            <th className="claim-table__col-claim-id">Payer Claim ID</th>
            <th className="claim-table__col-service-date">Service Date(s)</th>
            <th className="claim-table__col-amounts">
              <div>Billed Amt</div>
              <div>(Payment Amt)</div>
            </th>
            <th className="claim-table__col-practice">
              <div>Practice</div>
              <div>(Provider)</div>
            </th>
            <th className="claim-table__col-procedures">Procedure(s)</th>
            <th className="claim-table__col-modifiers">Modifier(s)</th>
            <th className="claim-table__col-reasons">Reason(s)</th>
            <th className="claim-table__col-remarks">Remark(s)</th>
          </tr>
        </thead>
        <tbody>
          {claims.map((claim, idx) => {
            const isSelected = selectedClaims.some(
              (sc) =>
                sc.claimNumber === claim.claimNumber &&
                sc.claimControlNumber === claim.claimControlNumber
            );
            return (
              <tr
                key={`${claim.claimNumber}-${claim.claimControlNumber}-${idx}`}
                className={isSelected ? 'selected' : ''}
              >
                <td className="claim-table__col-select">
                  <button
                    onClick={() => onClaimSelect(claim)}
                    className="claim-table__select-button"
                  >
                    <img
                      src={isSelected ? checkCircleIcon : addCircleIcon}
                      alt="Add Circle"
                    />
                  </button>
                </td>
                <td
                  className="claim-table__col-patient"
                  data-tip={claim.patientName}
                >
                  {claim.patientName}
                </td>
                <td
                  className="claim-table__col-claim-id"
                  data-tip={claim.claimNumber}
                >
                  {claim.claimNumber}
                </td>
                <td
                  className="claim-table__col-claim-id"
                  data-tip={claim.claimControlNumber}
                >
                  {claim.claimControlNumber}
                </td>
                <td className="claim-table__col-service-date">
                  <ArrayCell value={claim.serviceDates} />
                </td>
                <td className="claim-table__col-amounts">
                  <div>${claim.billedAmount}</div>
                  <div>(${claim.paymentAmount})</div>
                </td>
                <td className="claim-table__col-practice">
                  <div data-tip={claim.practiceName}>{claim.practiceName}</div>
                  {claim.providerName && (
                    <div data-tip={claim.providerName}>
                      ({claim.providerName})
                    </div>
                  )}
                </td>
                <td className="claim-table__col-procedures">
                  <ArrayCell value={claim.cptCodes} />
                </td>
                <td className="claim-table__col-modifiers">
                  <ArrayCell value={claim.cptModifiersCodes} />
                </td>
                <td className="claim-table__col-reasons">
                  <ArrayCell value={claim.reasonCodes} />
                </td>
                <td className="claim-table__col-remarks">
                  <ArrayCell value={claim.remarkCodes} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ReactTooltip place="top" effect="solid" />
    </div>
  );
};

export default ClaimTable;
