import * as ROUTES from '../../constants/routes';
import { APPEAL_STEPS } from '../../constants/appConstants';
import { appealStatus } from 'components/Dashboard/appeal-status-options';

export const getPathByAppealStep = (appealStep) => {
  const routesByAppealStep = {
    [APPEAL_STEPS.PATIENT_INFO]: ROUTES.CREATE_APPEAL_PATIENT_INFO,
    [APPEAL_STEPS.DENIAL_INFO]: ROUTES.CREATE_APPEAL_DENIAL_INFO,
    [APPEAL_STEPS.LETTERS_AND_FORM]: ROUTES.CREATE_APPEAL_APPEAL_LETTER,
    [APPEAL_STEPS.DOCUMENTS]: ROUTES.CREATE_APPEAL_DOCUMENTS,
    [APPEAL_STEPS.REVIEW]: ROUTES.CREATE_APPEAL_REVIEW,
    [APPEAL_STEPS.SUBMIT_CONFIRM]: ROUTES.CREATE_APPEAL_SUBMIT_CONFIRM,
  };

  return routesByAppealStep[appealStep] || ROUTES.CREATE_APPEAL_PATIENT_INFO;
};

export const validateAppealStep = (
  currentAppealStep,
  currentPageStep,
  appealId,
  replace,
  isMedicalRecords = false
) => {
  // INFO: Let user stay on letters and form page if medical record
  if (
    isMedicalRecords &&
    currentAppealStep === APPEAL_STEPS.DENIAL_INFO &&
    currentPageStep === APPEAL_STEPS.LETTERS_AND_FORM
  ) {
    return;
  }

  if (currentAppealStep < currentPageStep) {
    const path = getPathByAppealStep(currentAppealStep);
    replace(`${path}?appealId=${appealId}`);
  }
};

export const isSubmittedAppeal = (status) => appealStatus.submitted === status;

export const generateAppealPagePath = (path, appealId = null) =>
  appealId ? `${path}?appealId=${appealId}` : `${path}`;
