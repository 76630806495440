import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { replace } from 'connected-react-router';

import CardView from '../Shared/CardView';
import ResetPasswordForm from './ResetPasswordForm';

import { CREATE_PASSWORD } from 'constants/routes';
import resetPasswordIcon from '../../img/reset.svg';

export class ResetPassword extends Component {
  componentDidMount() {
    const { uid, token } = this.props;
    if (uid == null || token == null) {
      this.props.actions.replace('/login');
    }
  }

  render() {
    const { uid, token } = this.props;
    const isCreatePasswordForm = this.props.match.path === CREATE_PASSWORD;

    return (
      <div className="login">
        <CardView>
          <div className="login__header">
            <img
              alt="Reset password"
              className="login__header-logo"
              src={resetPasswordIcon}
            />
            <h2 datacy="create-or-reset-password-header">
              {isCreatePasswordForm ? 'Create' : 'Reset'} Password
            </h2>
          </div>

          <div className="login__subheader">
            Type a new password to login with.
          </div>

          <ResetPasswordForm
            uid={uid}
            token={token}
            onPasswordReset={this.onPasswordReset}
            isCreatePasswordForm={isCreatePasswordForm}
          />
        </CardView>
      </div>
    );
  }

  onPasswordReset = () => {
    this.props.actions.replace('/login');
  };
}

function mapStateToProps(state, ownProps) {
  return {
    uid: ownProps.match.params.uid,
    token: ownProps.match.params.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ replace }, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
);
